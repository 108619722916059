<template>
  <v-row style="margin: 20px">
    <v-card style="width: 100%">
      <v-card-title style="display: flex; justify-content: space-between">
        <span>انشاء تذكرة جديده للطلب</span>
        <v-icon
          color="#3F0E40"
          @click="$store.state.openAddTaskForRequest = false"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-textarea
                label="المحتوي"
                v-model="content"
                required
                outlined
              ></v-textarea>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-select
                label="مستلم التذكرة"
                class="my-0"
                :items="items"
                solo
                v-model="recieve"
                item-text="name"
                item-value="id"
                required
                outlined
                @change="reChangeContent"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" variant="text" @click="saveTaskForRequest">
          اضافة
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>
    
    <script>
import { axiosInstance } from "@/axios/config";
export default {
  data() {
    return {
      recieve: null,
      content: null,
      items: null,
      greeting: null,
    };
  },

  mounted() {
    this.getRecievedUsers();
  },

  methods: {
    saveTaskForRequest() {
      let body = new FormData();
      body.append("request_id", this.$store.state.tasksRequestId);
      body.append("task_content", this.content);
      body.append("received_id", this.recieve);
      axiosInstance
        .post("employee/admin/AddNewTask", body)
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },

    reChangeContent(selectedId) {
      const selectedUser = this.items.find((item) => item.id === selectedId);
      if (selectedUser && this.greeting.length > 0) {
        this.content = this.greeting + " - " + selectedUser.name;
      }
    },

    getRecievedUsers() {
      if(localStorage['role'] == 1){
        axiosInstance.get("agent/Task/Receivers", {headers: this.request_headers()}).then(res=> {
          this.items = res.data.payload;
          this.items.map((item) => {
            item["color"] = "red";
            item["text"] = item["name"];
            return item;
          });
        })
      }else{
        this.adminRecievedUsers();
      }
    },

    adminRecievedUsers(){
      axiosInstance
        .get(`employee/admin/TaskReceivers/${this.$store.state.tasksRequestId}`)
        .then((res) => {
          this.items = res.data.payload.allUsers;
          this.greeting = res.data.payload.greeting;
          this.items.map((item) => {
            item["color"] = "red";
            item["text"] = item["name"];
            return item;
          });
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
  },

};
</script>
  
  
    <style lang="scss">
.log-page {
  .v-timeline-item__divider {
    z-index: 1;
  }

  .theme--light.v-timeline::before {
    border: 2px solid #3f0e40;
    margin-top: 23px;
    height: calc(100% - 63px);
    opacity: 0.5;
  }

  .logDate {
    position: absolute;
    right: -45px;
    color: #444b5a;
    font-size: 12px;
  }

  .info-dad {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .v-application--is-rtl .v-timeline--dense:not(.v-timeline--reverse)::before {
    //background-color: #4516466c !important;
    z-index: 1;
    width: 3px !important;
    margin-right: 1px;
  }

  .theme--light.v-timeline .v-timeline-item__dot {
    //border: 2px solid #3F0E40 !important;
    margin-top: 18px;

    & > div {
      background-color: white !important;
    }
  }
}

@media (max-width: 578px) {
  .log-page {
    .v-alert {
      width: 110% !important;
      transform: translateX(28px) !important;
    }

    .v-alert__content {
      span:not(:first-child) {
        font-size: 10px !important;
      }

      span:first-child {
        font-size: 13px !important;
      }
    }

    .v-timeline-item__dot {
      height: 25px !important;
      width: 25px !important;
      z-index: 1;
    }

    .v-timeline-item__inner-dot {
      display: inline-block !important;
    }
  }
}
</style>
    