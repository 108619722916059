<template>
  <div>
    <!-- <v-btn
      @click="dialog = true"
      fab
      class="mx-2 pa-0 genaralIcons"
      small
      style="left: 20px"
      :color="$vuetify.theme.themes.light.BtnBKColor"
      depressed
      width="35"
      height="35"
      title="بحث"
    >
      <SearchIcon />
    </v-btn> -->

    <v-btn
      @click="dialogOfCreating = true"
      fab
      class="mx-2 pa-0 genaralIcons"
      small
      style="left: 80px; background-color: white"
      :color="$vuetify.theme.themes.light.BtnBKColor"
      depressed
      width="35"
      height="35"
      title="اضافة سلة جديده"
    >
      <img
        src="@/assets/icons/add-icon-svgrepo-com.svg"
        width="35"
        height="35"
      />
    </v-btn>

    <!-- search -->
    <!-- <v-dialog v-model="dialog" persistent width="60%">
      <v-card style="padding: 10px">
        <v-card-title>البحث</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-autocomplete
                :items="mortgageSettingItems"
                label="نوع الاعدادات"
                v-model="type_of_setting"
                outlined
                item-text="value"
                item-value="id"
                dense
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title
                      :id="attrs['aria-labelledby']"
                      v-text="item['value']"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-autocomplete
                :items="['غير نشط', 'نشط']"
                label="حالة الاعدادات"
                v-model="active_or_not"
                outlined
                dense
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title
                      :id="attrs['aria-labelledby']"
                      v-text="item"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
            الغاء
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="handleSearch">
            بحث
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- add new mortgage settings -->

    <v-dialog v-model="dialogOfCreating" persistent width="60%">
      <v-card style="padding: 10px">
        <v-card-title>أضافة سلة جديدة</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="اسم السلة"
                v-model="name_of_basket_in_creating"
                outlined
                dense
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="مسار السلة"
                v-model="path_of_basket_in_creating"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogOfCreating = false"
          >
            الغاء
          </v-btn>
          <v-btn color="green" variant="text" @click="addNewSetting">
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit  mortgage settings -->
    <v-dialog
      v-model="$store.state.open_edit_dialog_mortgage_settings"
      persistent
      width="60%"
    >
      <v-card style="padding: 10px">
        <v-card-title>تعديل اعدادات السلة</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="اسم السلة"
                v-model="edited_setting.basket_name"
                outlined
                dense
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="مسار السلة"
                v-model="edited_setting.basket_path"
                outlined
                dense
              >
              </v-text-field>
            </v-col>

          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="$store.state.open_edit_dialog_mortgage_settings = false"
          >
            الغاء
          </v-btn>
          <v-btn color="green" variant="text" @click="updateSetting">
            تعديل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { SearchIcon } from "@/assets/adminIcons/searchIcon";
import { axiosInstance } from "@/axios/config";
export default {
  components: {
    // SearchIcon,
  },
  data() {
    return {
      dialog: false,
      dialogOfCreating: false,
      type_of_setting: "",
      active_or_not: "",

      name_of_basket_in_creating: "",
      path_of_basket_in_creating: "",

      edited_setting: {},

      mortgageSettingItems:[],
    };
  },

  watch: {
    "$store.state.id_of_mortgage_settings": {
      handler() {
        this.openSettingToUpdate();
      },
    },
  },

  methods: {
    handleSearch() {
      this.dialog = false;
      const body = new FormData();
      body.append("type_of_setting", this.type_of_setting);

      if (this.active_or_not === "نشط") {
        body.append("active_or_not", 1);
      } else if (this.active_or_not === "غير نشط") {
        body.append("active_or_not", 0);
      }

      axiosInstance
        .post(`/employee/admin/all-mortgage-settings`, body)
        .then((res) => {
          this.$emit("filterData", res.data.payload);
        });
    },

    addNewSetting() {
      const body = new FormData();
      body.append("basket_name", this.name_of_basket_in_creating);
      body.append("basket_path", this.path_of_basket_in_creating);
      axiosInstance
        .post(`/employee/admin/add-Basket-settings-data`, body)
        .then((res) => {
          this.dialogOfCreating = false;
          this.$emit("settingsIsAdded");
          this.showPopup("success", res.data.message);

          this.name_of_basket_in_creating = "";
          this.path_of_basket_in_creating = "";
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },

    openSettingToUpdate() {
      axiosInstance
        .get(
          `/employee/admin/edit-Basket-settings-data/${this.$store.state.id_of_mortgage_settings}`,
          { headers: this.request_headers() }
        )
        .then((res) => {
          console.log(res.data.payload.data)
          this.edited_setting = res.data.payload.data;
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },

    updateSetting() {
      const body = new FormData();
      body.append("basket_name", this.edited_setting.basket_name);
      body.append("basket_path", this.edited_setting.basket_path);
      axiosInstance
        .post(`/employee/admin/update-Basket-settings-data/${this.$store.state.id_of_mortgage_settings}`, body)
        .then((res) => {
          this.$store.state.open_edit_dialog_mortgage_settings = false;
          this.$emit("settingsIsAdded");
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },
  },

};
</script>

<style>
.genaralIcons {
  position: absolute;
  top: -50px;
  min-width: unset;
  z-index: 5;
}
</style>

