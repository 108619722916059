import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyDYsB2PevvI0sVO1u9LHxwr56MDUtoEX7g",
  authDomain: "alwsata-app.firebaseapp.com",
  databaseURL: "https://alwsata-app-default-rtdb.firebaseio.com",
  projectId: "alwsata-app",
  storageBucket: "alwsata-app.appspot.com",
  messagingSenderId: "1032367025637",
  appId: "1:1032367025637:web:338736ecc3cfea305c130c",
  measurementId: "G-S7VES0NL3F"
};


firebase.initializeApp(config);

export default firebase;

