export const SimplifyNumber = (number) => {
    if (typeof number !== 'number') {
        return "";
    }

    const suffixes = ['', 'k', 'M', 'B', 'T']; // Suffixes for thousands, millions, billions, and trillions

    // Determine the appropriate suffix based on the number of digits
    let suffixIndex = 0;
    while (number >= 1000 && suffixIndex < suffixes.length - 1) {
        number /= 1000;
        suffixIndex++;
    }

    // Check if the decimal part is zero and remove it if necessary
    const decimalPart = number % 1;
    const formattedNumber = (decimalPart === 0) ? number.toFixed(0) : number.toFixed(1);

    // Remove trailing zeros and decimal point if the decimal part is zero
    const formattedNumberWithoutDecimal = formattedNumber.replace(/\.0+$/, '');

    return `${formattedNumberWithoutDecimal}${suffixes[suffixIndex]}`;
};


