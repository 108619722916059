<template functional>
  <div v-bind:class="{ 'mt-5': props.margin }" :style="`max-width:${props.width}`">
    <v-toolbar flat dense color="transparent" class="mb-2" v-if="props.title || props.icon">
      <v-subheader v-if="props.title">
        <v-icon v-if="props.icon" small>{{props.icon}}</v-icon> <span v-bind:class="{'ml-2': props.icon}">{{props.title}}</span>
      </v-subheader>
      <v-spacer></v-spacer>
      <slot name="actions"/>
    </v-toolbar>
    <div v-if ="props.loading" class="progress d-flex align-center justify-center" style="min-height: 50px">
      <v-progress-circular size="30" color="primary" indeterminate></v-progress-circular>
    </div>
    <slot v-else/>
  </div>
</template>
<script>
export default {
}
</script>
<style scoped>
  .v-toolbar--dense .v-toolbar__content {
    padding-left: 0px !important;
  }
  .v-toolbar .v-btn {
    opacity: 0.7 !important;
  }
  .v-subheader {
    padding: 0px;
    margin: 10px 0px 0px 0px;
    font-size: 18px !important;
  }
  .v-subheader .v-icon {
    font-size: 20px !important;
    margin-right: 5px !important;
  }
</style>
