<template>
  <div>
    <div class="containerTextInOrder">تحديث الطلب :</div>
    <v-data-table :headers="headers" :items="data" :hide-default-footer="true"></v-data-table>
  </div>
</template>

<style>
.containerTextInOrder {
  font-size: 25px;
  margin: 26px 0 20px 0 !important;
}
</style>

<script>
import Axios from "axios";
import {formatDate} from "@/mixins/formatDate";

export default {
  data() {
    return {
      headers: [
        {text: 'المحتوى', value: 'المحتوى', align: "center"},
        {text: 'التاريخ', value: 'التاريخ', align: "center"},
        {text: 'الملاحظة', value: 'الملاحظة', align: "center"},
      ],
      data: [],
      rowOne: '--',
      rowTwo: '--',
      rowThree: '--',
      rowFour: '--',
    };
  },

  methods: {
    setTableData(items) {

      if (items.alternative_user_id === null || items.alternative_user_id === '') {
        if (items.history.hr_id != null) {
          if (items.history.hr_accept_or_no == 0) {
            this.rowOne = `تم رفض الطلب من مسئول الموارد البشريه`
          } else {
            this.rowOne = `تم قبول الطلب من مسئول الموارد البشريه`
          }
        } else {
          this.rowOne = `فى انتظار موافقه مسئول الموارد البشريه`
        }

        if (items.history.hr_accept_or_no == 1) {
          if (items.history.general_director_id != null) {
            if (items.history.general_director_accept_or_no == 0) {
              this.rowTwo = `تم رفض الطلب من المدير العام`
            }else {
              this.rowTwo = `تم قبول الطلب من المدير العام`
            }
          }else {
            this.rowTwo = `فى انتظار موافقه المدير العام`
          }
        }

        this.data = [
           {
            'المحتوى': this.rowOne,
            'التاريخ': formatDate(items.history.hr_date),
            'الملاحظة': items.history.hr_note
          },
        ];

        (items.history.hr_accept_or_no == 1) && this.data.push({
              'المحتوى': this.rowTwo,
              'التاريخ': formatDate(items.history.general_director_date),
              'الملاحظة': items.history.general_director_note
            })

      } else {

        if (items.accepted_date) {
          if (items.accept_or_no == 0) {
            this.rowOne = `تم رفض الطلب من الطرف الاخر`
          } else {
            this.rowOne = `تم قبول الطلب من الطرف الاخر`
          }
        } else {
          this.rowOne = `فى انتظار موافقه الطرف الاخر`
        }

        if (items.history.manager_id != null) {
          if (items.history.manager_accept_or_no == 0) {
            this.rowTwo = `تم رفض الطلب من المدير المباشر`
          } else {
            this.rowTwo = `تم قبول الطلب من المدير المباشر`
          }
        } else {
          this.rowTwo = `فى انتظار موافقه المدير المباشر`
        }

        if (items.history.manager_accept_or_no == 1) {
          if (items.history.hr_id != null) {
            if (items.history.hr_accept_or_no == 0) {
              this.rowThree = `تم رفض الطلب من مسئول الموارد البشريه`
            } else {
              this.rowThree = `تم قبول الطلب من مسئول الموارد البشريه`
            }
          } else {
            this.rowThree = `فى انتظار موافقه مسئول الموارد البشريه`
          }
        }

        if (items.history.hr_accept_or_no == 1) {
          if (items.history.general_director_id != null) {
            if (items.history.general_director_accept_or_no == 0) {
              this.rowFour = `تم رفض الطلب من المدير العام`
            } else {
              this.rowFour = `تم قبول الطلب من المدير العام`
            }
          } else {
            this.rowFour = `فى انتظار موافقه المدير العام`
          }
        }

        this.data = [
          {
            'المحتوى': this.rowOne,
            'التاريخ': formatDate(items.accepted_date),
            'الملاحظة': '--'
          }, {
            'المحتوى': this.rowTwo,
            'التاريخ': formatDate(items.history.manager_date),
            'الملاحظة': items.history.manager_note
          }, {
            'المحتوى': this.rowThree,
            'التاريخ': formatDate(items.history.hr_date),
            'الملاحظة': items.history.hr_note
          },

        ]

        items.history?.hr_accept_or_no == 1 && this.data.push({
          'المحتوى': this.rowFour,
          'التاريخ': formatDate(items.history.general_director_date),
          'الملاحظة': items.history.general_director_note
        })
      }


    },

    getOrderVacation() {
      const urlPath = window.location.pathname.split('/')
      const id = urlPath.splice(-1)

      let url = ``;
      if(this.$router.history.current.params.isAgent){
        url = `${this.$store.state.url}/agent/vacation_details/${id}`
      }else{
         url = `${this.$store.state.url}/employee/admin/vacationHistory/${id}`
      }
      Axios.get(url, {headers: this.request_headers()}).then(res => {
        if(this.$router.history.current.params.isAgent){
          this.setTableData(res.data.payload[0])
        }else{
        this.setTableData(res.data.payload)
        }
      })
    }
  },

  created() {
    this.getOrderVacation();
  }
};
</script>
