<template>
  <div class="text-center">
    <v-btn color="primary" @click="dialog = true" class="open__dialog">
      اضافه مهمه
    </v-btn>
    <v-dialog v-model="dialog" width="60%">
      <v-card>
        <v-card-title> محتوي التذكره </v-card-title>
        <v-card-text>
          <v-textarea
            auto-grow
            variant="outlined"
            rows="3"
            row-height="25"
            v-model="taskBody"
            shaped
          ></v-textarea>
        </v-card-text>

        <v-col cols="12" sm="12" md="12">
          <v-autocomplete
            :items="receiver"
            :item-title="receiver.name"
            label="المستلم"
            v-model="reciverValue"
            item-text="name"
            return-object
            outlined
            dense
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-title
                  :id="attrs['aria-labelledby']"
                  v-text="item.name"
                ></v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
        <v-card-actions>
          <v-btn color="primary" @click="dialog = false">الغاء</v-btn>
          <v-btn color="success" @click="addTaske">اضافه</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { axiosInstance } from "@/axios/config";
import router from "@/routes";
import { ref, onMounted, watch, defineEmits } from "vue";

const emit = defineEmits(["reload"]);

const dialog = ref(false);

const receiver = ref([]);
const getReceiver = () => {
  axiosInstance
    // .get(`/employee/admin/TaskReceivers/${router.currentRoute.params.id}`)
    .get(`/agent/Task/Receivers`)
    .then((res) => {
      // receiver.value = res.data.payload.allUsers;
      receiver.value = res.data.payload;
    });
};

onMounted(() => {
  getReceiver();
});

const reciverValue = ref(null);
const taskBody = ref("");
watch(reciverValue, (current) => {
  const currentHour = new Date().getHours();
  const greeting = currentHour >= 0 && currentHour < 12 ? "صباح الخير" : "مساء الخير";
  taskBody.value = greeting + " " + current.name;
});

const addTaske = () => {
  dialog.value = false;
  let body = {
    request_id: router.currentRoute.params.id,
    task_content: taskBody.value,
    received_id: reciverValue.value.id,
  };

  axiosInstance.post(`/employee/admin/AddNewTask`, body).then((res) => {
    emit("reload");
  }).catch((err) => {
      emit("getErrors",err.response.data.message);
      // this.showPopup("error", err.response.data.message);
  });
};
</script>

<style>
.open__dialog {
  margin: 40px;
}
</style>
