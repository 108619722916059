<template>
  <v-row justify="center">
    <v-dialog
        v-model="this.$store.state.ValidationAsDialog"
        persistent
        width="624"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5" style="margin-bottom: 30px">تنبية </span>
        </v-card-title>
        <v-card-text>
            <v-row>
                
            <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="my-2">
              <span class="mb-2 " style=" font-size: 18px; color: red; ">
                {{$store.state.ValidationAsDialogMessage}}
              </span>
            </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue-darken-1"
              variant="text"
              @click="$store.state.ValidationAsDialog = false, $store.state.ValidationAsDialogMessage = '';"
          >
          اغلاق
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Axios from "axios";

export default {
  name: 'ValidationAsDialog',

  data(){
    return{
    }
  },

  methods: {
    
    send_payment() {
      let req_id = "";
        this.loading = true;
        req_id = parseInt(this.reqId);
        const formData = new FormData();
        formData.append("req_id", req_id);
        formData.append("sendTo", this.sendTo);
        formData.append("note", this.note);
        Axios.post(
          "https://appproduct.alwsata.com.sa/api/v2/employee/Requests/send-prepayment",
          formData,
          {
            headers: this.request_headers(),
          }
        )
          .then(() => {
            this.showPopup(
              "success",
              "تم ارسال الدفعة بنجاح"
            );
            this.refresh_request_details();
          })
          .catch((err) => {
            this.showPopup("error", err.response.data.message);
          })
          .finally(()=>{
            this.$store.state.ValidationAsDialog = false;
          });
      
    },
  },

}
</script>