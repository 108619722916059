<template>
  <div>
    <GroupProccess
      :selectedItem="selected_requests"
      @groupArchived="handleGroupArchived"
    />
    <div class="users-header">
      <div class="users-header__container">
        {{ text }}
      </div>

      <v-col
        cols="12"
        sm="6"
        md="2"
        style="margin-top: -3px"
        v-if="buttonText == 'إضافه مستخدم'"
      >
      </v-col>
      <div class="text-center right_bouttons filterContainer" v-else>
        <v-btn
          @click="handleAddUser"
          class="ma-2 white--text"
          :style="{
            'background-color': '#3F0E40',
            'min-width': '205px',
          }"
        >
          <span :style="{ color: 'white' }">{{ buttonText }}</span>
        </v-btn>
      </div>
    </div>
    <div v-if="!openAddUser" class="pageContainer">
      <div class="filterContainer" style="margin-bottom: 21px; height: 58px">
        <div style="display: flex">
          <div class="text-center right_bouttons" style="position: relative">
            <v-btn
              class="ma-2 white--text filter__border"
              @click="handleAllUsers"
              :style="{
                'background-color': switchColor.gitAllUsers ? '#3F0E40' : 'white',
              }"
            >
              <span :style="{ color: switchColor.gitAllUsers ? 'white' : '#3F0E40' }">
                الكل
              </span>

              <div :class="[switchColor.gitAllUsers && 'down__arrow']"></div>
            </v-btn>
          </div>
          <div class="text-center right_bouttons filterContainer">
            <v-btn
              @click="handleActiveUsers"
              class="ma-2 white--text filter__border"
              :style="{
                'background-color': switchColor.activeUsers ? '#3F0E40' : 'white',
              }"
            >
              <span :style="{ color: switchColor.activeUsers ? 'white' : '#3F0E40' }">
                النشطين
              </span>

              <div :class="[switchColor.activeUsers && 'down__arrow']"></div>
            </v-btn>
          </div>
          <div class="text-center right_bouttons filterContainer">
            <v-btn
              @click="handleNotActiveUsers"
              class="ma-2 white--text filter__border"
              :style="{
                'background-color': switchColor.isNotActive ? '#3F0E40' : 'white',
              }"
            >
              <span :style="{ color: switchColor.isNotActive ? 'white' : '#3F0E40' }">
                الغير نشطين
              </span>
              <div :class="[switchColor.isNotActive && 'down__arrow']"></div>
            </v-btn>
          </div>
          <div class="text-center right_bouttons filterContainer">
            <v-btn
              @click="handleArchivedUsers"
              class="ma-2 white--text filter__border"
              :style="{
                'background-color': switchColor.archived ? '#3F0E40' : 'white',
              }"
            >
              <span :style="{ color: switchColor.archived ? 'white' : '#3F0E40' }">
                المؤرشفين</span
              >
              <div :class="[switchColor.archived && 'down__arrow']"></div>
            </v-btn>
          </div>
          <div class="text-center right_bouttons filterContainer">
            <v-btn
              class="ma-2 white--text filter__border"
              @click="handleHelperUsers"
              :style="{
                'background-color': switchColor.helpers ? '#3F0E40' : 'white',
              }"
            >
              <span :style="{ color: switchColor.helpers ? 'white' : '#3F0E40' }">
                المتعاونين</span
              >
              <div :class="[switchColor.helpers && 'down__arrow']"></div>
            </v-btn>
          </div>
        </div>

        <v-col cols="3" sm="3" md="3">
          <SelectInput
            v-if="openSalesAgentsFilter"
            @selectedValues="getSelectedValueAgent"
            :originalItems="resultType"
            label="استشاري المبيعات"
          />
        </v-col>

        <div style="display: flex">
          <div style="margin: 10px 10px">
            <v-autocomplete
              :items="userType"
              label="اختر نوع المستخدم"
              v-model="workName"
              @change="handleSelectedWorkName"
              style="height: 48px"
              outlined
              dense
            >
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-title
                    :id="attrs['aria-labelledby']"
                    v-text="item.name"
                  ></v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>

            <div style="position: relative; width: auto; height: auto; margin-top: -54px">
              <svg
                style="position: absolute; width: 20px; top: 17px; left: 8"
                xmlns="http://www.w3.org/2000/svg"
                class="ionicon __web-inspector-hide-shortcut__"
                viewBox="0 0 512 512"
              >
                <path
                  d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z"
                />
              </svg>
            </div>
          </div>
          <div style="margin: 10px 10px; height: 50px" v-if="appearSearch">
            <v-text-field label="ابحث هنا" v-model="search" outlined dense>
            </v-text-field>
          </div>
          <div
            class="container__addUser"
            style="justify-content: center"
            @click="appearSearch = !appearSearch"
          >
            <v-icon style="color: white; font-size: 27px">mdi-magnify</v-icon>
          </div>
          <div
            class="container__addUser"
            style="padding-right: 4px"
            @click="handleAddUser"
          >
            <v-icon left style="color: white; font-size: 27px">mdi-plus</v-icon>
          </div>
        </div>
      </div>

      <table-component
        :showSelect="true"
        itemKey="id"
        :selectedRows.sync="selected_requests"
        :headers="headers"
        :pagination="pagination"
        :limit="$store.state.counterOfShow"
        :items="tableData"
        :loading="loading"
        v-model="page"
      >
        <template v-slot:[`item.allow_recived`]="{ item }">
          <span
            :class="{
              text__success: item['allow_recived'] === 'نشط',
              text__faild: item['allow_recived'] !== 'نشط',
            }"
            >{{ item.allow_recived }}</span
          >
        </template>

        <template v-slot:[`item.stop_recieveing_automatic`]="{ item }">
          <td style="display: flex; justify-content: center; align-items: center">
            <v-switch
              v-if="item.role == 0"
              v-model="item.stop_recieveing_automatic"
              color="primary"
              disabled
            ></v-switch>
          </td>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="rgb(63, 14, 64)"
                small
                depressed
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <SingleProccess
              :item="item"
              @recived="handleRecived"
              @switchUser="handleSwitchUser"
              @archived="handleArchived"
            />
          </v-menu>
        </template>
      </table-component>
    </div>
    <div v-else><AddNewUser @saveUser="handleSaveUser" /></div>

    <v-snackbar
      v-model="active"
      :color="color"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <div>
        <span class="font-weight-bold" style="font-size: 16px">{{ message }}</span>
      </div>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-row justify="center">
      <v-dialog v-model="dialog" width="800">
        <v-card>
          <v-card-title>
            <span>{{ dialogText }}</span>
          </v-card-title>
          <v-card-text> </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green-darken-1" variant="text" @click="dialog = false">
              الغاء
            </v-btn>
            <v-btn color="green-darken-1" variant="text" @click="handleOpenConfirm">
              تأكيد
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { axiosInstance } from "@/axios/config";
import SingleProccess from "./SingleProccess.vue";
import AddNewUser from "./AddNewUser.vue";
import SelectInput from "@/components/Charts/SelectInput.vue";

const switchColor = ref({
  gitAllUsers: true,
  activeUsers: false,
  isNotActive: false,
  archived: false,
  helpers: false,
});
const openAddUser = ref(false);
const openSalesAgentsFilter = ref(false);
const addNewUser = ref(false);
const search = ref("");

watch(search, (current) => {
  body.value["search"] = current;
  getAllData(1);
});
watch(
  () => store.state.counterOfShow,
  (current) => {
    body.value["per_page"] = current;
    getAllData(1);
  }
);

const page = ref(1);
watch(page, (current) => {
  getAllData(current);
});

const selected_requests = ref([]);

const buttonText = ref("إضافه مستخدم");
const text = ref("المستخدمين");

const handleAddUser = () => {
  addNewUser.value = !addNewUser.value;
  openAddUser.value = !openAddUser.value;
  if (addNewUser.value) {
    buttonText.value = "إلغاء والعودة لجميع المستخدمين";
    text.value = "أضافه مستخدم";
  } else {
    buttonText.value = "إضافه مستخدم";
    text.value = "المستخدمين";
  }
};
const userType = ref([]);

const headers = ref([
  { text: " الأسم", value: "name", align: "center" },
  { text: "المسمي الوظيفي", value: "job_title", align: "center" },
  { text: "الجوال", value: "mobile", align: "center" },
  { text: "اسم المستخدم ", value: "username", align: "center" },
  { text: "حاله المستخدم", value: "allow_recived", align: "center" },
  { text: "الإيميل", value: "email", align: "center" },
  { text: "تاريخ الانشاء", value: "created_at", align: "center" },
  { text: "ايقاف أتوماتيك", value: "stop_recieveing_automatic", align: "center" },
  { text: "العمليات", value: "action", align: "center" },
  { text: " الأسم عند الادارة", value: "name_for_admin", align: "center" },
]);

const tableData = ref([]);
const pagination = ref({});
const loading = ref(false);
const body = ref({});
const appearSearch = ref(false);

const getAllData = (page) => {
  loading.value = true;

  axiosInstance.post(`/v2/employee/all-users?page=${page}`, body.value).then((res) => {
    pagination.value = {
      limit: 3,
      count: res.data.payload.count_of_search,
    };
    tableData.value = res.data.payload.data;
    loading.value = false;
  });
};


const resultType = ref([]);
const getResultType = () => {
  axiosInstance.get(`/employee/admin/getUsersBasedOnRole/0`).then((res) => {
    resultType.value = res.data.message.map((item) => {
      item["color"] = "red";
      item["text"] = item["name"];
      item["value"] = item["id"];
      return item;
    });
  });
};

// const resultType = ref([]);
// const getResultType = () => {
//   let body = new FormData();
//   axiosInstance.post(`/employee/admin/filterUsers`,body).then((res) => {
//     resultType.value = res.data.payload.map((item) => {
//       item["color"] = "red";
//       item["text"] = item["name"];
//       item["value"] = item["id"];
//       return item;
//     });
//   });
// };

const selectedAgents = ref([]);
const getSelectedValueAgent = (val) => {
  selectedAgents.value = val;
  if (selectedAgents.value.length) {
    body.value.salesAgents = [];
    selectedAgents.value.forEach((item, index) => {
      // body.value.append([`salesAgents[${index}]`],item["id"]);
      body.value.salesAgents[index] = item["id"];
    });
  }else{
    delete body.value.salesAgents;
  }

  getAllData(1);
};

const getWorkName = () => {
  axiosInstance.get(`/employee/admin/announcements/allRoles`).then((res) => {
    userType.value = res.data.payload.map((item) => {
      item["color"] = "red";
      item["text"] = item["name"];
      return item;
    });
  });
};

const workName = ref([]);
const handleSelectedWorkName = () => {
  body.value["role"] = workName.value;
  getAllData(1);
};

const handleAllUsers = () => {
  const result = switchColor.value;
  for (let key in result) {
    result[key] = false;
  }
  switchColor.value.gitAllUsers = true;
  delete body.value["status"];
  openSalesAgentsFilter.value = false;
  getAllData(1);
};

const handleActiveUsers = () => {
  const result = switchColor.value;
  for (let key in result) {
    result[key] = false;
  }
  switchColor.value.activeUsers = true;
  body.value["status"] = 1;
  openSalesAgentsFilter.value = false;
  getAllData(1);
};

const handleNotActiveUsers = () => {
  const result = switchColor.value;
  for (let key in result) {
    result[key] = false;
  }
  switchColor.value.isNotActive = true;
  body.value["status"] = 0;
  openSalesAgentsFilter.value = false;
  getAllData(1);
};

const handleArchivedUsers = () => {
  const result = switchColor.value;
  for (let key in result) {
    result[key] = false;
  }
  switchColor.value.archived = true;
  body.value["status"] = 2;
  openSalesAgentsFilter.value = false;
  getAllData(1);
};

const handleHelperUsers = () => {
  const result = switchColor.value;
  for (let key in result) {
    result[key] = false;
  }
  switchColor.value.helpers = true;
  openSalesAgentsFilter.value = true;
  body.value["status"] = 4;
  getAllData(1);
};

const handleRecived = () => {
  active.value = true;
  message.value = "تم التحديث بنجاح";
  color.value = "green";
  getAllData(1);
};

const active = ref(false);
const message = ref("");
const color = ref("");
const handleSaveUser = (body) => {
  const formData = new FormData();

  for (const key in body) {
    if ({}.hasOwnProperty.call(body, key)) {
      formData.append(key, body[key]);
    }
  }

  axiosInstance
    .post(`/employee/admin/register`, formData)
    .then((res) => {
      active.value = true;
      message.value = "تمت الاضافه بنجاح";
      color.value = "green";

      getAllData(1);
      setTimeout(() => {
        openAddUser.value = false;
        buttonText.value = "إضافه مستخدم";
        text.value = "المستخدمين:";
      }, 1000);
    })
    .catch((err) => {
      active.value = true;
      message.value = err.response.data.message;
      color.value = "red";
    });
};

const dialog = ref(false);
const id = ref(null);
const dialogText = ref(``);

const handleSwitchUser = (val) => {
  dialogText.value = `هل أنت متأكد من تسجيل الدخول بهذا الحساب؟`;
  dialog.value = true;
  id.value = val;
};

const handleArchived = (val) => {
  dialogText.value = `هل أنت متأكد من ارشفه بهذا الحساب؟`;
  dialog.value = true;
  id.value = val;
};

const handleGroupArchived = () => {
  dialogText.value = `هل أنت متأكد من ارشفه هذه  الطلبات؟`;
  dialog.value = true;
};

const handleArchiveUser = () => {
  dialog.value = false;
  const body = new FormData();
  body.append("users_ids[0]", id.value);
  getAllData(1);
  axiosInstance.post(`/v2/employee/archive-users`, body).then((res) => {
    active.value = true;
    message.value = "تمت الارشفه بنجاح";
    color.value = "green";
  });
};

const handleGroupArchivedUsers = () => {
  dialog.value = false;
  let body = new FormData();

  selected_requests.value.forEach((item, index) => {
    body.append(`users_ids[${index}]`, item["id"]);
  });
  getAllData(1);
  axiosInstance.post(`/v2/employee/archive-users`, body).then((res) => {
    active.value = true;
    message.value = "تمت الارشفه بنجاح";
    color.value = "green";
  });
};

const handleOpenConfirm = () => {
  if (dialogText.value == `هل أنت متأكد من تسجيل الدخول بهذا الحساب؟`) {
    handleAgreeToSwitch();
  } else if (dialogText.value == `هل أنت متأكد من ارشفه بهذا الحساب؟`) {
    handleArchiveUser();
  } else if (dialogText.value == `هل أنت متأكد من ارشفه هذه  الطلبات؟`) {
    handleGroupArchivedUsers();
  }
};

import router from "@/routes";
import { store } from "@/store";
import GroupProccess from "./GroupProccess.vue";
const handleAgreeToSwitch = () => {
  dialog.value = false;
  axiosInstance.get(`/v2/employee/swith-logged-user/${id.value}`).then((res) => {
    if (res.data.payload.role !== 4) {
      store.state.loggedIn = false;
      let adminIdBeforeSwitched = localStorage["id"];
      let roleBeforeSwitched = localStorage["role"];


      localStorage.clear();
      document.cookie =
        `wsataJwt=;expires=expires=Sat, 20 Jan 1980 12:00:00 UTC;path=/;domain=` +
        window.location.hostname +
        `;`;

      let expireTime = new Date();
      expireTime = expireTime.setHours(expireTime.getHours() + 12);
      document.cookie =
        `wsataJwt=${JSON.stringify(res.data.payload)};expires=${Date(
          expireTime
        )};path=/;domain=` +
        window.location.hostname +
        `;`;
      localStorage.setItem("role", res.data.payload.role);
      localStorage.setItem("adminIdBeforeSwitched", adminIdBeforeSwitched);
      localStorage.setItem("roleBeforeSwitched", roleBeforeSwitched);

      localStorage.setItem("userIsSwitched", true);

      localStorage.setItem("avatar", res.data.payload.avatar);
      localStorage.setItem("loggedIn_user_name", res.data.payload.agent_name);

      document.querySelectorAll("header").forEach((head) => {
        head.style.display = "block";
      });
      window.location.reload();
      store.state.loggedIn = true;

      if (localStorage.getItem("role") == "1") {
        // localStorage.setItem("salesManagerIdBeforeSwitched", res.data.payload.id);
        // localStorage.setItem("roleBeforeSwitched", res.data.payload.role);
        router.push("/salesManager/requests/all");
      } else if (localStorage.getItem("role") == "0") {
        router.push("/agent/requests/all");
      } else if (localStorage.getItem("role") == "7") {
        router.push("/admin");
      } else if (localStorage.getItem("role") == "5") {
        router.push("/quality/requests/all");
      } else {
        router.push("/");
      }
      window.location.reload();
    }
  });
};

onMounted(() => {
  getAllData(1);
  getWorkName();
  getResultType();
});
</script>

<style>

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot{
  margin-top: 0px !important;
  padding: 10px !important;
}

.users-header__container {
  font-family: Cairo;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 135.5%;
}
.users-header {
  display: flex;
  justify-content: space-between;
  margin: 20px 5px;
}
.filterContainer {
  display: flex;
  justify-content: space-between;
}

.main__table .v-input__slot {
  border: none !important; /*Remove the border*/
}

.filter__border {
  height: 48px !important;
  font-size: 18px !important;
  border: 1px solid rgb(63, 14, 64);
}

.container__addUser {
  width: 50px;
  height: 50px;
  background: rgb(63, 14, 64);
  margin: 10px 5px;
  border-radius: 7px;
  display: flex;
}

.filterContainer
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  background-color: white;
  border: none;
}

.pageContainer .v-btn:not(.v-btn--round).v-size--small {
  min-width: auto !important;
}

.pageContainer .v-data-table th .v-icon {
  color: white;
}

.pageContainer .v-data-table__wrapper {
  border-radius: 12px 12px 0 0;
}

.text__success {
  color: green;
}

.text__faild {
  color: red;
}

.pageContainer #adminFilter .v-text-field--outlined.v-input--has-state fieldset,
.v-text-field--outlined.v-input--is-focused fieldset {
  height: 50px;
}

.filterContainer
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  height: 50px;
}

.pageContainer .v-simple-checkbox {
  margin-top: 10px;
}

.down__arrow {
  position: absolute;
  bottom: -19px;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid rgb(63, 14, 64);
  transform: translateX(-50%);
}
</style>
