import { render, staticRenderFns } from "./rateAverageReport.vue?vue&type=template&id=fda207ae&"
import script from "./rateAverageReport.vue?vue&type=script&lang=js&"
export * from "./rateAverageReport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports