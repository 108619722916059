<template>
  <v-card style="border-radius: 4px !important; border: none !important">
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
      :loading="loading"
    >
      <template v-slot:[`item.التحكم`]="{ item }">
        <p @click="test(item)" style="text-align: center; margin-top: 10px">
          <span v-if="item.التحكم">
            <a :href="item.التحكم" style="text-decoration: none">
              <img
                src="../../assets/document-download-icon.svg"
                width="20px"
                height="30px"
                alt="image"
              />
            </a>
          </span>
          <span v-else>
            <LoadingMore />
          </span>
        </p>
      </template>
      <template v-slot:[`item.report_pdf`]="{ item }">
        <p @click="test(item)" style="text-align: center; margin-top: 10px">
          <span v-if="item.report_pdf">
            <a :href="item.report_pdf" style="text-decoration: none">
              <img
                src="../../assets/document-download-icon.svg"
                width="20px"
                height="30px"
                alt="pdf"
              />
            </a>
          </span>
          <span v-else>
            <LoadingMore />
          </span>
        </p>
      </template>

      <template v-slot:[`body.append`]="{ items }" v-if="total === 'report'">
        <tr v-if="type === 'requestClassificaion'">
          <td style="margin-right: 17px">المجموع</td>
          <td v-if="headers.findIndex((res) => res.value === 'bdon-tsnyf') !== -1">
            {{ calculateTotal(items, "bdon-tsnyf", counter["bdon-tsnyf"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'bhag-llmtabaa') !== -1">
            {{ calculateTotal(items, "bhag-llmtabaa", counter["bhag-llmtabaa"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'bantthar-alaorak') !== -1">
            {{ calculateTotal(items, "bantthar-alaorak", counter["bantthar-alaorak"]) }}
          </td>
          <td
            v-if="headers.findIndex((res) => res.value === 'la-yrghb-fy-alkhdm') !== -1"
          >
            {{
              calculateTotal(items, "la-yrghb-fy-alkhdm", counter["la-yrghb-fy-alkhdm"])
            }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'ybhth-aan-aakar') !== -1">
            {{ calculateTotal(items, "ybhth-aan-aakar", counter["ybhth-aan-aakar"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'mrfod') !== -1">
            {{ calculateTotal(items, "mrfod", counter["mrfod"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'taathr-alatsal') !== -1">
            {{ calculateTotal(items, "taathr-alatsal", counter["taathr-alatsal"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'mrfoaa') !== -1">
            {{ calculateTotal(items, "mrfoaa", counter["mrfoaa"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'mktml') !== -1">
            {{ calculateTotal(items, "mktml", counter["mktml"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'lm-yrd') !== -1">
            {{ calculateTotal(items, "lm-yrd", counter["lm-yrd"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'agl-altoasl') !== -1">
            {{ calculateTotal(items, "agl-altoasl", counter["agl-altoasl"]) }}
          </td>
          <td
            v-if="
              headers.findIndex((res) => res.value === 'bdon-ratb-frd-aaml-hr') !== -1
            "
          >
            {{
              calculateTotal(
                items,
                "bdon-ratb-frd-aaml-hr",
                counter["bdon-ratb-frd-aaml-hr"]
              )
            }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'lm-ynasbh-alaard') !== -1">
            {{ calculateTotal(items, "lm-ynasbh-alaard", counter["lm-ynasbh-alaard"]) }}
          </td>
          <td
            v-if="headers.findIndex((res) => res.value === 'mlgh-mn-kbl-alaamyl') !== -1"
          >
            {{
              calculateTotal(items, "mlgh-mn-kbl-alaamyl", counter["mlgh-mn-kbl-alaamyl"])
            }}
          </td>
          <td
            v-if="
              headers.findIndex((res) => res.value === 'ldyh-altzam-aakary-hdyth') !== -1
            "
          >
            {{
              calculateTotal(
                items,
                "ldyh-altzam-aakary-hdyth",
                counter["ldyh-altzam-aakary-hdyth"]
              )
            }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'krd-shkhsy') !== -1">
            {{ calculateTotal(items, "krd-shkhsy", counter["krd-shkhsy"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'akhth-mn-albnk') !== -1">
            {{ calculateTotal(items, "akhth-mn-albnk", counter["akhth-mn-albnk"]) }}
          </td>
          <td
            v-if="
              headers.findIndex((res) => res.value === 'testing-classification') !== -1
            "
          >
            {{
              calculateTotal(
                items,
                "testing-classification",
                counter["testing-classification"]
              )
            }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'srf-nthr') !== -1">
            {{ calculateTotal(items, "srf-nthr", counter["srf-nthr"]) }}
          </td>
          <td
            v-if="
              headers.findIndex((res) => res.value === 'bdon-ratb-sahb-shrk-ao-moss') !==
              -1
            "
          >
            {{
              calculateTotal(
                items,
                "bdon-ratb-sahb-shrk-ao-moss",
                counter["bdon-ratb-sahb-shrk-ao-moss"]
              )
            }}
          </td>
          <td
            v-if="
              headers.findIndex(
                (res) => res.value === 'almgmoaa-bastthnaaa-layogd-tsnyf'
              ) !== -1
            "
          >
            {{
              calculateTotal(
                items,
                "almgmoaa-bastthnaaa-layogd-tsnyf",
                counter["almgmoaa-bastthnaaa-layogd-tsnyf"]
              )
            }}
          </td>
          <td></td>
        </tr>

        <tr v-if="type === 'requestType'">
          <td style="margin-right: 17px">المجموع</td>
          <td v-if="headers.findIndex((res) => res.value === 'بدون تصنيف') !== -1">
            {{ calculateTotal(items, "بدون تصنيف", counter["bdon-tsnyf"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'بحاجة للمتابعة') !== -1">
            {{ calculateTotal(items, "بحاجة للمتابعة", counter["bhag-llmtabaa"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'بانتظار الأوراق') !== -1">
            {{ calculateTotal(items, "بانتظار الأوراق", counter["bantthar-alaorak"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'لا يرغب في الخدمة') !== -1">
            {{
              calculateTotal(items, "لا يرغب في الخدمة", counter["la-yrghb-fy-alkhdm"])
            }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'يبحث عن عقار') !== -1">
            {{ calculateTotal(items, "يبحث عن عقار", counter["ybhth-aan-aakar"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'مرفوض') !== -1">
            {{ calculateTotal(items, "مرفوض", counter["mrfod"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'تعذر الإتصال') !== -1">
            {{ calculateTotal(items, "تعذر الإتصال", counter["taathr-alatsal"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'مرفوع') !== -1">
            {{ calculateTotal(items, "مرفوع", counter["mrfoaa"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'مكتمل') !== -1">
            {{ calculateTotal(items, "مكتمل", counter["mktml"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'لم يرد') !== -1">
            {{ calculateTotal(items, "لم يرد", counter["lm-yrd"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'اجل التواصل') !== -1">
            {{ calculateTotal(items, "اجل التواصل", counter["agl-altoasl"]) }}
          </td>
          <td
            v-if="
              headers.findIndex((res) => res.value === 'بدون راتب ( فرد عمل حر)') !== -1
            "
          >
            {{
              calculateTotal(
                items,
                "بدون راتب ( فرد عمل حر)",
                counter["bdon-ratb-frd-aaml-hr"]
              )
            }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'لم يناسبه العرض') !== -1">
            {{ calculateTotal(items, "لم يناسبه العرض", counter["lm-ynasbh-alaard"]) }}
          </td>
          <td
            v-if="headers.findIndex((res) => res.value === 'ملغى من قبل العميل') !== -1"
          >
            {{
              calculateTotal(items, "ملغى من قبل العميل", counter["mlgh-mn-kbl-alaamyl"])
            }}
          </td>
          <td
            v-if="
              headers.findIndex((res) => res.value === 'لديه التزام عقاري حديث') !== -1
            "
          >
            {{
              calculateTotal(
                items,
                "لديه التزام عقاري حديث",
                counter["ldyh-altzam-aakary-hdyth"]
              )
            }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'قرض شخصي') !== -1">
            {{ calculateTotal(items, "قرض شخصي", counter["krd-shkhsy"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'أخذ من البنك') !== -1">
            {{ calculateTotal(items, "أخذ من البنك", counter["akhth-mn-albnk"]) }}
          </td>
          <td
            v-if="
              headers.findIndex((res) => res.value === 'Testing Classification') !== -1
            "
          >
            {{
              calculateTotal(
                items,
                "Testing Classification",
                counter["testing-classification"]
              )
            }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'صرف نظر') !== -1">
            {{ calculateTotal(items, "صرف نظر", counter["srf-nthr"]) }}
          </td>
          <td
            v-if="
              headers.findIndex(
                (res) => res.value === 'بدون راتب (صاحب شركة أو مؤسسة)'
              ) !== -1
            "
          >
            {{
              calculateTotal(
                items,
                "بدون راتب (صاحب شركة أو مؤسسة)",
                counter["bdon-ratb-sahb-shrk-ao-moss"]
              )
            }}
          </td>
          <td></td>
        </tr>

        <tr v-if="type === 'basketRequest'">
          <td style="margin-right: 17px">المجموع</td>
          <td v-if="headers.findIndex((res) => res.value === 'مكتملة') !== -1">
            <span> {{ calculateTotal(items, "مكتملة", counter.completes) }}</span>
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'مؤرشفة') !== -1">
            <span> {{ calculateTotal(items, "مؤرشفة", counter.archived) }}</span>
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'متابعة') !== -1">
            <span> {{ calculateTotal(items, "متابعة", counter.following) }}</span>
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'مميزة') !== -1">
            <span> {{ calculateTotal(items, "مميزة", counter.star) }}</span>
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'مستلمة') !== -1">
            <span> {{ calculateTotal(items, "مستلمة", counter.received) }}</span>
          </td>
          <td></td>
        </tr>

        <tr v-if="type === 'requestReport'">
          <td style="margin-right: 17px">المجموع</td>
          <td v-if="headers.findIndex((res) => res.value === 'جديد') !== -1">
            {{ calculateTotal(items, "جديد", counter["new_status"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'مفتوح') !== -1">
            {{ calculateTotal(items, "مفتوح", counter["open_status"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'مؤرشف') !== -1">
            {{ calculateTotal(items, "مؤرشف", counter["archive_status"]) }}
          </td>
          <td
            v-if="
              headers.findIndex(
                (res) => res.value === ' بانتظار موافقه مدير المبيعات'
              ) !== -1
            "
          >
            {{
              calculateTotal(
                items,
                " بانتظار موافقه مدير المبيعات",
                counter["waiting_sm_status"]
              )
            }}
          </td>
          <td
            v-if="headers.findIndex((res) => res.value === 'رفض من مدير المبيعات') !== -1"
          >
            {{
              calculateTotal(items, "رفض من مدير المبيعات", counter["rejected_sm_status"])
            }}
          </td>
          <td
            v-if="
              headers.findIndex((res) => res.value === 'مؤرشف عند مدير المبيعات') !== -1
            "
          >
            {{
              calculateTotal(
                items,
                "مؤرشف عند مدير المبيعات",
                counter["archive_sm_status"]
              )
            }}
          </td>

          <td
            v-if="
              headers.findIndex((res) => res.value === 'بانتظار موافقه مدير التمويل') !==
              -1
            "
          >
            {{
              calculateTotal(
                items,
                "بانتظار موافقه مدير التمويل",
                counter["waiting_fm_status"]
              )
            }}
          </td>

          <td
            v-if="
              headers.findIndex((res) => res.value === 'رفض من قبل مدير التمويل') !== -1
            "
          >
            {{
              calculateTotal(
                items,
                "رفض من قبل مدير التمويل",
                counter["rejected_fm_status"]
              )
            }}
          </td>

          <td
            v-if="
              headers.findIndex((res) => res.value === 'مؤرشف عند مدير التمويل') !== -1
            "
          >
            {{
              calculateTotal(
                items,
                "مؤرشف عند مدير التمويل",
                counter["archive_fm_status"]
              )
            }}
          </td>

          <td
            v-if="
              headers.findIndex((res) => res.value === 'بانتظار موافقه مدير الرهن') !== -1
            "
          >
            {{
              calculateTotal(
                items,
                "بانتظار موافقه مدير الرهن",
                counter["waiting_mm_status"]
              )
            }}
          </td>

          <td
            v-if="
              headers.findIndex((res) => res.value === 'رفض من قبل مدير الرهن') !== -1
            "
          >
            {{
              calculateTotal(
                items,
                "رفض من قبل مدير الرهن",
                counter["rejected_mm_status"]
              )
            }}
          </td>

          <td
            v-if="headers.findIndex((res) => res.value === 'مؤرشف عند مدير الرهن') !== -1"
          >
            {{
              calculateTotal(items, "مؤرشف عند مدير الرهن", counter["archive_mm_status"])
            }}
          </td>

          <td
            v-if="
              headers.findIndex((res) => res.value === 'بانتظار موافقه المدير العام') !==
              -1
            "
          >
            {{
              calculateTotal(
                items,
                "بانتظار موافقه المدير العام",
                counter["waiting_gm_status"]
              )
            }}
          </td>
          <td
            v-if="
              headers.findIndex((res) => res.value === 'رفض من قبل المدير العام') !== -1
            "
          >
            {{
              calculateTotal(
                items,
                "رفض من قبل المدير العام",
                counter["rejected_gm_status"]
              )
            }}
          </td>

          <td
            v-if="
              headers.findIndex((res) => res.value === 'مؤرشف عند المدير العام') !== -1
            "
          >
            {{
              calculateTotal(
                items,
                "مؤرشف عند المدير العام",
                counter["archive_gm_status"]
              )
            }}
          </td>

          <td v-if="headers.findIndex((res) => res.value === 'ملغى') !== -1">
            {{ calculateTotal(items, "ملغى", counter["canceled_status"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'مكتمل') !== -1">
            {{ calculateTotal(items, "مكتمل", counter["completed_status"]) }}
          </td>

          <td v-if="headers.findIndex((res) => res.value === ' في تقرير التمويل') !== -1">
            {{
              calculateTotal(items, " في تقرير التمويل", counter["funding_report_status"])
            }}
          </td>

          <td v-if="headers.findIndex((res) => res.value === ' في تقرير الرهن') !== -1">
            {{
              calculateTotal(items, " في تقرير الرهن", counter["mortgage_report_status"])
            }}
          </td>
          <td></td>
        </tr>

        <tr v-if="type === 'dailySalesAgentForSalesManger'">
          <td style="margin-right: 17px">المجموع</td>

          <td
            v-if="
              headers.findIndex((res) => res.value === 'total_received_request') !== -1
            "
          >
            {{
              calculateTotal(
                items,
                "total_received_request",
                counter["total_recived_request_sum"]
              )
            }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'received_basket') !== -1">
            {{ calculateTotal(items, "received_basket", counter["received_basket_sum"]) }}
          </td>

          <td v-if="headers.findIndex((res) => res.value === 'star_basket') !== -1">
            {{ calculateTotal(items, "star_basket", counter["star_basket_sum"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'followed_basket') !== -1">
            {{ calculateTotal(items, "followed_basket", counter["followed_basket_sum"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'archived_basket') !== -1">
            {{ calculateTotal(items, "archived_basket", counter["archived_basket_sum"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'sent_basket') !== -1">
            {{ calculateTotal(items, "sent_basket", counter["sent_basket_sum"]) }}
          </td>
          <td v-if="headers.findIndex((res) => res.value === 'completed_request') !== -1">
            {{
              calculateTotal(items, "completed_request", counter["completed_request_sum"])
            }}
          </td>

          <td v-if="headers.findIndex((res) => res.value === 'updated_request') !== -1">
            {{ calculateTotal(items, "updated_request", counter["updated_request_sum"]) }}
          </td>

          <td v-if="headers.findIndex((res) => res.value === 'opened_request') !== -1">
            {{ calculateTotal(items, "opened_request", counter["opened_request_sum"]) }}
          </td>

          <td v-if="headers.findIndex((res) => res.value === 'received_task') !== -1">
            {{ calculateTotal(items, "received_task", counter["received_task_sum"]) }}
          </td>

          <td v-if="headers.findIndex((res) => res.value === 'replayed_task') !== -1">
            {{ calculateTotal(items, "replayed_task", counter["replayed_task_sum"]) }}
          </td>

          <td v-if="headers.findIndex((res) => res.value === 'missed_reminders') !== -1">
            {{
              calculateTotal(items, "missed_reminders", counter["missed_reminders_sum"])
            }}
          </td>

          <td v-if="headers.findIndex((res) => res.value === 'move_request_from') !== -1">
            {{
              calculateTotal(items, "move_request_from", counter["move_request_from_sum"])
            }}
          </td>

          <td v-if="headers.findIndex((res) => res.value === 'move_request_to') !== -1">
            {{ calculateTotal(items, "move_request_to", counter["move_request_to_sum"]) }}
          </td>
          <td></td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import LoadingMore from "./LoadingMore.vue";

export default {
  name: "DataTable",
  props: ["name", "headers", "desserts", "title", "total", "type", "loading", "counter"],
  components: { LoadingMore },

  data() {
    return {
      search: "",
    };
  },
  methods: {
    test(item) {},
    calculateTotal(items, column, count = null) {
      let total = 0;
      if (count != null) {
        return count;
      } else {
        for (const item of items) {
          total += item[column];
        }
        return total;
      }
    },
  },
};
</script>
