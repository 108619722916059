<template>
  <div>
    <v-row justify="center" class="mb-3 mt-5 graphContent" style="margin: 0px">
      <DailyReportFilter @handleFilter="handleFilter" />
    </v-row>

    <div v-if="!this.$store.state.allLoading" style="margin-top: 40px">
      <table-component
        :showSelect="true"
        itemKey="id"
        :selectedRows.sync="selected_requests"
        :headers="headers"
        :pagination="this.$store.state.pagination"
        :limit="counterOfShow"
        :items="this.$store.state.tableData"
        :loading="loading"
        @refresh="getTableData"
        v-model="page"
      >
      </table-component>

      <div class="card" style="margin-bottom: 100px; background-color: white !important;">
        <div class="card-header">
          <h3>مكالمات غير معروف الاستشاري الخاص بها</h3>
        </div>
        <div class="card-body" style="background-color: white !important;">
          <table class="styled-table">
            <thead>
              <tr>
                <td>الاستشاري</td>
                <td>مجموع المكالمات</td>
                <td>مدة المكالمات</td>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>##</td>
                <td>
                  {{ calls_withoit_source.count_of_calls_that_have_no_source }}
                </td>
                <td>
                  {{ calls_withoit_source.sum_of_calls_that_have_no_source }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
      "
      v-else
    >
      <LoadingMore />
    </div>
    <v-snackbar
      v-model="active"
      color="#40BE72"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <span class="font-weight-bold" style="font-size: 16px">{{
        message
      }}</span>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <OrderRequestDialog />

     <!-- chart -->
     <div style="box-shadow: 0 0 0 0 !important" class="adminGraph">
      <div v-if="!getDataDone">
        <div style="margin-top: 40px" v-if="!loading">
          <highcharts :options="chartOptions" style="height: 600px"></highcharts>
        </div>
      </div>
      <div v-else class="loader">
        <img src="../../../assets/Comp-2.gif" />
      </div>
    </div>

  </div>
</template>

<script>
import LoadingMore from "@/components/Charts/LoadingMore.vue";
import OrderRequestDialog from "@/components/AdminComponent/OrderRequestDialog.vue";
import { axiosInstance } from "@/axios/config";
import DailyReportFilter from "./DailyReportFilter.vue";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts-vue";

export default {
  name: "AdminSupport",
  components: {
    OrderRequestDialog,
    LoadingMore,
    DailyReportFilter,
    highcharts: Chart,

  },

  data() {
    return {
      body: {},
      allGetData: [],
      selected_requests: [],
      pagination: {
        limit: 3,
        count: this.$store.state.searchedCount,
      },
      page: 1,
      selectedRows: [],
      allData: [],
      active: false,
      message: "",
      showSelect: { default: false },
      copySupportRequests: [],
      loadMoreData: false,
      loading: false,
      selectedItems: [],
      headers: [
        { align: "center", value: "data-table-select", width: "5%" },
        { text: "الاستشاري", value: "الاستشاري", width: "170px" },
        {
          text: "المكالمات الصادرة",
          value: "المكالمات الصادرة",
          width: "170px",
        },
        { text: "مدة الصادرة", value: "مدة الصادرة", width: "170px" },
        {
          text: "المكالمات الواردة",
          value: "المكالمات الواردة",
          width: "170px",
        },
        { text: "مدة الواردة", value: "مدة الواردة", width: "170px" },
      ],
      counterOfShow: 10,
      calls_withoit_source: {},
      horizontalData: [],
      verticalData1: [],
      verticalData2: [],
      chartData: [],
      getDataDone: false,
      chartOptions: {
        chart: {
          type: "column",
        },
        title: {
          text: "تقرير المكالمات بالنسبة للاستشاريين",
        },
        xAxis: {
          min: 0,
          title: {
            text: "المكالمات",
          },
          categories: [],
        },
        yAxis: {
          title: {
            text: "المجموع",
          },
        },
        tooltip: {
          headerFormat: "<b>{point.x}</b><br/>",
          pointFormat:
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
          shared: true,
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "مجموع المكالمات الصادرة الخاصة بالاستشاري",
            data: [],
          },
          {
            name: "مجموع المكالمات الواردة الخاصة بالاستشاري",
            data: [],
          },
        ],
      },

    };
  },

  watch: {
    page(val) {
      this.searchRequest(val, "table");
    },
    selected_requests(val) {
      this.selectedItems = val;
      this.$emit("requests", val);
    },
  },

  methods: {
    reloadPage() {
      this.$store.state.allData = [];
      this.$store.state.tableData = [];
      this.allGetData = [];
      this.$store.state.followLoading = true;
      this.$store.state.isSearch = false;
      this.getAllRequests(1);
      this.$store.state.startElement = [];
    },

    handleFilter(val) {
      this.body = new FormData();
      if (val.startDate) {
        this.body.append("start_date", val.startDate);
      }

      if (val.endDate) {
        this.body.append("end_date", val.endDate);
      }

      if (val.status === "استشاري نشط") {
        this.body.append("status", 1);
      }

      if (val.status === "استشاري مؤرشف") {
        this.body.append("status", 0);
      }

      if (val.range) {
        this.body.append("range", val.range);
      }

      if (val.managerId.length) {
        const managerProperties = {};
        val.managerId.forEach((item, index) => {
          this.body.append([`manager_id[${index}]`], item.id);
        });
      }

      if (val.agentId.length) {
        const managerProperties = {};
        val.agentId.forEach((item, index) => {
          this.body.append([`adviser_id[${index}]`], item.id);
        });
      }

      if (val.dayNumber) {
        this.body.append("range", val.dayNumber);
      }
      this.searchRequest(1, "table");
    },

    setTblData(res) {
      console.log(res.id);
      this.$store.state.tableData.push({
        id: res.id,
        isSelected: false,
        الاستشاري: res.name,
        "المكالمات الصادرة": res.get_user_out_going_calls_count,
        "مدة الصادرة": res.get_user_out_going_calls_sum_duration,
        "المكالمات الواردة": res.get_user_on_going_calls_count,
        "مدة الواردة": res.get_user_on_going_calls_sum_duration,
      });
    },

    getTableData(page) {
      this.$store.state.tableData = [];
      this.loading = true;
      axiosInstance
        .post(`/employee/admin/getCallsWithAgents?page=${page}`, this.body, {
          headers: this.request_headers(),
        })
        .then((res) => {
          let response = res.data.payload.data.map((item) => {
            this.setTblData(item);
          });

          this.calls_withoit_source =
            res.data.payload.calls_that_have_no_source;

          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_agents,
          };

          this.drawChart(this.body)
          
          this.loading = false;
        });
    },

    searchRequest(page, type) {

      this.chartData = [];
      this.chartOptions.xAxis.categories = [];
      this.chartOptions.series[0].data = [];
      this.chartOptions.series[1].data = [];

      console.log("enter");
      this.loading = true;
      if (type === "table") {
        this.$store.state.tableData = [];
      }
      // this.body.append("page", page);
      let url = `/employee/admin/getCallsWithAgents?page=${page}`;
      axiosInstance
        .post(url, this.body, {
          headers: this.request_headers(),
        })
        .then((res) => {
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_agents,
          };
          this.loading = false;
          let response = res.data.payload.data.map((item) => {
            this.setTblData(item);
          });

          this.drawChart(this.body)

          this.calls_withoit_source =
            res.data.payload.calls_that_have_no_source;
        });
    },

    removeDublicate(array) {
      let uniqueArray = array.filter((item, index) => {
        return (
          index ===
          array.findIndex((obj) => {
            return obj.id === item.id;
          })
        );
      });
      this.$store.state.allData = uniqueArray;
    },

    getAllRequests(page) {
      if (
        this.$store.state.allData.length === 0 ||
        this.copySupportRequests.length === 0
      ) {
        this.$store.state.allLoading = true;
      }
      this.loadMoreData = true;
      axiosInstance
        .post(`/employee/admin/getCallsWithAgents?page=${page}`, this.body)
        .then((res) => {
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_agents,
          };
          let response = res.data.payload.data.map((item) => {
            this.setTblData(item);
            item["starChecked"] = false;
            return item;
          });

          this.drawChart(this.body)

          this.calls_withoit_source =
            res.data.payload.calls_that_have_no_source;

          this.allGetData.push(...response);
          this.removeDublicate(this.allGetData);
          this.copySupportRequests.push(...response);
        })
        .catch((err) => {
          this.$store.state.allLoading = false;
          this.loadMoreData = false;
        })
        .finally(() => {
          this.loadMoreData = false;
          this.$store.state.allLoading = false;
        });
    },

    handleChangeInput(row) {
      row["selected"] = !row["selected"];
      let findRow = this.selectedRows.findIndex(
        (item) => item["id"] === row["id"]
      );
      if (findRow !== -1) {
        this.selectedRows.splice(findRow, 1);
      } else {
        this.selectedRows.push(row);
      }
    },

    drawChart(body){
      axiosInstance
        .post(`/employee/admin/getCallsWithAgents`,body, {
          headers: this.request_headers(),
        })
        .then((res) => {
          // this.chartData = res.data.payload.data;
          this.chartData = res.data.payload.data_without_pagination;
          this.horizontalData = this.chartData.map((item) => item.name);
          this.verticalData1 = this.chartData.map((item) => item.get_user_out_going_calls_count);
          this.verticalData2 = this.chartData.map((item) => item.get_user_on_going_calls_count);
          this.chartOptions.xAxis.categories = this.horizontalData;
          this.chartOptions.series[0].data = this.verticalData1;
          this.chartOptions.series[1].data = this.verticalData2;
          this.loading = false;
        });
    }
  },

  created() {
    this.$store.state.tableData = [];
    this.$store.state.back = false;
    this.$store.state.isSearch = false;

    // this.body = new FormData();
    // this.body.append("status", 1);
    // this.getTableData(1);
    this.$store.state.startElement = [];
  },
};
</script>

<style>
.noData {
  display: flex;
  justify-content: center;
  font-size: 22px;
  color: #3f0e40;
  margin-top: 70px;
  font-weight: bold;
}
.support_container {
  display: flex;
  text-align: center;
  justify-content: space-between;
  margin-top: 45px;
  margin-bottom: 20px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  margin: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
  background-color: white;
  padding: 10px;
  text-align: center;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.styled-table th, .styled-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.styled-table th {
  background-color: #f2f2f2;
}

</style>
