<template>
  <div class="page-container">
    <div v-if ="loading" class="progress d-flex align-center justify-center px-10" style="min-height: 200px">
      <v-progress-circular size="30" color="primary" indeterminate></v-progress-circular>
    </div>
    <div class="content-container">
      <slot v-if="!loading" name="content"/>
      <slot name="pagination"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasePage',
  props: {
    loading: {type: Boolean}
  },
  data () {
    return {
    }
  }
}
</script>

<style>
  .page-container .progress {
    flex: 1;
    height: 60vh !important;
    padding-top: 30px;
  }
  .content-container {
    height: 100vh - 24px !important;
    /* margin-top: 24px; */
    width: 100%;
  }
</style>
