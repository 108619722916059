<template>
  <div class="notifs position-absolute">
    <v-progress-circular
      :style="`position: fixed;
        left: 50%;
        top: 50%;
        width: 50px;
        height: 50px;
        transform: translate(-50%, -50%);${
          loadingNotif ? 'z-index:1' : 'z-index:-100'
        }`"
      :active="loadingNotif"
      :indeterminate="loadingNotif"
      color="primary"
    ></v-progress-circular>
    <div class="the-container mx-2 my-10">
      <v-btn
        fab
        :color="$vuetify.theme.themes.light.dialogActionBTN"
        @click="addNewRequestDialog = true"
        class="my-10"
        style="position: fixed; left: 20px; bottom: 30px; z-index: 1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25.425"
          height="21.166"
          viewBox="0 0 25.425 21.166"
        >
          <g
            id="Icon_feather-user-plus"
            data-name="Icon feather-user-plus"
            transform="translate(1 1)"
          >
            <path
              id="Path_2554"
              data-name="Path 2554"
              d="M17.471,28.889v-2.13A4.259,4.259,0,0,0,13.212,22.5H5.759A4.259,4.259,0,0,0,1.5,26.759v2.13"
              transform="translate(-1.5 -9.723)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              id="Path_2555"
              data-name="Path 2555"
              d="M15.268,8.759A4.259,4.259,0,1,1,11.009,4.5,4.259,4.259,0,0,1,15.268,8.759Z"
              transform="translate(-3.023 -4.5)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              id="Path_2556"
              data-name="Path 2556"
              d="M30,12v6.389"
              transform="translate(-9.77 -6.676)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
            <path
              id="Path_2557"
              data-name="Path 2557"
              d="M31.889,16.5H25.5"
              transform="translate(-8.464 -7.982)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </g>
        </svg>
      </v-btn>
      <add-new-request v-model="addNewRequestDialog" width="350px" />
      <div class="cont-dad" v-for="(item, i) in items" :key="i">
        <div class="upper">
          <div class="right-side">
            <h2 class="notif-title">
              {{ item.customer ? item.customer.name : "" }}
            </h2>
            <span class="mr-4"
              >{{ item.notif_time }} - {{ item.notif_date }}</span
            >
          </div>
          <div class="left-side">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="transparent"
                  depressed
                  small
                  dark
                  v-bind="attrs"
                  v-on="on"
                  style="margin-left: -12px"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="3.548"
                    height="16.219"
                    viewBox="0 0 3.548 16.219"
                  >
                    <path
                      id="menu"
                      d="M1.774,3.548A1.851,1.851,0,0,1,.507,3.016,1.765,1.765,0,0,1,0,1.774,2,2,0,0,1,.507.507,1.781,1.781,0,0,1,1.774,0,1.882,1.882,0,0,1,3.016.507a1.8,1.8,0,0,1,.532,1.267A1.819,1.819,0,0,1,1.774,3.548Zm7.577-.532a1.791,1.791,0,0,0,.532-1.242A1.9,1.9,0,0,0,9.351.507,1.765,1.765,0,0,0,8.109,0,1.946,1.946,0,0,0,6.842.507a1.781,1.781,0,0,0-.507,1.267,1.882,1.882,0,0,0,.507,1.242,1.744,1.744,0,0,0,2.509,0Zm6.336,0a1.791,1.791,0,0,0,.532-1.242A1.9,1.9,0,0,0,15.687.507,1.765,1.765,0,0,0,14.445,0a1.946,1.946,0,0,0-1.267.507,1.781,1.781,0,0,0-.507,1.267,1.882,1.882,0,0,0,.507,1.242,1.744,1.744,0,0,0,2.509,0Z"
                      transform="translate(0 16.219) rotate(-90)"
                      fill="#acacac"
                    />
                  </svg>
                </v-btn>
              </template>
              <v-list>
                <v-list-item :to="handleOpen(item)">
                  <v-list-item-icon class="ml-2 pt-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18.528"
                      height="15.111"
                      viewBox="0 0 13.528 10.111"
                    >
                      <g
                        id="Icon_feather-eye-dark"
                        data-name="Icon feather-eye-dark"
                        transform="translate(-1 -5.5)"
                      >
                        <path
                          id="Path_4040"
                          data-name="Path 4040"
                          d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        />
                        <path
                          id="Path_4041"
                          data-name="Path 4041"
                          d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                          transform="translate(-7.444 -4.653)"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        />
                      </g>
                    </svg>
                  </v-list-item-icon>
                  <v-list-item-title>فتح</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <p class="desc">
          {{ item.notification_content }}
        </p>
      </div>
    </div>
    <v-row v-if="items.length > 0">
      <v-col cols="12" sm="2">
        <v-chip
          outlined
          color="primary"
          class="font-weight-bold my-0 py-0"
          v-if="!loadingNotif"
        >
          الإجمالي : {{ count }}
        </v-chip>
      </v-col>
      <v-col cols="12" sm="9">
        <v-pagination v-model="page" :length="pages || 1" circle></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import Axios from "axios";
import { store } from "@/store";
import router from "@/routes";
import { onBeforeMount, ref, watch } from "vue";

const theCookie = document.cookie
  .split(";")
  .map((coki) => coki.split("="))
  .reduce(
    (acc, [key, val]) => ({
      ...acc,
      [key.trim()]: decodeURIComponent(val),
    }),
    {}
  )["wsataJwt"];
const loadingNotif = ref(false);
const page = ref(1);
const addNewRequestDialog = ref(false);
const items = ref([]);
const selected_requests = ref([]);
const count = ref(1);
const pages = ref(1);
const headers = ref([
  {
    text: "عميل",
    value: "name",
  },
  { text: "المحتوي", value: "notification_content" },
  { text: "التاريخ", value: "notif_date" },
  { text: "الوقت", value: "notif_time" },
  { text: "حالة التنبيه", value: "status", align: "center" },
  { text: "العمليات", value: "action", align: "center", width: "3%" },
]);
const handleOpen = (item) => {
  if (item.vaction_id) {
    return {
      name: "alternative_vacation_details",
      params: { id: item.vaction_id, notify_id: item.notification_id },
    };
  } else if (item.type === 8) {
    return {
          name: 'SupportContent',
          params: { id: item.helpDeskId },
    };
  }
  else {
    return {
      name: "request_details",
      params: { id: item.request_number },
    };
  }

};
const get_all_notifications = async (page = 1) => {
  items.value = [];
  loadingNotif.value = true;
  await Axios.get(
    `https://appproduct.alwsata.com.sa/api/agent/All/Notification?page=${page}`,
    {
      headers: {
        "x-api-key":
          "WFKvB4Vjr8v5BVmxY7CDQ6ZMAU8DSHSBFKCMVpkfd5hhZyKTvnV3uh5XwxSwhnfeUbptZ7Z4XFJCR4vKXeHWGHTZ6djsYXjdAatf",
        Authorization: `Bearer ${JSON.parse(theCookie).token}`,
      },
    }
  ).then((response) => {
    items.value = response.data.payload.data;
    count.value = response.data.payload.count;
    items.value.forEach((el) => {
      el.notif_date = el.notification_date.split(" ")[0];
      el.notif_time = el.notification_date
        .split(" ")[1]
        .split("")
        .splice(0, 5)
        .join("");
      delete el.notification_date;
    });
    count.value = response.data.payload.count || 1;
    pages.value = Math.ceil(parseInt(count.value) / 12);
    loadingNotif.value = false;
  });
};

onBeforeMount(() => {
  get_all_notifications();
});

watch(page, (val) => get_all_notifications(val));

watch(() => store.state.not_processed_tasks_count, (val) => {
  if (val > 0 && localStorage["role"] == "0") {
      store.state.showAlertOfTasksDidntProccess = true;
      if(store.state.first_not_processed_task_id){
        router.push({ name: 'ticket_details', params: { ticket: store.state.first_not_processed_task_id } });
      }else{
        router.push({ name: "recieved_tickets" });
      }
    }
  }, { immediate: true }
);


</script>

<style lang="scss">
.notifs {
  .cont-dad {
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    color: #2c2c2c;
    padding: 9px 20px 11px 7px;
    margin-top: 20px;
    .upper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .right-side {
        display: flex;
        align-items: center;
        span {
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
    p {
      margin: 0;
      margin-top: 6px;
    }
    h2 {
      margin: 0;
      font-weight: 900;
      font-size: 18px;
    }
  }
}
@media (max-width: 600px) {
  .notifs {
    .cont-dad {
      h2 {
        font-size: 14px;
      }
      span {
        font-size: 11px !important;
      }
      p {
        font-size: 13px;
      }
    }
  }
}
</style>
