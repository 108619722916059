<template>
  <v-card
    class="mx-auto my-8"
    width="width: 100%;"
    style="box-shadow: 0 0 0 0 !important; border: none !important; max-width: 600px"
    elevation="16"
  >
    <v-card-text>
      <div class="card__container">
        <div style="width: 60%; font-size: 17px">
          <div>الرقــم الوظيفى: {{ $store.state.userProfileData.job_number }}</div>
          <div>الاســم بالعـربى :{{ $store.state.userProfileData.name }}</div>
          <div>الاسم بالإنجليزى :{{ $store.state.userProfileData.name_en }}</div>
          <div>الجنسيــــــــــــــة : {{ targetNationality }}</div>
          <hr style="margin-top: 20px; margin-bottom: 20px" />

          <a :href="$store.state.userPdf" target="_blank">
            <v-btn color="primary"> ملف الموظف بصيغه ال PDF</v-btn>
          </a>
        </div>
        <div>
          <img src="@/assets/purple-avatar.png" alt="image" style="width: 180px" />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { store } from "@/store";
import { ref, computed, onMounted, defineProps } from "vue";
import { axiosInstance } from "@/axios/config";
import router from "@/routes";

const props = defineProps(["pdf"]);

const targetNationality = computed(() => {
  const matchingNationality = nationality.value.find(
    (item) => item.id === store.state.userProfileData.control_nationality_id
  );

  if (matchingNationality) {
    return matchingNationality.value;
  }

  return null;
});

const nationality = ref([]);
const getNationality = () => {
  axiosInstance.get(`/employee/hr/employee-control-list`).then((res) => {
    nationality.value = res.data.payload.nationality;
  });
};

const openFile = () => {
  axiosInstance
    .get(`/employee/hr/upload-profile/${router.currentRoute.params.id}`, {
      responseType: "arraybuffer",
    })
    .then((response) => {
      const contentType = response.headers["content-type"];
      const blob = new Blob([response.data], { type: contentType });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};

onMounted(() => {
  getNationality();
});
</script>

<style>
.card__container {
  display: flex;
  justify-content: space-between;
}
</style>
