<template>
  <div>
    <div v-if="!this.$store.state.loadingData" class="table__view">
      <table-component
        :showSelect="false"
        itemKey="id"
        :headers="headers"
        :pagination="this.$store.state.pagination"
        :limit="10"
        :items="this.$store.state.tableData"
        :loading="loading"
        @refresh="getTableData"
        v-model="page"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#3f0e40" small depressed dark v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="handleUserStatus(item)" v-if="item.status == 0">
                <v-list-item-icon class="ml-3">
                  <NotAllowIcon />
                </v-list-item-icon>
                <v-list-item-title>السماح باستلام الطلبات المميزه</v-list-item-title>
              </v-list-item>

              <v-list-item @click="handleUserStatus(item)" v-if="item.status == 1">
                <v-list-item-icon class="ml-3">
                  <CheckIcon />
                </v-list-item-icon>
                <v-list-item-title>عدم السماح باستلام الطلبات المميزه</v-list-item-title>
              </v-list-item>

              <v-list-item @click="handleOrderRequest(item)">
                <v-list-item-icon class="ml-3">
                  <HolidayOrder />
                </v-list-item-icon>
                <v-list-item-title>سجل الطلب</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </table-component>
    </div>
    <div class="loadingMore" v-else>
      <LoadingMore />
    </div>
  </div>
</template>

<style lang="scss">
.loadingMore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
</style>

<script>
import Axios from "axios";
import LoadingMore from "@/components/Charts/LoadingMore.vue";
import { NotAllowIcon } from "@/assets/adminIcons/notAllowIcon";
import { ProcessedIcon } from "@/assets/adminIcons/processedIcon";
import { HolidayOrder } from "@/assets/adminIcons/holidayOrder";
import { CheckIcon } from "@/assets/adminIcons/checkIcon";

export default {
  name: "AgentsVacations",
  components: { LoadingMore, CheckIcon, HolidayOrder, NotAllowIcon },

  data() {
    return {
      page: 1,
      loading: false,
      copySupportRequests: [],
      loadMoreData: false,
      loadingData: false,
      selected_requests: [],
      iconToggle: false,
      icon: "",
      text: "",
      headers: [
        { text: "تاريخ الطلب", value: "تاريخ الطلب", align: "center" },
        { text: "المستخدم", value: "المستخدم", align: "center" },
        { text: "البديل", value: "البديل", align: "center" },
        { text: "يوم/من", value: "يوم/من", align: "center" },
        { text: "الي", value: "الي", align: "center" },
        { text: "رصيد الاجازه", value: "رصيد الاجازه", align: "center" },
        { text: "مده الاجازه", value: "مده الاجازه", align: "center" },
        {
          text: "الرصيد المتبقي بعد الموافقه",
          value: "الرصيد المتبقي بعد الموافقه",
          align: "center",
        },
        { text: "العمليات", value: "action", align: "center" },
      ],
    };
  },

  watch: {
    page(val) {
      this.getTableData(val);
    },

    selected_requests(val) {
      this.selectedItems = val;
      this.$emit("requests", val);
    },
  },

  methods: {
    handleOrderRequest(item) {
      this.$router.push({
        name: "vacation_history",
        params: { id: item.id },
      });
    },

    handleUserStatus(item) {
      let body = new FormData();
      if (item.status == 0) {
        body.append("allow", 1);
      } else if (item.status == 1) {
        body.append("allow", 0);
      }

      body.append("vacation_id", item.id);

      let url = `${this.$store.state.url}/employee/admin/allow_alternative`;

      Axios.post(url, body, { headers: this.request_headers() })
        .then((res) => {
          item.status === 0 ? (this.iconToggle = false) : (this.iconToggle = true);
          this.showPopup("success", res.data.payload);
          this.getTableData();
        })
        .catch((err) => {
          let error = { err };
          this.showPopup("error", error.err.response.data.message);
        });
    },

    setTbData(items) {
      this.$store.state.tableData.push({
        status: items.admin_allow_alternative,
        id: items.id,
        "تاريخ الطلب": items.created_at,
        المستخدم: items.user_name,
        البديل: items.alternative_name,
        "يوم/من": items.from,
        الي: items.to,
        "رصيد الاجازه": items.vacation_balance,
        "مده الاجازه": items.vacation_duration,
        "الرصيد المتبقي بعد الموافقه": items.remainder_balance,
      });
    },

    getTableData(count) {
      this.$store.state.tableData = [];
      this.loading = true;

      Axios.get(`${this.$store.state.url}/employee/admin/agent_vacation?page=${count}`, {
        headers: this.request_headers(),
      }).then((res) => {
        res.data.payload.data.map((item) => {
          this.setTbData(item);
        });

        this.$store.state.pagination = {
          limit: 3,
          count: res.data.payload.count,
        };

        this.loading = false;
      });
    },
  },

  created() {
    this.$store.state.tableData = [];
    this.getTableData(1);
  },
};
</script>
