<template>
  <page-component style="position: relative" :loading="loading">
    <template #content>
      <div class="py-5 mr-1 font-weight-bold">
        التذاكر المكتملة
        <span
          class="ml-4 font-weight-bold"
          style="float: left; color: #3b5de7"
          >{{ sent_counter }}</span
        >
      </div>
      <v-divider class="mb-5" style="border: 1px solid #EDEEF0" />
      <v-row class="mt-0">
        <v-col
          cols="12"
          lg="3"
          md="4"
          sm="6"
          xs="12"
          v-for="ticket in tableData"
          :key="`ticket-${ticket.id}`"
        >
          <v-card
            elevation="1"
            style="border: 2px solid rgb(233, 237, 244) !important; box-shadow: 0px 0px 0px 0px !important; margin: 0px !important;"
            class="pa-2 ma-2"
          >
            <div>
              <span style="font-size: 14px;">{{ ticket.user?.name || "لايوجد اسم" }}</span>
              <span
                class="py-1 px-4"
                style="float: left; border-radius: 2px; background: #D9DEFA; color: #495AA0; font-weight: bold; letter-spacing: -.5px; font-size: 14px; line-height: 100%; height: 25px;"
                >{{ ticket.status || "لايوجد حالة" }}</span
              >
            </div>
            <div class="mt-3" style="font-size: 14px;">
              {{ ticket.receive?.name || "لايوجد اسم" }}
              <span class="py-1" style="float: left">{{
                ticket.request ? ticket.request.customer?.name : ""
              }}</span>
            </div>
            <div class="mt-3" style="font-size: 14px;">
              <v-icon class="mb-1" style="margin-right: -4px; font-size: 20px;">mdi-calendar</v-icon>
              {{
                ticket.created_at.split(".")[0].split("T").join(" | ") ||
                "لايوجد تاريخ"
              }}
              <span class=" px-0" style="float: left">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.1" height="11.982" viewBox="0 0 16.1 11.982"
                  @click="openTaskConversation(ticket.id)" style="cursor: pointer; margin-bottom: -3px;">
                  <g id="Group_11443" data-name="Group 11443" transform="translate(-765.437 -394.636)">
                    <g id="Icon_feather-eye" data-name="Icon feather-eye" transform="translate(765.937 395.136)">
                      <path id="Path_3993" data-name="Path 3993"
                        d="M1.5,11.491S4.246,6,9.05,6s7.55,5.491,7.55,5.491-2.746,5.491-7.55,5.491S1.5,11.491,1.5,11.491Z"
                        transform="translate(-1.5 -6)" fill="none" stroke="#3b5de7" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="1" />
                      <path id="Path_3994" data-name="Path 3994"
                        d="M17.618,15.559A2.059,2.059,0,1,1,15.559,13.5,2.059,2.059,0,0,1,17.618,15.559Z"
                        transform="translate(-8.009 -10.068)" fill="none" stroke="#3b5de7" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="1" />
                    </g>
                  </g>
                </svg>
                <span style="margin-right: 5px">عرض التذكرة</span>
              </span>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <LoadingMore v-if="loadMoreData" style="margin: 40px" />
    </template>
  </page-component>
</template>

<script setup>
import { ref, onMounted, watch, onBeforeUnmount } from "vue";
import { axiosInstance } from "@/axios/config";
import LoadingMore from "@/components/Charts/LoadingMore.vue";
import { store } from "@/store";
import router from "@/routes";

const page = ref(1);
let loadMoreData = ref(false);
let loading = ref(false);
const sent_counter = ref(0);

watch(page, (current) => {
  getData(current);
});

const pagination = ref({});
const tableData = ref([]);

const openTaskConversation = (item) => {
  store.state.tasksDetailsRequestId = item;
  router.push({ name: 'admin_tickets_details', params: { ticeket: item } });
};

const getData = (currentPage) => {
  if (currentPage === 1) {
    loading.value = true;
  } else {
    loadMoreData.value = true;
  }
  store.state.openTasksDetailsRequestSection = false;

  axiosInstance
    .get(`/v2/employee/getAllCompletedTasksForAdmin?page=${currentPage}&per_page=16`)
    .then((res) => {
      const response = res.data.payload;

      const newTickets = response.data.filter((ticket) => !tableData.value.some((t) => t.id === ticket.id));
      tableData.value = [...tableData.value, ...newTickets];

      sent_counter.value = response.count_of_tasks;
      pagination.value = {
        limit: 3,
        count: response.count_of_tasks,
      };
    })
    .catch((err) => {
      loadMoreData.value = false;
      loading.value = false;
    })
    .finally(() => {
      loading.value = false; 
      loadMoreData.value = false;
    });
};

const handleScroll = () => {
  const scrollContainer = document.documentElement;
  const scrollTop = scrollContainer.scrollTop;
  const scrollHeight = scrollContainer.scrollHeight;
  const clientHeight = scrollContainer.clientHeight;
  const totalPages = Math.ceil(pagination.value.count /10);
  if (scrollTop + clientHeight >= scrollHeight - 5 && !loading.value && page.value < totalPages) {
    page.value++;
  }
};

onMounted(() => {
  getData(1);
  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>
