export const ConvertRequestIcon = () => {
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.513"
            height="16.448"
            viewBox="0 0 14.513 16.448"
        >
            <path
                id="Icon_material-subdirectory-arrow-right"
                data-name="Icon material-subdirectory-arrow-right"
                d="M20.513,16.643l-5.805,5.805-1.374-1.374,3.473-3.464H6V6H7.935v9.675h8.872l-3.473-3.464,1.374-1.374Z"
                transform="translate(-6 -6)"
            />
        </svg>
    )
}
