<template>
  <div>
    <UserCard v-if="$store.state.isProfile" />
    <ProfileHeader />
    <v-form class="mt-5 mb-5" :disabled="!active">
      <v-card flat>
        <v-layout
          row
          wrap
          style="background-color: #f3f5f7"
          class="userProfile__container"
        >
          <v-col cols="12" lg="12" md="12" sm="12" xs="12">
            <v-card class="pa-3 position-relative userProfile__card">
              <v-col cols="12" sm="12">
                <div style="display: flex" class="input__group">
                  <div style="width: 100px">
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('customerName')"
                    />
                    ملاحظات
                  </div>
                  <div class="horizontal__line"></div>
                  <div style="width: 100%">
                    <v-text-field
                      type="text"
                      class="form-control"
                      style="
                        background-color: #fbfbfb;
                        border-right: #fbfbfb;
                        margin-top: -20px;
                      "
                      v-model="$store.state.userProfileData.notes"
                    />
                  </div>
                </div>
              </v-col>
            </v-card>
          </v-col>
        </v-layout>
      </v-card>
    </v-form>

    <v-btn class="primary" @click="active = true" v-if="!active">تعديل</v-btn>

    <v-btn class="primary" v-if="active" @click="handleEdit">حفظ</v-btn>

    <v-btn @click="active = false" v-if="active" style="margin-right: 5px">عرض</v-btn>

    <v-snackbar
      v-model="activeSnack"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <div>
        <span class="font-weight-bold" style="font-size: 16px">تم التعديل</span>
      </div>
      <template v-slot:action>
        <v-btn icon small @click="activeSnack = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script setup>
import ProfileHeader from "./ProfileHeader.vue";
import UserCard from "./UserCard.vue";
import { ref } from "vue";
import router from "@/routes";
import { axiosInstance } from "@/axios/config";
import { store } from "@/store";

const active = ref(false);
const activeSnack = ref(false);

const handleEdit = () => {
  const formData = new FormData();

  Object.entries(store.state.userProfileData).forEach(([key, value]) => {
    formData.append(key, value);
  });
  axiosInstance
    .post(`/employee/hr/user-update-profile/${router.currentRoute.params.id}`, formData)
    .then((res) => {
      activeSnack.value = true;
    });
};
</script>
