<template>
  <div>
    <AddNewTaskDialog @reload="getTickets"  @getErrors="getErrors"/>
    <div v-if="tickets">
      <table-component
        :showSelect="false"
        itemKey="id"
        :headers="headers"
        :items="tickets"
        :loading="loading"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#40BE72" small depressed dark v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <!-- <v-list-item @click="openTaskConversation(item)">
                <v-list-item-icon class="ml-2"
                  ><v-icon>mdi-eye-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>فتح</v-list-item-title>
              </v-list-item> -->

              <router-link
                target="_blank"
                style="text-decoration: none; display: flex"
                :to="{ path: `/agent/tickets/${item.id}` }"
              >
                <v-list-item>
                  <v-list-item-icon class="ml-2"
                    ><v-icon>mdi-eye-outline</v-icon>
                  </v-list-item-icon>
                <v-list-item-title>فتح في تاب جديد</v-list-item-title>
                </v-list-item>
              </router-link>

            </v-list>
          </v-menu>
        </template>

        <template slot="item.created_at" slot-scope="props">
          {{ formatDate(props.item.created_at) }}
        </template>

        <template slot="item.status" slot-scope="props">
          {{ props.item.status }}
        </template>

        <template slot="item.sent_name" slot-scope="props">
          {{ props.item.user.name }}
        </template>

        <template slot="item.recieved_name" slot-scope="props">
          {{ props.item.receive.name }}
        </template>
      </table-component>
    </div>

    <TasksDetailsRequestSection
      v-if="this.$store.state.openTasksDetailsRequestSection"
      style="margin-top: 50px"
    />

    <v-snackbar
      v-model="active"
      color="red"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <span class="font-weight-bold" style="font-size: 16px">{{ message }}</span>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script setup>
import { axiosInstance } from "@/axios/config";
import router from "@/routes";
import { formatDate } from "@/mixins/formatDate";
import { onMounted, ref } from "vue";
import AddNewTaskDialog from "./SMAddNewTaskDialog.vue";
import TasksDetailsRequestSection from "./SMTasksDetailsRequestSection.vue";
import { store } from "@/store";

const tickets = ref([]);
const loading = ref(false);
const active = ref(false);
const message = ref("");
const getTickets = () => {
  store.state.openTasksDetailsRequestSection = false;
  loading.value = true;
  axiosInstance.get(`/agent/Tasks/${router.currentRoute.params.id}`).then((res) => {
    tickets.value = res.data.payload;
    loading.value = false;
  });
};

const getErrors = (m) => {
  active.value = true;
  message.value = m;
};

const headers = ref([
  { text: "تاريخ التذكرة", value: "created_at", width: "10%" },
  { text: "حالة التذكرة", value: "status", width: "10%" },
  { text: "مرسل التذكرة", value: "sent_name", width: "10%" },
  { text: "مستلم التذكرة", value: "recieved_name", width: "10%" },
  { text: "العمليات", value: "action", align: "center", width: "3%" },
]);

const openTaskConversation = (item) => {
  store.state.tasksDetailsRequestId = item.id;
  store.state.openTasksDetailsRequestSection = true;
};

onMounted(() => {
  getTickets();
});
</script>
