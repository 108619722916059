<template>
  <div class="extra-requests">
    <div
      class="printing mb-3"
      style="
        background: white;
        padding: 10px;
        justify-content: center;
        box-shadow: 0px 0px 6px -4px black;
        border-radius: 10px;
        margin-top: 20px;
      "
      :style="items.length <= 0 ? 'display:none' : 'display:flex'"
    >
      <div style="width: 100%">
        <v-spacer class="top-spacer" />
        <div style="display: flex; justify-content: space-between" v-if="!shouldHideDiv">
          <div>
            <v-btn
              v-if="!hidden_baskets.includes('latest')"
              :class="active_tab == 'latest' ? 'mx-2 tab_active' : 'mx-2'"
              active-class="purple white--text"
              depressed
              rounded
              @click="change_tab_requests('latest')"
              style="
                margin-top: 7px;
                border: 1px solid purple;
                background: rgb(251 246 245);
                color: purple;
                font-weight: bold;
                box-shadow: 0 0 0 0;
                border-radius: 4px;
              "
            >
              الطلبات الحرة
            </v-btn>
            <v-btn
              v-if="!hidden_baskets.includes('archives')"
              :class="active_tab == 'archives' ? 'mx-2 tab_active' : 'mx-2'"
              active-class="purple white--text"
              depressed
              rounded
              @click="change_tab_requests('archives')"
              style="
                margin-top: 7px;
                border: 1px solid purple;
                background: rgb(251 246 245);
                color: purple;
                font-weight: bold;
                box-shadow: 0 0 0 0;
                border-radius: 4px;
              "
            >
              الطلبات المؤرشفة
            </v-btn>
            <v-btn
              v-if="!hidden_baskets.includes('additional')"
              :class="active_tab == 'additional' ? 'mx-2 tab_active' : 'mx-2'"
              active-class="purple white--text"
              depressed
              rounded
              @click="change_tab_requests('additional')"
              style="
                margin-top: 7px;
                border: 1px solid purple;
                background: rgb(251 246 245);
                color: purple;
                font-weight: bold;
                box-shadow: 0 0 0 0;
                border-radius: 4px;
              "
            >
              الطلبات المعلقة
            </v-btn>
            <!--            <v-btn
                :class="(active_tab == 'archives')? 'mx-2 tab_active' : 'mx-2'"
                active-class="purple white&#45;&#45;text"
                depressed
                rounded
                @click="change_tab_requests('archives')"
                style="
                  margin-top: 7px;
                  border: 1px solid purple;
                  background: rgb(251 246 245);
                  color: purple;
                  font-weight: bold;
                  box-shadow: 0 0 0 0; 
                  border-radius: 4px;
       "        
              >
              الطلبات المؤرشفة
              </v-btn>-->
            <v-btn
              v-if="!hidden_baskets.includes('freez')"
              :class="active_tab == 'freez' ? 'mx-2 tab_active' : 'mx-2'"
              active-class="purple white--text"
              depressed
              rounded
              @click="change_tab_requests('freez')"
              style="
                margin-top: 7px;
                border: 1px solid purple;
                background: rgb(251 246 245);
                color: purple;
                font-weight: bold;
                box-shadow: 0 0 0 0;
                border-radius: 4px;
              "
            >
              الطلبات المجمدة
            </v-btn>
          </div>
          <div>
            <v-btn
              depressed
              width="45"
              height="45"
              :color="$vuetify.theme.themes.light.dataActionBTN"
              dark
              fab
              class="ml-1 hidden-sm-and-down"
              @click="exportExcel"
              v-if="!$store.state.cardsStyle"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.116"
                height="18.116"
                viewBox="0 0 12.25 14"
              >
                <g id="excel-file" transform="translate(-1.5)">
                  <path
                    id="Path_4097"
                    data-name="Path 4097"
                    d="M11.708,14H1.792a.292.292,0,0,1-.292-.292V3.548a.292.292,0,0,1,.074-.194L4.491.1A.292.292,0,0,1,4.708,0h7A.292.292,0,0,1,12,.292V7a.292.292,0,0,1-.583,0V.583H4.839L2.083,3.659v9.757h9.333V12.25a.292.292,0,1,1,.583,0v1.458A.293.293,0,0,1,11.708,14Z"
                    fill="#fff"
                  />
                  <path
                    id="Path_4098"
                    data-name="Path 4098"
                    d="M4.917,4.292H2.292a.292.292,0,0,1,0-.583H4.625V.792a.292.292,0,0,1,.583,0V4A.292.292,0,0,1,4.917,4.292Zm8.167,8.167h-7a.876.876,0,0,1-.875-.875v-3.5a.876.876,0,0,1,.875-.875h7a.876.876,0,0,1,.875.875v3.5A.876.876,0,0,1,13.083,12.458Zm-7-4.667a.292.292,0,0,0-.292.292v3.5a.292.292,0,0,0,.292.292h7a.293.293,0,0,0,.292-.292v-3.5a.293.293,0,0,0-.292-.292Z"
                    transform="translate(-0.208 -0.208)"
                    fill="#fff"
                  />
                  <path
                    id="Path_4099"
                    data-name="Path 4099"
                    d="M11.29,15.67,10.566,14.6h.3a.075.075,0,0,1,.047.012.1.1,0,0,1,.027.03l.572.878a.4.4,0,0,1,.032-.07l.54-.8a.186.186,0,0,1,.03-.036.058.058,0,0,1,.039-.012h.283l-.726,1.052.751,1.139h-.293a.079.079,0,0,1-.053-.017.176.176,0,0,1-.031-.038l-.588-.919a.316.316,0,0,1-.027.061l-.572.858a.21.21,0,0,1-.032.039.065.065,0,0,1-.05.018h-.275l.755-1.125Zm1.754.876h.948V16.8H12.747V14.6h.3Zm2.4-1.6a.117.117,0,0,1-.03.034.059.059,0,0,1-.039.012.111.111,0,0,1-.061-.026q-.042-.031-.086-.058a.805.805,0,0,0-.128-.057.558.558,0,0,0-.18-.026.575.575,0,0,0-.176.027.377.377,0,0,0-.128.073.279.279,0,0,0-.076.109.348.348,0,0,0-.026.134.243.243,0,0,0,.045.152.385.385,0,0,0,.119.1.952.952,0,0,0,.168.074l.193.065c.065.023.131.048.192.075a.658.658,0,0,1,.168.107.486.486,0,0,1,.12.158.515.515,0,0,1,.045.229.735.735,0,0,1-.049.269.632.632,0,0,1-.143.22.672.672,0,0,1-.231.146.849.849,0,0,1-.311.054.956.956,0,0,1-.389-.077.908.908,0,0,1-.3-.209l.086-.14a.107.107,0,0,1,.03-.029.07.07,0,0,1,.038-.012.118.118,0,0,1,.073.034.946.946,0,0,0,.1.076.608.608,0,0,0,.366.107.577.577,0,0,0,.188-.029.378.378,0,0,0,.258-.373.269.269,0,0,0-.045-.163.38.38,0,0,0-.118-.106.832.832,0,0,0-.167-.072l-.192-.061a2.009,2.009,0,0,1-.192-.073.608.608,0,0,1-.167-.109.484.484,0,0,1-.118-.164.571.571,0,0,1-.045-.244.579.579,0,0,1,.177-.418.622.622,0,0,1,.211-.134.75.75,0,0,1,.29-.05.921.921,0,0,1,.335.058.773.773,0,0,1,.264.169l-.072.141Z"
                    transform="translate(-3.765 -6.075)"
                    fill="#fff"
                  />
                </g>
              </svg>
            </v-btn>
            <v-btn
              depressed
              width="45"
              height="45"
              @click="printNow"
              :color="$vuetify.theme.themes.light.dataActionBTN"
              dark
              fab
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.116"
                height="18.116"
                viewBox="0 0 16.116 16.116"
              >
                <g
                  id="Icon_feather-printer"
                  data-name="Icon feather-printer"
                  transform="translate(-2.5 -2.5)"
                >
                  <path
                    id="Path_4011"
                    data-name="Path 4011"
                    d="M9,8.291V3h9.069V8.291"
                    transform="translate(-2.977)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                  <path
                    id="Path_4012"
                    data-name="Path 4012"
                    d="M6.023,20.3H4.512A1.512,1.512,0,0,1,3,18.791V15.012A1.512,1.512,0,0,1,4.512,13.5H16.6a1.512,1.512,0,0,1,1.512,1.512v3.779A1.512,1.512,0,0,1,16.6,20.3H15.093"
                    transform="translate(0 -5.209)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                  <path
                    id="Path_4013"
                    data-name="Path 4013"
                    d="M9,21h9.069v6.046H9Z"
                    transform="translate(-2.977 -8.931)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                </g>
              </svg>
            </v-btn>
          </div>
        </div>
        <v-spacer class="top-spacer" />
      </div>
    </div>
    <div style="margin-bottom: 40px" class="table__view"  v-if="!shouldHideDiv">
      <table-component
        :showSelect="true"
        :pagination="pagination"
        itemKey="id"
        :selectedRows.sync="selected_requests"
        @refresh="get_extra_requests"
        :headers="headers"
        :items="items"
        :loading="loading"
        v-model="page"
        :limit="12"
        v-if="!$store.state.cardsStyle"
      >
        <!-- eslint-disable -->
        <template v-slot:[`item.action`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#3f0e40" depressed small dark v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="move_extra_request(item)">
                <v-list-item-icon class="ml-3"
                  ><v-icon size="20">mdi-refresh</v-icon></v-list-item-icon
                >
                <v-list-item-title>سحب الطلب</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template slot="item.comment" slot-scope="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ props.item.comment }}</span>
            </template>
            <div class="font-weight-bold">{{ props.item.comment }}</div>
          </v-tooltip>
        </template>
        <template slot="item.quacomment" slot-scope="props">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ props.item.quacomment }}</span>
            </template>
            <div class="font-weight-bold">{{ props.item.quacomment }}</div>
          </v-tooltip>
        </template>
        <template slot="item.birth_date_higri" slot-scope="props">
          <span>{{
            props.item.customer ? props.item.customer.birth_date_higri : ""
          }}</span>
        </template>
        <template slot="item.work" slot-scope="props">
          <span>{{ props.item.customer ? props.item.customer.work : "" }}</span>
        </template>
        <template slot="item.customer_name" slot-scope="props">
          <span>{{ props.item.customer ? props.item.customer.name : "" }}</span>
        </template>
        <template slot="item.salary" slot-scope="props">
          <span>{{ props.item.customer ? props.item.customer.salary : "" }}</span>
        </template>
        <template slot="item.is_supported" slot-scope="props">
          <span>{{
            props.item.customer
              ? props.item.customer.is_supported != null
                ? props.item.customer.is_supported == "yes"
                  ? "نعم"
                  : "لا"
                : ""
              : ""
          }}</span>
        </template>
        <template slot="item.has_property" slot-scope="props">
          <span>{{
            props.item.customer
              ? props.item.customer.has_property != null
                ? props.item.customer.has_property == "yes"
                  ? "نعم"
                  : "لا"
                : ""
              : ""
          }}</span>
        </template>
        <template slot="item.owning_property" slot-scope="props">
          <span>{{
            props.item.real_estate
              ? props.item.real_estate.owning_property != null
                ? props.item.real_estate.owning_property == "1"
                  ? "نعم"
                  : "لا"
                : ""
              : ""
          }}</span>
        </template>
        <template slot="item.has_joint" slot-scope="props">
          <span>{{
            props.item.customer
              ? props.item.customer.has_joint != null
                ? props.item.customer.has_joint == "yes"
                  ? "نعم"
                  : "لا"
                : ""
              : ""
          }}</span>
        </template>
        <template slot="item.has_financial_distress" slot-scope="props">
          <span>{{
            props.item.customer
              ? props.item.customer.has_financial_distress != null
                ? props.item.customer.has_financial_distress == "yes"
                  ? "نعم"
                  : "لا"
                : ""
              : ""
          }}</span>
        </template>
        <template slot="item.has_obligations" slot-scope="props">
          <span>{{
            props.item.customer
              ? props.item.customer.has_obligations != null
                ? props.item.customer.has_obligations == "yes"
                  ? "نعم"
                  : "لا"
                : ""
              : ""
          }}</span>
        </template>
        <template slot="item.work" slot-scope="props">
          {{ props.item.customer ? props.item.customer.work : "" }}
        </template>
      </table-component>
    </div>

    <div style="display: flex; justify-content:center; text-align: center; margin-top: 120px;" v-else>
      <h2>لا يوجد طلبات لديك</h2>
    </div>

    <!-- Cards -->
    <!-- <CardsStyle :tableData="items" /> -->
    <!-- Cards -->
    <v-btn
      fab
      :color="$vuetify.theme.themes.light.dialogActionBTN"
      @click="addNewRequestDialog = true"
      class="my-10"
      style="position: fixed; left: 20px; bottom: 30px"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.425"
        height="21.166"
        viewBox="0 0 25.425 21.166"
      >
        <g
          id="Icon_feather-user-plus"
          data-name="Icon feather-user-plus"
          transform="translate(1 1)"
        >
          <path
            id="Path_2554"
            data-name="Path 2554"
            d="M17.471,28.889v-2.13A4.259,4.259,0,0,0,13.212,22.5H5.759A4.259,4.259,0,0,0,1.5,26.759v2.13"
            transform="translate(-1.5 -9.723)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_2555"
            data-name="Path 2555"
            d="M15.268,8.759A4.259,4.259,0,1,1,11.009,4.5,4.259,4.259,0,0,1,15.268,8.759Z"
            transform="translate(-3.023 -4.5)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_2556"
            data-name="Path 2556"
            d="M30,12v6.389"
            transform="translate(-9.77 -6.676)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_2557"
            data-name="Path 2557"
            d="M31.889,16.5H25.5"
            transform="translate(-8.464 -7.982)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </svg>
    </v-btn>
    <add-new-request
      v-model="addNewRequestDialog"
      width="350px"
      @submitted="get_extra_requests"
    />
    <br />
    <br />
    <AgentChat />
  </div>
</template>

<script>
import Axios from "axios";
// import CardsStyle from "@/components/Base/CardsStyle.vue";
import AgentChat from "@/components/AgentChat/AgentChat.vue";
import { axiosInstance } from "@/axios/config";


export default {
  name: "HelloWorld",
  components: { AgentChat },
  data: () => ({
    loading: false,
    hidden_baskets : [],
    tabs : ["latest","archives","additional","freez"],
    active_tab: "latest",
    pagination: {},
    headers: [
      { text: "تاريخ الطلب", value: "req_date" },
      { text: "اسم العميل", value: "customer_name" },
      { text: "تاريخ الميلاد هجري", value: "birth_date_higri" },
      { text: "جهة العمل", value: "work" },
      { text: "راتب العميل", value: "salary" },
      { text: "مدعوم", value: "is_supported" },
      { text: "وجد عقار", value: "has_property" },
      { text: "لديه متضامن", value: "has_joint" },
      { text: "لديه التزامات", value: "has_obligations" },
      { text: "لديه تعثرات", value: "has_financial_distress" },
      { text: "يمتلك عقار", value: "owning_property" },
      { text: "العمليات", value: "action", width: "1%" },
    ],
    page: 1,
    /* eslint-disable */
    items: [],
    selected_requests: [],
    addNewRequestDialog: false,
  }),
  methods: {
    move_extra_request(item) {
      if (this.active_tab == "additional") {
        Axios.get(
          `https://appproduct.alwsata.com.sa/api/agent/MoveAdditionalRequest/${item.id}`,
          {
            headers: this.request_headers(),
          }
        )
          .then(() => {
            this.showPopup("success", "تم سحب الطلب بنجاح");
            this.get_extra_requests();
          })
          .catch((err) => {
            this.showPopup("error", err.response.data.message);
          });
      } else {
        Axios.get(
          `https://appproduct.alwsata.com.sa/api/v2/employee/Requests/Move/${item.id}?type=${this.active_tab}`,
          {
            headers: this.request_headers(),
          }
        )
          .then(() => {
            this.showPopup("success", "تم سحب الطلب بنجاح");
            this.change_tab_requests(this.active_tab);
          })
          .catch((err) => {
            this.showPopup("error", err.response.data.message);
          });
      }
    },
    to_tickets_page(item) {
      this.$router.push({ name: "tickets", params: { id: item.id } });
    },
    get_extra_requests(page = 1) {
      this.loading = true;
      Axios.get(
        `https://appproduct.alwsata.com.sa/api/v2/employee/Requests?type=${this.active_tab}&page=${page}&per_page=${this.$store.state.counterOfShow}`,
        {
          headers: this.request_headers(),
        }
      ).then((response) => {
        this.items = response.data.payload.data;
        // this.pagination = {
        //   limit: 12,
        //   count: response.data.payload.count_of_additional,
        // };
        this.headers = [
          { text: "تاريخ الطلب", value: "req_date", align: "center" },
          { text: "اسم العميل", value: "customer_name", align: "center" },
          { text: "تاريخ الميلاد هجري", value: "birth_date_higri", align: "center" },
          { text: "جهة العمل", value: "work", align: "center" },
          { text: "راتب العميل", value: "salary", align: "center" },
          { text: "مدعوم", value: "is_supported", align: "center" },
          { text: "وجد عقار", value: "has_property", align: "center" },
          { text: "لديه متضامن", value: "has_joint", align: "center" },
          { text: "لديه التزامات", value: "has_obligations", align: "center" },
          { text: "لديه تعثرات", value: "has_financial_distress", align: "center" },
          { text: "يمتلك عقار", value: "owning_property", align: "center" },
          { text: "العمليات", value: "action", align: "center" },
        ];
        if (this.active_tab == "additional") {
          this.pagination = {
            limit: 12,
            count: response.data.payload.count_of_additional,
          };
        } else if (this.active_tab == "freez") {
          this.pagination = {
            limit: 12,
            count: response.data.payload.count_of_freez,
          };
        } else if (this.active_tab == "archives") {
          this.pagination = {
            limit: 12,
            count: response.data.payload.count_of_archives,
          };
        } else if (this.active_tab == "latest") {
          this.headers = [
            /*
            { text: "تاريخ الطلب", value: "req_date" },
*/
            { text: "اسم العميل", value: "customer_name", align: "center" },
            { text: "تاريخ الميلاد هجري", value: "birth_date_higri", align: "center" },
            { text: "جهة العمل", value: "work", align: "center" },
            /*{ text: "راتب العميل", value: "salary" },*/
            { text: "مدعوم", value: "is_supported", align: "center" },
            { text: "وجد عقار", value: "has_property", align: "center" },
            // { text: "لديه متضامن", value: "has_joint" },
            // { text: "لديه التزامات", value: "has_obligations" },
            // { text: "لديه تعثرات", value: "has_financial_distress" },
            { text: "يمتلك عقار", value: "owning_property", align: "center" },
            { text: "العمليات", value: "action", align: "center" },
          ];
          this.pagination = {
            limit: 12,
            count: response.data.payload.count_of_latest,
          };
        }
        this.loading = false;
      });
    },
    change_tab_requests(type) {
      this.active_tab = type;
      this.page = 1;
      this.loading = true;
      Axios.get(
        `https://appproduct.alwsata.com.sa/api/v2/employee/Requests?type=${type}&page=1&per_page=12`,
        {
          headers: this.request_headers(),
        }
      ).then((response) => {
        this.items = response.data.payload.data;
        this.headers = [
          { text: "تاريخ الطلب", value: "req_date" },
          { text: "اسم العميل", value: "customer_name" },
          { text: "تاريخ الميلاد هجري", value: "birth_date_higri" },
          { text: "جهة العمل", value: "work" },
          { text: "راتب العميل", value: "salary" },
          { text: "مدعوم", value: "is_supported" },
          { text: "وجد عقار", value: "has_property" },
          { text: "لديه متضامن", value: "has_joint" },
          { text: "لديه التزامات", value: "has_obligations" },
          { text: "لديه تعثرات", value: "has_financial_distress" },
          { text: "يمتلك عقار", value: "owning_property" },
          { text: "العمليات", value: "action", width: "1%" },
        ];
        if (this.active_tab == "additional") {
          this.pagination = {
            limit: 12,
            count: response.data.payload.count_of_additional,
          };
        } else if (this.active_tab == "freez") {
          this.pagination = {
            limit: 12,
            count: response.data.payload.count_of_freez,
          };
        } else if (this.active_tab == "archives") {
          this.pagination = {
            limit: 12,
            count: response.data.payload.count_of_archives,
          };
        } else if (this.active_tab == "latest") {
          this.headers = [
            // { text: "تاريخ الطلب", value: "req_date" },
            { text: "اسم العميل", value: "customer_name" },
            { text: "تاريخ الميلاد هجري", value: "birth_date_higri" },
            { text: "جهة العمل", value: "work" },
            { text: "راتب العميل", value: "salary" },
            { text: "مدعوم", value: "is_supported" },
            { text: "وجد عقار", value: "has_property" },
            // { text: "لديه متضامن", value: "has_joint" },
            // { text: "لديه التزامات", value: "has_obligations" },
            // { text: "لديه تعثرات", value: "has_financial_distress" },
            { text: "يمتلك عقار", value: "owning_property" },
            { text: "العمليات", value: "action", width: "1%" },
          ];
          this.pagination = {
            limit: 12,
            count: response.data.payload.count_of_latest,
          };
        }
        this.loading = false;
      });
    },
    exportExcel() {
      const table = document.querySelector(".extra-requests table");
      this.exportTableToCsv(table, "طلبات اضافية");
    },
    printNow() {
      let dataArr = [];
      this.items.forEach((item) => {
        let obj = {};
        obj.req_date = item.req_date;
        obj.customer_name = item.customer.name;
        obj.birth_date_higri = item.customer.birth_date_higri;
        obj.work = item.customer.work;
        obj.salary = item.customer.salary;
        obj.is_supported = item.customer.is_supported;
        obj.has_property = item.real_estate.has_property;
        obj.has_joint = item.customer.has_joint;
        obj.has_obligations = item.customer.has_obligations;
        obj.has_financial_distress = item.customer.has_financial_distress;
        obj.owning_property = item.real_estate.owning_property;
        dataArr.push(obj);
      });
      this.printTable(dataArr, this.headers, "جدول الطلبات الاضافية");
    },

    async get_hidden_baskets() {
      await Axios.get("https://appproduct.alwsata.com.sa/api/employee/admin/get-Basket-settings-data-for-agent", {
        headers: this.request_headers(),
      }).then((response) => {
        const basketPaths = response.data.payload.data.map(item => item.basket_path);
        this.$store.state.hidden_baskets_for_user = basketPaths;
        this.hidden_baskets = basketPaths;
      });
    },

  },

  computed: {
    shouldHideDiv() {
      const requiredValues = ["latest","archives","additional","freez"];
      return requiredValues.every(value => this.hidden_baskets.includes(value));
    },
  },

  watch: {
    page(val) {
      this.get_extra_requests(val);
    },

    active_tab(val) {
      this.change_tab_requests(val);
    },

    selected_requests(val) {
      this.$emit("requests", val);
    },

    "$store.state.first_not_processed_task_id": {
      handler(val) {
        if (val > 0 && localStorage["role"] == "0") {
          this.$store.state.showAlertOfTasksDidntProccess = true;
          this.$router.push({ name: 'ticket_details', params: { ticket: this.$store.state.first_not_processed_task_id } });
        }
      },
      immediate: true 
    },
    
    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval != oldvalue) {
          this.get_extra_requests(this.page);
        }
      },
    },

    "$store.state.hidden_baskets_for_user": {
      handler(newval, oldvalue) {
        if (newval != oldvalue) {
          if (newval.includes(this.active_tab)) {
            console.log("this.newval 2 ")
            let new_tab = this.tabs.find(tab => !newval.includes(tab));
            if (new_tab) {
              this.active_tab = new_tab;
            }
          }
        }
      },
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },
  },

  beforeMount(){
    this.get_hidden_baskets();
  },

  created() {
    this.$store.state.opened = -1;
    this.$store.state.cardsStyle = false;
    this.get_extra_requests();
  },
};
</script>

<style>
.tab_active {
  background-color: #3e0e3f !important;
  color: white !important;
}
</style>
