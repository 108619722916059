export const AddToNeedActionIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.121"
            height="17.121"
            viewBox="0 0 17.121 17.121"
        >
            <g
                id="Icon_feather-arrow-up-right"
                data-name="Icon feather-arrow-up-right"
                transform="translate(-9.439 -9.439)"
            >
                <path
                    id="Path_5860"
                    data-name="Path 5860"
                    d="M10.5,25.5l15-15"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
                <path
                    id="Path_5861"
                    data-name="Path 5861"
                    d="M10.5,10.5h15v15"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
            </g>
        </svg>

    )
}