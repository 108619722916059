<template>
  <div>
    <v-list-item @click="toRequestDetails(item)">
      <v-list-item-icon class="ml-2">
        <v-icon>mdi-eye-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-title>فتح</v-list-item-title>
    </v-list-item>

    <router-link
      style="text-decoration: none"
      target="_blank"
      :to="{ name: 'request_details', params: { id: item } }"
    >
      <v-list-item>
        <v-list-item-icon class="ml-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.528"
            height="15.111"
            viewBox="0 0 13.528 10.111"
          >
            <g
              id="Icon_feather-eye-dark"
              data-name="Icon feather-eye-dark"
              transform="translate(-1 -5.5)"
            >
              <path
                id="Path_4040"
                data-name="Path 4040"
                d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
              <path
                id="Path_4041"
                data-name="Path 4041"
                d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                transform="translate(-7.444 -4.653)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title> فتح في تاب جديد</v-list-item-title>
      </v-list-item>
    </router-link>

    <v-list-item @click="getRequestCalls(item)">
      <v-list-item-icon class="ml-3">
        <RequestCallsIcon />
      </v-list-item-icon>
      <v-list-item-title>المكالمات</v-list-item-title>
    </v-list-item>

    <v-list-item @click="convertRequest(item)">
      <v-list-item-icon class="ml-3">
        <ConvertRequestIcon />
      </v-list-item-icon>
      <v-list-item-title>تحويل الطلب</v-list-item-title>
    </v-list-item>

    <v-list-item @click="sendToQuality(item)">
      <v-list-item-icon class="ml-2">
        <SendToQualityIcon />
      </v-list-item-icon>
      <v-list-item-title>ارسال الطلب للجوده</v-list-item-title>
    </v-list-item>

    <v-list-item @click="tickets(item)">
      <v-list-item-icon class="ml-2">
        <TicketIcon />
      </v-list-item-icon>
      <v-list-item-title>التذاكر</v-list-item-title>
    </v-list-item>

    <v-list-item @click="addToConvert(item)">
      <v-list-item-icon class="ml-2">
        <AddToNeedActionIcon />
      </v-list-item-icon>
      <v-list-item-title>اضافه الي سله التحويل</v-list-item-title>
    </v-list-item>

    <v-list-item @click="toOrderRequest(item)">
      <v-list-item-icon class="ml-2">
        <OrderHistory />
      </v-list-item-icon>
      <v-list-item-title>سجل الطلب</v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import { ConvertRequestIcon } from "@/assets/adminIcons/convertRequest";
import { SendToQualityIcon } from "@/assets/adminIcons/sendToQualityIcon";
import { TicketIcon } from "@/assets/adminIcons/ticketIcon";
import { AddToNeedActionIcon } from "@/assets/adminIcons/addToNeedActionIcon";
import { OrderHistory } from "@/assets/adminIcons/orderHistory";
import { axiosInstance } from "@/axios/config";
import { RequestCallsIcon } from "@/assets/adminIcons/requestCalls";

export default {
  name: "FeaturedSingleProcess",
  components: {
    ConvertRequestIcon,
    SendToQualityIcon,
    TicketIcon,
    AddToNeedActionIcon,
    OrderHistory,
    RequestCallsIcon,
  },
  props: ["item", "selectedUser"],

  data() {
    return {
      label: "",
      message: "",
    };
  },

  watch: {
    selectedUser(val) {
      this.selectedValue(val);
    },
  },
  methods: {
    toOrderRequest(item) {
      this.$store.state.orderRequestId = item;
      if (this.$store.state.showCards || this.$store.state.showCards == false) {
        this.$store.state.orderRequestDialog = true;
      } else {
        this.$store.state.openOrderRequestSection = true;
      }
    },

    getRequestCalls(item) {
      this.$store.state.idOfRequestCalls = item;
      this.$store.state.openCallsDialog = true;
    },

    toRequestDetails(item) {
      this.$router.push({
        name: "request_details",
        params: { id: item },
      });
    },

    convertRequest(item) {
      this.$store.state.openDialog = true;
      this.$store.state.selectId = item;
      this.getUserAdmin();
    },

    getUserAdmin() {
      this.label = "استشاري المبيعات";
      axiosInstance.get("/v2/employee/sales-agent-users").then((res) => {
        this.$emit("getUsersNames", res.data.payload, this.label);
      });
    },

    sendToQuality(item) {
      this.$store.state.openDialog = true;
      this.getSupAdmin();
      this.$store.state.selectId = item;
    },

    selectedValue(itemSelected) {
      if (itemSelected.role === 5) {
        this.getQualityValues(itemSelected);
      } else if (itemSelected.role === 0) {
        this.getAgentRequest(itemSelected);
      }
    },

    getAgentRequest(itemSelected) {
      let body = new FormData();

      body.append("request_ids[0]", this.$store.state.selectId);
      body.append("agents_ids[0]", itemSelected.id);

      axiosInstance
        .post(`/v2/employee/Requests/moveAllRequests`, body)
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          let error = { err };
          this.message = error.err.response.data.message;
          this.showPopup("error", this.message);
        });
    },

    getQualityValues(itemSelected) {
      let body = new FormData();

      body.append("request_ids[0]", this.item);
      body.append("quality[0]", itemSelected.id);

      axiosInstance
        .post(`/v2/employee/Requests/moveToQualityReqArray`, body)
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          let error = { err };
          this.message = error.err.response.data.message;
        });
    },

    getSupAdmin() {
      this.label = "الجوده";
      axiosInstance.get("/v2/employee/quality-users").then((res) => {
        this.$emit("getUsersNames", res.data.payload, this.label);
      });
    },

    tickets(item) {
      this.$router.push({ name: "tickets", params: { id: item } });
    },

    addToConvert(item) {
      axiosInstance
        .get(`/v2/employee/Requests/addToNeedActionReq/${item}`)
        .then((res) => {
          this.showPopup("success", res.data.message);
        });
    },
  },
};
</script>
