<template>
  <div class="rates">
    <v-btn
      fab
      :color="$vuetify.theme.themes.light.dialogActionBTN"
      @click="addNewRequestDialog = true"
      class="my-10"
      style="position: fixed; left: 20px; bottom: 30px; z-index: 1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.425"
        height="21.166"
        viewBox="0 0 25.425 21.166"
      >
        <g
          id="Icon_feather-user-plus"
          data-name="Icon feather-user-plus"
          transform="translate(1 1)"
        >
          <path
            id="Path_2554"
            data-name="Path 2554"
            d="M17.471,28.889v-2.13A4.259,4.259,0,0,0,13.212,22.5H5.759A4.259,4.259,0,0,0,1.5,26.759v2.13"
            transform="translate(-1.5 -9.723)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_2555"
            data-name="Path 2555"
            d="M15.268,8.759A4.259,4.259,0,1,1,11.009,4.5,4.259,4.259,0,0,1,15.268,8.759Z"
            transform="translate(-3.023 -4.5)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_2556"
            data-name="Path 2556"
            d="M30,12v6.389"
            transform="translate(-9.77 -6.676)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_2557"
            data-name="Path 2557"
            d="M31.889,16.5H25.5"
            transform="translate(-8.464 -7.982)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </svg>
    </v-btn>
    <add-new-request v-model="addNewRequestDialog" width="350px" />
    <agent-chat />
    <div style="margin-bottom: 40px; margin-top: 20px" class="table__view">
      <table-component
        itemKey="id"
        @refresh="getRatesService"
        :headers="headers"
        :limit="10"
        :items="items"
        :pagination="pagination"
        :loading="loading"
        v-model="page"
      >
        <!-- eslint-disable -->
        <template v-slot:[`item.action`]="{ item }">
          <v-menu
            offset-y
            v-if="item.request != null && item.request.agent_id == item.user.id"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#3f0e40" small depressed dark v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="to_request_details(item)">
                <v-list-item-icon class="ml-2"
                  ><v-icon>mdi-eye-outline</v-icon></v-list-item-icon
                >
                <v-list-item-title>فتح الطلب</v-list-item-title>
              </v-list-item>

              <router-link
                style="text-decoration: none"
                target="_blank"
                :to="{ name: 'request_details', params: { id: item.request.id } }"
              >
                <v-list-item>
                  <v-list-item-icon class="ml-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18.528"
                      height="15.111"
                      viewBox="0 0 13.528 10.111"
                    >
                      <g
                        id="Icon_feather-eye-dark"
                        data-name="Icon feather-eye-dark"
                        transform="translate(-1 -5.5)"
                      >
                        <path
                          id="Path_4040"
                          data-name="Path 4040"
                          d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        />
                        <path
                          id="Path_4041"
                          data-name="Path 4041"
                          d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                          transform="translate(-7.444 -4.653)"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        />
                      </g>
                    </svg>
                  </v-list-item-icon>
                  <v-list-item-title> فتح في تاب جديد</v-list-item-title>
                </v-list-item>
              </router-link>

            </v-list>
          </v-menu>
        </template>

        <template slot="item.customer_name" slot-scope="props">
          {{ props.item.customer != null ? props.item.customer.name : "" }}
        </template>

        <template slot="item.stars" slot-scope="props">
          <span v-if="!props.item.stars"></span>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-list-item-icon class="ma-0 pa-0" v-for="index in 5" :key="index">
                  <v-icon size="25" color="warning">
                    {{
                      index <= props.item.stars ? "mdi-star" : "mdi-star-outline"
                    }}</v-icon
                  >
                </v-list-item-icon>
              </span>
            </template>
            <div class="font-weight-bold">
              {{
                props.item.stars > 1
                  ? props.item.stars + " نجوم "
                  : props.item.stars + " نجمة "
              }}
            </div>
          </v-tooltip>
        </template>

        <template slot="item.comments" slot-scope="props">
          <span v-if="!props.item.comments"></span>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ props.item.comments.substring(0, 40) + "..." }}</span>
            </template>
            <div class="font-weight-bold">{{ props.item.comments }}</div>
          </v-tooltip>
        </template>
      </table-component>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
export default {
  name: "RateServices",
  data() {
    return {
      items: [],
      headers: [
        {
          text: "تاريخ التقييم",
          value: "created_at",
          align: "center",
        },
        { text: "اسم العميل", value: "customer_name", align: "center" },
        { text: "التقييم", value: "stars", align: "center" },
        { text: "التعليق", value: "comments", align: "center" },
        { text: "العمليات", value: "action", align: "center" },
      ],
      loading: false,
      page: 1,
      addNewRequestDialog: false,
      pagination: {
        count: 0,
        limit: 0,
      },
    };
  },
  methods: {
    async getRatesService(retrive_payload = [], filter = false, page = 1) {
      this.loading = true;
      // this.page = 1;

      if (retrive_payload.length == 0 && !filter) {
        await Axios.get(
          `https://appproduct.alwsata.com.sa/api/employee/rates/all?page=${this.page}`,
          { headers: this.request_headers() }
        )
          .then((res) => {
            let response = res.data.payload;
            this.items = response.data;
            this.pagination = {
              limit: 10,
              count: response.total,
            };
            this.loading = false;
          })
          .catch((err) => console.log(err));
      } else {
        this.items = retrive_payload.data;
        this.pagination = {
          limit: 10,
          count: retrive_payload.total,
        };
        this.loading = false;
      }
    },
    to_request_details(item) {
      this.$router.push({
        name: "request_details",
        params: { id: item.request.id },
      });
    },
    resetPage() {
      this.page = 1;
    },
  },
  async created() {
    this.$store.state.opened = -1;
    await this.getRatesService();
  },
  mounted() {
    this.$root.$on("SEARCHRATESRESULT", (item, filter) => {
      this.getRatesService(item, filter);
    });

    this.$root.$on("RESETRATEPAGE", () => {
      this.resetPage();
    });
  },
  watch: {
    page(val) {
      this.getRatesService([], false, val);
    },

    "$store.state.first_not_processed_task_id": {
      handler(val) {
        if (val > 0 && localStorage["role"] == "0") {
          this.$store.state.showAlertOfTasksDidntProccess = true;
          this.$router.push({ name: 'ticket_details', params: { ticket: this.$store.state.first_not_processed_task_id } });
        }
      },
      immediate: true 
    },
    
  },
};
</script>
