<template>
  <div>
    <v-btn
      @click="dialog = true"
      fab
      class="mx-2 pa-0 genaralIcons"
      small
      style="background: #3f0e40; border-radius: 8px; left: 50px"
      :color="$vuetify.theme.themes.light.BtnBKColor"
      depressed
      width="40"
      height="40"
    >
      <v-icon color="white">mdi-magnify</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" persistent width="60%">
      <v-card style="padding: 20px">
        <v-card-title style="margin-bottom: 30px">البحث</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <SelectInput
                @selectedValues="getSelectedClient"
                :originalItems="client"
                label="العميل"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <SelectInput
                @selectedValues="getSelectedValueAdviser"
                :originalItems="agentsName"
                label="اسم الاستشاري"
              />
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-autocomplete
                :items="status"
                label="الحاله"
                v-model="filterObj.quality_request_need_turned_status"
                outlined
                dense
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title
                      :id="attrs['aria-labelledby']"
                      v-text="item"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
            الغاء
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="handleSearch"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { SearchIcon } from "@/assets/adminIcons/searchIcon";
import SelectInput from "@/components/Charts/SelectInput.vue";
import { axiosInstance } from "@/axios/config";
import { ref, onMounted, defineEmits } from "vue";
import { store } from "@/store";

const emit = defineEmits(["handleFilter"]);
const dialog = ref(false);
const filterObj = ref({
  quality_request_need_turned_customer_name: null,
  quality_request_need_turned_sales_agents: null,
  quality_request_need_turned_status: null,
});

const status = ref(["جديد", "تم تحويل الطلب", "تم رفض الطلب"]);
const handleSearch = () => {
  dialog.value = false;
  store.state.qualityObjFilter = filterObj;
  emit("handleFilter", filterObj.value);
};

const agentsName = ref([]);
const getAgentsName = () => {
  let body = new FormData();

  body.append("role", "agent");

  axiosInstance.post(`/employee/admin/filterUsers`, body).then((res) => {
    agentsName.value = res["data"]["payload"];
    agentsName.value.map((item) => {
      item["text"] = item["name"];
      item["color"] = "red";
      return item;
    });
  });
};

const getSelectedValueAdviser = (val) => {
  filterObj.value.quality_request_need_turned_sales_agents = val;
};

const client = ref([]);
const getClients = () => {
  axiosInstance.get(`/employee/quality/listCustomersOfNeedToBeTurnedReq`).then((res) => {
    client.value = res["data"]["payload"];
    client.value.map((item) => {
      item["text"] = item["name"];
      item["color"] = "red";
      return item;
    });
  });
};

const getSelectedClient = (val) => {
  filterObj.value.quality_request_need_turned_customer_name = val;
};

onMounted(() => {
  getAgentsName();
  getClients();
});
</script>

<style>
.genaralIcons {
  position: absolute;
  top: -50px;
  min-width: unset;
  z-index: 5;
}
</style>
