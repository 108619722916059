<template>
  <div>
    <v-list-item @click="toRequestDetails">
      <v-list-item-icon>
        <v-icon>mdi-eye-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-title>فتح الطلب</v-list-item-title>
    </v-list-item>

    <router-link
      style="text-decoration: none"
      target="_blank"
      :to="{ name: 'request_details', params: { id: id_of_request } }"
    >
      <v-list-item>
        <v-list-item-icon class="ml-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.528"
            height="15.111"
            viewBox="0 0 13.528 10.111"
          >
            <g
              id="Icon_feather-eye-dark"
              data-name="Icon feather-eye-dark"
              transform="translate(-1 -5.5)"
            >
              <path
                id="Path_4040"
                data-name="Path 4040"
                d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
              <path
                id="Path_4041"
                data-name="Path 4041"
                d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                transform="translate(-7.444 -4.653)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title> فتح في تاب جديد</v-list-item-title>
      </v-list-item>
    </router-link>

    <v-list-item @click="toOrderRequest">
      <v-list-item-icon>
        <OrderHistory />
      </v-list-item-icon>
      <v-list-item-title>سجل الطلب</v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import { OrderHistory } from "@/assets/adminIcons/orderHistory";
import { axiosInstance } from "@/axios/config";

export default {
  props: ["item"],
  components: { OrderHistory },
  data() {
    return {
      id_of_request: "",
      errorMassage: "",
    };
  },
  methods: {
    toRequestDetails() {
      this.$router.push({
        name: "request_details",
        params: { id: this.id_of_request },
      });
    },

    toOrderRequest() {
      this.$store.state.orderRequestId = this.id_of_request;
      this.$store.state.orderRequestDialog = true;
    },

    handleFilterResult(mobile) {
      let body = { customerPhone: mobile };
      axiosInstance
        .post("/employee/admin/getRequestByMobile", body)
        .then((res) => {
          if (res.data.payload.type_of_request === "request") {
            this.id_of_request = res.data.payload.id;
            this.errorMassage = null;
          } else if (res.data.payload.type_of_request === "pending") {
            this.errorMassage = "الرقم داخل الطلبات المعلقة";
          } else {
            this.errorMassage = "الرقم غير موجود";
          }
        })
        .catch((err) => {
          this.errorMassage = "حدث خطأ أثناء البحث";
        });
    },
  },
  created() {
    this.handleFilterResult(this.item);
  },
};
</script>
