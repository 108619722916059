<template>
  <div>
    <v-menu offset-y nudge-top="-7">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          size="45px"
          v-bind="attrs"
          v-on="on"
          style="z-index: 5; left: 10px; top: -53px; position: absolute"
        >
          <svg
            width="512"
            height="512"
            viewBox="0 0 512 512"
            fill="#3f0e40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M474.557 37.4414V168.577H328.851V37.4414H474.557ZM255.999 37.4414V474.557H37.4414V37.4414H255.999ZM474.557 197.719V314.281H328.851V197.719H474.556H474.557ZM474.557 343.422V474.557H328.851V343.422H474.556H474.557Z"
              fill="#3f0e40"
            />
          </svg>
        </v-avatar>
      </template>
      <v-list>
        <v-list-item
          class="pl-12"
          @click="$store.state.showCards = true"
          style="border-bottom: 2px solid #ededed"
        >
          <v-list-item-icon class="ml-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#3F0E40"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="four-squares-icon 1">
                <g id="Group">
                  <path
                    id="Vector"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.61524 12.7831H10.4074C10.7402 12.7831 11.0124 13.1075 11.0124 13.5034V21.0877C11.0124 21.4842 10.7404 21.8083 10.4074 21.8083H2.61524C2.28272 21.8083 2.01025 21.4842 2.01025 21.0877V13.5034C2.01025 13.1075 2.28272 12.7831 2.61524 12.7831ZM13.5925 2H21.3848C21.7178 2 21.9898 2.32407 21.9898 2.72043V10.3048C21.9898 10.7011 21.7178 11.0249 21.3848 11.0249H13.5925C13.2596 11.0249 12.988 10.7011 12.988 10.3048V2.72043C12.988 2.32407 13.2596 2 13.5925 2ZM2.60449 2H10.3971C10.73 2 11.0021 2.32407 11.0021 2.72043V10.3048C11.0021 10.7011 10.7302 11.0249 10.3971 11.0249H2.60449C2.27197 11.0249 2 10.7011 2 10.3048V2.72043C2 2.32407 2.27197 2 2.60449 2ZM13.6027 12.9753H21.3951C21.7276 12.9753 22 13.2994 22 13.6959V21.2803C22 21.6761 21.7276 22.0002 21.3951 22.0002H13.6027C13.2699 22.0002 12.9977 21.6761 12.9977 21.2803V13.6959C12.9977 13.2994 13.2699 12.9753 13.6027 12.9753Z"
                    fill="#3F0E40"
                  />
                </g>
              </g>
            </svg>
          </v-list-item-icon>
          <v-list-item-title style="line-height: 2"> عرض الكروت</v-list-item-title>
        </v-list-item>

        <v-list-item
          class="pl-12"
          @click="$store.state.showCards = false"
          style="border-bottom: 2px solid #ededed"
        >
          <v-list-item-icon class="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27.348"
              height="22.783"
              viewBox="0 0 24.348 19.783"
              fill="#3F0E40"
            >
              <path
                id="Icon_awesome-list-ul"
                data-name="Icon awesome-list-ul"
                d="M22.065,3.375a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,3.375Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,10.984Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283,2.283,2.283,0,0,1,2.283-2.283Zm-21.3.761H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,21.636V20.114A.761.761,0,0,1,.761,19.353Zm0-15.217H15.978a.761.761,0,0,1,.761.761V6.418a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,6.418V4.9A.761.761,0,0,1,.761,4.136Zm0,7.609H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,14.027V12.505A.761.761,0,0,1,.761,11.745Z"
                transform="translate(0 -3.375)"
                fill="#3F0E40"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-title style="line-height: 2">عرض الجدول</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- <v-text-field
      outlined
      v-model="$store.state.counterOfShow"
      dense
      width="10"
      height="35"
      color="white"
      style="
        position: absolute;
        top: -50px;
        left: 230px;
        min-width: unset;
        max-width: 50px !important;
        z-index: 5;
      "
      class="white-border"
    ></v-text-field> -->

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent width="auto">
          <v-card>
            <v-card-title class="text-h5"> </v-card-title>
            <v-card-text>
              {{ messages }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green-darken-1" variant="text" @click="dialog = false">
                الغاء
              </v-btn>
              <v-btn color="green-darken-1" variant="text" @click="sureToArchive">
                متاكد
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <TransferFilterDialog :selectedItems="selectedItems" :type="type" />

    <template>
      <v-row justify="center">
        <v-dialog v-model="dialogQuality" persistent width="624">
          <v-card>
            <v-card-title>
              <span class="text-h5" style="margin-bottom: 30px"
                >نقل الطلب الي الجوده</span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <SelectInput
                      @selectedValues="getSelectedValueManger"
                      :originalItems="qualityUsers"
                      label="الجوده"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="dialogQuality = false">
                الغاء
              </v-btn>
              <v-btn color="blue-darken-1" variant="text" @click="handleSendUserToAgent">
                تحويل
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <v-snackbar
      v-model="active"
      :color="snackColor"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <span class="font-weight-bold" style="font-size: 16px">{{ snackMessage }}</span>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style lang="scss">
.genaralIcons {
  position: absolute;
  top: -50px;
  min-width: unset;
  z-index: 5;
}

@media screen and (max-width: 626px) {
  .genaralIcons {
    top: -10px;
  }
}

.table-style svg {
  opacity: 0.4;

  &.active-table-style {
    opacity: 1;
  }
}

.white-border {
  .v-input--outlined {
    border-color: white !important; /* Set the border color to white */
  }

  .v-label {
    color: white !important; /* Set the label color to white */
  }

  .v-icon {
    color: white !important; /* Set the icon color to white */
  }

  input {
    color: white !important; /* Set the input text color to white */
  }
}
</style>

<script>
import TransferFilterDialog from "@/components/AdminComponent/TransferFilterDialog.vue";
import SelectInput from "@/components/Charts/SelectInput.vue";
import { qualityUser } from "@/mixins/qualityUser";
import { axiosInstance } from "@/axios/config";

export default {
  name: "HeaderIcons",
  components: {
    SelectInput,
    TransferFilterDialog,
  },
  props: ["selectedItems", "startItems", "type"],
  data() {
    return {
      qualityUsers: [],
      active: false,
      AllSelectedTable: [],
      dialog: false,
      message: "",
      snackColor: "",
      messages: "هل انت متاكد من رغبتك في ارشفت هذه الطلبات",
      allStatus: [],
      dialogQuality: false,
      selectedQuality: [],
      needToAction: [],
      snackMessage: "",
    };
  },
  computed: {
    showFeaturedProcess() {
      return window.location.pathname === "/admin/requests/featured";
    },
  },
  methods: {
    handleTableDesign() {
      this.$store.state.showCards = false;
    },

    handleSendUserToAgent() {
      this.dialogQuality = false;

      let url = "/v2/employee/Requests/moveToQualityReqArray";
      let body = new FormData();

      this.selectedQuality.forEach((item, index) => {
        body.append(`quality[${index}]`, item["id"]);
      });

      this.selectedItems.length &&
        this.selectedItems.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

      this.$store.state.startElement.length &&
        this.$store.state.startElement.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

      axiosInstance
        .post(url, body)
        .then((res) => {
          this.snackMessage = res.data.message;
          this.snackColor = "green";
          this.active = true;
        })
        .catch((err) => {
          let error = { err };
          this.snackMessage = error.err.response.data.message;
          this.snackColor = "red";
          this.active = true;
        });
    },

    getSelectedValueManger(val) {
      this.selectedQuality = val;
    },
    async convertRequest() {
      if (this.selectedItems.length || this.$store.state.startElement.length > 0) {
        this.dialogQuality = true;

        await qualityUser().then((res) => {
          this.qualityUsers = res.data.payload.map((item) => {
            item["color"] = "red";
            item["text"] = item["name_for_admin"] || item["name"];
            return item;
          });
        });
      }
    },

    moveToNeedAction() {
      if (this.selectedItems.length || this.$store.state.startElement.length > 0) {
        this.messages = "هل انت متأكد من تحويل هذه الطلبات";
        this.dialog = true;
      }
    },

    moveToFreez() {
      if (this.selectedItems.length || this.$store.state.startElement.length > 0) {
        this.messages = "  هل انت متأكد من تحويل هذه الطلبات الي سله التجميد";
        this.dialog = true;
      }
    },

    handleTurnuser() {
      if (this.selectedItems.length || this.$store.state.startElement.length > 0) {
        this.$store.state.turnuserDialog = true;
      }
    },

    handleTransferFilter() {
      if (this.selectedItems.length || this.$store.state.startElement.length > 0) {
        this.$store.state.TransferFilterDialog = true;
      }
    },

    toggleTableStyle(e) {
      document.querySelectorAll(".table-style").forEach((btn) => {
        btn.querySelector("svg").classList.remove("active-table-style");
      });
      e.target.querySelector("svg").classList.add("active-table-style");
    },

    requestArchived() {
      if (this.selectedItems.length || this.$store.state.startElement.length > 0) {
        this.dialog = true;
      }
    },

    sureToArchive() {
      if (this.messages === "هل انت متأكد من تحويل هذه الطلبات") {
        this.dialog = false;
        let url = "/v2/employee/Requests/moveToNeedActionReqArray";
        let body = new FormData();
        this.selectedItems.length > 0 &&
          this.selectedItems.forEach((item, index) => {
            body.append(`request_ids[${index}]`, item["id"]);
          });

        this.$store.state.startElement.length > 0 &&
          this.$store.state.startElement.forEach((item, index) => {
            body.append(`request_ids[${index}]`, item["id"]);
          });

        axiosInstance
          .post(url, body)
          .then((res) => {
            this.snackMessage = res.data.message;
            this.snackColor = "green";
            this.active = true;
          })
          .catch((err) => {
            let error = { err };
            this.snackMessage = error.err.response.data.message;
            this.snackColor = "red";
            this.active = true;
          });
      } else if (this.messages === "هل انت متاكد من رغبتك في ارشفت هذه الطلبات") {
        let url = "/v2/employee/Requests/Archive ";
        let body = new FormData();

        this.selectedItems.length &&
          this.selectedItems.forEach((item, index) => {
            body.append(`requests_ids[${index}]`, item["id"]);
          });

        this.$store.state.startElement.length &&
          this.$store.state.startElement.forEach((item, index) => {
            body.append(`requests_ids[${index}]`, item["id"]);
          });

        this.dialog = false;

        axiosInstance
          .post(url, body)
          .then((res) => {
            this.snackMessage = res.data.payload;
            this.snackColor = "green";
            this.active = true;
          })
          .catch((err) => {
            let error = { err };
            this.snackMessage = error.err.response.data.message;
            this.snackColor = "red";
            this.active = true;
          });
      } else if (
        this.messages === "  هل انت متأكد من تحويل هذه الطلبات الي سله التجميد"
      ) {
        this.dialog = false;
        let url = "/v2/employee/Requests/transferToFreezeReqArray";
        let body = new FormData();
        this.selectedItems.length &&
          this.selectedItems.forEach((item, index) => {
            body.append(`request_ids[${index}]`, item["id"]);
          });

        this.$store.state.startElement.length &&
          this.$store.state.startElement.forEach((item, index) => {
            body.append(`request_ids[${index}]`, item["id"]);
          });

        axiosInstance
          .post(url, body)
          .then((res) => {
            this.snackMessage = res.data.message;
            this.snackColor = "green";
            this.active = true;
            this.$emit("getData");
          })
          .catch((err) => {
            let error = { err };
            this.snackMessage = error.err.response.data.message;
            this.snackColor = "red";
            this.active = true;
          });
      }
    },
  },
};
</script>
