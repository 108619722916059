<template>
  <div v-if="!loading">
    <div v-if="!errorMassage">
      <div>
        <div class="card__title" v-for="(item, index) in [...returnBasketName]" :key="index"> الطلبات: {{ item }} </div>
        <FilterCard :CardData="data" />
      </div>
    </div>
    <div v-else class="noData">{{ this.errorMassage }}</div>
  </div>
  <div class="noData" v-else>
    <LoadingMore />
  </div>
</template>

<style>
.card__title {
  color: #2c2c2c;
  font-size: 16px;
  margin: 20px 0;
  font-weight: bold;
  /* display: inline-block; */
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  color: purple;
  font-size: 20px;
}
</style>

<script>
import axios from "axios";
import FilterCard from "./FilterCard.vue";
import LoadingMore from "@/components/Charts/LoadingMore.vue";

export default {
  name: "GlobalSearch",
  components: { FilterCard, LoadingMore },
  data() {
    return {
      loading: false,
      data: [],
      errorMassage: null,
    };
  },

  computed: {
  returnBasketName() {
    return this.data.found_in_basket.map(item => {
      if (item === 'archived') {
        return 'المؤرشفة';
      }

      if (item === 'need_actions_new') {
        return 'بحاجه للتحويل جديده';
      }

      if (item === 'need_actions_done') {
        return 'بحاجه للتحويل تمت معالجتها';
      }

      if (item === 'freez') {
        return "المجمده";
      }

      if (item === 'followed') {
        return "المتابعة";
      }

      if (item === 'stared') {
        return "المميزه";
      }

      if (item === 'received') {
        return "المستلمه";
      }

      if (item === 'additional') {
        return "المعلقه";
      }

      if (item === 'completed') {
        return "المكتمله";
      }

      if (item === 'need_to_be_returned_done') {
        return "بحاجه للتحويل جودة تمت معالجتها";
      }

      if (item === 'need_to_be_returned_new') {
        return "بحاجه للتحويل جوده جديده";
      }

      return item;
    });
  }
},

  watch: {
    "$route.query.phoneNum": {
      immediate: true, // This will trigger the watcher immediately when the component is created
      handler(newPhoneNum, oldPhoneNum) {
        if (newPhoneNum !== oldPhoneNum) {
          this.handleFilterResult();
        }
      },
    },
  },
  methods: {
    handleFilterResult() {
      this.loading = true;
      let url = `${this.$store.state.url}/employee/admin/globalSearchAdmin`;
      let body = { customerPhone: this.$route.query.phoneNum };
      axios
        .post(url, body, { headers: this.request_headers() })
        .then((res) => {
          this.loading = false;
          this.data = res.data.payload;
          this.errorMassage = null;
        })
        .catch((err) => {
          this.loading = false;
          this.errorMassage = "الرقم غير موجود";
        });
    },
  },
  created() {
    this.handleFilterResult();
  },
};
</script>

//fix issue in requests data not occuer (archieved) //add place to global search
