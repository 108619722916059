<template>
    <v-card class="pa-3 position-relative" style="height: 100%">
              <span
                class="right-color"
                :style="`position: absolute;
                  width: 6px;
                  height: 101.2%;
                  top: -1px;
                  right: -6px;${
                    item.status == `طلب تم فتحه`
                      ? `background-color:#40BE72`
                      : item.status == `طلب جديد`
                      ? `background-color:#3B5DE7`
                      : item.status == `مؤرشف لدى استشاري المبيعات`
                      ? `background-color:#017E84`
                      : item.status == `طلب مرفوض`
                      ? `background-color: indianred`
                      : item.status == `في انتظار موافقة مدير المبيعات`
                      ? `background-color: #FF715B`
                      : `background-color:black`
                  }`"
              ></span>
              <div class="card-head d-flex justify-space-between align-center">
                <v-card-title
                  class="pa-0"
                  style="font-size: 15px; font-weight: bold"
                  >{{ item?.name }}</v-card-title
                >
                
                <div class="icons">
                  
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        width="30"
                        height="30"
                        color="transparent"
                        depressed
                        fab
                        light
                        v-bind="attrs"
                        v-on="on"
                        style="margin-left: -10px"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="5.153"
                          height="27.986"
                          viewBox="0 0 4.153 18.986"
                        >
                          <path
                            id="menu"
                            d="M16.077,43.153a2.166,2.166,0,0,1-1.483-.623A2.066,2.066,0,0,1,14,41.077a2.341,2.341,0,0,1,.593-1.483A2.085,2.085,0,0,1,16.077,39a2.2,2.2,0,0,1,1.454.593,2.092,2.092,0,0,1-1.454,3.56Zm8.87-.623a2.1,2.1,0,0,0,.623-1.454,2.223,2.223,0,0,0-.623-1.483A2.066,2.066,0,0,0,23.493,39a2.278,2.278,0,0,0-1.483.593,2.085,2.085,0,0,0-.593,1.483,2.2,2.2,0,0,0,.593,1.454,2.042,2.042,0,0,0,2.937,0Zm7.417,0a2.1,2.1,0,0,0,.623-1.454,2.223,2.223,0,0,0-.623-1.483A2.066,2.066,0,0,0,30.91,39a2.278,2.278,0,0,0-1.483.593,2.085,2.085,0,0,0-.593,1.483,2.2,2.2,0,0,0,.593,1.454,2.042,2.042,0,0,0,2.937,0Z"
                            transform="translate(-39 32.986) rotate(-90)"
                            fill="#3b5de7"
                          />
                        </svg>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-if="action_type == 'open'" @click="to_request_details(item)">
                        <v-list-item-icon >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18.528"
                            height="15.111"
                            viewBox="0 0 13.528 10.111"
                          >
                            <g
                              id="Icon_feather-eye-dark"
                              data-name="Icon feather-eye-dark"
                              transform="translate(-1 -5.5)"
                            >
                              <path
                                id="Path_4040"
                                data-name="Path 4040"
                                d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                                fill="none"
                                stroke="#000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1"
                              />
                              <path
                                id="Path_4041"
                                data-name="Path 4041"
                                d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                                transform="translate(-7.444 -4.653)"
                                fill="none"
                                stroke="#000"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1"
                              />
                            </g>
                          </svg>
                        </v-list-item-icon>
                        <v-list-item-title>فتح</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="action_type == 'pull' && hidden_baskets.includes('pull_request_from_agent')">
                        <v-list-item-title>تم ايقاف الخاصية عنك</v-list-item-title>
                      </v-list-item>

                      <v-list-item v-if="action_type == 'pull' && !hidden_baskets.includes('pull_request_from_agent')" @click="pullRequest">
                        <v-list-item-title>سحب الطلب</v-list-item-title>
                      </v-list-item>

                    </v-list>
                  </v-menu>
                </div>
              </div>
              <div
                class="req-status-parent mt-1 d-flex justify-space-between align-center"
              >
                <span class="req-type">{{
                  item.type || "لايوجد نوع للطلب"
                }}</span>
                <!-- <span
                  class="req-status"
                  :style="`font-size: 13px;
                    padding: 0px 5px 5px;
                    color: white;${
                      item.status == `طلب تم فتحه`
                        ? `background-color:#40BE72`
                        : item.status == `طلب جديد`
                        ? `background-color:#3B5DE7`
                        : item.status == `مؤرشف لدى استشاري المبيعات`
                        ? `background-color:#017E84`
                        : item.status == `طلب مرفوض`
                        ? `background-color: indianred`
                        : item.status == `في انتظار موافقة مدير المبيعات`
                        ? `background-color: #FF715B`
                        : `background-color:black`
                    }`"
                  >{{ item.status }}</span
                > -->
              </div>
              <div
                class="req-date-parent my-2 d-flex justify-space-between align-center"
              >
                <span class="req-date">{{
                  (item.agent_date != null )? item.agent_date.split(" ")[0] : ""
                }}</span> 
                <span
                  class="req-status"
                  :style="`font-size: 13px; color: #2158aa; font-weight: bold`"
                  >{{ item.class_id_agent_text || "لايوجد تصنيف معاملة" }}</span
                >
              </div>
              <hr style="height: 1px !important; opacity: 0.3" class="mb-0" />
              <div class="desc parent">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-card-text v-on="on" class="pt-2 px-0">{{
                      item.agent_notes
                        ? item.agent_notes.length > 50
                          ? item.agent_notes.substring(0, 50) + "..."
                          : item.agent_notes
                        : "لايوجد ملاحظات"
                    }}</v-card-text>
                  </template>
                  <div>{{ item.agent_notes || "لايوجد ملاحظات" }}</div>
                </v-tooltip>
              </div>
            </v-card>
</template>

<script>

export default {
  props: {
    item:{
        required : true
    },
    action_type:{
      required: false
    },
    hidden_baskets:{
      requuired:false
    }
  },
  methods: {
    
    to_request_details(item) {
      this.$router.push({
        name: "request_details",
        params: { id: item.id },
      });
    },
    pullRequest(){
        this.$emit('pull-request',this.item.id);
    }
  },

  created(){
    console.log('hidden_baskets');
    console.log(this.hidden_baskets);
  }
};
</script>