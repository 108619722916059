<template>
    <div v-click-outside="clickOutside">

        <v-snackbar
          v-model="activeA"
          timeout="-1"
          
          right
          bottom
  color="red"
  dense
  outlined
  text
  type="error"
          class="ml-4"
          elevation="24"
          transition="fade-transition"
          v-for="(message,i) in messages" :key="i"
          :style="{'margin-bottom':calcMargin(i)}"
        >
          <div><span class="font-weight-bold" style="font-size: 16px">{{ message }}</span></div>
          <template v-slot:action>
            <v-btn icon small @click="hide(i)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
    </div>
</template>

<script>
export default {
  name: 'PopupErrorComponent',
  data() {
    return {
      activeA: false,
      text: "",
      messages: [],
      message: "",
      status: "success",
      link: null,
      copy: null
    };
  },
  computed: {
    copyText() {
      if (typeof this.copy != 'object') {
        return this.copy
      }
      return this.copy.content
    },
    hrefTarget () {
      return (this.link.newTap ? "_blank" : "_self")
    }
  },
  created() {},
  mounted() {
    this.$root.$on("popup-error", (status, messages, link = null, copy = null) => {
      this.activeA = true;
      this.text = "";
      this.status = status;
      this.messages = messages;
      this.link = link;
      this.copy = copy;
    });
  },
  methods: {
    clickOutside() {
      this.$nextTick(() => {
        this.activeA = false;
      });
    },
    calcMargin(i) {
        return (i*60) + 'px'
    },
    hide(i){
        this.messages.splice(i,1)
    }
  },
};
</script>
