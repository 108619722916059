<template>
  <div>
    <HeaderIcons
      :selectedItems="selectedItems"
      type="moveAllRequests"
      @getData="reloadPage"
    />


    <div v-if="!this.$store.state.allLoading" style="margin-top: 40px">
      <div v-if="$store.state.views === 'cards'">
        <div v-if="this.$store.state.allData.length > 0" style="margin-top: 40px">
          <RequestsCard
            :CardData="this.$store.state.allData"
            @back="handleBack"
            v-if="!this.$store.state.allLoading"
            @pageCount="handlePageNumber"
            @getUsersName="getUsersName"
          />
          <div>
            <LoadingMore v-if="loadMoreData" style="margin: 40px" />
          </div>
        </div>
        <div v-else>
          <div>
            <button @click="handleBack" class="button__back">رجوع</button>
          </div>
          <div class="noData">لا يوجد بيانات</div>
        </div>
      </div>

      <div v-else-if="$store.state.views === 'table'" class="table__view">

        <template>
          <v-row style="margin-top: 20px;">
            <v-col cols="12" sm="7" md="7">
              <div style="display: flex;margin-bottom:5px;">

                <div class="text-center right_bouttons" style="position: relative">
                  <v-btn
                    class="ma-2 white--text filter__border"
                    :style="{
                      'background-color': selectedTab === 2 ? '#3F0E40' : '#8E8E8E',
                    }"
                    @click="tabsFilter(2)"
                  >
                    <span :style="{ color: selectedTab === 2 ? 'white' : 'white' }">
                      الكل
                    </span>
                    <div :class="[selectedTab === 2  ? 'down__arrow' : '']"></div>
                  </v-btn>
                </div>

                <div class="text-center right_bouttons" style="position: relative">
                  <v-btn
                    class="ma-2 white--text filter__border"
                    :style="{
                      'background-color': selectedTab === 0 ? '#3F0E40' : '#8E8E8E',
                    }"
                    @click="tabsFilter(0)"
                  >
                    <span :style="{ color: selectedTab === 0 ? 'white' : 'white' }">
                      تحت المعالجة
                    </span>
                    <div :class="[selectedTab === 0  ? 'down__arrow' : '']"></div>
                  </v-btn>
                </div>

                <div class="text-center right_bouttons" style="position: relative">
                  <v-btn
                    class="ma-2 white--text filter__border"
                    :style="{
                      'background-color': selectedTab === 1 ? '#3F0E40' : '#8E8E8E',
                    }"
                    @click="tabsFilter(1)"
                  >
                    <span :style="{ color: selectedTab === 1 ? 'white' : 'white' }">
                    
                      تمت المعالجة
                    </span>
                    <div :class="[selectedTab === 1  ? 'down__arrow' : '']"></div>
                  </v-btn>
                </div>

              </div>
            </v-col>
          </v-row>
        </template>

        <table-component
          :showSelect="true"
          itemKey="id"
          :selectedRows.sync="selected_requests"
          :headers="headers"
          :pagination="this.$store.state.pagination"
          :limit="$store.state.counterOfShow"
          :items="this.$store.state.tableData"
          :loading="loading"
          @refresh="getTableData"
          v-model="page"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="white" small depressed dark v-bind="attrs" v-on="on">
                  <v-icon style="background: rgb(63, 14, 64); border-radius: 4px"
                    >mdi-dots-vertical</v-icon
                  >
                </v-btn>
              </template>
              <v-list>
                <AllSingleProcess :item="item" @getUsersName="getUsersName" @proccessRequest="proccessRequest"/>
              </v-list>
            </v-menu>
          </template>


          <template slot="item.agent_notes" slot-scope="props">
            <span v-if="!props.item.agent_notes"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.agent_notes.length > 7
                    ? props.item.agent_notes.substring(0, 7) + "..."
                    : props.item.agent_notes
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.agent_notes }}</div>
            </v-tooltip>
          </template>

        </table-component>
        <OrderRequestSection
          v-if="this.$store.state.openOrderRequestSection"
          style="margin-top: 50px"
        />
      </div>

      <div v-else-if="$store.state.views === 'default'">
        <div v-if="this.$store.state.allData.length > 0" style="margin-top: 40px">
          <DefaultView
            :CardData="this.$store.state.allData"
            @back="handleBack"
            v-if="!this.$store.state.allLoading"
            @pageCount="handlePageNumber"
            @getUsersName="getUsersName"
          />
          <div>
            <LoadingMore v-if="loadMoreData" style="margin: 40px" />
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
      "
      v-else
    >
      <LoadingMore />
    </div>
    <v-snackbar
      v-model="active"
      color="#40BE72"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <span class="font-weight-bold" style="font-size: 16px">{{ message }}</span>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <FilterDialogInAdmin pageName="administration_requests" />
    <OrderRequestDialog />
    <CallsDialog />
  </div>
</template>

<script>
import RequestsCard from "@/components/Charts/RequestsCard.vue";
import LoadingMore from "@/components/Charts/LoadingMore.vue";
import HeaderIcons from "@/components/Charts/HeaderIcons.vue";
import FilterDialogInAdmin from "@/components/Charts/FilterDialogInAdmin.vue";
import CallsDialog from "@/components/Charts/CallsDialog.vue";
import OrderRequestDialog from "@/components/AdminComponent/OrderRequestDialog.vue";
import OrderRequestSection from "@/components/AdminComponent/OrderRequestSection.vue";
import AllSingleProcess from "./AllSingleProcess.vue";
import { axiosInstance } from "@/axios/config";
import DefaultView from "@/components/Base/DefaultView.vue";

export default {
  name: "AdminSupport",
  components: {
    OrderRequestDialog,
    OrderRequestSection,
    FilterDialogInAdmin,
    RequestsCard,
    LoadingMore,
    HeaderIcons,
    AllSingleProcess,
    CallsDialog,
    DefaultView,
  },

  data() {
    return {
      allGetData: [],
      customers: [],
      search:'',
      selected_requests: [],
      pagination: {
        limit: 3,
        count: this.$store.state.searchedCount,
      },
      page: 1,
      selectedRows: [],
      allData: [],
      active: false,
      selectedTab:2,
      message: "",
      showSelect: { default: false },
      copySupportRequests: [],
      loadMoreData: false,
      count: null,
      loading: false,
      selectId: null,
      allStatus: [],
      label: "",
      selectedItems: [],
      countPage: 1,
      listenToFreezeAction: this.$store.state.freezeActionIsDone,
      headers: [
        { align: "center", value: "data-table-select" },
        { text: "عميل", value: "عميل", align: "center" },
        { text: "الجوال", value: "الجوال", align: "center" },
        { text: "استشاري المبيعات", value: "استشاري المبيعات", align: "center"},
        { text: "ملاحظه الاستشاري ", value: "agent_notes", align: "center"},
        { text: "تمت المعالجة", value: "تمت المعالجة", align: "center"},
        { text: "العمليات", value: "action", align: "center" },
      ],
    };
  },

  watch: {
    page(val) {
      if (this.$store.state.isSearch === false && this.search == null) {
        this.getTableData(val);
      } else {
        this.searchRequest(val, "table");
      }
    },

    selected_requests(val) {
      this.selectedItems = val;
      this.$emit("requests", val);
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval !== oldvalue && !this.$store.state.admin_search_active) {
          this.getTableData(this.page);
        }
      },
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },
  },

  beforeDestroy() {
    this.$store.state.openOrderRequestSection = false;
  },

  methods: {
    getUsersName(users, label, requestId) {
      this.allStatus = users;
      this.label = label;
      this.selectId = requestId;
    },

    tabsFilter(val){
      this.selectedTab = val;
      if(this.selectedTab == 2){
        this.$store.state.bodySearch.delete("AdministrationProcessed");
      }else{
        this.$store.state.bodySearch.append("AdministrationProcessed", this.selectedTab);
      }
      this.searchRequest(this.page, "table");
    },

    handleSearchFilter(){
      if(this.search != null && this.search != '' && this.search != undefined){
        this.$store.state.bodySearch.delete("CustomerNameOrComment");
        this.$store.state.bodySearch.append("CustomerNameOrComment", this.search);
      }else{
        this.$store.state.bodySearch.delete("CustomerNameOrComment");
      }
      this.searchRequest(this.page, "table");
    },

    handleBack() {
      this.$store.state.allData = [];
      this.$store.state.tableData = [];
      this.allGetData = [];
      this.$store.state.followLoading = true;
      this.$store.state.isSearch = false;
      this.$store.state.back = false;
      this.getAllRequests(1);
    },

    reloadPage() {
      this.$store.state.allData = [];
      this.$store.state.tableData = [];
      this.allGetData = [];
      this.$store.state.followLoading = true;
      this.$store.state.isSearch = false;
      this.getAllRequests(1);
      this.$store.state.startElement = [];
    },
    getAgentRequest(itemSelected) {
      let body = new FormData();

      body.append("request_ids[0]", this.selectId);
      body.append("agents_ids[0]", itemSelected.id);
      body.append("move_reason", itemSelected.move_reason);

      axiosInstance
        .post(`/v2/employee/Requests/moveAllRequests`, body, {
          headers: this.request_headers(),
        })
        .then((res) => {
          this.active = true;
          this.message = res.data.message;
        })
        .catch((err) => {
          this.active = true;
          let error = { err };
          this.message = error.err.response.data.message;
        });
    },

    selectedValue(itemSelected) {
      if (itemSelected.role === 5) {
        this.getQualityValues(itemSelected);
      } else if (itemSelected.role === 0) {
        this.getAgentRequest(itemSelected);
      }
    },

    getQualityValues(itemSelected) {
      let body = new FormData();

      body.append("request_ids[0]", this.selectId);
      body.append("quality[0]", itemSelected.id);

      axiosInstance
        .post(`/v2/employee/Requests/moveToQualityReqArray`, body, {
          headers: this.request_headers(),
        })
        .then((res) => {
          this.active = true;
          this.message = res.data.message;
        })
        .catch((err) => {
          this.active = true;
          let error = { err };
          this.message = error.err.response.data.message;
        });
    },

    proccessRequest(id){
      axiosInstance
        .get(`/v2/employee/Requests/proccessAdministrationRequest/${id}`,{
          headers: this.request_headers(),
        })
        .then((res) => {
          this.showPopup("success", res.data.message);
          this.searchRequest(this.page, "table");
        })
        .catch((err) => {
          let error = { err };
          this.showPopup("error", error.err.response.data.message);
        });
    },

    setTblData(res) {
      console.log("res", res);
      this.$store.state.tableData.push({
        id: res.id,
        isSelected: false,
        عميل: res.customer.name,
        الجوال: res.customer.mobile,
        "استشاري المبيعات": res.userBeforeSendingToAdministration?.name,
        agent_notes: res.agent_notes,
        "تمت المعالجة": res.is_administration_processed == 1 ? 'نعم' : 'لا',
        العمليات: res.sent_basket?.count,
      });
    },

    getCustomers() {
      axiosInstance.get("/employee/admin/getAllCustomer").then((res) => {
        this.customers = res.data.payload;
        this.customers.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      });
    },

    getTableData(count) {
      this.$store.state.tableData = [];
      this.loading = true;
      const pageNumber = Number(this.$store.state.counterOfShow);
      axiosInstance
        .get(`/v2/employee/Requests?type=administration_requests&page=${count}&per_page=${pageNumber}`, {
          headers: this.request_headers(),
        })
        .then((res) => {
          res.data.payload.data.map((item) => {
            this.setTblData(item);
          });
          this.loading = false;
        });
    },

    handlePageNumber(val) {
      if (this.$store.state.isSearch === false) {
        this.getAllRequests(val);
      } else {
        this.searchRequest(val);
      }
    },

    searchRequest(page, type) {
      this.loading = true;

      if (type === "table") {
        this.$store.state.tableData = [];
      }

      if (page > 1 || type === "table") {
        this.$store.state.bodySearch.delete("page");
        this.$store.state.bodySearch.append("page", page);
        // filter of processed or not 

        let url = `/v2/employee/Requests/search?type=administration_requests&per_page=${this.$store.state.counterOfShow}`;
        axiosInstance
          .post(url, this.$store.state.bodySearch, { headers: this.request_headers() })
          .then((res) => {
            this.$store.state.pagination = {
              limit: 3,
              count: res.data.payload.searched,
            };
            let response = res.data.payload.data.map((item) => {
              if (item.length === 0) {
                this.loading = false;
              }
              this.setTblData(item);
              item["starChecked"] = false;
              return item;
            });

            this.$store.state.allSearch.push(...response);
            this.removeDublicate(this.$store.state.allSearch);
            this.loading = false;
          });
      }
    },

    removeDublicate(array) {
      let uniqueArray = array.filter((item, index) => {
        return (
          index ===
          array.findIndex((obj) => {
            return obj.id === item.id;
          })
        );
      });
      this.$store.state.allData = uniqueArray;
    },

    getAllRequests(page) {
      if (
        this.$store.state.allData.length === 0 ||
        this.copySupportRequests.length === 0
      ) {
        this.$store.state.allLoading = true;
      }
      this.loadMoreData = true;
      axiosInstance
        .get(
          `/v2/employee/Requests?type=administration_requests&page=${page}&per_page=${this.$store.state.counterOfShow}`
        )
        .then((res) => {
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_administration_requests,
          };
          let response = res.data.payload.data.map((item) => {
            this.setTblData(item);
            item["starChecked"] = false;
            return item;
          });

          this.allGetData.push(...response);
          this.removeDublicate(this.allGetData);
          this.copySupportRequests.push(...response);
        })
        .catch((err) => {
          this.$store.state.allLoading = false;
          this.loadMoreData = false;
        })
        .finally(() => {
          this.loadMoreData = false;
          this.$store.state.allLoading = false;
        });
    },

    handleChangeInput(row) {
      row["selected"] = !row["selected"];
      let findRow = this.selectedRows.findIndex((item) => item["id"] === row["id"]);
      if (findRow !== -1) {
        this.selectedRows.splice(findRow, 1);
      } else {
        this.selectedRows.push(row);
      }
    },
  },
  created() {
    this.getCustomers();
    this.$store.state.tableData = [];
    this.$store.state.back = false;
    this.$store.state.isSearch = false;
    this.$store.state.bodySearch = new FormData();
    this.getAllRequests(1);
    this.$store.state.startElement = [];
  },
};
</script>

<style>
.button__back {
  width: 70px;
  height: 40px;
  border-radius: 5px;
  margin: 50px;
  color: white;
  background-color: #441545;
  direction: ltr;
}
.noData {
  display: flex;
  justify-content: center;
  font-size: 22px;
  color: #3f0e40;
  margin-top: 70px;
  font-weight: bold;
}

.faild__request {
  border: 1px solid red;
  color: red;
  display: inline-block;
  padding: 0 3px 3px 3px;
  border-radius: 4px;
}

.archive__request {
  border: 1px solid #dbb622;
  color: #dbb622;
  display: inline-block;
  padding: 0 3px 3px 3px;
  border-radius: 4px;
}

.anotherTypes__request {
  border: 1px solid #ff7b1c;
  color: #ff7b1c;
  display: inline-block;
  padding: 0 3px 3px 3px;
  border-radius: 4px;
}

.new__request {
  border: 1px solid green;
  color: green;
  padding: 0 4px 4px 4px;
  border-radius: 4px;
}

.table__view .v-btn:not(.v-btn--round).v-size--small {
  min-width: auto !important;
}
</style>
