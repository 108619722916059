<template>
  <div>
    <BasketSettingsFilter @filterData="handleFilter" @settingsIsAdded="getTableData"/>
    <div v-if="!this.$store.state.loadingData">
      <table-component
        :showSelect="false"
        itemKey="id"
        :headers="headers"
        :pagination="this.$store.state.pagination"
        :limit="10"
        :items="this.$store.state.tableData"
        :loading="loading"
        @refresh="getTableData"
        v-model="page"
      >
      <template v-slot:[`item.action`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#40BE72"
                small
                depressed
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="openSetting(item.id)">
                <v-list-item-icon class="ml-1">
                  <img
                    src="@/assets/icons/titcketIcon.svg"
                    alt="image"
                  />
                </v-list-item-icon>
                <v-list-item-title>فتح  </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </table-component>
    </div>
    <div class="loadingMore" v-else>
      <LoadingMore />
    </div>

  </div>
</template>

<style lang="scss">
.loadingMore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
</style>

<script>
import Axios from "axios";
import BasketSettingsFilter from "./BasketSettingsFilter.vue";
export default {
  name: "AdminLatestReport",
  components: {
    BasketSettingsFilter,
  },
  data() {
    return {
      page: 1,
      loading: false,
      copySupportRequests: [],
      loadMoreData: false,
      loadingData: false,
      iconToggle: false,
      headers: [
        { text: "السلة", value: "السلة" , width: "25%"},
        { text: "المسار", value: "المسار" , width: "40%"},
        { text: "المستخدم", value: "المستخدم" , width: "10%"},
        { text: "العمليات", value: "action", width: "10%" },
      ],
    };
  },

  watch: {
    page(val) {
      this.getTableData(val);
    },
  },

  methods: {

    setTbData(items) {
      this.$store.state.tableData.push({
        id: items.id,
        "السلة": items.basket_name,
        "المسار": items.basket_path,
        "المستخدم": items.user_role,
      });
    },

    openSetting(id){
      this.$store.state.open_edit_dialog_mortgage_settings = true;
      this.$store.state.id_of_mortgage_settings = id;
    },

    getTableData(count) {
      this.$store.state.tableData = [];
      this.loading = true;
      Axios.post(`${this.$store.state.url}/employee/admin/all-Basket-settings-data?page=${count}`,{},{headers: this.request_headers(),}
      ).then((res) => {
        res.data.payload.data.map((item) => {
          this.setTbData(item);
        });

        this.$store.state.pagination = {
          limit: 3,
          count: res.data.payload.count_of_basket_settings_data,
        };

        this.loading = false;
      });
    },

    
    handleFilter(val) {
      this.$store.state.tableData = [];
      val.data.map((res) => {
        this.setTbData(res);
      });
    },
  
  },

  created() {
    this.$store.state.tableData = [];
    this.getTableData(1);
  },
};
</script>
