import Axios from "axios";
export const qualityUser = async () => {
    let url = 'https://appproduct.alwsata.com.sa/api/v2/employee/quality-users'

    return Axios.get( url , {
        headers: {
            "x-api-key":
                "WFKvB4Vjr8v5BVmxY7CDQ6ZMAU8DSHSBFKCMVpkfd5hhZyKTvnV3uh5XwxSwhnfeUbptZ7Z4XFJCR4vKXeHWGHTZ6djsYXjdAatf",
            Authorization: `Bearer ${localStorage.token}`,
        },
    })
}