<template>
  <div style="justify-content: space-between; display: flex; margin: 15px 0 0">
    <div class="titleStyle">{{ this.title }}</div>

    <div>
      <div class="button inputRadio" style="background: #e6f0ee !important">
        <input
          type="radio"
          id="a75"
          :name="checkedValue"
          :checked="items[0]"
          @change="$emit('filterValue', $event.target.value)"
          :value= items[0]
        />
        <label class="btn btn-default inputRadio__label" for="a75"
          >{{ this.items[0] }}</label
        >
      </div>

      <div class="button inputRadio" style="background: #e6f0ee !important">
        <input
          type="radio"
          id="a50"
          :name="checkedValue"
          :checked="items[1]"
          @change="$emit('filterValue', $event.target.value)"
          :value= items[1]
        />
        <label class="btn btn-default inputRadio__label" for="a50"
          >{{ this.items[1] }}</label
        >
      </div>
      
      <div class="inputRadio button" style="background: #e6f0ee !important">
        <input
          type="radio"
          id="a25"
          :name="checkedValue"
          :checked="items[2]"
          @change="$emit('filterValue', $event.target.value)"
          :value=items[2]
        />
        <label class="btn btn-default inputRadio__label" for="a25">{{ this.items[2] }}</label>
      </div>
    </div>
  </div>
</template>

<style>
.titleStyle {
  font-size: 16px;
    color: #2C2C2C;
    padding-top: 20px;
    font-weight: bold;
}
</style>

<script>
import css from "./AgenttypeStyle.css";
export default {
  props: ['items', 'checkedValue', 'title'],
  data(){
    return{

    }
  }
};
</script>
