<template>
  <div v-if="!loading">
    <!-- <AgenttypeFilter @filterMethod="getTableData" /> -->
    
    <v-row justify="center" class="mt-5 graphContent" style="margin: 0px">
      <DailyReportFilter @handleFilter="handleFilter" />
    </v-row>

    <RadiosFilter
      title="تصفية التصنيفات"
      :items="fitstRadioFilter"
      @filterValue="getFirstFilterValue"
      checkedValue="check-substitution-1"
    />

    <MainSliderGroup
      :options="this.options"
      @removeRow="removeRow($event)"
      @checkActive="checkActive"
    />

    <RadiosFilter
      title="تصفية النتائج"
      :items="secondFilterValus"
      @filterValue="getSecondFilterValue"
      checkedValue="check-substitution-2"
    />

    <!-- <DataTable
      :headers="getHeaders"
      :desserts="this.tableItems"
      total="report"
      :type="type"
      :loading="tableLoading"
      :counter="this.count"
    /> -->

    <table-component
        itemKey="id"
        :headers="getHeaders"
        :pagination="this.$store.state.pagination"
        :limit="$store.state.counterOfShow"
        :items="this.tableItems"
        :loading="tableLoading"
        v-model="page"
      >
      </table-component>

    <ChartComponent :data="resData" :headers="getHeaders" :result="secondRadionValue" />
  </div>
  <div v-else class="loader" style="margin-top: 20px">
    <img src="@/assets/Comp-2.gif" />
  </div>
</template>

<script>
// import DataTable from "@/components/Charts/DataTable.vue";
import { convertToFormData } from "@/mixins/convertToFormDate";
import ChartComponent from "./ChartComponent.vue";
import axios from "axios";
import RadiosFilter from "@/components/Charts/RadiosFilter.vue";
import MainSliderGroup from "@/components/Charts/MainSliderGroup.vue";
import DailyReportFilter from "./DailyReportFilter.vue";


export default {
  name: "RequestsType",
  components: {
    // AgenttypeFilter,
    DailyReportFilter,
    MainSliderGroup,
    RadiosFilter,
    // DataTable,
    ChartComponent,
  },
  data() {
    return {
      page: 1,
      fitstRadioFilter: ["تصنيف إيجابي", "تصنيف سلبي", "الكل"],
      secondFilterValus: ["نسب فقط", "اعداد فقط", "الكل"],

      numberOnly: ["اعداد فقط", "الكل"],
      precentageOnly: ["نسب فقط", "الكل"],
      firstRadioValue: "",
      secondRadionValue: "اعداد فقط",
      loading: false,
      options: [],
      obj: {},
      selectedAdviser: [],
      copyOptions: [],
      tableItems: [],
      agentUsers: [],
      dataTable: [],
      type: "requestClassificaion",
      count: [],
      headers: [
        { text: "الاستشاري", value: "agent", width: "120px", show: true },
        { text: "بدون تصنيف", value: "bdon-tsnyf", width: "120px", show: true },
        { text: "بحاجة للمتابعة", value: "bhag-llmtabaa", width: "120px", show: true },
        {
          text: "بانتظار الأوراق",
          value: "bantthar-alaorak",
          width: "120px",
          show: true,
        },
        {
          text: "لا يرغب في الخدمة",
          value: "la-yrghb-fy-alkhdm",
          width: "130px",
          show: true,
        },
        { text: "يبحث عن عقار", value: "ybhth-aan-aakar", width: "120px", show: true },
        { text: "مرفوض", value: "mrfod", show: true },
        { text: "تعذر الإتصال", value: "taathr-alatsal", width: "120px", show: true },
        { text: "مرفوع", value: "mrfoaa", width: "120px",show: true },
        { text: "مكتمل", value: "mktml", width: "120px", show: true },
        { text: "لم يرد", value: "lm-yrd", width: "120px", show: true },
        { text: "اجل التواصل", value: "agl-altoasl", width: "110px", show: true },
        {
          text: "بدون راتب ( فرد عمل حر)",
          value: "bdon-ratb-frd-aaml-hr",
          width: "160px",
          show: true,
        },
        {
          text: "لم يناسبه العرض",
          value: "lm-ynasbh-alaard",
          width: "140px",
          show: true,
        },
        {
          text: "ملغى من قبل العميل",
          value: "mlgh-mn-kbl-alaamyl",
          width: "150px",
          show: true,
        },
        {
          text: "لديه التزام عقاري حديث",
          value: "ldyh-altzam-aakary-hdyth",
          width: "160px",
          show: true,
        },
        { text: "قرض شخصي", value: "krd-shkhsy", width: "110px", show: true },
        { text: "أخذ من البنك", value: "akhth-mn-albnk", width: "120px", show: true },
        { text: "Testing Classification", value: "testing-classification", show: true },
        { text: "صرف نظر", value: "srf-nthr", width: "120px", show: true },
        {
          text: "بدون راتب (صاحب شركة أو مؤسسة)",
          value: "bdon-ratb-sahb-shrk-ao-moss",
          width: "160px",
          show: true,
        },
        {
          text: "المجموع(بإستثناء لايوجد تصنيف)",
          value: "almgmoaa-bastthnaaa-layogd-tsnyf",
          show: true,
        },
      ],
      showArray: [],
      originalEle: [],
      tableLoading: false,
      resData: [],
      sliceValue: "",
      body:{},
    };
  },

  computed: {
    getHeaders() {
      return this.headers.filter((item) => item.show);
    },
  },

  methods: {
    checkActive(option) {
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].id === option.id) {
          this.options[i].selected = !this.options[i].selected;
        }
      }
    },

    removeRow(headerValue) {
      let findIndex = this.headers.findIndex((item) => item.text === headerValue);

      this.headers = this.headers.map((item, index) => {
        if (findIndex === index) {
          item.show = !item.show;
        }
        return item;
      });
    },

    getFirstFilterValue(val) {
      this.firstRadioValue = val;
    },

    getSecondFilterValue(val) {
      if(val === "الكل" || val === "اعداد فقط"){
        this.secondRadionValue = "اعداد فقط";
      }else{
        this.secondRadionValue = val;
      }
      this.getTableItems(this.dataTable);
    },

    getTableData(page,obj, selectedAdviser) {
      this.obj = obj;
      this.selectedAdviser = selectedAdviser;
      this.tableLoading = true;
      // this.loading = true;
      // if (!this.obj || !selectedAdviser) {
      //   this.loading = true;
      // }

      let url = `${this.$store.state.url}/employee/admin/request-classifications-chart-admin?page=${page}&per_page=${this.$store.state.counterOfShow}`;

      this.tableItems = [];
      this.options = [];
      this.resData = [];

      this.body = {};

      if (this.obj) {
        Object.keys(this.obj).forEach((key) => {
          if (!this.obj[key] && this.obj[key] !== 0) delete this.obj[key];
          if (
            key == "status" &&
            (this.obj[key] === "استشاري نشط" || this.obj[key] === "استشاري مؤرشف")
          ) {
            if (this.obj[key] === "استشاري نشط") {
              this.obj[key] = 1;
            } else if (this.obj[key] === "استشاري مؤرشف") {
              this.obj[key] = 0;
            } else {
              this.obj[key] = null;
            }
          }
        });
      }

      this.body = convertToFormData(this.obj);
      this.body.append("status", 1);


      if (this.selectedAdviser) {
        this.selectedAdviser.forEach((item, index) => {
          this.body.append(`adviser_id[${index}]`, item["id"]);
        });
      }

      axios.post(url, this.body, { headers: this.request_headers() }).then((res) => {
        let data = res.data.message;
        this.dataTable = data;
        this.getTableItems(data);
        this.resData = data.data;
        this.count = [];
        this.count = data.count;

        this.copyOptions = Object.values(data.headers);
        this.originalEle = Object.values(data.headers);

        for (let i = 0; i < this.copyOptions.length; i++) {
          this.options.push({
            id: i,
            label: this.copyOptions[i],
            selected: false,
          });
        }

        if (this.firstRadioValue === "الكل") {
          data.headers = {
            agent: "الاستشاري",
            ...data.headers,
            total: "المجموع(بإستثناء لايوجد تصنيف)",
          };
        }

        this.$store.state.pagination = {
          limit: 3,
          // count: 22,
          count: data.usersCount,
        };

        this.loading = false;
        this.tableLoading = false;
      });
    },

    handleFilter(val) {
      this.tableLoading = true;
      this.page = 1;
      this.body = new FormData();
      if (val.startDate) {
        this.body.append("start_date", val.startDate);
      }

      if (val.endDate) {
        this.body.append("end_date", val.endDate);
      }

      if (val.status == undefined){
        val.status = "استشاري غير مؤرشف"; // default
        this.body.append("status", 1);
      }

      if (val.status === "استشاري غير مؤرشف") {
        this.body.append("status", 1);
      }

      if (val.status === "استشاري مؤرشف") {
        this.body.append("status", 0);
      }

      if (val.range) {
        this.body.append("range", val.range);
      }

      if (val.product_type) {
        switch (val.product_type) {
          case "طلب شخصي":
            this.body.append("product_type", 0);
            break;
          case "طلب عقاري":
            this.body.append("product_type", 1);
            break;
          case "شخصي وعقاري":
            this.body.append("product_type", 2);
            break;
          default:
            this.body.append("product_type", 2);
            break;
        }
      }

      if (val.managerId.length) {
        const managerProperties = {};
        val.managerId.forEach((item, index) => {
          this.body.append([`manager_id[${index}]`], item.id);
        });
      }

      if (val.agentId.length) {
        const managerProperties = {};

        val.agentId.forEach((item, index) => {
          this.body.append([`adviser_id[${index}]`], item.id);
        });
      }

      if (val.dayNumber) {
        this.body.append("range", val.dayNumber);
      }

      let url = `${this.$store.state.url}/employee/admin/request-classifications-chart-admin?page=1&per_page=${this.$store.state.counterOfShow}`;
      this.tableItems = [];
      this.options = [];
      this.resData = [];
      axios.post(url, this.body, { headers: this.request_headers() }).then((res) => {
        let data = res.data.message;
        this.dataTable = data;
        this.getTableItems(data);
        this.resData = data.data;
        this.count = [];
        this.count = data.count;

        this.copyOptions = Object.values(data.headers);
        this.originalEle = Object.values(data.headers);

        for (let i = 0; i < this.copyOptions.length; i++) {
          this.options.push({
            id: i,
            label: this.copyOptions[i],
            selected: false,
          });
        }

        if (this.firstRadioValue === "الكل") {
          data.headers = {
            agent: "الاستشاري",
            ...data.headers,
            total: "المجموع(بإستثناء لايوجد تصنيف)",
          };
        }

        this.$store.state.pagination = {
          limit: 3,
          // count: 22,
          count: data.usersCount,
        };

        this.loading = false;
        this.tableLoading = false;
      });
    },

    getTableItems(res) {
    let sperator = this.numberOnly.includes(this.secondRadionValue) && this.precentageOnly.includes(this.secondRadionValue) ? ' | ' : '';
    this.tableItems = [];
      res.data.forEach((item) => {
        const tableItem = {
          id: item["id"],
          agent: item["name"],

          "bdon-tsnyf":  item["bdon-tsnyf"],
          "bhag-llmtabaa": (this.numberOnly.includes(this.secondRadionValue) ? item["bhag-llmtabaa"] : '' ) +sperator+(this.precentageOnly.includes(this.secondRadionValue) ? (item["nsb-bhag-llmtabaa"]) : ''),
          "bantthar-alaorak": (this.numberOnly.includes(this.secondRadionValue) ? item["bantthar-alaorak"] : '' ) +sperator+(this.precentageOnly.includes(this.secondRadionValue) ? (item["nsb-bantthar-alaorak"]) : ''),
          "la-yrghb-fy-alkhdm": (this.numberOnly.includes(this.secondRadionValue) ? item["la-yrghb-fy-alkhdm"] : '' ) +sperator+(this.precentageOnly.includes(this.secondRadionValue) ? (item["nsb-la-yrghb-fy-alkhdm"]) : ''),
          "ybhth-aan-aakar": (this.numberOnly.includes(this.secondRadionValue) ? item["ybhth-aan-aakar"] : '' ) +sperator+(this.precentageOnly.includes(this.secondRadionValue) ? (item["nsb-ybhth-aan-aakar"]) : ''),
          mrfod: (this.numberOnly.includes(this.secondRadionValue) ? item["mrfod"] : '' ) +sperator+(this.precentageOnly.includes(this.secondRadionValue) ? (item["nsb-mrfod"]) : ''),
          "taathr-alatsal": (this.numberOnly.includes(this.secondRadionValue) ? item["taathr-alatsal"] : '' ) +sperator+(this.precentageOnly.includes(this.secondRadionValue) ? (item["nsb-taathr-alatsal"]) : ''),
          mrfoaa: (this.numberOnly.includes(this.secondRadionValue) ? item["mrfoaa"] : '' ) +sperator+(this.precentageOnly.includes(this.secondRadionValue) ? (item["nsb-mrfoaa"]) : ''),
          mktml: (this.numberOnly.includes(this.secondRadionValue) ? item["mktml"] : '' ) +sperator+(this.precentageOnly.includes(this.secondRadionValue) ? (item["nsb-mktml"]) : ''),
          "lm-yrd": (this.numberOnly.includes(this.secondRadionValue) ? item["lm-yrd"] : '' ) +sperator+(this.precentageOnly.includes(this.secondRadionValue) ? (item["nsb-lm-yrd"]) : ''),
          "agl-altoasl": (this.numberOnly.includes(this.secondRadionValue) ? item["agl-altoasl"] : '' ) +sperator+(this.precentageOnly.includes(this.secondRadionValue) ? (item["nsb-agl-altoasl"]) : ''),
          "bdon-ratb-frd-aaml-hr":  (this.numberOnly.includes(this.secondRadionValue) ? item["bdon-ratb-frd-aaml-hr"] : '' ) +sperator+(this.precentageOnly.includes(this.secondRadionValue) ? (item["nsb-bdon-ratb-frd-aaml-hr"]) : ''),
          "lm-ynasbh-alaard": (this.numberOnly.includes(this.secondRadionValue) ? item["lm-ynasbh-alaard"] : '' ) +sperator+(this.precentageOnly.includes(this.secondRadionValue) ? (item["nsb-lm-ynasbh-alaard"]) : ''),
          "mlgh-mn-kbl-alaamyl":  (this.numberOnly.includes(this.secondRadionValue) ? item["mlgh-mn-kbl-alaamyl"] : '' ) +sperator+(this.precentageOnly.includes(this.secondRadionValue) ? (item["nsb-mlgh-mn-kbl-alaamyl"]) : ''),
          "ldyh-altzam-aakary-hdyth":  (this.numberOnly.includes(this.secondRadionValue) ? item["ldyh-altzam-aakary-hdyth"] : '' ) +sperator+(this.precentageOnly.includes(this.secondRadionValue) ? (item["nsb-ldyh-altzam-aakary-hdyth"]) : ''),
          "krd-shkhsy":  (this.numberOnly.includes(this.secondRadionValue) ? item["krd-shkhsy"] : '' ) +sperator+(this.precentageOnly.includes(this.secondRadionValue) ? (item["nsb-krd-shkhsy"]) : ''),
          "akhth-mn-albnk": (this.numberOnly.includes(this.secondRadionValue) ? item["akhth-mn-albnk"] : '' ) +sperator+(this.precentageOnly.includes(this.secondRadionValue) ? (item["nsb-akhth-mn-albnk"]) : ''),
          "testing-classification": (this.numberOnly.includes(this.secondRadionValue) ? item["testing-classification"] : '' ) +sperator+(this.precentageOnly.includes(this.secondRadionValue) ? (item["nsb-testing-classification"]) : ''),
          "srf-nthr":(this.numberOnly.includes(this.secondRadionValue) ? item["srf-nthr"] : '' ) +sperator+(this.precentageOnly.includes(this.secondRadionValue) ? (item["nsb-srf-nthr"]) : ''),
          "bdon-ratb-sahb-shrk-ao-moss": (this.numberOnly.includes(this.secondRadionValue) ? item["bdon-ratb-sahb-shrk-ao-moss"] : '' ) +sperator+(this.precentageOnly.includes(this.secondRadionValue) ? (item["nsb-bdon-ratb-sahb-shrk-ao-moss"]) : ''),
          "almgmoaa-bastthnaaa-layogd-tsnyf": item["almgmoaa-bastthnaaa-layogd-tsnyf"],
        };
        this.tableItems.push(tableItem);
      });
    },
  },

  watch: {
    page(val) {
      console.log('Page number is ' + this.page);
      this.getTableData(val);
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval !== oldvalue && !this.$store.state.admin_search_active) {
          this.getTableData(this.page);
        }
      },
    },

    firstRadioValue(val) {
      if (val === "تصنيف سلبي") {
        this.copyOptions = [];
        this.headers = [
          { text: "الاستشاري", value: "agent", width: "120px", show: true },
          { text: "بدون تصنيف", value: "bdon-tsnyf", width: "120px", show: true },
          {
            text: "لا يرغب في الخدمة",
            value: "la-yrghb-fy-alkhdm",
            width: "130px",
            show: true,
          },
          { text: "مرفوض", value: "mrfod", show: true },
          { text: "تعذر الإتصال", value: "taathr-alatsal", width: "120px", show: true },
          {
            text: "بدون راتب ( فرد عمل حر)",
            value: "bdon-ratb-frd-aaml-hr",
            width: "160px",
            show: true,
          },
          {
            text: "لم يناسبه العرض",
            value: "lm-ynasbh-alaard",
            width: "140px",
            show: true,
          },
          {
            text: "ملغى من قبل العميل",
            value: "mlgh-mn-kbl-alaamyl",
            width: "150px",
            show: true,
          },
          {
            text: "لديه التزام عقاري حديث",
            value: "ldyh-altzam-aakary-hdyth",
            width: "160px",
            show: true,
          },
          { text: "قرض شخصي", value: "krd-shkhsy", width: "110px", show: true },
          { text: "أخذ من البنك", value: "akhth-mn-albnk", width: "120px", show: true },
          { text: "Testing Classification", value: "testing-classification", show: true },
          { text: "صرف نظر", value: "srf-nthr", width: "120px", show: true },
          {
            text: "بدون راتب (صاحب شركة أو مؤسسة)",
            value: "bdon-ratb-sahb-shrk-ao-moss",
            width: "160px",
            show: true,
          },
          {
            text: "المجموع(بإستثناء لايوجد تصنيف)",
            value: "almgmoaa-bastthnaaa-layogd-tsnyf",
            show: true,
          },
        ];

        for (let i = 0; i < this.options.length; i++) {
          let findIndex = this.headers.findIndex(
            (item) => item.text === this.options[i].label
          );
          if (findIndex === -1) {
            this.options[i].selected = true;
          } else {
            this.options[i].selected = false;
          }
        }
        for (let i = 0; i < this.headers.length; i++) {
          this.copyOptions.push(this.headers[i].value);
        }

        this.copyOptions.pop();
        this.copyOptions.shift();

        this.showArray = [
          { id: 1, value: "bhag-llmtabaa" },
          { id: 2, value: "bantthar-alaorak" },
          { id: 4, value: "ybhth-aan-aakar" },
          { id: 5, value: "mrfod" },
          { id: 7, value: "mrfoaa" },
          { id: 8, value: "mktml" },
          { id: 9, value: "lm-yrd" },
          { id: 10, value: "agl-altoasl" },
        ];
      }

      if (val === "تصنيف إيجابي") {
        this.copyOptions = [];
        this.headers = [
          { text: "الاستشاري", value: "agent", width: "120px", show: true },
          { text: "بدون تصنيف", value: "bdon-tsnyf", width: "120px", show: true },
          { text: "بحاجة للمتابعة", value: "bhag-llmtabaa", width: "120px", show: true },
          {
            text: "بانتظار الأوراق",
            value: "bantthar-alaorak",
            width: "120px",
            show: true,
          },
          { text: "يبحث عن عقار", value: "ybhth-aan-aakar", width: "120px", show: true },
          { text: "مرفوع", value: "mrfoaa", show: true },
          { text: "مكتمل", value: "mktml", show: true },
          { text: "لم يرد", value: "lm-yrd", width: "70px", show: true },
          { text: "اجل التواصل", value: "agl-altoasl", width: "110px", show: true },
          {
            text: "المجموع(بإستثناء لايوجد تصنيف)",
            value: "almgmoaa-bastthnaaa-layogd-tsnyf",
            show: true,
          },
        ];

        for (let i = 0; i < this.options.length; i++) {
          let findIndex = this.headers.findIndex(
            (item) => item.text === this.options[i].label
          );
          if (findIndex === -1) {
            this.options[i].selected = true;
          } else {
            this.options[i].selected = false;
          }
        }

        for (let i = 0; i < this.headers.length; i++) {
          this.copyOptions.push(this.headers[i].value);
        }

        this.copyOptions.pop();
        this.copyOptions.shift();

        this.showArray = [
          { id: 3, value: "la-yrghb-fy-alkhdm" },
          { id: 5, value: "mrfod" },
          { id: 6, value: "taathr-alatsal" },
          { id: 10, value: "bdon-ratb-frd-aaml-hr" },
          { id: 11, value: "lm-ynasbh-alaard" },
          { id: 12, value: "mlgh-mn-kbl-alaamyl" },
          { id: 13, value: "ldyh-altzam-aakary-hdyth" },
          { id: 14, value: "krd-shkhsy" },
          { id: 15, value: "akhth-mn-albnk" },
          { id: 16, value: "testing-classification" },
          { id: 17, value: "srf-nthr" },
          { id: 18, value: "bdon-ratb-sahb-shrk-ao-moss" },
        ];
      }

      if (val === "الكل") {
        this.copyOptions = [];
        this.showArray = [];
        this.headers = [
          { text: "الاستشاري", value: "agent", width: "120px", show: true },
          { text: "بدون تصنيف", value: "bdon-tsnyf", width: "120px", show: true },
          { text: "بحاجة للمتابعة", value: "bhag-llmtabaa", width: "120px", show: true },
          {
            text: "بانتظار الأوراق",
            value: "bantthar-alaorak",
            width: "120px",
            show: true,
          },
          {
            text: "لا يرغب في الخدمة",
            value: "la-yrghb-fy-alkhdm",
            width: "130px",
            show: true,
          },
          { text: "يبحث عن عقار", value: "ybhth-aan-aakar", width: "120px", show: true },
          { text: "مرفوض", value: "mrfod", show: true },
          { text: "تعذر الإتصال", value: "taathr-alatsal", width: "120px", show: true },
          { text: "مرفوع", value: "mrfoaa", show: true },
          { text: "مكتمل", value: "mktml", show: true },
          { text: "لم يرد", value: "lm-yrd", width: "70px", show: true },
          { text: "اجل التواصل", value: "agl-altoasl", width: "110px", show: true },
          {
            text: "بدون راتب ( فرد عمل حر)",
            value: "bdon-ratb-frd-aaml-hr",
            width: "160px",
            show: true,
          },
          {
            text: "لم يناسبه العرض",
            value: "lm-ynasbh-alaard",
            width: "140px",
            show: true,
          },
          {
            text: "ملغى من قبل العميل",
            value: "mlgh-mn-kbl-alaamyl",
            width: "150px",
            show: true,
          },
          {
            text: "لديه التزام عقاري حديث",
            value: "ldyh-altzam-aakary-hdyth",
            width: "160px",
            show: true,
          },
          { text: "قرض شخصي", value: "krd-shkhsy", width: "110px", show: true },
          { text: "أخذ من البنك", value: "akhth-mn-albnk", width: "120px", show: true },
          { text: "Testing Classification", value: "testing-classification", show: true },
          { text: "صرف نظر", value: "srf-nthr", width: "120px", show: true },
          {
            text: "بدون راتب (صاحب شركة أو مؤسسة)",
            value: "bdon-ratb-sahb-shrk-ao-moss",
            width: "160px",
            show: true,
          },
          {
            text: "المجموع(بإستثناء لايوجد تصنيف)",
            value: "almgmoaa-bastthnaaa-layogd-tsnyf",
            show: true,
          },
        ];

        for (let i = 0; i < this.options.length; i++) {
          let findIndex = this.headers.findIndex(
            (item) => item.text === this.options[i].label
          );
          if (findIndex === -1) {
            this.options[i].selected = true;
          } else {
            this.options[i].selected = false;
          }
        }

        for (let i = 0; i < this.headers.length; i++) {
          this.copyOptions.push(this.headers[i].value);
        }

        this.copyOptions.pop();
        this.copyOptions.shift();
      }
    },
  },

  created() {
    // this.body = new FormData();
    // this.body.append("status", 1);
    this.getTableData();
  },
};
</script>
