<template>
  <div>
    <UserCard v-if="$store.state.isProfile" />
    <ProfileHeader />
    <v-form class="mt-5 mb-5" :disabled="!active">
      <v-card flat>
        <v-layout
          row
          wrap
          style="background-color: #f3f5f7"
          class="userProfile__container"
        >
          <v-col cols="12" lg="4" md="4" sm="6" xs="12">
            <v-card class="pa-3 position-relative userProfile__card">
              <v-col cols="12" sm="12">
                <div style="display: flex; align-items: center" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('work')"
                    />
                    الكفالة
                  </div>
                  <div class="horizontal__line"></div>

                  <v-autocomplete
                    :items="guaranty"
                    hide-details
                    v-model="$store.state.userProfileData.control_guaranty_id"
                    item-text="value"
                    item-value="id"
                    style="border: none !important"
                    class="mr-1"
                    outlined
                    dense
                  >
                  </v-autocomplete>
                  <img src="@/assets/icons/down-arrow.svg" />
                </div>
              </v-col>
            </v-card>
          </v-col>

          <v-col cols="12" lg="4" md="4" sm="6" xs="12">
            <v-card class="pa-3 position-relative userProfile__card">
              <v-col cols="12" sm="12">
                <div style="display: flex; align-items: center" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('work')"
                    />
                    الشركة الكافلة
                  </div>
                  <div class="horizontal__line"></div>

                  <v-autocomplete
                    :items="companies"
                    hide-details
                    v-model="$store.state.userProfileData.control_guaranty_company_id"
                    item-text="value"
                    item-value="id"
                    style="border: none !important"
                    class="mr-1"
                    outlined
                    dense
                  >
                  </v-autocomplete>
                  <img src="@/assets/icons/down-arrow.svg" />
                </div>
              </v-col> </v-card
          ></v-col>

          <v-col cols="12" lg="4" md="4" sm="6" xs="12">
            <v-card class="pa-3 position-relative userProfile__card">
              <v-col cols="12" sm="12">
                <div style="display: flex" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('customerName')"
                    />
                    إسم الكفيل
                  </div>
                  <div class="horizontal__line"></div>
                  <div>
                    <v-text-field
                      type="text"
                      class="form-control"
                      style="
                        background-color: #fbfbfb;
                        border-right: #fbfbfb;
                        margin-top: -20px;
                      "
                      v-model="$store.state.userProfileData.guaranty_name"
                    />
                  </div>
                </div>
              </v-col>
            </v-card>
          </v-col>
        </v-layout>
      </v-card>
    </v-form>

    <v-btn class="primary" @click="active = true" v-if="!active">تعديل</v-btn>

    <v-btn class="primary" v-if="active" @click="handleEdit">حفظ</v-btn>

    <v-btn @click="active = false" v-if="active" style="margin-right: 5px">عرض</v-btn>

    <v-snackbar
      v-model="activeSnack"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <div>
        <span class="font-weight-bold" style="font-size: 16px">تم التعديل</span>
      </div>
      <template v-slot:action>
        <v-btn icon small @click="activeSnack = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script setup>
import ProfileHeader from "./ProfileHeader.vue";
import { ref, onMounted } from "vue";
import router from "@/routes";
import { axiosInstance } from "@/axios/config";
import { store } from "@/store";
import UserCard from "./UserCard.vue";

const active = ref(false);
const activeSnack = ref(false);

const handleEdit = () => {
  const formData = new FormData();

  Object.entries(store.state.userProfileData).forEach(([key, value]) => {
    formData.append(key, value);
  });
  axiosInstance
    .post(`/employee/hr/user-update-profile/${router.currentRoute.params.id}`, formData)
    .then((res) => {
      activeSnack.value = true;
    });
};

const guaranty = ref([]);
const companies = ref([]);
const getGuaranty = () => {
  axiosInstance.get(`/employee/hr/employee-control-list`).then((res) => {
    guaranty.value = res.data.payload.guaranty;
    companies.value = res.data.payload.company;
  });
};

onMounted(() => {
  getGuaranty();
});
</script>
