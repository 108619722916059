<template>
<v-card class="pa-3 position-relative" style="height: 100%">
    <span class="right-color requestType"
      :style="`${item.status == `طلب تم فتحه` ? `background-color:#40BE72`
  : item.status == `طلب جديد` ? `background-color:#3B5DE7` : item.status == `مؤرشف لدى استشاري المبيعات`
    ? `background-color:#017E84` : item.status == `طلب مرفوض` ? `background-color: indianred`
      : item.status == `في انتظار موافقة مدير المبيعات` ? `background-color: #FF715B` : `background-color:black`}`">
    </span>
    <div class="card-head d-flex justify-space-between align-center">
      <v-card-title class="pa-0 requestTitle">
        {{ item.request.customer?.name}}
      </v-card-title>
      <div class="icons">
        <v-btn v-if="$route.name == 'all' || $route.name == 'following'" @click="select_element(item)" width="30" height="30" color="transparent" depressed fab>
          <v-icon size="30" :color="in_selected_elements(item) ? 'primary' : '#999898'">
            {{ in_selected_elements(item) ?"mdi-hexagram": "mdi-crop-square" }}
          </v-icon>
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn width="30" height="30" color="transparent" depressed fab light v-bind="attrs" v-on="on"
              style="margin-left: -10px">
              <img src="@/assets/icons/eyeIcon.svg" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="to_request_details(item)">
              <v-list-item-icon class="ml-3">
                <img src="@/assets/icons/titcketIcon.svg" />
              </v-list-item-icon>
              <v-list-item-title>فتح</v-list-item-title>
            </v-list-item>
            <v-list-item @click="to_tickets_page(item)">
              <v-list-item-icon class="ml-3">
                <img src="@/assets/icons/ticketIcon.svg" />
              </v-list-item-icon>
              <v-list-item-title>التذاكر</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.quality_status != 3 && item.quality_status !=5 || true" @click="add_needToBeTurnedReq(item)">
              <v-list-item-icon class="ml-3">
                <!-- <img src="@/assets/icons/ticketIcon.svg" /> -->
              </v-list-item-icon>
              <v-list-item-title>اضافة الي سلة التحويل</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="item.quality_status == 3 || item.quality_status == 4" @click="restore_request(item, i)">
              <v-list-item-icon class="ml-3">
                <!-- <img src="@/assets/icons/ticketIcon.svg" /> -->
              </v-list-item-icon>
              <v-list-item-title>استرجاع الطلب</v-list-item-title>
            </v-list-item>
            <v-list-item @click="open_questions_dialog(item)">
              <v-list-item-icon class="ml-3">
                <!-- <img src="@/assets/icons/ticketIcon.svg" /> -->
              </v-list-item-icon>
              <v-list-item-title>اسئلة التقييم</v-list-item-title>
            </v-list-item>
            
          </v-list>
         
        </v-menu>
      </div>
    </div>
    <div class="req-status-parent mt-1 d-flex justify-space-between align-center">
      <span class="req-type" style="color: #444B5A ; font-size: 15px;">
        {{ item.request.type || "لايوجد نوع للطلب" }}
      </span>
      <span class="req-status spanRequestType" :style="`${item.status == `طلب تم فتحه` ? `background-color:#40BE72` : item.status == `طلب جديد`
  ? `background-color:#3B5DE7` : item.status == `مؤرشف لدى استشاري المبيعات` ? `background-color:#017E84`
    : item.status == `طلب مرفوض` ? `background-color: indianred` : item.status == `في انتظار موافقة مدير المبيعات`
      ? `background-color: #FF715B` : `background-color:black`}`">{{ item.status }}
      </span>
    </div>
    <div class="req-status-parent mt-1 d-flex justify-space-between align-center">
      <span class="req-type" style="color: #444B5A ; font-size: 15px;">
       حالة الطلب عند استشاري المبيعات
      </span>
      <span class="req-status "  :style="(item.request.status != null && item.request.status.length > 21) ? ` font-size: 12px !important;` : ` font-size: 15px;`">
        {{ item.request?.status }}
      </span>
    </div>
    

    <div v-if="0" class="req-date-parent my-2 d-flex justify-space-between align-center">
      <span class="req-date">
        {{ (item.agent_date !== undefined)? item.agent_date.split(" ")[0] : '' }}
      </span>
      <span class="req-status" :style="`color: #444B5A ; font-size: 15px;`">
        {{ item.request.class_id_agent_text || "لايوجد تصنيف معاملة" }}
      </span>
    </div>
    <div class="req-date-parent my-2 d-flex justify-space-between align-center">
      <span class="req-date">
        {{ (item.date_time_quality !== undefined && item.date_time_quality != null)? item.date_time_quality.reminder_date?.split(" ")[0] : '' }}
      </span>
      <span class="req-status" :style="`color: #444B5A ; font-size: 15px;`">
        {{ item.quality_classification || "لايوجد تصنيف معاملة" }}
      </span>
    </div>
    <hr style="height: 1px !important; opacity: 0.3" class="mb-0" />
    <div class="desc parent">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-card-text v-on="on" class="pt-1 pb-1 px-0">
            {{
              item.quality_note ? item.quality_note.length > 50
                ? item.quality_note.substring(0, 50) + "..." : item.quality_note : "لايوجد ملاحظات"
            }}
          </v-card-text>
        </template>
        <div style="color: #444B5A ; font-size: 14px;">{{ item.quality_note || "لايوجد ملاحظات" }}</div>
      </v-tooltip>
    </div>
    <div class="desc parent">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-card-text v-on="on" class="pt-1 pb-1 px-0">
              ملاحظة استشاري المبيعات : 
            {{
              item.request.agent_notes ? item.request.agent_notes.length > 50
                ? item.request.agent_notes.substring(0, 30) + "..." : item.request.agent_notes : "لايوجد ملاحظات"
            }}
          </v-card-text>
        </template>
        <div style="color: #444B5A ; font-size: 14px;"> ملاحظة استشاري المبيعات : {{ item.request.agent_notes || "لايوجد ملاحظات" }}</div>
      </v-tooltip>
    </div>
  </v-card>

</template>

<script>

export default {
    name: "CardStyle",
    props:{
        item : {
            required : true
        },
    },
    methods: {
        
        to_request_details(item) {
            this.$router.push({
            name: "quality_request_details",
            params: { id: item.id },
            });
        },
    },
};
</script>