<template>
  <div style="margin-top: 40px">
    <highcharts :options="chartOptions" style="height: 800px"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts-vue";

export default {
  props: ["data", "headers"],
  data() {
    return {
      stacksTotal: [],
      showArray: [],
      originalEl: [],
      chartData: {
        responseNameArr: [],
        SendMortgage: [],
        currentMortgage: [],
      },
      emptyArr: [],

      chartOptions: {
        chart: {
          type: "column",
        },
        title: {
          text: " تقرير الرهن ",
          align: "center",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          min: 0,
        },
        tooltip: {
          headerFormat: "<b>{point.x}</b><br/>",
          pointFormat:
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
          shared: true,
        },
        plotOptions: {
          column: {
            stacking: "Column",
            dataLabels: {
              enabled: true,
            },
          },
        },

        series: [],
      },
    };
  },

  components: {
    highcharts: Chart,
  },

  watch: {
    data(newValue) {
      this.setChartData();
    },

    headers(val) {
      this.setChartData();
    },
  },

  computed: {
    returnChartData() {
      return this.chartData;
    },
  },

  methods: {
    setChartData() {
      Object.keys(this.chartData).forEach((key, index) => {
        this.chartData[key] = [];
      });

      this.data.data.map((res) => {
        this.chartData.responseNameArr.push(res.name);
        this.chartData.currentMortgage.push(res["waiting_mm_status"]["count"]);
        this.chartData.SendMortgage.push(res["sent_basket_mortgage"]["count"]);

        this.chartOptions["xAxis"]["categories"] = this.chartData.responseNameArr;
      });

      this.chartOptions["series"] = [];
      this.headers.map((res, index) => {
        if (res.value === "مرفوعه رهن") {
          this.chartOptions["series"].push({
            name: "مرفوعه رهن",
            data: this.chartData.SendMortgage,
          });
        } else if (res.value === "قائم رهن") {
          this.chartOptions["series"].push({
            name: "قائم رهن",
            data: this.chartData.currentMortgage,
          });
        }
      });
    },
  },

  created() {
    this.chartOptions["series"] = [];
    this.setChartData();
  },
};
</script>
