<template>
  <div class="chartsPage">
    <div class="mx-5" style="margin-top: 25px" v-if="changeBage">
      <AddNewRequestBtn />
      <v-row justify="center" style="margin: 0px">
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header style="font-weight: 600; padding: 20px"
              ><span style="position: absolute; bottom: 15px"
                ><v-img
                  style="width: 20px; height: 25px"
                  id="logo"
                  contain
                  src="@/assets/Group.svg"
              /></span>
              <span style="margin-right: 30px">فلتر البحث</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <PickerInput
                    label="من تاريخ"
                    @dateValue="targetStartDate($event)"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <PickerInput
                    label="الي تاريخ"
                    @dateValue="targetEndDate($event)"
                  />
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    :items="allStatus"
                    label="الحالة"
                    v-model="selectedStatusFilter"
                    outlined
                    dense
                  >
                    <template v-slot:item="{ item, attrs, on }">
                      <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-title
                          :id="attrs['aria-labelledby']"
                          v-text="item"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6" style="margin-top: 13px">
                  <SelectInput
                    @selectedValues="getSelectedValueAdviser"
                    :originalItems="agentUsers"
                    label="اسم الاستشاري"
                  />
                </v-col>

                <div style="display: flex; width: 33%">
                  <div class="button" style="background: white !important">
                    <input
                      type="radio"
                      id="a25"
                      name="check-substitution-2"
                      :checked="radio === 'all'"
                      @change="radio = $event.target.value"
                      value="all"
                    />
                    <label class="btn btn-default" for="a25">الكل</label>
                  </div>
                  <div class="button" style="background: white !important">
                    <input
                      type="radio"
                      id="a50"
                      name="check-substitution-2"
                      :checked="radio === 'day'"
                      @change="radio = $event.target.value"
                      value="day"
                    />
                    <label class="btn btn-default" for="a50">اليوم</label>
                  </div>
                  <div class="button" style="background: white !important">
                    <input
                      type="radio"
                      id="a75"
                      name="check-substitution-2"
                      :checked="radio === 'week'"
                      @change="radio = $event.target.value"
                      value="week"
                    />
                    <label class="btn btn-default" for="a75">الاسبوع</label>
                  </div>
                </div>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="عدد الايام"
                    v-model="inputNumberDays"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2" md="2">
                  <v-btn
                    depressed
                    color="primary"
                    style="
                      width: 100%;
                      padding: 21px;
                      margin-top: -1px;
                      color: white !important;
                    "
                    @click="runSearchMethod(title)"
                  >
                    بحث
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <div v-if="getDataDone">
        <SliderGroup :options="this.options" @removeRow="removeRow($event)" />
        <SubHeaderVue
          @pasketRequest="pasketRequest($event)"
          @typesRequest="getAllRequests($event)"
          @ordeCaseRequest="ordeCaseRequest($event)"
          :typesRequestsActive="this.active"
          :basketRequestActive="this.basketRequestActive"
          :orderCaseRequestActive="this.orderCaseRequestActive"
        />
        <DataTable
          :headers="getHeaders"
          :desserts="this.tableItems"
          :title="this.title"
          total="report"
          :type="type"
          :counter="this.count"
        />
      </div>
      <div v-else class="loader" style="margin-top: 20px">
        <img src="@/assets/Comp-2.gif" />
      </div>
    </div>
    <div v-else class="loader" style="margin-top: 20px">
      <img src="@/assets/Comp-2.gif" />
    </div>
  </div>
</template>

<script>
import PickerInput from "@/components/AdminComponent/PickerInput.vue";
import SliderGroup from "@/components/Charts/SliderGroup.vue";
import DataTable from "@/components/Charts/DataTable.vue";
import SubHeaderVue from "@/components/Charts/SubHeader.vue";
import AddNewRequestBtn from "@/components/Charts/AddNewRequestBtn.vue";
import SelectInput from "@/components/Charts/SelectInput.vue";
import Axios from "axios";
import axios from "axios";

export default {
  name: "SMRequests",
  data() {
    return {
      NHeaders: [],
      type: "",
      headers: [],
      tableItems: [],
      options: [],
      resName: [],
      copyResName: [],
      startDate: "",
      endDate: "",
      getDataDone: true,
      title: "تقرير تصنيفات الطلب",
      active: true,
      basketRequestActive: false,
      orderCaseRequestActive: false,
      changeBage: true,
      allStatus: ["الكل", "استشاري مؤرشف", "استشاري نشط"],
      selectedStatusFilter: {
        id: 0,
        label: "الكل",
      },
      agentNames: null,
      agentUsers: [],
      advisers: [],
      radio: "all",
      status: null,
      inputNumberDays: null,
      activeAgent: [],
      archivedAgent: [],
      allAgents: [],
      count: [],
    };
  },

  watch: {
    selectedStatusFilter(val) {
      this.active = val;

      if (val === "استشاري نشط") {
        this.status = 1;
        this.agentUsers = this.activeAgent;
      } else if (val === "استشاري مؤرشف") {
        this.status = 0;
        this.agentUsers = this.archivedAgent;
      } else {
        this.status = null;
        this.agentUsers = this.allAgents;
      }
    },

    "$store.state.rates_in_admin_count": {
      handler(val) {
        if (val > this.$store.state.redirect_count_of_rates && localStorage["role"] == "1") {
          this.$store.state.showAlertOfRatingDidntProccess = true;
          this.$router.push({ name: "salesManager_SMRateServices" });
        }
      },
      immediate: true 
    }
    
  },

  components: {
    PickerInput,
    SliderGroup,
    DataTable,
    SubHeaderVue,
    AddNewRequestBtn,
    SelectInput,
  },

  methods: {
    getAgentBasedOnStatus() {},

    getSelectedValueAdviser(adviser) {
      this.advisers = adviser;
    },

    getAgentUsers(status) {
      let body = {};
      if (status !== null) {
        body = { status: status };
      }
      axios
        .post(
          `${this.$store.state.url}/sales/request-classifications-chart`,
          body,
          {
            headers: this.request_headers(),
          }
        )
        .then((res) => {
          this.agentUsers = res.data.message.data;
          if (status === 1) {
            this.activeAgent = this.agentUsers.map((item) => {
              item["color"] = "red";
              item["text"] = item["name"];
              return item;
            });
          } else if (status === 0) {
            this.archivedAgent = this.agentUsers.map((item) => {
              item["color"] = "red";
              item["text"] = item["name"];
              return item;
            });
          } else if (status === null) {
            this.allAgents = this.agentUsers.map((item) => {
              item["color"] = "red";
              item["text"] = item["name"];
              return item;
            });
          }
        });
    },

    //order cases request logic
    ordeCaseRequest(title) {
      if (title === "search") {
        this.getDataDone = false;
      } else {
        this.changeBage = false;
        this.startDate = "";
        this.endDate = "";
        this.radio = "all";
        this.inputNumberDays = null;
        this.status = null;
      }

      this.active = false;
      this.basketRequestActive = false;
      this.orderCaseRequestActive = true;
      this.title = "تقرير تصنيفات الطلب";
      this.type = "requestType";

      let body = new FormData();
      if (this.startDate && !this.endDate) {
        // body = { start_date: this.startDate }
        body.append("start_date", this.startDate);
      }

      if (this.startDate && this.endDate) {
        body.append("start_date", this.startDate);
        body.append("end_date", this.endDate);
      }

      if (this.endDate && !this.startDate) {
        body.append("end_date", this.endDate);
      }

      if (this.advisers) {
        this.advisers.forEach((item, index) => {
          body.append(`adviser_id[${index}]`, item["id"]);
        });
      }

      if (this.inputNumberDays) {
        this.radio = this.inputNumberDays;
      }

      if (this.radio !== "all") {
        body.append("range", this.radio);
      }

      if (this.status !== null) {
        body.append("status", this.status);
      }

      Axios.post(
        `${this.$store.state.url}/sales/request-classifications-chart`,
        body,
        {
          headers: this.request_headers(),
        }
      ).then((res) => {
        this.changeBage = true;
        this.getDataDone = true;
        let data = res.data.message;
        this.tableItems = [];
        this.headers = [];
        this.getOrderCasesData(data);

        for (const key in data.headers) {
          if (data.headers[key] === "بدون تصنيف") {
            delete data.headers[key];
          }
        }
        this.resName = [];
        this.copyResName = [];
        this.options = [];

        this.options = Object.values(data.headers);
        this.count = [];
        this.count = data.count;

        data.headers = {
          agent: "الاستشاري",
          ...data.headers,
          التحكم: "التحكم",
        };

        this.resName = Object.values(data.headers);
        this.copyResName = Object.values(data.headers);
        this.agentUsers = res.data.message.data;
        this.agentUsers.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      });
    },

    getOrderCasesData(res) {
      this.tableItems = [];
      res.data.forEach((item, index) => {
        const tableItem = {
          id: item.id,
          الاستشاري: item.name,
          "بحاجة للمتابعة": item["bhag-llmtabaa"],
          "بانتظار الأوراق": item["bantthar-alaorak"],
          "لا يرغب في الخدمة": item["la-yrghb-fy-alkhdm"],
          "يبحث عن عقار": item["ybhth-aan-aakar"],
          مرفوض: item["mrfod"],
          "تعذر الإتصال": item["taathr-alatsal"],
          مرفوع: item["mrfoaa"],
          مكتمل: item["mktml"],
          "لم يرد": item["lm-yrd"],
          "اجل التواصل": item["agl-altoasl"],
          "بدون راتب ( فرد عمل حر)": item["bdon-ratb-frd-aaml-hr"],
          "لم يناسبه العرض": item["lm-ynasbh-alaard"],
          "ملغى من قبل العميل": item["mlgh-mn-kbl-alaamyl"],
          "لديه التزام عقاري حديث": item["ldyh-altzam-aakary-hdyth"],
          "قرض شخصي": item["krd-shkhsy"],
          "أخذ من البنك": item["akhth-mn-albnk"],
          "Testing Classification": item["testing-classification"],
          "صرف نظر": item["srf-nthr"],
          "بدون راتب (صاحب شركة أو مؤسسة)": item["bdon-ratb-sahb-shrk-ao-moss"],
          التحكم: "", // Placeholder value for PDF data
          isPdfLoading: true, // Flag to indicate if PDF is loading
        };
        this.tableItems.push(tableItem);
        this.getPdfOrderCases(item.id, index); // Fetch PDF asynchronously
      });
    },

    getPdfOrderCases(id, index) {
      const body = { user_id: id };
      const url = `${this.$store.state.url}/sales/request-classifications-chart-agent-pdf`;

      axios
        .post(url, body, {
          headers: this.request_headers(),
        })
        .then((res) => {
          const pdf = res.data.payload.report;
          // Update table item with fetched PDF
          this.tableItems[index].التحكم = pdf;
          this.tableItems[index].isPdfLoading = false; // Set PDF loading flag to false
        })
        .catch((error) => {
          console.error(error);
          this.tableItems[index].isPdfLoading = false; // Set PDF loading flag to false in case of error
        });
    },

    //paskets Request logic
    pasketRequest(title) {
      if (title === "search") {
        this.getDataDone = false;
      } else {
        this.changeBage = false;
        this.startDate = "";
        this.endDate = "";
        this.radio = "all";
        this.inputNumberDays = null;
        this.status = null;
      }

      this.active = false;
      this.orderCaseRequestActive = false;
      this.basketRequestActive = true;
      this.title = "تقرير سلال الطلب";
      this.type = "basketRequest";

      let body = new FormData();
      if (this.startDate && !this.endDate) {
        body.append("start_date", this.startDate);
      }

      if (this.startDate && this.endDate) {
        body.append("start_date", this.startDate);
        body.append("end_date", this.endDate);
      }

      if (this.endDate && !this.startDate) {
        body.append("end_date", this.endDate);
      }

      if (this.advisers) {
        this.advisers.forEach((item, index) => {
          body.append(`adviser_id[${index}]`, item["id"]);
        });
      }

      if (this.inputNumberDays) {
        this.radio = this.inputNumberDays;
      }

      if (this.radio !== "all") {
        body.append("range", this.radio);
      }

      if (this.status !== null) {
        body.append("status", this.status);
      }
      Axios.post(`${this.$store.state.url}/sales/request-baskets-chart`, body, {
        headers: this.request_headers(),
      }).then((res) => {
        this.changeBage = true;
        this.getDataDone = true;
        let data = res.data.payload;
        this.tableItems = [];
        this.headers = [];
        this.getBasketTableData(data);
        this.resName = [];
        this.copyResName = [];
        this.options = [];
        this.options = Object.values(data.headers);
        this.count = [];
        this.count = data.count;

        data.headers = {
          agent: "الاستشاري",
          ...data.headers,
          التحكم: "التحكم",
        };

        this.resName = Object.values(data.headers);
        this.copyResName = Object.values(data.headers);
      });
    },

    getBasketTableData(res) {
      this.tableItems = [];
      res.data.forEach((item, index) => {
        const tableItem = {
          الاستشاري: item.name,
          مكتملة: item.completes,
          مؤرشفة: item.archived,
          متابعة: item.following,
          مميزة: item.star,
          مستلمة: item.received,
          التحكم: "", // Placeholder value for PDF data
          isPdfLoading: true, // Flag to indicate if PDF is loading
        };
        this.tableItems.push(tableItem);
        this.getPdfBasket(item.id, index); // Fetch PDF asynchronously
      });
    },

    getPdfBasket(id, index) {
      const body = { user_id: id };
      const url = `${this.$store.state.url}/sales/request-baskets-chart-agent-pdf`;

      axios
        .post(url, body, {
          headers: this.request_headers(),
        })
        .then((res) => {
          const pdf = res.data.payload.report;
          // Update table item with fetched PDF
          this.tableItems[index].التحكم = pdf;
          this.tableItems[index].isPdfLoading = false; // Set PDF loading flag to false
        })
        .catch((error) => {
          console.error(error);
          this.tableItems[index].isPdfLoading = false; // Set PDF loading flag to false in case of error
        });
    },
    //target search value
    targetStartDate(date) {
      this.startDate = date;
    },

    targetEndDate(date) {
      this.endDate = date;
    },

    //requests type logic
    getAllRequests(title) {
      if (title === "search") {
        this.getDataDone = false;
      } else {
        this.changeBage = false;
        this.startDate = "";
        this.endDate = "";
        this.radio = "all";
        this.inputNumberDays = null;
        this.status = null;
      }

      this.basketRequestActive = false;
      this.orderCaseRequestActive = false;
      this.active = true;

      this.title = "تقرير حالات الطلب";
      this.type = "requestReport";
      this.getDataDone = false;

      let body = new FormData();
      if (this.startDate && !this.endDate) {
        body.append("start_date", this.startDate);
      }

      if (this.startDate && this.endDate) {
        body.append("start_date", this.startDate);
        body.append("end_date", this.endDate);
      }

      if (this.endDate && !this.startDate) {
        body.append("end_date", this.endDate);
      }

      if (this.advisers) {
        this.advisers.forEach((item, index) => {
          body.append(`adviser_id[${index}]`, item["id"]);
        });
      }

      if (this.inputNumberDays) {
        this.radio = this.inputNumberDays;
      }

      if (this.radio !== "all") {
        body.append("range", this.radio);
      }

      if (this.status !== null) {
        body.append("status", this.status);
      }

      Axios.post(`${this.$store.state.url}/sales/request-source-chart`, body, {
        headers: this.request_headers(),
      }).then((res) => {
        this.getDataDone = true;
        this.changeBage = true;
        let data = res.data.payload;
        this.tableItems = [];
        this.headers = [];
        this.getTableData(data);
        this.resName = [];
        this.copyResName = [];
        this.options = [];

        this.options = Object.values(data.headers);

        this.count = [];
        this.count = data.count;


        data.headers = {
          agent: "الاستشاري",
          ...data.headers,
          التحكم: "التحكم",
        };

        this.resName = Object.values(data.headers);
        this.copyResName = Object.values(data.headers);
      });
    },

    getTableData(res) {
      this.tableItems = [];
      res.data.forEach((item, index) => {
        const tableItem = {
          الاستشاري: item.name,
          جديد: item.new_status,
          مفتوح: item.open_status,
          مؤرشف: item.archive_status,
          " بانتظار موافقه مدير المبيعات": item.waiting_sm_status,
          "رفض من مدير المبيعات": item.rejected_sm_status,
          "مؤرشف عند مدير المبيعات": item.archive_sm_status,
          //" بانتظار موافقه الاستشارى": item.waiting_sm_status,
          //"رفض من قبل الاستشارى": item.rejected_sm_status,
          //"مؤرشف عند الاستشارى": item.archive_sm_status,
          "بانتظار موافقه مدير التمويل": item.waiting_fm_status,
          "رفض من قبل مدير التمويل": item.rejected_fm_status,
          "مؤرشف عند مدير التمويل": item.archive_fm_status,
          "بانتظار موافقه مدير الرهن": item.waiting_mm_status,
          "رفض من قبل مدير الرهن": item.rejected_mm_status,
          "مؤرشف عند مدير الرهن": item.archive_mm_status,
          "بانتظار موافقه المدير العام": item.waiting_gm_status,
          "رفض من قبل المدير العام": item.rejected_gm_status,
          "مؤرشف عند المدير العام": item.archive_gm_status,
          "ملغى": item.canceled_status,
          "مكتمل": item.completed_status,
          " في تقرير التمويل": item.funding_report_status,
          " في تقرير الرهن": item.mortgage_report_status,
          التحكم: "", // Placeholder value for PDF data
          isPdfLoading: true, // Flag to indicate if PDF is loading
        };
        this.tableItems.push(tableItem);
        this.getPdfTypes(item.id, index); // Fetch PDF asynchronously
      });
    },

    getPdfTypes(id, index) {
      const body = { user_id: id };
      const url = `${this.$store.state.url}/sales/request-source-chart-agent-pdf`;

      axios
        .post(url, body, {
          headers: this.request_headers(),
        })
        .then((res) => {
          const pdf = res.data.payload.report;
          // Update table item with fetched PDF
          this.tableItems[index].التحكم = pdf;
          this.tableItems[index].isPdfLoading = false; // Set PDF loading flag to false
        })
        .catch((error) => {
          console.error(error);
          this.tableItems[index].isPdfLoading = false; // Set PDF loading flag to false in case of error
        });
    },
    //remove and insert data
    removeRow(headerValue) {
      this.headers = [];
      let findIndex = this.resName.findIndex((item) => item === headerValue);

      if (findIndex !== -1) {
        this.resName.splice(findIndex, 1);
      } else {
        let filterRemovedItem = this.copyResName.filter(
          (x) => !this.resName.includes(x)
        );
        let foundInsertedItem = filterRemovedItem.findIndex(
          (item) => item === headerValue
        );

        this.copyResName.map((res, index) => {
          if (headerValue === res) {
            this.resName.splice(index - foundInsertedItem, 0, headerValue);
          }
        });
      }
    },

    //target page search
    runSearchMethod(title) {
      if (title === "تقرير سلال الطلب") {
        this.pasketRequest("search");
      } else if (title === "تقرير حالات الطلب") {
        this.getAllRequests("search");
      } else if (title === "تقرير تصنيفات الطلب") {
        this.ordeCaseRequest("search");
      }
    },
  },

  created() {
    this.ordeCaseRequest();
    this.getAgentUsers(null);
    this.getAgentUsers(1);
    this.getAgentUsers(0);
  },

  computed: {
    getAgentNames() {
      return this.agentNames;
    },
    user_name() {
      return JSON.parse(
        document.cookie
          .split(";")
          .map((coki) => coki.split("="))
          .reduce(
            (acc, [key, val]) => ({
              ...acc,
              [key.trim()]: decodeURIComponent(val),
            }),
            {}
          )["wsataJwt"]
      )["agent_name"];
    },

    getHeaders() {
      this.resName.map((res) => {
        if (
          res === "رفض من قبل مدير الرهن" ||
          res === "الاستشاري" ||
          res === "Testing Classification" ||
          res === "ملغى من قبل العميل" ||
          res === "لديه التزام عقاري حديث" ||
          res === "بدون راتب ( فرد عمل حر)" ||
          res === "بانتظار موافقه مدير الرهن" ||
          res === "مؤرشف عند مدير الرهن" ||
          res === "مؤرشف عند مدير التمويل" ||
          res === "رفض من قبل مدير التمويل" ||
          res === "بانتظار موافقه مدير التمويل" ||
          res === "مؤرشف عند مدير المبيعات" ||
          res === "رفض من قبل مدير المبيعات" ||
          res === " بانتظار موافقه الاستشارى" ||
          res === "رفض من قبل الاستشارى" ||
          res === "مؤرشف عند الاستشارى" ||
          res === "مؤرشف عند المدير العام" ||
          res === "رفض من قبل المدير العام" ||
          res === "رفض من مدير المبيعات" ||
          res === "بانتظار موافقه المدير العام"
        ) {
          this.headers.push({
            text: res,
            value: res,
            width: "150px",
          });
        } else if (
          res === "بانتظار موافقه مدير المبيعات" ||
          res === " بانتظار موافقه مدير المبيعات" ||
          res === "بدون راتب (صاحب شركة أو مؤسسة)"
        ) {
          this.headers.push({
            text: res,
            value: res,
            width: "160px",
          });
        } else if (
          res === "بحاجة للمتابعة" ||
          res === "أخذ من البنك" ||
          res === "لم يناسبه العرض" ||
          res === "بانتظار الأوراق" ||
          res === "يبحث عن عقار" ||
          res === "لا يرغب في الخدمة" ||
          res === " في تقرير الرهن" ||
          res === " في تقرير التمويل"
        ) {
          this.headers.push({
            text: res,
            value: res,
            width: "120px",
          });
        } else if (
          res === "قرض شخصي" ||
          res === "صرف نظر" ||
          res === "لم يرد" ||
          res === "تعذر الإتصال" ||
          res === "اجل التواصل"
        ) {
          this.headers.push({
            text: res,
            value: res,
            width: "120px",
          });
        } else {
          this.headers.push({
            text: res,
            value: res,
          });
        }
      });
      return this.headers;
    },
  },
};
</script>

<style>
.chartsPage i.v-icon.notranslate.mdi.mdi-chevron-down.theme--light {
  background: #3f0e40;
}
</style>
