<template>
  <div>
    <v-list-item @click="to_request_details(item.id)">
      <v-list-item-icon class="ml-3">
        <v-icon>mdi-eye-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-title>
        فتح الطلب
        <!--                          <routes-link :to="{path: `/requests/${item.id}/agent-data`}" style="text-decoration: none;">-->
        <!--                          </routes-link>-->
      </v-list-item-title>
    </v-list-item>

    <!-- <v-list-item @click="sendRequestToAdministration(item)">
      <v-list-item-icon class="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.121"
              height="17.121"
              viewBox="0 0 17.121 17.121"
            >
              <g
                id="Icon_feather-arrow-up-right"
                data-name="Icon feather-arrow-up-right"
                transform="translate(-9.439 -9.439)"
              >
                <path
                  id="Path_5860"
                  data-name="Path 5860"
                  d="M10.5,25.5l15-15"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Path_5861"
                  data-name="Path 5861"
                  d="M10.5,10.5h15v15"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
              </g>
            </svg>
      </v-list-item-icon>
      <v-list-item-title>ارسال للإدارة</v-list-item-title>
    </v-list-item> -->

    <router-link
      style="text-decoration: none"
      target="_blank"
      :to="{ name: 'request_details', params: { id: item.id } }"
    >
      <v-list-item>
        <v-list-item-icon class="ml-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.528"
            height="15.111"
            viewBox="0 0 13.528 10.111"
          >
            <g
              id="Icon_feather-eye-dark"
              data-name="Icon feather-eye-dark"
              transform="translate(-1 -5.5)"
            >
              <path
                id="Path_4040"
                data-name="Path 4040"
                d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
              <path
                id="Path_4041"
                data-name="Path 4041"
                d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                transform="translate(-7.444 -4.653)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title> فتح في تاب جديد</v-list-item-title>
      </v-list-item>
    </router-link>

    <v-list-item @click="convertRequest(item)">
      <v-list-item-icon class="ml-3">
        <ConvertRequestIcon />
      </v-list-item-icon>
      <v-list-item-title>تحويل الطلب</v-list-item-title>
    </v-list-item>

    <v-list-item @click="getRequestCalls(item)">
      <v-list-item-icon class="ml-3">
        <RequestCallsIcon />
      </v-list-item-icon>
      <v-list-item-title>المكالمات</v-list-item-title>
    </v-list-item>

    <v-list-item @click="sendToQuality(item)">
      <v-list-item-icon class="ml-2">
        <SendToQualityIcon />
      </v-list-item-icon>

      <v-list-item-title>ارسال الطلب للجوده</v-list-item-title>
    </v-list-item>
    <router-link
      style="text-decoration: none; display: flex"
      :to="{ path: `/admin/tickets-details/${item.id}` }"
    >
      <v-list-item>
        <v-list-item-icon class="ml-2">
          <TicketIcon />
        </v-list-item-icon>
        <v-list-item-title>التذاكر</v-list-item-title>
      </v-list-item>
    </router-link>

    <v-list-item @click="addToConvert(item)">
      <v-list-item-icon class="ml-2">
        <AddToNeedActionIcon />
      </v-list-item-icon>

      <v-list-item-title>اضافه الي سله التحويل</v-list-item-title>
    </v-list-item>

    <v-list-item @click="toOrderRequest(item)">
      <v-list-item-icon class="ml-2">
        <OrderHistory />
      </v-list-item-icon>
      <v-list-item-title>سجل الطلب</v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import { axiosInstance } from "@/axios/config";
import { OrderHistory } from "@/assets/adminIcons/orderHistory";
import { ConvertRequestIcon } from "@/assets/adminIcons/convertRequest";
import { RequestCallsIcon } from "@/assets/adminIcons/requestCalls";
import { SendToQualityIcon } from "@/assets/adminIcons/sendToQualityIcon";
import { TicketIcon } from "@/assets/adminIcons/ticketIcon";
import { AddToNeedActionIcon } from "@/assets/adminIcons/addToNeedActionIcon";

export default {
  components: {
    OrderHistory,
    ConvertRequestIcon,
    SendToQualityIcon,
    AddToNeedActionIcon,
    TicketIcon,
    RequestCallsIcon,
  },
  props: ["item"],
  data() {
    return {
      label: "",
      message: "",
    };
  },
  computed: {
    returnAllRequests() {
      return this.allStatus;
    },
  },
  methods: {
    to_request_details(item) {
      // this.$emit("getId", item);
      this.$store.state.requestId = item;
      this.$router.push({
        name: "request_details",
        params: { id: item },
      });
    },

    convertRequest(item) {
      this.$store.state.openDialog = true;
      this.getUserAdmin(item);
    },

    sendRequestToAdministration(item) {
      this.$store.state.openAdministrationDialog = true;
      this.$store.state.administrationRequestId = item.id;
    },

    

    getUserAdmin(item) {
      this.label = "استشاري المبيعات";
      let url = `/v2/employee/sales-agent-users`;
      axiosInstance.get(url).then((res) => {
        this.$emit("getUsersName", res.data.payload, this.label, item.id);
      });
    },

    sendToQuality(item) {
      this.$store.state.openDialog = true;
      this.getSupAdmin(item);
    },

    getRequestCalls(item) {
      this.$store.state.idOfRequestCalls = item.id;
      this.$store.state.openCallsDialog = true;
    },

    getSupAdmin(item) {
      this.label = "الجوده";
      let url = `/v2/employee/quality-users`;
      axiosInstance.get(url).then((res) => {
        this.$emit("getUsersName", res.data.payload, this.label, item.id);
      });
    },

    tickets(item) {
      this.$router.push({ name: "tickets-details", params: { id: item.id } });
    },

    toOrderRequest(item) {
      this.$store.state.orderRequestId = item.id;
      if (this.$store.state.showCards || this.$store.state.showCards == false) {
        this.$store.state.orderRequestDialog = true;
      } else {
        this.$store.state.openOrderRequestSection = true;
      }
    },

    addToConvert(item) {
      axiosInstance
        .get(`/v2/employee/Requests/addToNeedActionReq/${item.id}`)
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          let error = { err };
          this.message = error.err.response.data.message;
          this.showPopup("error", this.message);
        });
    },
  },

};
</script>
