export const  OrderHistory = () => {
    return(
        <svg className="ml-2" id="history_2_" data-name="history (2)" xmlns="http://www.w3.org/2000/svg"
             width="22.654" height="23.212" viewBox="0 0 22.654 23.212">
            <path id="Path_2774" data-name="Path 2774"
                  d="M29.292,16.287H16.2a.454.454,0,1,1,0-.907H29.292a.454.454,0,1,1,0,.907Zm0,3.95H16.2a.454.454,0,0,1,0-.907H29.292a.454.454,0,1,1,0,.907Zm-8.309,3.955H16.194a.454.454,0,0,1,0-.907h4.789a.454.454,0,0,1,0,.907Zm-1.261,3.95H16.194a.454.454,0,0,1,0-.907h3.529a.454.454,0,1,1,0,.907Zm8.767,6.381A5.946,5.946,0,1,1,32.7,32.79,5.941,5.941,0,0,1,28.489,34.524Zm0-10.976a5.039,5.039,0,1,0,3.573,1.468A5.034,5.034,0,0,0,28.489,23.548Z"
                  transform="translate(-11.799 -11.312)"/>
            <path id="Path_2775" data-name="Path 2775"
                  d="M43.3,40.448a.454.454,0,0,1-.454-.454V37.274a.454.454,0,1,1,.907,0v2.721A.454.454,0,0,1,43.3,40.448Z"
                  transform="translate(-26.613 -23.028)"  />
            <path id="Path_2776" data-name="Path 2776"
                  d="M40.243,44.4H37.7a.454.454,0,1,1,0-.907h2.54a.454.454,0,1,1,0,.907Z"
                  transform="translate(-23.553 -26.673)"  />
            <path id="Path_2777" data-name="Path 2777"
                  d="M19.5,27.617H9.318A2.268,2.268,0,0,1,7.05,25.35V8.678A2.268,2.268,0,0,1,9.318,6.41H26.67a2.268,2.268,0,0,1,2.268,2.268v8.617a.454.454,0,1,1-.907,0V8.678A1.361,1.361,0,0,0,26.67,7.317H9.318A1.361,1.361,0,0,0,7.957,8.678V25.35A1.361,1.361,0,0,0,9.318,26.71H19.5a.454.454,0,1,1,0,.907Z"
                  transform="translate(-7.05 -6.41)" />
        </svg>
    )
}