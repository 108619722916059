<template>
  <div>
    <ArhivedInQualityHeaderIcons
      :selectedItems="selectedItems"
      type="moveAllRequests"
      @getData="reloadPage"
    />

    <div v-if="!this.$store.state.allLoading" style="margin-top: 40px">
      <div v-if="$store.state.showCards === true">
        <div v-if="this.$store.state.allData.length > 0" style="margin-top: 40px">
          <RequestsCard
            :CardData="this.$store.state.allData"
            @back="handleBack"
            v-if="!this.$store.state.allLoading"
            @pageCount="handlePageNumber"
            @getUsersName="getUsersName"
          />
          <div>
            <LoadingMore v-if="loadMoreData" style="margin: 40px" />
          </div>
        </div>
        <div v-else>
          <div>
            <button
              @click="handleBack"
              style="
                width: 70px;
                height: 40px;
                border-radius: 5px;
                margin: 50px;
                color: white;
                background-color: #441545;
                direction: ltr;
              "
            >
              رجوع
            </button>
          </div>
          <div class="noData">لا يوجد بيانات</div>
        </div>
      </div>

      <div v-else class="table__view">
        <table-component
          :showSelect="true"
          itemKey="id"
          :selectedRows.sync="selected_requests"
          :headers="headers"
          :pagination="this.$store.state.pagination"
          :limit="$store.state.counterOfShow"
          :items="this.$store.state.tableData"
          :loading="loading"
          @refresh="getTableData"
          v-model="page"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="#3f0e40" small depressed dark v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <AllSingleProcess :item="item" @getUsersName="getUsersName" />
              </v-list>
            </v-menu>
          </template>

          <template slot="item.quality_notes" slot-scope="props">
            <span v-if="!props.item.quality_notes"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.quality_notes.length > 7
                    ? props.item.quality_notes.substring(0, 7) + "..."
                    : props.item.quality_notes
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.quality_notes }}</div>
            </v-tooltip>
          </template>

          <template slot="item.user_status" slot-scope="props">
            <span v-if="!props.item.user_status"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.user_status.length > 8
                    ? props.item.user_status.substring(0, 8) + "..."
                    : props.item.user_status
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.user_status }}</div>
            </v-tooltip>
          </template>

          <template slot="item.name" slot-scope="props">
            <span v-if="!props.item.name"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.name.length > 8
                    ? props.item.name.substring(0, 8) + ".."
                    : props.item.name
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.name }}</div>
            </v-tooltip>
          </template>

          <template slot="item.status" slot-scope="props">
            <span v-if="!props.item.status"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.status.length > 8
                    ? props.item.status.substring(0, 8) + ".."
                    : props.item.status
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.status }}</div>
            </v-tooltip>
          </template>

          <template slot="item.source" slot-scope="props">
            <span v-if="!props.item.source"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.source.length > 10
                    ? props.item.source.substring(0, 10) + ".."
                    : props.item.source
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.source }}</div>
            </v-tooltip>
          </template>

          <template slot="item.agent_notes" slot-scope="props">
            <span v-if="!props.item.agent_notes"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.agent_notes.length > 7
                    ? props.item.agent_notes.substring(0, 7) + "..."
                    : props.item.agent_notes
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.agent_notes }}</div>
            </v-tooltip>
          </template>
        </table-component>
        <OrderRequestSection
          v-if="this.$store.state.openOrderRequestSection"
          style="margin-top: 50px"
        />
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
      "
      v-else
    >
      <LoadingMore />
    </div>
    <v-snackbar
      v-model="active"
      color="#40BE72"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <span class="font-weight-bold" style="font-size: 16px">{{ message }}</span>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <AdminDialog @selectedValue="selectedValue" :items="allStatus" :label="label" />
    <FilterDialogInAdmin pageName="all" />
    <OrderRequestDialog />
    <CallsDialog />
  </div>
</template>

<script>
import RequestsCard from "@/components/Charts/RequestsCard.vue";
import LoadingMore from "@/components/Charts/LoadingMore.vue";
import ArhivedInQualityHeaderIcons from "@/components/Charts/ArhivedInQualityHeaderIcons.vue";
import AdminDialog from "@/components/AdminComponent/AdminDialog.vue";
import FilterDialogInAdmin from "@/components/Charts/FilterDialogInAdmin.vue";
import { formatDate } from "@/mixins/formatDate";
import { SourceValue } from "@/mixins/sourceValue";
import OrderRequestDialog from "@/components/AdminComponent/OrderRequestDialog.vue";
import OrderRequestSection from "@/components/AdminComponent/OrderRequestSection.vue";
import AllSingleProcess from "@/views/admin/Requests/ArchivedInQuality/AllSingleProcess.vue";
import { axiosInstance } from "@/axios/config";
import CallsDialog from "@/components/Charts/CallsDialog.vue";

export default {
  name: "AdminSupport",
  components: {
    OrderRequestDialog,
    OrderRequestSection,
    FilterDialogInAdmin,
    AdminDialog,
    RequestsCard,
    LoadingMore,
    ArhivedInQualityHeaderIcons,
    AllSingleProcess,
    CallsDialog,
  },

  data() {
    return {
      allGetData: [],
      selected_requests: [],
      pagination: {
        limit: 3,
        count: this.$store.state.searchedCount,
      },
      page: 1,
      selectedRows: [],
      allData: [],
      active: false,
      message: "",
      showSelect: { default: false },
      copySupportRequests: [],
      loadMoreData: false,
      count: null,
      loading: false,
      selectId: null,
      allStatus: [],
      label: "",
      selectedItems: [],
      countPage: 1,
      listenToFreezeAction: this.$store.state.freezeActionIsDone,
      headers: [
        { align: "center", value: "data-table-select" },
        { text: "رقم الطلب", value: "رقم الطلب", align: "center" },
        { text: "تاريخ الطلب", value: "تاريخ الطلب", width: "107px", align: "center" },
        {
          text: "تاريخ الطلب عند الجودة",
          value: "تاريخ الطلب عند الجودة",
          width: "120px",
          align: "center",
        },
        {
          text: "حالة الطلب عند الجودة",
          value: "user_status",
          width: "120px",
          align: "center",
        },
        { text: "نوع الطلب", value: "نوع الطلب", align: "center" },
        {
          text: "استشاري المبيعات",
          value: "استشاري المبيعات",
          align: "center",
        },
        { text: "عميل", value: "name", align: "center" },
        { text: "الجودة", value: "الجودة", align: "center" },
        { text: "الجوال", value: "الجوال", align: "center" },
        { text: "حالة الطلب", value: "status", width: "110px", align: "center" },
        {
          text: "مصدر المعامله",
          value: "source",
          width: "100px",
          align: "center",
        },
        {
          text: "تصنيف الاستشاري",
          value: "تصنيف الاستشاري",
          width: "110px",
          align: "center",
        },
        {
          text: "ملاحظه الاستشاري ",
          value: "agent_notes",
          width: "110px",
          align: "center",
        },
        {
          text: "ملاحظة الجوده",
          value: "quality_notes",
          width: "110px",
          align: "center",
        },
        {
          text: "تاريخ الاستشاري",
          value: "تاريخ الاستشاري",
          width: "107px",
          align: "center",
        },
        {
          text: "استلام الجودة",
          value: "هل تم استلامه من قبل الجوده",
          align: "center",
        },
        {
          text: "تم التحديث ",
          value: "تم التحديث في",
          width: "107px",
          align: "center",
        },
        { text: "", value: "action", align: "center" },
      ],
    };
  },

  watch: {
    page(val) {
      if (this.$store.state.isSearch === false) {
        this.getTableData(val);
      } else {
        this.searchRequest(val, "table");
      }
    },
    selected_requests(val) {
      this.selectedItems = val;
      this.$emit("requests", val);
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval != oldvalue) {
          this.getTableData(this.page);
        }
      },
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },
  },

  beforeDestroy() {
    this.$store.state.openOrderRequestSection = false;
  },

  methods: {
    getUsersName(users, label, requestId) {
      this.allStatus = users;
      this.label = label;
      this.selectId = requestId;
    },

    handleBack() {
      this.$store.state.allData = [];
      this.$store.state.tableData = [];
      this.allGetData = [];
      this.$store.state.followLoading = true;
      this.$store.state.isSearch = false;
      this.$store.state.back = false;
      this.getAllRequests(1);
    },

    reloadPage() {
      this.$store.state.allData = [];
      this.$store.state.tableData = [];
      this.allGetData = [];
      this.$store.state.followLoading = true;
      this.$store.state.isSearch = false;
      this.getAllRequests(1);
      this.$store.state.startElement = [];
    },
    getAgentRequest(itemSelected) {
      let body = new FormData();

      body.append("request_ids[0]", this.selectId);
      body.append("agents_ids[0]", itemSelected.id);

      axiosInstance
        .post(`/v2/employee/Requests/moveAllRequests`, body, {
          headers: this.request_headers(),
        })
        .then((res) => {
          this.active = true;
          this.message = res.data.message;
        })
        .catch((err) => {
          this.active = true;
          let error = { err };
          this.message = error.err.response.data.message;
        });
    },

    selectedValue(itemSelected) {
      if (itemSelected.role === 5) {
        this.getQualityValues(itemSelected);
      } else if (itemSelected.role === 0) {
        this.getAgentRequest(itemSelected);
      }
    },

    getQualityValues(itemSelected) {
      let body = new FormData();

      body.append("request_ids[0]", this.selectId);
      body.append("quality[0]", itemSelected.id);

      axiosInstance
        .post(`/v2/employee/Requests/moveToQualityReqArray`, body, {
          headers: this.request_headers(),
        })
        .then((res) => {
          this.active = true;
          this.message = res.data.message;
        })
        .catch((err) => {
          this.active = true;
          let error = { err };
          this.message = error.err.response.data.message;
        });
    },

    setTblData(res) {
      this.$store.state.tableData.push({
        id: res.request.id,
        isSelected: false,
        "رقم الطلب": res.request.id,
        "تاريخ الطلب": res.request.created_at ? formatDate(res.request.created_at) : "",
        "تاريخ الطلب عند الجودة": res.created_at ? formatDate(res.created_at) : "",
        user_status: res.status || "",
        "نوع الطلب": res.request.type || "لا_يوجد",
        "استشاري المبيعات": res.request.user?.name,
        name: res.request.customer.name,
        الجودة: res.qualityUser?.name,
        الجوال: res.request.customer.mobile,
        status: res.request.status,
        source: SourceValue(res.request.source),
        "تصنيف الاستشاري": res.request.class_id_agent_text,
        agent_notes: res.request.agent_notes,
        quality_notes: res.request.quality_notes,
        "تاريخ الاستشاري": res.request.agent_date
          ? formatDate(res.request.agent_date)
          : "",
        "هل تم استلامه من قبل الجوده":
          res.request.user?.allow_recived && res.request.user?.allow_recived === 0
            ? "لا"
            : "نعم",
        "تم التحديث في": res.request.updated_at ? formatDate(res.request.updated_at) : "",
        العمليات: res.request.sent_basket?.count,
      });
    },

    getTableData(count) {
      this.$store.state.tableData = [];
      this.loading = true;
      axiosInstance
        .get(
          `/v2/employee/Requests?type=archived_in_quality&page=${count}&per_page=${this.$store.state.counterOfShow}`,
          {
            headers: this.request_headers(),
          }
        )
        .then((res) => {
          let response = res.data.payload.data.map((item) => {
            this.setTblData(item);
          });
          this.loading = false;
        });
    },

    handlePageNumber(val) {
      if (this.$store.state.isSearch === false) {
        this.getAllRequests(val);
      } else {
        this.searchRequest(val);
      }
    },

    searchRequest(page, type) {
      this.loading = true;

      if (type === "table") {
        this.$store.state.tableData = [];
      }

      if (page > 1 || type === "table") {
        this.$store.state.bodySearch.delete("page");
        this.$store.state.bodySearch.append("page", page);

        let url = `/v2/employee/Requests/search?type=1`;
        axiosInstance
          .post(url, this.$store.state.bodySearch, { headers: this.request_headers() })
          .then((res) => {
            this.$store.state.pagination = {
              limit: 3,
              count: res.data.payload.searched,
            };
            let response = res.data.payload.data.map((item) => {
              if (item.length === 0) {
                this.loading = false;
              }
              this.setTblData(item);
              item["starChecked"] = false;
              return item;
            });

            this.$store.state.allSearch.push(...response);
            this.removeDublicate(this.$store.state.allSearch);
            this.loading = false;
          });
      }
    },

    removeDublicate(array) {
      let uniqueArray = array.filter((item, index) => {
        return (
          index ===
          array.findIndex((obj) => {
            return obj.id === item.id;
          })
        );
      });
      this.$store.state.allData = uniqueArray;
    },

    getAllRequests(page) {
      if (
        this.$store.state.allData.length === 0 ||
        this.copySupportRequests.length === 0
      ) {
        this.$store.state.allLoading = true;
      }
      this.loadMoreData = true;
      axiosInstance
        .get(
          `/v2/employee/Requests?type=archived_in_quality&page=${page}&per_page=${this.$store.state.counterOfShow}`
        )
        .then((res) => {
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_archived_in_quality,
          };
          let response = res.data.payload.data.map((item) => {
            this.setTblData(item);
            item["starChecked"] = false;
            return item;
          });

          this.allGetData.push(...response);
          this.removeDublicate(this.allGetData);
          this.copySupportRequests.push(...response);
        })
        .catch((err) => {
          this.$store.state.allLoading = false;
          this.loadMoreData = false;
        })
        .finally(() => {
          this.loadMoreData = false;
          this.$store.state.allLoading = false;
        });
    },

    handleChangeInput(row) {
      row["selected"] = !row["selected"];
      let findRow = this.selectedRows.findIndex((item) => item["id"] === row["id"]);
      if (findRow !== -1) {
        this.selectedRows.splice(findRow, 1);
      } else {
        this.selectedRows.push(row);
      }
    },
  },
  created() {
    this.$store.state.tableData = [];
    this.$store.state.back = false;
    this.$store.state.isSearch = false;
    this.$store.state.bodySearch = new FormData();
    this.getAllRequests(1);
    this.$store.state.startElement = [];
  },
};
</script>

<style>
.noData {
  display: flex;
  justify-content: center;
  font-size: 22px;
  color: #3f0e40;
  margin-top: 70px;
  font-weight: bold;
}
</style>
