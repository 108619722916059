<template>
  <page-component style="position: relative">
    <template #content>
      <!-- بيانات العميل -->
      <v-sheet class="px-5 py-5">
        <div style="display: flex; justify-content: space-between;">
          <span class="font-weight-bold my-5" style="display: inline">المكالمات</span>
          <v-btn
                  color="primary"
          depressed
          @click="download_zip"
        >
        Download Zip
        </v-btn>

        </div>
        <v-row
          style="background-color: #f8f9fa; border: 1px solid #eff2f7; margin-top: 20px;"
          class="mx-0 file-info-row"
        >
          <v-progress-linear
            color="primary"
            :indeterminate="loading"
            reverse
          ></v-progress-linear>
          <v-col cols="2">اسم الاستشاري</v-col>
          <v-col cols="4"> المكالمه</v-col>
          <v-col cols="3">تاريخ المكالمه</v-col>
        </v-row>

        <v-row
          v-for="item in calls_details"
          :key="item.id"
          class="mx-0 py-4 file-info-row"
        >
          <v-col cols="3">{{ item.agent_name }}</v-col>
          <v-col cols="3">
            <audio controls>
                                                <source :src="item.path" type="audio/ogg">
                                              Your browser does not support the audio element.
                                              </audio>
          </v-col>
          <v-col cols="3">{{ item.day_date }}</v-col>
        </v-row>
        
        <v-row class="mt-6">
          <v-col cols="12" class="my-2">
            <div
              v-if="file.attachment == null"
              class="mt-4"
              style="border: 2px dashed #ced4da; height: 134px"
            >
              <v-file-input
                v-model="file.attachment"
                outlined
                class="mt-6 d-flex align-center justify-center file-inp"
                hide-input
                prepend-icon="mdi-cloud-upload-outline"
              >
              </v-file-input>
              <div class="d-flex align-center justify-center">
                <div>انقر للتحميل</div>
              </div>
            </div>
            <div class="d-flex align-center justify-center">
              <v-icon
                v-if="file.attachment != null"
                @click="file.attachment = null"
                class="ml-2"
                >mdi-close-circle</v-icon
              >
              <div :key="key" v-if="file.attachment != null">
                {{ file.attachment.name }}
              </div>
            </div>
            <div
              class="mt-5 d-flex align-center justify-center"
              v-if="file.attachment != null"
            >
              <v-btn
                color="primary"
                @click="upload_file"
                :loading="btnLoadiing"
                >رفع الملف</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </template>
  </page-component>
</template>

<script>
import Axios from "axios";
export default {
  data() {
    return {
      file: {},
      calls_details: [],
      loading: false,
      testArray: [
        { id: 1, agent_name: 'Agent 1', path: '/path1', day_date: '2023-01-01' },
        { id: 2, agent_name: 'Agent 2', path: '/path2', day_date: '2023-01-02' },
        { id: 3, agent_name: 'Agent 3', path: '/path3', day_date: '2023-01-03' },
      ],
    };
  },
  created() {
    this.get_request_calls();
  },

  methods: {
    get_request_calls() {
      this.loading = true;
      Axios.get(
        `https://appproduct.alwsata.com.sa/api/agent/Request/call-center/${this.$route.params.id}`,
        { headers: this.request_headers() }
      )
        .then((response) => {
          this.calls_details = response.data.payload;
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async download_zip() {
      this.loading = true;
      await fetch(
        `https://appproduct.alwsata.com.sa/api/add-callcenterrecords-to-zip?req_id=${this.$route.params.id}`,
        { headers: this.request_headers() }
      )
      .then((res) => res.blob())
        .then((data) => {
          const tempUrl = URL.createObjectURL(data);
          const theLink = document.createElement("a");
          theLink.href = tempUrl;
          theLink.download = `archive${this.$route.params.id}.zip`;
          document.documentElement.appendChild(theLink);
          theLink.click();
          theLink.remove();
          URL.revokeObjectURL(tempUrl);
        })
        .catch((err) => this.showPopup("error", "فشل عملية التحميل"))
        .finally(() => {
          this.get_request_calls();
          this.loading = false;
        });
    },
    
    upload_file() {
      this.btnLoadiing = true;
      let formData = new FormData();
      formData.append("file", this.file.attachment);
      formData.append("req_id", this.$route.params.id);
      Axios.post(
        "https://appproduct.alwsata.com.sa/api/extract-callcenterrecords-to-zip",
        formData,
        {
          headers: this.request_headers(),
        }
      )
        .then(() => {
          this.showPopup("success", "تم حفظ الملف بنجاح");
          this.btnLoadiing = false;
          setTimeout(() => {
            this.get_request_calls();
          }, 1000);
          this.btnLoadiing = false;
          this.refresh();
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
          this.btnLoadiing = false;
        });
    },
   
  },
};
</script>

<style lang="scss">
@media (max-width: 576px) {
  .file-info-row {
    div {
      font-size: 12px;
    }
    button {
      font-size: 16px !important;
    }
  }
}
</style>
