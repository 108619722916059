<template>
  <div>
    <v-row class="chatContainer" v-if="$store.state.chatStatus === 'all'">
      <v-col cols="12" sm="6" md="4">
        <v-expansion-panels accordion :value="$store.state.opened">
          <v-expansion-panel>
            <v-expansion-panel-header
              class="headerContainer"
              :style="returnStatusBorderColor"
            >
              <div style="display: flex">
                المحادثات
                <div class="massageCount" :style="returnStatusCountColor">
                  {{ navbarMsgsNotif.unreadMsgs }}
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="bodyContainer">
              <template>
                <div v-if="navbarMsgsNotif.rooms.length">
                  <div v-for="(chat, index) in navbarMsgsNotif.rooms" :key="index">
                    <div
                      class="contentContainer"
                      :style="`cursor: pointer;${
                            chat.data.ureadMsgsCount
                              ? 'background: #d9cfd9;'
                              : 'background: unset;'
                          }`"
                      @click="get_chat(chat.data, chat.id)"
                    >
                      <div style="display: flex">
                        <div class="imageContainer">
                          <img src="@/assets/purple-avatar.png" alt="image" />
                        </div>
                        <div>
                          <div>{{ chat.data.UserName || "..." }}</div>
                          <div class="lastMassageContainer">
                            {{ chat.data.lastMessage }}
                          </div>
                        </div>
                      </div>

                      <div class="chatDateContainer">
                        {{
                          new Date(
                            parseInt(chat.data.LastMessageTime)
                          ).toLocaleDateString() == new Date().toLocaleDateString()
                            ? new Date(
                                parseInt(chat.data.LastMessageTime)
                              ).toLocaleTimeString("en-US")
                            : new Date(
                                parseInt(chat.data.LastMessageTime)
                              ).toLocaleDateString()
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 18px;
                    font-weight: bold;
                  "
                >
                  لايوجد محادثات
                </div>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <div v-else>
      <ChatDetails :chatDetails="chats" />
    </div>
  </div>
</template>

<script>
import ChatDetails from "./ChatDetails.vue";
import css from "./Chat.css";
import firebase from "@/Firebase";
import { axiosInstance } from "@/axios/config";

export default {
  components: { ChatDetails },
  data() {
    return {
      navbarMsgsNotif: { unreadMsgs: 0, rooms: [] },
      rooms: [],
      getAgentMassage: [],

      chats: [],
    };
  },

  computed: {
    returnStatusBorderColor() {
      if (this.navbarMsgsNotif.unreadMsgs > 0) {
        return `border: 1px solid #FF4B4B`;
      } else {
        return `none`;
      }
    },

    returnStatusCountColor() {
      if (this.navbarMsgsNotif.unreadMsgs > 0) {
        return `background-color: #FF4B4B`;
      } else {
        return `background-color: #3F0E40`;
      }
    },
  },

  methods: {
    reply(chat) {
      // Handle the user's reply here
      const reply = {
        senderName: "John Doe",
        senderImage: "https://example.com/john-doe.jpg",
        content: chat.replyMessage,
        date: new Date().toISOString().split("T")[0],
      };
      chat.messages.push(reply);
      chat.replyMessage = ""; // Clear the reply input field
    },

    async get_rooms() {
      this.fetched = true;
      const id = JSON.parse(
        document.cookie
          .split(";")
          .map((coki) => coki.split("="))
          .reduce(
            (acc, [key, val]) => ({
              ...acc,
              [key.trim()]: decodeURIComponent(val),
            }),
            {}
          )["wsataJwt"]
      )["agent_id"];
      const chat_rooms = firebase
        .firestore()
        .collection("rooms")
        .where("AgentId", "in", [`${id}`])
        .orderBy("LastMessageTime", "desc");
      chat_rooms.onSnapshot((res) => {
        this.navbarMsgsNotif.rooms = [];
        this.navbarUnReadMsgs = 0;
        this.navbarMsgsNotif.unreadMsgs = 0;
        res.forEach(async (docOne) => {
          if (docOne.data().RequestId) {
            let obj = { id: docOne.id, data: docOne.data() };
            obj.data.ureadMsgsCount = 0;
            let chat_ref = firebase
              .firestore()
              .collection("messages")
              .where("roomId", "==", `${docOne.id}`);
            chat_ref.onSnapshot((res) => {
              //this.navbarUnReadMsgs = 0;
              obj.data.ureadMsgsCount = 0;
              // this.navbarMsgsNotif.unreadMsgs = 0;
              res.forEach((docTwo) => {
                if (docTwo.data().senderId != id) {
                  if (docTwo.data().is_read == 0) {
                    this.navbarMsgsNotif.unreadMsgs += 1;
                    obj.data.ureadMsgsCount += 1;
                  }
                }
                obj.data.lastMsgSenderId = docTwo.data().senderId;
                obj.data.lastMessage = docTwo.data().text
                  ? docTwo.data().text !== undefined && docTwo.data().text.length > 30
                    ? docTwo.data().text.split("").slice(0, 29).join("") + " " + "..."
                    : docTwo.data().text
                  : "تم ارسال مرفق ..";
              });
              this.navbarMsgsNotif.rooms.push(obj);
              // this.setNavChatAction(this.navbarMsgsNotif);
            });
          }
        });
      });
      setTimeout(() => {
        this.fetched = false;
      }, 1000);
    },

    get_chat(room, room_id) {
      this.$store.state.chatStatus = "details";
      this.$store.state.details_active_room = room;
      this.$store.state.details_active_room_id = room_id;
      this.active_room = room;
      this.active_room_id = room_id;

      this.getAgentMassage.forEach((res) => {
        if (this.active_room.AgentId == res.id) {
          this.activeAgentChat = res.name;
        }
      });

      this.chats = [];
      axiosInstance
        .get(`/customerRequest/${room.UserId}`)
        .then((res) => {
          this.active_room.RequestId = res.data.payload.req_id;
          let chat_ref = firebase
            .firestore()
            .collection("messages")
            .where("roomId", "==", `${room_id}`);
          chat_ref.onSnapshot((snapshot) => {
            this.chats = [];
            snapshot.forEach((doc) => {
              const data = doc.data();
              if (data.message_type !== "transferring") {
                const msg_exist = this.chats.find(
                  (msg) => msg.created_at === data.created_at
                );
                if (!msg_exist) {
                  this.chats.push(data);
                }
              }
            });
            this.chats.forEach((chat) => {
              if (chat.file) {
                const fileType = chat.file.split(".")[1];
                const imgTypes = [
                  "jpg",
                  "jpeg",
                  "png",
                  "apng",
                  "avif",
                  "jfif",
                  "pjpeg",
                  "pjp",
                ];
                if (imgTypes.includes(fileType)) {
                  chat.fileType = "image";
                }
              }
            });
            this.chats.sort((a, b) => a.created_at - b.created_at);
          });
        })
        .catch((err) => this.showPopup("error", err.response.data.payload.message));

      this.readChatMsgs(room_id);
    },

    readChatMsgs(room_id) {
      const chat_ref = firebase
        .firestore()
        .collection("messages")
        .where("roomId", "==", `${room_id}`);

      const unSub = chat_ref.onSnapshot((snapshot) => {
        snapshot.forEach((doc) => {
          const data = doc.data();
          if (data.senderId !== this.agent["agent_id"] && data.is_read === 0) {
            doc.ref.update({ is_read: 1 });
          }
        });
        unSub();
      });
    },
  },

  created() {
    this.agent = JSON.parse(
      document.cookie
        .split(";")
        .map((coki) => coki.split("="))
        .reduce(
          (acc, [key, val]) => ({
            ...acc,
            [key.trim()]: decodeURIComponent(val),
          }),
          {}
        )["wsataJwt"]
    );
    this.get_rooms();
    // this.$root.$on("triggerChatRoomsData", () => {
    //   this.get_rooms();
    // });
  },
};
</script>
