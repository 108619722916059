<template>
  <div style="margin-bottom: 40px; margin-top: 20px" class="table__view">
    <table-component
      :showSelect="false"
      itemKey="id"
      :selectedRows.sync="selected_rows"
      @refresh="getTeckits"
      :headers="headers"
      :limit="10"
      :items="items"
      :pagination="pagination"
      :loading="loading"
      v-model="page"
    >
      <!-- eslint-disable -->
      <template v-slot:[`item.status`]="{ item }">
        <v-badge
          style="position: unset !important"
          :color="item.color"
          :content="item.status"
          inline
        ></v-badge>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="#3f0e40" small depressed dark v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item router :to="{ name: 'SupportContent', params: { id: item.id } }">
              <v-list-item-icon class="ml-2"
                ><v-icon>mdi-eye-outline</v-icon></v-list-item-icon
              >
              <v-list-item-title>فتح</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </table-component>
    <AgentChat />
  </div>
</template>

<script>
import Axios from "axios";
import AgentChat from "@/components/AgentChat/AgentChat.vue";

export default {
  name: "AgentSupport",
  components: { AgentChat },
  data() {
    return {
      items: [],
      headers: [
        {
          text: "تاريخ اخر تحديث",
          value: "updated_at",
          width: "17%",
          align: "center",
        },
        {
          text: "الوصف",
          value: "descrebtion",
          width: "35%",
          align: "center",
        },
        {
          text: "الرد",
          value: "replay",
          width: "35%",
          align: "center",
        },
        {
          text: "الحالة",
          value: "status",
          width: "10%",
          align: "center",
        },
        { text: "العمليات", value: "action", align: "center" },
      ],
      loading: false,
      page: 1,
      selected_rows: [],
      addNewRequestDialog: false,
      pagination: {
        count: 0,
        limit: 0,
      },
    };
  },
  methods: {
    async getTeckits(page) {
      this.loading = true;
      // this.page = 1;
      await Axios.get(
        "https://appproduct.alwsata.com.sa/api/employee/collaborator/list-technical-support-requests?page="+page,
        { headers: this.request_headers() }
      )
        .then((res) => {
          this.items = res.data.payload.data;
          this.items.forEach((el) => {
            let cont = el.descrebtion.split(" ");
            if (cont.length > 9) {
              el.descrebtion = cont.slice(0, 9).join(" ") + "...";
            }
            if (el.status == 0) {
              el.color = "warning";
              el.status = "جديدة";
            } else if (el.status == 2) {
              el.color = "success";
              el.status = "مكتملة";
            } else {
              el.color = "primary";
              el.status = "جاري معالجتها";
            }
          });
          this.pagination = {
            limit: 10,
            count: res.data.payload.total,
          };
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },
  },
  async created() {
    this.$store.state.opened = -1;
    await this.getTeckits(this.page);
  },

  watch: {
    page(val) {
      this.getTeckits(val);
    },

    "$store.state.first_not_processed_task_id": {
      handler(val) {
        if (val > 0 && localStorage["role"] == "0") {
          this.$store.state.showAlertOfTasksDidntProccess = true;
          this.$router.push({ name: 'ticket_details', params: { ticket: this.$store.state.first_not_processed_task_id } });
        }
      },
      immediate: true 
    },

    "$store.state.rates_in_admin_count": {
      handler(val) {
        if (val > this.$store.state.redirect_count_of_rates && localStorage['role'] == 1) {
          this.$store.state.showAlertOfRatingDidntProccess = true;
          this.$router.push({ name: "salesManager_SMRateServices" });
        }
      },
      immediate: true 
    }

  },
};
</script>
