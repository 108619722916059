<template>
  <v-row justify="center">
    <v-dialog v-model="this.$store.state.QualityQuestionsDialog" persistent width="100%">
      <v-card>
        <v-card-title>
          <span class="text-h5" style="margin-bottom: 30px">تاكيد ارسال الدفعة؟</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="4" md="4" class="py-0 mb-1">
              <v-card class="mx-auto" color="#dc3545" dark max-width="400">
                <v-card-title>
                  <span class="text-h6 font-weight-light">إجابات (لا)</span>
                </v-card-title>

                <v-card-text class="text-h5 font-weight-bold">
                  {{ row_list.true_Questions }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="4" md="4" class="py-0 mb-1">
              <v-card class="mx-auto" color="#26c6da" dark max-width="400">
                <v-card-title>
                  <span class="text-h6 font-weight-light">إجابات (نعم)</span>
                </v-card-title>

                <v-card-text class="text-h5 font-weight-bold">
                  {{ row_list.false_Questions }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="4" md="4" class="py-0 mb-1">
              <v-card class="mx-auto" color="#28a745" dark max-width="400">
                <v-card-title>
                  <span class="text-h6 font-weight-light">اسئلة لم يتم الاجابة عنها</span>
                </v-card-title>

                <v-card-text class="text-h5 font-weight-bold">
                  {{ row_list.total_questions_not_answered }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              v-for="(item, i) in row_list.questions"
              :key="i"
              cols="12"
              class="py-0 mb-1"
            >
              <div class="mb-2">
                {{ item.question }}
              </div>
              <v-radio-group
                row
                v-model="lst[item.id]"
                @change="setVal($event, item)"
                class="mr-n4 px-0"
              >
                <v-radio :value="1" label="نعم"></v-radio>
                <v-radio :value="2" label="لا"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="$store.state.QualityQuestionsDialog = false"
          >
            الغاء
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="fire_url"> ارسال </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Axios from "axios";

export default {
  name: "QualityQuestionsDialog",

  data() {
    return {
      row_list: [],
      fileds: [],
      req_id: "",
      lst: [],
    };
  },
  watch: {
    "$store.state.QualityQuestionsDialog": {
      handler() {
        this.get_request_questions();
        this.lst = [];
      },
    },
  },
  async created() {
    this.get_request_questions();
  },
  methods: {
    setVal(e, item) {
      this.fileds.push({
        question_id: item.id,
        answer: e,
      });
    },
    fire_url() {
      this.loading = true;
      const formData = new FormData();
      formData.append("survey_id", this.row_list.survey.id);
      formData.append("data", [this.fileds]);
      Axios.post(
        "https://appproduct.alwsata.com.sa/api/employee/quality/quality-questions/answers",
        {
          survey_id: this.row_list.survey.id,
          data: this.fileds,
        },
        {
          headers: this.request_headers(),
        }
      )
        .then((response) => {
          this.showPopup("success", "تم  بنجاح");
          this.refresh_request_details();
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          this.$store.state.QualityQuestionsDialog = false;
        });
    },
    async get_request_questions() {
      this.req_id = this.$store.state.QualityQuestionsDialogReqId;
      this.loading = true;
      if (this.req_id !== undefined) {
        await Axios.get(
          `https://appproduct.alwsata.com.sa/api/employee/quality/quality-questions/${this.req_id}`,
          {
            headers: this.request_headers(),
          }
        ).then((response) => {
          this.row_list = response.data.payload;
          let answers = response.data.payload.survey_questions_answers;
          answers.forEach((element) => {
            this.lst[element.ques_id] = element.answer;
          });
        });
      }
    },
  },
};
</script>
