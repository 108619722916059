<template>
  <page-component
      style="position: relative"
      :loading="loading"
      class="log-page"
  >
    <template #content>
      <v-sheet class="px-5" style="margin-top: 10px; padding-top: 15px">
        <div class="font-weight-bold"
        >سجل الطلب</div
        >
        <v-timeline class="my-10" align-top dense style="margin-right: 45px;">
          <v-timeline-item
              color="primary"
              small
              v-for="(item, index) in request_logs"
              :key="item.id"
          >
            <template v-slot:icon>
              <div class="logDate">
                <div>
                  {{ item.raw_date.split("T")[0] }}
                </div>

                <div>{{
                    item.raw_date.split("T")[1].split(".")[0]
                  }}
                </div>
              </div>
            </template>
            <v-alert
                border="left"
                colored-border
                :color="getAlertColor(index)"
                elevation="2"
                style="
                    border: 1px solid #c5c5c5;
                    box-shadow: unset !important;
                    background-color: #fbfbfb;
                    padding: 5px 15px !important;
                    font-size: 14px;
                    margin-top: 2px;
                    font-weight: bold;
                    border-radius: 6px;
                  "
                class="ml-5"
            >
              <div style="
              position: absolute;
              right: -15px;
              top: 15px;">
                <img src="@/assets/icons/Polygon.svg" alt="image"/>
              </div>
              <div class="pr-4 info-dad">
                <span style="font-size: 18px; font-weight: bold">{{ item.title }}</span>
              </div>
              <div class="my-2">
                <span>{{ item.text }} : {{ item.value }}</span>
              </div>
            </v-alert>
          </v-timeline-item>
        </v-timeline>


      </v-sheet>
    </template>
  </page-component>
</template>

<script>
import Axios from "axios";
export default {
  props: ["agent"],
  data() {
    return {
      request_logs: [],
      date: {
        birth_date: "",
        birth_date_higri: "",
      },
      birth_date: true,
      loading: false,
    };
  },
  created() {
    this.get_request_logs();
  },
  methods: {
    getAlertColor(index) {
      return index % 2 === 0 ? '#90DBAE' : '#e6b5e6';
    },

    get_request_logs() {
      this.loading = true;
      Axios.get(
        `https://appproduct.alwsata.com.sa/api/agent/Request/History-With-Notes/${this.$store.state.request_info.id}`,
        {
          headers: this.request_headers(),
        }
      )
        .then((response) => {
          this.request_logs = response.data.payload;
          this.loading = false;
          this.setBreadCrumbs([
            "الرئيسية",
            "الطلبات",
            this.agent.customer.name,
          ]);
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
          this.loading = false;
        });
    },
  },
  watch: {
    agent_data: {
      handler(val) {
        val.customer.birth_date = this.date.birth_date;
        val.customer.birth_date_higri = this.date.birth_date_higri;
        val.source = parseInt(val.source);
        val.customer.work = parseInt(val.customer.work);
        this.setBreadCrumbs(["الرئيسية", "الطلبات", val.customer.name]);
      },
    },
  },
};
</script>
<style lang="scss">
.log-page {
  .info-dad {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .v-application--is-rtl .v-timeline--dense:not(.v-timeline--reverse)::before {
    background-color: #4516466c !important;
    width: 3px !important;
  }
  .theme--light.v-timeline .v-timeline-item__dot {
    border: 1px solid #451646 !important;
    margin-top: 18px;
    & > div {
      background-color: white !important;
    }
  }
}
@media (max-width: 578px) {
  .log-page {
    .v-alert {
      width: 110% !important;
      transform: translateX(28px) !important;
    }
    .v-alert__content {
      span:not(:first-child) {
        font-size: 10px !important;
      }
      span:first-child {
        font-size: 13px !important;
      }
    }
    .v-timeline-item__dot {
      height: 14px !important;
      width: 14px !important;
    }
    .v-timeline-item__inner-dot {
      display: none !important;
    }
  }
}
</style>
