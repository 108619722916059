<template>
  <div>
    <v-list-item @click="toRequestDetails">
      <v-list-item-icon class="ml-2">
        <v-icon>mdi-eye-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-title>فتح الطلب</v-list-item-title>
    </v-list-item>

    <router-link
      style="text-decoration: none"
      target="_blank"
      :to="{ name: 'request_details', params: { id: item } }"
    >
      <v-list-item>
        <v-list-item-icon class="ml-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.528"
            height="15.111"
            viewBox="0 0 13.528 10.111"
          >
            <g
              id="Icon_feather-eye-dark"
              data-name="Icon feather-eye-dark"
              transform="translate(-1 -5.5)"
            >
              <path
                id="Path_4040"
                data-name="Path 4040"
                d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
              <path
                id="Path_4041"
                data-name="Path 4041"
                d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                transform="translate(-7.444 -4.653)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title> فتح في تاب جديد</v-list-item-title>
      </v-list-item>
    </router-link>

    <v-list-item @click="toOrderRequest">
      <v-list-item-icon class="ml-2">
        <OrderHistory />
      </v-list-item-icon>
      <v-list-item-title>سجل الطلب</v-list-item-title>
    </v-list-item>

    <v-list-item @click="isProccessed" v-if="rateRecord.is_processed == 1">
      <v-list-item-icon class="ml-2">
        <ProcessedIcon />
      </v-list-item-icon>
      <v-list-item-title>تمت المعالجة</v-list-item-title>
    </v-list-item>

    <v-list-item @click="getTasks">
      <v-list-item-icon class="ml-3">
        <img src="@/assets/icons/ticketIcon.svg" />
      </v-list-item-icon>
      <v-list-item-title> التذاكر</v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import { OrderHistory } from "@/assets/adminIcons/orderHistory";
import { ProcessedIcon } from "@/assets/adminIcons/processedIcon";
import { axiosInstance } from "@/axios/config";

export default {
    props:['item','idOfRate','rateRecord'],
    components:{OrderHistory, ProcessedIcon},
    methods:{
        toRequestDetails() {
            this.$router.push({
              name: "request_details",
              params: { id: this.item },
            });
        },
        
        toOrderRequest() {
          // show request history section
          this.$store.state.orderRequestId = this.item;
          this.$store.state.openOrderRequestSection = true;
          
          // hide task section
          this.$store.state.tasksRequestId = null;
          this.$store.state.openTasksRequestSection = false;
          // hide task details section
          this.$store.state.tasksDetailsRequestId = null;
          this.$store.state.openTasksDetailsRequestSection = false;
          // hide add task section
          this.$store.state.openAddTaskForRequest = false;
        },

        getTasks() {
          // show task section
          this.$store.state.tasksRequestId = this.item;
          this.$store.state.openTasksRequestSection = true;
          // hide hstory section
          this.$store.state.orderRequestId = null;
          this.$store.state.openOrderRequestSection = false;
          // hide task details section
          this.$store.state.tasksDetailsRequestId = null;
          this.$store.state.openTasksDetailsRequestSection = false;
          // hide add task section
          this.$store.state.openAddTaskForRequest = false;
        },

    isProccessed() {
      axiosInstance
        .post(`/employee/admin/convert-to-proccess/${this.idOfRate}`)
        .then((res) => {
          this.showPopup("success", res.data.payload);
          this.$emit('refreshAfterProccesses')
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },
  },
};
</script>
