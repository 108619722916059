<template>
  <v-card
    @click="$emit('getCardId', item.id)"
    class="px-2 position-relative"
    style="
      height: 130px;
      border: 1px solid #d4d5d5;
      border-radius: 8px !important;
      overflow: hidden;
    "
  >
    <span
      class="right-color card__border"
      :style="`${
        returnStatusReq(item) === 0
          ? `background-color: #017F85`
          : returnStatusReq(item) === 1
          ? `background-color: #41BF72`
          : returnStatusReq(item) === 2
          ? `background-color: #DEB523`
          : `background-color: #FE705B`
      } `"
    >
    </span>
    <div class="card-head d-flex justify-space-between align-center">
      <v-card-title class="pa-0 requestTitle">
        {{ returnName(item) }}
      </v-card-title>
      <div>
        <span class="req-date" style="color: #545a69">
          {{ returnDate(item) }}
        </span>
      </div>
    </div>

    <div class="req-status-parent mt-1 d-flex justify-space-between align-center">
      <span class="req-type">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M18.72 14.7587C19.07 13.9087 19.26 12.9987 19.26 11.9987C19.26 11.2787 19.15 10.5887 18.96 9.94873C18.31 10.0987 17.63 10.1787 16.92 10.1787C15.466 10.1803 14.0329 9.83225 12.7415 9.16395C11.4502 8.49565 10.3384 7.52668 9.5 6.33873C8.6031 8.5089 6.91112 10.2545 4.77 11.2187C4.73 11.4687 4.73 11.7387 4.73 11.9987C4.73 12.9534 4.91804 13.8988 5.2834 14.7808C5.64875 15.6629 6.18425 16.4643 6.85933 17.1394C8.22272 18.5028 10.0719 19.2687 12 19.2687C13.05 19.2687 14.06 19.0387 14.97 18.6287C15.54 19.7187 15.8 20.2587 15.78 20.2587C14.14 20.8087 12.87 21.0787 12 21.0787C9.58 21.0787 7.27 20.1287 5.57 18.4187C4.536 17.3879 3.76737 16.1217 3.33 14.7287H2V10.1787H3.09C3.42024 8.57124 4.17949 7.08314 5.28719 5.87232C6.39489 4.6615 7.80971 3.77313 9.38153 3.30148C10.9533 2.82984 12.6235 2.7925 14.2149 3.19343C15.8062 3.59437 17.2593 4.41862 18.42 5.57873C19.6802 6.83398 20.5398 8.43483 20.89 10.1787H22V14.7287H21.94L18.38 17.9987L13.08 17.3987V15.7287H17.91L18.72 14.7587ZM9.27 11.7687C9.57 11.7687 9.86 11.8887 10.07 12.1087C10.281 12.3215 10.3995 12.609 10.3995 12.9087C10.3995 13.2084 10.281 13.496 10.07 13.7087C9.86 13.9187 9.57 14.0387 9.27 14.0387C8.64 14.0387 8.13 13.5387 8.13 12.9087C8.13 12.2787 8.64 11.7687 9.27 11.7687ZM14.72 11.7687C15.35 11.7687 15.85 12.2787 15.85 12.9087C15.85 13.5387 15.35 14.0387 14.72 14.0387C14.09 14.0387 13.58 13.5387 13.58 12.9087C13.58 12.6064 13.7001 12.3164 13.9139 12.1026C14.1277 11.8888 14.4177 11.7687 14.72 11.7687Z"
            fill="#3F0E40"
          />
        </svg>
        <span class="userName">
          {{ item.user?.name || item.request?.user?.name || "لا يوجد" }}
        </span>
      </span>
      <span
        class="req-status spanRequestType"
        :style="`${
          returnStatusReq(item) === 0
            ? `background-color: #017F85`
            : returnStatusReq(item) === 1
            ? `background-color: #41BF72`
            : returnStatusReq(item) === 2
            ? `background-color: #DEB523`
            : `background-color: #FE705B`
        }`"
      >
        {{ returnStatus(item) }}
      </span>
    </div>

    <div class="req-status-parent d-flex justify-space-between align-center">
      <span class="agent__text">
        {{ returnAgentText(item) || "لا يوجد" }}
      </span>
    </div>

    <hr style="border: 1px solid #000000; opacity: 0.1" class="mb-0" />
    <div class="desc parent">
      <v-tooltip bottom color="#451646">
        <template v-slot:activator="{ on }">
          <v-card-text v-on="on" class="px-0 py-2">
            {{
              returnNote(item)
                ? returnNote(item).length > 30
                  ? returnNote(item).substring(0, 30) + "..."
                  : returnNote(item)
                : "لايوجد ملاحظات"
            }}
          </v-card-text>
        </template>
        <div style="font-size: 14px; background-color: #451646; color: white">
          {{ returnNote(item) }}
        </div>
      </v-tooltip>
    </div>
  </v-card>
</template>

<script setup>
import { defineProps } from "vue";
const props = defineProps(["item"]);

const objOne = {
  "/admin/requests/all": "/admin/requests/all",
  "/admin/requests/recieved": "/admin/requests/recieved",
  "/admin/requests/followed": "/admin/requests/followed",
  "/admin/requests/featured": "/admin/requests/featured",
  "/admin/requests/archieved": "/admin/requests/archieved",
  "/admin/requests/latest": "/admin/requests/latest",
  "/admin/requests/pending": "/admin/requests/pending",
  "/admin/requests/freeze": "/admin/requests/freeze",
};

const objTwo = {
  "/admin/requests/need-action-new": "/admin/requests/need-action-new",
  "/admin/requests/wating-new": "/admin/requests/wating-new",
  "/admin/requests/wating-done": "/admin/requests/wating-done",
  "/admin/requests/need-action-done": "/admin/requests/need-action-done",
  "/admin/requests/archived-in-quality": "/admin/requests/archived-in-quality",
};

const objThree = {
  "/admin/requests/need-to-turn-new": "/admin/requests/need-to-turn-new",
  "/admin/requests/need-to-turn-done": "/admin/requests/need-to-turn-done",
};

const returnName = (item) => {
  if (objOne[window.location.pathname]) {
    return item.customer?.name;
  } else if (objTwo[window.location.pathname]) {
    return item.request?.customer?.name;
  } else if (objThree[window.location.pathname]) {
    return item.quality_request?.request?.customer?.name;
  }
};

const returnStatusReq = (item) => {
  if (objOne[window.location.pathname]) {
    return item.statusReq;
  } else if (objTwo[window.location.pathname]) {
    return item.request.statusReq;
  } else if (objThree[window.location.pathname]) {
    return item.quality_request.request.statusReq;
  }
};

const returnStatus = (item) => {
  if (objOne[window.location.pathname]) {
    return item.status || "معلقه";
  } else if (objTwo[window.location.pathname]) {
    return item.request.status;
  } else if (objThree[window.location.pathname]) {
    return item.quality_request.request.status;
  } else {
    return item.status;
  }
};

const returnDate = (item) => {
  if (objOne[window.location.pathname]) {
    return item.req_date;
  } else if (objTwo[window.location.pathname]) {
    return item.request.req_date;
  } else if (objThree[window.location.pathname]) {
    return item.quality_request.request.req_date;
  }
};

const returnNote = (item) => {
  if (objOne[window.location.pathname]) {
    return item.agent_notes || "لايوجد ملاحظات";
  } else if (objTwo[window.location.pathname]) {
    return item.request.agent_notes || "لايوجد ملاحظات";
  } else if (objThree[window.location.pathname]) {
    return item.quality_request.request.agent_notes || "لايوجد ملاحظات";
  }
};

const returnAgentText = (item) => {
  if (objOne[window.location.pathname]) {
    return item.class_id_agent_text;
  } else if (objTwo[window.location.pathname]) {
    return item.request.class_id_agent_text;
  } else if (objThree[window.location.pathname]) {
    return item.quality_request.request.class_id_agent_text;
  }
};
</script>
