<template>
  <div>
    <v-sheet width="70%" class="mx-auto">
      <v-form fast-fail @submit.prevent style="border-radius: 5px; overflow: hidden">
        <div class="addUser__header">أضافة مستخدم</div>

        <v-col cols="12" sm="12" md="12">
          <v-text-field label="الإسم" v-model="userData.name" outlined dense>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12" style="margin-top: -15px">
          <v-text-field
            label="إسم المستخدم"
            :rules="feildRequird"
            :error-messages="showErrors ? userNameErrorMessages : []"
            v-model="userData.username"
            outlined
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12" style="margin-top: -15px">
          <v-text-field
            label="الاسم عند الادارة"
            v-model="userData.name_for_admin"
            outlined
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12" style="margin-top: -15px">
          <v-text-field
            label="الاسم في الكول سينتر"
            v-model="userData.name_in_callCenter"
            outlined
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12" style="margin-top: -15px">
          <v-text-field
            label="البريد الإلكتروني"
            :rules="emailRequired"
            :error-messages="showEmailErrors ? emailErrorMessages : []"
            v-model="userData.email"
            outlined
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12" style="margin-top: -15px">
          <v-text-field
            label="رقم الجوال"
            v-model="userData.mobile"
            :rules="phoneRequired"
            v-numericOnly
            outlined
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12" style="margin-top: -15px">
          <v-text-field
            label="كلمه المرور"
            v-model="userData.password"
            :rules="passwordRequired"
            :error-messages="showPasswordErrors ? passwordErrorMessages : []"
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="12" md="12" style="margin-top: -15px">
          <v-autocomplete
            :items="[
              { text: 'عربي', name: 'عربي', value: 'ar' },
              { text: 'انجليزي', name: 'انجليزي', value: 'en' },
            ]"
            label="اللغة "
            v-model="userData.locale"
            :rules="langRequird"
            :error-messages="showLangErrors ? langErrorMessages : []"
            outlined
            dense
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-title
                  :id="attrs['aria-labelledby']"
                  v-text="item.text"
                ></v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="12" md="12" style="margin-top: -15px">
          <v-autocomplete
            :items="userType"
            label="المسمى الوظيفي"
            v-model="userData.role"
            :rules="roleRequird"
            :error-messages="showRoleErrors ? roleErrorMessages : []"
            outlined
            dense
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-title
                  :id="attrs['aria-labelledby']"
                  v-text="item.name"
                ></v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" sm="12" md="12" style="margin-top: -15px" v-if="show.showManger">
          <v-autocomplete
            :items="resultType"
            label="مدير المبيعات"
            v-model="userData.salesmanager"
            :rules="mangerRequird"
            :error-messages="showMangerErrors ? mangerErrorMessages : []"
            outlined
            dense
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-title
                  :id="attrs['aria-labelledby']"
                  v-text="item.name"
                ></v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12" sm="12" md="12" v-if="show.showManger">
          <SelectInput
            @selectedValues="getSelectedHidenBasket"
            :originalItems="basketSettingsData"
            label="سلال لا يمكن رؤيتها من الاستشاري"
          />
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="12"
          style="margin-top: -15px"
          v-if="show.showFunding"
        >
          <v-autocomplete
            :items="morgage"
            label="مشرف التمويل"
            v-model="userData.fundingmanager"
            :rules="mortgageRequird"
            :error-messages="showMortgageErrors ? morErrorMessages : []"
            outlined
            dense
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-title
                  :id="attrs['aria-labelledby']"
                  v-text="item.name"
                ></v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="12"
          style="margin-top: -15px"
          v-if="show.showFunding"
        >
          <v-autocomplete
            :items="funding"
            label="مدير الرهن"
            v-model="userData.mortgagemanager"
            :rules="fundingRequird"
            :error-messages="showFundingErrors ? funErrorMessages : []"
            outlined
            dense
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-title
                  :id="attrs['aria-labelledby']"
                  v-text="item.name"
                ></v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="12"
          style="margin-top: -15px"
          v-if="show.showGlobalManger"
        >
          <v-autocomplete
            :items="resultType"
            label="المدير العام"
            v-model="userData.generalmanager"
            :rules="genaralRequird"
            :error-messages="showgeneralErrors ? generalErrorMessages : []"
            outlined
            dense
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-title
                  :id="attrs['aria-labelledby']"
                  v-text="item.name"
                ></v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="12"
          v-if="show.showQulityFollowingStatus"
          style="margin-top: -60px"
        >
          <v-checkbox
            v-model="userData.is_follow"
            label="قابل للمتابعه من قبل مشرف الجودة"
            type="checkbox"
          ></v-checkbox>
        </v-col>

        <div v-if="show.showHelperForm">
          <v-col cols="12" sm="12" md="12">
            <SelectInput
              @selectedValues="getSelectedValueAgent"
              :originalItems="resultType"
              label="استشاري المبيعات"
            />
          </v-col>

          <div style="margin-top: -15px; margin-right: 15px; margin-bottom: 15px">
            أين يخدم ؟
          </div>

          <v-col cols="12" sm="12" md="12">
            <SelectInput
              @selectedValues="getSelectedAreas"
              :originalItems="area"
              label="المنطقة"
            />
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <SelectInput
              @selectedValues="getSelectedCitys"
              :originalItems="citys"
              label="المدينه"
            />
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <SelectInput
              @selectedValues="getSelectedDistrict"
              :originalItems="distracts"
              label="الحي"
            />
          </v-col>

          <v-col cols="12" sm="12" md="12" style="margin-top: -15px">
            <v-autocomplete
              :items="directions"
              label="الإتجاه"
              v-model="userData.direction"
              outlined
              dense
            >
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-title
                    :id="attrs['aria-labelledby']"
                    v-text="item.name"
                  ></v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="12" style="margin-top: -60px">
            <v-checkbox
              v-model="userData.is_agent_show"
              label=" إظهار إسم الإستشاريين"
              type="checkbox"
            ></v-checkbox>
          </v-col>
        </div>

        <div v-if="show.showBankEmployee">
          <v-col cols="12" sm="12" md="12">
            <SelectInput
              @selectedValues="getSelectedValueAgent"
              :originalItems="resultType"
              label="استشاري المبيعات"
            />
          </v-col>

          <v-col cols="12" sm="12" md="12">
            <SelectInput
              @selectedValues="getSelectedMortigage"
              :originalItems="morgage"
              label="مشرفين التمويل"
            />
          </v-col>

          <v-col cols="12" sm="12" md="12" style="margin-top: -25px; margin-bottom: 25px">
            <v-autocomplete
              label="البنك"
              :items="$store.state.salary_sources"
              v-model="userData.bank_id"
              item-text="value"
              item-value="id"
              hide-details
              class="mr-1"
              outlined
              dense
            >
              <img src="@/assets/icons/down-arrow.svg" />
            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" md="12" style="margin-top: -15px">
            <v-text-field label="Subdomain" v-model="userData.subdomain" outlined dense>
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="12" style="margin-top: -15px">
            <v-text-field
              label="كود مصدر المعاملة"
              v-model="userData.code"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </div>

        <v-col
          cols="12"
          sm="12"
          md="12"
          style="margin-top: -15px"
          v-if="show.showAccountent"
        >
          <v-autocomplete
            :items="accountent"
            label="نوع المحاسب"
            v-model="userData.accountant_type"
            outlined
            dense
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-title
                  :id="attrs['aria-labelledby']"
                  v-text="item.name"
                ></v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
        <v-btn type="submit" block id="addUser__footer" @click="handleSubmit">حفظ</v-btn>
      </v-form>
    </v-sheet>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, defineEmits, watch } from "vue";
import { axiosInstance } from "@/axios/config";
import SelectInput from "@/components/Charts/SelectInput.vue";

// Create validation rules
const feildRequird = computed(() => [(value) => value?.length > 0 || "هذا الحقل مطلوب"]);
const passwordRequired = computed(() => [
  (value) => (value && value.length > 6) || "كلمة المرور يجب أن تكون أطول من 6 أحرف",
]);

const phoneRequired = computed(() => [
  (value) => value.length >= 9 || "يجب ان يكون رقم الهاتف مكون من 9 ارقام",
  (value) => /^[5]/.test(value) || "يجب أن يبدأ رقم الهاتف بالرقم 5",
]);

const emailRequired = computed(() => [
  (value) =>
    /^\S+@\S+\.\S+$/.test(value) || "يجب أن يكون البريد الإلكتروني بالصيغة الصحيحة",
]);
const roleRequird = [(value) => value !== "" || "المسمى الوظيفي مطلوب"];
const mangerRequird = [(value) => value !== "" || "هذا الحقل  مطلوب"];
const fundingRequird = [(value) => value !== "" || "هذا الحقل  مطلوب"];
const mortgageRequird = [(value) => value !== "" || "هذا الحقل  مطلوب"];
const genaralRequird = [(value) => value !== "" || "هذا الحقل  مطلوب"];
const langRequird = [(value) => value != false || "اللغه مطلوبة"];

const showErrors = ref(false);
const showPasswordErrors = ref(false);
const showRoleErrors = ref(false);
const showMangerErrors = ref(false);
const showLangErrors = ref(false);
const showEmailErrors = ref(false);
const showMortgageErrors = ref(false);
const showFundingErrors = ref(false);
const showgeneralErrors = ref(false);

// Extract error messages from rules

const userNameErrorMessages = computed(() =>
  feildRequird.value
    .map((rule) => rule(userData.value.username))
    .filter((message) => message !== true)
);

const passwordErrorMessages = computed(() =>
  passwordRequired.value
    .map((rule) => rule(userData.value.password))
    .filter((message) => message !== true)
);

const emailErrorMessages = computed(() =>
  emailRequired.value
    .map((rule) => rule(userData.value.email))
    .filter((message) => message !== true)
);

const roleErrorMessages = computed(() =>
  roleRequird
    .map((rule) => rule(userData.value.role))
    .filter((message) => message !== true)
);

const funErrorMessages = computed(() =>
  roleRequird
    .map((rule) => rule(userData.value.fundingmanager))
    .filter((message) => message !== true)
);

const generalErrorMessages = computed(() =>
  roleRequird
    .map((rule) => rule(userData.value.generalmanager))
    .filter((message) => message !== true)
);

const morErrorMessages = computed(() =>
  roleRequird
    .map((rule) => rule(userData.value.mortgagemanager))
    .filter((message) => message !== true)
);

const mangerErrorMessages = computed(() =>
  roleRequird
    .map((rule) => rule(userData.value.salesmanager))
    .filter((message) => message !== true)
);

const langErrorMessages = computed(() =>
  langRequird
    .map((rule) => rule(userData.value.locale))
    .filter((message) => message !== true)
);

const selectedAgents = ref([]);
const getSelectedValueAgent = (val) => {
  selectedAgents.value = val;
};

const userData = ref({
  name: "",
  username: "",
  name_for_admin: "",
  name_in_callCenter: "",
  email: "",
  mobile: "",
  password: "",
  locale: "",
  role: "",
  salesmanager: "",
  fundingmanager: "",
  mortgagemanager: "",
  generalmanager: "",
  is_follow: "",
  direction: "",
  is_agent_show: "",
  accountant_type: "",
  subdomain: "",
  bank_id: "",
  code: "",
});

const userType = ref([]);
let basketSettingsData = ref({});

const getWorkName = () => {
  axiosInstance.get(`/employee/admin/announcements/allRoles`).then((res) => {
    userType.value = res.data.payload.map((item) => {
      item["color"] = "red";
      item["text"] = item["name"];
      return item;
    });
  });
};

const getBaskets = () => {
  axiosInstance.post(`/employee/admin/all-Basket-settings-data`,{}).then((res) => {
    basketSettingsData.value = res.data.payload.data.map((item) => {
      item["color"] = "red";
      item["text"] = item["basket_name"];
      // item["text"] = item["name"];
      item["value"] = item["id"];
      return item;
    });
  });
};


const directions = ref([]);
const handleDirection = () => {
  let data = [
    { name: "شمال", value: "north" },
    { name: "جنوب", value: "south" },
    { name: "شرق", value: "east" },
    { name: "غرب", value: "west" },
  ];
  directions.value = data.map((item) => {
    item["color"] = "red";
    item["text"] = item["name"];
    return item;
  });
};

const area = ref([]);
const getAreas = () => {
  axiosInstance.get(`/areas`).then((res) => {
    area.value = res.data.payload.map((item) => {
      item["color"] = "red";
      item["text"] = item["value"];
      return item;
    });
  });
};

const citys = ref([]);
const getCitys = (val) => {
  let body = new FormData();
  val.forEach((item, index) => {
    body.append(`area_id[${index}]`, item["id"]);
  });
  axiosInstance.post(`/cities`, body).then((res) => {
    citys.value = res.data.payload.map((item) => {
      item["color"] = "red";
      item["text"] = item["value"];
      return item;
    });
  });
};

const distracts = ref([]);
const getDistracts = (val) => {
  let body = new FormData();
  val.forEach((item, index) => {
    body.append(`city_id[${index}]`, item["id"]);
  });
  axiosInstance.post(`/districts`, body).then((res) => {
    distracts.value = res.data.payload.map((item) => {
      item["color"] = "red";
      item["text"] = item["value"];
      return item;
    });
  });
};



const selectedArea = ref([]);
const getSelectedAreas = (val) => {
  selectedArea.value = val;
  getCitys(val);
};

const selectedCitys = ref([]);
const getSelectedCitys = (val) => {
  selectedCitys.value = val;
  getDistracts(val);
};

const selectedDistrict = ref([]);
const getSelectedDistrict = (val) => {
  selectedDistrict.value = val;
};

const selectedHidenBasket = ref([]);
const getSelectedHidenBasket = (val) => {
  selectedHidenBasket.value = val;
};

const selectedMortage = ref([]);
const getSelectedMortigage = (val) => {
  selectedMortage.value = val;
};

const emit = defineEmits(["saveUser"]);
const handleSubmit = () => {
  if (!userData.value.username) {
    showErrors.value = true;
  }

  if (!userData.value.password) {
    showPasswordErrors.value = true;
  }

  if (!userData.value.role) {
    showRoleErrors.value = true;
  }
  if (!userData.value.locale) {
    showLangErrors.value = true;
  }

  if (!userData.value.salesmanager) {
    showMangerErrors.value = true;
  }

  if (!userData.value.mortgagemanager) {
    showMortgageErrors.value = true;
  }

  if (!userData.value.fundingmanager) {
    showFundingErrors.value = true;
  }

  if (!userData.value.generalmanager) {
    showgeneralErrors.value = true;
  }

  if (!userData.value.email) {
    showEmailErrors.value = true;
  }

  if (selectedArea.value.length) {
    selectedArea.value.forEach((item, index) => {
      userData.value[`area_id[${index}]`] = item["id"];
    });
  }

  if (selectedAgents.value.length) {
    selectedAgents.value.forEach((item, index) => {
      userData.value[`salesagents[${index}]`] = item["id"];
    });
  }

  if (selectedCitys.value.length) {
    selectedCitys.value.forEach((item, index) => {
      userData.value[`city_id[${index}]`] = item["id"];
    });
  }

  if (selectedDistrict.value.length) {
    selectedDistrict.value.forEach((item, index) => {
      userData.value[`district_id[${index}]`] = item["id"];
    });
  }

  if (selectedHidenBasket.value.length) {
    selectedHidenBasket.value.forEach((item, index) => {
      userData.value[`baskets_ids[${index}]`] = item["id"];
    });
  }

  if (selectedAgents.value.length) {
    selectedAgents.value.forEach((item, index) => {
      userData.value[`fundingmanagers[${index}]`] = item["id"];
    });
  }

  const data = userData.value;
  let body = {};
  for (const key in data) {
    if (data[key] !== "" && data[key] !== undefined && data[key] !== null) {
      body[key] = data[key];
    }
  }

  if (
    userData.value.username &&
    userData.value.password &&
    userData.value.role !== "" &&
    userData.value.email
  ) {
    emit("saveUser", body);
  }
};

const resultType = ref([]);
const morgage = ref([]);
const funding = ref([]);

const show = ref({
  showManger: false,
  showFunding: false,
  showGlobalManger: false,
  showQulityFollowingStatus: false,
  showHelperForm: false,
  showAccountent: false,
  showBankEmployee: false,
});

watch(
  () => userData.value.role,
  (current) => {
    const result = show.value;
    for (const key in result) {
      result[key] = false;
    }

    if (current == 0) {
      getResultType(1, resultType);
      show.value.showManger = true;
    }
    if (current == 1) {
      getResultType(1);
      show.value.showFunding = true;
      getResultType(2, morgage);
      getResultType(3, funding);
    }

    if (current == 3 || current == 2) {
      show.value.showGlobalManger = true;
      getResultType(4, resultType);
    }

    if (current == 5) {
      show.value.showQulityFollowingStatus = true;
    }

    if (current == 6) {
      show.value.showHelperForm = true;
      getResultType(0, resultType);
      getAreas();
      handleDirection();
    }

    if (current == 8) {
      show.value.showAccountent = true;
      getAccountentType();
    }

    if (current == 13) {
      show.value.showBankEmployee = true;
      getResultType(0, resultType);
      getResultType(2, morgage);
    }
  }
);
const getResultType = (role, place) => {
  axiosInstance.get(`/employee/admin/getUsersBasedOnRole/${role}`).then((res) => {
    place.value = res.data.message.map((item) => {
      item["color"] = "red";
      item["text"] = item["name"];
      item["value"] = item["id"];
      return item;
    });
  });
};

const accountent = ref([]);
const getAccountentType = () => {
  let data = [
    { name: "محاسب تساهيل", value: 0 },
    { name: "محاسب وساطة", value: 1 },
  ];
  accountent.value = data.map((item) => {
    item["color"] = "red";
    item["text"] = item["name"];
    return item;
  });
};

onMounted(() => {
  getWorkName();
  getBaskets();
});
</script>

<style>
.addUser__header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  background: #3f0e40;
  color: white;
  font-weight: bold;
  font-size: 20px;
}
#addUser__footer {
  height: 70px;
  background: #3f0e40;
  color: white;
  font-weight: bold;
  font-size: 20px;
}
</style>

// eye icon // when the manger appear
