<template>
  <div>
    <UserCard v-if="$store.state.isProfile" />
    <ProfileHeader />
    <div class="table__view">
      <v-btn
        color="primary"
        style="font-size: 16px; margin-bottom: 10px"
        @click="handlAddDecument"
      >
        إضافة ملف
      </v-btn>

      <table-component
        :showSelect="false"
        itemKey="id"
        :headers="headers"
        :limit="10"
        :items="tableData"
        :loading="loading"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#3f0e40" small depressed dark v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list><AttachProccess :item="item" @handleDelete="handleDelete" /> </v-list>
          </v-menu>
        </template>
      </table-component>
    </div>

    <div class="table__view">
      <v-card-title>المرفقات المؤرشفة</v-card-title>
      <table-component
        :showSelect="false"
        itemKey="id"
        :headers="archivedHeaders"
        :limit="10"
        :items="tableArchivedData"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#3f0e40" small depressed dark v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <AttachmentsArchivedProccess
                :item="item"
                @remove="handleDelete"
                @restore="handleRestore"
              />
            </v-list>
          </v-menu>
        </template>
      </table-component>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialog" width="800">
        <v-card>
          <v-card-title>
            <span>{{ dialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-col cols="12" sm="12">
              <v-text-field type="text" label="إسم الملف" v-model="fileName" />
            </v-col>
            <v-col cols="12" sm="12">
              <v-file-input label="File input" @change="handleFileChange"></v-file-input>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green-darken-1" variant="text" @click="dialog = false">
              الغاء
            </v-btn>
            <v-btn
              color="green-darken-1"
              variant="text"
              primary
              @click="handleOpenConfirm"
            >
              تأكيد
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="activeSnack"
        timeout="-1"
        right
        bottom
        class="ml-4"
        elevation="24"
        transition="fade-transition"
      >
        <div>
          <span class="font-weight-bold" style="font-size: 16px">تمت الاضافه</span>
        </div>
        <template v-slot:action>
          <v-btn icon small @click="activeSnack = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="deleteDialog" width="400">
        <v-card>
          <v-card-title>
            <span>هل انت متأكد</span>
          </v-card-title>
          <v-card-text>{{ cofirmBody }} </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green-darken-1" variant="text" @click="deleteDialog = false">
              الغاء
            </v-btn>
            <v-btn color="green-darken-1" variant="text" primary @click="handleConfirm">
              تأكيد
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="activeSnack"
        timeout="-1"
        right
        bottom
        class="ml-4"
        elevation="24"
        transition="fade-transition"
      >
        <div>
          <span class="font-weight-bold" style="font-size: 16px">تمت الاضافه</span>
        </div>
        <template v-slot:action>
          <v-btn icon small @click="activeSnack = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </div>
</template>
<script setup>
import ProfileHeader from "./ProfileHeader.vue";
import UserCard from "./UserCard.vue";
import { ref, onMounted } from "vue";
import { axiosInstance } from "@/axios/config";
import AttachProccess from "./AttachProccess.vue";
import AttachmentsArchivedProccess from "./AttachmentsArchivedProccess";
import router from "@/routes";
import { store } from "@/store";

const dialog = ref(false);
const deleteDialog = ref(false);
const dialogTitle = ref("");
const fileName = ref(``);
const handlAddDecument = () => {
  dialog.value = true;
  dialogTitle.value = `ارفاق مرفق`;
};

const targetFile = ref(null);
const handleFileChange = (event) => {
  targetFile.value = event;
};

const activeSnack = ref(false);
const loading = ref(false);

const handleOpenConfirm = () => {
  const formData = new FormData();
  formData.append("name", fileName.value);
  formData.append("file", targetFile.value);
  loading.value = true;

  axiosInstance
    .post(`/employee/hr/user-upload-file/${router.currentRoute.params.id}`, formData)
    .then((res) => {
      activeSnack.value = true;
      dialog.value = false;
      loading.value = false;
      getData();
    });
};

const itemId = ref(null);
const cofirmBody = ref("");
const handleDelete = (item, place) => {
  itemId.value = item.id;
  if (place === "remove") {
    cofirmBody.value = "هل انت متأكد من مسح الملف نهائيا,لن تسطيع إرجاع الملف ؟";
  } else {
    cofirmBody.value = "هل انت متأكد من أرشفة الملف ؟";
  }
  deleteDialog.value = true;
};

const handleRestore = (item) => {
  itemId.value = item.id;
  cofirmBody.value = "هل انت متأكد من إستعادة الملف ؟";
  deleteDialog.value = true;
};

const handleConfirm = () => {
  if (cofirmBody.value === "هل انت متأكد من إستعادة الملف ؟") {
    handleConfirmRestore();
  } else {
    handleConfirmDelete();
  }
};

const handleConfirmRestore = () => {
  axiosInstance.get(`/employee/hr/file-restore/${itemId.value}`).then((res) => {
    deleteDialog.value = false;
    getData();
  });
};

const handleConfirmDelete = () => {
  axiosInstance
    .delete(`/employee/hr/file-archive-or-delete/${itemId.value}`)
    .then((res) => {
      deleteDialog.value = false;
      getData();
    });
};

const headers = ref([
  { text: "#", value: "id", align: "center" },
  { text: "إسم الملف", value: "filename", align: "center" },
  { text: "تاريخ الرفع", value: "upload_date", align: "center" },
  { text: "التحكم ", value: "actions", align: "center" },
]);

const archivedHeaders = ref([
  { text: "#", value: "id", align: "center" },
  { text: "إسم الملف", value: "filename", align: "center" },
  { text: "تم الحذف في ", value: "deleted_at", align: "center" },
  { text: "تاريخ الرفع", value: "upload_date", align: "center" },
  { text: "التحكم ", value: "actions", align: "center" },
]);

const tableData = ref(store.state.employeeFiles);
const tableArchivedData = ref(store.state.archivedEmployeeFiles);

const getData = () => {
  axiosInstance
    .get(`/employee/hr/user-profile/${router.currentRoute.params.id}`)
    .then((res) => {
      tableData.value = res.data.payload.employeeFiles;
      tableArchivedData.value = res.data.payload.archivedEmployeeFiles;
    });
};

onMounted(() => {
  getData();
});
</script>
