export const convertToFormData = (
    data,
    formData = new FormData(),
    parentKey = ""
) => {
    if (Array.isArray(data)) {
        data.forEach((value, index) => {
            const key = `${parentKey}[${index}]`;
            convertToFormData(value, formData, key);
        });
    } else if (typeof data === "object" && data !== null) {
        Object.entries(data).forEach(([key, value]) => {
            const nestedKey = parentKey ? `${parentKey}[${key}]` : key;

            if (Array.isArray(value) && value.every((item) => item instanceof File)) {
                value.forEach((file, index) => {
                    const fileKey = `${nestedKey}[${index}]`;
                    formData.append(fileKey, file);
                });
            } else if (value instanceof File) {
                formData.append(nestedKey, value);
            } else if (value instanceof Date) {
                formData.append(nestedKey, value.toISOString());
            } else {
                convertToFormData(value, formData, nestedKey);
            }
        });
    } else {
        formData.append(parentKey, data);
    }

    return formData;
};
