<template>
  <div>
    <br/>
    <v-row>
        <v-col cols="2">
            <v-sheet class="c-block-1 card-v2">
            <div class="inner-c c-pointer" style="padding-top: 2rem;" @click="openReviews">
                <h4>
                    تقييمات العملاء
                </h4>
                <div
                    class=""
                    :style="'display:flex'"
                    v-if="1"
                    >
                    <span>
                        <v-list-item-icon class="ma-0 pa-0" v-for="index in 5" :key="index">
                        <v-icon size="25" color="warning"> {{ average_rate_stars(index) }} </v-icon>
                        </v-list-item-icon>
                    </span>
                </div>
                <p>
                    ( {{reviews_percent}} out of 5)
                </p>
            </div>
        </v-sheet>
        </v-col>
        <v-col cols="2">
            <v-sheet class="c-block-1 card-v2">
            <div class="inner-c"  style="width: 90%;">
                <h4>
                    التصنيفات
                </h4>
                <v-row class="h6-5rem">
                    <v-col cols="4"  class="outer-d c-pointer" @click="openRequests('positive')">
                        <div class="inner-c-d">{{positive_percent}}%
                            <span class="chart-vertical" :style="` height:${positive_percent}px;`"></span>
                            <label for="">ايجابية</label>
                        </div>
                    </v-col>
                    <v-col cols="4"  class="outer-d c-pointer" @click="openRequests('negative')">
                        <div class="inner-c-d">
                          {{negative_percent}}%
                            <span class="chart-vertical" :style="`background-color: red;  height:${negative_percent}px;`"> </span>
                            <label for="">سلبية</label>
                        </div>
                    </v-col>
                    <v-col cols="4"  class="outer-d c-pointer" @click="openRequests('without')">
                        <div class="inner-c-d">
                          {{without_percent}}%
                            <span class="chart-vertical" :style="`background-color: yellow; height:${without_percent}px;`"> </span>
                            <label for="">بدون </label>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-sheet>
        </v-col>
        <v-col cols="2">
            <v-sheet class="c-block-1 card-v2">
            <div class="inner-c c-pointer"  style="width: 90%;"  @click="openRecieved">
                <h4>
                    الطلبات المستلمة
                </h4>
                <br/>
                <div>
                    <div style="position: relative; text-align: center;">
                        <svg width="162" height="71" viewBox="0 0 162 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M80.7586 5.76167e-06C98.2473 -0.0063762 115.327 5.28916 129.745 15.188C144.162 25.0869 155.24 39.1239 161.517 55.4473L121.138 70.953C117.993 62.7965 112.453 55.7836 105.245 50.8375C98.0369 45.8914 89.5003 43.2441 80.7586 43.2441C72.017 43.2441 63.4803 45.8914 56.2726 50.8375C49.0648 55.7836 43.5239 62.7965 40.3793 70.953L0 55.4473C6.27696 39.1239 17.355 25.0869 31.7726 15.188C46.1902 5.28916 63.27 -0.0063762 80.7586 5.76167e-06Z" :fill="recieved_color"/>
                        </svg>
                        <span style="position: absolute; left: 75px; color: #fff;">{{recieved_percent}}%</span>
                    </div>
                    <span style="transform: rotate(12deg); display: block; margin-top: -20px;">
                        <svg width="18" height="33" viewBox="0 0 18 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.35934 31.7681C2.15974 31.2166 1.22789 30.2118 0.768208 28.9741C0.308527 27.7364 0.358562 26.3669 0.907335 25.1661L17.1016 0.152344L9.95757 29.339C9.40339 30.5349 8.39755 31.4622 7.16063 31.9176C5.92371 32.373 4.55668 32.3192 3.35934 31.7681Z" fill="#3F3E40"/>
                        </svg>

                    </span>
                </div>
            </div>
        </v-sheet>
        </v-col>
        <v-col cols="2">
            <v-sheet class="c-block-1 card-v2">
            <div class="inner-c c-pointer" style="padding-top: 2rem;" @click="openWhatsappMsg">
                <h4>
                    رسائل الواتساب 
                </h4>
                <div
                    class=""
                    :style="'display:flex'"
                    v-if="1"
                    >
                    <v-progress-linear
                               v-model="whatsapp_msg_percent"
                               :color="'green'"
                               height="25"
                               :rounded="true"
                               :reverse="true"
                               style="pointer-events: none; text-align: right;"
                               >
                               <template v-slot:default="{ value }">
                                   <strong>{{ value }}%</strong>
                               </template>
                               </v-progress-linear>
                </div>
                <p style=" direction:ltr;">
                                الردود / اجمالي الرسائل
                            </p>
                            <p style=" direction:ltr;">
                                ( {{whatsapp_msg_count}} / {{whatsapp_msg_s}})
                            </p>
            </div>
        </v-sheet>
        </v-col>
    </v-row>
    <!-- <RateSection /> -->
    <!-- <RecievedSection /> -->
    <!-- <PositiveSection /> -->
 
  </div>
</template>

<script>

import { axiosInstance } from "@/axios/config";
// import RateSection from './Percentage/RateSection.vue'
// import RecievedSection from './Percentage/RecievedSection.vue'
// import PositiveSection from './Percentage/PositiveSection.vue'
import Axios from "axios";
import router from "@/routes";

export default {
  components: {
    // RateSection,
    // RecievedSection,
    // PositiveSection,
  },
  data() {
    return {
        reviews_count: null,
        reviews_percent: null,
        whatsapp_msg_s: null,
        whatsapp_msg_count: null,
        whatsapp_msg_percent: null,
        positive_count: null,
        positive_percent: null,
        negative_count: null,
        negative_percent: null,
        without_count: null,
        without_percent: null,
        recieved_count: null,
        recieved_percent: null,
        recieved_color: null,
        // 
        day_date: '',
        agents: [],
        star_1: 0,
        star_2: 0,
        star_3: 0,
        star_4: 0,
        star_5: 0,
        star_1_c: 0,
        star_2_c: 0,
        star_3_c: 0,
        star_4_c: 0,
        star_5_c: 0,
        star_t_c: 0,
        star_avg: 0,
        valueDeterminate: 50,
      items: [
        {
          label: "اسبوع",
          value: 7 
        },
        {
          label: "اسبوعين",
          value: 14 
        },
        {
          label: "شهر",
          value: 30 
        },
      ],
    };
  },

  methods: {
    openReviews(){
      // this.$store.state.loadingData = true;
      router.push({
        name: "admin-rates-page",
        // params: { id: props.item.id },
      });
    },
    openWhatsappMsg(){
      // this.$store.state.loadingData = true;
      router.push({
        name: "admin-whatsapp-msg-page",
        // params: { id: props.item.id },
      });
    },
    openRecieved(){
      // this.$store.state.loadingData = true;
      router.push({
        name: "admin-recieved-page",
        // params: { id: props.item.id },
      });
    },
    openRequests(type){
      // this.$store.state.loadingData = true;
      router.push({
        name: "admin-requests-statistics-page",
        params: { type: type },
      });
    },
    average_rate_stars(index) {
      if (index <= this.reviews_percent) {
        return "mdi-star";
      } else if (Math.round(this.reviews_percent) == index) {
        return "mdi-star-half-full";
      } else {
        return "mdi-star-outline";
      }
    },
    average_whatapp_msg_stars(index) {
      if (index <= this.whatsapp_msg_percent) {
        return "mdi-star";
      } else if (Math.round(this.whatsapp_msg_percent) == index) {
        return "mdi-star-half-full";
      } else {
        return "mdi-star-outline";
      }
    },
    reloadData(){
      axiosInstance
    .get(`/employee/admin/agent-percentage-report?relaod&filter_date=${this.day_date}`)
    .then((res) => {
      this.star_1 = res.data.payload.star_1;
      this.star_2 = res.data.payload.star_2;
      this.star_3 = res.data.payload.star_3;
      this.star_4 = res.data.payload.star_4;
      this.star_5 = res.data.payload.star_5;
      this.star_1_c = res.data.payload.star_1_c;
      this.star_2_c = res.data.payload.star_2_c;
      this.star_3_c = res.data.payload.star_3_c;
      this.star_4_c = res.data.payload.star_4_c;
      this.star_5_c = res.data.payload.star_5_c;
      this.star_t_c = this.star_1_c + this.star_2_c +this.star_3_c + this.star_4_c + this.star_5_c;
      this.star_adv = res.data.payload.star_adv;
    })
    .catch((err) => {
      console.log("eror")
    });
    },

    getReviews(){
      axiosInstance
      .get(`/employee/admin/reviews-statistics`, {})
      .then((res) => {
          this.reviews_count = res.data.payload.star_avg_c;
          let percent = res.data.payload.star_avg;
          percent = (percent / 20);
          this.reviews_percent = percent.toFixed(2);
      })
      .catch((err) => {
        console.log("eror")
      });
    },
    getWhatappMSG(){
      axiosInstance
      .get(`/employee/admin/whatsapp-msg-statistics`, {})
      .then((res) => {
          this.whatsapp_msg_count = res.data.payload.star_avg_c;
          this.whatsapp_msg_s = res.data.payload.star_avg_s;
          let percent = res.data.payload.star_avg;
          // percent = (percent / 20);
          this.whatsapp_msg_percent = percent.toFixed(2);
      })
      .catch((err) => {
        console.log("eror")
      });
    },
    getRequests(){
      axiosInstance
      .get(`/employee/admin/requests-statistics`, {})
      .then((res) => {
          this.positive_count = res.data.payload.positive.count;
          let percent = res.data.payload.positive.percent;
          // percent = (percent / 20);
          this.positive_percent = percent.toFixed(2);

          this.negative_count = res.data.payload.negative.count;
           percent = res.data.payload.negative.percent;
          // percent = (percent / 20);
          this.negative_percent = percent.toFixed(2);

          this.without_count = res.data.payload.none.count;
           percent = res.data.payload.none.percent;
          // percent = (percent / 20);
          this.without_percent = percent.toFixed(2);
      })
      .catch((err) => {
        console.log("eror")
      });
    },
    getRecieved(){
      axiosInstance
      .get(`/employee/admin/recieved-statistics`, {})
      .then((res) => {
          this.recieved_color = res.data.payload.color;
          this.recived_count = res.data.payload.percentage;
          let percent = res.data.payload.percentage;
          // percent = (percent / 20);
          this.recieved_percent = percent.toFixed(2);
      })
      .catch((err) => {
        console.log("eror")
      });
    },
  },

  created() {
    this.getReviews();
    this.getRequests();
    this.getRecieved();
    this.getWhatappMSG();
    // axiosInstance
    // .get(`/employee/admin/agent-percentage-report`, {})
    // .then((res) => {
    //   this.agents = res.data.payload.agents;
    //   this.star_1 = res.data.payload.star_1;
    //   this.star_2 = res.data.payload.star_2;
    //   this.star_3 = res.data.payload.star_3;
    //   this.star_4 = res.data.payload.star_4;
    //   this.star_5 = res.data.payload.star_5;
    //   this.star_1_c = res.data.payload.star_1_c;
    //   this.star_2_c = res.data.payload.star_2_c;
    //   this.star_3_c = res.data.payload.star_3_c;
    //   this.star_4_c = res.data.payload.star_4_c;
    //   this.star_5_c = res.data.payload.star_5_c;
    //   this.star_t_c = this.star_1_c + this.star_2_c +this.star_3_c + this.star_4_c + this.star_5_c;
    //   this.star_adv = res.data.payload.star_adv;
    // })
    // .catch((err) => {
    //   console.log("eror")
    // });
  },
};
</script>
<style>
h4.sub-title{
  background-color: #d9d1da;
    padding: 11px 11px;
    margin: 11px 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid;
    color: #451646;
}
.c-block-1 .rate-1 {
    display: flex;
    justify-content: space-between;
}
.c-block-1 h5 {
  color: #7e1280;
    font-size: 15px;
    margin-bottom: 5px;
}
.c-block-1 {
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #d7d7d7;
}
.svg-cont{
  margin: auto;
  display: table;
}
.c-block-2 h5{
  margin: auto;
  margin-bottom: 21px;
    display: table;
}
.card-v2{
    padding: 10px !important;
    border-radius: 10px !important;
    border: 1px solid gray !important;
    display: flex;
    justify-content: center;
    text-align: center;
    /* min-height: 12rem; */
}
.card-v2 h4{
    margin: 10px 0;
}
.card-v2 span{
    direction: ltr;
}
.card-v2 p{
    direction: ltr;
}
.card-v2 .inner-c{
    min-height: 12rem;
}
.card-v2 .inner-c .chart-vertical{
    width: 100%;
    height:100px;
    background-color: green;
    display: block;
    width: 3rem;
    color: #fff;
}
.h6-5rem{
    height: 8.5rem;
}
.h6-5rem .outer-d {
    height: 100%;
    position: relative;
}
.h6-5rem .outer-d .inner-c-d{
    position: absolute;
    bottom: 0;
}
.back-txt{
  text-align: left;
  padding-left: 4rem;
}
.sales-managers-ch label{
  text-align: right;
}
.back-txt, .c-pointer{
  cursor: pointer;
}
</style>