<template>
  <div>
    <v-menu offset-y nudge-top="-20">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          height="40px"
          width="40px"
          style="min-width: unset; left: 10px; border-radius: var(--8, 8px)"
          class="ml-5 mr-3 p-0 genaralIcons"
          color="#3f0e40"
        >
          <v-icon color="white" size="40">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          style="border-bottom: 2px solid #ededed"
          class="black--text font-weight-bold"
          @click="sendToQuality"
        >
          <v-list-item-icon class="ml-2">
            <SendToQualityIcon />
          </v-list-item-icon>
          <v-list-item-title>نقل الي الجوده</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
      @click="$store.state.freezingSearch = true"
      fab
      class="mx-2 pa-0 genaralIcons"
      small
      style="left: 120px; border-radius: var(--8, 8px)"
      :color="$vuetify.theme.themes.light.BtnBKColor"
      depressed
      width="40"
      height="40"
    >
      <v-icon color="white">mdi-magnify</v-icon>
    </v-btn>

    <v-menu offset-y nudge-top="-7">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          size="45px"
          v-bind="attrs"
          v-on="on"
          style="z-index: 5; left: 75px; top: -53px; position: absolute"
        >
          <svg
            width="512"
            height="512"
            viewBox="0 0 512 512"
            fill="#3f0e40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M474.557 37.4414V168.577H328.851V37.4414H474.557ZM255.999 37.4414V474.557H37.4414V37.4414H255.999ZM474.557 197.719V314.281H328.851V197.719H474.556H474.557ZM474.557 343.422V474.557H328.851V343.422H474.556H474.557Z"
              fill="#3f0e40"
            />
          </svg>
        </v-avatar>
      </template>
      <v-list>
        <v-list-item
          class="pl-12"
          @click="$store.state.showCards = true"
          style="border-bottom: 2px solid #ededed"
        >
          <v-list-item-icon class="ml-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#3F0E40"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="four-squares-icon 1">
                <g id="Group">
                  <path
                    id="Vector"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.61524 12.7831H10.4074C10.7402 12.7831 11.0124 13.1075 11.0124 13.5034V21.0877C11.0124 21.4842 10.7404 21.8083 10.4074 21.8083H2.61524C2.28272 21.8083 2.01025 21.4842 2.01025 21.0877V13.5034C2.01025 13.1075 2.28272 12.7831 2.61524 12.7831ZM13.5925 2H21.3848C21.7178 2 21.9898 2.32407 21.9898 2.72043V10.3048C21.9898 10.7011 21.7178 11.0249 21.3848 11.0249H13.5925C13.2596 11.0249 12.988 10.7011 12.988 10.3048V2.72043C12.988 2.32407 13.2596 2 13.5925 2ZM2.60449 2H10.3971C10.73 2 11.0021 2.32407 11.0021 2.72043V10.3048C11.0021 10.7011 10.7302 11.0249 10.3971 11.0249H2.60449C2.27197 11.0249 2 10.7011 2 10.3048V2.72043C2 2.32407 2.27197 2 2.60449 2ZM13.6027 12.9753H21.3951C21.7276 12.9753 22 13.2994 22 13.6959V21.2803C22 21.6761 21.7276 22.0002 21.3951 22.0002H13.6027C13.2699 22.0002 12.9977 21.6761 12.9977 21.2803V13.6959C12.9977 13.2994 13.2699 12.9753 13.6027 12.9753Z"
                    fill="#3F0E40"
                  />
                </g>
              </g>
            </svg>
          </v-list-item-icon>
          <v-list-item-title style="line-height: 2"> عرض الكروت</v-list-item-title>
        </v-list-item>

        <v-list-item
          class="pl-12"
          @click="$store.state.showCards = false"
          style="border-bottom: 2px solid #ededed"
        >
          <v-list-item-icon class="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27.348"
              height="22.783"
              viewBox="0 0 24.348 19.783"
              fill="#3F0E40"
            >
              <path
                id="Icon_awesome-list-ul"
                data-name="Icon awesome-list-ul"
                d="M22.065,3.375a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,3.375Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,10.984Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283,2.283,2.283,0,0,1,2.283-2.283Zm-21.3.761H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,21.636V20.114A.761.761,0,0,1,.761,19.353Zm0-15.217H15.978a.761.761,0,0,1,.761.761V6.418a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,6.418V4.9A.761.761,0,0,1,.761,4.136Zm0,7.609H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,14.027V12.505A.761.761,0,0,1,.761,11.745Z"
                transform="translate(0 -3.375)"
                fill="#3F0E40"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-title style="line-height: 2">عرض الجدول</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { SendToQualityIcon } from "@/assets/adminIcons/sendToQualityIcon";

export default {
  name: "HeaderIcon",
  components: { SendToQualityIcon },
  props: ["selectedItems"],
  methods: {
    sendToQuality() {
      if (this.selectedItems.length > 0 || this.$store.state.startElement.length > 0) {
        this.$store.state.dialogQuality = true;
      }
    },

    handleTableDesign() {
      this.$store.state.showCards = false;
    },

    toggleTableStyle(e) {
      document.querySelectorAll(".table-style").forEach((btn) => {
        btn.querySelector("svg").classList.remove("active-table-style");
      });
      e.target.querySelector("svg").classList.add("active-table-style");
    },
  },
};
</script>

<style lang="scss">
.genaralIcons {
  position: absolute;
  top: -50px;
  min-width: unset;
  z-index: 5;
}

@media screen and (max-width: 626px) {
  .genaralIcons {
    top: -10px;
  }
}

.table-style svg {
  opacity: 0.4;

  &.active-table-style {
    opacity: 1;
  }
}
</style>
