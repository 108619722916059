
<template>
  <div style="margin-top: 40px">
    <highcharts :options="chartOptions" style="height: 800px"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts-vue";
import Axios from "axios";

export default {
  props: ["data" , "result", "headers"],
  data() {
    return {
      stacksTotal: [],
      showArray: [],
      originalEl: [],
      chartData: {
        responseNameArr: [],
        withoutRating: [],
        needToFollowUp: [],
        needToFollowUpPrec: [],
        waitingForPaper: [],
        waitingForPaperPrec: [],
        notWillingToServe: [],
        notWillingToServePrec: [],
        lookingForProperty: [],
        lookingForPropertyPrec: [],
        unacceptable: [],
        unacceptablePrec: [],
        unableToConnect: [],
        unableToConnectPrec: [],
        raised: [],
        raisedPrec: [],
        completed: [],
        completedPrec: [],
        didNotAnswar: [],
        didNotAnswarPrec: [],
        toCommunicate: [],
        toCommunicatePrec: [],
        withoutSalary: [],
        withoutSalaryPrec: [],
        notSuitable: [],
        notSuitablePrec: [],
        cancelbyClient: [],
        cancelbyClientPrec: [],
        personalLoan: [],
        personalLoanPrec: [],
        takeFromBank: [],
        takeFromBankPrec: [],
        testingClassification: [],
        testingClassificationPrec: [],
        lookAway: [],
        lookAwayPrec: [],
        companyOwner: [],
        companyOwnerPrec: [],
      },
      emptyArr: [],

      chartOptions: {
        chart: {
          type: "column",
        },
        title: {
          text: "تصنيفات الطلب - استشاري المبيعات",
          align: "right",
        },
        xAxis: {
          categories: [],
        },
        yAxis: {
          min: 0,
        },
        tooltip: {
          formatter: function () {
            var string = '';
            string = this.x +' - ' + this.series.name + ' : <b>' + this.y+'</b> (<b>'+this.series.userOptions.precentage+'</b>)';
            return string;
        },},
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
            },
          },
        },

        series: [],
      },
    };
  },

  components: {
    highcharts: Chart,
  },

  watch: {
    data(newValue) {
      this.setChartData();
    },

    result(val) {
      this.filterByResult(val);
    },

    headers(val){
      this.setChartData()
    }
  },

  computed: {
    returnChartData() {
      return this.chartData;
    },
  },

  methods: {
    filterByResult(val) {
      console.log(this.chartOptions.tooltip.formatter)
      if (val === "اعداد فقط") {
        this.chartOptions.tooltip.formatter = function () {
            var string = '';
            string = this.x +' - ' + this.series.name + ' : <b>' + this.y+'</b>';
            return string;
        }
      } else if (val === "نسب فقط") {
        this.chartOptions.tooltip.formatter = function () {
            var string = '';
            string = this.x +' - ' + this.series.name + ' : (<b>'+this.series.userOptions.precentage+'</b>)';
            return string;
        }
      } else if (val === "الكل") {
        this.chartOptions.tooltip.formatter = function () {
            var string = '';
            string = this.x +' - ' + this.series.name + ' : <b>' + this.y+'</b> (<b>'+this.series.userOptions.precentage+'</b>)';
            return string;
        }
      }
    },
    setChartData() {
      Object.keys(this.chartData).forEach((key, index) => {
        this.chartData[key] = [];
      });

      this.data.map((res) => {
        this.chartData.responseNameArr.push(res.name);
        this.chartData.withoutRating.push(res["bdon-tsnyf"]);
        this.chartData.needToFollowUp.push(res["bhag-llmtabaa"]);
        this.chartData.needToFollowUpPrec.push(res["nsb-bhag-llmtabaa"]);
        this.chartData.waitingForPaper.push(res["bantthar-alaorak"]);
        this.chartData.waitingForPaperPrec.push(res["nsb-bantthar-alaorak"]);
        this.chartData.notWillingToServe.push(res["la-yrghb-fy-alkhdm"]);
        this.chartData.notWillingToServePrec.push(res["nsb-la-yrghb-fy-alkhdm"]);
        this.chartData.lookingForProperty.push(res["ybhth-aan-aakar"]);
        this.chartData.lookingForPropertyPrec.push(res["nsb-ybhth-aan-aakar"]);
        this.chartData.unacceptable.push(res["mrfod"]);
        this.chartData.unacceptablePrec.push(res["nsb-mrfod"]);
        this.chartData.unableToConnect.push(res["taathr-alatsal"]);
        this.chartData.unableToConnectPrec.push(res["nsb-taathr-alatsal"]);
        this.chartData.raised.push(res["mrfoaa"]);
        this.chartData.raisedPrec.push(res["nsb-mrfoaa"]);
        this.chartData.completed.push(res["mktml"]);
        this.chartData.completedPrec.push(res["nsb-mktml"]);
        this.chartData.didNotAnswar.push(res["lm-yrd"]);
        this.chartData.didNotAnswarPrec.push(res["nsb-lm-yrd"]);
        this.chartData.toCommunicate.push(res["agl-altoasl"]);
        this.chartData.toCommunicatePrec.push(res["nsb-agl-altoasl"]);
        this.chartData.withoutSalary.push(res["bdon-ratb-frd-aaml-hr"]);
        this.chartData.withoutSalaryPrec.push(res["nsb-bdon-ratb-frd-aaml-hr"]);
        this.chartData.notSuitable.push(res["lm-ynasbh-alaard"]);
        this.chartData.notSuitablePrec.push(res["nsb-lm-ynasbh-alaard"]);
        this.chartData.cancelbyClient.push(res["mlgh-mn-kbl-alaamyl"]);
        this.chartData.cancelbyClientPrec.push(res["nsb-mlgh-mn-kbl-alaamyl"]);
        this.chartData.personalLoan.push(res["krd-shkhsy"]);
        this.chartData.personalLoanPrec.push(res["nsb-krd-shkhsy"]);
        this.chartData.takeFromBank.push(res["akhth-mn-albnk"]);
        this.chartData.takeFromBankPrec.push(res["nsb-akhth-mn-albnk"]);
        this.chartData.testingClassification.push(res["testing-classification"]);
        this.chartData.testingClassificationPrec.push(res["nsb-testing-classification"]);
        this.chartData.lookAway.push(res["srf-nthr"]);
        this.chartData.lookAwayPrec.push(res["nsb-srf-nthr"]);
        this.chartData.companyOwner.push(res["bdon-ratb-sahb-shrk-ao-moss"]);
        this.chartData.companyOwnerPrec.push(res["nsb-bdon-ratb-sahb-shrk-ao-moss"]);

        this.chartOptions["xAxis"]["categories"] =
          this.chartData.responseNameArr;
      });

      this.chartOptions["series"] = []
      this.headers.map((res, index) => {
        if (res.value === "bdon-tsnyf") {
          this.chartOptions["series"].push({
            name: res.text,
            data: this.chartData.withoutRating,
            precentage: 0,
          });
        }else if (res.value === "bhag-llmtabaa") {
          this.chartOptions["series"].push({
            name: res.text,
            data: this.chartData.needToFollowUp,
            precentage: this.chartData.needToFollowUpPrec[0],
          });
        }else if (res.value === "bantthar-alaorak") {
          this.chartOptions["series"].push({
            name: res.text,
            data: this.chartData.waitingForPaper,
            precentage: this.chartData.waitingForPaperPrec[0],
          });
        }else if (res.value === "la-yrghb-fy-alkhdm") {
          this.chartOptions["series"].push({
            name: res.text,
            data: this.chartData.notWillingToServe,
            precentage: this.chartData.notWillingToServePrec[0],
          });
        }else if (res.value === "ybhth-aan-aakar") {
          this.chartOptions["series"].push({
            name:res.text,
            data: this.chartData.lookingForProperty,
            precentage: this.chartData.lookingForPropertyPrec[0],
          });
        }else if (res.value === "mrfod") {
          this.chartOptions["series"].push({
            name: res.text,
            data: this.chartData.unacceptable,
            precentage: this.chartData.unacceptablePrec[0],
          });
        }else if (res.value === "taathr-alatsal") {
          this.chartOptions["series"].push({
            name:res.text,
            data: this.chartData.unableToConnect,
            precentage: this.chartData.unableToConnectPrec[0],
          });
        }else if (res.value === "mrfoaa") {
          this.chartOptions["series"].push({
            name: res.text,
            data: this.chartData.raised,
            precentage: this.chartData.raisedPrec[0],
          });
        }else if (res.value === "mktml") {
          this.chartOptions["series"].push({
            name: res.text,
            data: this.chartData.completed,
            precentage: this.chartData.completedPrec[0],
          });
        }else if (res.value === "lm-yrd") {
          this.chartOptions["series"].push({
            name: res.text,
            data: this.chartData.didNotAnswar,
            precentage: this.chartData.didNotAnswarPrec[0],
          });
        }else if (res.value === "agl-altoasl") {
          this.chartOptions["series"].push({
            name: res.text,
            data: this.chartData.toCommunicate,
            precentage: this.chartData.toCommunicatePrec[0],
          });
        }else if (res.value === "bdon-ratb-frd-aaml-hr") {
          this.chartOptions["series"].push({
            name: res.text,
            data: this.chartData.withoutSalary,
            precentage: this.chartData.withoutSalaryPrec[0],
          });
        }else if (res.value === "lm-ynasbh-alaard") {
          this.chartOptions["series"].push({
            name: res.text,
            data: this.chartData.notSuitable,
            precentage: this.chartData.notSuitablePrec[0],
          });
        }else if (res.value === "mlgh-mn-kbl-alaamyl") {
          this.chartOptions["series"].push({
            name: res.text,
            data: this.chartData.cancelbyClient,
            precentage: this.chartData.cancelbyClientPrec[0],
          });
        }else if (res.value === "krd-shkhsy") {
          this.chartOptions["series"].push({
            name: res.text,
            data: this.chartData.personalLoan,
            precentage: this.chartData.personalLoanPrec[0],
          });
        }else if (res.value === "akhth-mn-albnk") {
          this.chartOptions["series"].push({
            name: res.text,
            data: this.chartData.takeFromBank,
            precentage: this.chartData.takeFromBankPrec[0],
          });
        }else if (res.value === "testing-classification") {
          this.chartOptions["series"].push({
            name: res.text,
            data: this.chartData.testingClassification,
            precentage: this.chartData.testingClassificationPrec[0],
          });
        }else if (res.value === "srf-nthr") {
          this.chartOptions["series"].push({
            name: res.text,
            data: this.chartData.lookAway,
            precentage: this.chartData.lookAwayPrec[0],
          });
        }else if (res.value === "bdon-ratb-sahb-shrk-ao-moss") {
          this.chartOptions["series"].push({
            name: res.text,
            data: this.chartData.companyOwner,
            precentage: this.chartData.companyOwnerPrec[0],
          });
        }

      });

    },
  },

  created() {
    this.chartOptions["series"] = [];
    this.setChartData();
  },
};
</script>
