<template>
  <v-container>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h6">دمج الطلبات</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="10">
                <v-text-field
                  label="رقم الهاتف"
                  v-model="phoneNumber"
                  :rules="[saudiPhoneNumberRule]"
                  required
                  outlined
                ></v-text-field>
              </v-col>
              <v-btn
                color="success"
                style="margin-top: 12px; font-weight: bold; height: 52px"
                @click="ifTrue"
              >
                تحقق
              </v-btn>
            </v-row>
          </v-form>
          <div
            v-if="openAll"
            style="
              background: #f8d7da;
              color: red;
              padding: 5px;
              margin-bottom: 13px;
              border-radius: 4px;
            "
          >
            لا يمكن الرجوع فى هذه الخطوة فى حالة دمج الطلبين سيتم إضافة جوال الطلب للطلب
            الحالي
          </div>
          <table
            v-if="openAll"
            border="1px"
            style="
              text-align: center;
              color: #6e9cbf;
              height: 400px;
              font-weight: bold;
              width: 100%;
            "
          >
            <tr>
              <td>رقم الطلب</td>
              <td>{{ userData.id }}</td>
            </tr>
            <tr>
              <td>اسم الاستشاري</td>
              <td>{{ userData.user.name }}</td>
            </tr>
            <tr>
              <td>إسم العميل</td>
              <td>{{ userData.customer.name }}</td>
            </tr>
            <tr>
              <td>رقم الجوال</td>
              <td>{{ userData.customer.mobile }}</td>
            </tr>
            <tr>
              <td>حالة الطلب</td>
              <td>{{ userData.status }}</td>
            </tr>
            <tr>
              <td>تصنيف الطلب</td>
              <td>{{ userData.class_id_agent_text || "null" }}</td>
            </tr>
            <tr>
              <td>مصدر المعاملة</td>
              <td>{{ userData.source_text }}</td>
            </tr>
            <tr>
              <td>عرض الطلب</td>
              <td>
                <v-btn @click="handleOpenRequest" color="success">عرض</v-btn>
              </td>
            </tr>
          </table>
        </v-card-text>
        <div style="padding-right: 25px" v-if="openAll">
          <p>من فضلك أختار الإستشاري</p>
          <div style="display: flex; justify-content: space-between; width: 73%">
            <div>
              <input
                type="radio"
                id="css"
                name="fav_language"
                value="CSS"
                :checked="current"
                @change="handleCurrentOwner"
              />
              <label for="css" style="margin-right: 4px">{{ this.customerName }}</label
              ><br />
            </div>
            <div>
              <input
                type="radio"
                id="javascript"
                name="fav_language"
                value="JavaScript"
                :checked="incoming"
                @change="handleIncomingOwner"
              />
              <label for="javascript" style="margin-right: 4px">{{
                userData.user.name
              }}</label>
            </div>
          </div>
          <div
            style="
              display: flex;
              font-size: 13px;
              justify-content: space-between;
              width: 90%;
            "
          >
            <div>الحفاظ على هذا الطلب ودمج الآخر معه</div>
            <div>الحفاظ على هذا الطلب ودمج الآخر معه</div>
          </div>
        </div>
        <v-card-actions v-if="openAll">
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="onConfirm">تحويل</v-btn>
          <v-btn color="red darken-1" text @click="dialog = false">الفاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="dialogTwo"
      :color="color"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <span class="font-weight-bold" style="font-size: 16px">{{ message }}</span>
      <template v-slot:action>
        <v-btn icon small @click="$store.state.activeSnack = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { axiosInstance } from "@/axios/config";

export default {
  name: "MargeUserDialog",
  data() {
    return {
      dialog: this.$store.state.openDialogMargeUsers,
      valid: false,
      openAll: false,
      userData: [],
      phoneNumber: "",
      dialogTwo: false,
      message: "",
      color: null,
      margedId: null,
      current: true,
      incoming: false,

      saudiPhoneNumberRule: (value) => {
        const pattern = /^(?:\+966|5)[0-9]{8}$/;
        this.valid = pattern.test(value);
        return pattern.test(value) || "رقم الهاتف غير صالح";
      },
    };
  },

  methods: {
    handleCurrentOwner() {
      this.current = !this.current;
    },

    handleIncomingOwner() {
      this.incoming = !this.incoming;
    },

    handleOpenRequest() {
      this.$router.push({
        name: "request_details",
        params: { id: this.margedId },
      });
    },

    onConfirm() {
      if (this.$refs.form.validate()) {
        let body = {
          current_req_id: Number(this.$route.params.id),
          incoming_req_id: this.userData.id,
        };

        if (this.current) {
          body.type = "current";
        }

        if (this.incoming) {
          body.type = "incoming";
        }
        axiosInstance
          .post("/v2/employee/Requests/mergeTwoRequests", body)
          .then((res) => {
            this.dialogTwo = true;
            this.message = res.data.message;
            this.color = "green";
          })
          .catch((err) => {
            this.dialogTwo = true;
            let error = { err };
            this.message = error.err.response.data.message;
            this.color = "red";
          });
        this.dialog = false;
      }
    },

    getUserName() {
      let id = this.$route.params.id;
      axiosInstance
        .get(`/v2/employee/Requests/${id}`)
        .then((res) => {
          this.customerName = res.data.payload.customer.name;
        })
        .catch((err) => {
          console.log("err");
        });
    },

    ifTrue() {
      if (this.valid) {
        let body = { mobile_number: this.phoneNumber };
        axiosInstance
          .post("/v2/employee/Requests/getRequestToMerge", body)
          .then((res) => {
            this.margedId = res.data.payload.id;
            this.userData = res.data.payload;
            this.$refs.form.validate();
            this.openAll = true;
          })
          .catch((err) => {
            let error = { err };
            this.saudiPhoneNumberRule = error.err.response.data.message;
          });
      }
    },
  },
  created() {
    this.getUserName();
  },
};
</script>
