<template>
  <v-row justify="center">
    <v-dialog v-model="$store.state.openCallsDialog" width="600">
      <v-card>
        <v-card-title>
          <div>المكالمات الخاصة بالطلب</div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div v-if="calls_details.length > 0">
              <v-row
                style="
                  background-color: #f8f9fa;
                  border: 1px solid #eff2f7;
                  margin-top: 20px;
                "
                class="mx-0 file-info-row d-flex align-center text-center"
              >
                <v-progress-linear color="primary" reverse></v-progress-linear>
                <v-col cols="3"> الاستشاري</v-col>
                <v-col cols="5"> المكالمه</v-col>
                <v-col cols="4">تاريخ المكالمه</v-col>
              </v-row>

              <v-row
                v-for="item in calls_details"
                :key="item.id"
                class="mx-0 py-4 file-info-row d-flex align-center text-center"
              >
                <v-col cols="3">{{ item.agent_name }}</v-col>
                <v-col cols="5"
                  ><audio
                    controls
                    :src="item.path"
                    style="width: 100%; max-width: 200px"
                  ></audio
                ></v-col>
                <v-col cols="4">{{ item.day_date }}</v-col>
              </v-row>
            </div>

            <div v-else>
              <h2 class="mx-0 py-4 file-info-row d-flex align-center text-center">
                عفوا لا يوجد مكالمات لهذا الطلب !
              </h2>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="closeRequestCalls">
            الغاء
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { axiosInstance } from "@/axios/config";

export default {
  data() {
    return {
      calls_details: [],
    };
  },

  methods: {
    get_calls_for_request() {
      if (this.$store.state.idOfRequestCalls) {
        axiosInstance
          .get(`/agent/Request/call-center/${this.$store.state.idOfRequestCalls}`)
          .then((response) => {
            this.calls_details = response.data.payload;
          })
          .catch((err) => {
            this.showPopup("error", err.response.data.message);
          });
      }
    },

    closeRequestCalls() {
      this.$store.state.idOfRequestCalls = null;
      this.$store.state.openCallsDialog = false;
    },
  },

  created() {
    if (this.$store.state.idOfRequestCalls != null) {
      this.get_calls_for_request();
    } else {
      console.log("There is an Erorr !");
    }
  },

  watch: {
    "$store.state.idOfRequestCalls": {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.get_calls_for_request();
        }
      },
    },
  },
};
</script>
