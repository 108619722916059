// decimalOnly.js
const decimalOnlyDirective = {
    // The directive's bind hook is called only once when the directive is first bound to an element.
    bind(el) {
      el.handler = (event) => {
        // Remove any non-decimal characters from the input value
        // const newValue = event.target.value.replace(/\D/g, '');
        const newValue = event.target.value.replace(/([^0-9_.])/g, '');
        // Update the input value with the filtered value
        event.target.value = newValue;
        // Emit an input event to make sure the v-model is updated
        // event.target.dispatchEvent(new Event('input'));
      };
  
      // Attach the event listener for the input event
      el.addEventListener('input', el.handler);
    },
  
    // The directive's unbind hook is called when the directive is unbound from the element.
    unbind(el) {
      // Remove the event listener when the directive is unbound to prevent memory leaks.
      el.removeEventListener('input', el.handler);
    },
  };
  
  export default decimalOnlyDirective;
  