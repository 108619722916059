<template>
    <v-row justify="center">
      <v-dialog
        v-model="this.$store.state.DosentWantOurProductDialog"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title class="text-h5">
            تاكيد العملية!
          </v-card-title>
            <v-card-text style="font-weight: bold;">
              هل العميل يبحث عن شيء غير متعلق بالتمويل العقاري ؟
            </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            
                color="red"
                depressed
                elevation="3"
                outlined
                small
              @click="$store.state.DosentWantOurProductDialog = false"
            >
              الفاء
            </v-btn>
            <v-btn
                depressed
                elevation="3"
                small
              color="primary"
              @click="send_req_to_admin"
            >
              نعم
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>

<script>
import Axios from "axios";
// import store from '../../store'

export default {
  name: 'DosentWantOurProductDialog',

  data(){
    return{
    }
  },

  methods: {
    
    send_req_to_admin() {
     console.log("dw");
      let req_id = this.$route.params.id;
      this.$emit('sendRequstToAdmin',req_id)
      this.$store.state.DosentWantOurProductDialog = false;
    },
  },

}
</script>