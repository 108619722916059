<template>
  <div class="all-requests">
    <HeaderProcess @filterHelpDesk="searchInHelpDesks" :items="supportRequests" :headers="headers"/>
    <div class="support_container">
      <div class="text-center right_bouttons">
        <v-btn
          class="ma-2 white--text"
          :style="{
            'background-color': gitAllSupports ? '#3F0E40' : '#D2C8D4',
          }"
          @click="getAllResult()"
          :disabled="getButtonsStatus"
        >
          <span :style="{ color: gitAllSupports ? 'white' : '#3F0E40' }">
            الكل
          </span>
        </v-btn>

        <v-btn
          class="ma-2 white--text"
          :style="{
            'background-color': gitCustomerSupport ? '#3F0E40' : '#D2C8D4',
          }"
          @click="getAllClients('clients')"
          :disabled="getButtonsStatus"
        >
          <span :style="{ color: gitCustomerSupport ? 'white' : '#3F0E40' }">
            العملاء
          </span>
        </v-btn>

        <v-btn
          class="ma-2"
          :style="{
            'background-color': gitEmployeeSupport ? '#3F0E40' : '#D2C8D4',
          }"
          @click="getAllClients('employee')"
          :disabled="getButtonsStatus"
        >
          <span :style="{ color: gitEmployeeSupport ? 'white' : '#3F0E40' }">
            الموظفين
          </span>
        </v-btn>
      </div>

      <div class="text-center left_bouttons">
        <v-btn
          class="ma-2 white--text"
          :style="{
            'background-color': getNewSupports ? '#3F0E40' : '#D2C8D4',
          }"
          @click="filterRequests('new')"
          :disabled="getButtonsStatus"
        >
          <span :style="{ color: getNewSupports ? 'white' : '#3F0E40' }">
            جديد
          </span>
        </v-btn>

        <v-btn
          class="ma-2 white--text"
          :style="{
            'background-color': toggleToOpendSupport ? '#3F0E40' : '#D2C8D4',
          }"
          @click="filterRequests('open')"
          :disabled="getButtonsStatus"
        >
          <span :style="{ color: toggleToOpendSupport ? 'white' : '#3F0E40' }">
            تم فتحه
          </span>
        </v-btn>

        <v-btn
          class="ma-2"
          :style="{
            'background-color': toggleToCompleteSupport ? '#3F0E40' : '#D2C8D4',
          }"
          @click="filterRequests('complete')"
          :disabled="getButtonsStatus"
        >
          <span
            :style="{ color: toggleToCompleteSupport ? 'white' : '#3F0E40' }"
          >
            مكتمل
          </span>
        </v-btn>

        <v-btn
          class="ma-2"
          :style="{
            'background-color': toggleToCancelSupports ? '#3F0E40' : '#D2C8D4',
          }"
          @click="filterRequests('canceled')"
          :disabled="getButtonsStatus"
        >
          <span
            :style="{ color: toggleToCancelSupports ? 'white' : '#3F0E40' }"
          >
            ملغي
          </span>
        </v-btn>
      </div>
    </div>
    <div v-if="!loadingData">
      <div v-if="supportRequests.length > 0">
        <div v-if="$store.state.showCards === true">
          <AdminCard
            :CardData="supportRequests"
            v-if="!loadingData"
            @pageCount="handlePageNumber"
          />
        </div>
        <div v-else>
          <table-component
            :showSelect="false"
            itemKey="id"
            :headers="headers"
            :pagination="this.$store.state.pagination"
            :limit="$store.state.counterOfShow"
            :items="this.$store.state.tableData"
            :loading="loading"
            @refresh="getTableViewData"
            v-model="page"
          >
            <template slot="item.discription" slot-scope="props">
              <span v-if="!props.item.discription"></span>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{
                    props.item.discription.length > 35
                      ? props.item.discription.substring(0, 35) + "..."
                      : props.item.discription
                  }}</span>
                </template>
                <div class="font-weight-bold">{{ props.item.discription }}</div>
              </v-tooltip>
            </template>

            <template slot="item.replay" slot-scope="props">
              <span v-if="!props.item.replay"></span>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{
                    props.item.replay.length > 35
                      ? props.item.replay.substring(0, 35) + "..."
                      : props.item.replay
                  }}</span>
                </template>
                <div class="font-weight-bold">{{ props.item.replay }}</div>
              </v-tooltip>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#40BE72"
                    small
                    depressed
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    @click="to_request_details(item)"
                    v-if="item['هل لديه طلب تمويل'] === 'نعم'"
                  >
                    <v-list-item-icon class="ml-2">
                      <img src="@/assets/icons/titcketIcon.svg" alt="image" />
                    </v-list-item-icon>
                    <v-list-item-title>فتح الطلب</v-list-item-title>
                  </v-list-item>

                  <router-link
                    v-if="item['هل لديه طلب تمويل'] === 'نعم'"
                    style="text-decoration: none"
                    target="_blank"
                    :to="{
                      name: 'request_details',
                      params: { id: item.request_id },
                    }"
                  >
                    <v-list-item>
                      <v-list-item-icon class="ml-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18.528"
                          height="15.111"
                          viewBox="0 0 13.528 10.111"
                        >
                          <g
                            id="Icon_feather-eye-dark"
                            data-name="Icon feather-eye-dark"
                            transform="translate(-1 -5.5)"
                          >
                            <path
                              id="Path_4040"
                              data-name="Path 4040"
                              d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                              fill="none"
                              stroke="#000"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1"
                            />
                            <path
                              id="Path_4041"
                              data-name="Path 4041"
                              d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                              transform="translate(-7.444 -4.653)"
                              fill="none"
                              stroke="#000"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="1"
                            />
                          </g>
                        </svg>
                      </v-list-item-icon>
                      <v-list-item-title> فتح في تاب جديد</v-list-item-title>
                    </v-list-item>
                  </router-link>

                  <v-list-item @click="to_supportRequest_details(item)">
                    <v-list-item-icon class="ml-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.599"
                        height="17.599"
                        viewBox="0 0 17.599 17.599"
                      >
                        <path
                          id="support"
                          d="M20.746,12.41v-.463a6.947,6.947,0,1,0-13.894,0v.463A1.852,1.852,0,0,0,5,14.262v2.779a1.852,1.852,0,0,0,1.852,1.852h.083a1.389,1.389,0,0,0,2.7-.463V12.873a1.389,1.389,0,0,0-1.852-1.311,6.021,6.021,0,0,1,12,0,1.389,1.389,0,0,0-1.815,1.311v5.557a1.389,1.389,0,0,0,1.389,1.389h.153a3.242,3.242,0,0,1-2.932,1.852H15.189v-.463A1.389,1.389,0,1,0,13.8,22.6h2.779a4.168,4.168,0,0,0,4.14-3.7h.028A1.852,1.852,0,0,0,22.6,17.041V14.262A1.852,1.852,0,0,0,20.746,12.41ZM5.926,17.041V14.262a.926.926,0,0,1,.926-.926v4.631A.926.926,0,0,1,5.926,17.041ZM8.242,12.41a.463.463,0,0,1,.463.463v5.557a.463.463,0,1,1-.926,0V12.873A.463.463,0,0,1,8.242,12.41ZM13.8,21.672a.463.463,0,1,1,.463-.463v.463Zm5.557-2.779a.463.463,0,0,1-.463-.463V12.873a.463.463,0,0,1,.926,0v5.557A.463.463,0,0,1,19.357,18.894Zm2.316-1.852a.926.926,0,0,1-.926.926V13.336a.926.926,0,0,1,.926.926Z"
                          transform="translate(-5 -5)"
                        />
                      </svg>
                    </v-list-item-icon>
                    <v-list-item-title>طلب الدعم الفني</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    @click="toOrderRequest(item)"
                    v-if="item['هل لديه طلب تمويل'] === 'نعم'"
                  >
                    <v-list-item-icon class="ml-2">
                      <OrderHistory />
                    </v-list-item-icon>
                    <v-list-item-title>سجل الطلب</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </table-component>
        </div>
        <div>
          <LoadingMore v-if="loadMoreData" style="margin: 40px" />
        </div>
      </div>
      <div class="noData" v-else>لا يوجد بيانات</div>
    </div>
    <div
      style="display: flex; align-items: center; justify-content: center"
      v-else
    >
      <LoadingMore />
    </div>

    <OrderRequestDialog />
  </div>
</template>

<script>
import { axiosInstance } from "@/axios/config";
import AdminCard from "@/components/Charts/AdminCard.vue";
import LoadingMore from "@/components/Charts/LoadingMore.vue";
import HeaderProcess from "./HeaderProcess.vue";
import { OrderHistory } from "@/assets/adminIcons/orderHistory";
import OrderRequestDialog from "@/components/AdminComponent/OrderRequestDialog.vue";

export default {
  name: "AdminSupport",
  components: {
    AdminCard,
    LoadingMore,
    HeaderProcess,
    OrderHistory,
    OrderRequestDialog,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      supportRequests: [],
      copySupportRequests: [],
      allAgents: [],
      selectedStatusFilter: "",
      gitAllSupports: false,
      gitCustomerSupport: false,
      gitEmployeeSupport: false,
      getNewSupports: false,
      toggleToOpendSupport: false,
      toggleToCompleteSupport: false,
      toggleToCancelSupports: false,
      loadingData: false,
      allNames: [],
      body: new FormData(),
      loadMoreData: false,
      isButtonsDisapled: false,
      page: 1,
      headers: [
        { text: "الرقم", value: "id" },
        { text: "تاريخ الطلب", value: "created_at" },
        { text: "الإسم", value: "name" },
        { text: "الجوال", value: "mobile" },
        { text: "الوصف", value: "descrebtion" },
        { text: "الرد", value: "replay" },
        { text: "حالة الطلب", value: "status" },
        { text: "هل لديه طلب تمويل", value: "has_request" },
        { text: "العمليات", value: "action" },
      ],
    };
  },

  computed: {
    getButtonsStatus() {
      return this.isButtonsDisapled;
    },
  },
  watch: {
    page(val) {
      this.getTableViewData(val);
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval !== oldvalue && !this.$store.state.admin_search_active) {
          this.getTableViewData(this.page);
        }
      },
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },

  },
  methods: {
    to_supportRequest_details(item) {
      this.$router.push({
        name: "help_desk",
        params: { id: item.id },
      });
    },

    searchInHelpDesks(data){
      console.log(data);
      this.body = new FormData();
      if(data.name.length > 0){
        this.body.append("name",data.name);
      }
      if(data.mobile.length > 0){
        this.body.append("mobile",data.mobile);
      }
      if(data.descrebtion.length > 0){
        this.body.append("descrebtion",data.descrebtion);
      }
      this.supportRequests = [];
      this.copySupportRequests = [];
      this.$store.state.tableData = [];
      this.getAllRequests(1);
    },

    to_request_details(item) {
      console.log('item is '+item.request)
      axiosInstance
        .get(`/employee/collaborator/request-of-customer-helpdesk/${item.id}`, {
          headers: this.request_headers(),
        })
        .then((res) => {
          this.requestDetails = res.data.payload.id;

          this.$router.push({
            name: "request_details",
            params: { id: res.data.payload.id },
          });
        });
    },

    toOrderRequest(item) {
      this.$store.state.orderRequestId = item.request_id;
      if (this.$store.state.showCards || this.$store.state.showCards == false) {
        this.$store.state.orderRequestDialog = true;
      } else {
        this.$store.state.openOrderRequestSection = true;
      }
    },

    filterRequests(status) {
      this.toggleToOpendSupport = false;
      this.toggleToCompleteSupport = false;
      this.toggleToCancelSupports = false;
      this.getNewSupports = false;

      if (status === "new") {
        this.loadingData = true;
        this.supportRequests = [];
        this.copySupportRequests = [];
        this.$store.state.tableData = [];
        if (this.body.has("status_of_helpdesk")) {
          this.body.delete("status_of_helpdesk");
        }
        this.body.append("status_of_helpdesk", 0);
        this.getNewSupports = true;
        this.getAllRequests(1);
      }

      if (status === "open") {
        if (this.body.has("status_of_helpdesk")) {
          this.body.delete("status_of_helpdesk");
        }
        this.loadingData = true;
        this.supportRequests = [];
        this.copySupportRequests = [];
        this.$store.state.tableData = [];
        this.body.append("status_of_helpdesk", 1);
        this.toggleToOpendSupport = true;
        this.getAllRequests(1);
      }

      if (status === "complete") {
        if (this.body.has("status_of_helpdesk")) {
          this.body.delete("status_of_helpdesk");
        }
        this.loadingData = true;
        this.supportRequests = [];
        this.copySupportRequests = [];
        this.$store.state.tableData = [];
        this.body.append("status_of_helpdesk", 2);
        this.toggleToCompleteSupport = true;
        this.getAllRequests(1);
      }

      if (status === "canceled") {
        if (this.body.has("status_of_helpdesk")) {
          this.body.delete("status_of_helpdesk");
        }
        this.loadingData = true;
        this.supportRequests = [];
        this.copySupportRequests = [];
        this.$store.state.tableData = [];
        this.body.append("status_of_helpdesk", 3);
        this.toggleToCancelSupports = true;
        this.getAllRequests(1);
      }
    },
    handlePageNumber(val) {
      this.getAllRequests(val);
    },

    getAllResult() {
      this.loadingData = true;
      this.supportRequests = [];
      this.copySupportRequests = [];
      this.$store.state.tableData = [];
      this.body = new FormData();
      this.gitAllSupports = true;
      this.toggleToOpendSupport = false;
      this.toggleToCompleteSupport = false;
      this.toggleToCancelSupports = false;
      this.getNewSupports = false;
      this.gitCustomerSupport = false;
      this.gitEmployeeSupport = false;
      this.getAllRequests(1);
    },

    getAllClients(status) {
      this.gitEmployeeSupport = false;
      this.gitAllSupports = false;
      this.gitCustomerSupport = false;
      if (status === "clients") {
        if (this.body.has("type_of_helpdesk")) {
          this.body.delete("type_of_helpdesk");
        }
        this.body.append("type_of_helpdesk", 0);
        this.supportRequests = [];
        this.copySupportRequests = [];
        this.$store.state.tableData = [];
        this.gitCustomerSupport = true;
        this.getAllRequests(1);
      }

      if (status === "employee") {
        if (this.body.has("type_of_helpdesk")) {
          this.body.delete("type_of_helpdesk");
        }
        this.body.append("type_of_helpdesk", 1);
        this.gitEmployeeSupport = true;
        this.supportRequests = [];
        this.copySupportRequests = [];
        this.$store.state.tableData = [];
        this.getAllRequests(1);
      }
    },

    getTableViewData(count) {
      this.isButtonsDisapled = true;
      //   this.$store.state.tableData = []
      this.loading = true;
      axiosInstance
        .post(`/employee/admin/help-desk?page=${count}&per_page=${this.$store.state.counterOfShow}`, this.body, {
          headers: this.request_headers(),
        })
        .then((res) => {
          let response = res.data.payload.data.map((item) => {
            this.getTableData(item);
            return item;
          });
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_new_helpdesks,
          };
          this.loading = false;
        })
        .catch((err) => {
          this.loadingData = false;
          this.loadMoreData = false;
          this.isButtonsDisapled = false;
        });
    },

    getAllRequests(count) {
      if (
        this.supportRequests.length === 0 &&
        this.copySupportRequests.length === 0
      ) {
        this.loadingData = true;
      }

      this.loadMoreData = true;

      this.isButtonsDisapled = true;

      this.loading = true;
      axiosInstance
        .post(`/employee/admin/help-desk?page=${count}`, this.body, {
          headers: this.request_headers(),
        })
        .then((res) => {
          let response = res.data.payload.data.map((item) => {
            if (item.length === 0) {
              this.loading = false;
            }
            this.getTableData(item);
            item["starChecked"] = false;
            return item;
          });
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.total,
          };
          this.loading = false;

          this.supportRequests.push(...response);
          this.copySupportRequests.push(...response);
          this.copySupportRequests.map((res) => {
            this.allNames.push(res.name);
          });
        })
        .catch((err) => {
          this.loadingData = false;
          this.loadMoreData = false;
        })
        .finally(() => {
          
          this.isButtonsDisapled = false; // don't stop filter if no data...
          this.loadMoreData = false;
          this.loadingData = false;
          if (this.$store.state.showCards === true) {
            if (count >= 2) {
              this.isButtonsDisapled = false;
            }
          } else {
            this.isButtonsDisapled = false;
          }
        });
    },

    getTableData(items) {
      this.$store.state.tableData.push({
        id: items.id,
        الرقم: items.id,
        created_at: items.created_at,
        name: items.name,
        mobile: items?.mobile,
        descrebtion: items.descrebtion,
        replay: items.replay,
        status: this.returnStatusValue(items?.status),
        has_request: items.has_request === "Yes" ? "نعم" : "لا",
        request_id:items.request?.id
      });
    },
    returnStatusValue(status) {
      if (status == 0) {
        return "جديد";
      } else if (status == 1) {
        return "تم فتحه";
      } else if (status == 2) {
        return "مكتمل";
      } else {
        return "ملغي";
      }
    },
  },

  created() {
    this.gitAllSupports = true;
    this.$store.state.tableData = [];
    this.getAllRequests();
  },
};
</script>

<style>
.noData {
  display: flex;
  justify-content: center;
  font-size: 22px;
  color: #3f0e40;
  margin-top: 70px;
  font-weight: bold;
}

.support_container {
  display: flex;
  text-align: center;
  justify-content: space-between;
  margin-top: 45px;
  margin-bottom: 20px;
}

.right_bouttons .theme--light.v-btn.v-btn--has-bg,
.left_bouttons .theme--light.v-btn.v-btn--has-bg {
  margin-top: 7px;
  color: #6982ec;
  font-weight: bold;
  box-shadow: 0 0 0 0;
  border-radius: 4px;
  color: #8f8cec !important;
  border-radius: 4px;
  padding: 20px !important;
}
</style>
