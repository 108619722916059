<template>
  <div>
    <v-menu>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          height="30"
          color="transparent"
          depressed
          fab
          light
          v-bind="attrs"
          v-on="on"
          style="margin-left: -10px"
        >
          <img src="@/assets/icons/eyeIcon.svg" alt="image" />
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="convertRequest(item)">
          <v-list-item-icon class="ml-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14.513"
              height="16.448"
              viewBox="0 0 14.513 16.448"
            >
              <path
                id="Icon_material-subdirectory-arrow-right"
                data-name="Icon material-subdirectory-arrow-right"
                d="M20.513,16.643l-5.805,5.805-1.374-1.374,3.473-3.464H6V6H7.935v9.675h8.872l-3.473-3.464,1.374-1.374Z"
                transform="translate(-6 -6)"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-title>تحويل الطلب</v-list-item-title>
        </v-list-item>

        <!-- <v-list-item @click="sendRequestToAdmin">
          <v-list-item-icon class="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.121"
              height="17.121"
              viewBox="0 0 17.121 17.121"
            >
              <g
                id="Icon_feather-arrow-up-right"
                data-name="Icon feather-arrow-up-right"
                transform="translate(-9.439 -9.439)"
              >
                <path
                  id="Path_5860"
                  data-name="Path 5860"
                  d="M10.5,25.5l15-15"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Path_5861"
                  data-name="Path 5861"
                  d="M10.5,10.5h15v15"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
              </g>
            </svg>
          </v-list-item-icon>
          <v-list-item-title>ارسال للإدارة</v-list-item-title>
        </v-list-item> -->

        <router-link
          v-if="$store.state.request_info.personalRequest"
          style="text-decoration: none"
          target="_blank"
          :to="{ name: 'request_details', params: { id:$store.state.request_info.personalRequest?.id  } }"
        >
          <v-list-item>
            <v-list-item-icon class="ml-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.528"
                height="15.111"
                viewBox="0 0 13.528 10.111"
              >
                <g
                  id="Icon_feather-eye-dark"
                  data-name="Icon feather-eye-dark"
                  transform="translate(-1 -5.5)"
                >
                  <path
                    id="Path_4040"
                    data-name="Path 4040"
                    d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                  <path
                    id="Path_4041"
                    data-name="Path 4041"
                    d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                    transform="translate(-7.444 -4.653)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                  />
                </g>
              </svg>
            </v-list-item-icon>
            <v-list-item-title> فتح الطلب الشخصي</v-list-item-title>
          </v-list-item>
        </router-link>

        <v-list-item @click="sendToQuality(item)">
          <v-list-item-icon class="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20.227"
              height="20.227"
              viewBox="0 0 20.227 20.227"
            >
              <g
                id="Icon_feather-send"
                data-name="Icon feather-send"
                transform="translate(-2.5 -2.293)"
              >
                <path
                  id="Path_4004"
                  data-name="Path 4004"
                  d="M26.961,3,16.5,13.461"
                  transform="translate(-4.941)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
                <path
                  id="Path_4005"
                  data-name="Path 4005"
                  d="M22.02,3,15.363,22.02l-3.8-8.559L3,9.657Z"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
              </g>
            </svg>
          </v-list-item-icon>

          <v-list-item-title>ارسال الطلب للجوده</v-list-item-title>
        </v-list-item>
        <v-list-item @click="tickets(item)">
          <v-list-item-icon class="ml-2">
            <svg
              class="ml-2"
              xmlns="http://www.w3.org/2000/svg"
              width="23.194"
              height="23.212"
              viewBox="0 0 23.194 23.212"
            >
              <g id="ticket_menu" data-name="ticket menu" transform="translate(0)">
                <path
                  id="Path_2758"
                  data-name="Path 2758"
                  d="M93.931,77.839a.631.631,0,0,0-.8-.076,2.521,2.521,0,0,1-3.493-3.506.638.638,0,0,0-.076-.795l-.725-.725A2.525,2.525,0,0,0,87.051,72h0a2.525,2.525,0,0,0-1.785.738L72.92,85.123a2.525,2.525,0,0,0,.006,3.563l.486.479a.636.636,0,0,0,.845.044,2.5,2.5,0,0,1,3.525,3.519.631.631,0,0,0,.038.851l.9.9a2.527,2.527,0,0,0,3.569,0h0l12.36-12.36a2.527,2.527,0,0,0,0-3.569ZM81.382,93.579a1.264,1.264,0,0,1-1.785,0l-.517-.517A3.758,3.758,0,0,0,73.929,87.9l-.107-.107a1.264,1.264,0,0,1,0-1.785l8.406-8.437,7.58,7.58Zm12.36-12.36L90.69,84.271l-7.58-7.586,3.039-3.052a1.24,1.24,0,0,1,.889-.372h0a1.26,1.26,0,0,1,.889.366l.385.385a3.788,3.788,0,0,0,5.064,5.064l.359.359a1.258,1.258,0,0,1,.006,1.785Z"
                  transform="translate(-72.187 -72)"
                  :fill="$route.name == 'tickets' ? '#fff' : '#2c2c2c'"
                />
                <path
                  id="Path_2759"
                  data-name="Path 2759"
                  d="M171.445,217.177l-3.058-3.058a1.264,1.264,0,0,0-1.785,0h0l-2.983,2.983a1.264,1.264,0,0,0,0,1.785l3.058,3.058a1.264,1.264,0,0,0,1.785,0l2.983-2.983a1.249,1.249,0,0,0,.032-1.772C171.47,217.177,171.463,217.171,171.445,217.177Zm-3.872,3.872-3.065-3.058,2.983-2.983h0l3.058,3.058Z"
                  transform="translate(-157.508 -204.811)"
                  :fill="$route.name == 'tickets' ? '#fff' : '#2c2c2c'"
                />
              </g>
            </svg>
          </v-list-item-icon>
          <v-list-item-title>التذاكر</v-list-item-title>
        </v-list-item>
        <v-list-item @click="addToConvert(item)">
          <v-list-item-icon class="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.121"
              height="17.121"
              viewBox="0 0 17.121 17.121"
            >
              <g
                id="Icon_feather-arrow-up-right"
                data-name="Icon feather-arrow-up-right"
                transform="translate(-9.439 -9.439)"
              >
                <path
                  id="Path_5860"
                  data-name="Path 5860"
                  d="M10.5,25.5l15-15"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Path_5861"
                  data-name="Path 5861"
                  d="M10.5,10.5h15v15"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
              </g>
            </svg>
          </v-list-item-icon>

          <v-list-item-title>اضافه الي سله التحويل</v-list-item-title>
        </v-list-item>

        <v-list-item
          @click="$store.state.openDialogMargeUsers = !$store.state.openDialogMargeUsers"
        >
          <v-list-item-icon class="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.121"
              height="17.121"
              viewBox="0 0 17.121 17.121"
            >
              <g
                id="Icon_feather-arrow-up-right"
                data-name="Icon feather-arrow-up-right"
                transform="translate(-9.439 -9.439)"
              >
                <path
                  id="Path_5860"
                  data-name="Path 5860"
                  d="M10.5,25.5l15-15"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
                <path
                  id="Path_5861"
                  data-name="Path 5861"
                  d="M10.5,10.5h15v15"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                />
              </g>
            </svg>
          </v-list-item-icon>

          <v-list-item-title>دمج طلب مع اخر</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="save_Request"
          class="black--text font-weight-bold"
          style="border-bottom: 2px solid #ededed"
        >
          <v-list-item-icon class="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.55"
              height="19.55"
              viewBox="0 0 19.55 19.55"
            >
              <g
                id="Icon_feather-save"
                data-name="Icon feather-save"
                transform="translate(-4 -4)"
              >
                <path
                  id="Path_4006"
                  data-name="Path 4006"
                  d="M20.989,23.05H6.561A2.061,2.061,0,0,1,4.5,20.989V6.561A2.061,2.061,0,0,1,6.561,4.5H17.9L23.05,9.653V20.989A2.061,2.061,0,0,1,20.989,23.05Z"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
                <path
                  id="Path_4007"
                  data-name="Path 4007"
                  d="M20.805,27.744V19.5H10.5v8.244"
                  transform="translate(-1.878 -4.695)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
                <path
                  id="Path_4008"
                  data-name="Path 4008"
                  d="M10.5,4.5V9.653h8.244"
                  transform="translate(-1.878)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
              </g>
            </svg>
          </v-list-item-icon>
          <v-list-item-title>حفظ الطلب</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-snackbar
      v-model="active"
      :color="color"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <span class="font-weight-bold" style="font-size: 16px">{{ message }}</span>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <AdminDialog @selectedValue="selectedValue" :items="allStatus" />
    <MargeUserDialog v-if="$store.state.openDialogMargeUsers" />
    <AdministrationDialog @selectedAdministrationValue="selectedAdministrationValue"/>

  </div>
</template>

<script>
import AdminDialog from "@/components/AdminComponent/AdminDialog.vue";
import MargeUserDialog from "@/components/AdminComponent/MargeUserDialog.vue";
import AdministrationDialog from "@/components/AdminComponent/AdministrationDialog.vue";

import { axiosInstance } from "@/axios/config";

export default {
  components: { MargeUserDialog, AdminDialog,AdministrationDialog },
  data() {
    return {
      openMargeUser: false,
      allStatus: [],
      item: this.$store.state.requestId,
      active: false,
      message: "",
      color: null,
    };
  },
  methods: {
    margeUsers() {
      this.openMargeUser = true;
    },

    convertRequest(item) {
      this.$store.state.openDialog = true;
      this.getUserAdmin();
      this.selectId = this.item;
    },

    selectedAdministrationValue(itemSelected) {
      this.sendRequestToAdministration(itemSelected);
    },

    sendRequestToAdministration(itemSelected) {
      let body = new FormData();
      body.append("administration_comment", itemSelected);

      axiosInstance
        .post(`/v2/employee/Requests/moveToAdministration/${this.$route.params.id}`, body, {
          headers: this.request_headers(),
        })
        .then((res) => {
          this.active = true;
          this.message = res.data.message;
        })
        .catch((err) => {
          this.active = true;
          let error = { err };
          this.message = error.err.response.data.message;
        });
    },

    getUserAdmin() {
      axiosInstance.get(`/v2/employee/sales-agent-users`).then((res) => {
        this.allStatus = res.data.payload;
      });
    },

    sendRequestToAdmin() {
      this.$store.state.openAdministrationDialog = true;
    },


    save_Request() {
      this.saveErrs = {
        alert: false,
        error: [],
      };
      let payload = this.$store.state.request_info;
      let update_request = {
        request_id: parseInt(payload.id),
        customerName: payload.customer.name,
        sex: payload.customer.sex,
        birth_date: payload.customer.birth_date,
        birth_date_higri: payload.customer.birth_date_higri,
        age: payload.customer.age,
        age_years: payload.customer.age_years,
        work: payload.customer.work,
        madany_id: !payload.customer.askary_id
          ? parseInt(payload.customer.madany_id)
          : undefined,
        job_title: payload.customer.job_title,
        askary_id: !payload.customer.madany_id
          ? parseInt(payload.customer.askary_id)
          : undefined,
        military_rank: payload.customer.military_rank,
        hiring_date: payload.customer.hiring_date,
        salary: payload.customer.salary,
        salary_id: parseInt(payload.customer.salary_id),
        is_supported: payload.customer.is_supported,
        nationality_id: payload.customer.nationality_id,
        you_have: payload.customer.you_have,
        source_of_income: payload.customer.source_of_income,
        have_salary: payload.customer.have_salary,

        basic_salary: payload.customer.basic_salary,
        without_transfer_salary: payload.customer.without_transfer_salary,
        add_support_installment_to_salary:
          payload.customer.add_support_installment_to_salary,
        guarantees: parseInt(payload.customer.guarantees),
        has_obligations: payload.customer.has_obligations,
        obligations_value: payload.customer.obligations_value,
        has_financial_distress: payload.customer.has_financial_distress,
        financial_distress_value: payload.customer.financial_distress_value,
        jointName: payload.joint.name,
        jointMobile: payload.joint.mobile,
        jointSalary: payload.joint.salary,
        jointbirth: payload.joint.birth_date,
        jointBirth_higri: payload.joint.birth_date_higri,
        jointAge: payload.joint.age,
        jointage_years: payload.joint.age_years,
        jointWork: payload.joint.work,
        joint_hiring_date: payload.joint.hiring_date,
        jointmadanyWork: !payload.joint.jointaskaryWork
          ? parseInt(payload.joint.madany_id)
          : undefined,
        jointJobTitle: payload.joint.job_title,
        jointaskaryWork: !payload.joint.jointmadanyWork
          ? parseInt(payload.joint.askary_id)
          : undefined,
        jointRank: payload.joint.military_rank,
        jointsalary_source: parseInt(payload.joint.salary_id),
        jointSupport: payload.joint.is_supported,
        joint_add_support_installment_to_salary:
          payload.joint.add_support_installment_to_salary,
        jointObligations: payload.joint.has_obligations,
        jointobligations_value: payload.joint.obligations_value,
        jointfunding_source: parseInt(payload.joint.funding_id),
        realName: payload.real_estate.name,
        realMobile: payload.real_estate.mobile,
        realCity: payload.real_estate.city,
        realRegion: payload.real_estate.region,
        realPursuit: payload.real_estate.pursuit,
        realStatus: payload.real_estate.status,
        realAge: payload.real_estate.age,
        residence_type: payload.real_estate.residence_type,
        realType: payload.real_estate.type,
        realCost: payload.real_estate.cost,
        owning_property: payload.real_estate.owning_property,
        has_property: payload.real_estate.has_property,
        evaluated: payload.real_estate.evaluated,
        tenant: payload.real_estate.tenant,
        mortgage: payload.real_estate.mortgage,
        product_type: payload.funding.product_code,
        funding_source: payload.funding.funding_source,
        fundDur: payload.funding.funding_duration,
        fundPers: payload.funding.personalFun_cost,
        fundPersPre: payload.funding.personalFun_pre,
        fundReal: payload.funding.realFun_cost,
        fundRealPre: payload.funding.realFun_pre,
        fundFlex: payload.funding.flexiableFun_cost,
        fundExten: payload.funding.extendFund_cost,
        personal_salary_deduction: payload.funding.personal_salary_deduction,
        personal_installment: payload.funding.personal_monthly_installment,
        fundDed: payload.funding.ded_pre,
        fundMonth: payload.funding.monthly_in,
        installment_after_support: payload.funding.monthly_installment_after_support,
        reqtyp: payload.type,
        follow_date: payload.follow_date,
        follow_time: payload.follow_time
          ? payload.follow_time.length == 8
            ? payload.follow_time
            : payload.follow_time + ":00"
          : undefined,
        reqSource: payload.source,
        req_product_type: payload.req_product_type,
        agent_identity_number: payload.agent_identity_number,
        class_agent: parseInt(payload.class_id_agent),
        rejection_id_agent: parseInt(payload.rejection_id_agent),
        collaborator_id: parseInt(payload.collaborator_id),
        comment: payload.agent_notes,
      };
      let update_payload = {};
      for (const prop in update_request) {
        if (update_request[prop] != null) {
          update_payload[prop] = update_request[prop];
        }
      }

      axiosInstance
        .post("/v2/employee/Requests", update_payload)
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          this.showPopupError("success", err.response.data.message[0].split(","));
        });
    },

    sendToQuality(item) {
      this.$store.state.openDialog = true;
      this.getSupAdmin();
      this.selectId = this.item;
    },

    getSupAdmin() {
      axiosInstance.get(`/v2/employee/quality-users`).then((res) => {
        this.allStatus = res.data.payload;
      });
    },

    selectedValue(obj) {
      let itemSelected = obj;
      if (itemSelected.role === 5) {
        this.getQualityValues(itemSelected);
      } else if (itemSelected.role === 0) {
        this.getAgentRequest(itemSelected);
      }
    },

    getQualityValues(itemSelected) {
      let body = new FormData();

      body.append("request_ids[0]", this.$route.params.id);
      body.append("quality[0]", itemSelected.id);

      axiosInstance
        .post(`/v2/employee/Requests/moveToQualityReqArray`, body)
        .then((res) => {
          this.active = true;
          this.message = res.data.message;
          this.color = "green";
        })
        .catch((err) => {
          this.active = true;
          let error = { err };
          this.message = error.err.response.data.message;
          this.color = "red";
        });
    },

    getAgentRequest(itemSelected) {
      let body = new FormData();

      body.append("request_ids[0]", this.$route.params.id);
      body.append("agents_ids[0]", itemSelected.id);
      body.append("move_reason", itemSelected.move_reason);

      axiosInstance
        .post(`/v2/employee/Requests/moveAllRequests`, body)
        .then((res) => {
          this.active = true;
          this.message = res.data.message;
          this.color = "green";
        })
        .catch((err) => {
          this.active = true;
          let error = { err };
          this.message = error.err.response.data.message;
          this.color = "red";
        });
    },

    tickets(item) {
      this.$router.push({ name: "tickets", params: { id: this.item } });
    },

    addToConvert(item) {
      axiosInstance
        .get(`/v2/employee/Requests/addToNeedActionReq/${this.$route.params.id}`)
        .then((res) => {
          this.active = true;
          this.color = "green";
          this.message = "تم الاضافه الي سله التحويل";
        })
        .catch((err) => {
          this.active = true;
          let error = { err };
          this.message = error.err.response.data.message;
          this.color = "red";
        });
    },
  },
};
</script>
