<template>
    <div class="notifs position-absolute">
      <div class="the-container mx-2 my-10">
        <div class="cont-dad" style=" width: 25rem; margin: auto; ">
          <h2 style="margin-bottom:1rem;"> البحث عن عميل</h2>
          <v-row>
              <v-col cols="12" lg="12" md="12" sm="12" xs="12" class="mb-1 py-0">
                <div class="mb-2">
                    رقم العميل
                </div>
              <v-text-field
                hide-details
                v-model="mobile"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
              </v-col>
              <v-col cols="12" lg="12">
                  <v-btn
                  :color="$vuetify.theme.themes.light.dataActionBTN"
                  style="font-size: 16px; text-align: center; "
                  class="pb-2 receive-btn"
              :loading="Loader"
                  @click="search"
                  dark>
                  بحث 
                      </v-btn>
              </v-col>
              <v-col cols="12" v-if="row">
                <v-card class="pa-3 position-relative" style="height: 100%">
                  <span class="right-color requestType"
                    :style="`${row.request.status == `طلب تم فتحه` ? `background-color:#40BE72`
                : row.request.status == `طلب جديد` ? `background-color:#3B5DE7` : row.request.status == `مؤرشف لدى استشاري المبيعات`
                  ? `background-color:#017E84` : row.request.status == `طلب مرفوض` ? `background-color: indianred`
                    : row.request.status == `في انتظار موافقة مدير المبيعات` ? `background-color: #FF715B` : `background-color:black`}`">
                  </span>
                  <div class="card-head d-flex justify-space-between align-center">
                    <v-card-title class="pa-0 requestTitle">
                      {{ row?.name}}
                    </v-card-title>
                    <div class="icons">
                      
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn width="30" height="30" color="transparent" depressed fab light v-bind="attrs" v-on="on"
                            style="margin-left: -10px">
                            <img src="@/assets/icons/eyeIcon.svg" />
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="add_request(row)">
                            <v-list-item-icon class="ml-3">
                              <img src="@/assets/icons/titcketIcon.svg" />
                            </v-list-item-icon>
                            <v-list-item-title>إضافة</v-list-item-title>
                          </v-list-item>
                        </v-list>
                       
                      </v-menu>
                    </div>
                  </div>
                  <div class="req-status-parent mt-1 d-flex justify-space-between align-center">
                    <span class="req-type" style="color: #444B5A ; font-size: 15px;">
                      {{ row.request.type || "لايوجد نوع للطلب" }}
                    </span>

                    <span class="req-type" style="color: #404175 ; font-size: 20px;">
                      {{ row.request.user.name || null }}
                    </span>

                    <!-- <span class="req-status spanRequestType" :style="`${row.request.status == `طلب تم فتحه` ? `background-color:#40BE72` : row.request.status == `طلب جديد`
                ? `background-color:#3B5DE7` : row.request.status == `مؤرشف لدى استشاري المبيعات` ? `background-color:#017E84`
                  : row.request.status == `طلب مرفوض` ? `background-color: indianred` : row.request.status == `في انتظار موافقة مدير المبيعات`
                    ? `background-color: #FF715B` : `background-color:black`}`">{{ row.request.classification?.value }}
                    </span> -->
                  </div>

                  <div class="req-date-parent my-2 d-flex justify-space-between align-center">
                    <span class="req-date">
                      {{ (row.request.agent_date !== undefined)? row.request.agent_date.split(" ")[0] : '' }}
                    </span>
                    <span class="req-status" :style="`color: #444B5A ; font-size: 15px;`">
                      {{ row.request.class_id_agent_text || "لايوجد تصنيف معاملة" }}
                    </span>
                  </div>
                  <hr style="height: 1px !important; opacity: 0.3" class="mb-0" />
                  <div class="desc parent">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-card-text v-on="on" class="pt-2 px-0">
                          {{
                            row.request.agent_notes ? row.request.agent_notes.length > 50
                              ? row.request.agent_notes.substring(0, 50) + "..." : row.request.agent_notes : "لايوجد ملاحظات"
                          }}
                        </v-card-text>
                      </template>
                      <div style="color: #444B5A ; font-size: 14px;">{{ row.request.agent_notes || "لايوجد ملاحظات" }}</div>
                    </v-tooltip>
                  </div>
                </v-card>
              </v-col>
              
          </v-row>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import Axios from "axios";
  export default {
    data() {
      return {
          mobile:'',
          row: null,
        Loader: false,
    
      };
    },
    methods: {
        add_request(item){
            Axios.get(
          `https://appproduct.alwsata.com.sa/api/employee/quality/AddRequestQuality?request_id=${item.request.id}`,
          {
            headers: this.request_headers(),
          }
        )
        .then((res) => {
            
            this.showPopup("success", res.data.message);
            this.Loader = false;
          })
          .catch((err) => {
            this.showPopup("error", err.response.data.message);
            // this.verifing = false;
            this.Loader = false;
          });
        },
      async search() {
        this.Loader = true;
        const data = {
            mobile: this.mobile
        };
        await Axios.post(
          "https://appproduct.alwsata.com.sa/api/employee/quality/checkCustomer",
          data,
          {
            headers: this.request_headers(),
          }
        )
        .then((res) => {
            this.row = res.data.payload;
            // this.showPopup("success", res.data.message);
            this.Loader = false;
          })
          .catch((err) => {
            this.showPopup("error", err.response.data.message);
            // this.verifing = false;
            this.Loader = false;
          });
      }
    },
    mounted() {
        
    },
  };
  </script>
  
  
  <style lang="scss">
  .notifs {
    .cont-dad {
      background: #ffffff;
      border: 1px solid #ebebeb;
      border-radius: 6px;
      color: #2c2c2c;
      padding: 9px 20px 11px 7px;
      margin-top: 20px;
      .upper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .right-side {
          display: flex;
          align-items: center;
          span {
            font-size: 15px;
            font-weight: 500;
          }
        }
      }
      p {
        margin: 0;
        margin-top: 6px;
      }
      h2 {
        margin: 0;
        font-weight: 900;
        font-size: 18px;
      }
    }
  }
  @media (max-width: 600px) {
    .notifs {
      .cont-dad {
        h2 {
          font-size: 14px;
        }
        span {
          font-size: 11px !important;
        }
        p {
          font-size: 13px;
        }
      }
    }
  }
  </style>
  