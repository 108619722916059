<template>
  <div>
    <v-menu offset-y nudge-top="-7">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar size="45px" v-bind="attrs" v-on="on" style="z-index: 5; left: 80px; top: -53px; position: absolute">
          <svg width="512" height="512" viewBox="0 0 512 512" fill="#3f0e40" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M474.557 37.4414V168.577H328.851V37.4414H474.557ZM255.999 37.4414V474.557H37.4414V37.4414H255.999ZM474.557 197.719V314.281H328.851V197.719H474.556H474.557ZM474.557 343.422V474.557H328.851V343.422H474.556H474.557Z"
              fill="#3f0e40" />
          </svg>
        </v-avatar>
      </template>
      <v-list>
        <v-list-item class="pl-12" @click="$store.state.showCards = true" style="border-bottom: 2px solid #ededed">
          <v-list-item-icon class="ml-2">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="#3F0E40" xmlns="http://www.w3.org/2000/svg">
              <g id="four-squares-icon 1">
                <g id="Group">
                  <path id="Vector" fill-rule="evenodd" clip-rule="evenodd"
                    d="M2.61524 12.7831H10.4074C10.7402 12.7831 11.0124 13.1075 11.0124 13.5034V21.0877C11.0124 21.4842 10.7404 21.8083 10.4074 21.8083H2.61524C2.28272 21.8083 2.01025 21.4842 2.01025 21.0877V13.5034C2.01025 13.1075 2.28272 12.7831 2.61524 12.7831ZM13.5925 2H21.3848C21.7178 2 21.9898 2.32407 21.9898 2.72043V10.3048C21.9898 10.7011 21.7178 11.0249 21.3848 11.0249H13.5925C13.2596 11.0249 12.988 10.7011 12.988 10.3048V2.72043C12.988 2.32407 13.2596 2 13.5925 2ZM2.60449 2H10.3971C10.73 2 11.0021 2.32407 11.0021 2.72043V10.3048C11.0021 10.7011 10.7302 11.0249 10.3971 11.0249H2.60449C2.27197 11.0249 2 10.7011 2 10.3048V2.72043C2 2.32407 2.27197 2 2.60449 2ZM13.6027 12.9753H21.3951C21.7276 12.9753 22 13.2994 22 13.6959V21.2803C22 21.6761 21.7276 22.0002 21.3951 22.0002H13.6027C13.2699 22.0002 12.9977 21.6761 12.9977 21.2803V13.6959C12.9977 13.2994 13.2699 12.9753 13.6027 12.9753Z"
                    fill="#3F0E40" />
                </g>
              </g>
            </svg>
          </v-list-item-icon>
          <v-list-item-title style="line-height: 2">
            عرض الكروت</v-list-item-title>
        </v-list-item>

        <v-list-item class="pl-12" @click="$store.state.showCards = false" style="border-bottom: 2px solid #ededed">
          <v-list-item-icon class="ml-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="27.348" height="22.783" viewBox="0 0 24.348 19.783"
              fill="#3F0E40">
              <path id="Icon_awesome-list-ul" data-name="Icon awesome-list-ul"
                d="M22.065,3.375a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,3.375Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,10.984Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283,2.283,2.283,0,0,1,2.283-2.283Zm-21.3.761H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,21.636V20.114A.761.761,0,0,1,.761,19.353Zm0-15.217H15.978a.761.761,0,0,1,.761.761V6.418a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,6.418V4.9A.761.761,0,0,1,.761,4.136Zm0,7.609H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,14.027V12.505A.761.761,0,0,1,.761,11.745Z"
                transform="translate(0 -3.375)" fill="#3F0E40" />
            </svg>
          </v-list-item-icon>
          <v-list-item-title style="line-height: 2">عرض الجدول</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn style="z-index: 5; left: 130px; top: -53px; position: absolute" @click="helpDeskDialog = true" fab
      class="mx-2 pa-0 genaralIcons" small :color="$vuetify.theme.themes.light.BtnBKColor" depressed width="40"
      height="40">
      <v-icon color="white">mdi-magnify</v-icon>
    </v-btn>

    <v-btn style="z-index: 5; left: 180px; top: -53px; position: absolute" title="excel" depressed width="40"
      height="40" :color="$vuetify.theme.themes.light.dataActionBTN" dark fab class="ml-1 hidden-sm-and-down"
      @click="exportExcel" v-if="$store.state.showCards == false">
      <svg xmlns="http://www.w3.org/2000/svg" width="18.116" height="18.116" viewBox="0 0 12.25 14">
        <g id="excel-file" transform="translate(-1.5)">
          <path id="Path_4097" data-name="Path 4097"
            d="M11.708,14H1.792a.292.292,0,0,1-.292-.292V3.548a.292.292,0,0,1,.074-.194L4.491.1A.292.292,0,0,1,4.708,0h7A.292.292,0,0,1,12,.292V7a.292.292,0,0,1-.583,0V.583H4.839L2.083,3.659v9.757h9.333V12.25a.292.292,0,1,1,.583,0v1.458A.293.293,0,0,1,11.708,14Z"
            fill="#fff" />
          <path id="Path_4098" data-name="Path 4098"
            d="M4.917,4.292H2.292a.292.292,0,0,1,0-.583H4.625V.792a.292.292,0,0,1,.583,0V4A.292.292,0,0,1,4.917,4.292Zm8.167,8.167h-7a.876.876,0,0,1-.875-.875v-3.5a.876.876,0,0,1,.875-.875h7a.876.876,0,0,1,.875.875v3.5A.876.876,0,0,1,13.083,12.458Zm-7-4.667a.292.292,0,0,0-.292.292v3.5a.292.292,0,0,0,.292.292h7a.293.293,0,0,0,.292-.292v-3.5a.293.293,0,0,0-.292-.292Z"
            transform="translate(-0.208 -0.208)" fill="#fff" />
          <path id="Path_4099" data-name="Path 4099"
            d="M11.29,15.67,10.566,14.6h.3a.075.075,0,0,1,.047.012.1.1,0,0,1,.027.03l.572.878a.4.4,0,0,1,.032-.07l.54-.8a.186.186,0,0,1,.03-.036.058.058,0,0,1,.039-.012h.283l-.726,1.052.751,1.139h-.293a.079.079,0,0,1-.053-.017.176.176,0,0,1-.031-.038l-.588-.919a.316.316,0,0,1-.027.061l-.572.858a.21.21,0,0,1-.032.039.065.065,0,0,1-.05.018h-.275l.755-1.125Zm1.754.876h.948V16.8H12.747V14.6h.3Zm2.4-1.6a.117.117,0,0,1-.03.034.059.059,0,0,1-.039.012.111.111,0,0,1-.061-.026q-.042-.031-.086-.058a.805.805,0,0,0-.128-.057.558.558,0,0,0-.18-.026.575.575,0,0,0-.176.027.377.377,0,0,0-.128.073.279.279,0,0,0-.076.109.348.348,0,0,0-.026.134.243.243,0,0,0,.045.152.385.385,0,0,0,.119.1.952.952,0,0,0,.168.074l.193.065c.065.023.131.048.192.075a.658.658,0,0,1,.168.107.486.486,0,0,1,.12.158.515.515,0,0,1,.045.229.735.735,0,0,1-.049.269.632.632,0,0,1-.143.22.672.672,0,0,1-.231.146.849.849,0,0,1-.311.054.956.956,0,0,1-.389-.077.908.908,0,0,1-.3-.209l.086-.14a.107.107,0,0,1,.03-.029.07.07,0,0,1,.038-.012.118.118,0,0,1,.073.034.946.946,0,0,0,.1.076.608.608,0,0,0,.366.107.577.577,0,0,0,.188-.029.378.378,0,0,0,.258-.373.269.269,0,0,0-.045-.163.38.38,0,0,0-.118-.106.832.832,0,0,0-.167-.072l-.192-.061a2.009,2.009,0,0,1-.192-.073.608.608,0,0,1-.167-.109.484.484,0,0,1-.118-.164.571.571,0,0,1-.045-.244.579.579,0,0,1,.177-.418.622.622,0,0,1,.211-.134.75.75,0,0,1,.29-.05.921.921,0,0,1,.335.058.773.773,0,0,1,.264.169l-.072.141Z"
            transform="translate(-3.765 -6.075)" fill="#fff" />
        </g>
      </svg>
    </v-btn>
    <v-btn style="z-index: 5; left: 230px; top: -53px; position: absolute" title="print" depressed width="42"
      height="42" @click="printNow" :color="$vuetify.theme.themes.light.dataActionBTN" fab
      v-if="$store.state.showCards == false">
      <svg xmlns="http://www.w3.org/2000/svg" width="18.116" height="18.116" viewBox="0 0 16.116 16.116">
        <g id="Icon_feather-printer" data-name="Icon feather-printer" transform="translate(-2.5 -2.5)">
          <path id="Path_4011" data-name="Path 4011" d="M9,8.291V3h9.069V8.291" transform="translate(-2.977)"
            fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
          <path id="Path_4012" data-name="Path 4012"
            d="M6.023,20.3H4.512A1.512,1.512,0,0,1,3,18.791V15.012A1.512,1.512,0,0,1,4.512,13.5H16.6a1.512,1.512,0,0,1,1.512,1.512v3.779A1.512,1.512,0,0,1,16.6,20.3H15.093"
            transform="translate(0 -5.209)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round"
            stroke-width="1" />
          <path id="Path_4013" data-name="Path 4013" d="M9,21h9.069v6.046H9Z" transform="translate(-2.977 -8.931)"
            fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
        </g>
      </svg>
    </v-btn>


    <!-- filter  -->
    <v-dialog v-model="helpDeskDialog" persistent width="1024">
      <v-card>
        <v-card-title>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="رقم هاتف الدعم الفني" v-model="phoneNumber" outlined v-numericOnly
                  dense></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field label="أسم المستخدم او العميل" v-model="customerOrUserName" outlined dense></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field label="الوصف" v-model="descrebtion" outlined dense></v-text-field>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="display:flex; justify-content: space-between;" class="mt-3">
          <div>
            <v-btn color="blue-darken-1" variant="text" @click="deleteFilter">
              حذف الفلتر
            </v-btn>
          </div>
          <div>
            <v-btn color="blue-darken-1" variant="text" class="ml-2" @click="helpDeskDialog = false">
              الغاء
            </v-btn>
            <v-btn color="blue-darken-1" variant="text" @click="startSearch">
              بحث
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
.card__style {
  position: absolute;
  top: -50px;
  min-width: unset;
  z-index: 5;
}
</style>

<script>

export default {
  name: "HeaderProcess",
  props: ['items', 'headers'],

  data() {
    return {
      dialog: false,
      helpDeskDialog: false,
      phoneNumber: "",
      customerOrUserName: "",
      descrebtion: "",
    };
  },
  methods: {
    toggleTableStyle(e) {
      document.querySelectorAll(".table-style").forEach((btn) => {
        btn.querySelector("svg").classList.remove("active-table-style");
      });
      e.target.querySelector("svg").classList.add("active-table-style");
    },

    handleTableView() {
      this.$store.state.showCards = false;
    },

    exportExcel() {
      const table = document.querySelector(".all-requests table");
      this.exportTableToCsv(table, "طلبات الدعم الفني");
    },
    deleteFilter() {
      this.phoneNumber = "";
      this.customerOrUserName = "";
      this.descrebtion = "";
    },

    printNow() {
      console.log('fodkfodkfodkof');
      console.log(this.items);
      this.printTable(this.items, this.headers, "جدول طلبات الدعم الفني");
    },

    startSearch() {
      console.log("start search");
      this.helpDeskDialog = false;
      this.$emit("filterHelpDesk", {
        name: this.customerOrUserName,
        mobile: this.phoneNumber,
        descrebtion: this.descrebtion,
      });
    }

  },
};
</script>
