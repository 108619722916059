<template>
  <v-snackbar
    v-model="active"
    color="#333333"
    timeout="-1"
    right
    bottom
    v-click-outside="clickOutside"
    class="ml-4"
    elevation="24"
    transition="fade-transition"
  >
    <div v-for="message in messages" :key="message">
      <span class="font-weight-bold" style="font-size: 16px">{{ message }}</span>
    </div>
    <div v-if="text_link && link" class="mt-3">
      <v-btn outlined @click="openRequestOrTicket">
        {{text_link}}
      </v-btn>
    </div>
    <template v-slot:action>
      <v-btn icon small @click="active = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'PopupErrorBtnComponent',
  data() {
    return {
      active: false,
      text: "",
      messages: [],
      message: "",
      status: "success",
      link: null,
      copy: null,
      text_link: null,
    };
  },
  computed: {
    copyText() {
      if (typeof this.copy != 'object') {
        return this.copy
      }
      return this.copy.content
    },
    hrefTarget () {
      return (this.link.newTap ? "_blank" : "_self")
    },

  },
  created() {},
  mounted() {
    this.$root.$on("popup-btn", (status, text,text_link=null, link = null) => {
      this.active = true;
      this.text = text;
      this.status = status;
      this.messages = this.text.split("\n");
      this.link = link;
      this.text_link = text_link;
    });
  },
  methods: {
    clickOutside() {
      this.$nextTick(() => {
        this.active = false;
      });
    },

    openRequestOrTicket(){
      if(this.text_link == "فتح التذكرة"){
        this.$router.push({ name: 'ticket_details', params: { ticket: this.link } });
        this.active = false;
      } else if(this.text_link == "فتح الطلب"){
        this.$router.push({ name: 'request_details', params: { id: this.link } });
      }
    }
  },
};
</script>
