export const SearchIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.467"
            height="15.92"
            viewBox="0 0 16.467 14.92"
            className="mt-1"
        >
            <path
                id="Icon_feather-filter"
                data-name="Icon feather-filter"
                d="M18.467,4.5H3l6.187,7.316v5.058L12.28,18.42v-6.6Z"
                transform="translate(-2.5 -4)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
            ></path>
        </svg>

    )
}
