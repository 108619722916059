<template>
  <v-row justify="center">
    <v-dialog v-model="$store.state.search_admin" persistent width="824">
      <v-card id="adminFilter">
        <v-card-title style="display: flex; justify-content: space-between">
          <span>البحث</span>
          <v-icon color="red" @click="$store.state.search_admin = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <hr />
        <v-card-text>
          <v-container>
            <v-row>

              <v-col cols="12" sm="6" md="6">
                <SelectInput
                  ref="selectInput1"
                  @selectedValues="getSelectedValueManger"
                  :originalItems="getManagerNames"
                  label="مدير المبيعات"
                />
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <SelectInput
                  ref="selectInput2"
                  @selectedValues="getSelectedValueAgent"
                  :originalItems="getAgentsName"
                  label="استشاري المبيعات"
                />
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <SelectInput
                  ref="selectInput10"
                  @selectedValues="getSelectedAgentType"
                  :originalItems="selesAgentType"
                  label="تصنيف استشاري المبيعات"
                />
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="الهاتف"
                  v-model="phoneNumber"
                  outlined
                  v-numericOnly
                  dense
                ></v-text-field>
              </v-col>
              
              <v-col cols="12" sm="6" md="6">
                <SelectInput
                  ref="selectInput6"
                  @selectedValues="getSelectedRealEstatesCityies"
                  :originalItems="real_estate_cities_list"
                  label="مدينة العقار"
                />
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions
          style="display=flex; justify-content: space-between;"
          class="mt-3"
        >
          <!-- <v-spacer></v-spacer> -->

          <div>
            <v-btn color="blue-darken-1" variant="text" @click="removeFilter">
              حذف الفلتر
            </v-btn>
          </div>
          <div>
            <v-btn
              color="blue-darken-1"
              variant="text"
              class="ml-2"
              @click="$store.state.search_admin = false"
            >
              الغاء
            </v-btn>
            <v-btn color="blue-darken-1" variant="text" @click="searchRequest">
              بحث
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Dialog from "../../mixins/dialog";
import SelectInput from "@/components/Charts/SelectInput.vue";
// import PickerInput from "@/components/AdminComponent/PickerInput.vue";
import { removeDuplicated } from "@/mixins/removeDublicated";
// import { SearchIconBlack } from "@/assets/adminIcons/searchIconBlack";
import { axiosInstance } from "@/axios/config";

export default {
  components: { 
    // PickerInput, 
    SelectInput
    // SearchIconBlack 
  },
  mixins: [Dialog],
  props: ["pageName"],

  data() {
    return {
      body: {},
      disabled: false,
      filterType: "إسم العميل",
      panel: 0,
      filterTypeParts: "",
      allStatus: ["الكل", "استشاري مؤرشف", "استشاري نشط"],
      allTypes: ["جميع التصنيفات", "ايجابي", "سلبي"],
      typeRequest: [],
      allType: "",
      noteType: ["بها محتوي", "فارغه"],
      allMangerType: ["general", "gen"],
      allMangers: [],
      allAgents: [],
      selectedStatusFilter: "الكل",
      selectedManger: [],
      selectedMang: [],
      selesAgentType: [],
      selesMangerType: [],
      fundingMangerType: [],
      marteryMangerType: [],
      genaralMangerType: [],
      qualityUserType: [],
      selectAgent: [],
      salaryFrom: "",
      salaryTo: "",
      FundRealFrom: "",
      FundRealTo: "",
      requestStatu: [],
      selectedRequestStatus: [],
      requestSource: [],
      selectedRegion: [],
      sourceWork: [],
      phoneNumber: "",
      selectAgentType: [],
      selectedSalesManger: [],
      selectedFundingManger: [],
      selectMortgageManger: [],
      selectGeneralManger: [],
      productType: [],
      selectedProductType: [],
      requestTypeDrop: [],
      birthDate: "",
      selectBirthData: null,
      counter: 0,
      isAplicationInstal: "",
      getRequestsType: "",
      selectedRegionList: [],
      selectedTypeRequest: [],
      requestDateFrom: "",
      requestDateTo: "",
      completeDateFrom: "",
      completeDateTo: "",
      updatedDate: false,
      updatedDateFrom: "",
      updatedDateTo: "",
      UnUpdatedDate: "",
      workList: [],
      selectedWorkList: [],
      salaryList: [],
      selectedSalaryList: [],
      fundingSide: [],
      selectedFundingSide: [],
      quality: [],
      selectedQualityUsers: [],
      resiveFromQuality: "",
      isHasProperty: "",
      qualityArchive: "",
      selectedVarityQuality: [],
      qualityStatus: [],
      selectedQualityStatus: [],
      real_estate_types_list: [],
      real_estate_cities_list: [],
      selectedRealEstateTypesList: [],
      selectedRealEstateCitiesList: [],

      requestsCount: 0,
      typesCount: 0,
      clientCount: 0,
      qualityCount: 0,
    };
  },
  computed: {
    getManagerNames() {
      return this.allMangers;
    },

    getAgentsName() {
      return this.allAgents;
    },
  },

  watch: {
    selectedStatusFilter(val) {
      if (val === "الكل") {
        this.getAllManger("manager");
        this.getAllAgents("agent", 1, this.selectedMang);
      } else if (val === "استشاري مؤرشف") {
        this.getAllAgents("agent", 0, this.selectedMang);
        this.getAllManger("manager ", 0);
      } else if (val === "استشاري نشط") {
        this.getAllAgents("agent", 1, this.selectedMang);
        this.getAllManger("manager ", 1);
      }
    },
  },

  methods: {
    removeFilter() {
      this.requestsCount = 0;
      this.typesCount = 0;
      this.clientCount = 0;
      this.qualityCount = 0;

      this.filterTypeParts = "";
      this.selectedStatusFilter = "الكل";
      this.getRequestsType = "";
      this.allType = "";
      this.salaryFrom = "";
      this.salaryTo = "";

      this.FundRealFrom = "";
      this.FundRealTo = "";
      this.isAplicationInstal = "";
      this.resiveFromQuality = "";
      this.qualityArchive = "";

      this.phoneNumber = "";

      for (let i = 1; i <= 18; i++) {
        if (this.$refs[`selectInput${i}`] && this.$refs[`selectInput${i}`].clear) {
          this.$refs[`selectInput${i}`].clear();
        }
      }

      for (let i = 1; i <= 7; i++) {
        if (this.$refs[`pickerInput${i}`]) {
          this.$refs[`pickerInput${i}`].date = null;
        }
      }
    },
    getQualityType(val) {
      this.selectedQualityStatus = val;
    },

    getVarityQuality(val) {
      this.selectedVarityQuality = val;
    },
    getQuality(val) {
      this.selectedQualityUsers = val;
    },

    getFundingSide(val) {
      this.selectedFundingSide = val;
    },

    getSalaryList(val) {
      this.selectedSalaryList = val;
    },

    getworkList(val) {
      this.selectedWorkList = val;
    },

    saveUpdatedFrom(val) {
      this.updatedDateFrom = val;
    },

    saveUpdatedTo(val) {
      this.updatedDateTo = val;
    },

    saveUnUpdatedTo(val) {
      this.UnUpdatedDate = val;
    },

    handleToggle() {
      this.updatedDate = !this.updatedDate;
    },

    saveBirthDate(val) {
      this.birthDate = val;
    },

    saveDateFrom(val) {
      this.requestDateFrom = val;
    },

    saveDateTo(val) {
      this.requestDateTo = val;
    },

    saveCompleteDateFrom(val) {
      this.completeDateFrom = val;
    },

    saveCompleteDateTo(val) {
      this.completeDateTo = val;
    },

    requestStatus() {
      axiosInstance.get("/agent/RequestStatus").then((response) => {
        this.requestTypeDrop = response.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["value"];
          return item;
        });
      });
    },

    get_work_list() {
      this.workList = this.$store.state.works_list.map((item) => {
        item["color"] = "red";
        return item;
      });
    },

    getRequestSource() {
      axiosInstance.get("/agent/Request/Sources").then((res) => {
        this.requestSource = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["value"];
          return item;
        });
      });
    },

    getSelectedRegion() {
      axiosInstance.get("/agent/regions").then((res) => {
        this.selectedRegion = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["region_ip"];
          return item;
        });
      });
    },

    get_salary_sources() {
      axiosInstance.get("/customer/SalarySources").then((res) => {
        this.salaryList = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["value"];
          return item;
        });
      });
    },

    getSelectedRegionValue(val) {
      this.selectedRegionList = val;
    },

    getRealEstatesTypes() {
      axiosInstance.get("/agent/RealEstate/Types").then((res) => {
        this.real_estate_types_list = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["value"];
          return item;
        });
      });
    },

    getSelectedRealEstatesTypes(val) {
      this.selectedRealEstateTypesList = val;
    },

    getSelectedRealEstatesCityies(val) {
      this.selectedRealEstateCitiesList = val;
    },

    getRealEstatesCity() {
      axiosInstance.get("/agent/Cities").then((res) => {
        this.real_estate_cities_list = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["value"];
          return item;
        });
      });
    },

    getRequestType() {
      this.requestTypeDrop = this.$store.state.request_statuses.map((item) => {
        item["color"] = "red";
        item["text"] = item["value"];
        return item;
      });
    },

    getSourceWork(val) {
      this.sourceWork = val;
    },

    searchRequest() {
      this.$store.state.isSearch = true;
      // this.$store.state.showCards = true;
      this.body = new FormData();
      this.$store.state.search_admin = false;

      this.$store.state.allRequests = [];
      this.$store.state.allSearch = [];
      this.$store.state.allData = [];
      this.$store.state.recievedSearch = [];
      this.$store.state.recievedData = [];
      this.$store.state.followSearch = [];
      this.$store.state.followData = [];
      this.$store.state.featureSearch = [];
      this.$store.state.featureData = [];
      this.$store.state.archiveSearch = [];
      this.$store.state.archiveData = [];
      this.$store.state.latestData = [];

      this.$store.state.tableData = [];
      this.$store.state.InfinityPageNumber = 1;

      this.$store.state.loadingData = true;
      // this.$store.state.allLoading = true;
      this.$store.state.recievedLoading = true;
      this.$store.state.followLoading = true;
      this.$store.state.featureLoading = true;
      this.$store.state.archiveLoading = true;
      this.$store.state.latestLoading = true;

      this.$store.state.back = true;
      this.$store.state.startElement = [];
      this.$store.state.bodySearch = {};
      this.counter++;

      this.requestsCount = 0;
      this.typesCount = 0;
      this.clientCount = 0;
      this.qualityCount = 0;

      if (this.selectedMang.length > 0) {
        this.selectedMang.forEach((item, index) => {
          this.body.append(`sales_manager_ids[${index}]`, item["id"]);
        });
        this.requestsCount++;
      }

      if (this.selectAgent.length > 0) {
        this.selectAgent.forEach((item, index) => {
          this.body.append(`agent_name[${index}]`, item["id"]);
        });
        this.requestsCount++;
      }

      if (this.selectedRequestStatus.length > 0) {
        this.selectedRequestStatus.forEach((item, index) => {
          this.body.append(`request_status[${index}]`, item["status_id"]);
        });
        this.requestsCount++;
      }

      if (this.sourceWork.length > 0) {
        this.sourceWork.forEach((item, index) => {
          this.body.append(`work_source[${index}]`, item["id"]);
        });
        this.requestsCount++;
      }

      if (this.selectAgentType.length > 0) {
        this.selectAgentType.forEach((item, index) => {
          this.body.append(`agent_classification[${index}]`, item["id"]);
        });
        this.typesCount++;
      }

      if (this.selectedSalesManger.length > 0) {
        this.selectedSalesManger.forEach((item, index) => {
          this.body.append(`sales_manager_classification[${index}]`, item["id"]);
        });
        this.typesCount++;
      }

      if (this.selectedSalaryList.length > 0) {
        this.selectedSalaryList.forEach((item, index) => {
          this.body.append(`salary_source[${index}]`, item["id"]);
        });
        this.requestsCount++;
      }

      if (this.selectedFundingManger.length > 0) {
        this.selectedFundingManger.forEach((item, index) => {
          this.body.append(`funding_manager_classification[${index}]`, item["id"]);
        });
        this.typesCount++;
      }

      if (this.selectMortgageManger.length > 0) {
        this.selectMortgageManger.forEach((item, index) => {
          this.body.append(`mortgage_manager_classification[${index}]`, item["id"]);
        });
        this.typesCount++;
      }

      if (this.selectGeneralManger.length > 0) {
        this.selectGeneralManger.forEach((item, index) => {
          this.body.append(`general_manager_classification[${index}]`, item["id"]);
        });
        this.typesCount++;
      }

      if (this.selectedProductType.length > 0) {
        this.selectedProductType.forEach((item, index) => {
          this.body.append(`request_product_type[${index}]`, item["id"]);
        });
        this.requestsCount++;
      }

      if (this.selectedRegionList.length > 0) {
        this.selectedRegionList.forEach((item, index) => {
          this.body.append(`customer_region_ip[${index}]`, item["text"]);
        });
        this.clientCount++;
      }

      if (this.selectedRealEstateCitiesList.length > 0) {
        this.selectedRealEstateCitiesList.forEach((item, index) => {
          this.body.append(`real_estate_city[${index}]`, item["id"]);
        });
        this.clientCount++;
      }

      if (this.selectedRealEstateTypesList.length > 0) {
        this.selectedRealEstateTypesList.forEach((item, index) => {
          this.body.append(`real_estate_type[${index}]`, item["id"]);
        });
        this.clientCount++;
      }

      if (this.selectedTypeRequest.length > 0) {
        this.selectedTypeRequest.forEach((item, index) => {
          this.body.append(`request_types[${index}]`, item["value"]);
        });
        this.requestsCount++;
      }

      if (this.selectedWorkList.length > 0) {
        this.selectedWorkList.forEach((item, index) => {
          this.body.append(`work_source[${index}]`, item["id"]);
        });
        this.requestsCount++;
      }

      if (this.selectedFundingSide.length > 0) {
        this.selectedFundingSide.forEach((item, index) => {
          this.body.append(`funding_source[${index}]`, item["id"]);
        });
        this.requestsCount++;
      }

      if (this.selectedQualityUsers.length > 0) {
        this.selectedQualityUsers.forEach((item, index) => {
          this.body.append(`quality_of_request[${index}]`, item["id"]);
        });
        this.qualityCount++;
      }

      if (this.selectedVarityQuality.length > 0) {
        this.selectedVarityQuality.forEach((item, index) => {
          this.body.append(`quality_classification_of_request[${index}]`, item["id"]);
        });
        this.qualityCount++;
      }

      if (this.selectedQualityStatus.length > 0) {
        this.selectedQualityStatus.forEach((item, index) => {
          this.body.append(`quality_status_of_request[${index}]`, item["id"]);
        });
        this.qualityCount++;
      }

      if (this.allType === "سلبي") {
        this.body.append("agent_positive_or_negative_classification", 0);
        this.typesCount++;
      }

      if (this.allType === "ايجابي") {
        this.body.append("agent_positive_or_negative_classification", 1);
        this.typesCount++;
      }

      if (this.selectedStatusFilter === "استشاري نشط") {
        this.body.append("agent_active_or_archived_classification", 1);
        this.requestsCount++;
      }

      if (this.selectedStatusFilter === "استشاري مؤرشف") {
        this.body.append("agent_active_or_archived_classification", 0);
        this.requestsCount++;
      }

      if (this.phoneNumber) {
        this.body.append("customer_phone", this.phoneNumber);
        this.clientCount++;
      }

      if (this.salaryFrom) {
        this.body.append("customer_salary_from", this.salaryFrom);
        this.clientCount++;
      }

      if (this.salaryTo) {
        this.body.append("customer_salary_to", this.salaryTo);
        this.clientCount++;
      }

      if (this.FundRealFrom) {
        this.body.append("fund_real_from", this.FundRealFrom);
        this.clientCount++;
      }

      if (this.FundRealTo) {
        this.body.append("fund_real_to", this.FundRealTo);
        this.clientCount++;
      }

      if (this.birthDate) {
        this.body.append("customer_birth_date", this.birthDate);
        this.clientCount++;
      } else {
        this.body.delete("customer_birth_date");
      }

      if (this.counter === 1) {
        this.$store.state.allRequests = [];
        this.body.append("page", 1);
      }

      if (this.getRequestsType === "فارغه") {
        this.body.append("note_status", 0);
        this.requestsCount++;
      }

      if (this.getRequestsType === "بها محتوي") {
        this.body.append("note_status", 1);
        this.requestsCount++;
      }

      if (this.isAplicationInstal === "نعم") {
        this.body.append("customer_app_download", 1);
        this.clientCount++;
      }

      if (this.isAplicationInstal === "لا") {
        this.body.append("customer_app_download", 0);
        this.clientCount++;
      }

      if (this.requestDateFrom) {
        this.body.append("request_date_from", this.requestDateFrom);
        this.requestsCount++;
      }

      if (this.requestDateTo) {
        this.body.append("request_date_to", this.requestDateTo);
        this.requestsCount++;
      } else {
        this.body.delete("request_date_to");
      }

      if (this.completeDateFrom) {
        this.body.append("complete_date[0]", this.completeDateFrom);
        this.requestsCount++;
      } else {
        this.body.delete("complete_date[0]");
      }

      if (this.completeDateTo) {
        this.body.append("complete_date[1]", this.completeDateTo);
        this.requestsCount++;
      } else {
        this.body.delete("complete_date[1]");
      }

      if (this.updatedDateFrom) {
        this.body.append("request_date_updated_from", this.updatedDateFrom);
        this.requestsCount++;
      } else {
        this.body.delete("request_date_updated_from");
      }

      if (this.updatedDateTo) {
        this.body.append("request_date_updated_to", this.updatedDateTo);
        this.requestsCount++;
      } else {
        this.body.delete("request_date_updated_to");
      }

      if (this.UnUpdatedDate) {
        this.body.append("request_date_did_not_updated_from", this.UnUpdatedDate);
        this.requestsCount++;
      } else {
        this.body.delete("request_date_did_not_updated_from");
      }

      if (this.resiveFromQuality === "نعم") {
        this.body.append("quality_request_received", 1);
        this.qualityCount++;
      }

      if (this.resiveFromQuality === "لا") {
        this.body.append("quality_request_received", 0);
        this.qualityCount++;
      }

      if (this.qualityArchive === "لا") {
        this.body.append("request_archived_by_quality", 0);
        this.qualityCount++;
      }

      if (this.isHasProperty === "نعم") {
        this.body.append("real_estate_has_property", 1);
      }

      if (this.isHasProperty === "لا") {
        this.body.append("real_estate_has_property", 0);
      }

      if (this.qualityArchive === "نعم") {
        this.body.append("request_archived_by_quality", 1);
        this.qualityCount++;
      }

      if (this.filterTypeParts) {
        this.body.append("text_name", this.filterTypeParts);
        this.requestsCount++;
      }

      this.$store.state.bodySearch = this.body;

      this.$emit("handleFilter", this.body);
    },

    setTblData(res) {
      const newItem = {
        id: res.id,
        isSelected: false,
        "رقم الطلب": res.id,
        عميل: res.customer.name,
        الجوال: res.customer.mobile,
        "تصنيف الاستشاري": res.class_id_agent_text,
        "هل يمتلك عقار":
          res.real_estate?.has_property && res.real_estate?.has_property === 0
            ? "لا"
            : "نعم",
        "مدينة العقار": res.real_estate.realCity,
        "منطقة العقار": res.real_estate.region,
      };

      const exists = this.$store.state.tableData.some((item) => item.id === newItem.id);

      if (!exists) {
        this.$store.state.tableData.push(newItem);
      }
    },

    requestClass(data) {
      data.map((item) => {
        item["color"] = "red";
        item["text"] = item["value"];
        return item;
      });
    },

    getTypeRequestList() {
      let data = [
        {
          color: "red",
          value: "شراء",
        },
        {
          color: "red",
          value: "رهن",
        },
        {
          color: "red",
          value: "تساهيل",
        },
        {
          color: "red",
          value: "شراء-دفعة",
        },
        {
          color: "red",
          value: "رهن-شراء",
        },
      ];
      this.typeRequest = data.map((item) => {
        item["color"] = "red";
        item["text"] = item["value"];
        return item;
      });
    },

    getRequestClassification(val) {
      axiosInstance
        .get(`/agent/Request/Classification?type_of_classification=${val}`)
        .then((res) => {
          if (val === 1) {
            this.selesMangerType = res.data.payload;
            this.requestClass(this.selesMangerType);
          } else if (val === 2) {
            this.fundingMangerType = res.data.payload;
            this.requestClass(this.fundingMangerType);
          } else if (val === 3) {
            this.marteryMangerType = res.data.payload;
            this.requestClass(this.marteryMangerType);
          } else if (val === 4) {
            this.genaralMangerType = res.data.payload;
            this.requestClass(this.genaralMangerType);
          } else if (val === 5) {
            this.qualityUserType = res.data.payload;
            this.requestClass(this.qualityUserType);
          } else if (val === 0) {
            this.selesAgentType = res.data.payload;
            this.requestClass(this.selesAgentType);
          }
        });
    },

    getSelectedValueManger(val) {
      this.selectedMang = val;
      if (val.length && this.selectedStatusFilter === "استشاري مؤرشف") {
        this.getAllAgents("agent", 0, val);
      } else if (val.length && this.selectedStatusFilter === "استشاري نشط") {
        this.getAllAgents("agent", 1, val);
      } else if (val.length && this.selectedStatusFilter === "الكل") {
        this.getAllAgents("agent", 1, val);
      }
    },

    getTypeRequest(val) {
      this.selectedTypeRequest = val;
    },

    getSelectedValueAgent(val) {
      this.selectAgent = val;
    },

    getSelectedAgentType(val) {
      this.selectAgentType = val;
    },

    getRequestStatus(val) {
      this.selectedRequestStatus = val;
    },

    getSelectedMangerType(val) {
      this.selectedSalesManger = val;
    },

    getSelectedFundingType(val) {
      this.selectedFundingManger = val;
    },

    getMerteryType(val) {
      this.selectMortgageManger = val;
    },

    getGeneralManger(val) {
      this.selectGeneralManger = val;
    },

    getSelectedProductType(val) {
      this.selectedProductType = val;
    },

    getAllManger(role, status) {
      this.allMangers = [];
      let body = { role: role };
      if (status === 0) {
        body = {
          status: status,
          role: role,
        };
      }

      axiosInstance.post("/employee/admin/filterUsers", body).then((res) => {
        this.allMangers = res.data.payload;
        this.allMangers.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      });
    },

    getQualityStatus() {
      axiosInstance
        .get("/employee/quality/quality-request/request/status")
        .then((res) => {
          this.qualityStatus = res.data.payload.map((item) => {
            item["color"] = "red";
            item["text"] = item["status_value"];
            return item;
          });
        });
    },

    getAllAgents(role, status, val) {
      this.allAgents = [];
      let body = new FormData();
      body.append("role", role);
      if (status === 0 && !val) {
        body.append("status", status);
        body.append("role", role);
      } else if (status === 1 && !val) {
        body.append("status", status);
        body.append("role", role);
      }
      if (val) {
        val.forEach((item, index) => {
          this.selectedManger.push(`'manager_id[${index}]': '${item["id"]}'`);
        });
      }

      let removeDublicate = removeDuplicated(this.selectedManger);

      if (removeDublicate.length) {
        val.forEach((item, index) => {
          body.append(`manager_id[${index}]`, item["id"]);
        });
        body.append("status", status);
        body.append("role", role);
      }

      let url = "/employee/admin/filterUsers";
      axiosInstance.post("/employee/admin/filterUsers", body).then((res) => {
        this.allAgents = res.data.payload;
        this.allAgents.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      });
    },

    get_customer() {
      if (document.cookie !== "") {
        axiosInstance.get("/agent/RelatedCustomers").then((response) => {
          if (this.$store.state.agent_customers) {
            this.$store.state.agent_customers = response.data.payload;
          }
        });
      }
    },

    getSupAdmin() {
      axiosInstance.get("/v2/employee/quality-users").then((res) => {
        this.quality = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["name_for_admin"];
          return item;
        });
      });
    },

    get_funding_sources() {
      axiosInstance.get("/agent/Funding/Sources").then((response) => {
        this.fundingSide = response.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["value"];
          return item;
        });
      });
    },

    get_collaborators() {
      this.getting = true;
      axiosInstance
        .get("/agent/Collaborators")
        .then((response) => {
          this.collaborators = response.data.payload.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.getting = false;
        });
    },

    getProductType() {
      axiosInstance.get("/customer/SelectedProduct").then((res) => {
        this.productType = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["value"];
          return item;
        });
      });
    },
  },

  mounted() {
    this.getAllManger("manager");
    this.getAllAgents("agent");
    this.getRequestClassification(0);
    this.getRequestClassification(1);
    this.getRequestClassification(2);
    this.getRequestClassification(3);
    this.getRequestClassification(4);
    this.getRequestClassification(5);
    this.getProductType();
    this.requestStatus();
    this.getRequestSource();
    this.getRequestType();
    this.getSelectedRegion();
    this.getTypeRequestList();
    this.get_work_list();
    this.get_salary_sources();
    this.get_funding_sources();
    this.getSupAdmin();
    this.getQualityStatus();
    this.getRealEstatesTypes();
    this.getRealEstatesCity();
  },
};
</script>

<style>
#adminFilter
  .selectInput
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  border: 2px solid #f7f8fb !important;
  margin-top: 0 !important;
}

#adminFilter .v-text-field > .v-input__control > .v-input__slot {
}

.v-chip--select {
  background-color: #3c5de7 !important;
  color: white !important;
  border-radius: 1% !important;
}

.v-chip__close::before {
  color: white !important;
}

.v-col {
  margin: 0px !important;
}

::-webkit-calendar-picker-indicator {
  left: -22px;
  position: absolute;
  z-index: 1;
  opacity: 0;
}

input[type="date"] {
  text-align: end;
}

.panalContextFilter .col-md-4,
.panalContextFilter .col-md-6,
.panalContextFilter .col-lg-12,
.panalContextFilter .col-md-12 {
  padding-bottom: 0px;
  padding-top: 0px;
}

.theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  color: black !important;
  background: #f7f7f7;
}
</style>
