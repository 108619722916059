<template>
  <v-list>
    <v-list-item @click="to_request_details()">
      <v-list-item-icon class="ml-3">
        <img src="@/assets/icons/titcketIcon.svg" />
      </v-list-item-icon>
      <v-list-item-title>فتح</v-list-item-title>
    </v-list-item>

    <router-link
      style="text-decoration: none"
      target="_blank"
      :to="{
              name: 'quality_request_details',
              params: { id: item.id },
            }"
    >
      <v-list-item>
        <v-list-item-icon class="ml-3">
          <img src="@/assets/icons/titcketIcon.svg" />
        </v-list-item-icon>
        <v-list-item-title> فتح في تاب جديد</v-list-item-title>
      </v-list-item>
    </router-link>

    <v-list-item @click="getRequestCalls()">
      <v-list-item-icon class="ml-3">
        <RequestCallsIcon />
      </v-list-item-icon>
      <v-list-item-title>المكالمات</v-list-item-title>
    </v-list-item>

    <v-list-item @click="to_tickets_page()">
      <v-list-item-icon class="ml-3">
        <img src="@/assets/icons/ticketIcon.svg" />
      </v-list-item-icon>
      <v-list-item-title>التذاكر</v-list-item-title>
    </v-list-item>
    <v-list-item
      v-if="(item.quality_status != 3 && item.quality_status != 5) || !returnWindowPlace"
      @click="add_needToBeTurnedReq()"
    >
      <v-list-item-icon class="ml-2">
        <img src="@/assets/icons/turnBasketIcon.svg" />
      </v-list-item-icon>
      <v-list-item-title>اضافة الي سلة التحويل</v-list-item-title>
    </v-list-item>
    <v-list-item
      v-if="item.quality_status == 3 || item.quality_status == 4"
      @click="restore_request()"
    >
      <v-list-item-icon class="ml-3">
        <img src="@/assets/icons/restore.svg" />
      </v-list-item-icon>
      <v-list-item-title>استرجاع الطلب</v-list-item-title>
    </v-list-item>
    <v-list-item @click="open_questions_dialog()">
      <v-list-item-icon class="ml-3">
        <img src="@/assets/icons/rateQuation.svg" />
      </v-list-item-icon>
      <v-list-item-title>اسئلة التقييم</v-list-item-title>
    </v-list-item>
    <v-list-item v-if="CurrentRole == 0" @click="to_chat_page()">
      <v-list-item-icon class="ml-3">
        <img src="@/assets/icons/userChat.svg" />
      </v-list-item-icon>
      <v-list-item-title>retrive request</v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import { Axios } from "axios";
import { axiosInstance } from "@/axios/config";
import { RequestCallsIcon } from "@/assets/adminIcons/requestCalls";

export default {
  props: ["item"],
  components: { RequestCallsIcon},
  data() {
    return {
      CurrentRole: 0,
    };
  },
  computed: {
    returnWindowPlace() {
      return window.location.pathname === "/quality/requests/completed";
    },
  },
  mounted() {
    this.CurrentRole = window.localStorage.getItem("role");
    this.$root.$on("REFRESHCARDDATA", () => {
      this.get_requests();
    });
  },
  methods: {
    to_tickets_page() {
      if (this.CurrentRole == "1") {
        this.$router.push({ name: "quality_tickets", params: { id: this.item.id } });
      } else {
        this.$router.push({ name: "tickets", params: { id: this.item.id } });
      }
    },
    to_request_details() {
      if (this.CurrentRole == "5") {
        this.$router.push({
          name: "quality_request_details",
          params: { id: this.item.id },
        });
      } else {
        this.$router.push({
          name: "request_details",
          params: { id: this.item.id },
        });
      }
    },

    getRequestCalls(){
      this.$store.state.idOfRequestCalls = this.item.request.id;
      this.$store.state.openCallsDialog = true;
    },

    add_needToBeTurnedReq() {
      let req_id = "";
      req_id = this.item.id;
      // const formData = new FormData();
      // formData.append("request_ids[]", req_id);
      axiosInstance
        .get(`/employee/quality/addNeedToBeTurnedReq/${req_id}`, {
          headers: this.request_headers(),
        })
        .then(() => {
          this.showPopup("success", "تم إضافة الطلب لسلة التحويل");
          setTimeout(() => {
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
            }, 1000);
          }, 2000);
        })

        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },

    restore_request() {
      axiosInstance
        .get(`/employee/quality/restore-request/${this.item.id}`, {
          headers: this.request_headers(),
        })
        .then(() => {
          this.showPopup("success", "تم استرجاع الطلب بنجاح");
          //   this.tableData[i].quality_status = 1;
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },
    open_questions_dialog() {
      this.$store.state.QualityQuestionsDialogReqId = this.item.id;
      this.$store.state.QualityQuestionsDialog = true;
    },
    to_chat_page() {
      this.$router.push({
        name: "chat_component",
        query: {
          customer_name: this.item.customer.name,
          customer_id: this.item.customer.id,
          request_id: this.item.id,
        },
      });
    },
  },
};
</script>
