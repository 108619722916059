<template>
  <div style="box-shadow: 0 0 0 0 !important" class="adminGraph">
    <div v-if="!getDataDone">
      <div style="margin-top: 40px" v-if="!loading">
        <highcharts :options="chartOptions" style="height: 800px"></highcharts>
      </div>
    </div>
    <div v-else class="loader">
      <img src="../../../assets/Comp-2.gif" />
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts-vue";

export default {
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      horizontalData: [],
      verticalData: [],
      chartData: [],
      getDataDone: false,
      loading: false,
      chartOptions: {
        chart: {
          type: "column",
        },
        title: {
          text: "الطلبات الحرة",
        },
        xAxis: {
          min: 0,
          title: {
            text: "الاستشاريين",
          },
          categories: [], 
        },
        yAxis: {
          title: {
            text: "المجموع",
          },
        },
        tooltip: {
          headerFormat: "<b>{point.x}</b><br/>",
          pointFormat:
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
          shared: true,
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "مجموع الطلبات الحرة",
            data: [],
          },
        ],
      },
    };
  },

  methods: {
    getData(loader) {
      if (loader === "getDataDone") {
        this.getDataDone = true;
      } else if (loader === "loading") {
        this.loading = true;
      }
      let url = `${this.$store.state.url}/employee/latestReportChart`;
      Axios.get(url, { headers: this.request_headers() }).then((res) => {
        this.chartData = res.data.payload;
        this.horizontalData = this.chartData.map((item) => item.name);
        this.verticalData = this.chartData.map((item) => item.latest_requests_count);

        // Update the chart data
        this.chartOptions.xAxis.categories = this.horizontalData;
        this.chartOptions.series[0].data = this.verticalData;

        this.loading = false;
        this.getDataDone = false;
      });
    },
  },

  created() {
    this.getData("getDataDone");
  },
};
</script>
