<template>
  <div class="all-requests">
    <quality-search @handleFilter="handleFilter" />
    <HeaderProccess />
    <div
      class="printing mb-3"
      style="
        background: white;
        padding: 4px;
        border-radius: 4px !important;
        margin-bottom: 30px !important;
        height: 52px;
        margin-top: 25px;
        display: flex;
        border: 1px solid #ececec;
        margin-left: 3px;
      "
      :style="items.length <= 0 ? 'display:none' : 'display:flex'"
    >
      <div>
        <v-btn
          depressed
          width="40"
          height="40"
          :color="$vuetify.theme.themes.light.dataActionBTN"
          dark
          fab
          class="ml-1 hidden-sm-and-down"
          @click="exportExcel"
          v-if="!$store.state.cardsStyle"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.116"
            height="18.116"
            viewBox="0 0 12.25 14"
          >
            <g id="excel-file" transform="translate(-1.5)">
              <path
                id="Path_4097"
                data-name="Path 4097"
                d="M11.708,14H1.792a.292.292,0,0,1-.292-.292V3.548a.292.292,0,0,1,.074-.194L4.491.1A.292.292,0,0,1,4.708,0h7A.292.292,0,0,1,12,.292V7a.292.292,0,0,1-.583,0V.583H4.839L2.083,3.659v9.757h9.333V12.25a.292.292,0,1,1,.583,0v1.458A.293.293,0,0,1,11.708,14Z"
                fill="#fff"
              />
              <path
                id="Path_4098"
                data-name="Path 4098"
                d="M4.917,4.292H2.292a.292.292,0,0,1,0-.583H4.625V.792a.292.292,0,0,1,.583,0V4A.292.292,0,0,1,4.917,4.292Zm8.167,8.167h-7a.876.876,0,0,1-.875-.875v-3.5a.876.876,0,0,1,.875-.875h7a.876.876,0,0,1,.875.875v3.5A.876.876,0,0,1,13.083,12.458Zm-7-4.667a.292.292,0,0,0-.292.292v3.5a.292.292,0,0,0,.292.292h7a.293.293,0,0,0,.292-.292v-3.5a.293.293,0,0,0-.292-.292Z"
                transform="translate(-0.208 -0.208)"
                fill="#fff"
              />
              <path
                id="Path_4099"
                data-name="Path 4099"
                d="M11.29,15.67,10.566,14.6h.3a.075.075,0,0,1,.047.012.1.1,0,0,1,.027.03l.572.878a.4.4,0,0,1,.032-.07l.54-.8a.186.186,0,0,1,.03-.036.058.058,0,0,1,.039-.012h.283l-.726,1.052.751,1.139h-.293a.079.079,0,0,1-.053-.017.176.176,0,0,1-.031-.038l-.588-.919a.316.316,0,0,1-.027.061l-.572.858a.21.21,0,0,1-.032.039.065.065,0,0,1-.05.018h-.275l.755-1.125Zm1.754.876h.948V16.8H12.747V14.6h.3Zm2.4-1.6a.117.117,0,0,1-.03.034.059.059,0,0,1-.039.012.111.111,0,0,1-.061-.026q-.042-.031-.086-.058a.805.805,0,0,0-.128-.057.558.558,0,0,0-.18-.026.575.575,0,0,0-.176.027.377.377,0,0,0-.128.073.279.279,0,0,0-.076.109.348.348,0,0,0-.026.134.243.243,0,0,0,.045.152.385.385,0,0,0,.119.1.952.952,0,0,0,.168.074l.193.065c.065.023.131.048.192.075a.658.658,0,0,1,.168.107.486.486,0,0,1,.12.158.515.515,0,0,1,.045.229.735.735,0,0,1-.049.269.632.632,0,0,1-.143.22.672.672,0,0,1-.231.146.849.849,0,0,1-.311.054.956.956,0,0,1-.389-.077.908.908,0,0,1-.3-.209l.086-.14a.107.107,0,0,1,.03-.029.07.07,0,0,1,.038-.012.118.118,0,0,1,.073.034.946.946,0,0,0,.1.076.608.608,0,0,0,.366.107.577.577,0,0,0,.188-.029.378.378,0,0,0,.258-.373.269.269,0,0,0-.045-.163.38.38,0,0,0-.118-.106.832.832,0,0,0-.167-.072l-.192-.061a2.009,2.009,0,0,1-.192-.073.608.608,0,0,1-.167-.109.484.484,0,0,1-.118-.164.571.571,0,0,1-.045-.244.579.579,0,0,1,.177-.418.622.622,0,0,1,.211-.134.75.75,0,0,1,.29-.05.921.921,0,0,1,.335.058.773.773,0,0,1,.264.169l-.072.141Z"
                transform="translate(-3.765 -6.075)"
                fill="#fff"
              />
            </g>
          </svg>
        </v-btn>
        <v-btn
          depressed
          width="42"
          height="42"
          @click="printNow"
          :color="$vuetify.theme.themes.light.dataActionBTN"
          fab
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.116"
            height="18.116"
            viewBox="0 0 16.116 16.116"
          >
            <g
              id="Icon_feather-printer"
              data-name="Icon feather-printer"
              transform="translate(-2.5 -2.5)"
            >
              <path
                id="Path_4011"
                data-name="Path 4011"
                d="M9,8.291V3h9.069V8.291"
                transform="translate(-2.977)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
              <path
                id="Path_4012"
                data-name="Path 4012"
                d="M6.023,20.3H4.512A1.512,1.512,0,0,1,3,18.791V15.012A1.512,1.512,0,0,1,4.512,13.5H16.6a1.512,1.512,0,0,1,1.512,1.512v3.779A1.512,1.512,0,0,1,16.6,20.3H15.093"
                transform="translate(0 -5.209)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
              <path
                id="Path_4013"
                data-name="Path 4013"
                d="M9,21h9.069v6.046H9Z"
                transform="translate(-2.977 -8.931)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
          </svg>
        </v-btn>
        <v-spacer class="top-spacer" />
      </div>
    </div>
    <div class="table__view">
      <table-component
        :showSelect="false"
        :pagination="pagination"
        itemKey="id"
        :selectedRows.sync="selected_requests"
        @refresh="get_all_requests"
        :headers="headers"
        :items="items"
        :loading="loading"
        v-model="page"
        v-if="!$store.state.cardsStyle"
      >
        <!-- eslint-disable -->
        <template v-slot:[`item.action`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#3f0e40" depressed small dark v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <SingleProccess :item="item" />
          </v-menu>
        </template>
        <template slot="item.agent_date" slot-scope="props">
          <div>{{ props.item.request.agent_date.split(" ")[0] }}</div>
          <div>{{ props.item.request.agent_date.split(" ")[1].split(".")[0] }}</div>
        </template>
        <template slot="item.agent_notes" slot-scope="props">
          <span v-if="!props.item.request.agent_notes"></span>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{
                props.item.request.agent_notes.substring(0, 25) + "..."
              }}</span>
            </template>
            <div class="font-weight-bold">{{ props.item.request.agent_notes }}</div>
          </v-tooltip>
        </template>
        <template slot="item.quality_notes" slot-scope="props">
          <span v-if="!props.item.request.quality_notes"></span>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{
                props.item.request.quality_notes.substring(0, 25) + "..."
              }}</span>
            </template>
            <div class="font-weight-bold">{{ props.item.request.quality_notes }}</div>
          </v-tooltip>
        </template>
        <template slot="item.source" slot-scope="props">
          {{ props.item.request.source_text
          }}{{
            props.item.request.source == 2
              ? ` - ${props.item.request?.collaborator_name}`
              : ""
          }}
        </template>
        <template slot="item.customer_name" slot-scope="props">
          {{ props.item.request.customer?.name }}
        </template>
        <template slot="item.customer_mobile" slot-scope="props">
          {{ props.item.request.customer.mobile }}
        </template>

        <template slot="item.agent_name" slot-scope="props">
          {{ props.item.request.user?.name }}
        </template>
        <template slot="item.req_date" slot-scope="props">
          {{ props.item.request.req_date }}
        </template>
        <template slot="item.req_id" slot-scope="props">
          {{ props.item.request.id }}
        </template>
        <template slot="item.created_at" slot-scope="props">
          {{ props.item.created_at }}
        </template>
        <template slot="item.quality_status" slot-scope="props">
          {{ props.item.status }}
        </template>
        <template slot="item.status" slot-scope="props">
          {{ props.item.request.status }}
        </template>
        <template slot="item.quality_notes" slot-scope="props">
          <v-text-field
            @focusout="change_comment(props.item.id, props.item.request.id, $event)"
            @keyup.enter="change_comment(props.item.id, props.item.request.id, $event)"
            style="width: 15rem"
            hide-details
            class="mr-1"
            outlined
            dense
            :value="props.item.request.quality_notes"
          ></v-text-field>
        </template>
        <template slot="item.quality_classification_text" slot-scope="props">
          <v-autocomplete
            style="background-color: white; width: 10rem"
            :items="$store.state.request_class"
            item-text="value"
            item-value="id"
            hide-details
            :value="props.item.request.class_id_quality"
            class="mr-1 class_id"
            outlined
            dense
            append-icon
            @change="change_classification(props.item.id, props.item.request.id, $event)"
          >
            <template v-slot:append>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11.532"
                height="6.328"
                viewBox="0 0 11.532 6.328"
                style="margin-top: 8px"
              >
                <path
                  id="Icon_ionic-ios-arrow-down"
                  data-name="Icon ionic-ios-arrow-down"
                  d="M11.956,15.667l4.361-4.188a.845.845,0,0,1,1.164,0,.775.775,0,0,1,0,1.12L12.54,17.344a.848.848,0,0,1-1.136.023L6.428,12.6a.773.773,0,0,1,0-1.12.845.845,0,0,1,1.164,0Z"
                  transform="translate(-6.188 -11.246)"
                  fill="#6c757d"
                />
              </svg> </template
          ></v-autocomplete>
        </template>
      </table-component>
    </div>

    <div v-if="!cardLoading">
      <card-style :tableData="items" @pageCount="handlePageNumber" />
      <div>
        <LoadingMore v-if="loadMoreData" style="margin: 40px" />
      </div>
    </div>
    <div v-else><loading-more /></div>

    <!-- <AddNewRequestBtn /> -->
    <quality-questions-dialog />
    <AddNewRequestBtn />
    <CallsDialog />
  </div>
</template>

<script>
import Axios from "axios";
import HeaderProccess from "@/views/quality/HeaderProccess";
import QualitySearch from "@/views/quality/QualitySearch.vue";
import QualityQuestionsDialog from "@/components/Dialogs/QualityQuestionsDialog.vue";
import { axiosInstance } from "@/axios/config";
import CardStyle from "@/views/quality/CardStyle.vue";
import LoadingMore from "@/components/Charts/LoadingMore.vue";
import SingleProccess from "@/views/quality/AllRequests/SingleProccess.vue";
import AddNewRequestBtn from "@/components/Charts/AddNewRequestBtn";
import CallsDialog from "@/components/Charts/CallsDialog.vue";
export default {
  name: "HelloWorld",
  components: {
    HeaderProccess,
    QualitySearch,
    QualityQuestionsDialog,
    CardStyle,
    LoadingMore,
    SingleProccess,
    AddNewRequestBtn,
    CallsDialog,
  },
  data: () => ({
    loading: false,
    cardLoading: false,
    pagination: {},
    loadMoreData: false,
    headers: [
      { text: "تاريخ الطلب", value: "req_date", width: "140px", align: "center" },
      {
        text: "تاريخ الطلب عند الجودة ",
        value: "created_at",
        width: "160px",
        align: "center",
      },
      { text: "اسم الاستشاري", value: "agent_name", width: "140px", align: "center" },
      { text: "عميل", value: "customer_name", width: "120px", align: "center" },
      { text: "الجوال", value: "customer_mobile", width: "140px", align: "center" },
      {
        text: "حالة الطلب  عند الجودة",
        value: "quality_status",
        width: "170px",
        align: "center",
      },
      {
        text: "حالة الطلب  عند الاستشاري",
        value: "status",
        width: "170px",
        align: "center",
      },
      {
        text: "ملاحظة استشاري المبيعات",
        value: "agent_notes",
        width: "170px",
        align: "center",
      },
      {
        text: "تصنيف الإستشاري",
        value: "class_id_agent_text",
        width: "170px",
        align: "center",
      },
      {
        text: "ملاحظة استشاري الجودة",
        value: "quality_notes",
        width: "170px",
        align: "center",
      },
      {
        text: "تصنيف الجودة",
        value: "quality_classification_text",
        width: "170px",
        align: "center",
      },
      { text: "رقم الطلب", value: "req_id", align: "center" },
      { text: " العمليات ", value: "action", align: "center" },
    ],
    page: 1,
    /* eslint-disable */
    items: [],
    addNewRequestDialog: false,
    selected_requests: [],
    isSearched: false,
    searchObject: {},
  }),
  watch: {
    page(val) {
      if (!this.isSearched) {
        this.get_all_requests(val);
      } else {
        this.items = [];
        this.loading = true;
        this.handleFilter(this.$store.state.qualityObjFilter, val);
      }
    },
    selected_requests(val) {
      this.$emit("requests", val);
    },
  },

  methods: {
    handlePageNumber(val) {
      if (!this.isSearched) {
        this.get_all_requests(val);
      } else {
        this.handleFilter(this.$store.state.qualityObjFilter, val);
      }
    },
    handleFilter(searchObj, page = 1) {
      if (page === 1) {
        this.items = [];
        this.loading = true;
        this.cardLoading = true;
      }
      this.isSearched = true;

      let url = `/v2/employee/Requests/search?type=all&page=${page}`;

      const body = new FormData();
      const val = searchObj;
      for (let key in val) {
        if (val[key] !== null) {
          if (Array.isArray(val[key])) {
            val[key].map((item, index) => {
              if (key === "quality_task_status" || key === "quality_request_status") {
                body.append(`${key}[${index}]`, item.status_key);
              } else {
                if (item.id) {
                  body.append(`${key}[${index}]`, item.id);
                } else {
                  body.append(`${key}[${index}]`, item.value);
                }
              }
            });
          } else {
            if (val[key] === "الفارغه") {
              body.append(`${key}[0]`, 0);
            } else if (val[key] === "تحتوي علي محتوي") {
              body.append(`${key}[0]`, 1);
            } else {
              body.append(key, val[key]);
            }
          }
        }
      }
      axiosInstance.post(url, body).then((res) => {
        this.items.push(...res.data.payload.data);
        this.pagination = {
          limit: 12,
          count: res.data.payload.searched,
        };
        this.loading = false;
        this.cardLoading = false;
      });
    },

    async get_all_requests(page = 1) {
      this.loading = true;
      if (!this.items.length) {
        this.cardLoading = true;
      }
      this.loadMoreData = true;
      await Axios.get(
        `https://appproduct.alwsata.com.sa/api/v2/employee/Requests?type=all&page=${page}&per_page=12`,
        {
          headers: this.request_headers(),
        }
      ).then((response) => {
        this.items.push(...response.data.payload.data);
        this.pagination = {
          limit: 12,
          count: response.data.payload.count_of_all,
        };
        this.loading = false;
        this.cardLoading = false;
        this.loadMoreData = false;
      });
    },

    exportExcel() {
      const table = document.querySelector(".all-requests table");
      this.exportTableToCsv(table, "جميع الطلبات");
    },
    printNow() {
      this.items.forEach((data) => {
        data.customer_name = data.customer.name;
      });
      this.printTable(this.items, this.headers, "جدول جميع الطلبات");
    },

    change_comment(quality_id, req_id, val) {
      let update_payload = {
        id: parseInt(quality_id),
        request_id: req_id,
        quality_comment: val.target.value,
      };
      Axios.post(
        "https://appproduct.alwsata.com.sa/api/v2/employee/Requests",
        update_payload,
        { headers: this.request_headers() }
      )
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          // work here
          this.showPopupError("success", err.response.data.message[0].split(","));
        });
    },
    change_classification(quality_id, req_id, val) {
      let update_payload = {
        id: parseInt(quality_id),
        request_id: req_id,
        // quality_comment: val,
        class_quality: val,
      };
      Axios.post(
        "https://appproduct.alwsata.com.sa/api/v2/employee/Requests",
        update_payload,
        { headers: this.request_headers() }
      )
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          // work here
          this.showPopupError("success", err.response.data.message[0].split(","));
        });
    },
  },
  async created() {
    await this.get_all_requests();
  },
};
</script>
