<template>
  <v-list>

    <v-list-item @click="to_request_details()">
      <v-list-item-icon class="ml-3">
        <img src="@/assets/icons/titcketIcon.svg" />
      </v-list-item-icon>
      <v-list-item-title>فتح</v-list-item-title>
    </v-list-item>

    <v-list-item @click="getRequestCalls()">
      <v-list-item-icon class="ml-3">
        <RequestCallsIcon />
      </v-list-item-icon>
      <v-list-item-title>المكالمات</v-list-item-title>
    </v-list-item>

    <v-list-item @click="to_tickets_page()">
      <v-list-item-icon class="ml-3">
        <img src="@/assets/icons/ticketIcon.svg" />
      </v-list-item-icon>
      <v-list-item-title>التذاكر</v-list-item-title>
    </v-list-item>

    <v-list-item @click="open_questions_dialog()">
      <v-list-item-icon class="ml-3">
        <img src="@/assets/icons/rateQuation.svg" />
      </v-list-item-icon>
      <v-list-item-title>اسئلة التقييم</v-list-item-title>
    </v-list-item>

    <v-list-item v-if="item.status === 'طلب جديد' " @click="removeRequestFromNeedToBeTurnedReq">
      <v-list-item-icon class="ml-3">
        <img src="@/assets/icons/rateQuation.svg" />
      </v-list-item-icon>
      <v-list-item-title>ازالة الطلب من سلة التحويل</v-list-item-title>
    </v-list-item>

  </v-list>
</template>

<script>
import { Axios } from "axios";
import { axiosInstance } from "@/axios/config";
import { RequestCallsIcon } from "@/assets/adminIcons/requestCalls";

export default {
  props: ["item"],
  components: { RequestCallsIcon},
  data() {
    return {
      CurrentRole: 0,
    };
  },
  computed: {
    returnWindowPlace() {
      return window.location.pathname === "/quality/requests/completed";
    },
  },
  mounted() {
    this.CurrentRole = window.localStorage.getItem("role");
    this.$root.$on("REFRESHCARDDATA", () => {
      this.get_requests();
    });
  },
  methods: {
    to_tickets_page() {
        this.$router.push({ name: "quality_tickets", params: { id: this.item.quality_request?.id } });
        // this.$router.push({ name: "tickets", params: { id: this.item.quality_request?.id } });
    },
    to_request_details() {
      if (this.CurrentRole == "5") {
        this.$router.push({
          name: "quality_request_details",
          params: { id: this.item.quality_request?.id },
        });
      } else {
        this.$router.push({
          name: "request_details",
          params: { id: this.item.id },
        });
      }
    },

    getRequestCalls(){
      this.$store.state.idOfRequestCalls = this.item.request.id;
      this.$store.state.openCallsDialog = true;
    },

    removeRequestFromNeedToBeTurnedReq() {
      axiosInstance
        .get(`/employee/quality/removeNeedToBeTurnedReq/${this.item.id}`, {
          headers: this.request_headers(),
        })
        .then(() => {
          this.showPopup("success", "تم حذف الطلب بنجاح");
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },
    open_questions_dialog() {
      this.$store.state.QualityQuestionsDialogReqId = this.item.quality_request?.id;
      this.$store.state.QualityQuestionsDialog = true;
    },
    to_chat_page() {
      this.$router.push({
        name: "chat_component",
        query: {
          customer_name: this.item.customer.name,
          customer_id: this.item.customer.id,
          request_id: this.item.id,
        },
      });
    },
  },
};
</script>
