<template>
  <div>
    <v-btn
        @click="$store.state.search_admin = true"
      fab
      class="mx-2 pa-0 genaralIcons"
      small
      style="left: 80px"
      :color="$vuetify.theme.themes.light.BtnBKColor"
      depressed
      width="35"
      height="35"
    >
      <SearchIcon />
    </v-btn>

    <v-divider
      style="
        background-color: rgb(180, 188, 217);
        transform: translateX(5px);
        position: absolute;
        height: 35px !important;
        max-height: 100% !important;
        min-height: 0;
        bottom: 14%;
        left: 130px;
        top: -50px;
        min-width: unset;
        z-index: 5;
      "
      vertical
    />

    <v-btn
      fab
      class="mx-2 pa-0 genaralIcons table-style active-table-style"
      icon
      color="#ffffff"
      @click="handleTableDesign"
      @focus="toggleTableStyle"
      depressed
      width="35"
      height="35"
      style="left: 140px"
    >
     <TableDesign />
    </v-btn>
   
    <template>
      <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            width="auto"
        >
          <v-card>
            <v-card-title class="text-h5">

            </v-card-title>
            <v-card-text>
              {{messages}}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click="dialog = false"
              >
                الغاء
              </v-btn>
              <v-btn
                  color="green-darken-1"
                  variant="text"
                  @click=sureToArchive
              >
                متاكد
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <TransferFilterDialog :selectedItems="selectedItems" :type="type"/>

    <template>
      <v-row justify="center">
        <v-dialog
            v-model="dialogQuality"
            persistent
            width="624"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5" style="margin-bottom: 30px">نقل الطلب الي الجوده</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <SelectInput
                        @selectedValues="getSelectedValueManger"
                        :originalItems="qualityUsers"
                        label="الجوده"
                    />
                  </v-col>

                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue-darken-1"
                  variant="text"
                  @click="dialogQuality = false"
              >
                الغاء
              </v-btn>
              <v-btn
                  color="blue-darken-1"
                  variant="text"
                  @click="handleSendUserToAgent"
              >
                تحويل
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>


    <v-snackbar v-model="active" :color="snackColor" timeout="-1" right bottom class="ml-4" elevation="24"
                transition="fade-transition">

      <span class="font-weight-bold" style="font-size: 16px">{{ snackMessage }}</span>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<style lang="scss">
.genaralIcons {
  position: absolute;
  top: -50px;
  min-width: unset;
  z-index: 5;
}

@media screen and (max-width: 626px) {
  .genaralIcons{
    top: -10px;
  }
}

.table-style svg {
  opacity: 0.4;

  &.active-table-style {
    opacity: 1;
  }
}
</style>

<script>
import Axios from "axios";
import TransferFilterDialog from "@/components/AdminComponent/TransferFilterDialog.vue";
import SelectInput from "@/components/Charts/SelectInput.vue";
import {removeDuplicated} from "@/mixins/removeDublicated";
import {qualityUser} from "@/mixins/qualityUser";
import {SearchIcon} from "@/assets/adminIcons/searchIcon";
import {TableDesign} from "@/assets/adminIcons/tableDesign";

export default {
  name: "HeaderIcons",
  components: { SelectInput, TransferFilterDialog, SearchIcon, TableDesign },
  props: ['selectedItems' , 'startItems' , "type"],
  data(){
    return{
      qualityUsers: [],
      active:false,
      AllSelectedTable: [],
      dialog: false,
      message: '',
      snackColor: '',
      messages: 'هل انت متاكد من رغبتك في ارشفت هذه الطلبات',
      allStatus: [],
      dialogQuality: false,
      selectedQuality: [],
      needToAction: [],
      snackMessage: '',
    }
  },
  computed:{
    showFeaturedProcess(){
     return window.location.pathname === "/admin/requests/featured"
    }
  },
  methods: {
    handleTableDesign(){
      this.$store.state.showCards = false

    },

    handleSendUserToAgent(){
      this.dialogQuality = false

      let url = 'https://appproduct.alwsata.com.sa/api/v2/employee/Requests/moveToQualityReqArray'
      let body = new FormData();

      this.selectedQuality.forEach((item, index) => {
        body.append(`quality[${index}]`, item["id"]);
      });

      this.selectedItems.length && this.selectedItems.forEach((item, index) => {
        body.append(`request_ids[${index}]`, item["id"]);
      });

      this.$store.state.startElement.length && this.$store.state.startElement.forEach((item, index) => {
        body.append(`request_ids[${index}]`, item["id"]);
      });

      Axios.post(url , body , {headers: this.request_headers()}).then(res => {
        this.snackMessage = res.data.message
        this.snackColor = 'green'
        this.active = true
      }).catch(err => {
        let error = { err };
        this.snackMessage = error.err.response.data.message
        this.snackColor = 'red'
        this.active = true

      })
    },

    getSelectedValueManger(val){
      this.selectedQuality = val
    },
    async convertRequest() {
      if(this.selectedItems.length || this.$store.state.startElement.length > 0) {
        this.dialogQuality = true;

        await qualityUser().then(res => {
          this.qualityUsers = res.data.payload.map((item) => {
            item["color"] = "red";
            item["text"] = item["name_for_admin"] || item['name'];
            return item;
          })
        })
      }
    },

    moveToNeedAction(){
      if(this.selectedItems.length || this.$store.state.startElement.length > 0){
        this.messages = 'هل انت متأكد من تحويل هذه الطلبات'
        this.dialog = true
      }
    },

    moveToFreez(){
      if(this.selectedItems.length || this.$store.state.startElement.length > 0){
        this.messages = '  هل انت متأكد من تحويل هذه الطلبات الي سله التجميد'
        this.dialog = true
      }
    },

    handleTurnuser(){
      if(this.selectedItems.length || this.$store.state.startElement.length > 0) {
        this.$store.state.turnuserDialog = true;
      }
    },

    handleTransferFilter(){
      if(this.selectedItems.length || this.$store.state.startElement.length > 0) {
        this.$store.state.TransferFilterDialog = true;
      }
    },
    

    toggleTableStyle(e) {
      document.querySelectorAll(".table-style").forEach((btn) => {
        btn.querySelector("svg").classList.remove("active-table-style");
      });
      e.target.querySelector("svg").classList.add("active-table-style");
    },

    requestArchived(){
      if(this.selectedItems.length || this.$store.state.startElement.length > 0){
        this.dialog = true
      }
    },

    sureToArchive(){
      if(this.messages === 'هل انت متأكد من تحويل هذه الطلبات')
      {
        this.dialog = false
        let url = 'https://appproduct.alwsata.com.sa/api/v2/employee/Requests/moveToNeedActionReqArray';
        let body = new FormData();
        this.selectedItems.length > 0 && this.selectedItems.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

        this.$store.state.startElement.length > 0 && this.$store.state.startElement.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

        Axios.post(url , body , {headers: this.request_headers()}).then(res => {
          this.snackMessage = res.data.message
          this.snackColor = 'green'
          this.active = true

        }).catch(err => {
          let error = { err };
          this.snackMessage = error.err.response.data.message
          this.snackColor = 'red'
          this.active = true

        })
      }
      else if (this.messages === 'هل انت متاكد من رغبتك في ارشفت هذه الطلبات')
      {

      let url = 'https://appproduct.alwsata.com.sa/api/v2/employee/Requests/Archive ';
      let body = new FormData();

        this.selectedItems.length && this.selectedItems.forEach((item, index) => {
          body.append(`requests_ids[${index}]`, item["id"]);
        });

        this.$store.state.startElement.length && this.$store.state.startElement.forEach((item, index) => {
          body.append(`requests_ids[${index}]`, item["id"]);
        });

        this.dialog = false

      Axios.post(url , body ,{headers: this.request_headers()}).then(res => {
        this.snackMessage = res.data.payload
        this.snackColor = 'green'
        this.active = true

      }).catch(err => {
        let error = { err };
        this.snackMessage = error.err.response.data.message
        this.snackColor = 'red'
        this.active = true
      })
      }
      else if (this.messages === '  هل انت متأكد من تحويل هذه الطلبات الي سله التجميد')
      {
        this.dialog = false
        let url = 'https://appproduct.alwsata.com.sa/api/v2/employee/Requests/transferToFreezeReqArray';
        let body = new FormData();
        this.selectedItems.length && this.selectedItems.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

        this.$store.state.startElement.length && this.$store.state.startElement.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

        Axios.post(url , body , {headers: this.request_headers()}).then(res => {
          this.snackMessage = res.data.message
          this.snackColor = 'green'
          this.active = true
          this.$emit('getData')
        }).catch(err => {
          let error = { err };
          this.snackMessage = error.err.response.data.message
          this.snackColor = 'red'
          this.active = true
        })
      }
    },
  },
};
</script>
