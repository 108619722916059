<template>
  <div>
    <HeaderIcons
      :selectedItems="selectedItems"
      type="moveAllRequests"
      @getData="reloadPage"
    />

    <div v-if="!this.$store.state.allLoading" style="margin-top: 40px">
      <div v-if="$store.state.views === 'cards'">
        <div v-if="this.$store.state.allData.length > 0" style="margin-top: 40px">
          <RequestsCard
            :CardData="this.$store.state.allData"
            @back="handleBack"
            v-if="!this.$store.state.allLoading"
            @pageCount="handlePageNumber"
            @getUsersName="getUsersName"
          />
          <div>
            <LoadingMore v-if="loadMoreData" style="margin: 40px" />
          </div>
        </div>
        <div v-else>
          <div>
            <button @click="handleBack" class="button__back">رجوع</button>
          </div>
          <div class="noData">لا يوجد بيانات</div>
        </div>
      </div>

      <div v-else-if="$store.state.views === 'table'" class="table__view">
        <v-col cols="4" sm="4" md="4">
          <v-text-field
            v-model="search"
            label="أبحث باسم العميل او الملاحظة"
            prepend-inner-icon="mdi-magnify"
            hide-details
            single-line
            dense
            height="30"
            class="text-center"
            style="border: 1px solid !important;"
            @blur="handleSearchFilter"
          ></v-text-field>
        </v-col>


        <!-- <v-col cols="12" sm="6" md="4">
          <v-autocomplete
            :items="customers"
            label="أبحث باسم العميل او الملاحظة"
            v-model="search"
            outlined
            dense
            @blur="handleSearchFilter"
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-title
                  :id="attrs['aria-labelledby']"
                  v-text="item.name"
                ></v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col> -->

        <table-component
          :showSelect="true"
          itemKey="id"
          :selectedRows.sync="selected_requests"
          :headers="headers"
          :pagination="this.$store.state.pagination"
          :limit="$store.state.counterOfShow"
          :items="this.$store.state.tableData"
          :loading="loading"
          @refresh="getTableData"
          v-model="page"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="white" small depressed dark v-bind="attrs" v-on="on">
                  <v-icon style="background: rgb(63, 14, 64); border-radius: 4px"
                    >mdi-dots-vertical</v-icon
                  >
                </v-btn>
              </template>
              <v-list>
                <AllSingleProcess :item="item" @getUsersName="getUsersName" />
              </v-list>
            </v-menu>
          </template>

          <template slot="item.quality_notes" slot-scope="props">
            <span v-if="!props.item.quality_notes"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.quality_notes.length > 7
                    ? props.item.quality_notes.substring(0, 7) + "..."
                    : props.item.quality_notes
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.quality_notes }}</div>
            </v-tooltip>
          </template>

          <template slot="item.source" slot-scope="props">
            <span v-if="!props.item.source"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.source.length > 5
                    ? props.item.source.substring(0, 5) + "..."
                    : props.item.source
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.source }}</div>
            </v-tooltip>
          </template>

          <template slot="item.type" slot-scope="props">
            <span v-if="props.item.type !== 'لا_يوجد'">{{ props.item.type }}</span>
            <span v-else style="color: red">{{ props.item.type }}</span>
          </template>

          <template slot="item.status" slot-scope="props">
            <span v-if="props.item.status?.includes('مرفوض ')" class="faild__request">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{
                    props.item.status.length > 7
                      ? props.item.status.substring(0, 7) + ".."
                      : props.item.status
                  }}</span>
                </template>
                <div class="font-weight-bold">{{ props.item.status }}</div>
              </v-tooltip>
            </span>
            <span
              v-else-if="
                props.item.status?.includes('طلب جديد') ||
                props.item.status?.includes('طلب تم فتحه')
              "
              class="new__request"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{
                    props.item.status.length > 8
                      ? props.item.status.substring(0, 8) + ".."
                      : props.item.status
                  }}</span>
                </template>
                <div class="font-weight-bold">{{ props.item.status }}</div>
              </v-tooltip>
            </span>

            <span
              v-else-if="
                props.item.status?.includes('مؤرشف') ||
                props.item.status?.includes('مكتمل')
              "
              class="archive__request"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{
                    props.item.status.length > 7
                      ? props.item.status.substring(0, 7) + ".."
                      : props.item.status
                  }}</span>
                </template>
                <div class="font-weight-bold">{{ props.item.status }}</div>
              </v-tooltip>
            </span>
            <span v-else class="anotherTypes__request">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{
                    props.item.status.length > 8
                      ? props.item.status.substring(0, 8) + ".."
                      : props.item.status
                  }}</span>
                </template>
                <div class="font-weight-bold">{{ props.item.status }}</div>
              </v-tooltip>
            </span>
          </template>

          <template slot="item.agent_notes" slot-scope="props">
            <span v-if="!props.item.agent_notes"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.agent_notes.length > 7
                    ? props.item.agent_notes.substring(0, 7) + "..."
                    : props.item.agent_notes
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.agent_notes }}</div>
            </v-tooltip>
          </template>
        </table-component>
        <OrderRequestSection
          v-if="this.$store.state.openOrderRequestSection"
          style="margin-top: 50px"
        />
      </div>

      <div v-else-if="$store.state.views === 'default'">
        <div v-if="this.$store.state.allData.length > 0" style="margin-top: 40px">
          <DefaultView
            :CardData="this.$store.state.allData"
            @back="handleBack"
            v-if="!this.$store.state.allLoading"
            @pageCount="handlePageNumber"
            @getUsersName="getUsersName"
          />
          <div>
            <LoadingMore v-if="loadMoreData" style="margin: 40px" />
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
      "
      v-else
    >
      <LoadingMore />
    </div>
    <v-snackbar
      v-model="active"
      color="#40BE72"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <span class="font-weight-bold" style="font-size: 16px">{{ message }}</span>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <AdminDialog @selectedValue="selectedValue" :items="allStatus" :label="label" />
    <AdministrationDialog @selectedAdministrationValue="selectedAdministrationValue"/>
    <FilterDialogInAdmin pageName="all" />
    <OrderRequestDialog />
    <CallsDialog />
  </div>
</template>

<script>
import RequestsCard from "@/components/Charts/RequestsCard.vue";
import LoadingMore from "@/components/Charts/LoadingMore.vue";
import HeaderIcons from "@/components/Charts/HeaderIcons.vue";
import AdminDialog from "@/components/AdminComponent/AdminDialog.vue";
import AdministrationDialog from "@/components/AdminComponent/AdministrationDialog.vue";
import FilterDialogInAdmin from "@/components/Charts/FilterDialogInAdmin.vue";
import CallsDialog from "@/components/Charts/CallsDialog.vue";
import { formatDate } from "@/mixins/formatDate";
import { SourceValue } from "@/mixins/sourceValue";
import OrderRequestDialog from "@/components/AdminComponent/OrderRequestDialog.vue";
import OrderRequestSection from "@/components/AdminComponent/OrderRequestSection.vue";
import AllSingleProcess from "@/views/admin/Requests/AllRequests/AllSingleProcess.vue";
import { axiosInstance } from "@/axios/config";
import DefaultView from "@/components/Base/DefaultView.vue";

export default {
  name: "AdminSupport",
  components: {
    OrderRequestDialog,
    OrderRequestSection,
    FilterDialogInAdmin,
    AdminDialog,
    AdministrationDialog,
    RequestsCard,
    LoadingMore,
    HeaderIcons,
    AllSingleProcess,
    CallsDialog,
    DefaultView,
  },

  data() {
    return {
      allGetData: [],
      customers: [],
      search:'',
      selected_requests: [],
      pagination: {
        limit: 3,
        count: this.$store.state.searchedCount,
      },
      page: 1,
      selectedRows: [],
      allData: [],
      active: false,
      message: "",
      showSelect: { default: false },
      copySupportRequests: [],
      loadMoreData: false,
      count: null,
      loading: false,
      selectId: null,
      allStatus: [],
      label: "",
      selectedItems: [],
      countPage: 1,
      listenToFreezeAction: this.$store.state.freezeActionIsDone,
      headers: [
        { align: "center", value: "data-table-select" },
        { text: "تاريخ الطلب", value: "created_at", width: "107px", align: "center" },
        { text: "نوع الطلب", value: "type", align: "center" },
        {
          text: "استشاري المبيعات",
          value: "استشاري المبيعات",
          align: "center",
        },
        { text: "عميل", value: "عميل", align: "center" },
        { text: "الجوال", value: "الجوال", align: "center" },
        { text: "حالة الطلب", value: "status", width: "108px", align: "center" },
        {
          text: "مصدر المعامله",
          value: "source",
          align: "center",
        },
        {
          text: "تصنيف الاستشاري",
          value: "تصنيف الاستشاري",
          align: "center",
        },
        {
          text: "ملاحظه الاستشاري ",
          value: "agent_notes",
          width: "110px",
          align: "center",
        },
        {
          text: "ملاحظة الجوده",
          value: "quality_notes",
          width: "110px",
          align: "center",
        },
        {
          text: "تاريخ الاستشاري",
          value: "agent_date",
          width: "107px",
          align: "center",
        },
        {
          text: "استلام الجودة",
          value: "هل تم استلامه من قبل الجوده",
          align: "center",
        },
        {
          text: "تم التحديث ",
          value: "updated_at",
          width: "107px",
          align: "center",
        },
        { text: "", value: "action", align: "center" },
      ],
    };
  },

  watch: {
    page(val) {
      if (this.$store.state.isSearch === false && this.search == null) {
        this.getTableData(val);
      } else {
        this.searchRequest(val, "table");
      }
    },

    // search(newval,oldval){
    //   if(newval != oldval){
    //     // this.$store.state.bodySearch.delete("CustomerNameOrComment");
    //     // this.$store.state.bodySearch.append("CustomerNameOrComment", val);
    //     this.searchRequest(this.page, "table");
    //   }
    // },

    selected_requests(val) {
      this.selectedItems = val;
      this.$emit("requests", val);
    },
    


    "$store.state.reloaddata_after_action": {
      handler(newval, oldvalue) {
        console.log("reload_after_action changed")
        if (newval.action == 'admin_all_requests') {
          this.searchRequest(1, newval.view_type);
        }
      },
      immediate: true,
      deep: true 
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval !== oldvalue && !this.$store.state.admin_search_active) {
          this.getTableData(this.page);
        }
      },
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },
  },

  beforeDestroy() {
    this.$store.state.openOrderRequestSection = false;
  },

  methods: {
    getUsersName(users, label, requestId) {
      this.allStatus = users;
      this.label = label;
      this.selectId = requestId;
    },

    handleSearchFilter(){
      if(this.search != null && this.search != '' && this.search != undefined){
        this.$store.state.bodySearch.delete("CustomerNameOrComment");
        this.$store.state.bodySearch.append("CustomerNameOrComment", this.search);
        this.$store.state.CustomerNameOrComment = this.search;
      }else{
        this.$store.state.bodySearch.delete("CustomerNameOrComment");
        this.$store.state.CustomerNameOrComment = '';
      }
      this.searchRequest(this.page, "table");
    },

    handleBack() {
      this.$store.state.allData = [];
      this.$store.state.tableData = [];
      this.allGetData = [];
      this.$store.state.followLoading = true;
      this.$store.state.isSearch = false;
      this.$store.state.back = false;
      this.getAllRequests(1);
    },

    reloadPage() {
    console.log("reloadPage emited");
      this.$store.state.allData = [];
      this.$store.state.tableData = [];
      this.allGetData = [];
      this.$store.state.followLoading = true;
      this.$store.state.isSearch = false;
      this.getAllRequests(1);
      this.$store.state.startElement = [];
    },

    getAgentRequest(itemSelected) {
      let body = new FormData();

      body.append("request_ids[0]", this.selectId);
      body.append("agents_ids[0]", itemSelected.id);
      body.append("move_reason", itemSelected.move_reason);

      axiosInstance
        .post(`/v2/employee/Requests/moveAllRequests`, body, {
          headers: this.request_headers(),
        })
        .then((res) => {
          this.active = true;
          this.message = res.data.message;
        })
        .catch((err) => {
          this.active = true;
          let error = { err };
          this.message = error.err.response.data.message;
        });
    },

    sendRequestToAdministration(itemSelected) {
      console.log('sendRequestToAdministration' + itemSelected)
      let body = new FormData();
      body.append("administration_comment", itemSelected);

      axiosInstance
        .post(`/v2/employee/Requests/moveToAdministration/${this.$store.state.administrationRequestId}`, body, {
          headers: this.request_headers(),
        })
        .then((res) => {
          this.active = true;
          this.message = res.data.message;
        })
        .catch((err) => {
          this.active = true;
          let error = { err };
          this.message = error.err.response.data.message;
        });
    },

    selectedValue(itemSelected) {
      if (itemSelected.role === 5) {
        this.getQualityValues(itemSelected);
      } else if (itemSelected.role === 0) {
        this.getAgentRequest(itemSelected);
      }
    },

    selectedAdministrationValue(itemSelected) {
      this.sendRequestToAdministration(itemSelected);
    },

    getQualityValues(itemSelected) {
      let body = new FormData();

      body.append("request_ids[0]", this.selectId);
      body.append("quality[0]", itemSelected.id);

      axiosInstance
        .post(`/v2/employee/Requests/moveToQualityReqArray`, body, {
          headers: this.request_headers(),
        })
        .then((res) => {
          this.active = true;
          this.message = res.data.message;
        })
        .catch((err) => {
          this.active = true;
          let error = { err };
          this.message = error.err.response.data.message;
        });
    },

    setTblData(res) {
      // console.log("res", res);
      this.$store.state.tableData.push({
        id: res.id,
        isSelected: false,
        "رقم الطلب": res.id,
        created_at: res.created_at ? formatDate(res.created_at) : "",
        type: res.type || "لا_يوجد",
        "استشاري المبيعات": res.user?.name,
        عميل: res.customer.name,
        الجوال: res.customer.mobile,
        status: res.status,
        source: SourceValue(res.source),
        "تصنيف الاستشاري": res.class_id_agent_text,
        agent_notes: res.agent_notes,
        quality_notes: res.quality_notes,
        agent_date: res.agent_date ? formatDate(res.agent_date) : "",
        "هل تم استلامه من قبل الجوده":
          (res.class_id_quality != null || res.quacomment != null) ? "نعم" :  "لا",
          // res.user?.allow_recived && res.user?.allow_recived === 0 ? "لا" : "نعم",
        updated_at: res.updated_at ? formatDate(res.updated_at) : "",
        العمليات: res.sent_basket?.count,
      });
    },

    getCustomers() {
      axiosInstance.get("/employee/admin/getAllCustomer").then((res) => {
        this.customers = res.data.payload;
        this.customers.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      });
    },

    getTableData(count) {
      this.$store.state.tableData = [];
      this.loading = true;
      const pageNumber = Number(this.$store.state.counterOfShow);
      axiosInstance
        .get(`/v2/employee/Requests?type=all&page=${count}&per_page=${pageNumber}`, {
          headers: this.request_headers(),
        })
        .then((res) => {
          res.data.payload.data.map((item) => {
            this.setTblData(item);
          });
          this.loading = false;
        });
    },

    handlePageNumber(val) {
      if (this.$store.state.isSearch === false) {
        this.getAllRequests(val);
      } else {
        this.searchRequest(val);
      }
    },

    searchRequest(page, type) {
      this.loading = true;

      if (type === "table") {
        this.$store.state.tableData = [];
      }

      if (page > 1 || type === "table") {
        this.$store.state.bodySearch.delete("page");
        this.$store.state.bodySearch.append("page", page);

        let url = `/v2/employee/Requests/search?type=1&per_page=${this.$store.state.counterOfShow}`;
        axiosInstance
          .post(url, this.$store.state.bodySearch, { headers: this.request_headers() })
          .then((res) => {
            this.$store.state.pagination = {
              limit: 3,
              count: res.data.payload.searched,
            };
            let response = res.data.payload.data.map((item) => {
              if (item.length === 0) {
                this.loading = false;
              }
              this.setTblData(item);
              item["starChecked"] = false;
              return item;
            });

            this.$store.state.allSearch.push(...response);
            this.removeDublicate(this.$store.state.allSearch);
            this.loading = false;
          });
      }
    },

    removeDublicate(array) {
      let uniqueArray = array.filter((item, index) => {
        return (
          index ===
          array.findIndex((obj) => {
            return obj.id === item.id;
          })
        );
      });
      this.$store.state.allData = uniqueArray;
    },

    getAllRequests(page) {
      if (
        this.$store.state.allData.length === 0 ||
        this.copySupportRequests.length === 0
      ) {
        this.$store.state.allLoading = true;
      }
      this.loadMoreData = true;
      axiosInstance
        .get(
          `/v2/employee/Requests?type=all&page=${page}&per_page=${this.$store.state.counterOfShow}`
        )
        .then((res) => {
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_all,
          };
          let response = res.data.payload.data.map((item) => {
            this.setTblData(item);
            item["starChecked"] = false;
            return item;
          });

          this.allGetData.push(...response);
          this.removeDublicate(this.allGetData);
          this.copySupportRequests.push(...response);
        })
        .catch((err) => {
          this.$store.state.allLoading = false;
          this.loadMoreData = false;
        })
        .finally(() => {
          this.loadMoreData = false;
          this.$store.state.allLoading = false;
        });
    },

    handleChangeInput(row) {
      row["selected"] = !row["selected"];
      let findRow = this.selectedRows.findIndex((item) => item["id"] === row["id"]);
      if (findRow !== -1) {
        this.selectedRows.splice(findRow, 1);
      } else {
        this.selectedRows.push(row);
      }
    },
  },
  created() {
    this.getCustomers();
    this.$store.state.tableData = [];
    this.$store.state.back = false;
    this.$store.state.isSearch = false;
    this.$store.state.bodySearch = new FormData();
    this.getAllRequests(1);
    this.$store.state.startElement = [];
  },
};
</script>

<style>
.button__back {
  width: 70px;
  height: 40px;
  border-radius: 5px;
  margin: 50px;
  color: white;
  background-color: #441545;
  direction: ltr;
}
.noData {
  display: flex;
  justify-content: center;
  font-size: 22px;
  color: #3f0e40;
  margin-top: 70px;
  font-weight: bold;
}

.faild__request {
  border: 1px solid red;
  color: red;
  display: inline-block;
  padding: 0 3px 3px 3px;
  border-radius: 4px;
}

.archive__request {
  border: 1px solid #dbb622;
  color: #dbb622;
  display: inline-block;
  padding: 0 3px 3px 3px;
  border-radius: 4px;
}

.anotherTypes__request {
  border: 1px solid #ff7b1c;
  color: #ff7b1c;
  display: inline-block;
  padding: 0 3px 3px 3px;
  border-radius: 4px;
}

.new__request {
  border: 1px solid green;
  color: green;
  padding: 0 4px 4px 4px;
  border-radius: 4px;
}

.table__view .v-btn:not(.v-btn--round).v-size--small {
  min-width: auto !important;
}
</style>
