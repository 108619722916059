<template>
  <div class="picker__container">
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
          min-width="auto">
          <template v-slot:activator="{ on, attrs }">
<!--              <v-img style="
                  width: 22px;
                  height: 27px;
                  position: absolute;
                  left: 12px;
                  top: 17px;" id="logo" contain src="@/assets/calendarGray.svg" />-->
              <v-text-field v-model="date" :label="label" prepend-icon="mdi-calendar" v-bind="attrs"
                  v-on="on"></v-text-field>

          </template>
          <v-date-picker v-model="date" :active-picker.sync="activePicker"
              :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
              min="1950-01-01" @change="save"></v-date-picker>
      </v-menu>
  </div>
</template>

<style>
.picker__container {
  position: relative;

}

.picker__container .v-text-field__slot {
  padding: 5px;
  outline: 2px solid #dee2e7;
  border: none;
  border-radius: 4px;
}

.picker__container .v-input__slot {
  outline: none;
}
</style>


<script>
export default {
  name: "pickerInput",
  props: ["name", "label","selected"],
  
  data: () => ({
      activePicker: null,
      date: null,
      menu: false,
  }),
  watch: {
      menu(val) {
          val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
      selected(val) {
          this.date = val;
      },
  },
  methods: {
      save(date) {
          this.$refs.menu.save(date)
          this.$emit('dateValue', date)
      },
  },
  created() {
      if(this.selected){
          this.date = this.selected;
      }
  },


}
</script>