import { render, staticRenderFns } from "./SMAddNewTaskDialog.vue?vue&type=template&id=a966a1a4&"
import script from "./SMAddNewTaskDialog.vue?vue&type=script&setup=true&lang=js&"
export * from "./SMAddNewTaskDialog.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./SMAddNewTaskDialog.vue?vue&type=style&index=0&id=a966a1a4&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports