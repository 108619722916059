<template>
  <v-row justify="center">
    <v-dialog v-model="this.$store.state.dialogQuality" persistent width="624">
      <v-card>
        <v-card-title>
          <span class="text-h5" style="margin-bottom: 30px">نقل الطلب الي الجوده</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <SelectInput
                  @selectedValues="getSelectedValueManger"
                  :originalItems="qualityUsers"
                  label="الجوده"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="$store.state.dialogQuality = false"
          >
            الغاء
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="handleSendUserToAgent">
            تحويل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { qualityUser } from "@/mixins/qualityUser";
import SelectInput from "@/components/Charts/SelectInput.vue";
import { axiosInstance } from "@/axios/config";

export default {
  name: "QualityDialog",
  components: { SelectInput },
  props: ["selectedItems", "type"],

  data() {
    return {
      qualityUsers: [],
    };
  },

  methods: {
    getSelectedValueManger(val) {
      this.selectedQuality = val;
    },

    handleSendUserToAgent() {
      this.$store.state.dialogQuality = false;
      let type = this.type;
      if (!this.type) {
        type = "moveToQualityReqArray";
      }

      let url = `/v2/employee/Requests/${type}`;
      let body = new FormData();

      this.selectedQuality.forEach((item, index) => {
        body.append(`quality[${index}]`, item["id"]);
      });

      if (this.selectedItems.length) {
        this.selectedItems.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });
      }

      if (this.$store.state.startElement.length) {
        this.$store.state.startElement.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });
      }

      axiosInstance
        .post(url, body)
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          let error = { err };
          this.showPopup("error", error.err.response.data.message);
        });
    },

    async convertRequest() {
      this.dialogQuality = true;
      await qualityUser().then((res) => {
        this.qualityUsers = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["name_for_admin"] || item["name"];
          return item;
        });
      });
    },
  },

  created() {
    this.convertRequest();
  },
};
</script>
