<template>
  <v-snackbar
    v-model="active"
    color="#333333"
    timeout="-1"
    right
    bottom
    v-click-outside="clickOutside"
    class="ml-4"
    elevation="24"
    transition="fade-transition"
  >
    <div v-for="message in messages" :key="message"><span class="font-weight-bold" style="font-size: 16px">{{ message }}</span></div>
    <template v-slot:action>
      <v-btn icon small @click="active = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'PopupComponent',
  data() {
    return {
      active: false,
      text: "",
      messages: [],
      message: "",
      status: "success",
      link: null,
      copy: null,
    };
  },
  computed: {
    copyText() {
      if (typeof this.copy != 'object') {
        return this.copy
      }
      return this.copy.content
    },
    hrefTarget () {
      return (this.link.newTap ? "_blank" : "_self")
    }
  },
  created() {},
  mounted() {
    this.$root.$on("popup", (status, text, link = null, copy = null) => {
      this.active = true;
      this.text = text;
      this.status = status;
      this.messages = this.text.split("\n");
      this.link = link;
      this.copy = copy;
    });
  },
  methods: {
    clickOutside() {
      this.$nextTick(() => {
        setTimeout(() => {
            this.active = false;
        }, 30000);
      });
    },
  },
};
</script>
