<template>
  <div>
    <LatestReportFilter @filterData="handleFilter" />
    <div v-if="!this.$store.state.loadingData">
      <table-component
        :showSelect="false"
        itemKey="id"
        :headers="headers"
        :pagination="this.$store.state.pagination"
        :limit="10"
        :items="this.$store.state.tableData"
        :loading="loading"
        @refresh="getTableData"
        v-model="page"
      >
        <template v-slot:[`item.action`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#40BE72"
                small
                depressed
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="handleOrderRequest(item)">
                <v-list-item-icon class="ml-2">
                  <HolidayOrder />
                </v-list-item-icon>
                <v-list-item-title>فتح الطلب </v-list-item-title>
              </v-list-item>
              <v-list-item @click="handleOrdersRequest(item)">
                <v-list-item-icon class="ml-2">
                  <HolidayOrder />
                </v-list-item-icon>
                <v-list-item-title>سجل الطلب </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </table-component>
    </div>
    <div class="loadingMore" v-else>
      <LoadingMore />
    </div>

     <!-- chart -->
     <div style="box-shadow: 0 0 0 0 !important" class="adminGraph">
      <div v-if="!getDataDone">
        <div style="margin-top: 40px" v-if="!loading">
          <highcharts :options="chartOptions" style="height: 600px"></highcharts>
        </div>
      </div>
      <div v-else class="loader">
        <img src="../../../../assets/Comp-2.gif" />
      </div>
    </div>

  </div>
</template>

<style lang="scss">
.loadingMore {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
</style>

<script>
import Axios from "axios";
import { HolidayOrder } from "@/assets/adminIcons/holidayOrder";
import LatestReportFilter from "./LatestReportFilter.vue";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts-vue";

export default {
  name: "AdminLatestReport",
  components: { HolidayOrder, LatestReportFilter,highcharts: Chart, },

  data() {
    return {
      page: 1,
      loading: false,
      copySupportRequests: [],
      loadMoreData: false,
      loadingData: false,
      selected_requests: [],
      iconToggle: false,
      headers: [
        { text: "تاريخ السحب", value: "تاريخ السحب" },
        { text: "من الإستشاري", value: "من الإستشاري" },
        { text: "إلى الإستشاري", value: "إلى الإستشاري" },
        { text: "العمليات", value: "action" },
      ],
      horizontalData: [],
      verticalData1: [],
      verticalData2: [],
      chartData: [],
      getDataDone: false,
      chartOptions: {
        chart: {
          type: "column",
        },
        title: {
          text: "تقرير الطلبات الحرة للاستشاريين",
        },
        xAxis: {
          min: 0,
          title: {
            text: "الطلبات الحرة",
          },
          categories: [],
        },
        yAxis: {
          title: {
            text: "المجموع",
          },
        },
        tooltip: {
          headerFormat: "<b>{point.x}</b><br/>",
          pointFormat:
            '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
          shared: true,
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "مجموع الطلبات الحرة التي سحبها الاستشاري",
            data: [],
          },
          {
            name: "مجموع الطلبات الحرة التي تم سحبها من الاستشاري",
            data: [],
          },
        ],
      },
    };
  },

  watch: {
    page(val) {
      this.getTableData(val);
    },

    selected_requests(val) {
      this.selectedItems = val;
      this.$emit("requests", val);
    },
  },

  methods: {
    handleOrderRequest(item) {
      this.$router.push({
        name: "request_details",
        params: { id: item.request_id },
      });
    },
    handleOrdersRequest(item) {
      this.$router.push({
        name: "logs",
        params: { id: item.request_id },
      });
    },

    setTbData(items) {
      this.$store.state.tableData.push({
        id: items.id,
        request_id: items.request_id,
        "تاريخ السحب": items.history_date_formated,
        "من الإستشاري": items.user_sent.name,
        "إلى الإستشاري": items.user_received.name,
      });
    },

    handleFilter(val) {
      this.$store.state.tableData = [];
      val.data.map((res) => {
        this.setTbData(res);
      });
      this.chartData = [];
      this.chartOptions.xAxis.categories = [];
      this.chartOptions.series[0].data = [];
      this.chartOptions.series[1].data = [];
      this.drawChart(val.chart);
    },

    getTableData(count) {
      this.$store.state.tableData = [];
      this.loading = true;
      Axios.post(
        // `${this.$store.state.url}/employee/latestReport?page=${count}`,
        `${this.$store.state.url}/employee/latestReportFilter?page=${count}`,{},
        {
          headers: this.request_headers(),
        }
      ).then((res) => {
        res.data.payload.data.map((item) => {
          this.setTbData(item);
        });

        this.$store.state.pagination = {
          limit: 3,
          count: res.data.payload.total,
        };

        this.drawChart(res.data.payload.chart);
        this.loading = false;
      });
    },

    drawChart(data) {
      this.chartData = data;
      this.horizontalData = this.chartData.map((item) => item.name);
      this.verticalData1 = this.chartData.map((item) => item.countPulledFromAgent);
      this.verticalData2 = this.chartData.map((item) => item.countPulledByAgent);
      this.chartOptions.xAxis.categories = this.horizontalData;
      this.chartOptions.series[0].data = this.verticalData1;
      this.chartOptions.series[1].data = this.verticalData2;
      this.loading = false;
    },
  },

  created() {
    this.$store.state.tableData = [];
    this.getTableData(1);
  },
};
</script>
