<template>
  <div>
    <div class="vRowToShowData">
      <button
        v-if="this.$store.state.back"
        @click="$emit('back', '')"
        class="back__button"
      >
        رجوع
      </button>
      <v-sheet class="mx-auto mb-7" v-if="$store.state.startElement.length > 0">
        <v-slide-group multiple show-arrows>
          <v-slide-item
            v-for="(person, index) in $store.state.startElement"
            :key="index"
          >
            <div class="slider__button">
              <img src="@/assets/icons/chipStar.svg" alt="image" />
              <span style="margin: 5px">
                {{
                  person?.customer?.name ||
                  person?.request?.customer?.name ||
                  person?.quality_request?.request?.customer?.name
                }}
              </span>
              <v-icon
                class="exitIcon"
                @click="starToggle(person['id'] || person.request.id)"
                style="color: white; font-size: 15px"
                >mdi-close
              </v-icon>
            </div>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>

      <v-card flat>
        <v-layout row wrap>
          <v-col
            cols="12"
            lg="3"
            md="4"
            sm="6"
            xs="12"
            class="px-2 py-1"
            v-for="(item, i) in CardData"
            :key="i"
          >
            <v-card
              class="pa-3 position-relative"
              style="
                height: 100%;
                background-color: #f4f2f4;
                border: 1px solid #d4d5d5;
              "
            >
              <span
                class="right-color requestType"
                :style="`${
                  returnStatusReq(item) === 0
                    ? `background-color: #017F85`
                    : returnStatusReq(item) === 1
                    ? `background-color: #41BF72`
                    : returnStatusReq(item) === 2
                    ? `background-color: #DEB523`
                    : `background-color: #FE705B`
                }`"
              >
              </span>
              <div class="card-head d-flex justify-space-between align-center">
                <v-card-title
                  class="pa-0 requestTitle"
                  style="color: #3f0f41; font-size: 16px; font-weight: bold"
                >
                  {{ returnName(item) }}
                </v-card-title>
                <div class="icons">

                  <img src="@/assets/icons/Frame2.svg" alt="image" v-if="item.personalRequest || item.mainRequest"  class="mx-2">
                  <input
                    class="star"
                    type="checkbox"
                    :checked="!item.starChecked"
                    @change="starToggle(item?.id || item?.request?.id)"
                    style="margin-left: 5px"
                  />

                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        height="30"
                        color="transparent"
                        depressed
                        fab
                        light
                        v-bind="attrs"
                        v-on="on"
                        style="margin-left: -10px"
                      >
                        <img src="@/assets/icons/eyeIcon.svg" alt="image" />
                      </v-btn>
                    </template>


                    <v-list v-if="showProcessAll|| showProcessLatest">
                      <AllSingleProcess
                        :item="item"
                        @getUsersName="getUsersName"
                      />
                    </v-list>

                    

                    <v-list v-if="showProcessAdministration">
                      <AdministrationSingleProcess :item="item" @getUsersName="getUsersName"  @proccessRequest="proccessRequest"/>
                    </v-list>

                    <v-list v-if="showProcessFundingDuration">
                      <AdminFundingDurationSingleProcess :item="item"/>
                    </v-list>

                    <v-list v-if="showFreezeProcess">
                      <FreezingSingleProcess :item="item.id" />
                    </v-list>

                    <v-list v-if="showProcessAddToNew">
                      <AddNewProcess :item="item.id" :request_id="item.request.id"/>
                    </v-list>

                    <v-list v-if="showProcessWaitingNew">
                      <AddNewProcess :item="item.id"  :request_id="item.request.id"/>
                    </v-list>

                    <v-list v-if="showPendingProcess">
                      <SingleProcess :item="item.id" />
                    </v-list>

                    <v-list v-if="showArchivedInQuality">
                      <SingleProccessArchivedInQuality :item="item" />
                    </v-list>

                    

                    <v-list v-if="showNeedToMoveNew">
                      <SingleProccessInNew :item="item" />
                    </v-list>

                    <v-list v-if="showFeaturedProcess">
                      <FeaturedSingleProcess :item="item.id" />
                    </v-list>
                  </v-menu>
                </div>
              </div>

              <div
                class="req-status-parent mt-1 d-flex justify-space-between align-center"
              >
                <span class="req-type" style="color: #545a69; font-size: 16px">
                  {{ returnType(item) }}
                </span>
                <span
                  class="req-status spanRequestType"
                  :style="`${
                    returnStatusReq(item) === 0
                      ? `background-color: #017F85`
                      : returnStatusReq(item) === 1
                      ? `background-color: #41BF72`
                      : returnStatusReq(item) === 2
                      ? `background-color: #DEB523`
                      : `background-color: #FE705B`
                  }`"
                >
                  {{ returnStatus(item) }}
                </span>
              </div>

              <div
                class="req-status-parent mt-1 d-flex justify-space-between align-center"
              >
                <span class="req-type" style="color: #545a69; font-size: 16px">
                  الاستشاري
                </span>
                <span>
                  {{ item.user?.name || item.request?.user?.name || item.userBeforeSendingToAdministration?.name || "لا يوجد" }}
                </span>
              </div>

              <div
                class="req-date-parent my-2 d-flex justify-space-between align-center"
              >
                <span class="req-date" style="color: #545a69">
                  {{ returnDate(item) }}
                </span>
                <span
                  class="req-status"
                  :style="`color: #074C74 ; font-size: 14px;`"
                >
                  {{ returnAgentText(item) }}
                </span>
              </div>

              <hr
                style="border: 1px solid #000000; opacity: 0.1"
                class="mb-0"
              />
              <div class="desc parent">
                <v-tooltip bottom color="#451646">
                  <template v-slot:activator="{ on }">
                    <!-- <v-card-text
                      v-on="on"
                      class="pt-2 px-0"
                    >
                      {{ returnNote(item) }}
                    </v-card-text> -->

                    <v-card-text v-on="on" class="pt-2 px-0">
                          {{
                            returnNote(item) ? returnNote(item).length > 50
                              ? returnNote(item).substring(0, 70) + "..." : returnNote(item): "لايوجد ملاحظات"
                          }}
                        </v-card-text>
                  </template>
                  <div style="
                          font-size: 14px;
                          background-color: #451646;
                        ">{{ returnNote(item) }}</div>
                </v-tooltip>
              </div>
            </v-card>
          </v-col>
        </v-layout>
        <v-card v-intersect="infiniteScrolling"></v-card>
      </v-card>
    </div>
    <v-snackbar
      v-model="active"
      color="#40BE72"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <span class="font-weight-bold" style="font-size: 16px">{{
        message
      }}</span>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Axios from "axios";
import AddNewProcess from "@/views/admin/Requests/MoveToNew/AddNewProcess.vue";
import SingleProcess from "@/views/admin/Requests/pending/SingleProcess.vue";
import SingleProccessInNew from "@/views/admin/Requests/QualityNew/SingleProccessInNew.vue";
import SingleProccessArchivedInQuality from "@/views/admin/Requests/SingleProccessArchivedInQuality.vue";
import FeaturedSingleProcess from "@/views/admin/Requests/FeaturedBasket/FeaturedSingleProcess.vue";
import AllSingleProcess from "@/views/admin/Requests/AllRequests/AllSingleProcess.vue";
import AdministrationSingleProcess from "@/views/admin/Requests/AdministrationRequest/AllSingleProcess.vue";
import AdminFundingDurationSingleProcess from "@/views/admin/Requests/AdminFundingDuration/AllSingleProcess.vue";
import FreezingSingleProcess from "@/views/admin/Requests/Freezing/FreezingSingleProcess.vue";
import { axiosInstance } from "@/axios/config";


export default {
  name: "RequestsCard",
  components: {
    SingleProccessInNew,
    SingleProcess,
    AddNewProcess,
    FeaturedSingleProcess,
    AllSingleProcess,
    AdministrationSingleProcess,
    AdminFundingDurationSingleProcess,
    FreezingSingleProcess,
    SingleProccessArchivedInQuality
  },
  props: ["CardData"],
  mounted() {
    this.cpCardData = structuredClone(this.CardData);
  },
  data() {
    return {
      startElement: this.$store.state.startElement,
      cpCardData: [],
      page: this.$store.state.InfinityPageNumber,
      allStatus: [],
      label: "",
      active: false,
      message: "",
      getCustomer: [],
      selectedItems: [],
      searched: this.$store.state.isSearch,
    };
  },

  watch: {
    startElement(val) {
      this.selectedItems = val;
    },
    selected_requests(val) {
      this.selectedItems = val;
      this.$emit("requests", val);
    },
  },

  computed: {
    returnAllRequests() {
      return this.allStatus;
    },

    showFreezeProcess() {
      return window.location.pathname === "/admin/requests/freeze" || window.location.pathname === "/admin/requests/need-to-turn-done";
    },

    showNeedToMoveNew() {
      return window.location.pathname === "/admin/requests/need-to-turn-new";
    },

    showArchivedInQuality() {
      return window.location.pathname === "/admin/requests/archived-in-quality";
    },

    showFeaturedProcess() {
      return window.location.pathname === "/admin/requests/featured";
    },

    showProcessAll() {
      return window.location.pathname === "/admin/requests/all" || window.location.pathname === "/admin/requests/has-personal-request";
    },

    showProcessAdministration() {
      return window.location.pathname === "/admin/requests/administration";
    }, 

    showProcessFundingDuration() {
      return window.location.pathname === "/admin/requests/fundingDurationRequests";
    }, 


    showProcessFreeze() {
      return window.location.pathname === "/admin/requests/freeze";
    },
    showProcessLatest(){
      return window.location.pathname === '/admin/requests/latest'
    },

    showProcessNeedToTurnDone(){
      return window.location.pathname === '/admin/requests/need-to-turn-done'

    },

    showProcessAddToNew() {
      return window.location.pathname === "/admin/requests/need-action-new";
    },

    showProcessWaitingNew() {
      return window.location.pathname === "/admin/requests/wating-new";
    },

    showPendingProcess() {
      return window.location.pathname === "/admin/requests/pending";
    },
  },
  methods: {
    getUsersName(users, label, requestId) {
      this.$emit("getUsersName", users, label, requestId);
    },

    proccessRequest(id){
      axiosInstance
        .get(`/v2/employee/Requests/proccessAdministrationRequest/${id}`,{
          headers: this.request_headers(),
        })
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          let error = { err };
          this.showPopup("error", error.err.response.data.message);
        });
    },

    returnName(item) {

      if (
        window.location.pathname === "/admin/requests/all" ||
        window.location.pathname === "/admin/requests/has-personal-request" ||
        window.location.pathname === "/admin/requests/administration" ||
        window.location.pathname === "/admin/requests/fundingDurationRequests" ||
        window.location.pathname === "/admin/requests/recieved" ||
        window.location.pathname === "/admin/requests/followed" ||
        window.location.pathname === "/admin/requests/featured" ||
        window.location.pathname === "/admin/requests/archieved" ||
        window.location.pathname === '/admin/requests/latest' ||
        window.location.pathname === "/admin/requests/pending" ||
        window.location.pathname === "/admin/requests/freeze"
      ) {
        return item.customer?.name;
      } else if (
        window.location.pathname === "/admin/requests/need-action-new" ||
        window.location.pathname === "/admin/requests/wating-new" ||
        window.location.pathname === "/admin/requests/wating-done" ||
        window.location.pathname === "/admin/requests/need-action-done" ||
        window.location.pathname === "/admin/requests/archived-in-quality" 

      ) {
        return item.request?.customer?.name;
      } else if (
        window.location.pathname === "/admin/requests/need-to-turn-new" ||
        window.location.pathname === "/admin/requests/need-to-turn-done"
      ) {
        return item.quality_request?.request?.customer?.name;
      }
    },

    returnStatusReq(item) {

      if (
        window.location.pathname === "/admin/requests/all" ||
        window.location.pathname === "/admin/requests/has-personal-request" || 
        window.location.pathname === "/admin/requests/administration" || 
        window.location.pathname === "/admin/requests/fundingDurationRequests" || 
        window.location.pathname === "/admin/requests/recieved" ||
        window.location.pathname === "/admin/requests/followed" ||
        window.location.pathname === "/admin/requests/featured" ||
        window.location.pathname === "/admin/requests/archieved" ||
          window.location.pathname === '/admin/requests/latest' ||
        window.location.pathname === "/admin/requests/pending" ||
        window.location.pathname === "/admin/requests/freeze"
      ) {
        return item.statusReq;
      } else if (
        window.location.pathname === "/admin/requests/need-action-new" ||
        window.location.pathname === "/admin/requests/wating-new" ||
        window.location.pathname === "/admin/requests/wating-done" ||
        window.location.pathname === "/admin/requests/need-action-done" ||
        window.location.pathname === "/admin/requests/archived-in-quality" 

      ) {
        return item.request.statusReq;
      } else if (
        window.location.pathname === "/admin/requests/need-to-turn-new" ||
        window.location.pathname === "/admin/requests/need-to-turn-done"
      ) {
        return item.quality_request.request.statusReq;
      }
    },

    returnType(item) {

      if (
        window.location.pathname === "/admin/requests/all" ||
        window.location.pathname === "/admin/requests/has-personal-request" || 
        window.location.pathname === "/admin/requests/administration" || 
        window.location.pathname === "/admin/requests/fundingDurationRequests" || 

        window.location.pathname === "/admin/requests/recieved" ||
        window.location.pathname === "/admin/requests/followed" ||
        window.location.pathname === "/admin/requests/featured" ||
        window.location.pathname === '/admin/requests/latest' ||
        window.location.pathname === "/admin/requests/archieved" ||
        window.location.pathname === "/admin/requests/pending" ||
        window.location.pathname === "/admin/requests/freeze"
      ) {
        return item.type || "لا يوجد نوع طلب";
      } else if (
        window.location.pathname === "/admin/requests/need-action-new" ||
        window.location.pathname === "/admin/requests/wating-new" ||
        window.location.pathname === "/admin/requests/wating-done" ||
        window.location.pathname === "/admin/requests/need-action-done" ||
        window.location.pathname === "/admin/requests/archived-in-quality" 

      ) {
        return item.request.type || "لا يوجد نوع طلب";
      } else if (
        window.location.pathname === "/admin/requests/need-to-turn-new" ||
        window.location.pathname === "/admin/requests/need-to-turn-done"
      ) {
        return item.quality_request.request.type;

      }
    },

    returnStatus(item) {

      if (
        window.location.pathname === "/admin/requests/all" ||
        window.location.pathname === "/admin/requests/has-personal-request" || 
        window.location.pathname === "/admin/requests/administration" || 
        window.location.pathname === "/admin/requests/fundingDurationRequests" || 
        window.location.pathname === "/admin/requests/recieved" ||
        window.location.pathname === "/admin/requests/followed" ||
        window.location.pathname === "/admin/requests/featured" ||
        window.location.pathname === '/admin/requests/latest' ||
        window.location.pathname === "/admin/requests/archieved" ||
        window.location.pathname === "/admin/requests/pending" ||
        window.location.pathname === "/admin/requests/freeze"
      ) {
        return item.status || "معلقه";
      } else if (
        window.location.pathname === "/admin/requests/need-action-new" ||
        window.location.pathname === "/admin/requests/wating-done" ||
        window.location.pathname === "/admin/requests/wating-new" ||
        window.location.pathname === "/admin/requests/need-action-done" ||
        window.location.pathname === "/admin/requests/archived-in-quality" 

      ) {
        return item.request.status;
      } else if (
        window.location.pathname === "/admin/requests/need-to-turn-new" ||
        window.location.pathname === "/admin/requests/need-to-turn-done"
      ) {
        return item.quality_request.request.status;
      }
    },

    returnDate(item) {

      if (
        window.location.pathname === "/admin/requests/all" ||
        window.location.pathname === "/admin/requests/has-personal-request" || 
        window.location.pathname === "/admin/requests/administration" || 
        window.location.pathname === "/admin/requests/fundingDurationRequests" || 
        window.location.pathname === "/admin/requests/recieved" ||
        window.location.pathname === "/admin/requests/followed" ||
        window.location.pathname === "/admin/requests/featured" ||
        window.location.pathname === '/admin/requests/latest' ||
        window.location.pathname === "/admin/requests/archieved" ||
        window.location.pathname === "/admin/requests/pending" ||
        window.location.pathname === "/admin/requests/freeze"
      ) {
        return item.req_date;
      } else if (
        window.location.pathname === "/admin/requests/need-action-new" ||
        window.location.pathname === "/admin/requests/wating-done" ||
        window.location.pathname === "/admin/requests/wating-new" ||
        window.location.pathname === "/admin/requests/need-action-done" ||
        window.location.pathname === "/admin/requests/archived-in-quality" 

      ) {
        return item.request.req_date;
      } else if (
        window.location.pathname === "/admin/requests/need-to-turn-new" ||
        window.location.pathname === "/admin/requests/need-to-turn-done"
      ) {
        return item.quality_request.request.req_date;
      }
    },

    returnAgentText(item) {

      if (
        window.location.pathname === "/admin/requests/all" ||
        window.location.pathname === "/admin/requests/has-personal-request" || 
        window.location.pathname === "/admin/requests/administration" || 
        window.location.pathname === "/admin/requests/fundingDurationRequests" || 
        window.location.pathname === "/admin/requests/recieved" ||
        window.location.pathname === "/admin/requests/followed" ||
        window.location.pathname === '/admin/requests/latest' ||
        window.location.pathname === "/admin/requests/featured" ||
        window.location.pathname === "/admin/requests/archieved" ||
        window.location.pathname === "/admin/requests/pending" ||
        window.location.pathname === "/admin/requests/freeze"
      ) {
        return item.class_id_agent_text;
      } else if (
        window.location.pathname === "/admin/requests/need-action-new" ||
        window.location.pathname === "/admin/requests/wating-done" ||
        window.location.pathname === "/admin/requests/wating-new" ||
        window.location.pathname === "/admin/requests/need-action-done" ||
        window.location.pathname === "/admin/requests/archived-in-quality" 

      ) {
        return item.request.class_id_agent_text;
      } else if (
        window.location.pathname === "/admin/requests/need-to-turn-new" ||
        window.location.pathname === "/admin/requests/need-to-turn-done"
      ) {
        return item.quality_request.request.class_id_agent_text;
      }
    },

    returnNote(item) {

      if (
        window.location.pathname === "/admin/requests/all" ||
        window.location.pathname === "/admin/requests/has-personal-request" || 
        window.location.pathname === "/admin/requests/administration" || 
        window.location.pathname === "/admin/requests/fundingDurationRequests" || 
        window.location.pathname === "/admin/requests/recieved" ||
        window.location.pathname === "/admin/requests/followed" ||
        window.location.pathname === "/admin/requests/featured" ||
        window.location.pathname === '/admin/requests/latest' ||
        window.location.pathname === "/admin/requests/archieved" ||
        window.location.pathname === "/admin/requests/pending" ||
        window.location.pathname === "/admin/requests/freeze"
      ) {
        return item.agent_notes || "لايوجد ملاحظات";
      } else if (
        window.location.pathname === "/admin/requests/need-action-new" ||
        window.location.pathname === "/admin/requests/wating-done" ||
        window.location.pathname === "/admin/requests/wating-new" ||
        window.location.pathname === "/admin/requests/need-action-done" ||
        window.location.pathname === "/admin/requests/archived-in-quality" 

      ) {
        return item.request.agent_notes || "لايوجد ملاحظات";
      } else if (
        window.location.pathname === "/admin/requests/need-to-turn-new" ||
        window.location.pathname === "/admin/requests/need-to-turn-done"
      ) {
        return item.quality_request.request.agent_notes || "لايوجد ملاحظات";
      }
    },

    infiniteScrolling() {
      setTimeout(() => {
        this.page++;
        this.$emit("pageCount", this.page);
      }, 100);
    },

    returnStatusValue(status) {
      if (status === 0) {
        return "جديد";
      } else if (status === 1) {
        return "تم فتحه";
      } else if (status === 2) {
        return "مكتمل";
      } else {
        return "مرفوض";
      }
    },

    starToggle(id) {
      this.cpCardData = this.CardData.map((ele) => {
        if (ele.id === id) {
          ele.starChecked = !ele.starChecked;

          if (ele.starChecked) {
            this.$store.state.startElement.push(ele);
          } else {
            let targetItem = this.$store.state.startElement.findIndex(
              (item) => item.id === ele.id
            );
            if (targetItem !== -1) {
              this.$store.state.startElement.splice(targetItem, 1);
            }
          }
          return ele;
        }
        return ele;
      });
    },

    deleteSelectedPerson(index, person) {
      this.starToggle(person.id || person.request.id);
    },
  },
};
</script>

<style lang="scss">
.vRowToShowData .back__button {
  width: 70px;
  height: 40px;
  border-radius: 5px;
  margin: 50px;
  color: white;
  background-color: #441545;
  direction: ltr;
}

.vRowToShowData .slider__button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  background: #984abe;
  color: white;
  border-radius: 4px;
  padding: 15px;
}

.star {
  visibility: hidden;
  font-size: 30px;
  cursor: pointer;
  color: purple;
}

.star:before {
  //content: "\2605";
  position: absolute;
  visibility: visible;
  top: 0;
  color: purple;
}

.star:checked:before {
  //content: "\2606";
  position: absolute;
  top: 0;
}

.spanRequestType {
  font-size: 13px;
  padding: 0 5px 5px;
  color: white;
}

.vRowToShowData .requestTitle {
  font-size: 15px;
  font-weight: bold;
}

.vRowToShowData .requestType {
  position: absolute;
  width: 6px;
  height: 101.2%;
  top: -1px;
  right: -6px;
}

.vRowToShowData {
  padding-top: 11px !important;
}

.vChipLoadingIcon .exitIcon {
  font-size: 13px;
  position: absolute;
  left: 3px;
  top: 4px;
  cursor: pointer;
}

.vChipLoadingIcon img {
  margin-left: 5px;
}

.vChipLoadingIcon {
  clip-path: unset !important;
  border-radius: 5px !important;
  height: 36px;
  flex-shrink: 0;
  padding-left: 30px !important;
}

.vSheetContainer {
  height: 52px;
  overflow: hidden;
  border-radius: 5px;
  max-width: 100% !important;
  margin-top: 30px;
}

.app {
  display: grid;
  place-items: center;
  background-color: transparent;
  height: 50vh;
}

.no-requests {
  flex: 1;
  overflow-y: hidden;
}

.v-chip.v-chip.v-chip--outlined.v-chip.v-chip {
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
  clip-path: polygon(
    0% 50%,
    10% 0%,
    100% 0%,
    100% 100%,
    10% 100%,
    0% 50%
  ) !important;
  background-color: #3f0e40 !important;
  color: white !important;
  padding-left: 20px !important;
}

.v-btn {
  &:focus {
    &:before {
      background-color: white !important;
    }
  }
}

.selected-slider {
  .v-slide-group__content {
    align-items: center !important;
  }

  .theme--light.v-icon.v-icon:not(.mdi-close) {
    background-color: #f2f2f2 !important;
    border-color: #f2f2f2 !important;
    font-size: 40px;
    color: black;
  }
}
</style>
