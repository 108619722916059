<template>
  <div>
    <v-list-item @click="$emit('handleDelete', item, 'delete')">
      <v-list-item-icon class="ml-2">
        <img src="../assets/4.svg" alt="delete" />
      </v-list-item-icon>
      <v-list-item-title>حذف</v-list-item-title>
    </v-list-item>

    <v-list-item @click="openAndDownloadAttachment('download')">
      <v-list-item-icon class="ml-2">
        <img src="../assets/install-software-download-icon.svg" alt="download" />
      </v-list-item-icon>
      <v-list-item-title>تحميل الملف</v-list-item-title>
    </v-list-item>

    <v-list-item @click="openAndDownloadAttachment('open')">
      <v-list-item-icon class="ml-2">
        <v-icon color="black">mdi-eye-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-title> فتح المرفق</v-list-item-title>
    </v-list-item>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
const props = defineProps(["item"]);
import { axiosInstance } from "@/axios/config";

const openAndDownloadAttachment = (body) => {
  axiosInstance
    .get(`/employee/hr/open-and-download-file/${props.item?.id}`, {
      method: body,
      responseType: "arraybuffer",
    })
    .then((response) => {
      const contentType = response.headers["content-type"];
      const blob = new Blob([response.data], { type: contentType });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      if (body === "download") {
        link.download = "document.pdf";
      } else if (body === "open") {
        link.target = "_blank";
      }

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
</script>
