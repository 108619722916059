<template>
  <v-row justify="center">
    <v-dialog
        v-model="this.$store.state.refuseDialog"
        persistent
        width="624"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5" style="margin-bottom: 30px">سبب رفض التحويل</span>
        </v-card-title>
        <hr class="horizontal"/>
        <v-card-text>
          <v-container>
            <v-row>
                <span class="reasonLabel">سبب الرفض</span>
              <v-col cols="12" sm="12" md="12">
                <input type="text" v-model="reason" class="inputReason"/>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue-darken-1"
              variant="text"
              @click="$store.state.refuseDialog = false"
          >
            الغاء
          </v-btn>
          <v-btn
              color="blue-darken-1"
              variant="text"
              @click="handleRefuseUser"
          >
            رفض
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style>
.horizontal{
  margin: -18px;
  margin-bottom: 25px
}

.reasonLabel{
  margin-right: 18px;
  font-weight: bold;
}

.inputReason{
  width: 100%;
  border: 1px solid gray;
  height: 40px;
  outline: none;
  border-radius: 7px;
  padding: 5px;
}
</style>

<script>

import Axios from "axios";

export default {
  name: 'RefuseRequest',

  data(){
    return{
      reason: '',
    }
  },

  methods:{
    handleRefuseUser(){
      let body = new FormData();
      let url = `${this.$store.state.url}/v2/employee/Requests/rejectReasonMoveNeedToBeTurnedQualityReqNew`

      
      body.append('id' , this.$store.state.refusedId.id)
      if(this.reason){
        body.append('reject_reason', this.reason)
      }
      Axios.post(url, body, {headers:this.request_headers()}).then(res => {
        this.$store.state.refuseDialog = false
        this.showPopup("success", res.data.message);
        this.$emit("handleRefaund")
      }).catch(err => {
        let error = { err };
        // this.showPopup("error", error.err.response.data.message);
        this.$store.state.refuseDialog = false
      })
    }
  }
}
</script>