<template>
  <div>
    <ProcessGroup :selectedItems="selected_requests" type="moveAllPendingRequests" />
    <div v-if="!this.getDataLoading" style="margin-top: 40px">
      <div v-if="$store.state.views === 'cards'">
        <div v-if="this.allRequests.length > 0">
          <RequestsCard
            :CardData="this.allRequests"
            v-if="!this.getDataLoading"
            @pageCount="handlePageNumber"
            @back="handleBack"
          />
          <div>
            <LoadingMore v-if="loadMoreData" style="margin: 40px" />
          </div>
        </div>
        <div class="noData" v-else>لا يوجد بيانات</div>
      </div>
      <div v-else-if="$store.state.views === 'table'" class="table__view">
        <table-component
          :showSelect="true"
          itemKey="id"
          :selectedRows.sync="selected_requests"
          :headers="headers"
          :pagination="this.$store.state.pagination"
          :limit="$store.state.counterOfShow"
          :items="this.$store.state.tableData"
          :loading="loading"
          @refresh="getTableData"
          v-model="page"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="#3f0e40" small depressed dark v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <SingleProcess :item="item.id" />
              </v-list>
            </v-menu>
          </template>
        </table-component>
        <OrderRequestSection
          v-if="this.$store.state.openOrderRequestSection"
          style="margin-top: 50px"
        />
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
      "
      v-else
    >
      <LoadingMore />
    </div>
    <QualityDialog :selectedItems="selected_requests" />
    <AdminDialog
      @selectedValue="selectedValue"
      :items="this.$store.state.allStatusAgent"
      label="استشاري المبيعات"
    />
    <PendingCondition />
    <OrderRequestDialog />
    <CallsDialog />
  </div>
</template>

<script>
import Axios from "axios";
import RequestsCard from "@/components/Charts/RequestsCard.vue";
import LoadingMore from "@/components/Charts/LoadingMore.vue";
import { formatDate } from "@/mixins/formatDate";
import { SourceValue } from "@/mixins/sourceValue";
import SingleProcess from "./SingleProcess.vue";
import AdminDialog from "@/components/AdminComponent/AdminDialog.vue";
import ProcessGroup from "@/views/admin/Requests/MoveToNew/ProcessGroup.vue";
import QualityDialog from "@/components/AdminComponent/QualityDialog.vue";
import PendingCondition from "@/views/admin/Requests/pending/PendingCondition.vue";
import OrderRequestDialog from "@/components/AdminComponent/OrderRequestDialog.vue";
import OrderRequestSection from "@/components/AdminComponent/OrderRequestSection.vue";
import CallsDialog from "@/components/Charts/CallsDialog.vue";

export default {
  name: "AdminSupport",
  components: {
    OrderRequestDialog,
    OrderRequestSection,
    PendingCondition,
    QualityDialog,
    ProcessGroup,
    AdminDialog,
    SingleProcess,
    RequestsCard,
    LoadingMore,
    CallsDialog,
  },

  data() {
    return {
      page: 1,
      loading: false,
      copySupportRequests: [],
      loadMoreData: false,
      loadingData: false,
      selected_requests: [],
      allRequests: [],
      getDataLoading: false,
      headers: [
        { align: "center", value: "data-table-select" },
        { text: "تاريخ الطلب", value: "تاريخ الطلب", width: "107px", align: "center" },
        { text: "عميل", value: "عميل", align: "center" },
        { text: "الجوال", value: "الجوال", align: "center" },
        { text: "جهه العمل", value: "جهه العمل", align: "center" },
        {
          text: "هل هو مدعوم",
          value: "هل هو مدعوم",
          width: "90px",
          align: "center",
        },
        { text: "راتب العميل", value: "راتب العميل", align: "center" },
        { text: "هل وجد عقار", value: "هل وجد عقار", width: "90px", align: "center" },
        {
          text: "هل لديه متضامن",
          value: "هل لديه متضامن",
          width: "90px",
          align: "center",
        },
        {
          text: "هل لديه التزامات",
          value: "هل لديه التزامات",
          width: "90px",
          align: "center",
        },
        {
          text: "هل لديه تعثرات",
          value: "هل لديه تعثرات",
          width: "90px",
          align: "center",
        },
        {
          text: "هل يمتلك عقار",
          value: "هل يمتلك عقار",
          width: "94px",
          align: "center",
        },
        {
          text: "تاريخ الميلاد الهجري",
          value: "تاريخ الميلاد الهجري",
          width: "107px",
          align: "center",
        },
        {
          text: "مصدر المعامله",
          value: "مصدر المعامله",
          align: "center",
        },
        {
          text: "تم التحديث ",
          value: "تم التحديث في",
          width: "107px",
          align: "center",
        },
        { text: "", value: "action", align: "center" },
      ],
    };
  },

  beforeDestroy() {
    this.$store.state.openOrderRequestSection = false;
  },

  watch: {
    page(val) {
      if (this.$store.state.isSearch === false) {
        this.getTableData(val);
      } else {
        this.searchRequest(val, "table");
      }
    },

    selected_requests(val) {
      this.selectedItems = val;
      this.$emit("requests", val);
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval != oldvalue) {
          this.getTableData(this.page);
        }
      },
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },
  },

  methods: {
    handleBack() {
      this.allRequests = [];
      this.$store.state.tableData = [];
      this.getDataLoading = true;
      this.$store.state.isSearch = false;
      this.$store.state.back = false;
      this.getAllRequests(1, "back");
    },
    handlePageNumber(val) {
      if (this.$store.state.isSearch === false) {
        this.getAllRequests(val);
      } else {
        this.searchRequest(val);
      }
    },
    searchRequest(page, type) {
      // this.$store.state.tableData = []
      this.loading = true;

      this.$store.state.bodySearch.delete("page");
      if (page > 1 || type === "table") {
        this.$store.state.bodySearch.append("page", page);

        let url = `${this.$store.state.url}/v2/employee/Requests/search?type=additional`;
        Axios.post(url, this.$store.state.bodySearch, {
          headers: this.request_headers(),
        }).then((res) => {
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.searched,
          };
          let response = res.data.payload.data;
          response.map((item) => {
            if (item.length === 0) {
              this.loading = false;
            }
            this.setTbData(item);
            item["starChecked"] = false;
            return item;
          });

          this.allRequests.push(...response);
          this.loading = false;
        });
      }
    },

    selectedValue(itemSelected) {
      this.getAgentRequest(itemSelected);
    },

    getAgentRequest(itemSelected) {
      let body = new FormData();

      body.append("request_ids[0]", this.$store.state.selectId);
      body.append("agents_ids[0]", itemSelected.id);

      Axios.post(
        `${this.$store.state.url}/v2/employee/Requests/moveAllPendingRequests`,
        body,
        { headers: this.request_headers() }
      )
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          let error = { err };
          let message = error.err.response.data.message[0];
          this.showPopup("error", message);
        });
    },

    getTableData(count) {
      this.$store.state.tableData = [];
      this.loading = true;
      Axios.get(
        `${this.$store.state.url}/v2/employee/Requests?type=additional&page=${count}&per_page=${this.$store.state.counterOfShow}`,
        { headers: this.request_headers() }
      ).then((res) => {
        res.data.payload.data.map((item) => {
          this.setTbData(item);
        });
        this.loading = false;
      });
    },

    setTbData(items) {
      this.$store.state.tableData.push({
        id: items.id,
        "تاريخ الطلب": items.req_date,
        عميل: items.customer.name,
        الجوال: items.customer.mobile,
        "جهه العمل": this.getWorkList(items.customer.work),
        "هل هو مدعوم": this.returnValue(items.customer.is_supported),
        "راتب العميل": items.customer.salary,
        "هل وجد عقار": this.returnValue(items.real_estate.has_property),
        "هل لديه متضامن": this.returnValue(items.customer.has_joint),
        "هل لديه التزامات": this.returnValue(items.customer.has_obligations),
        "هل لديه تعثرات": this.returnValue(items.customer.has_financial_distress),
        "هل يمتلك عقار": this.returnValue(items.real_estate.owning_property),
        "تاريخ الميلاد الهجري": items.customer.birth_date_higri,
        "مصدر المعامله": SourceValue(items.source),
        "تم التحديث في": items.updated_at ? formatDate(items.updated_at) : "",
      });
    },

    returnValue(item) {
      if (item === 0 || !item || item === "no") {
        return "لا";
      } else {
        return "نعم";
      }
    },

    getWorkList(item) {
      let value = "";
      if (Number(item)) {
        value = Number(item);
      }
      if (typeof value === "number") {
        const obj = this.$store.state.works_list.find((item) => item.id == value);
        return obj.value;
      } else {
        return item;
      }
    },

    getAllRequests(count) {
      if (this.allRequests.length === 0 && this.copySupportRequests.length === 0) {
        this.getDataLoading = true;
      }
      this.loadMoreData = true;
      Axios.get(
        `https://appproduct.alwsata.com.sa/api/v2/employee/Requests?type=additional&page=${count}&per_page=${this.$store.state.counterOfShow}`,
        { headers: this.request_headers() }
      )
        .then((res) => {
          this.getDataLoading = false;
          let response = res.data.payload.data;
          response.map((item) => {
            this.setTbData(item);
            item["starChecked"] = false;
            return item;
          });

          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_additional,
          };

          this.allRequests.push(...response);
          this.copySupportRequests.push(...response);
        })
        .catch((err) => {
          this.loadingData = false;
          this.loadMoreData = false;
        })
        .finally(() => {
          this.loadMoreData = false;
          this.loadingData = false;
        });
    },
  },

  created() {
    this.$store.state.tableData = [];
    this.$store.state.back = false;
    this.$store.state.bodySearch = new FormData();
    this.$store.state.isSearch = false;
    this.getAllRequests();
    this.$store.state.startElement = [];
    this.$store.state.views = "cards";
  },
};
</script>

<style>
.noData {
  display: flex;
  justify-content: center;
  font-size: 22px;
  color: #3f0e40;
  margin-top: 70px;
  font-weight: bold;
}
</style>
