<template>
  <div>
    <v-list-item @click="to_request_details(item.id)">
      <v-list-item-icon class="ml-3">
        <v-icon>mdi-eye-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-title>
        فتح الطلب
      </v-list-item-title>
    </v-list-item>


    <router-link
      style="text-decoration: none"
      target="_blank"
      :to="{ name: 'request_details', params: { id: item.id } }"
    >
      <v-list-item>
        <v-list-item-icon class="ml-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.528"
            height="15.111"
            viewBox="0 0 13.528 10.111"
          >
            <g
              id="Icon_feather-eye-dark"
              data-name="Icon feather-eye-dark"
              transform="translate(-1 -5.5)"
            >
              <path
                id="Path_4040"
                data-name="Path 4040"
                d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
              <path
                id="Path_4041"
                data-name="Path 4041"
                d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                transform="translate(-7.444 -4.653)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title> فتح في تاب جديد</v-list-item-title>
      </v-list-item>
    </router-link>

    <v-list-item @click="toOrderRequest(item)">
      <v-list-item-icon class="ml-2">
        <OrderHistory />
      </v-list-item-icon>
      <v-list-item-title>سجل الطلب</v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import { axiosInstance } from "@/axios/config";
import { OrderHistory } from "@/assets/adminIcons/orderHistory";

export default {
  components: {
    OrderHistory
  },
  props: ["item"],
  data() {
    return {
      label: "",
      message: "",
    };
  },
  computed: {
    returnAllRequests() {
      return this.allStatus;
    },
  },
  methods: {
    to_request_details(item) {
      this.$store.state.requestId = item;
      this.$router.push({
        name: "request_details",
        params: { id: item },
      });
    },

    toOrderRequest(item) {
      console.log(item)
      this.$store.state.orderRequestId = item.id;
      if (this.$store.state.showCards || this.$store.state.showCards == false) {
        this.$store.state.orderRequestDialog = true;
      } else {
        this.$store.state.openOrderRequestSection = true;
      }
    },
   
  },
};
</script>
