<template>
  <page-component v-if="$store.state.cardsStyle">
    <template #content>
      <div class="cards mb-5 selected-slider">
        <v-row
          class="px-2 mx-1 vRowToShowData"
          style="display: inline-block; width: 100%"
        >
          <v-col
            class="app no-requests font-weight-bold"
            style="font-size: 36px"
            cols="12"
            v-if="!tableData.length"
          >
            لا توجد طلبات
          </v-col>

          <v-card flat>
            <v-layout row wrap>
              <v-col
                cols="12"
                lg="3"
                md="4"
                sm="6"
                xs="12"
                v-for="(item, i) in tableData"
                :key="i"
                class="px-2 py-1"
              >
                <v-card class="pa-3 position-relative" style="height: 100%">
                  <span
                    class="right-color requestType"
                    :style="`${
                      item.status == `طلب تم فتحه`
                        ? `background-color:#40BE72`
                        : item.status == `طلب جديد`
                        ? `background-color:#3B5DE7`
                        : item.status == `مؤرشف لدى استشاري المبيعات`
                        ? `background-color:#017E84`
                        : item.status == `طلب مرفوض`
                        ? `background-color: indianred`
                        : item.status == `مكتمل`
                        ? `background-color: #DEB523`
                        : item.status == `في انتظار موافقة مدير المبيعات`
                        ? `background-color: #FF715B`
                        : `background-color:black`
                    }`"
                  >
                  </span>
                  <div class="card-head d-flex justify-space-between align-center">
                    <v-card-title class="pa-0 requestTitle">
                      {{ item.request.customer?.name }}
                    </v-card-title>
                    <div>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            width="30"
                            height="30"
                            color="transparent"
                            depressed
                            fab
                            light
                            v-bind="attrs"
                            v-on="on"
                            style="margin-left: -10px"
                          >
                            <img src="@/assets/icons/eyeIcon.svg" />
                          </v-btn>
                        </template>
                        <SingleProccess :item="item" />
                      </v-menu>
                    </div>
                  </div>
                  <div
                    class="req-status-parent mt-1 d-flex justify-space-between align-center"
                  >
                    <span class="req-type" style="color: #444b5a; font-size: 15px">
                      {{ item.request.type || "لايوجد نوع للطلب" }}
                    </span>
                    <span
                      class="req-status spanRequestType"
                      :style="`${
                        item.status == `طلب تم فتحه`
                          ? `background-color:#40BE72`
                          : item.status == `طلب جديد`
                          ? `background-color:#3B5DE7`
                          : item.status == `مؤرشف لدى استشاري المبيعات`
                          ? `background-color:#017E84`
                          : item.status == `مكتمل`
                          ? `background-color: #DEB523`
                          : item.status == `طلب مرفوض`
                          ? `background-color: indianred`
                          : item.status == `في انتظار موافقة مدير المبيعات`
                          ? `background-color: #FF715B`
                          : `background-color:black`
                      }`"
                      >{{ item.status }}
                    </span>
                  </div>
                  <div
                    class="req-status-parent mt-1 d-flex justify-space-between align-center"
                  >
                    <span class="req-type" style="color: #444b5a; font-size: 15px">
                      حالة الطلب عند استشاري المبيعات
                    </span>
                    <span
                      class="req-status"
                      :style="
                        item.request.status != null && item.request.status.length > 21
                          ? ` font-size: 12px !important;`
                          : ` font-size: 15px;`
                      "
                    >
                      {{ item.request?.status }}
                    </span>
                  </div>
                  <div
                    v-if="CurrentRole == 1 || CurrentRole == 5"
                    class="req-status-parent mt-1 d-flex justify-space-between align-center"
                  >
                    <span class="req-type" style="color: #444b5a; font-size: 15px">
                      استشاري المبيعات
                    </span>
                    <span class="req-status">
                      {{ item.request.user?.name }}
                    </span>
                  </div>

                  <div
                    v-if="0"
                    class="req-date-parent my-2 d-flex justify-space-between align-center"
                  >
                    <span class="req-date">
                      {{
                        item.agent_date !== undefined && item.agent_date != null
                          ? item.agent_date.split(" ")[0]
                          : ""
                      }}
                    </span>
                    <span class="req-status" :style="`color: #444B5A ; font-size: 15px;`">
                      {{ item.request.class_id_agent_text || "لايوجد تصنيف معاملة" }}
                    </span>
                  </div>
                  <div
                    class="req-date-parent my-2 d-flex justify-space-between align-center"
                  >
                    <span class="req-date">
                      {{
                        item?.date_time_quality && item.date_time_quality?.reminder_date
                          ? item.date_time_quality.reminder_date.split(" ")[0]
                          : ""
                      }}
                    </span>
                    <span class="req-status" :style="`color: #444B5A ; font-size: 15px;`">
                      {{ item.quality_classification || "لايوجد تصنيف معاملة" }}
                    </span>
                  </div>
                  <hr style="height: 1px !important; opacity: 0.3" class="mb-0" />
                  <div class="desc parent">
                    <v-tooltip bottom color="#451646">
                      <template v-slot:activator="{ on }">
                        <v-card-text v-on="on" class="pt-1 pb-1 px-0">
                          {{
                            item.quality_note
                              ? item.quality_note.length > 50
                                ? item.quality_note.substring(0, 50) + "..."
                                : item.quality_note
                              : "لايوجد ملاحظات"
                          }}
                        </v-card-text>
                      </template>
                      <div style="font-size: 14px">
                        {{ item.quality_note || "لايوجد ملاحظات" }}
                      </div>
                    </v-tooltip>
                  </div>
                  <div class="desc parent">
                    <v-tooltip bottom color="#451646">
                      <template v-slot:activator="{ on }">
                        <v-card-text v-on="on" class="pt-1 pb-1 px-0">
                          ملاحظة استشاري المبيعات :
                          {{
                            item.request.agent_notes
                              ? item.request.agent_notes.length > 50
                                ? item.request.agent_notes.substring(0, 30) + "..."
                                : item.request.agent_notes
                              : "لايوجد ملاحظات"
                          }}
                        </v-card-text>
                      </template>
                      <div style="font-size: 14px; background-color: #451646">
                        ملاحظة استشاري المبيعات :
                        {{ item.request.agent_notes || "لايوجد ملاحظات" }}
                      </div>
                    </v-tooltip>
                  </div>
                </v-card>
              </v-col>
            </v-layout>

            <v-card v-intersect="infiniteScrolling"></v-card>
          </v-card>
        </v-row>
      </div>
    </template>
  </page-component>
</template>

<script>
import SingleProccess from "@/views/quality/AllRequests/SingleProccess";
export default {
  name: "CardsStyle",
  props: ["tableData"],
  components: { SingleProccess },

  data() {
    return {
      loading: false,
      CurrentRole: 0,
      pages: 1,
      pagination: {
        count: 0,
        limit: 12,
      },
      stopLoadingData: false,
      page: this.$store.state.InfinityPageNumber,
    };
  },
  methods: {
    infiniteScrolling() {
      setTimeout(() => {
        this.page++;
        this.$emit("pageCount", this.page);
      }, 100);
    },
  },
  mounted() {
    this.CurrentRole = window.localStorage.getItem("role");
  },
};
</script>
