<template>
  <v-row justify="center">
    <v-dialog v-model="$store.state.FilterRateDialog" persistent width="1024">
      <v-card>
        <v-card-title>
          <!-- <span> ابحث الان  </span> -->
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6" lg="6" md="6" class="pb-5 pt-0">
                <div class="mb-2 font-weight-bold">استشاري المبيعات</div>
                <v-autocomplete
                  chips
                  deletable-chips
                  color="primary"
                  hide-details
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  append-icon
                  v-model="agents"
                  :items="allAgents"
                  multiple
                >
                  <template v-slot:append>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.003"
                      height="6.291"
                      viewBox="0 0 11.003 6.291"
                      class="mt-3"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-back"
                        data-name="Icon ionic-ios-arrow-back"
                        d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                        transform="translate(0 6.291) rotate(-90)"
                        fill="#2c2c2c"
                      />
                    </svg>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="6" lg="6" md="6" class="pb-5 pt-0">
                <div class="mb-2 font-weight-bold">عدد النجوم</div>
                <v-autocomplete
                  chips
                  deletable-chips
                  color="primary"
                  :items="[
                    { id: 1, value: '1 نجمة' },
                    { id: 2, value: '2 نجمتان' },
                    { id: 3, value: '3 نجوم' },
                    { id: 4, value: '4 نجوم' },
                    { id: 5, value: '5 نجوم' },
                  ]"
                  hide-details
                  item-text="value"
                  item-value="id"
                  outlined
                  dense
                  append-icon
                  v-model="number_of_stars"
                  multiple
                >
                  <template v-slot:append>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.003"
                      height="6.291"
                      viewBox="0 0 11.003 6.291"
                      class="mt-3"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-back"
                        data-name="Icon ionic-ios-arrow-back"
                        d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                        transform="translate(0 6.291) rotate(-90)"
                        fill="#2c2c2c"
                      />
                    </svg>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="6" lg="6" md="6" class="pb-5 pt-0">
                <div class="mb-2 font-weight-bold">
                  <span>تاريخ التقييم من</span>
                </div>
                <v-text-field
                  type="date"
                  hide-details
                  outlined
                  dense
                  v-model="rate_date_from"
                >
                  <template v-slot:append>
                    <svg
                      class="mt-1"
                      id="calendar"
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.396"
                      height="16.989"
                      viewBox="0 0 17.396 16.989"
                    >
                      <path
                        id="Path_2784"
                        data-name="Path 2784"
                        d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                        transform="translate(-4.21 -7.917)"
                        fill="#6c757d"
                      />
                      <path
                        id="Path_2785"
                        data-name="Path 2785"
                        d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                        transform="translate(-4.21 -4.86)"
                        fill="#6c757d"
                      />
                    </svg>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="6" lg="6" md="6" class="pb-5 pt-0">
                <div class="mb-2 font-weight-bold">
                  <span> تاريخ التقييم الي </span>
                </div>
                <v-text-field
                  type="date"
                  hide-details
                  outlined
                  dense
                  v-model="rate_date_to"
                >
                  <template v-slot:append>
                    <svg
                      class="mt-1"
                      id="calendar"
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.396"
                      height="16.989"
                      viewBox="0 0 17.396 16.989"
                    >
                      <path
                        id="Path_2784"
                        data-name="Path 2784"
                        d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                        transform="translate(-4.21 -7.917)"
                        fill="#6c757d"
                      />
                      <path
                        id="Path_2785"
                        data-name="Path 2785"
                        d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                        transform="translate(-4.21 -4.86)"
                        fill="#6c757d"
                      />
                    </svg>
                  </template>
                </v-text-field>
              </v-col>

             

              <v-col cols="6" lg="6" md="6" class="pb-5 pt-0">
                <div class="mb-2 font-weight-bold">مصدر التقييم</div>
                <v-autocomplete
                  chips
                  deletable-chips
                  color="primary"
                  :items="[
                    { id: 1, value: 'الموقع' },
                    { id: 2, value: 'التطبيق' },
                    { id: 3, value: 'الرسائل النصية' },
                    { id: 4, value: 'واتس اب' },
                  ]"
                  hide-details
                  item-text="value"
                  item-value="id"
                  outlined
                  dense
                  append-icon
                  v-model="source_of_rate"
                >
                  <template v-slot:append>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.003"
                      height="6.291"
                      viewBox="0 0 11.003 6.291"
                      class="mt-3"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-back"
                        data-name="Icon ionic-ios-arrow-back"
                        d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                        transform="translate(0 6.291) rotate(-90)"
                        fill="#2c2c2c"
                      />
                    </svg>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialog = $store.state.FilterRateDialog = false"
          >
            الغاء
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="startSearch">
            بحث
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
//   props: [],
  data() {
    return {
      agents: null,
      rate_date_from: null,
      rate_date_to: null,
      number_of_stars: null,
      source_of_rate: null,
      allAgents: null,
    };
  },
  methods: {
    startSearch() {
      // Can i use custom event ..
      this.$store.state.FilterRateDialog = false
      this.$emit("startRateSearch", {
        agents: this.agents,
        rate_date_from: this.rate_date_from,
        rate_date_to: this.rate_date_to,
        stars: this.number_of_stars,
        source: this.source_of_rate,
      });
      // Or can i use vuex instead ???
    },

    fillAllAgents() {

      let url = 'https://appproduct.alwsata.com.sa/api/employee/admin/filterUsers';
      axios.post(url, {role: "agent"}, {headers: this.request_headers()}).then(res => {
        this.allAgents = (res.data.payload);
        this.allAgents.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      })
      // axios
      //   .get(
      //     "https://appproduct.alwsata.com.sa/api/sales/agents-statistics",
      //     { headers: this.request_headers() }
      //   )
      //   .then((res) => {
      //     this.allAgents = res.data.payload.data;
      //     this.allAgents.map((item) => {
      //       item["color"] = "red";
      //       item["text"] = item["name"];
      //       return item;
      //     });
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    
  },

  mounted() {
    this.fillAllAgents();
  },
};
</script>
