<template>
  <div class="all-requests">
    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
      "
      class="btns-dad"
    >
      <h2>اجازات الموظفين</h2>
      
      </div>
    <v-row class="px-2 mx-1 vRowToShowData" style="display: inline-block; width: 100%; margin-top: 1rem;">

      <v-col  class="app no-requests font-weight-bold" style="font-size: 36px" cols="12" v-if="!loading && !items.length">
        لا توجد بيانات
      </v-col>
      <v-col  v-if="loading" class="app no-requests font-weight-bold" style="font-size: 36px" cols="12" >
        <v-progress-linear
      indeterminate
      color="green"
    ></v-progress-linear>
      </v-col>

      <v-layout row wrap>
        <v-col cols="12" lg="3" md="4" sm="6" xs="12" v-for="(item, i) in items" :key="i" class="px-2 py-1">
          <v-card class="pa-3 position-relative" style="height: 100%">
            <span class="right-color requestType"
              :style="`background-color:#3f0e40`">
            </span>
            <div class="card-head d-flex justify-space-between align-center">
              <v-card-title class="pa-0 requestTitle" style="color: #3f0e40;">
                {{ item.user_name}}
              </v-card-title>
                    <div class="icons" v-if="item.history?.manager_date == null">
                      <v-menu v-if="item.history.hr_date == null" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn width="30" height="30" color="transparent" depressed fab light v-bind="attrs" v-on="on"
                            style="margin-left: -10px">
                            <img src="@/assets/icons/eyeIcon.svg" />
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="accept_vacation(item)">
                            <v-list-item-icon class="ml-3">
                              <v-icon
                                right
                                dark
                                color="#45c455"
                              >
                                mdi-check-circle
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>موافقة</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="reject_vacation(item)">
                            <v-list-item-icon class="ml-3">
                              <v-icon
                                right
                                dark
                                color="#e43c30"
                              >
                                mdi-close-circle
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>رفض</v-list-item-title>
                          </v-list-item>
                        </v-list>
                       
                      </v-menu>
                    </div>
            </div>
            <div class="req-status-parent mt-1 d-flex justify-space-between align-center">
              <span class="req-type" style="color: #017e84 ; font-size: 15px;">
                البديل
              </span>
              <span class="req-status ">{{ item.alternative_name }}
              </span>
            </div>
            <div class="req-status-parent mt-1 d-flex justify-space-between align-center">
              <span class="req-type" style="color: #eeb902 ; font-size: 15px;">
                اليوم / من
              </span>
              <span class="req-status ">{{ item.from }}
              </span>
            </div>
            <div class="req-status-parent mt-1 d-flex justify-space-between align-center">
              <span class="req-type" style="color: #116a9d ; font-size: 15px;">
                اليوم / الي
              </span>
              <span class="req-status ">{{ item.to }}
              </span>
            </div>
            <div class="req-status-parent mt-1 d-flex justify-space-between align-center">
              <span class="req-type" style="color: #6dcb92 ; font-size: 15px;">
               رصيد الاجازة
              </span>
              <span class="req-status ">{{ item.vacation_balance }}
              </span>
            </div>
            <div class="req-status-parent mt-1 d-flex justify-space-between align-center">
              <span class="req-type" style="color: #fe816e ; font-size: 15px;">
               مدة الاجازة
              </span>
              <span class="req-status ">{{ item.vacation_duration }}
              </span>
            </div>
            <div class="req-status-parent mt-1 d-flex justify-space-between align-center">
              <span class="req-type" style="color: #116a9d ; font-size: 15px;">
               الرصيد المتبقي بعد الموافقة
              </span>
              <span class="req-status ">{{ item.remainder_balance }}
              </span>
            </div>
            <v-divider></v-divider>
            <div v-if="item.history?.manager_date != null" class="req-status-parent mt-1 d-flex justify-space-between align-center">
              <span class="req-type" style="color: #116a9d ; font-size: 15px;">
                الاجراء
              </span>
              <span class="req-status " v-if="item.history?.manager_accept_or_no == 1">
                تم الموافقة
              </span>
              <span class="req-status " v-if="item.history?.manager_accept_or_no == 0">
                تم رفض طلب الاجازة
              </span>
            </div>
            <div v-if="item.history?.manager_date != null" class="req-status-parent mt-1 d-flex justify-space-between align-center">
              <span class="req-type" style="color: #116a9d ; font-size: 15px;">
                تاريخ الاجراء
              </span>
              <span class="req-status ">
                {{ item.history?.manager_date }}
              </span>
            </div>
        
          </v-card>
        </v-col>
      </v-layout>

    </v-row>
    
    <!-- cards -->
  </div>
</template>

<script>
import Axios from "axios";
export default {
  name: "HelloWorld",
  data: () => ({
    loading: true,
    page: 1,
    /* eslint-disable */
    items: [],
    addNewRequestDialog: false,
    selected_requests: [],
  }),
  methods: {
    goto_add_vaction(){
      this.$router.push({
          name: "salesManager_add_vacation"
        });
    },
    async get_all_items() {
      this.loading = true;
      await Axios.get(
        `https://appproduct.alwsata.com.sa/api/salesmanager/agent_vacation`,
        {
          headers: this.request_headers(),
        }
      ).then((response) => {
        this.items = response.data.payload;
        this.loading = false;
      });
    },
    async accept_vacation(item) {
      this.loading = true;
      let data = {
        user_type: "salesmanager",
        vacation_id : item.id,
        accept: 1
        
      }
      await Axios.post(
        `https://appproduct.alwsata.com.sa/api/salesmanager/accept_vacation`,data,
        {
          headers: this.request_headers(),
        }
      ).then((res) => {
          this.showPopup("success", res.data.payload);
          this.get_all_items();
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
          this.verifing = false;
        });
    },
    async reject_vacation(item) {
      this.loading = true;
      let data = {
        user_type: "salesmanager",
        vacation_id : item.id,
        accept: 0
        
      }
      await Axios.post(
        `https://appproduct.alwsata.com.sa/api/salesmanager/accept_vacation`,data,
        {
          headers: this.request_headers(),
        }
      ).then((res) => {
          this.showPopup("success", res.data.payload);
          this.get_all_items();
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
          this.verifing = false;
        });
    },
  },
  async created() {
    await this.get_all_items();
  },
};
</script>

