<template>
  <div style="position: relative">
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
              min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-img
            style="
                                  width: 20px;
                                  height: 25px;
                                  position: absolute;
                                  left: 12px;
                                  top: 6px;
                                "
            id="logo"
            contain
            src="@/assets/calendarGray.svg"
        />
        <i aria-hidden="true" @click="handleDelete" style="position: absolute; left: 40px; top: 8px" class="v-icon notranslate mdi mdi-close theme--light"></i>
        <v-text-field
            v-model="date"
            :label="label"
            prepend-icon="mdi-calendar"
            outlined
            dense
            v-bind="attrs"
            v-on="on"
            readonly
        ></v-text-field>
      </template>
        <v-date-picker v-model="date" :active-picker.sync="activePicker"
                       :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                       min="1950-01-01" @change="save"></v-date-picker>
    </v-menu>
  </div>
</template>


<script>
export default {
  name: "TodayPickerInput",
  props: ["name", "label" ,"newStartDate",'selected'],
  data: () => ({
    activePicker: null,
    date: new Date().getFullYear() +
        "-" +
        ("0" + (new Date().getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + new Date().getDate()).slice(-2),
    menu: false,
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },

    newStartDate(val,oldval) {
      if(val != oldval){
        this.date = val;
      }
    },

    selected(val) {
      if(val){
        this.date = val;
      }
    },
  },
  methods: {
    handleDelete(){
      this.date = null
      this.$emit('dateValue', this.date)
    },
    save(date) {
      this.$refs.menu.save(date)
      this.$emit('dateValue', date)
    },
  },

  created(){
    this.$emit('dateValue', this.date);
  }


}
</script>

