export const base = {
  computed: {},
  methods: {
    setBreadCrumbs(items) {
      return (this.$store.state.bcItems = items);
    },
    showPopup(status, msg, link, copy) {
      this.$emit("submitted");
      this.$root.$emit("popup", status, msg, link, copy);
    },
    showPopupError(status, msg, link, copy) {
      this.$emit("submitted");
      this.$root.$emit("popup-error", status, msg, link, copy);
    },
    showPopupWithBtn(status, msg,text_link,link) {
      this.$emit("submitted");
      this.$root.$emit("popup-btn", status, msg, text_link, link);
    },
    exportTableToCsv(table, sheetName) {
      const XLSX = require("xlsx");
      let wb = XLSX.utils.table_to_book(table, { sheet: "sheet1" });
      XLSX.writeFile(wb, sheetName + new Date() + ".xlsx");
    },
    printTable(items, headers, tableTitle) {
      let dataArr = [];
      let props = [];
      for (let val of items) {
        let obj = {};
        headers.forEach((header) => {
          if (header.text) {
            if (header.text != "العمليات") {
              props.push(header.text);
            }
            if (typeof val[header.value] == "object" && val[header.value]) {
              obj[header.text] = val[header.value].value;
            } else {
              obj[header.text] = val[header.value];
            }
            if (obj[header.text] == null) {
              obj[header.text] = "";
            }
          }
        });
        delete obj["العمليات"];
        dataArr.push(obj);
      }
      props.reverse()
      const finalProps = [...new Set(props)];
      const PrintThis = require("print-js");
      PrintThis({
        printable: dataArr,
        type: "json",
        properties: finalProps,
        gridHeaderStyle: "font-size: 13px;padding: 5px;border: 1px solid",
        gridStyle: "font-size: 13px;padding: 5px;border: 1px solid;text-align: center",
        header: tableTitle,
        headerStyle: "text-align: end",
      });
    },

     request_headers() {
      const theCookie =  document.cookie
        .split(";")
        .map((coki) => coki.split("="))
        .reduce(
          (acc, [key, val]) => ({
            ...acc,
            [key.trim()]: decodeURIComponent(val),
          }),
          {}
        )["wsataJwt"];


        if ( theCookie !== undefined){
          let isAuthAdmin = 'false';
          let authAdminId = '';

          let Auth_sales_manager = 'false';
          let Auth_sales_manager_id = '';

          // if (localStorage["role"] && localStorage["role"] == "7") {
          //   isAuthAdmin = 'true';
          //   authAdminId = JSON.parse(theCookie).agent_id;
          // }

          if (localStorage["roleBeforeSwitched"] && localStorage["roleBeforeSwitched"] == "1") {
            Auth_sales_manager = 'true';
            Auth_sales_manager_id = localStorage["salesManagerIdBeforeSwitched"];
          }

          if (localStorage["roleBeforeSwitched"] && localStorage["roleBeforeSwitched"] == "7") {
            isAuthAdmin = 'true';
            authAdminId = localStorage["adminIdBeforeSwitched"];
          }

          return {
            "x-api-key":
              "WFKvB4Vjr8v5BVmxY7CDQ6ZMAU8DSHSBFKCMVpkfd5hhZyKTvnV3uh5XwxSwhnfeUbptZ7Z4XFJCR4vKXeHWGHTZ6djsYXjdAatf",
            Authorization: `Bearer ${JSON.parse(theCookie).token}`,
            "appplatform": 'vue',
            "Auth-admin": isAuthAdmin,
            "Auth-admin-id": authAdminId,
            "Auth-sales-manager" : Auth_sales_manager,
            "Auth-sales-manager-id" : Auth_sales_manager_id,
          }; 
        }
    },


    work_source(id) {
      for (let i = 0; i < this.$store.state.work_sources.length; i++) {
        if(this.$store.state.work_sources[i]['id'] == id) {
          return this.$store.state.work_sources[i]['value']
        }
      }
      return id
    },
    salary_source(id) {
      for (let i = 0; i < this.$store.state.salary_sources.length; i++) {
        if(this.$store.state.salary_sources[i]['id'] == id) {
          return this.$store.state.salary_sources[i]['value']
        }
      }
      return id
    },
    works_list(id) {
      for (let i = 0; i < this.$store.state.works_list.length; i++) {
        if(this.$store.state.works_list[i]['id'] == id) {
          return this.$store.state.works_list[i]['value']
        }
      }
      return id
    },
    request_class(id) {
      for (let i = 0; i < this.$store.state.request_class.length; i++) {
        if(this.$store.state.request_class[i]['id'] == id) {
          return this.$store.state.request_class[i]['value']
        }
      }
      return id
    },
    military_rank(id) {
      for (let i = 0; i < this.$store.state.military_ranks.length; i++) {
        if(this.$store.state.military_ranks[i]['id'] == id) {
          return this.$store.state.military_ranks[i]['value']
        }
      }
      return id
    },
    request_status(id) {
      for (let i = 0; i < this.$store.state.request_statuses.length; i++) {
        if(this.$store.state.request_statuses[i]['id'] == id) {
          return this.$store.state.request_statuses[i]['value']
        }
      }
      return id
    },
    agent_customer(id) {
      return this.$store.state.agent_customers.filter((el) => el.id == id)[0][
        "name"
      ];
    },
    show_Field_log(field) {
      this.$store.state.field = field;
      this.$store.state.field_log = true;
    },
  },
};
