<template>
  <div>
    <UserCard v-if="$store.state.isProfile" />
    <ProfileHeader />
    <v-form class="mt-5 mb-5" :disabled="!active">
      <v-card flat>
        <v-layout
          row
          wrap
          style="background-color: #f3f5f7"
          class="userProfile__container"
        >
          <v-col cols="12" lg="4" md="4" sm="6" xs="12">
            <v-card class="pa-3 position-relative userProfile__card">
              <v-col cols="12" sm="12">
                <div style="display: flex; align-items: center" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('work')"
                    />
                    نوع الهوية
                  </div>
                  <div class="horizontal__line"></div>

                  <v-autocomplete
                    :items="identity"
                    hide-details
                    v-model="$store.state.userProfileData.control_identity_id"
                    item-text="value"
                    item-value="id"
                    style="border: none !important"
                    class="mr-1"
                    outlined
                    dense
                  >
                  </v-autocomplete>
                  <img src="@/assets/icons/down-arrow.svg" />
                </div>
              </v-col>

              <v-col cols="12" sm="12">
                <div style="display: flex" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('customerName')"
                    />
                    رقم الهوية
                  </div>
                  <div class="horizontal__line"></div>
                  <div>
                    <v-text-field
                      type="text"
                      class="form-control"
                      style="
                        background-color: #fbfbfb;
                        border-right: #fbfbfb;
                        margin-top: -20px;
                      "
                      v-model="$store.state.userProfileData.residence_number"
                    />
                  </div>
                </div>
              </v-col>
            </v-card>
          </v-col>

          <v-col cols="12" lg="4" md="4" sm="6" xs="12">
            <v-card class="pa-3 position-relative userProfile__card">
              <v-col cols="12" sm="12">
                <div style="display: flex; align-items: center" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('joint_hiring_date')"
                    />
                    تاريخ إنتهاء الهوية هجري
                  </div>
                  <div class="horizontal__line"></div>

                  <v-text-field
                    style="margin-bottom: 5px"
                    hide-details
                    v-model="$store.state.userProfileData.residence_end_date"
                    class="mr-1"
                    dense
                    append-icon
                  >
                    <template v-slot:append>
                      <svg
                        @click="showEndHiringTwoDate = !showEndHiringTwoDate"
                        id="calendar"
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.396"
                        height="16.989"
                        viewBox="0 0 17.396 16.989"
                        style="margin-top: 15px; cursor: pointer"
                      >
                        <path
                          id="Path_2784"
                          data-name="Path 2784"
                          d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                          transform="translate(-4.21 -7.917)"
                          fill="#6c757d"
                        />
                        <path
                          id="Path_2785"
                          data-name="Path 2785"
                          d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                          transform="translate(-4.21 -4.86)"
                          fill="#6c757d"
                        />
                      </svg>
                    </template>
                  </v-text-field>
                  <v-hijri-date-picker
                    @change="showEndHiringTwoDate = false"
                    v-if="showEndHiringTwoDate"
                    style="position: absolute; z-index: 1000"
                    v-model="$store.state.userProfileData.residence_end_date"
                    locale="ar"
                  />
                </div>
              </v-col>
            </v-card>
          </v-col>

          <v-col cols="12" lg="4" md="4" sm="6" xs="12">
            <v-card class="pa-3 position-relative userProfile__card">
              <v-col cols="12" sm="12">
                <div style="display: flex; align-items: center" class="input__group">
                  <div style="display: flex; align-items: center">
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('joint_hiring_date')"
                    />
                    تاريخ إنتهاء الهوية ميلادي
                  </div>
                  <div class="horizontal__line"></div>

                  <v-text-field
                    type="date"
                    style="margin-bottom: 5px"
                    hide-details
                    v-model="$store.state.userProfileData.residence_end_date_m"
                    class="mr-1"
                    dense
                    append-icon
                  >
                    <template v-slot:append>
                      <svg
                        id="calendar"
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.396"
                        height="16.989"
                        viewBox="0 0 17.396 16.989"
                        style="margin-top: 15px"
                      >
                        <path
                          id="Path_2784"
                          data-name="Path 2784"
                          d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                          transform="translate(-4.21 -7.917)"
                          fill="#6c757d"
                        />
                        <path
                          id="Path_2785"
                          data-name="Path 2785"
                          d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                          transform="translate(-4.21 -4.86)"
                          fill="#6c757d"
                        />
                      </svg>
                    </template>
                  </v-text-field>
                </div>
              </v-col>
            </v-card>
          </v-col>
        </v-layout>
      </v-card>
    </v-form>

    <v-btn class="primary" @click="active = true" v-if="!active">تعديل</v-btn>

    <v-btn class="primary" v-if="active" @click="handleEdit">حفظ</v-btn>

    <v-btn @click="active = false" v-if="active" style="margin-right: 5px">عرض</v-btn>

    <v-snackbar
      v-model="activeSnack"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <div>
        <span class="font-weight-bold" style="font-size: 16px">تم التعديل</span>
      </div>
      <template v-slot:action>
        <v-btn icon small @click="activeSnack = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script setup>
import ProfileHeader from "./ProfileHeader.vue";
import { ref, onMounted } from "vue";
import router from "@/routes";
import { axiosInstance } from "@/axios/config";
import UserCard from "./UserCard.vue";
import { store } from "@/store";

const active = ref(false);
const activeSnack = ref(false);
const showEndHiringTwoDate = ref(false);

const handleEdit = () => {
  const formData = new FormData();

  Object.entries(store.state.userProfileData).forEach(([key, value]) => {
    formData.append(key, value);
  });
  axiosInstance
    .post(`/employee/hr/user-update-profile/${router.currentRoute.params.id}`, formData)
    .then((res) => {
      activeSnack.value = true;
    });
};

const identity = ref([]);
const getIdentity = () => {
  axiosInstance.get(`/employee/hr/employee-control-list`).then((res) => {
    identity.value = res.data.payload.identity;
  });
};

onMounted(() => {
  getIdentity();
});
</script>
