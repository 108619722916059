var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"notifs position-absolute"},[_c('v-progress-circular',{style:(`position: fixed;
      left: 50%;
      top: 50%;
      width: 50px;
      height: 50px;
      transform: translate(-50%, -50%);${
        _setup.loadingNotif ? 'z-index:1' : 'z-index:-100'
      }`),attrs:{"active":_setup.loadingNotif,"indeterminate":_setup.loadingNotif,"color":"primary"}}),_c('div',{staticClass:"the-container mx-2 my-10"},_vm._l((_setup.items),function(item,i){return _c('div',{key:i,staticClass:"cont-dad"},[_c('div',{staticClass:"upper"},[_c('div',{staticClass:"right-side"},[_c('h2',{staticClass:"notif-title"},[_vm._v(" "+_vm._s(item.customer ? item.customer.name : "")+" ")]),_c('span',{staticClass:"mr-4"},[_vm._v(_vm._s(item.notif_time)+" - "+_vm._s(item.notif_date))])]),_c('div',{staticClass:"left-side"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"-12px"},attrs:{"color":"transparent","depressed":"","small":"","dark":""}},'v-btn',attrs,false),on),[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"3.548","height":"16.219","viewBox":"0 0 3.548 16.219"}},[_c('path',{attrs:{"id":"menu","d":"M1.774,3.548A1.851,1.851,0,0,1,.507,3.016,1.765,1.765,0,0,1,0,1.774,2,2,0,0,1,.507.507,1.781,1.781,0,0,1,1.774,0,1.882,1.882,0,0,1,3.016.507a1.8,1.8,0,0,1,.532,1.267A1.819,1.819,0,0,1,1.774,3.548Zm7.577-.532a1.791,1.791,0,0,0,.532-1.242A1.9,1.9,0,0,0,9.351.507,1.765,1.765,0,0,0,8.109,0,1.946,1.946,0,0,0,6.842.507a1.781,1.781,0,0,0-.507,1.267,1.882,1.882,0,0,0,.507,1.242,1.744,1.744,0,0,0,2.509,0Zm6.336,0a1.791,1.791,0,0,0,.532-1.242A1.9,1.9,0,0,0,15.687.507,1.765,1.765,0,0,0,14.445,0a1.946,1.946,0,0,0-1.267.507,1.781,1.781,0,0,0-.507,1.267,1.882,1.882,0,0,0,.507,1.242,1.744,1.744,0,0,0,2.509,0Z","transform":"translate(0 16.219) rotate(-90)","fill":"#acacac"}})])])]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{
                  name: 'quality_request_details',
                  params: { id: item.request_number },
                }}},[_c('v-list-item-icon',{staticClass:"ml-2 pt-1"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"18.528","height":"15.111","viewBox":"0 0 13.528 10.111"}},[_c('g',{attrs:{"id":"Icon_feather-eye-dark","data-name":"Icon feather-eye-dark","transform":"translate(-1 -5.5)"}},[_c('path',{attrs:{"id":"Path_4040","data-name":"Path 4040","d":"M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z","fill":"none","stroke":"#000","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1"}}),_c('path',{attrs:{"id":"Path_4041","data-name":"Path 4041","d":"M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z","transform":"translate(-7.444 -4.653)","fill":"none","stroke":"#000","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1"}})])])]),_c('v-list-item-title',[_vm._v("فتح")])],1)],1)],1)],1)]),_c('p',{staticClass:"desc"},[_vm._v(" "+_vm._s(item.notification_content)+" ")])])}),0),(_setup.items.length > 0)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[(!_setup.loadingNotif)?_c('v-chip',{staticClass:"font-weight-bold my-0 py-0",attrs:{"outlined":"","color":"primary"}},[_vm._v(" الإجمالي : "+_vm._s(_setup.count)+" ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('v-pagination',{attrs:{"length":_setup.pages || 1,"circle":""},model:{value:(_setup.page),callback:function ($$v) {_setup.page=$$v},expression:"page"}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }