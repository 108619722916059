<template>
  <div>
    <ProcessGroup @getData="reloadPage" :selectedItems="selected_requests" type="moveAllReqsNewRequests" />
    <div v-if="!this.$store.state.needNewLoading" style="margin-top: 40px">
      <div v-if="$store.state.views === 'cards'">
        <div v-if="this.$store.state.needNewData.length > 0">
          <RequestsCard
            :CardData="this.$store.state.needNewData"
            v-if="!this.$store.state.needNewLoading"
            @pageCount="handlePageNumber"
            @back="handleBack"
          />
          <div>
            <LoadingMore v-if="loadMoreData" style="margin: 40px" />
          </div>
        </div>
        <div v-else>
          <div>
            <button
              @click="handleBack"
              style="
                width: 70px;
                height: 40px;
                border-radius: 5px;
                margin: 50px;
                color: white;
                background-color: #441545;
                direction: ltr;
              "
            >
              رجوع
            </button>
          </div>
          <div class="noData">لا يوجد بيانات</div>
        </div>
      </div>
      <div v-else-if="$store.state.views === 'table'" class="table__view">
        <table-component
          :showSelect="true"
          itemKey="id"
          :selectedRows.sync="selected_requests"
          :headers="headers"
          :pagination="this.$store.state.pagination"
          :limit="$store.state.counterOfShow"
          :items="this.$store.state.tableData"
          :loading="loading"
          @refresh="getTableData"
          v-model="page"
        >
          <template slot="item.comment" slot-scope="props">
            <span v-if="!props.item.comment"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.comment.length > 7
                    ? props.item.comment.substring(0, 7) + "..."
                    : props.item.comment
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.comment }}</div>
            </v-tooltip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="#3f0e40" small depressed dark v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <AddNewProcess :item="item['id']" :request_id="item.request_id" />
              </v-list>
            </v-menu>
          </template>
        </table-component>
        <OrderRequestSection
          v-if="this.$store.state.openOrderRequestSection"
          style="margin-top: 50px"
        />
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
      "
      v-else
    >
      <LoadingMore />
    </div>
    <QualityDialog
      :selectedItems="selected_requests"
      type="moveReqsNewToQualityReqArray"
    />
    <AdminDialog
      @selectedValue="selectedValue"
      :items="this.$store.state.allStatusAgent"
      label="استشاري المبيعات"
    />
    <OrderRequestDialog />
    <CallsDialog />
  </div>
</template>

<script>
import Axios from "axios";
import RequestsCard from "@/components/Charts/RequestsCard.vue";
import LoadingMore from "@/components/Charts/LoadingMore.vue";
import { formatDate } from "@/mixins/formatDate";
import { SourceValue } from "@/mixins/sourceValue";
import AddNewProcess from "@/views/admin/Requests/MoveToNew/AddNewProcess.vue";
import AdminDialog from "@/components/AdminComponent/AdminDialog.vue";
import ProcessGroup from "@/views/admin/Requests/MoveToNew/ProcessGroup.vue";
import QualityDialog from "@/components/AdminComponent/QualityDialog.vue";
import OrderRequestDialog from "@/components/AdminComponent/OrderRequestDialog.vue";
import OrderRequestSection from "@/components/AdminComponent/OrderRequestSection.vue";
import CallsDialog from "@/components/Charts/CallsDialog.vue";

export default {
  name: "AdminSupport",
  components: {
    OrderRequestDialog,
    OrderRequestSection,
    QualityDialog,
    ProcessGroup,
    AdminDialog,
    AddNewProcess,
    RequestsCard,
    LoadingMore,
    CallsDialog,
  },

  data() {
    return {
      page: 1,
      loading: false,
      copySupportRequests: [],
      loadMoreData: false,
      loadingData: false,
      selected_requests: [],
      headers: [
        { align: "center", value: "data-table-select" },
        { text: "رقم الطلب", value: "رقم الطلب", align: "center" },
        {
          text: "تاريخ انشاء طلب التحويل",
          value: "تاريخ انشاء طلب التحويل",
          width: "115px",
          align: "center",
        },
        {
          text: "تاريخ دخول الطلب الي النظام",
          value: "تاريخ دخول الطلب الي النظام",
          width: "120px",
          align: "center",
        },
        { text: "حاله الطلب", value: "حاله الطلب", width: "120px", align: "center" },
        {
          text: "استشاري المبيعات",
          value: "استشاري المبيعات",
          align: "center",
        },
        { text: "عميل", value: "عميل", align: "center" },
        { text: "الجوال", value: "الجوال", align: "center" },
        {
          text: "مصدر المعامله",
          value: "مصدر المعامله",
          align: "center",
        },
        {
          text: "تصنيف الاستشاري",
          value: "تصنيف الاستشاري",
          align: "center",
        },
        { text: "الملاحظه", value: "comment", align: "center" },
        { text: "العمليه", value: "العمليه", width: "140px", align: "center" },
        { text: "", value: "action", align: "center" },
      ],
    };
  },

  watch: {
    page(val) {
      if (this.$store.state.isSearch === false) {
        this.getTableData(val);
      } else {
        this.searchRequest(val, "table");
      }
    },

    selected_requests(val) {
      this.selectedItems = val;
      this.$emit("requests", val);
    },

    "$store.state.reloaddata_after_action": {
      handler(newval, oldvalue) {
        console.log("reload_after_action changed")
        if (newval.action == 'admin_need_action_new') {
          this.$store.state.needNewData = [];
          this.$store.state.tableData = [];
          this.$store.state.needNewLoading = true;
          this.$store.state.isSearch = false;
          this.searchRequest(1, 'table');
        }
      },
      immediate: true,
      deep: true 
    },
    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval != oldvalue) {
          this.getTableData(this.page);
        }
      },
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },
  },

  beforeDestroy() {
    this.$store.state.openOrderRequestSection = false;
  },

  methods: {
    handleBack() {
      this.$store.state.needNewData = [];
      this.$store.state.tableData = [];
      this.$store.state.needNewLoading = true;
      this.$store.state.isSearch = false;
      this.$store.state.back = false;
      this.getAllRequests(1, "back");
    },

    reloadPage() {
      this.$store.state.needNewData = [];
      this.$store.state.tableData = [];
      this.$store.state.needNewLoading = true;
      this.$store.state.isSearch = false;
      this.getAllRequests(1);
      this.$store.state.startElement = [];
    },

    handlePageNumber(val) {
      if (this.$store.state.isSearch === false) {
        this.getAllRequests(val);
      } else {
        this.searchRequest(val);
      }
    },
    searchRequest(page, type) {
      this.loading = true;

      if (type === "table") {
        this.$store.state.tableData = [];
      }

      this.$store.state.bodySearch.delete("page");
      if (page > 1 || type === "table") {
        this.$store.state.bodySearch.delete("page");
        this.$store.state.bodySearch.append("page", page);

        let url = `${this.$store.state.url}/v2/employee/Requests/search?type=need_actions_new`;
        Axios.post(url, this.$store.state.bodySearch, {
          headers: this.request_headers(),
        }).then((res) => {
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.searched,
          };
          let response = res.data.payload.data;
          response.map((item) => {
            if (item.length === 0) {
              this.loading = false;
            }
            this.setTbData(item);
            item["starChecked"] = false;
            return item;
          });

          this.$store.state.needNewSearch.push(...response);
          this.removeDublicate(this.$store.state.needNewSearch);
          this.loading = false;
        });
      }
    },

    removeDublicate(array) {
      let uniqueArray = array.filter((item, index) => {
        return (
          index ===
          array.findIndex((obj) => {
            return obj.id === item.id; // Compare based on the 'id' property
          })
        );
      });
      this.$store.state.needNewData = uniqueArray;
    },

    selectedValue(itemSelected) {
      this.getAgentRequest(itemSelected);
    },

    getAgentRequest(itemSelected) {
      let body = new FormData();

      body.append("request_ids[0]", this.$store.state.selectId);
      body.append("agents_ids[0]", itemSelected.id);

      Axios.post(
        `${this.$store.state.url}/v2/employee/Requests/moveAllReqsNewRequests`,
        body,
        { headers: this.request_headers() }
      )
        .then((res) => {
          this.showPopup("success", res.data.message);
        })
        .catch((err) => {
          let error = { err };
          this.showPopup("error", error.err.response.data.message);
        });
    },

    getTableData(count) {
      this.$store.state.tableData = [];
      this.loading = true;
      Axios.get(
        `${this.$store.state.url}/v2/employee/Requests?type=need_actions_new&page=${count}&per_page=${this.$store.state.counterOfShow}`,
        { headers: this.request_headers() }
      ).then((res) => {
        res.data.payload.data.map((item) => {
          this.setTbData(item);
        });
        this.loading = false;
      });
    },

    setTbData(items) {
      this.$store.state.tableData.push({
        id: items.id,
        "رقم الطلب": items.id,
        "تاريخ انشاء طلب التحويل": items.created_at,
        "تاريخ دخول الطلب الي النظام": items.request.created_at
          ? formatDate(items.request.created_at)
          : "",
        "حاله الطلب": items.request.status,
        "استشاري المبيعات": items.request.user?.name,
        عميل: items.request.customer.name,
        الجوال: items.request.customer.mobile,
        "مصدر المعامله": SourceValue(items.request.source),
        "تصنيف الاستشاري": items.request.class_id_agent_text,
        comment: items.request.comment,
        request_id: items.request.id,
        العمليه: items.action,
      });
    },

    getAllRequests(count) {
      if (
        this.$store.state.needNewData.length === 0 &&
        this.copySupportRequests.length === 0
      ) {
        this.$store.state.needNewLoading = true;
      }
      this.loadMoreData = true;
      Axios.get(
        `https://appproduct.alwsata.com.sa/api/v2/employee/Requests?type=need_actions_new&page=${count}&per_page=${this.$store.state.counterOfShow}`,
        { headers: this.request_headers() }
      )
        .then((res) => {
          this.$store.state.needNewLoading = false;
          let response = res.data.payload.data.map((item) => {
            this.setTbData(item);
            item["starChecked"] = false;
            return item;
          });
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_need_actions_new,
          };
          this.$store.state.needNewData.push(...response);
          this.copySupportRequests.push(...response);
        })
        .catch((err) => {
          this.loadingData = false;
          this.loadMoreData = false;
        })
        .finally(() => {
          this.loadMoreData = false;
          this.loadingData = false;
        });
    },
  },

  created() {
    this.$store.state.needNewData = [];
    this.$store.state.tableData = [];
    this.$store.state.isSearch = false;
    this.$store.state.back = false;
    this.$store.state.bodySearch = new FormData();
    this.getAllRequests();
    this.$store.state.startElement = [];
    this.$store.state.views = "cards";
  },
};
</script>

<style>
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.noData {
  display: flex;
  justify-content: center;
  font-size: 22px;
  color: #3f0e40;
  margin-top: 70px;
  font-weight: bold;
}
</style>
