<template>
  <div>
    <v-menu offset-y nudge-top="-20">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          height="40px"
          width="40px"
          style="min-width: unset; left: 10px"
          class="ml-5 mr-3 p-0 genaralIcons"
          color="#3f0e40"
        >
          <v-icon color="white" size="40">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="handleArchived">
          <v-list-item-icon class="ml-2">
            <img src="./assets/4.svg" style="width: 22px" alt="archive" />
          </v-list-item-icon>
          <v-list-item-title> ارشفه المستخدمين</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
const props = defineProps(["selectedItem"]);
const emit = defineEmits(["groupArchived"]);

const handleArchived = () => {
  if (props.selectedItem.length) {
    emit("groupArchived");
  }
};
</script>
