<template>
  <div>
    <div class="vRowToShowData">
      <div style="display: flex">
        <div class="view__cards">
          <v-col
            cols="12"
            xs="4"
            class="px-2 py-1"
            v-for="(item, i) in CardData"
            :key="i"
          >
            <div v-if="isAdmin">
              <AdminCard @getCardId="getCardId" :item="item" />
            </div>
            <div v-else><AgentCard @getCardId="getCardId" :item="item" /></div>
          </v-col>

          <v-card v-intersect="infiniteScrolling"></v-card>
        </div>
        <div style="width: 77.2%" v-if="cardId">
          <v-col
            cols="12"
            xs="9"
            style="background: white; border-radius: 12px; margin: 0 20px 0 10px"
          >
            <div @click="handleClose" class="close__icon">X</div>
            <RequestDetails :id="cardId" :name="name" />
          </v-col>
        </div>
      </div>
    </div>
    <v-snackbar
      v-model="active"
      color="#40BE72"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <span class="font-weight-bold" style="font-size: 16px">{{ message }}</span>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import RequestDetails from "../RequestDetailsForDefaultVue/RequestDetails.vue";
import AdminCard from "../common/AdminCard.vue";
import AgentCard from "../common/AgentCard.vue";

export default {
  name: "RequestsCard",
  components: {
    RequestDetails,
    AdminCard,
    AgentCard,
  },
  props: ["CardData", "name"],

  data() {
    return {
      page: this.$store.state.InfinityPageNumber,

      label: "",
      active: false,
      message: "",

      selectedItems: [],

      isAdmin: localStorage.getItem("isAdmin"),
      cardId: null,
    };
  },

  methods: {
    handleClose() {
      this.cardId = null;
    },
    getCardId(id) {
      this.cardId = id;
    },
    getUsersName(users, label, requestId) {
      this.$emit("getUsersName", users, label, requestId);
    },

    infiniteScrolling() {
      setTimeout(() => {
        this.page++;
        this.$emit("pageCount", this.page);
      }, 100);
    },
  },
};
</script>

<style lang="scss">
.close__icon {
  position: absolute;
  font-size: 22px;
  width: 25px;
  height: 25px;
  margin: 10px 10px;
  color: red;
  cursor: pointer;
}

.vRowToShowData {
  padding-top: 11px !important;
}

.vChipLoadingIcon img {
  margin-left: 5px;
}

.app {
  display: grid;
  place-items: center;
  background-color: transparent;
  height: 50vh;
}

.v-btn {
  &:focus {
    &:before {
      background-color: white !important;
    }
  }
}

.view__cards {
  width: 22%;
  padding: 10px;
  background: white;
  border-radius: 12px;
  overflow: auto;
  height: 100vh;
  direction: ltr;
  > div {
    direction: rtl;
  }

  .card__border {
    right: 0px;
    height: 101%;
    border-radius: 0px !important;
    top: 0px;
    position: absolute;
    width: 4px;
  }
}
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #3f0e40;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ece7ec;
}

.req-title {
  color: #3f0e40;
  font-size: 16px;
  font-weight: 700;
}

.req-date {
  color: #3f0e40;
  font-size: 14px;
  font-weight: 400;
}
</style>
