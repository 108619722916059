<template>
  <div class="all-requests">
    
    <v-row class="px-2 mx-1 vRowToShowData" style="display: inline-block; width: 100%; margin-top: 1rem;">

      <v-col  class="app no-requests font-weight-bold" style="font-size: 36px" cols="12" v-if="!loading && !items.length">
        لا توجد بيانات
      </v-col>
      <v-col  v-if="loading" class="app no-requests font-weight-bold" style="font-size: 36px" cols="12" >
        <v-progress-linear
      indeterminate
      color="green"
    ></v-progress-linear>
      </v-col>

      <v-layout row wrap>
        <v-col cols="12" lg="3" md="4" sm="6" xs="12" v-for="(item, i) in items" :key="i" class="px-2 py-1">
          <v-card class="pa-3 position-relative" style="height: 100%">
            <span class="right-color requestType"
              :style="`background-color:#40BE72`">
            </span>
            <div class="card-head d-flex justify-space-between align-center">
              <v-card-title class="pa-0 requestTitle">
                {{ item.name}}
              </v-card-title>
            </div>
            <div class="req-status-parent mt-1 d-flex justify-space-between align-center">
              <span class="req-type" style="color: #444B5A ; font-size: 15px;">
                استشاري
              </span>
              <span class="req-status spanRequestType" :style="`background-color:#40BE72`">عدد الطلبات: {{ item.counts.all_requests_count }}
              </span>
            </div>
        
            <div class="req-date-parent my-2 d-flex justify-space-between align-center">
              <span style="text-align: center;">
               المستلمة
              <br/>
               {{ item.counts.received_requests_count }}
              </span>
              <span style="text-align: center;">
               المكتملة
              <br/>
               {{ item.counts.completed_requests_count }}
              </span>
              <span style="text-align: center;">
               المؤرشفة
              <br/>
               {{ item.counts.archived_requests_count }}
              </span>
            </div>
          </v-card>
        </v-col>
      </v-layout>

    </v-row>
    
    <!-- cards -->
  </div>
</template>

<script>
import Axios from "axios";
export default {
  name: "HelloWorld",
  data: () => ({
    loading: true,
    page: 1,
    /* eslint-disable */
    items: [],
    addNewRequestDialog: false,
    selected_requests: [],
  }),
  methods: {
    async get_all_agents(page = 1) {
      this.loading = true;
      await Axios.get(
        `https://appproduct.alwsata.com.sa/api/sales/agents-statistics?per_page=50&status=1`,
        {
          headers: this.request_headers(),
        }
      ).then((response) => {
        this.items = response.data.payload.data;
        this.loading = false;
      });
    },
  },

  watch: {
    "$store.state.rates_in_admin_count": {
      handler(val) {
        if (val > this.$store.state.redirect_count_of_rates && localStorage["role"] == "1") {
          this.$store.state.showAlertOfRatingDidntProccess = true;
          this.$router.push({ name: "salesManager_SMRateServices" });
        }
      },
      immediate: true 
    }
  },
  
  async created() {
    await this.get_all_agents();
  },
};
</script>

