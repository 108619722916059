<template>
  <div class="chartsPage">
    <div class="mx-5" style="margin-top: 25px" v-if="changeBage">
      <AddNewRequestBtn />
      <v-row justify="center" style="margin: 0px" class="expansionStyle">
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header style="font-weight: 600; padding: 20px"
              ><span style="position: absolute; bottom: 15px"
                ><v-img
                  style="width: 20px; height: 25px"
                  id="logo"
                  contain
                  src="@/assets/Group.svg"
              /></span>
              <span style="margin-right: 30px">فلتر البحث</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" sm="5" md="5">
                  <PickerInput
                    label="من تاريخ"
                    @dateValue="targetStartDate($event)"
                  />
                </v-col>
                <v-col cols="12" sm="5" md="5">
                  <PickerInput
                    label="الي تاريخ"
                    @dateValue="targetEndDate($event)"
                  />
                </v-col>
                <v-col cols="12" sm="2" md="2" style="margin-top: 15px">
                  <v-btn
                    depressed
                    color="primary"
                    style="
                      width: 100%;
                      padding: 21px;
                      margin-top: -1px;
                      color: white !important;
                    "
                    @click="runSearchMethod(title)"
                  >
                    بحث
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
      <div v-if="getDataDone">
        <SliderGroup :options="this.options" @removeRow="removeRow($event)" />
        <SubHeaderVue
          @pasketRequest="pasketRequest($event)"
          @typesRequest="getAllRequests($event)"
          @ordeCaseRequest="ordeCaseRequest($event)"
          :typesRequestsActive="this.active"
          :basketRequestActive="this.basketRequestActive"
          :orderCaseRequestActive="this.orderCaseRequestActive"
        />
        <DataTable
          :headers="getHeaders"
          :desserts="this.tableItems"
          :title="this.title"
        />
        <div class="chartChat">
          <agent-chat />
        </div>
      </div>
      <div v-else class="loader" style="margin-top: 20px">
        <img src="../../assets/Comp-2.gif" />
      </div>
    </div>
    <div v-else class="loader" style="margin-top: 20px">
      <img src="../../assets/Comp-2.gif" />
    </div>
  </div>
</template>

<script>
import PickerInput from "@/components/Charts/PickerInput.vue";
import SliderGroup from "@/components/Charts/SliderGroup.vue";
import DataTable from "@/components/Charts/DataTable.vue";
import SubHeaderVue from "@/components/Charts/SubHeader.vue";
import AddNewRequestBtn from "@/components/Charts/AddNewRequestBtn.vue";
import Axios from "axios";

export default {
  data() {
    return {
      headers: [],
      tableItems: [],
      options: [],
      resName: [],
      copyResName: [],
      startDate: "",
      endDate: "",
      getDataDone: true,
      title: "تقرير تصنيفات الطلب",
      active: true,
      basketRequestActive: false,
      orderCaseRequestActive: false,
      changeBage: true,
      partStaticUrl: "https://appproduct.alwsata.com.sa/api/agent/",
    };
  },

  components: {
    PickerInput,
    SliderGroup,
    DataTable,
    SubHeaderVue,
    AddNewRequestBtn,
  },

  methods: {
    //order cases request logic
    ordeCaseRequest(title) {
      if (title === "search") {
        this.getDataDone = false;
      } else {
        this.changeBage = false;
        this.startDate = "";
        this.endDate = "";
      }

      this.active = false;
      this.basketRequestActive = false;
      this.orderCaseRequestActive = true;
      this.title = "تقرير تصنيفات الطلب";
      this.$store.state.opened = -1;

      let body = {};
      if (this.startDate && !this.endDate) {
        body = { start_date: this.startDate };
      }

      if (this.startDate && this.endDate) {
        body = {
          start_date: this.startDate,
          end_date: this.endDate,
        };
      }

      if (this.endDate && !this.startDate) {
        body = { end_date: this.endDate };
      }

      Axios.post(`${this.partStaticUrl}classifications_chart`, body, {
        headers: this.request_headers(),
      }).then((res) => {
        this.changeBage = true;
        this.getDataDone = true;
        let data = res.data.message;
        this.tableItems = [];
        this.headers = [];
        this.getOrderCasesData(data);
        this.resName = [];
        this.copyResName = [];
        this.options = [];

        this.resName.shift();
        this.resName.push("الاستشاري");
        this.copyResName.push("الاستشاري");

        data.map((res) => {
          if (res.name) {
            if (res.name !== "بدون تصنيف") {
              this.resName.push(res.name);
              this.copyResName.push(res.name);
              this.options.push(res.name);
            }
          } else {
            this.resName.push("التحكم");
            this.copyResName.push("التحكم");
          }
        });
      });
    },

    getOrderCasesData(res) {
      this.tableItems = [];

      const itemMapping = {
        "بحاجة للمتابعة": "بحاجة للمتابعة",
        "بانتظار الأوراق": "بانتظار الأوراق",
        "لا يرغب في الخدمة": "لا يرغب في الخدمة",
        "يبحث عن عقار": "يبحث عن عقار",
        مرفوض: "مرفوض",
        "تعذر الإتصال": "تعذر الإتصال",
        مرفوع: "مرفوع",
        مكتمل: "مكتمل",
        "لم يرد": "لم يرد",
        "اجل التواصل": "اجل التواصل",
        "بدون راتب ( فرد عمل حر)": "بدون راتب ( فرد عمل حر)",
        "لم يناسبه العرض": "لم يناسبه العرض",
        "ملغى من قبل العميل": "ملغى من قبل العميل",
        "لديه التزام عقاري حديث": "لديه التزام عقاري حديث",
        "قرض شخصي": "قرض شخصي",
        "أخذ من البنك": "أخذ من البنك",
        "Testing Classification": "Testing Classification",
        "صرف نظر": "صرف نظر",
        "بدون راتب (صاحب شركة أو مؤسسة)": "بدون راتب (صاحب شركة أو مؤسسة)",
      };

      const result = {};
      result["الاستشاري"] = this.user_name;

      res.forEach((item) => {
        if (Object.prototype.hasOwnProperty.call(itemMapping, item.name)) {
          result[itemMapping[item.name]] = item.count;
        }

        result["التحكم"] = item.report_pdf;
      });

      this.tableItems = [result];
    },

    //paskets Request logic
    pasketRequest(title) {
      if (title === "search") {
        this.getDataDone = false;
      } else {
        this.changeBage = false;
        this.startDate = "";
        this.endDate = "";
      }

      this.active = false;
      this.orderCaseRequestActive = false;
      this.basketRequestActive = true;
      this.title = "تقرير سلال الطلب";
      this.$store.state.opened = -1;

      let body = {};
      if (this.startDate && !this.endDate) {
        body = { start_date: this.startDate };
      }

      if (this.startDate && this.endDate) {
        body = {
          start_date: this.startDate,
          end_date: this.endDate,
        };
      }

      if (this.endDate && !this.startDate) {
        body = { end_date: this.endDate };
      }

      Axios.post(`${this.partStaticUrl}baskets_chart`, body, {
        headers: this.request_headers(),
      }).then((res) => {
        this.changeBage = true;
        this.getDataDone = true;
        let data = res.data.message;
        this.tableItems = [];
        this.headers = [];
        this.getBasketTableData(data);
        this.resName = [];
        this.copyResName = [];
        this.options = [];

        this.resName.push("الاستشاري");
        this.copyResName.push("الاستشاري");
        data.map((res) => {
          if (res.name_ar) {
            this.resName.push(res.name_ar);
            this.copyResName.push(res.name_ar);
            this.options.push(res.name_ar);
          } else {
            this.resName.push("التحكم");
            this.copyResName.push("التحكم");
          }
        });
      });
    },

    getBasketTableData(res) {
      this.tableItems = [];

      const itemMapping = {
        مكتملة: "مكتملة",
        مؤرشفة: "مؤرشفة",
        متابعة: "متابعة",
        مميزة: "مميزة",
        مستلمة: "مستلمة",
      };

      const result = {};
      result["الاستشاري"] = this.user_name;

      res.forEach((item) => {
        if (Object.prototype.hasOwnProperty.call(itemMapping, item.name_ar)) {
          result[itemMapping[item.name_ar]] = item.count;
        }

        result["التحكم"] = item.report_pdf;
      });

      this.tableItems = [result];
    },

    //target search value
    targetStartDate(date) {
      this.startDate = date;
    },

    targetEndDate(date) {
      this.endDate = date;
    },

    //requests type logic
    getAllRequests(title) {
      if (title === "search") {
        this.getDataDone = false;
      } else {
        this.changeBage = false;
        this.startDate = "";
        this.endDate = "";
      }

      this.basketRequestActive = false;
      this.orderCaseRequestActive = false;
      this.active = true;

      this.title = "تقرير حالات الطلب";
      this.$store.state.opened = -1;
      this.getDataDone = false;

      let body = {};
      if (this.startDate && !this.endDate) {
        body = { start_date: this.startDate };
      }

      if (this.startDate && this.endDate) {
        body = {
          start_date: this.startDate,
          end_date: this.endDate,
        };
      }

      if (this.endDate && !this.startDate) {
        body = { end_date: this.endDate };
      }

      Axios.post(`${this.partStaticUrl}request_chart`, body, {
        headers: this.request_headers(),
      }).then((res) => {
        this.getDataDone = true;
        this.changeBage = true;
        let data = res.data.message;
        this.tableItems = [];
        this.headers = [];
        this.getTableData(data);
        this.resName = [];
        this.copyResName = [];
        this.options = [];

        this.resName.push("الاستشاري");
        this.copyResName.push("الاستشاري");
        data.map((res) => {
          if (res.name_ar) {
            this.resName.push(res.name_ar);
            this.copyResName.push(res.name_ar);
            this.options.push(res.name_ar);
          } else {
            this.resName.push("التحكم");
            this.copyResName.push("التحكم");
          }
        });
      });
    },

    getTableData(res) {
      this.tableItems = [];

      const itemMapping = {
        "جديد": "جديد",
        "مفتوح": "مفتوح",
        "مؤرشف": "مؤرشف",
        "بانتظار موافقه مدير المبيعات": "بانتظار موافقه مدير المبيعات",
        "رفض من قبل مدير المبيعات": "رفض من قبل مدير المبيعات",
        "مؤرشف عند مدير المبيعات": "مؤرشف عند مدير المبيعات",
        "بانتظار موافقه مدير التمويل": "بانتظار موافقه مدير التمويل",
        "رفض من قبل مدير التمويل": "رفض من قبل مدير التمويل",
        "مؤرشف عند مدير التمويل": "مؤرشف عند مدير التمويل",
        "بانتظار موافقه مدير الرهن": "بانتظار موافقه مدير الرهن",
        "رفض من قبل مدير الرهن": "رفض من قبل مدير الرهن",
        "مؤرشف عند مدير الرهن": "مؤرشف عند مدير الرهن",
        "بانتظار موافقه المدير العام": "بانتظار موافقه المدير العام",
        "رفض من قبل المدير العام": "رفض من قبل المدير العام",
        "مؤرشف عند المدير العام": "مؤرشف عند المدير العام",
        "ملغى": "ملغى",
        "مكتمل": "مكتمل",
        " في تقرير التمويل": " في تقرير التمويل",
        " في تقرير الرهن": " في تقرير الرهن"

      };

      const result = {};
      result["الاستشاري"] = this.user_name;

      res.forEach((item) => {
        if (Object.prototype.hasOwnProperty.call(itemMapping, item.name_ar)) {
          result[itemMapping[item.name_ar]] = item.count;
        }

        result["التحكم"] = item.report_pdf;
      });

      this.tableItems = [result];
    },

    //remove and insert data
    removeRow(headerValue) {
      this.headers = [];
      let findIndex = this.resName.findIndex((item) => item === headerValue);

      if (findIndex !== -1) {
        this.resName.splice(findIndex, 1);
      } else {
        let filterRemovedItem = this.copyResName.filter(
          (x) => !this.resName.includes(x)
        );
        let foundInsertedItem = filterRemovedItem.findIndex(
          (item) => item === headerValue
        );

        this.copyResName.map((res, index) => {
          if (headerValue === res) {
            this.resName.splice(index - foundInsertedItem, 0, headerValue);
          }
        });
      }
    },

    //target page search
    runSearchMethod(title) {
      if (title === "تقرير سلال الطلب") {
        this.pasketRequest("search");
      } else if (title === "تقرير حالات الطلب") {
        this.getAllRequests("search");
      } else if (title === "تقرير تصنيفات الطلب") {
        this.ordeCaseRequest("search");
      }
    },
  },

  watch:{
    "$store.state.first_not_processed_task_id": {
      handler(val) {
        if (val > 0 && localStorage["role"] == "0") {
          this.$store.state.showAlertOfTasksDidntProccess = true;
          this.$router.push({ name: 'ticket_details', params: { ticket: this.$store.state.first_not_processed_task_id } });
        }
      },
      immediate: true 
    },
  },

  created() {
    this.$store.state.opened = -1;
    this.ordeCaseRequest();
  },

  computed: {
    user_name() {
      return JSON.parse(
        document.cookie
          .split(";")
          .map((coki) => coki.split("="))
          .reduce(
            (acc, [key, val]) => ({
              ...acc,
              [key.trim()]: decodeURIComponent(val),
            }),
            {}
          )["wsataJwt"]
      )["agent_name"];
    },

    getHeaders() {
      this.resName.map((res) => {
        if (
          res === "رفض من قبل مدير الرهن" ||
          res === "Testing Classification" ||
          res === "ملغى من قبل العميل" ||
          res === "لديه التزام عقاري حديث" ||
          res === "بدون راتب ( فرد عمل حر)" ||
          res === "بانتظار موافقه مدير الرهن" ||
          res === "مؤرشف عند مدير الرهن" ||
          res === "مؤرشف عند مدير التمويل" ||
          res === "رفض من قبل مدير التمويل" ||
          res === "بانتظار موافقه مدير التمويل" ||
          res === "مؤرشف عند مدير المبيعات" ||
          res === "رفض من قبل مدير المبيعات" ||
          res === " بانتظار موافقه الاستشارى" ||
          res === "رفض من قبل الاستشارى" ||
          res === "مؤرشف عند الاستشارى"  ||
          res === "رفض من قبل المدير العام"
        ) {
          this.headers.push({
            text: res,
            value: res,
            width: "200px",
          });
        } else if (
          res === "بانتظار موافقه مدير المبيعات" ||
          res === "بدون راتب (صاحب شركة أو مؤسسة)"||
          res === "بانتظار موافقه المدير العام"||
          res === "مؤرشف عند المدير العام"
        ) {
          this.headers.push({
            text: res,
            value: res,
            width: "220px",
          });
        } else if (
          res === "بحاجة للمتابعة" ||
          res === "أخذ من البنك" ||
          res === "لم يناسبه العرض" ||
          res === "بانتظار الأوراق" ||
          res === "يبحث عن عقار" ||
          res === "لا يرغب في الخدمة" ||
          res === " في تقرير التمويل"||
          res === ' في تقرير الرهن'
        ) {
          this.headers.push({
            text: res,
            value: res,
            width: "150px",
          });
        } else if (
          res === "قرض شخصي" ||
          res === "صرف نظر" ||
          res === "لم يرد" ||
          res === "تعذر الإتصال" ||
          res === "اجل التواصل"
        ) {
          this.headers.push({
            text: res,
            value: res,
            width: "120px",
          });
        } else {
          this.headers.push({
            text: res,
            value: res,
          });
        }
      });
      return this.headers;
    },
  },
};
</script>

<style>
.expansionStyle i.v-icon.notranslate.mdi.mdi-chevron-down.theme--light {
  background: #3f0e40;
}

.chartChat i.v-icon.notranslate.mdi.mdi-chevron-down.theme--light {
  background: white !important;
}
</style>
