<template>
  <v-sheet
    class="mx-auto"
    
    style="height: 50px;
overflow: hidden;
border-radius: 5px; max-width: 100% !important; margin-top: 30px;"
  >
    <v-slide-group
      multiple
      show-arrows
    >
      <v-slide-item
        v-for="n in options"
        :key="n.id"
        v-slot="{ toggle }"
      >
        <v-btn
          class="mx-2"
          :class = "(n.selected) ? 'active' : 'notActive' "
          depressed
          rounded
          @click="removeRow(n.label); toggle(); checkActive(n)"
          style="
            margin-top: 7px;
            border: 1px solid purple;
            background: rgb(251 246 245);
            color: purple;
            font-weight: bold;
            box-shadow: 0 0 0 0; 
            border-radius: 4px;
 "
        >
           {{ n.label }}
        </v-btn>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
</template>

<script>
export default {
  name: "SliderGroup",
  props: ["name" , "options"],
  
data: () => ({
  model: null,
}),

methods:{
  checkActive(option){
    this.$emit('checkActive', option)
  },
  removeRow(headerValue){
    this.$emit('removeRow', headerValue)
  }
}
}
</script>

<style>
.active{
  color: white !important;
  background-color: #AE4EBE !important;
}

.notActive{
  background: rgb(251 246 245);
            color: purple;
}

.v-slide-group__next .v-icon.v-icon , .v-slide-group__prev .v-icon.v-icon, .v-slide-group__next .v-icon.v-icon{
  color: purple !important;
  background-color: rgb(251, 246, 245) !important;
}
</style>