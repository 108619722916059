<template>
    <div>
      <v-row style=" margin-top: 10px; ">
        <v-col cols="3">
          <v-sheet class="c-block-1" style=" margin-bottom: 11px;  display: flex; justify-content: space-between;">
            <h4>
                الطلبات المستلمة
            </h4>
            
            <div class="back-txt" style="padding-left: 0;;" @click="backToAgentPercentage">
                        عودة
                        <span>
                            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20 17.418C17.5535 14.4315 15.381 12.737 13.482 12.334C11.5835 11.9315 9.776 11.8705 8.059 12.1515V17.5L0 8.7725L8.059 0.5V5.5835C11.2335 5.6085 13.932 6.7475 16.155 9C18.3775 11.2525 19.6595 14.0585 20 17.418Z" fill="#3F0E40"/>
                            </svg>
                            
                        </span>
                    </div>
          </v-sheet>
          <v-sheet class="c-block-1 auc-card2" >
            <div class="shape" id="gague" data-g-val="70">
                  <div class="wr">
                      <div class="center"></div>
                      <div class="spin">
                          <img src="../../../../assets/Frame 777.svg" alt="">
                      </div>
                      <div class="spin-cover"></div>
                      <div class="bottom-cover"></div>
                      <div class="shape-num">{{recieved_percent}}%</div>
                  </div>
              </div>
          </v-sheet>
        </v-col>
        <v-col cols="9">
          <v-sheet class="c-block-1">
            
            <secondary-table-component
                        :showSelect="false"
                        itemKey="id"
                        :headers="headers"
                        :pagination="this.$store.state.pagination"
                        :limit="$store.state.counterOfShow"
                        :items="tableData"
                        :loading="loading"
                        >
                        
                        <template slot="item.count" slot-scope="props">
                            <div>
                                <span class="btn btn-info" style=" color: #fff; background: #1d78ff; padding: 6px 15px; border-radius: 5px; ">
                                    {{props.item.count}} طلب
                                </span>
                                </div>
                        </template>
                        <template slot="item.percentage" slot-scope="props">
                            <div>
                               
                                <v-progress-linear
                                v-model="props.item.percentage"
                                :color="props.item.color"
                                height="25"
                                :rounded="true"
                                :reverse="true"
                                style="pointer-events: none; text-align: right;"
                                >
                                <template v-slot:default="{ value }">
                                    <strong>{{ value.toFixed(2) }}%</strong>
                                </template>
                                </v-progress-linear>
                            </div>
                        </template>
                    </secondary-table-component>
          </v-sheet>
        </v-col>
      </v-row>
        <v-sheet class="c-block-1">

            <v-row v-if="0">
                <v-col cols="12">
                    <table-component
                        :showSelect="false"
                        itemKey="id"
                        :headers="headers"
                        :pagination="this.$store.state.pagination"
                        :limit="$store.state.counterOfShow"
                        :items="tableData"
                        :loading="loading"
                        >
                        
                        <template slot="item.percentage" slot-scope="props">
                            <div>
                                <span>
                                    ({{props.item.count}} طلب)
                                </span>
                               
                                <v-progress-linear
                                v-model="props.item.percentage"
                                :color="props.item.color"
                                height="25"
                                style="pointer-events: none"
                                >
                                <template v-slot:default="{ value }">
                                    <strong>{{ value.toFixed(2) }}%</strong>
                                </template>
                                </v-progress-linear>
                            </div>
                        </template>
                    </table-component>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <h4 class="sub-title">
                        الاستشاريين
                    </h4>
                </v-col>
                <v-col cols="12" class="sales-managers-ch">
                    <v-row>
                        <v-col v-for="(item, index) in itemsSalesManagers" :key="index" cols="2">
                        <v-radio-group v-model="selected_sales_manger">
                            <v-radio :label="item.label" :value="item.value"></v-radio>
                        </v-radio-group>
                        </v-col>
                    </v-row>

                </v-col>

                <v-col cols="12">

                    <RecievedChart :xaxis="xaxis" :chartData="chartData" :chartColors="chartColors" :chartLabels="chartLabels" />
                </v-col>
            </v-row>
        </v-sheet>
    </div>
  </template>
  
  <script>
  
  import { axiosInstance } from "@/axios/config";
  import RecievedChart from './RecievedChart'
  import Axios from "axios";
import router from "@/routes";
  
  export default {
    components: {
      RecievedChart
    },
    data() {
      return {
        loading: true,
        xaxis:[],
        chartData:[],
        chartColors:[],
        chartLabels:[],
        selected_sales_manger: '',
        recieved_count: null,
        recieved_percent: null,
        recieved_color: '',
        selectedItem: null,
        itemsSalesManagers: [
          { label: 'جميع الاستشاريين', value: '' }
        ],
        headers: [
              { text: "جميع الاقسام", value: "name", align: "center", width: '40%' },
              { text: "طلبات", value: "count", align: "center", width: '20%' },
              { text: "احصائيات طلبات الاقسام", value: "percentage", align: "center" }
        ],
        tableData:[],
      };
    },
  
    watch:{
      selected_sales_manger(val){
        this.getAgentsRecieved();
      }
    },
    methods: {
      backToAgentPercentage(){
        router.push({
          name: "agent-percentage",
        });
      },
      
    getRecieved(){
        axiosInstance
        .get(`/employee/admin/recieved-statistics`, {})
        .then((res) => {
          this.recieved_color = res.data.payload.color;
            this.recieved_count = res.data.payload.percentage;
            let percent = res.data.payload.percentage;
            // percent = (percent / 20);
            this.recieved_percent = percent.toFixed(2);
            
        const gaugeElement = document.querySelector("#gague");
        this.setGaugeValue(gaugeElement, this.recieved_percent, 100);
        })
        .catch((err) => {
          console.log("eror")
        });
      },
    getSalesRecieved(){
        axiosInstance
        .get(`/employee/admin/recieved-statistics-salesmanagers`, {})
        .then((res) => {
          console.log("res", res)
          this.tableData = res.data.payload;
          for (let index = 0; index < res.data.payload.length; index++) {
            const element = res.data.payload[index];
            this.itemsSalesManagers.push({
              label: element.name,
              value: element.id
            });
            
          }
          this.loading = false
            // this.reviews_count = res.data.payload.star_avg_c;
            // let percent = res.data.payload.star_avg;
            // percent = (percent / 20);
            // this.reviews_percent = percent.toFixed(2);
        })
        .catch((err) => {
          console.log("eror")
        });
      },
    getAgentsRecieved(){
      let data = '';
      if (this.selected_sales_manger != '') {
        data = 'sales_manger_id='+this.selected_sales_manger;
      }
      
      this.xaxis = [];
      this.chartData = [];
      this.chartColors = [];
      this.chartLabels = [];
        axiosInstance
        .get(`/employee/admin/recieved-statistics-salesmanagers-agents?${data}` )
        .then((res) => {
          console.log("res", res)
          for (let index = 0; index < res.data.payload.length; index++) {
            const element = res.data.payload[index];
            this.xaxis.push(element.name);
            this.chartData.push(element.percentage);
            this.chartColors.push(element.color);
            this.chartLabels.push(element.acual + ' / ' + element.target );
            
          }
            // this.reviews_count = res.data.payload.star_avg_c;
            // let percent = res.data.payload.star_avg;
            // percent = (percent / 20);
            // this.reviews_percent = percent.toFixed(2);
        })
        .catch((err) => {
          console.log("eror")
        });
      },
      updateGauge(gauge, value) {
              const rotateVal = value * 2.7;
              if (rotateVal > 180) {
                  gauge.querySelector(".spin-cover").style.display = "none"
              } else {
                  gauge.querySelector(".spin-cover").style.display = "block"
              }
              if (rotateVal > 90) {
                  gauge.querySelector(".spin-cover").style.transform = `rotate(${rotateVal - 90}deg)`;
              } else {
                  gauge.querySelector(".spin-cover").style.transform = `rotate(0deg)`;
              }
              gauge.querySelector(".spin").style.transform = `rotate(${rotateVal}deg)`;
              value++;
      },
      setGaugeValue(gauge, cur, base) {
          let value = 100 * Math.round(cur) / Math.round(base);
          if (value < 0 || value > 100) {
              return;
          }
          let waittime = base * 10; //  calc the wait time 
          gauge.querySelector(".spin-cover").style.transitionDuration = `${waittime}ms`;
          gauge.querySelector(".spin").style.transitionDuration = `${waittime}ms`;

          this.updateGauge(gauge, value);
          // intervalId = setInterval(updateGauge, waittime);

      }
    },
  
    created() {
      this.getRecieved();
      this.getSalesRecieved();
      this.getAgentsRecieved();
    },
  };
  </script>
  <style scoped>
  .title-row{
    border: 1px solid;
    border-radius: 10px;
    padding: 7px 0 0 0;
  }
  .title-sec-1{
    display: flex;
    justify-content: flex-start;
  }
  .title-sec-1 .inner-c{
     display: flex; justify-content: space-around; 
     /* min-width: 30%; */
  }
  /* .title-sec-1{} */
h4.sub-title{
  background-color: #d9d1da;
    padding: 11px 11px;
    margin: 11px 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid;
    color: #451646;
}
.v-progress-linear__content strong{
  right: 0;
    position: absolute;
    margin-right: 8px;
}
.auc-card2 .shape {
    height: 228px;
}
.auc-card2 .shape {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.auc-card2 .shape .wr {
    overflow: hidden;
    width: 172px;
    height: 172px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #ffffff;
}

.auc-card2 .shape .wr .center {
    position: absolute;
    width: 120px;
    height: 120px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 1px 1px #fff;
}

.auc-card2 .shape .wr .noti-overlay.sold {
    background-color: #00A550;
}

.auc-card2 .shape .wr .noti-overlay.visible {
    visibility: visible;
    opacity: 1;
}

.auc-card2 .shape .wr .noti-overlay>div {
    width: 100%;

}

.auc-card2 .shape .wr .noti-overlay .i-wr {
    font-size: 18px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    margin-top: 8px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 2px rgba(0, 0, 0, .15);
}

.auc-card2 .shape .wr .spin {
    position: relative;
    z-index: 10;
    transition: transform linear;
}

.auc-card2 .shape .wr .spin-cover {
    position: absolute;
    width: 170px;
    height: 170px;
    z-index: 13;
    background-color: transparent;
    border: 24px solid transparent;
    border-radius: 50%;
    border-top: 25px solid #ffffff;
    border-right: 25px solid #ffffff;
    transition: transform linear;
}

.auc-card2 .shape .wr .bottom-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 15;
    background-color: transparent;
    border: 27px solid transparent;
    border-radius: 50%;
    border-bottom: 27px solid #fff;
    box-shadow: 0 2px 2px #fff;
}

.auc-card2 .shape .wr .shape-num {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: 20;
    width: 46px;
    height: 46px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #451646;
    color: #ffffff;

}

.auc-card2 .shape .price {
    position: absolute;
    bottom: 29px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    width: 110px;
    height: 22px;
    background-color: #fff;
    color: #ffffff;
    text-align: center;
    overflow: hidden;
    padding: 0 5px;
}

.auc-card2 .shape .price .num {
    font-size: 14px;
    font-weight: 500;
    color: #333644;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Roboto', sans-serif;
}


.auc-card2 .shape .price .person {
    font-size: 12px;
    color: #555555;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
  </style>