<template>
  <div class=".received-requests">
    <div
      v-if="0"
      style="
        box-shadow: 0px 0px 6px -4px black;
        background: white;
        border-radius: 10px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
      "
      class="btns-dad"
    >
      <v-switch
        v-model="readyToRecieve"
        :label="readyLable"
        :color="$vuetify.theme.themes.light.dataActionBTN"
        :value="readyToRecieve"
        hide-details
        :disabled="disabledBtn"
        @change="switchReadyState"
        class="mt-0"
      ></v-switch>
      <v-btn
        :color="$vuetify.theme.themes.light.dataActionBTN"
        style="font-size: 16px"
        class="pb-2 receive-btn"
        @click="ask_for_more_customers"
        dark
        >اطلب مزيد من العملاء!</v-btn
      >
      <v-btn
        :color="$vuetify.theme.themes.light.dataActionBTN"
        :style="showBtn ? 'font-size: 16px' : 'display:none'"
        class="pb-2 receive-btn"
        @click="toggleTables"
        dark
        >{{ showBtnText }}</v-btn
      >
    </div>

    <v-btn
      v-if="false"
      fab
      :color="$vuetify.theme.themes.light.dialogActionBTN"
      @click="addNewRequestDialog = true"
      class="my-10"
      style="position: fixed; left: 20px; bottom: 0px; z-index: 3"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.425"
        height="21.166"
        viewBox="0 0 25.425 21.166"
      >
        <g
          id="Icon_feather-user-plus"
          data-name="Icon feather-user-plus"
          transform="translate(1 1)"
        >
          <path
            id="Path_2554"
            data-name="Path 2554"
            d="M17.471,28.889v-2.13A4.259,4.259,0,0,0,13.212,22.5H5.759A4.259,4.259,0,0,0,1.5,26.759v2.13"
            transform="translate(-1.5 -9.723)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_2555"
            data-name="Path 2555"
            d="M15.268,8.759A4.259,4.259,0,1,1,11.009,4.5,4.259,4.259,0,0,1,15.268,8.759Z"
            transform="translate(-3.023 -4.5)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_2556"
            data-name="Path 2556"
            d="M30,12v6.389"
            transform="translate(-9.77 -6.676)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_2557"
            data-name="Path 2557"
            d="M31.889,16.5H25.5"
            transform="translate(-8.464 -7.982)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </svg>
    </v-btn>
    <add-new-request
      v-model="addNewRequestDialog"
      width="350px"
      @submitted="get_all_requests"
    />

    <!--Received SMRequests Table-->
    <div :class="showTable == 1 ? 'mt-6' : 'mt-6 d-none'">
      <h3>الطلبات المستلمة :</h3>
      <div
        class="printing mb-3"
        style="
          background: white;
          padding: 10px;
          justify-content: center;
          box-shadow: 0px 0px 6px -4px black;
          border-radius: 10px;
        "
        :style="items.length <= 0 ? 'display:none' : 'display:flex'"
      >
        <div>
          <v-btn
            depressed
            width="45"
            height="45"
            :color="$vuetify.theme.themes.light.dataActionBTN"
            dark
            fab
            class="ml-1 hidden-sm-and-down"
            @click="exportExcel"
            v-if="!$store.state.cardsStyle"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18.116"
              height="18.116"
              viewBox="0 0 12.25 14"
            >
              <g id="excel-file" transform="translate(-1.5)">
                <path
                  id="Path_4097"
                  data-name="Path 4097"
                  d="M11.708,14H1.792a.292.292,0,0,1-.292-.292V3.548a.292.292,0,0,1,.074-.194L4.491.1A.292.292,0,0,1,4.708,0h7A.292.292,0,0,1,12,.292V7a.292.292,0,0,1-.583,0V.583H4.839L2.083,3.659v9.757h9.333V12.25a.292.292,0,1,1,.583,0v1.458A.293.293,0,0,1,11.708,14Z"
                  fill="#fff"
                />
                <path
                  id="Path_4098"
                  data-name="Path 4098"
                  d="M4.917,4.292H2.292a.292.292,0,0,1,0-.583H4.625V.792a.292.292,0,0,1,.583,0V4A.292.292,0,0,1,4.917,4.292Zm8.167,8.167h-7a.876.876,0,0,1-.875-.875v-3.5a.876.876,0,0,1,.875-.875h7a.876.876,0,0,1,.875.875v3.5A.876.876,0,0,1,13.083,12.458Zm-7-4.667a.292.292,0,0,0-.292.292v3.5a.292.292,0,0,0,.292.292h7a.293.293,0,0,0,.292-.292v-3.5a.293.293,0,0,0-.292-.292Z"
                  transform="translate(-0.208 -0.208)"
                  fill="#fff"
                />
                <path
                  id="Path_4099"
                  data-name="Path 4099"
                  d="M11.29,15.67,10.566,14.6h.3a.075.075,0,0,1,.047.012.1.1,0,0,1,.027.03l.572.878a.4.4,0,0,1,.032-.07l.54-.8a.186.186,0,0,1,.03-.036.058.058,0,0,1,.039-.012h.283l-.726,1.052.751,1.139h-.293a.079.079,0,0,1-.053-.017.176.176,0,0,1-.031-.038l-.588-.919a.316.316,0,0,1-.027.061l-.572.858a.21.21,0,0,1-.032.039.065.065,0,0,1-.05.018h-.275l.755-1.125Zm1.754.876h.948V16.8H12.747V14.6h.3Zm2.4-1.6a.117.117,0,0,1-.03.034.059.059,0,0,1-.039.012.111.111,0,0,1-.061-.026q-.042-.031-.086-.058a.805.805,0,0,0-.128-.057.558.558,0,0,0-.18-.026.575.575,0,0,0-.176.027.377.377,0,0,0-.128.073.279.279,0,0,0-.076.109.348.348,0,0,0-.026.134.243.243,0,0,0,.045.152.385.385,0,0,0,.119.1.952.952,0,0,0,.168.074l.193.065c.065.023.131.048.192.075a.658.658,0,0,1,.168.107.486.486,0,0,1,.12.158.515.515,0,0,1,.045.229.735.735,0,0,1-.049.269.632.632,0,0,1-.143.22.672.672,0,0,1-.231.146.849.849,0,0,1-.311.054.956.956,0,0,1-.389-.077.908.908,0,0,1-.3-.209l.086-.14a.107.107,0,0,1,.03-.029.07.07,0,0,1,.038-.012.118.118,0,0,1,.073.034.946.946,0,0,0,.1.076.608.608,0,0,0,.366.107.577.577,0,0,0,.188-.029.378.378,0,0,0,.258-.373.269.269,0,0,0-.045-.163.38.38,0,0,0-.118-.106.832.832,0,0,0-.167-.072l-.192-.061a2.009,2.009,0,0,1-.192-.073.608.608,0,0,1-.167-.109.484.484,0,0,1-.118-.164.571.571,0,0,1-.045-.244.579.579,0,0,1,.177-.418.622.622,0,0,1,.211-.134.75.75,0,0,1,.29-.05.921.921,0,0,1,.335.058.773.773,0,0,1,.264.169l-.072.141Z"
                  transform="translate(-3.765 -6.075)"
                  fill="#fff"
                />
              </g>
            </svg>
          </v-btn>
          <v-btn
            depressed
            width="45"
            height="45"
            @click="printNow(items, headers)"
            :color="$vuetify.theme.themes.light.dataActionBTN"
            dark
            fab
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18.116"
              height="18.116"
              viewBox="0 0 16.116 16.116"
            >
              <g
                id="Icon_feather-printer"
                data-name="Icon feather-printer"
                transform="translate(-2.5 -2.5)"
              >
                <path
                  id="Path_4011"
                  data-name="Path 4011"
                  d="M9,8.291V3h9.069V8.291"
                  transform="translate(-2.977)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
                <path
                  id="Path_4012"
                  data-name="Path 4012"
                  d="M6.023,20.3H4.512A1.512,1.512,0,0,1,3,18.791V15.012A1.512,1.512,0,0,1,4.512,13.5H16.6a1.512,1.512,0,0,1,1.512,1.512v3.779A1.512,1.512,0,0,1,16.6,20.3H15.093"
                  transform="translate(0 -5.209)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
                <path
                  id="Path_4013"
                  data-name="Path 4013"
                  d="M9,21h9.069v6.046H9Z"
                  transform="translate(-2.977 -8.931)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1"
                />
              </g>
            </svg>
          </v-btn>
          <v-spacer class="top-spacer" />
        </div>
      </div>
      <div>
        <table-component
          id="recieved-requests"
          :headers="headers"
          :items="items"
          :loading="loading"
          v-model="page"
          :showSelect="true"
          @refresh="get_requests"
          itemKey="id"
          :pagination="pagination"
          :selectedRows.sync="selected_requests"
          v-if="!$store.state.cardsStyle"
        >
          <!-- eslint-disable -->
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#3f0e40"
                  depressed
                  small
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="to_request_details(item)">
                  <v-list-item-icon class="ml-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18.528"
                      height="15.111"
                      viewBox="0 0 13.528 10.111"
                    >
                      <g
                        id="Icon_feather-eye-dark"
                        data-name="Icon feather-eye-dark"
                        transform="translate(-1 -5.5)"
                      >
                        <path
                          id="Path_4040"
                          data-name="Path 4040"
                          d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        />
                        <path
                          id="Path_4041"
                          data-name="Path 4041"
                          d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                          transform="translate(-7.444 -4.653)"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        />
                      </g>
                    </svg>
                  </v-list-item-icon>
                  <v-list-item-title>فتح</v-list-item-title>
                </v-list-item>

                <router-link
                  style="text-decoration: none"
                  target="_blank"
                  :to="{
                    name: 'salesManager_request_details',
                    params: { id: item.id },
                  }"
                >
                  <v-list-item>
                    <v-list-item-icon class="ml-3">
                      <img src="@/assets/icons/titcketIcon.svg" />
                    </v-list-item-icon>
                    <v-list-item-title> فتح في تاب جديد</v-list-item-title>
                  </v-list-item>
                </router-link>

                <v-list-item @click="getRequestCalls(item)">
                  <v-list-item-icon class="ml-3">
                    <RequestCallsIcon />
                  </v-list-item-icon>
                  <v-list-item-title>المكالمات</v-list-item-title>
                </v-list-item>

                <v-list-item @click="to_tickets_page(item)">
                  <v-list-item-icon class="ml-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18.014"
                      viewBox="0 0 18 18.014"
                    >
                      <g id="ticket" transform="translate(-72.188 -72)">
                        <path
                          id="Path_2758"
                          data-name="Path 2758"
                          d="M89.062,76.532a.489.489,0,0,0-.622-.059,1.956,1.956,0,0,1-2.711-2.721.5.5,0,0,0-.059-.617l-.563-.563A1.96,1.96,0,0,0,83.723,72h0a1.959,1.959,0,0,0-1.385.573l-9.582,9.611a1.96,1.96,0,0,0,0,2.765l.377.372a.494.494,0,0,0,.656.034,1.941,1.941,0,0,1,2.736,2.731.489.489,0,0,0,.029.661l.695.695a1.961,1.961,0,0,0,2.77,0h0l9.592-9.592a1.961,1.961,0,0,0,0-2.77ZM79.323,88.747a.981.981,0,0,1-1.385,0l-.4-.4a2.916,2.916,0,0,0-4-4l-.083-.083a.981.981,0,0,1,0-1.385l6.523-6.548,5.882,5.882Zm9.592-9.592-2.369,2.369-5.882-5.887,2.359-2.369a.962.962,0,0,1,.69-.289h0a.978.978,0,0,1,.69.284l.3.3a2.939,2.939,0,0,0,3.93,3.93l.279.279a.976.976,0,0,1,0,1.385Z"
                          transform="translate(0)"
                          fill="#383c40"
                        />
                        <path
                          id="Path_2759"
                          data-name="Path 2759"
                          d="M169.609,216.41l-2.373-2.374a.981.981,0,0,0-1.385,0h0l-2.315,2.315a.981.981,0,0,0,0,1.385l2.374,2.373a.981.981,0,0,0,1.385,0l2.315-2.315a.969.969,0,0,0,.024-1.375C169.629,216.41,169.624,216.4,169.609,216.41Zm-3,3-2.378-2.373,2.315-2.315h0l2.373,2.373Z"
                          transform="translate(-86.606 -134.813)"
                          fill="#383c40"
                        />
                      </g>
                    </svg>
                  </v-list-item-icon>
                  <v-list-item-title>التذاكر</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template slot="item.agent_date" slot-scope="props">
            <div>{{ props.item.agent_date.split(" ")[0] }}</div>
            <div>{{ props.item.agent_date.split(" ")[1].split(".")[0] }}</div>
          </template>
          <template slot="item.agent_notes" slot-scope="props">
            <span v-if="!props.item.agent_notes"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.agent_notes.substring(0, 25) + "..."
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.agent_notes }}</div>
            </v-tooltip>
          </template>
          <template slot="item.quality_notes" slot-scope="props">
            <span v-if="!props.item.quality_notes"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.quality_notes.substring(0, 25) + "..."
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.quality_notes }}</div>
            </v-tooltip>
          </template>
          <template slot="item.source" slot-scope="props">
            {{ props.item.source_text
            }}{{
              props.item.source == 2 ? ` - ${props.item.collaborator_name}` : ""
            }}
          </template>
          <template slot="item.customer_name" slot-scope="props">
            {{ props.item.customer.name }}
          </template>
          <template slot="item.agent_name" slot-scope="props">
            {{ props.item.user.name }}
          </template>
        </table-component>
      </div>
      <!-- Crards -->
      <CardsStyle :tableData="items" />
      <!-- Crards -->
    </div>

    <!--Ready State Table-->
    <div :class="showTable == 2 ? 'mt-6' : 'mt-6 d-none'">
      <h3>العملاء الجدد :</h3>
      <div
        class="printing mb-3"
        :style="
          readyStateReqData.length <= 0 ? 'display:none' : 'display:block'
        "
      >
        <v-app-bar
          color="white"
          style="box-shadow: 0px 0px 6px -4px black; border-radius: 10px"
          class="ml-sm-5"
        >
          <v-spacer class="top-spacer" />
          <v-btn
            depressed
            width="45"
            height="45"
            :color="$vuetify.theme.themes.light.dataActionBTN"
            dark
            fab
            class="ml-1 hidden-sm-and-down"
            @click="exportExcel_2"
            v-if="!$store.state.cardsStyle"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18.116"
              height="18.116"
              viewBox="0 0 12.25 14"
            >
              <g id="excel-file" transform="translate(-1.5)">
                <path
                  id="Path_4097"
                  data-name="Path 4097"
                  d="M11.708,14H1.792a.292.292,0,0,1-.292-.292V3.548a.292.292,0,0,1,.074-.194L4.491.1A.292.292,0,0,1,4.708,0h7A.292.292,0,0,1,12,.292V7a.292.292,0,0,1-.583,0V.583H4.839L2.083,3.659v9.757h9.333V12.25a.292.292,0,1,1,.583,0v1.458A.293.293,0,0,1,11.708,14Z"
                  fill="#fff"
                />
                <path
                  id="Path_4098"
                  data-name="Path 4098"
                  d="M4.917,4.292H2.292a.292.292,0,0,1,0-.583H4.625V.792a.292.292,0,0,1,.583,0V4A.292.292,0,0,1,4.917,4.292Zm8.167,8.167h-7a.876.876,0,0,1-.875-.875v-3.5a.876.876,0,0,1,.875-.875h7a.876.876,0,0,1,.875.875v3.5A.876.876,0,0,1,13.083,12.458Zm-7-4.667a.292.292,0,0,0-.292.292v3.5a.292.292,0,0,0,.292.292h7a.293.293,0,0,0,.292-.292v-3.5a.293.293,0,0,0-.292-.292Z"
                  transform="translate(-0.208 -0.208)"
                  fill="#fff"
                />
                <path
                  id="Path_4099"
                  data-name="Path 4099"
                  d="M11.29,15.67,10.566,14.6h.3a.075.075,0,0,1,.047.012.1.1,0,0,1,.027.03l.572.878a.4.4,0,0,1,.032-.07l.54-.8a.186.186,0,0,1,.03-.036.058.058,0,0,1,.039-.012h.283l-.726,1.052.751,1.139h-.293a.079.079,0,0,1-.053-.017.176.176,0,0,1-.031-.038l-.588-.919a.316.316,0,0,1-.027.061l-.572.858a.21.21,0,0,1-.032.039.065.065,0,0,1-.05.018h-.275l.755-1.125Zm1.754.876h.948V16.8H12.747V14.6h.3Zm2.4-1.6a.117.117,0,0,1-.03.034.059.059,0,0,1-.039.012.111.111,0,0,1-.061-.026q-.042-.031-.086-.058a.805.805,0,0,0-.128-.057.558.558,0,0,0-.18-.026.575.575,0,0,0-.176.027.377.377,0,0,0-.128.073.279.279,0,0,0-.076.109.348.348,0,0,0-.026.134.243.243,0,0,0,.045.152.385.385,0,0,0,.119.1.952.952,0,0,0,.168.074l.193.065c.065.023.131.048.192.075a.658.658,0,0,1,.168.107.486.486,0,0,1,.12.158.515.515,0,0,1,.045.229.735.735,0,0,1-.049.269.632.632,0,0,1-.143.22.672.672,0,0,1-.231.146.849.849,0,0,1-.311.054.956.956,0,0,1-.389-.077.908.908,0,0,1-.3-.209l.086-.14a.107.107,0,0,1,.03-.029.07.07,0,0,1,.038-.012.118.118,0,0,1,.073.034.946.946,0,0,0,.1.076.608.608,0,0,0,.366.107.577.577,0,0,0,.188-.029.378.378,0,0,0,.258-.373.269.269,0,0,0-.045-.163.38.38,0,0,0-.118-.106.832.832,0,0,0-.167-.072l-.192-.061a2.009,2.009,0,0,1-.192-.073.608.608,0,0,1-.167-.109.484.484,0,0,1-.118-.164.571.571,0,0,1-.045-.244.579.579,0,0,1,.177-.418.622.622,0,0,1,.211-.134.75.75,0,0,1,.29-.05.921.921,0,0,1,.335.058.773.773,0,0,1,.264.169l-.072.141Z"
                  transform="translate(-3.765 -6.075)"
                  fill="#fff"
                />
              </g>
            </svg>
          </v-btn>
          <v-btn
            depressed
            width="45"
            height="45"
            @click="printNow_2"
            :color="$vuetify.theme.themes.light.dataActionBTN"
            dark
            fab
          >
            <v-icon size="20" color="white">mdi-printer</v-icon>
          </v-btn>
          <v-spacer class="top-spacer" />
        </v-app-bar>
      </div>
      <div class="table__view">
        <table-component
          id="customer-requests"
          :headers="headersTwo"
          :items="readyStateReqData"
          :loading="loadingTwo"
          itemKey="request_id"
          @refresh="receiveCutomers"
        >
          <!-- eslint-disable -->
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#3f0e40"
                  depressed
                  small
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  style="cursor: pointer"
                  @click="$router.push({ name: 'chat_component' })"
                >
                  <v-list-item-icon style="margin-left: 10px !important"
                    ><v-icon>mdi-chat</v-icon></v-list-item-icon
                  >
                  <v-list-item-title>الدردشة</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template slot="item.agent_date" slot-scope="props">
            <div>{{ props.item.agent_date.split(" ")[0] }}</div>
            <div>{{ props.item.agent_date.split(" ")[1].split(".")[0] }}</div>
          </template>
          <template slot="item.comment" slot-scope="props">
            <span v-if="!props.item.comment"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.comment.substring(0, 25) + "..."
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.comment }}</div>
            </v-tooltip>
          </template>
          <template slot="item.quacomment" slot-scope="props">
            <span v-if="!props.item.quacomment"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.quacomment.substring(0, 25) + "..."
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.quacomment }}</div>
            </v-tooltip>
          </template>
          <template slot="item.source" slot-scope="props">
            {{ props.item.source ? props.item.source["value"] : "" }}
          </template>
        </table-component>
      </div>
      <!-- Test -->
      <CardsStyle :tableData="items" />
      <!-- Test -->
      <CallsDialog />
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import CardsStyle from "@/components/Base/CardsStyle.vue";
import { RequestCallsIcon } from "@/assets/adminIcons/requestCalls";
import CallsDialog from "@/components/Charts/CallsDialog.vue";

export default {
  name: "HelloWorld",
  components: { CardsStyle, RequestCallsIcon, CallsDialog },
  data: () => ({
    loading: false,
    disabledBtn: false,
    page: 1,
    addNewRequestDialog: false,
    items: [],
    pagination: {},
    selected_requests: [],
    headers: [
      {
        text: "تاريخ الطلب",
        value: "req_date",
        align: "center",
        width: "120px",
      },
      { text: "نوع الطلب", value: "type", align: "center" },
      { text: "اسم الاستشاري", value: "agent_name", align: "center" },
      { text: "عميل", value: "customer_name", align: "center" },
      { text: "حالة الطلب", value: "status", align: "center" },
      { text: "مصدر المعامله", value: "source", align: "center" },
      { text: "تصنيف المعامله", value: "class_id_agent_text", align: "center" },
      { text: "الملاحظه", value: "agent_notes", align: "center" },
      { text: "ملاحظة الجوده", value: "quality_notes", align: "center" },
      { text: "تاريخ الاستشاري", value: "agent_date", align: "center" },
      { text: "العمليات", value: "action", align: "center" },
    ],

    loadingTwo: false,
    readyToRecieve: "",
    readyLable: "",
    headersTwo: [
      { text: "تاريخ الرسالة", value: "message_at_date", align: "center" },
      { text: "وقت الرسالة", value: "message_at_time", align: "center" },
      { text: "نص الرسالة", value: "message_value", align: "center" },
      { text: "العمليات", value: "action", align: "center" },
    ],
    readyStateReqData: [],
    showTable: 1,
    showBtn: false,
    showBtnText: "طلبات قائمة الإنتظار",
  }),
  watch: {
    selected_requests(val) {
      this.$emit("requests", val);
    },
    page(val) {
      this.get_requests(val);
    },

    "$store.state.rates_in_admin_count": {
      handler(val) {
        if (val > this.$store.state.redirect_count_of_rates && localStorage["role"] == "1") {
          this.$store.state.showAlertOfRatingDidntProccess = true;
          this.$router.push({ name: "salesManager_SMRateServices" });
        }
      },
      immediate: true 
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval != oldvalue) {
          this.get_requests(this.page);
        }
      },
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },
  },
  methods: {
    async get_all_requests(page = 1) {
      this.loading = true;
      await Axios.get(
        `https://appproduct.alwsata.com.sa/api/v2/employee/Requests?type=all&page=${page}&per_page=12`,
        {
          headers: this.request_headers(),
        }
      ).then((response) => {
        this.items = response.data.payload.data;
        this.pagination = {
          limit: 12,
          count: response.data.payload.count_of_all,
        };
        this.loading = false;
      });
    },

    itemRowBackground(item) {
      return item.type === "طلب تم فتحه" ? "style-1" : "style-2";
    },

    ask_for_more_customers() {
      Axios.get(
        "https://appproduct.alwsata.com.sa/api/agent/AskForMoreCustomers",
        {
          headers: this.request_headers(),
        }
      )
        .then(() => {
          this.showPopup("success", "تم طلب مزيد من العملاء بنجاح");
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },

    //Switch Ready State
    async getReadyReceivingState() {
      await Axios.get(
        "https://appproduct.alwsata.com.sa/api/agent/ready-receive",
        {
          headers: this.request_headers(),
        }
      ).then(async (res) => {
        if (res.data.payload === 0) {
          this.readyToRecieve = false;
          this.disabledBtn = true;
          this.showBtn = false;
          await this.receiveCutomers();
        } else {
          this.readyToRecieve = true;
          this.showBtn = true;
          this.disabledBtn = false;
        }
        this.changeState(this.readyToRecieve);
      });
    },
    async switchReadyState() {
      let body = { agent_statue: this.readyToRecieve ? 1 : 0 };

      await Axios.put(
        "https://appproduct.alwsata.com.sa/api/agent/ready-receive",
        body,
        {
          headers: this.request_headers(),
        }
      )
        .then((res) => {
          if (this.readyToRecieve) {
            this.showPopup(
              "success",
              " بتشغيلك لهذه الخاصية يعني انك جاهز لستلام رسائل فوريه والرد عليها بشكل فوري لعملاء جدد من موقعنا"
            );
          } else {
            this.showPopup(
              "success",
              res.data.message + " , الان لايمكنك استلام رسائل فوريه من عملائنا"
            );
          }
          this.changeState(this.readyToRecieve);
        })
        .then(async () => {
          if (this.readyToRecieve) {
            this.showBtn = true;
            await this.receiveCutomers();
          } else {
            this.showBtn = false;
            this.showTable = 1;
            this.showBtnText = "طلبات قائمة الإنتظار";
          }
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        });
    },
    async receiveCutomers() {
      this.loadingTwo = true;
      await Axios.get(
        "https://appproduct.alwsata.com.sa/api/agent/waiting-list",
        {
          headers: this.request_headers(),
        }
      ).then((res) => {
        this.loadingTwo = false;
        this.readyStateReqData = [];
        res.data.payload.forEach((body) => {
          body.message_at_date = new Date(body.message_at)
            .toLocaleDateString()
            .split("/")
            .reverse()
            .join("/");
          body.message_at_time = new Date(body.message_at).toLocaleTimeString();
          if (body.message_type != "text") {
            body.message_value = "تم ارسال ملف";
          }
          this.readyStateReqData.push(body);
        });
      });
    },
    toggleTables() {
      if (this.showTable == 1) {
        this.showTable = 2;
        this.showBtnText = "عرض جدول الطلبات المستلمة";
        this.$store.state.cardsStyle = false;
        document.querySelector(".separatorr").style.cssText = `
          display: none !important
        `;
      } else {
        this.showTable = 1;
        this.showBtnText = "طلبات قائمة الإنتظار";
        document.querySelector(".separatorr").style.cssText = `
          block: none !important
        `;
      }
    },
    async pullRequest(reqId) {
      await Axios.post(
        `https://appproduct.alwsata.com.sa/api/agent/transfer-request?request_id=${reqId}`,
        {
          headers: this.request_headers(),
        }
      ).catch((err) => console.log(err));
    },
    changeState(val) {
      if (val) {
        this.readyLable = "جاهز لاستلام عملاء جدد";
        setTimeout(() => {
          document.querySelector(".v-input .v-label").style.color = "#2f4395";
        }, 100);
      } else {
        this.readyLable = "غير جاهز لاستلام عملاء جدد";
        setTimeout(() => {
          document.querySelector(".v-input .v-label").style.color =
            "rgb(155 178 215)";
        }, 100);
      }
    },
    //End Switch Ready State

    async get_requests(page = 1) {
      this.loading = true;
      await Axios.get(
        `https://appproduct.alwsata.com.sa/api/v2/employee/Requests?type=received&page=${page}&per_page=${this.$store.state.counterOfShow}`,
        {
          headers: this.request_headers(),
        }
      ).then((response) => {
        this.items = response.data.payload.data;

        this.pagination = {
          limit: 12,
          count: response.data.payload.count_of_received,
        };
        this.loading = false;
      });
    },
    to_request_details(item) {
      if (localStorage["role"] && localStorage["role"] == "1") {
        this.$router.push({
          name: "salesManager_request_details",
          params: { id: item.id },
        });
      } else {
        this.$router.push({
          name: "request_details",
          params: { id: item.id },
        });
      }
    },

    getRequestCalls(item) {
      this.$store.state.idOfRequestCalls = item.id;
      this.$store.state.openCallsDialog = true;
    },

    to_tickets_page(item) {
      this.$router.push({ name: "tickets", params: { id: item.id } });
    },
    exportExcel() {
      const table = document.querySelector("#recieved-requests");
      this.exportTableToCsv(table, "الطلبات المستلمة");
    },
    printNow() {
      this.items.forEach((data) => {
        data.customer_name = data.customer.name;
      });
      this.printTable(this.items, this.headers, "جدول الطلبات المستلمة");
    },

    exportExcel_2() {
      const table = document.querySelector("#customer-requests");
      this.exportTableToCsv(table, "طلبات العملاء الجدد");
    },
    printNow_2() {
      this.printTable(
        this.readyStateReqData,
        this.headersTwo,
        "جدول طلبات العملاء الجدد"
      );
    },
    to_chat_page(item) {
      this.$router.push({
        name: "chat_component",
        query: {
          customer_name: item.customer.name,
          customer_id: item.customer.id,
          request_id: item.id,
        },
      });
    },
  },
  async created() {
    await this.getReadyReceivingState();
  },
  async mounted() {
    await this.get_requests();
  },
};
</script>

<style lang="scss">
.v-input .v-label {
  font-size: 20px;
  margin-right: 16px;
  margin-bottom: 3px;
}
@media (max-width: 990px) {
  .btns-dad {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 11px;
  }
}
@media (max-width: 600px) {
  .receive-btn {
    font-size: 13px !important;
  }
}

.style-1 {
  background-color: rgb(215, 215, 44);
}
.style-2 {
  background-color: rgb(114, 114, 67);
}
</style>
