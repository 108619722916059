import SMTheNotifc from "@/views/sales_manager/TheNotifc.vue";
import SMTheCalender from "@/views/sales_manager/TheCalendar.vue";
import SMFundCalculator from "@/views/sales_manager/FundCalculator.vue";
import RequestTicket from "@/components/RequestDetails/RequestTicket.vue";
import SMSentTickets from "@/components/Tickets/sales_manager/SentTickets.vue";
import SMRecievedTickets from "@/components/Tickets/sales_manager/RecievedTickets.vue";
import SMCompletedTickets from "@/components/Tickets/sales_manager/CompletedTickets.vue";
import SMTicketDetails from "@/components/Tickets/sales_manager/TicketDetails.vue";
import SMVacations from "@/views/sales_manager/AllVacation.vue";
import SMAgentVacations from "@/views/sales_manager/AllAgentVacation.vue";
import SMAddVacation from "@/views/sales_manager/AddVacation.vue";
import SMRateServices from "@/views/sales_manager/RateServices.vue";
import SMERelatedAgents from "@/components/Requests/sales_manager/RelatedAgents.vue";
import SMEAllRequests from "@/components/Requests/sales_manager/AllRequests.vue";
import SMERecievedRequests from "@/components/Requests/sales_manager/RecievedRequests.vue";
import SMESpecialRequests from "@/components/Requests/sales_manager/SpecialRequests.vue";
import SMEFollowRequests from "@/components/Requests/sales_manager/FollowRequests.vue";
import SMECompletedRequests from "@/components/Requests/sales_manager/CompletedRequests.vue";
import SMEArchievedRequests from "@/components/Requests/sales_manager/ArchievedRequests.vue";
import SMRequestClassificationChart from "@/views/sales_manager/RequestClassificationChart.vue";
import SMAllRequests from "@/components/Requests/SMAllRequests.vue";
import SMRecievedRequests from "@/components/Requests/SMRecievedRequests.vue";
import FollowRequests from "@/components/Requests/FollowRequests.vue";
import CompletedRequests from "@/components/Requests/CompletedRequests.vue";
import BuyingRequests from "@/components/Requests/BuyingRequests.vue";
import SMMortgageBuyingRequests from "@/components/Requests/SMMortgageBuyingRequests.vue";
import SMBuyingRequests from "@/components/Requests/SMBuyingRequests.vue";
import SMMortgageRequests from "@/components/Requests/SMMortgageRequests.vue";
import SMRejectedRequests from "@/components/Requests/SMRejectedRequests.vue";
import SMRequestDetails from "@/components/SMRequestDetails/RequestDetails.vue";
import SMAgentData from "@/components/SMRequestDetails/AgentData.vue";
import SMEstateData from "@/components/SMRequestDetails/EstateData.vue";
import SMFundData from "@/components/SMRequestDetails/FundData.vue";
import FundCalc from "@/components/RequestDetails/FundCalc.vue";
import SMRequestAttachments from "@/components/SMRequestDetails/RequestAttachments.vue";
import SMPrePayment from "@/components/SMRequestDetails/PrePayment.vue";
import SMRequestCalls from "@/components/SMRequestDetails/SMRequestCalls.vue";
import SMRequestLog from "@/components/SMRequestDetails/RequestLog.vue";
import RequestTickets from "@/components/RequestDetails/RequestTickets.vue";
import FundHistory from "@/views/agent2/FundHistory.vue";
import SMRequests from "@/views/sales_manager/Charts/SMRequests/index.vue";
import SalesProductivityContent from "@/views/sales_manager/Charts/Productivity/SalesProductivityContent";
import RequestsType from "@/views/sales_manager/Charts/RequestsType/index.vue";
// import DailyReport from "@/views/sales_manager/Charts/DailyReport/index.vue";
import DailyReport from "@/views/sales_manager/Charts/DailyReport/NewIndex.vue";
import RequestCallsReport from "@/views/sales_manager/Charts/Calls/RequestCalls.vue";
import DuplicatedRegister from "@/views/sales_manager/Charts/DuplicatedRegister/DuplicatedRegister.vue";
import RateAverageReport from "@/views/sales_manager/Charts/RateAverage/rateAverageReport.vue";
import UsersSettings from "@/views/sales_manager/Charts/SwitchUser/UsersSettings.vue";
import SMTicketsDetails from "@/components/Requests/SMTicketsDetails.vue"



export const salesManagerRouter = [
    {
        path: "/salesManager/notifications",
        name: "salesManager_TheNotifc",
        component: SMTheNotifc,
        meta: {
            icon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="18.552" height="19" viewBox="0 0 20.552 21">
          <g id="message_1_" data-name="message (1)" transform="translate(1 1)">
            <g id="bell">
              <line id="Line_3" data-name="Line 3" x2="3" transform="translate(8 19)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path id="Path_2642" data-name="Path 2642" d="M22.552,19.179H4a3.044,3.044,0,0,0,1.687-2.732V11.59A7.59,7.59,0,0,1,13.276,4h0a7.59,7.59,0,0,1,7.59,7.59v4.857a3.044,3.044,0,0,0,1.687,2.732Z" transform="translate(-4 -4)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </g>
          </g>
        </svg>
        `,
            listed: true,
            group: '',
            page_name: "الإشعارات",
        },
    },
    {
        path: "/salesManager/calender",
        name: "salesManager_TheCalender",
        component: SMTheCalender,
        meta: {
            icon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="17.599" height="18.22" viewBox="0 0 17.599 18.22">
          <path id="reminder" d="M19.967,18.29a2.376,2.376,0,0,1-.7-1.278c0-.015,0-.029,0-.043V15.339A2.545,2.545,0,0,0,17.3,12.85V5.3a2.2,2.2,0,0,0-2.2-2.2H13.137V2.587a.44.44,0,0,0-.88,0V3.1H7.544V2.587a.44.44,0,0,0-.88,0V3.1H4.7A2.2,2.2,0,0,0,2.5,5.3v10.41a2.2,2.2,0,0,0,2.2,2.2h9.041a3.559,3.559,0,0,1-.311.377.44.44,0,0,0,.308.754h1.521a1.44,1.44,0,0,0,2.871,0h1.528a.44.44,0,0,0,.308-.754ZM4.7,3.984H6.664v.521a.44.44,0,1,0,.88,0V3.984h4.713v.521a.44.44,0,1,0,.88,0V3.984H15.1a1.32,1.32,0,0,1,1.32,1.32v.9H3.38V5.3A1.32,1.32,0,0,1,4.7,3.984Zm0,13.049a1.32,1.32,0,0,1-1.32-1.32V7.082H16.421V12.8a2.56,2.56,0,0,0-2.282,2.541v1.674c0,.015-.005.014-.005.021Zm12,2.453a.562.562,0,0,1-.543-.44h1.085a.561.561,0,0,1-.542.44Zm.973-1.32H14.643a2.332,2.332,0,0,0,.375-1.1.535.535,0,0,0,0-.054V15.339a1.68,1.68,0,1,1,3.359,0v1.729a2.335,2.335,0,0,0,.375,1.1Z" transform="translate(-2.5 -2.147)" fill="#fff"/>
        </svg>
      `,
            group: '',
            listed: true,
            page_name: "التذكيرات"
        },
    },
    {
        path: "/salesManager/calculator",
        name: "salesManager_FundCalculator",
        component: SMFundCalculator,
        meta: {
            icon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
          <path id="calculator" d="M4.682,6.318H5.5v.818h0a.409.409,0,0,0,.409.409h0a.409.409,0,0,0,.409-.409V6.318h.818a.409.409,0,1,0,0-.818H6.318V4.682a.409.409,0,1,0-.818,0V5.5H4.682a.409.409,0,0,0,0,.818Zm.649,7.773-.868.868a.409.409,0,1,0,.578.578l.868-.868.868.868a.409.409,0,0,0,.578-.578l-.868-.868.868-.868.006-.006a.409.409,0,1,0-.584-.573l-.868.868-.868-.868-.006-.006a.409.409,0,0,0-.573.584ZM15.318,5.5H12.864a.409.409,0,0,0,0,.818h2.455a.409.409,0,0,0,0-.818Zm-2.455,7.773h2.455a.409.409,0,0,0,0-.818H12.864a.409.409,0,0,0,0,.818ZM16.546,1H3.455A2.457,2.457,0,0,0,1,3.455V16.546A2.457,2.457,0,0,0,3.455,19H16.546A2.457,2.457,0,0,0,19,16.546V3.455A2.457,2.457,0,0,0,16.546,1ZM9.591,18.182H3.455a1.638,1.638,0,0,1-1.636-1.636V10.409H9.591Zm0-8.591H1.818V3.455A1.638,1.638,0,0,1,3.455,1.818H9.591Zm8.591,6.955a1.638,1.638,0,0,1-1.636,1.636H10.409V10.409h7.773Zm0-6.955H10.409V1.818h6.136a1.638,1.638,0,0,1,1.636,1.636Zm-5.318,6.136h2.455a.409.409,0,0,0,0-.818H12.864a.409.409,0,0,0,0,.818Z" transform="translate(-1 -1)" fill="#fff"/>
        </svg>

      `,
            group: '',
            listed: true,
            page_name: "حاسبة التمويل"
        },
    },
    {
        path: "/salesManager/requests/:id/tickets/:ticket",
        name: "salesManager_request_ticket",
        component: RequestTicket,
        meta: { listed: false },
    },
    {
        path: "/salesManager/tickets/sent",
        name: "salesManager_sent_tickets",
        component: SMSentTickets,
        meta: {
            icon: "",
            listed: true,
            group: "التذاكر ",
            page_name: "التذاكر المرسله",
        },
    },
    {
        path: "/salesManager/tickets/recieved",
        name: "salesManager_recieved_tickets",
        component: SMRecievedTickets,
        meta: {
            icon: "",
            listed: true,
            group: "التذاكر ",
            page_name: "التذاكر المستلمه",
        },
    },
    {
        path: "/salesManager/tickets/completed",
        name: "salesManager_completed_tickets",
        component: SMCompletedTickets,
        meta: {
            icon: "",
            listed: true,
            group: "التذاكر ",
            page_name: "التذاكر المكتمله",
        },
    },
    {
        path: "/salesManager/tickets/:ticket",
        name: "salesManager_ticket_details",
        component: SMTicketDetails,
        meta: { listed: false },
    },
    // {
    //     path: "/salesManager/vacations",
    //     name: "salesManager_vacations",
    //     component: SMVacations,
    //     meta: {
    //         icon: "",
    //         listed: true,
    //         group: "طلبات الاجازات",
    //         page_name: "طلباتي ",
    //     },
    // },
    // {
    //     path: "/salesManager/agent_vacations",
    //     name: "salesManager_agent_vacations",
    //     component: SMAgentVacations,
    //     meta: {
    //         icon: "",
    //         listed: true,
    //         group: "طلبات الاجازات",
    //         page_name: "اجازات الاستشاريين ",
    //     },
    // },
    // {
    //     path: "/salesManager/vacations/add",
    //     name: "salesManager_add_vacation",
    //     component: SMAddVacation,
    //     meta: {
    //         icon: "",
    //         listed: true,
    //         group: "طلبات الاجازات",
    //         page_name: "اضافة",
    //     },
    // },
    {
        path: "/salesManager/rates",
        name: "salesManager_SMRateServices",
        component: SMRateServices,
        meta: {
            icon: `
        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="21.599" height="21.468" viewBox="0 0 128.000000 128.000000" preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none">
        <path d="M593 1228 c-11 -13 -51 -96 -88 -186 -38 -90 -72 -165 -76 -168 -4 -2 -95 -13 -200 -23 -176 -17 -194 -20 -211 -41 -40 -50 -23 -72 231 -292 l53 -46 -43 -192 c-40 -177 -42 -194 -28 -218 29 -52 61 -43 240 62 88 53 165 96 171 96 5 0 82 -43 169 -96 176 -106 209 -114 238 -62 14 24 12 41 -28 218 -36 161 -41 194 -30 204 8 7 52 45 99 86 195 170 211 192 172 240 -17 21 -35 24 -212 41 -107 10 -197 22 -201 26 -5 5 -39 81 -77 171 -37 90 -76 172 -85 182 -23 27 -69 25 -94 -2z m122 -248 c42 -102 75 -166 89 -175 13 -8 90 -20 196 -30 96 -9 176 -17 177 -18 1 -1 -61 -58 -138 -125 -118 -105 -139 -127 -139 -152 0 -16 16 -99 36 -185 20 -86 33 -159 31 -162 -3 -2 -74 36 -158 86 -84 50 -159 91 -168 91 -9 0 -87 -43 -173 -95 -87 -52 -158 -88 -158 -82 0 7 16 83 35 168 20 84 35 167 33 184 -2 24 -28 52 -140 150 -75 66 -136 121 -135 122 1 1 81 9 177 18 101 9 184 22 196 30 12 8 47 77 89 174 37 89 71 161 75 161 4 0 37 -72 75 -160z"/>
        </g>
        </svg>
        `,
            listed: true,
            group: '',
            page_name: "تقييمات الخدمة",
        },
    },
    {
        path: "/salesManager/agents/all",
        name: "SMERelatedAgents",
        component: SMERelatedAgents,
        meta: {
            icon: "",
            listed: true,
            group: "استشاري المبيعات",
            page_name: "استشاري المبيعات",
            breadcrumb: "",
        },
    },
    {
        path: "/salesManager/agents/requests/all",
        name: "SMEAllRequests",
        component: SMEAllRequests,
        meta: {
            icon: "",
            listed: true,
            group: "استشاري المبيعات",
            page_name: "جميع الطلبات",
            breadcrumb: "",
        },
    },
    {
        path: "/salesManager/agents/requests/recieved",
        name: "SMERecievedRequests",
        component: SMERecievedRequests,
        meta: {
            icon: "",
            listed: true,
            group: "استشاري المبيعات",
            page_name: "الطلبات المستلمه",
        },
    },
    {
        path: "/salesManager/agents/requests/special",
        name: "SMESpecialRequests",
        component: SMESpecialRequests,
        meta: {
            icon: "",
            listed: true,
            group: "استشاري المبيعات",
            page_name: "الطلبات المميزة",
        },
    },
    {
        path: "/salesManager/agents/requests/follow",
        name: "SMEFollowRequests",
        component: SMEFollowRequests,
        meta: {
            icon: "",
            listed: true,
            group: "استشاري المبيعات",
            page_name: "الطلبات المتابعه",
        },
    },
    {
        path: "/salesManager/agents/requests/completed",
        name: "SMECompletedRequests",
        component: SMECompletedRequests,
        meta: {
            icon: "",
            listed: true,
            group: "استشاري المبيعات",
            page_name: "الطلبات المكتمله",
        },
    },
    {
        path: "/salesManager/agents/requests/archived",
        name: "SMEArchievedRequests",
        component: SMEArchievedRequests,
        meta: {
            icon: "",
            listed: true,
            group: "استشاري المبيعات",
            page_name: "الطلبات المؤرشفة",
        },
    },
    {
        path: "/salesManager/charts/request-classification",
        name: "SMReportRequests",
        component: SMRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الرسوم البيانية ",
            page_name: "الطلبات",
        },
    },
    {
        path: "/salesManager/charts/productivity-report",
        name: "SalesProductivityContent",
        component: SalesProductivityContent,
        meta: {
            icon: "",
            listed: true,
            group: "الرسوم البيانية ",
            page_name: "تقرير الإنتاجية",
        },
    },
    {
        path: "/salesManager/chart/request_class_with_agent",
        name: "SMReportType",
        component: RequestsType,
        meta: {
            icon: "",
            listed: true,
            group: "الرسوم البيانية ",
            page_name: "تصنيف الطلب-استشاري المبيعات",
        },
    },
    {
        path: "/salesManager/chart/dailyPerformance",
        name: "DailyReport",
        component: DailyReport,
        meta: {
            icon: "",
            listed: true,
            group: "الرسوم البيانية ",
            page_name: "الاداء اليومي",
        },
    },

    {
        path: "/salesManager/chart/calls",
        name: "RequestCallsReport",
        component: RequestCallsReport,
        meta: {
            icon: "",
            listed: true,
            group: "الرسوم البيانية ",
            page_name: "تقرير المكالمات",
        },
    },

    {
        path: "/salesManager/chart/duplicated-register",
        name: "DuplicatedRegister",
        component: DuplicatedRegister,
        meta: {
            icon: "",
            listed: true,
            group: "الرسوم البيانية ",
            page_name: "تقرير محاولات التسجيل",
        },
    },

    {
        path: "/salesManager/chart/rateAverage",
        name: "rateAverage",
        component: RateAverageReport,
        meta: {
            icon: "",
            listed: true,
            group: "الرسوم البيانية ",
            page_name: "تقييمات الخدمة",
        },
    },
    {
        path: "/salesManager/requests/all",
        name: "salesManagerAll",
        component: SMAllRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات ",
            page_name: "جميع الطلبات",
            breadcrumb: "",
        },
    },
    {
        path: "/salesManager/requests/recieved",
        name: "salesManager_recieved",
        component: SMRecievedRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات ",
            page_name: "الطلبات المستلمه",
        },
    },
    {
        path: "/salesManager/requests/follow",
        name: "salesManager_following",
        component: FollowRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات ",
            page_name: "الطلبات المتابعه",
        },
    },
    {
        path: "/salesManager/requests/completed",
        name: "salesManager_completed",
        component: CompletedRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات ",
            page_name: "الطلبات المكتمله",
        },
    },
    {
        path: "/salesManager/requests/prepaid",
        name: "salesManager_buying",
        component: BuyingRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات ",
            page_name: "طلبات الشراء - الدفعه",
        },
    },
    {
        path: "/salesManager/requests/mortgageprepaid",
        name: "salesManager_SMmortgagebuying",
        component: SMMortgageBuyingRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات ",
            page_name: "طلبات الرهن - الشراء",
        },
    },
    {
        path: "/salesManager/requests/paid",
        name: "salesManager_SMbuying",
        component: SMBuyingRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات ",
            page_name: "طلبات الشراء",
        },
    },
    {
        path: "/salesManager/requests/mortgage",
        name: "salesManager_SMmortgage",
        component: SMMortgageRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات ",
            page_name: "طلبات الرهن",
        },
    },
    {
        path: "/salesManager/requests/refused",
        name: "salesManager_SMrejected",
        component: SMRejectedRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات ",
            page_name: "الطلبات المرفوضة",
        },
    },
    {
        path: "/salesManager/requests/:id",
        name: "salesManager_request_details",
        component: SMRequestDetails,
        meta: { icon: "", listed: false, page_name: "تفاصيل الطلب" },
        redirect: { name: "salesManager_agent_data" },
        children: [
            {
                path: "agent-data",
                name: "salesManager_agent_data",
                component: SMAgentData,
                meta: { icon: "", listed: false },
            },
            {
                path: "real-estate-data",
                name: "salesManager_real_estate_data",
                component: SMEstateData,
                meta: { icon: "", listed: false },
            },
            {
                path: "fund-data",
                name: "salesManager_fund_data",
                component: SMFundData,
                meta: { icon: "", listed: false },
            },
            {
                path: "fund-calculator",
                name: "salesManager_fund_calc",
                component: FundCalc,
                meta: { icon: "", listed: false },
            },
            {
                path: "attachments",
                name: "salesManager_attachments",
                component: SMRequestAttachments,
                meta: { icon: "", listed: false },
            },
            {
                path: "prepayment",
                name: "salesManager_prepayment",
                component: SMPrePayment,
                meta: { icon: "", listed: false },
            },
            {
                path: "logs",
                name: "salesManager_logs",
                component: SMRequestLog,
                meta: { icon: "", listed: false },
            },
            {
                path: "tickets",
                name: "salesManager_tickets",
                component: RequestTickets,
                meta: { icon: "", listed: false },
            },
            {
                path: "calls",
                name: "salesManager_calls",
                component: SMRequestCalls,
                meta: { icon: "", listed: false },
            },
        ],
    },
    {
        path: "/salesManager/requests/:id/funding-calculator-history",
        name: "salesManager_FundHistory",
        component: FundHistory,
        meta: {
            icon: "",
            listed: false,
            group: "الرئيسية / الطلبات ",
            page_name: "تاريخ التمويل",
        },
    },

    {
        path: "/salesManager/related-agents",
        name: "sale_manager_users",
        component: UsersSettings,
        meta: { icon: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <g clip-path="url(#clip0_798_740)">
        <path d="M15.9112 15.3781C18.3112 15.3781 20.1779 13.4225 20.1779 11.0225C20.1779 8.62253 18.2223 6.75586 15.8223 6.75586C13.4223 6.75586 11.5557 8.71141 11.5557 11.0225C11.5557 13.4225 13.5112 15.3781 15.9112 15.3781ZM15.8223 8.53364C15.9112 8.53364 15.9112 8.53364 15.8223 8.53364C17.2446 8.53364 18.4001 9.68919 18.4001 11.1114C18.4001 12.5336 17.2446 13.6003 15.8223 13.6003C14.4001 13.6003 13.3334 12.4447 13.3334 11.1114C13.3334 9.68919 14.489 8.53364 15.8223 8.53364Z" fill="white"/>
        <path d="M29.0663 14.8441C27.3775 13.333 25.1552 12.533 22.8441 12.6219H22.133C21.9552 13.333 21.6886 13.9553 21.333 14.4886C21.8663 14.3997 22.3108 14.3997 22.8441 14.3997C24.533 14.3108 26.2219 14.8441 27.5552 15.8219V22.2219H29.333V15.1108L29.0663 14.8441Z" fill="white"/>
        <path d="M20.8001 6.93338C21.2446 5.86671 22.489 5.33338 23.6446 5.77782C24.7112 6.22227 25.2446 7.46671 24.8001 8.62227C24.4446 9.42227 23.6446 9.9556 22.8446 9.9556C22.6668 9.9556 22.4001 9.9556 22.2223 9.86671C22.3112 10.3112 22.3112 10.7556 22.3112 11.1112V11.6445C22.489 11.6445 22.6668 11.7334 22.8446 11.7334C25.0668 11.7334 26.8446 9.9556 26.8446 7.82227C26.8446 5.60004 25.0668 3.82227 22.9334 3.82227C21.5112 3.82227 20.2668 4.53338 19.5557 5.77782C20.0001 6.04449 20.4446 6.40004 20.8001 6.93338Z" fill="white"/>
        <path d="M10.667 14.5784C10.3114 14.0451 10.0448 13.4229 9.86699 12.7118H9.15588C6.84477 12.6229 4.62255 13.4229 2.93366 14.8451L2.66699 15.1118V22.2229H4.44477V15.8229C5.86699 14.8451 7.46699 14.3118 9.15588 14.4007C9.68921 14.4007 10.2225 14.4896 10.667 14.5784Z" fill="white"/>
        <path d="M9.15524 11.6435C9.33302 11.6435 9.5108 11.6435 9.68857 11.5546V11.0213C9.68857 10.5768 9.68857 10.1324 9.77746 9.77683C9.59969 9.86572 9.33302 9.86572 9.15524 9.86572C7.99969 9.86572 7.02191 8.88795 7.02191 7.73239C7.02191 6.57683 7.99969 5.59906 9.15524 5.59906C10.0441 5.59906 10.8441 6.13239 11.1997 6.93239C11.5552 6.48795 12.0886 6.0435 12.533 5.68795C11.3775 3.82128 8.97746 3.19906 7.1108 4.35461C5.24413 5.51017 4.62191 7.91017 5.77746 9.77683C6.48858 10.9324 7.73302 11.6435 9.15524 11.6435Z" fill="white"/>
        <path d="M23.1998 20.1784L23.022 19.9118C21.2442 17.9562 18.7553 16.8007 16.0887 16.8895C13.422 16.8007 10.8442 17.9562 9.06645 19.9118L8.88867 20.1784V26.934C8.88867 27.734 9.51089 28.4451 10.3998 28.4451H21.7776C22.5776 28.4451 23.2887 27.734 23.2887 26.934V20.1784H23.1998ZM21.422 26.6673H10.6664V20.8007C12.0887 19.3784 14.0442 18.6673 16.0887 18.6673C18.0442 18.5784 19.9998 19.3784 21.422 20.8007V26.6673Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_798_740">
        <rect width="32" height="32" fill="white"/>
        </clipPath>
        </defs>
        </svg>`,listed: true, group: "", page_name:  "الاستشاريين"},
    },
    {
        path: "/salesManager",
        name: "salesManagerHome",
        meta: {
            icon: "",
            listed: false,
            page_name: "الاستشاريين",
            breadcrumb: "",
        },
        redirect: { name: "sale_manager_users" },
    },

    {
        path: "/salesManager/tickets-details/:id",
        name: "salesManager-tickets-details",
        component: SMTicketsDetails,
        meta: {
            icon: ``,
            listed: false,
            page_name: "التذاكر",
            breadcrumb: "",
        },
    },
]