import { base } from './base'

export const Dialog = {
  mixins: [base],
  props: { value: Boolean },
  data() {
    return {
      errors: [],
      verrors: [],
      loading: false,
      autofocus: false,
    }
  },
  computed: {
    active: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  watch: {
    active() {
      if (!this.active) {
        this.autofocus = false
      } else {
        this.autofocus = true
      }
    }
  },
  methods: {
    close() {
      this.active = false
      this.$store.state.field = '';
      this.$emit('destroyed')
    },
    
    submitted(msg) {
      this.close()
      this.$emit('submitted')
      this.showPopup('success', msg)
    },
    getErrors() {
      this.validator.$touch()
      for (var field in this.validator.$params) {
        let errors = []
        for (var check in this.validator[field].$params) {
          if (!this.validator[field][check]) {
            if (this.messages[check]) errors.push(this.messages[check])
          }
        }
        this.$set(this.verrors, field, errors)
      }
      return false
    },
  }
}
export default Dialog
