<template>
  <div>
    <HeaderIcons
      :selectedItems="selectedItems"
      @reloadingData="getAllRequests"
      type="moveAllRequests"
      @getData="reloadPage"
    />
    <div v-if="!this.$store.state.latestLoading" style="margin-top: 40px">
      <div v-if="$store.state.views === 'cards'">
        <div v-if="this.$store.state.latestData.length > 0" style="margin-top: 40px">
          <RequestsCard
            :CardData="this.$store.state.latestData"
            @back="handleBack"
            v-if="!this.$store.state.latestLoading"
            @pageCount="handlePageNumber"
          />
          <div>
            <LoadingMore v-if="loadMoreData" style="margin: 40px" />
          </div>
        </div>
        <div v-else>
          <div>
            <button
              @click="handleBack"
              style="
                width: 70px;
                height: 40px;
                border-radius: 5px;
                margin: 50px;
                color: white;
                background-color: #441545;
                direction: ltr;
              "
            >
              رجوع
            </button>
          </div>
          <div class="noData">لا يوجد بيانات</div>
        </div>
      </div>

      <div v-else-if="$store.state.views === 'table'" class="table__view">
        <table-component
          :showSelect="true"
          itemKey="id"
          :selectedRows.sync="selected_requests"
          :headers="headers"
          :pagination="this.$store.state.pagination"
          :limit="$store.state.counterOfShow"
          :items="this.$store.state.tableData"
          :loading="loading"
          @refresh="getTableData"
          v-model="page"
        >
          <template slot="item.quality_notes" slot-scope="props">
            <span v-if="!props.item.quality_notes"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.quality_notes.length > 7
                    ? props.item.quality_notes.substring(0, 7) + "..."
                    : props.item.quality_notes
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.quality_notes }}</div>
            </v-tooltip>
          </template>

          <template slot="item.agent_notes" slot-scope="props">
            <span v-if="!props.item.agent_notes"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.agent_notes.length > 7
                    ? props.item.agent_notes.substring(0, 7) + "..."
                    : props.item.agent_notes
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.agent_notes }}</div>
            </v-tooltip>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="#3f0e40" small depressed dark v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="toRequestDetails(item)">
                  <v-list-item-icon class="ml-2">
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>فتح</v-list-item-title>
                </v-list-item>

                <router-link
                  style="text-decoration: none"
                  target="_blank"
                  :to="{ name: 'request_details', params: { id: item.id } }"
                >
                  <v-list-item>
                    <v-list-item-icon class="ml-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18.528"
                        height="15.111"
                        viewBox="0 0 13.528 10.111"
                      >
                        <g
                          id="Icon_feather-eye-dark"
                          data-name="Icon feather-eye-dark"
                          transform="translate(-1 -5.5)"
                        >
                          <path
                            id="Path_4040"
                            data-name="Path 4040"
                            d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                            fill="none"
                            stroke="#000"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                          />
                          <path
                            id="Path_4041"
                            data-name="Path 4041"
                            d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                            transform="translate(-7.444 -4.653)"
                            fill="none"
                            stroke="#000"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                          />
                        </g>
                      </svg>
                    </v-list-item-icon>
                    <v-list-item-title> فتح في تاب جديد</v-list-item-title>
                  </v-list-item>
                </router-link>

                <v-list-item @click="convertRequest(item)">
                  <v-list-item-icon class="ml-3">
                    <ConvertRequestIcon />
                  </v-list-item-icon>
                  <v-list-item-title>تحويل الطلب</v-list-item-title>
                </v-list-item>

                <v-list-item @click="getRequestCalls(item)">
                  <v-list-item-icon class="ml-3">
                    <RequestCallsIcon />
                  </v-list-item-icon>
                  <v-list-item-title>المكالمات</v-list-item-title>
                </v-list-item>

                <v-list-item @click="tickets(item)">
                  <v-list-item-icon class="ml-2">
                    <TicketIcon />
                  </v-list-item-icon>
                  <v-list-item-title>التذاكر</v-list-item-title>
                </v-list-item>

                <v-list-item @click="toOrderRequest(item)">
                  <v-list-item-icon class="ml-2">
                    <OrderHistory />
                  </v-list-item-icon>
                  <v-list-item-title>سجل الطلب</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </table-component>
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
      "
      v-else
    >
      <LoadingMore />
    </div>
    <v-snackbar
      v-model="active"
      color="#40BE72"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <span class="font-weight-bold" style="font-size: 16px">{{ message }}</span>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <AdminDialog @selectedValue="selectedValue" :items="allStatus" />
    <FilterDialogInAdmin pageName="latest" />
    <OrderRequestDialog />
    <CallsDialog />
  </div>
</template>

<script>
import Axios from "axios";
import RequestsCard from "@/components/Charts/RequestsCard.vue";
import LoadingMore from "@/components/Charts/LoadingMore.vue";
import HeaderIcons from "@/components/Charts/HeaderIcons.vue";
import AdminDialog from "@/components/AdminComponent/AdminDialog.vue";
import FilterDialogInAdmin from "@/components/Charts/FilterDialogInAdmin.vue";
import { formatDate } from "@/mixins/formatDate";
import { ConvertRequestIcon } from "@/assets/adminIcons/convertRequest";
import { TicketIcon } from "@/assets/adminIcons/ticketIcon";
import { SourceValue } from "@/mixins/sourceValue";
import CallsDialog from "@/components/Charts/CallsDialog.vue";
import { RequestCallsIcon } from "@/assets/adminIcons/requestCalls";
import { OrderHistory } from "@/assets/adminIcons/orderHistory";
import OrderRequestDialog from "@/components/AdminComponent/OrderRequestDialog.vue";

export default {
  name: "AdminSupport",
  components: {
    TicketIcon,
    ConvertRequestIcon,
    OrderHistory,
    OrderRequestDialog,
    FilterDialogInAdmin,
    AdminDialog,
    RequestsCard,
    LoadingMore,
    HeaderIcons,
    CallsDialog,
    RequestCallsIcon,
  },

  data() {
    return {
      allGetData: [],
      selected_requests: [],
      allRequests: [],
      pagination: {
        limit: 3,
        count: this.$store.state.searchedCount,
      },
      page: 1,
      selectedRows: [],
      allData: [],
      active: false,
      message: "",
      showSelect: { default: false },
      copySupportRequests: [],
      loadMoreData: false,
      count: null,
      loading: false,
      selectId: null,
      allStatus: [],
      selectedItems: [],
      countPage: 1,
      listenToFreezeAction: this.$store.state.freezeActionIsDone,
      headers: [
        { align: "center", value: "data-table-select" },
        { text: "رقم الطلب", value: "رقم الطلب", align: "center" },
        { text: "تاريخ الطلب", value: "تاريخ الطلب", width: "107px", align: "center" },
        { text: "نوع الطلب", value: "نوع الطلب", align: "center" },
        {
          text: "استشاري المبيعات",
          value: "استشاري المبيعات",
          width: "120px",
          align: "center",
        },
        { text: "عميل", value: "عميل", align: "center" },
        { text: "الجوال", value: "الجوال", align: "center" },
        { text: "حالة الطلب", value: "حالة الطلب", width: "90px", align: "center" },
        {
          text: "مصدر المعامله",
          value: "مصدر المعامله",
          width: "90px",
          align: "center",
        },
        {
          text: "تصنيف الاستشاري",
          value: "تصنيف الاستشاري",
          width: "100px",
          align: "center",
        },
        {
          text: "ملاحظه الاستشاري ",
          value: "agent_notes",
          align: "center",
        },
        {
          text: "ملاحظة الجوده",
          value: "quality_notes",
          align: "center",
        },
        {
          text: "تاريخ الاستشاري",
          value: "تاريخ الاستشاري",
          width: "107px",
          align: "center",
        },
        {
          text: "استلام الجودة",
          value: "هل تم استلامه من قبل الجوده",
          align: "center",
        },
        {
          text: "تم التحديث ",
          value: "تم التحديث في",
          width: "107px",
          align: "center",
        },
        { text: "", value: "action", align: "center" },
      ],
    };
  },

  watch: {
    listenToFreezeAction(val) {
      console.log(val);
    },

    page(val) {
      if (this.$store.state.isSearch === false) {
        this.getTableData(val);
      } else {
        this.searchRequest(val, "table");
      }
    },
    selected_requests(val) {
      this.selectedItems = val;
      this.$emit("requests", val);
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval != oldvalue) {
          this.getTableData(this.page);
        }
      },
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },
  },

  methods: {
    handleBack() {
      this.$store.state.latestData = [];
      this.$store.state.tableData = [];
      this.$store.state.lat = true;
      this.$store.state.isSearch = false;
      this.$store.state.back = false;
      this.getAllRequests(1);
    },

    toOrderRequest(item) {
      this.$store.state.orderRequestId = item.id;
      if (this.$store.state.showCards || this.$store.state.showCards == false) {
        this.$store.state.orderRequestDialog = true;
      } else {
        this.$store.state.openOrderRequestSection = true;
      }
    },

    getRequestCalls(item) {
      this.$store.state.idOfRequestCalls = item.id;
      this.$store.state.openCallsDialog = true;
    },

    reloadPage() {
      this.$store.state.latestData = [];
      this.$store.state.tableData = [];
      this.allGetData = [];
      this.$store.state.latestLoading = true;
      this.$store.state.isSearch = false;
      this.getAllRequests(1);
      this.$store.state.startElement = [];
    },

    toRequestDetails(item) {
      this.$router.push({
        name: "request_details",
        params: { id: item.id },
      });
    },

    convertRequest(item) {
      this.$store.state.openDialog = true;
      this.getUserAdmin();
      this.selectId = item.id;
    },

    getUserAdmin() {
      let url = "https://appproduct.alwsata.com.sa/api/v2/employee/sales-agent-users";
      Axios.get(url, { headers: this.request_headers() }).then((res) => {
        this.allStatus = res.data.payload;
      });
    },

    getAgentRequest(itemSelected) {
      let body = new FormData();

      body.append("request_ids[0]", this.selectId);
      body.append("agents_ids[0]", itemSelected.id);

      Axios.post(
        `https://appproduct.alwsata.com.sa/api/v2/employee/Requests/moveAllRequests`,
        body,
        { headers: this.request_headers() }
      )
        .then((res) => {
          this.active = true;
          this.message = res.data.message;
          this.getTableData(1);
        })
        .catch((err) => {
          this.active = true;
          let error = { err };
          this.message = error.err.response.data.message;
        });
    },

    selectedValue(itemSelected) {
      if (itemSelected.role === 0) {
        this.getAgentRequest(itemSelected);
      }
    },

    tickets(item) {
      this.$router.push({ name: "tickets", params: { id: item.id } });
    },

    setTblData(res) {
      this.$store.state.tableData.push({
        id: res.id,
        isSelected: false,
        "رقم الطلب": res.id,
        "تاريخ الطلب": res.created_at ? formatDate(res.created_at) : "",
        "نوع الطلب": res.type || "لا_يوجد",
        "استشاري المبيعات": res.user?.name,
        عميل: res.customer?.name,
        الجوال: res.customer?.mobile,
        "حالة الطلب": res.status,
        "مصدر المعامله": SourceValue(res.source),
        "تصنيف الاستشاري": res.class_id_agent_text,
        agent_notes: res.agent_notes,
        quality_notes: res.quality_notes,
        "تاريخ الاستشاري": res.agent_date ? formatDate(res.agent_date) : "",
        "هل تم استلامه من قبل الجوده":
          (res.class_id_quality != null || res.quacomment != null) ? "نعم" :  "لا",
          // res.user?.allow_recived && res.user?.allow_recived === 0 ? "لا" : "نعم",
        "تم التحديث في": res.updated_at ? formatDate(res.updated_at) : "",
        العمليات: res.sent_basket?.count,
      });
    },

    getTableData(count) {
      this.$store.state.tableData = [];
      this.loading = true;
      Axios.get(
        `https://appproduct.alwsata.com.sa/api/v2/employee/Requests?type=latest&page=${count}&per_page=${this.$store.state.counterOfShow}`,
        { headers: this.request_headers() }
      ).then((res) => {
        let response = res.data.payload.data.map((item) => {
          this.setTblData(item);
        });
        this.loading = false;
      });
    },

    handlePageNumber(val) {
      if (this.$store.state.isSearch === false) {
        this.getAllRequests(val);
      } else {
        this.searchRequest(val);
      }
    },

    searchRequest(page, type) {
      this.loading = true;

      if (type === "table") {
        this.$store.state.tableData = [];
      }

      if (page > 1 || type === "table") {
        this.$store.state.bodySearch.delete("page");
        this.$store.state.bodySearch.append("page", page);

        let url = `https://appproduct.alwsata.com.sa/api/v2/employee/Requests/search?type=latest`;
        Axios.post(url, this.$store.state.bodySearch, {
          headers: this.request_headers(),
        }).then((res) => {
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.searched,
          };
          let response = res.data.payload.data.map((item) => {
            if (item.length === 0) {
              this.loading = false;
            }
            this.setTblData(item);
            item["starChecked"] = false;
            return item;
          });

          // this.allData.push(...response);
          // this.removeDublicate(this.allData);

          this.$store.state.latestSearch.push(...response);
          this.removeDublicate(this.$store.state.latestSearch);
          this.loading = false;
        });
      }
    },

    removeDublicate(array) {
      let uniqueArray = array.filter((item, index) => {
        return (
          index ===
          array.findIndex((obj) => {
            return obj.id === item.id; // Compare based on the 'id' property
          })
        );
      });
      this.$store.state.latestData = uniqueArray;
    },

    getAllRequests(page) {
      if (
        this.$store.state.latestData.length === 0 ||
        this.copySupportRequests.length === 0
      ) {
        this.$store.state.latestLoading = true;
      }
      this.loadMoreData = true;
      Axios.get(
        `https://appproduct.alwsata.com.sa/api/v2/employee/Requests?type=latest&page=${page}&per_page=${this.$store.state.counterOfShow}`,
        { headers: this.request_headers() }
      )
        .then((res) => {
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_latest,
          };
          let response = res.data.payload.data.map((item) => {
            this.setTblData(item);
            item["starChecked"] = false;
            return item;
          });

          this.allGetData.push(...response);
          this.removeDublicate(this.allGetData);
          this.copySupportRequests.push(...response);
        })
        .catch((err) => {
          this.$store.state.latestLoading = false;
          this.loadMoreData = false;
        })
        .finally(() => {
          this.loadMoreData = false;
          this.$store.state.latestLoading = false;
        });
    },

    handleChangeInput(row) {
      row["selected"] = !row["selected"];
      let findRow = this.selectedRows.findIndex((item) => item["id"] === row["id"]);
      if (findRow !== -1) {
        this.selectedRows.splice(findRow, 1);
      } else {
        this.selectedRows.push(row);
      }
    },
  },
  created() {
    // this.$store.state.allRequests = [];
    this.$store.state.views = "cards";
    this.$store.state.tableData = [];
    this.$store.state.back = false;
    this.$store.state.bodySearch = new FormData();
    this.getAllRequests(1);
    this.$store.state.startElement = [];
  },
};
</script>

<style>
.noData {
  display: flex;
  justify-content: center;
  font-size: 22px;
  color: #3f0e40;
  margin-top: 70px;
  font-weight: bold;
}
</style>
