<template>
  <div style="box-shadow: 0 0 0 0 !important" class="adminGraph">
    <v-row justify="center" class="mt-5 graphContent" style="margin: 0px">
      <DailyReportFilter @handleFilter="handleFilter" />
    </v-row>
    <div v-if="!getDataDone">
      <SliderGroup
          :options="this.options"
          @removeRow="removeRow($event)"
          style="margin-bottom: 30px"
      />
      <table-component
        itemKey="id"
        :headers="tableHeaders"
        :formBody="body"
        :pagination="this.$store.state.pagination"
        :limit="10"
        :items="tableData"
        :loading="loading"
        v-model="page"
      >
        <template v-slot:[`item.التحكم`]="{ item }">
          <a :href="item.التحكم" style="text-decoration: none">
            <img src="@/assets/document-download-icon.svg" width="20px" height="30px" />
          </a>
        </template>
      </table-component>
    </div>
    <div v-else class="loader">
      <img src="../../../assets/Comp-2.gif" />
    </div>
  </div>
</template>
<style>
.bg-primary {
  background-color: rgb(63, 14, 64) !important;
  color: #fff !important;
  border: 1px solid #ddd !important;
}
.bg-primary * {
  color: #fff !important;
}

.v-data-table__wrapper > table > thead > tr > th.dimensions {
  text-align: center;
}

.v-data-table-header th.height,
.v-data-table-header th.base,
.v-data-table-header th.volume {
  display: none;
}

.v-data-table tr td.dimensions {
  text-align: center;
}

.v-data-table tr td.height,
.v-data-table tr td.base,
.v-data-table tr td.volume {
  display: none;
}
</style>
<script>
import DailyReportFilter from "./DailyReportFilter.vue";
import Axios from "axios";
import SliderGroup from "@/components/Charts/SliderGroup";

export default {
  components: {
    DailyReportFilter,
    SliderGroup
  },

  data() {
    return {
      chartData: [],
      tableData: [],
      options: [],
      showArray: [],
      filterObj: {},
      body: {},
      page: 1,
      getDataDone: false,
      loading: false,

      tableHeaders: [
        { text: "الاستشاري", value: "الاستشاري", align: "center" , width: "170px"},
        { text: "المستقبلة", value: "المستقبلة", align: "center" },
        { text: "قبول أولي", value: "قبول أولي", align: "center" },
        { text: "المتابعه", value: "المتابعه", align: "center" },
        { text: "%المتابعه", value: "%المتابعه", align: "center" },
        { text: "مرفوعه مبيعات", value: "مرفوعه مبيعات", align: "center" },
        { text: "مرفوضه مبيعات", value: "مرفوضه مبيعات", align: "center" },
        { text: "%مبيعات", value: "%مبيعات", align: "center" },
        { text: "مرفوعه تمويل", value: "مرفوعه تمويل", align: "center" },
        { text: "مرفوضة تمويل", value: "مرفوضة تمويل", align: "center" },
        { text: "قائم تمويل", value: "قائم تمويل", align: "center" },
        { text: "%تمويل", value: "%تمويل", align: "center" },
        { text: "مرفوعه رهن", value: "مرفوعه رهن", align: "center" },
        { text: "مرفوضة رهن", value: "مرفوضة رهن", align: "center" },
        { text: "قائم رهن", value: "قائم رهن", align: "center" },
        { text: "%رهن", value: "%رهن", align: "center" },
        { text: "مفرغة", value: "مفرغة", align: "center" },
        { text: "%مفرغة", value: "%مفرغة", align: "center" },
        // { text: "التحكم", value: "التحكم" },

        // { text: "التحكم", value: "التحكم" },
      ],
    };
  },

  watch: {
    page(val) {
      this.getData(val, "loading");
    },
  },

  methods: {
    removeRow(headerValue) {
      let findIndex = this.tableHeaders.findIndex((item) => item.text === headerValue);

      if (findIndex !== -1) {
        this.tableHeaders.splice(findIndex, 1);
        this.showArray.splice(findIndex, 0, {
          id: findIndex,
          value: headerValue,
        });
      } else {
        let foundInsertedItem = this.options.findIndex((item) => item === headerValue);

        this.showArray.sort((a, b) => a.id - b.id);
        this.showArray.map((res, index) => {
          if (res.value === headerValue) {
            this.tableHeaders.splice(foundInsertedItem - (index - 1), 0, {
              text: headerValue,
              value: headerValue,
            });
            this.showArray.splice(index, 1);
          }
        });
      }
    },

    handleFilter(val) {
      this.body = new FormData();

      if (val.startDate) {
        this.body.append("start_date", val.startDate);
      }
      if (val.endDate) {
        this.body.append("end_date", val.endDate);
      }
      if (val.status == undefined){
        val.status = "استشاري غير مؤرشف"; // default
        this.body.append("status", 1);
      }

      if (val.status === "استشاري غير مؤرشف") {
        this.body.append("status", 1);
      }

      if (val.status === "استشاري مؤرشف") {
        this.body.append("status", 0);
      }

      if (val.range) {
        this.body.append("range", val.range);
      }

      if (val.managerId.length) {
        const managerProperties = {};

        val.managerId.forEach((item, index) => {
          this.body.append([`manager_id[${index}]`], item.id);
        });
      }

      if (val.agentId.length) {
        const managerProperties = {};

        val.agentId.forEach((item, index) => {
          this.body.append([`adviser_id[${index}]`], item.id);
        });
      }

      if (val.dayNumber) {
        this.body.append("range", val.dayNumber);
      }

      this.getData(1, "getDataDone");
    },

    getData(page, loader) {
      if (loader === "getDataDone") {
        this.getDataDone = true;
      } else if (loader === "loading") {
        this.loading = true;
      }
      this.options = [];
      this.tableData = [];
      /*if (this.status == undefined){
        this.status = "استشاري غير مؤرشف"; // default
        this.body.append("status", 1);
      }*/
      let url = `${this.$store.state.url}/employee/admin/getProductivity?page=${page}`;
      Axios.post(url, this.body, { headers: this.request_headers() }).then((res) => {
        this.setTableData(res.data.payload.data);
        this.setOptions();
        this.chartData = res.data.payload;
        this.$store.state.pagination = {
          limit: 3,
          count: res.data.payload.total_agents,
        };
        this.loading = false;
        this.getDataDone = false;
      });
    },

    setOptions() {
      this.tableHeaders.map((item, index) => {
        if (item.text !== "الاستشاري" && item.text !== "التحكم") {
          this.options.push(item.text);


        }
      });
    },

    setTableData(data) {
      data.map((res) => {
        this.tableData.push({
          "الاستشاري": res.name,
          "المستقبلة": res.total_received_request?.count,
          "قبول أولي": res.followed_basket_possible_to_sell?.count,
          "المتابعه": res.possible_to_sell_to_customer_count?.count,
          "%المتابعه": res.followed_basket_possible_to_sell?.percentage,
          "مرفوعه مبيعات": res.sent_basket?.count,
          "مرفوضه مبيعات": res.sent_basket_reject?.count,
          "%مبيعات": res.sent_basket?.percentage,

          "مرفوعه تمويل": res.sent_basket_funding?.count,
          "مرفوضة تمويل": res.sent_basket_funding_reject?.count,
          "قائم تمويل": res.waiting_fm_status?.count,
          "%تمويل": res.waiting_fm_status?.percentage,

          "مرفوعه رهن": res.sent_basket_mortgage?.count,
          "مرفوضة رهن": res.sent_basket_mortgage_reject?.count,
          "قائم رهن": res.waiting_mm_status?.count,
          "%رهن": res.waiting_mm_status?.percentage,
          "مفرغة": res.completed_request?.count,
          "%مفرغة": res.completed_request?.percentage,
        });
      });
    },
  },

  created() {

    this.getData(1, "getDataDone");
    this.$store.state.pagination = {};
  },
};
</script>
