<template>
  <v-row style="margin: 20px">
    <v-card style="width: 100%">
      <v-card-title style="display: flex; justify-content: space-between">
        <span> التذاكر الخاصة بالطلب</span>
        <v-icon color="#3F0E40" @click="$store.state.openTasksRequestSection = false"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text>
        <div v-if="!loading">
          <table-component
            :showSelect="false"
            itemKey="id"
            @refresh="getTasksOfRequest"
            :headers="headers"
            :limit="10"
            :items="items"
            :loading="loading"
          >
            <!-- eslint-disable -->
            <template v-slot:[`item.status`]="{ item }">
              <v-badge
                style="position: unset !important"
                :color="item.color"
                :content="item.status"
                inline
              ></v-badge>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="#40BE72" small depressed dark v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="openTaskConversation(item)">
                    <v-list-item-icon class="ml-2"
                      ><v-icon>mdi-eye-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>فتح</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

            <template slot="item.created_at" slot-scope="props">
              {{ props.item.created_at.split(".")[0].split("T").join(" | ") }}
            </template>

            <template slot="item.status" slot-scope="props">
              {{ props.item.status }}
            </template>

            <template slot="item.sent_name" slot-scope="props">
              {{ props.item.user.name }}
            </template>

            <template slot="item.recieved_name" slot-scope="props">
              {{ props.item.receive.name }}
            </template>
          </table-component>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" variant="text" @click="addNewTaskForRequest">
          انشاء تذكرة للطلب
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>
import { axiosInstance } from "@/axios/config";
export default {
  data() {
    return {
      headers: [
        {
          text: "تاريخ التذكرة",
          value: "created_at",
          width: "10%",
        },
        {
          text: "حالة التذكرة",
          value: "status",
          width: "10%",
        },
        {
          text: "مرسل التذكرة",
          value: "sent_name",
          width: "10%",
        },
        {
          text: "مستلم التذكرة",
          value: "recieved_name",
          width: "10%",
        },

        { text: "العمليات", value: "action", align: "center", width: "3%" },
      ],
      items: [],
      loading: false,
    };
  },
  created() {
    if (this.$store.state.tasksRequestId !== null) {
      this.getTasksOfRequest();
    }
  },
  methods: {
    getTasksOfRequest() {
      if (this.$store.state.tasksRequestId) {
        this.loading = true;
        axiosInstance
          .get(`/agent/Tasks/${this.$store.state.tasksRequestId}`)
          .then((response) => {
            this.items = response.data.payload;
            this.loading = false;
          })
          .catch((err) => {
            let error = { err };
            this.$store.state.openTasksRequestSection = false;
            this.showPopup("error", error.err.response.data.message);
            this.loading = false;
          });
      }
    },

    openTaskConversation(item) {
      this.$store.state.tasksDetailsRequestId = item.id;
      this.$store.state.openTasksDetailsRequestSection = true;
    },

    addNewTaskForRequest() {
      this.$store.state.openAddTaskForRequest = true;
    },
  },
  watch: {
    "$store.state.tasksRequestId": {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.getTasksOfRequest();
        }
      },
    },
  },
};
</script>

<style lang="scss">
.log-page {
  .v-timeline-item__divider {
    z-index: 1;
  }

  .theme--light.v-timeline::before {
    border: 2px solid #3f0e40;
    margin-top: 23px;
    height: calc(100% - 63px);
    opacity: 0.5;
  }

  .logDate {
    position: absolute;
    right: -45px;
    color: #444b5a;
    font-size: 12px;
  }

  .info-dad {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .v-application--is-rtl .v-timeline--dense:not(.v-timeline--reverse)::before {
    //background-color: #4516466c !important;
    z-index: 1;
    width: 3px !important;
    margin-right: 1px;
  }

  .theme--light.v-timeline .v-timeline-item__dot {
    //border: 2px solid #3F0E40 !important;
    margin-top: 18px;

    & > div {
      background-color: white !important;
    }
  }
}

@media (max-width: 578px) {
  .log-page {
    .v-alert {
      width: 110% !important;
      transform: translateX(28px) !important;
    }

    .v-alert__content {
      span:not(:first-child) {
        font-size: 10px !important;
      }

      span:first-child {
        font-size: 13px !important;
      }
    }

    .v-timeline-item__dot {
      height: 25px !important;
      width: 25px !important;
      z-index: 1;
    }

    .v-timeline-item__inner-dot {
      display: inline-block !important;
    }
  }
}
</style>
