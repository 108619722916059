<template>
  <div>
    <HeaderIcons
      :selectedItems="selectedItems"
      @reloadingData="getAllRequests"
      type="moveAllRequests"
    />

    <div v-if="!this.$store.state.featureLoading" style="margin-top: 40px">
      <div v-if="$store.state.views === 'cards'">
        <div v-if="this.$store.state.featureData.length > 0" style="margin-top: 40px">
          <RequestsCard
            :CardData="this.$store.state.featureData"
            @back="handleBack"
            @pageCount="handlePageNumber"
          />
          <div>
            <LoadingMore v-if="loadMoreData" style="margin: 40px" />
          </div>
        </div>
        <div v-else>
          <div>
            <button
              @click="handleBack"
              style="
                width: 70px;
                height: 40px;
                border-radius: 5px;
                margin: 50px;
                color: white;
                background-color: #441545;
                direction: ltr;
              "
            >
              رجوع
            </button>
          </div>
          <div class="noData">لا يوجد بيانات</div>
        </div>
      </div>

      <div v-else-if="$store.state.views === 'table'" class="table__view">
        <table-component
          :showSelect="true"
          itemKey="id"
          :selectedRows.sync="selected_requests"
          :headers="headers"
          :pagination="this.$store.state.pagination"
          :limit="$store.state.counterOfShow"
          :items="this.$store.state.tableData"
          :loading="loading"
          @refresh="getTableData"
          v-model="page"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="#3f0e40" small depressed dark v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <FeaturedSingleProcess
                  :item="item.id"
                  @getUsersNames="getUsersNames"
                  :selectedUser="selectedUser"
                />
              </v-list>
            </v-menu>
          </template>

          <template slot="item.quality_notes" slot-scope="props">
            <span v-if="!props.item.quality_notes"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.quality_notes.length > 7
                    ? props.item.quality_notes.substring(0, 7) + "..."
                    : props.item.quality_notes
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.quality_notes }}</div>
            </v-tooltip>
          </template>

          <template slot="item.agent_notes" slot-scope="props">
            <span v-if="!props.item.agent_notes"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.agent_notes.length > 7
                    ? props.item.agent_notes.substring(0, 7) + "..."
                    : props.item.agent_notes
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.agent_notes }}</div>
            </v-tooltip>
          </template>
        </table-component>
        <OrderRequestSection
          v-if="this.$store.state.openOrderRequestSection"
          style="margin-top: 50px"
        />
      </div>

      <div v-else-if="$store.state.views === 'default'">
        <div v-if="this.$store.state.featureData.length > 0" style="margin-top: 40px">
          <DefaultView :CardData="this.$store.state.featureData" />
          <div>
            <LoadingMore v-if="loadMoreData" style="margin: 40px" />
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
      "
      v-else
    >
      <LoadingMore />
    </div>

    <AdminDialog @selectedValue="selectedValue" :items="allStatus" :label="label" />
    <FilterDialogInAdmin pageName="stared" />
    <CallsDialog />

    <OrderRequestDialog />
  </div>
</template>

<script>
import CallsDialog from "@/components/Charts/CallsDialog.vue";
import { axiosInstance } from "@/axios/config";
import RequestsCard from "@/components/Charts/RequestsCard.vue";
import LoadingMore from "@/components/Charts/LoadingMore.vue";
import HeaderIcons from "@/components/Charts/HeaderIcons.vue";
import AdminDialog from "@/components/AdminComponent/AdminDialog.vue";
import FilterDialogInAdmin from "@/components/Charts/FilterDialogInAdmin.vue";
import { formatDate } from "@/mixins/formatDate";
import OrderRequestDialog from "@/components/AdminComponent/OrderRequestDialog.vue";
import OrderRequestSection from "@/components/AdminComponent/OrderRequestSection.vue";
import FeaturedSingleProcess from "./FeaturedSingleProcess.vue";
import { SourceValue } from "@/mixins/sourceValue";
import DefaultView from "@/components/Base/DefaultView.vue";

export default {
  name: "AdminSupport",
  components: {
    OrderRequestDialog,
    FilterDialogInAdmin,
    FeaturedSingleProcess,
    OrderRequestSection,
    AdminDialog,
    RequestsCard,
    LoadingMore,
    HeaderIcons,
    CallsDialog,
    DefaultView,
  },

  data() {
    return {
      allGetData: [],
      selected_requests: [],
      allRequests: [],
      lodingToGetData: false,

      pagination: {
        limit: 3,
        count: this.$store.state.searchedCount,
      },
      page: 1,
      selectedRows: [],
      showSelect: { default: false },
      copySupportRequests: [],
      loadMoreData: false,
      count: null,
      loading: false,
      selectId: null,
      selectedItems: [],
      selectedUser: [],
      allStatus: [],
      label: "",
      countPage: 1,
      headers: [
        { align: "center", value: "data-table-select" },
        { text: "تاريخ الطلب", value: "تاريخ الطلب", width: "107px", align: "center" },
        { text: "نوع الطلب", value: "نوع الطلب", align: "center" },
        {
          text: "استشاري المبيعات",
          value: "استشاري المبيعات",
          align: "center",
        },
        { text: "عميل", value: "عميل", width: "120px", align: "center" },
        { text: "الجوال", value: "الجوال", align: "center" },
        { text: "حالة الطلب", value: "حالة الطلب", width: "120px", align: "center" },
        {
          text: "مصدر المعامله",
          value: "مصدر المعامله",
          width: "120px",
          align: "center",
        },
        {
          text: "تصنيف الاستشاري",
          value: "تصنيف الاستشاري",
          align: "center",
        },
        {
          text: "ملاحظه الاستشاري ",
          value: "agent_notes",
          align: "center",
        },
        {
          text: "ملاحظة الجوده",
          value: "quality_notes",
          align: "center",
        },
        {
          text: "تاريخ الاستشاري",
          value: "تاريخ الاستشاري",
          width: "107px",
          align: "center",
        },
        {
          text: "استلام الجودة",
          value: "هل تم استلامه من قبل الجوده",
          align: "center",
        },
        {
          text: "تم التحديث ",
          value: "تم التحديث في",
          width: "107px",
          align: "center",
        },
        { text: "", value: "action", align: "center" },
      ],
    };
  },

  beforeDestroy() {
    this.$store.state.openOrderRequestSection = false;
  },

  watch: {
    page(val) {
      if (this.$store.state.isSearch === false) {
        this.getTableData(val);
      } else {
        this.searchRequest(val, "table");
      }
    },
    selected_requests(val) {
      this.selectedItems = val;
      this.$emit("requests", val);
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval != oldvalue) {
          this.getTableData(this.page);
        }
      },
    },
  },

  methods: {
    selectedValue(user) {
      this.selectedUser = user;
    },

    getUsersNames(users, label) {
      this.allStatus = users;
      this.label = label;
    },
    handleBack() {
      this.$store.state.featureData = [];
      this.$store.state.tableData = [];
      this.$store.state.featureLoading = true;
      this.$store.state.isSearch = false;
      this.$store.state.back = false;
      this.getAllRequests(1);
    },

    setTblData(res) {
      this.$store.state.tableData.push({
        id: res.id,
        isSelected: false,
        "تاريخ الطلب": res.created_at ? formatDate(res.created_at) : "",
        "نوع الطلب": res.type || "لا يوجد نوع طلب",
        "استشاري المبيعات": res.user?.name,
        عميل: res.customer.name,
        الجوال: res.customer.mobile,
        "حالة الطلب": res.status,
        "مصدر المعامله": SourceValue(res.source),
        "تصنيف الاستشاري": res.class_id_agent_text,
        agent_notes: res.agent_notes,
        quality_notes: res.quality_notes,
        "تاريخ الاستشاري": res.agent_date ? formatDate(res.agent_date) : "",
        "هل تم استلامه من قبل الجوده":
          (res.class_id_quality != null || res.quacomment != null) ? "نعم" :  "لا",
          // res.user?.allow_recived && res.user?.allow_recived === 0 ? "لا" : "نعم",
        "تم التحديث في": res.updated_at ? formatDate(res.updated_at) : "",
        العمليات: res.sent_basket?.count,
      });
    },

    getTableData(count) {
      this.$store.state.tableData = [];
      this.loading = true;
      axiosInstance
        .get(
          `/v2/employee/Requests?type=stared&page=${count}&per_page=${this.$store.state.counterOfShow}`,
          { headers: this.request_headers() }
        )
        .then((res) => {
          let response = res.data.payload.data.map((item) => {
            this.setTblData(item);
          });
          this.loading = false;
        });
    },

    handlePageNumber(val) {
      if (this.$store.state.isSearch === false) {
        this.getAllRequests(val);
      } else {
        this.searchRequest(val);
      }
    },

    searchRequest(page, type) {
      this.loading = true;

      if (type === "table") {
        this.$store.state.tableData = [];
      }
      if (page > 1 || type === "table") {
        this.$store.state.bodySearch.delete("page");
        this.$store.state.bodySearch.append("page", page);
        let url = `/v2/employee/Requests/search?type=stared`;
        axiosInstance
          .post(url, this.$store.state.bodySearch, { headers: this.request_headers() })
          .then((res) => {
            this.$store.state.pagination = {
              limit: 3,
              count: res.data.payload.searched,
            };
            let response = res.data.payload.data.map((item) => {
              if (item.length === 0) {
                this.loading = false;
              }
              this.setTblData(item);
              item["starChecked"] = false;
              return item;
            });

            this.$store.state.featureSearch.push(...response);
            this.removeDublicate(this.$store.state.featureSearch);
            this.loading = false;
          });
      }
    },

    removeDublicate(array) {
      let uniqueArray = array.filter((item, index) => {
        return (
          index ===
          array.findIndex((obj) => {
            return obj.id === item.id; // Compare based on the 'id' property
          })
        );
      });
      this.$store.state.featureData = uniqueArray;
    },

    getAllRequests(page) {
      if (
        this.$store.state.featureData.length === 0 ||
        this.copySupportRequests.length === 0
      ) {
        this.$store.state.featureLoading = true;
      }
      this.loadMoreData = true;
      axiosInstance
        .get(
          `/v2/employee/Requests?type=stared&page=${page}&per_page=${this.$store.state.counterOfShow}`,
          { headers: this.request_headers() }
        )
        .then((res) => {
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_stared,
          };
          let response = res.data.payload.data.map((item) => {
            this.setTblData(item);
            item["starChecked"] = false;
            return item;
          });

          this.allGetData.push(...response);
          this.removeDublicate(this.allGetData);
          this.copySupportRequests.push(...response);
        })
        .catch((err) => {
          this.$store.state.featureLoading = false;
          this.loadMoreData = false;
        })
        .finally(() => {
          this.loadMoreData = false;
          this.$store.state.featureLoading = false;
        });
    },

    handleChangeInput(row) {
      row["selected"] = !row["selected"];
      let findRow = this.selectedRows.findIndex((item) => item["id"] === row["id"]);
      if (findRow !== -1) {
        this.selectedRows.splice(findRow, 1);
      } else {
        this.selectedRows.push(row);
      }
    },
  },
  created() {
    this.$store.state.tableData = [];
    this.$store.state.back = false;
    this.$store.state.isSearch = false;
    this.$store.state.bodySearch = new FormData();
    this.getAllRequests();
    this.$store.state.startElement = [];
  },
};
</script>

<style>
.noData {
  display: flex;
  justify-content: center;
  font-size: 22px;
  color: #3f0e40;
  margin-top: 70px;
  font-weight: bold;
}

.table__view .v-btn:not(.v-btn--round).v-size--small {
  min-width: auto !important;
}
</style>
