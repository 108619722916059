<template>
  <div class="fund-history">
    <v-progress-circular
      :active="loading"
      :indeterminate="loading"
      color="primary"
      :style="`position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
        height: 50px;${loading ? 'z-index:1' : 'z-index:-100'}`"
    ></v-progress-circular>
    <div
      v-if="fundData.length <= 0"
      :style="` position: fixed;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 0;
        ${fundData.length > 0 ? 'z-index:-100' : 'z-index:1'}`"
    >
      <p style="font-size: 30px; letter-spacing: 2px; font-weight: 500">
        {{ resultText }}
      </p>
    </div>
    <div
      v-for="(data, i) in fundData"
      :key="i"
      class="mb-4"
      style="z-index: 2; position: relative"
    >
      <h3
        style="
          background: #f7f7f7;
          text-align: center;
          padding: 18px 0;
          border: 2px solid rgb(225 225 225);
          margin-bottom: 0;
          font-size: 17px;
          font-weight: 500;
          color: black !important;
          opacity: 0.8;
        "
      >
        {{ user_name }} -
      </h3>
      <div
        style="
          height: 53vh;
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-wrap: wrap;
          border-right: 3px solid rgb(225 225 225);
          border-left: 3px solid rgb(225 225 225);
          padding: 20px 0 0;
          background: #ffffff;
        "
        class="content-dad"
      >
        <p><strong>البرنامج: </strong> {{ data.program_name }}</p>
        <p><strong>الرتبة العسكرية: </strong>{{ data.military_rank }}</p>
        <p>
          <strong>الضمانات: </strong
          >{{ data.guarantees == 0 ? "لا" : data.guarantees == 1 ? "نعم" : "" }}
        </p>
        <p>
          <strong>مدة التمويل بالسنوات (شخصي): </strong
          >{{
            data.personal_funding_months
              ? (data.personal_funding_months / 12).toFixed(1)
              : ""
          }}
        </p>
        <p>
          <strong>حالة العقار: </strong
          >{{
            data.property_completed == 0
              ? "غير مكتمل"
              : data.property_completed == 1
              ? "مكتمل"
              : ""
          }}
        </p>
        <p>
          <strong>نسبة الدفعة الاولي: </strong>{{ data.first_batch_percentage }}
        </p>
        <p><strong>خصم العميل: </strong>{{ data.discount }}</p>
        <p><strong>جهة العمل (متضامن): </strong>{{ data.joint_work }}</p>
        <p>
          <strong>اضافة قسم الدعم الي الراتب (متضامن): </strong
          >{{
            data.add_support_installment_to_salary == 0
              ? "لا"
              : data.add_support_installment_to_salary == 1
              ? "نعم"
              : ""
          }}
        </p>
        <p><strong>تاريخ الميلاد: </strong>{{ data.birth_hijri }}</p>
        <p><strong>جهة نزول الراتب: </strong>{{ data.salary_bank_id }}</p>
        <p><strong>الراتب الاساسي: </strong>{{ data.basic_salary }}</p>
        <p><strong>نسبة الاستقطاع: </strong>{{ data.salary_deduction }}</p>
        <p>
          <strong>المسكن: </strong
          >{{
            data.residence_type == 0
              ? "لا"
              : data.residence_type == 1
              ? "نعم"
              : ""
          }}
        </p>
        <p>
          <strong>توفير الدفعة الاولي: </strong
          >{{
            data.provide_first_batch == 0
              ? "لا"
              : data.provide_first_batch == 1
              ? "نعم"
              : ""
          }}
        </p>
        <p>
          <strong>يوجد متضامن: </strong
          >{{ data.have_joint == 0 ? "لا" : data.have_joint == 1 ? "نعم" : "" }}
        </p>
        <p>
          <strong>الرتبة العسكرية (متضامن): </strong
          >{{ data.joint_military_rank }}
        </p>
        <p>
          <strong>مبلغ السداد المبكر (متضامن): </strong
          >{{ data.joint_early_repayment }}
        </p>
        <p><strong>صافي الراتب: </strong>{{ data.salary }}</p>
        <p>
          <strong>الدعم السكني: </strong
          >{{
            data.residential_support == 0
              ? "لا"
              : data.residential_support == 1
              ? "نعم"
              : ""
          }}
        </p>
        <p>
          <strong>بدون تحويل الراتب: </strong
          >{{
            data.without_transfer_salary == 0
              ? "لا"
              : data.without_transfer_salary == 1
              ? "نعم"
              : ""
          }}
        </p>
        <p>
          <strong>مدة التمويل بالسنوات: </strong
          >{{
            data.funding_months ? (data.funding_months / 12).toFixed(1) : ""
          }}
        </p>
        <p>
          <strong>نسبة ارباح الدفعة الاولي (%): </strong
          >{{ data.first_batch_profit }}
        </p>
        <p>
          <strong>تاريخ الميلاد (متضامن): </strong>{{ data.joint_birth_hijri }}
        </p>
        <p>
          <strong>جهة نزول الراتب (متضامن)]: </strong
          >{{ data.joint_salary_bank_id }}
        </p>
        <p><strong>جهة العمل: </strong>{{ data.work }}</p>
        <p>
          <strong>اضافة قسم الدعم الي الراتب: </strong
          >{{
            data.add_support_installment_to_salary == 0
              ? "لا"
              : data.add_support_installment_to_salary == 0
              ? "نعم"
              : ""
          }}
        </p>
        <p>
          <strong>نسبة استقطاع صافي الراتب: </strong
          >{{ data.personal_salary_deduction }}
        </p>
        <p><strong>قيمة العقار: </strong>{{ data.property_amount }}</p>
        <p><strong>مبلغ السداد المبكر: </strong>{{ data.early_repayment }}</p>
        <p><strong>رسوم ادارية: </strong>{{ data.fees }}</p>
        <p><strong>صافي الراتب (متضامن): </strong>{{ data.joint_salary }}</p>
        <p>
          <strong>الدعم السكني (متضامن): </strong
          >{{
            data.joint_residential_support == 0
              ? "لا"
              : data.joint_residential_support == 1
              ? "نعم"
              : ""
          }}
        </p>
      </div>
      <h3
        style="
          background: #f7f7f7;
          text-align: center;
          padding: 18px 0;
          border: 2px solid rgb(225 225 225);
          margin-bottom: 0;
          font-size: 17px;
          font-weight: 500;
          color: black !important;
        "
      >
        {{
          data.days
            ? "منذ " +
              " " +
              data.days +
              " " +
              "ايام" +
              "," +
              " " +
              data.hours +
              " " +
              "ساعات" +
              "."
            : "منذ " + " " + data.hours + " " + "ساعات" + "."
        }}
      </h3>
    </div>
  </div>
</template>

<script>
import Axios from "axios";

export default {
  name: "FundHistory",
  data() {
    return {
      fundData: [],
      loading: false,
      resultText: "",
    };
  },
  computed: {
    user_name() {
      return JSON.parse(
        document.cookie
          .split(";")
          .map((coki) => coki.split("="))
          .reduce(
            (acc, [key, val]) => ({
              ...acc,
              [key.trim()]: decodeURIComponent(val),
            }),
            {}
          )["wsataJwt"]
      )["agent_name"];
    },
  },
  methods: {
    async getHistoryData() {
      this.loading = true;
      const id = this.$route.params.id;
      await Axios.get(
        `https://appproduct.alwsata.com.sa/api/agent/Request/CalculatorHistory/${id}`,
        {
          headers: this.request_headers(),
        }
      )
        .then((res) => {
          if (res.data.payload.length <= 0) {
            this.resultText = "لاتوجد بيانات متاحة";
          } else {
            res.data.payload.forEach((el) => {
              const created_at = new Date(el.created_at);
              const dateNow = new Date();
              const differ = dateNow - created_at;
              const days = Math.floor(differ / 1000 / 60 / 60 / 24);
              const hours = Math.floor((differ / 1000 / 60 / 60) % 24);
              if (days > 0) {
                el.days = days;
              }
              el.hours = hours;
              this.$store.state.military_ranks.forEach((rank) => {
                if (el.military_rank == rank.id) {
                  el.military_rank = rank.value;
                }
                if (el.joint_military_rank == rank.id) {
                  el.joint_military_rank = rank.value;
                }
              });
            });
            this.fundData = res.data.payload;
          }
          this.loading = false;
        })
        .catch((err) => this.showPopup("error", err.response.message));
    },
  },
  async created() {
    this.$store.state.opened = -1
    await this.getHistoryData();
  },
};
</script>

<style lang="scss">
.fund-history {
  p {
    font-size: 14px;
    font-weight: bold;
    strong {
      color: #0f5b94;
      font-size: 16px;
    }
  }
}
@media (max-width: 991px) {
  .fund-history {
    .content-dad {
      height: unset !important;
    }
  }
}
</style>
