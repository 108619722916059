import QualitySentTickets from "@/components/Tickets/quality/SentTickets.vue";
import QualityRecievedTickets from "@/components/Tickets/quality/RecievedTickets.vue";
import QualityCompletedTickets from "@/components/Tickets/quality/CompletedTickets.vue";
import QualityTicketDetails from "@/components/Tickets/quality/TicketDetails.vue";
import QualityTheCalender from "@/views/quality/TheCalendar.vue";
import QualityTheNotifc from "@/views/quality/TheNotifc.vue";
import QRequestDetails from "@/components/QualityRequestDetails/RequestDetails.vue";
import QAgentData from "@/components/QualityRequestDetails/AgentData.vue";
import QEstateData from "@/components/QualityRequestDetails/EstateData.vue";
import QFundData from "@/components/QualityRequestDetails/FundData.vue";
import QFundCalc from "@/components/QualityRequestDetails/FundCalc.vue";
import QRequestAttachments from "@/components/QualityRequestDetails/RequestAttachments.vue";
import QPrePayment from "@/components/QualityRequestDetails/PrePayment.vue";
import QRequestLog from "@/components/QualityRequestDetails/RequestLog.vue";
import QRequestTickets from "@/components/QualityRequestDetails/RequestTickets.vue";
import QRequestCalls from "@/components/QualityRequestDetails/QRequestCalls.vue";
import QualityAllRequests from "@/components/Requests/quality/AllRequests.vue";
import QualityRecievedRequests from "@/components/Requests/quality/RecievedRequests.vue";
import QualityFollowRequests from "@/components/Requests/quality/FollowRequests.vue";
import QualityArchievedRequests from "@/components/Requests/quality/ArchievedRequests.vue";
import QualityCompletedRequests from "@/components/Requests/quality/CompletedRequests.vue";
import QualityNeedToMoveRequests from "@/components/Requests/quality/NeedToMoveRequests.vue";
import QualitySearchCustomer from "@/views/quality/SearchCustomer";

export const qualityRouter = [
    {
        path: "/quality/tickets/sent",
        name: "quality_sent_tickets",
        component: QualitySentTickets,
        meta: {
            icon: "",
            listed: true,
            group: "التذاكر .",
            page_name: "التذاكر المرسله",
        },
    },
    {
        path: "/quality/tickets/recieved",
        name: "quality_recieved_tickets",
        component: QualityRecievedTickets,
        meta: {
            icon: "",
            listed: true,
            group: "التذاكر .",
            page_name: "التذاكر المستلمه",
        },
    },
    {
        path: "/quality/tickets/completed",
        name: "quality_completed_tickets",
        component: QualityCompletedTickets,
        meta: {
            icon: "",
            listed: true,
            group: "التذاكر .",
            page_name: "التذاكر المكتمله",
        },
    },
    {
        path: "/quality/tickets/:ticket",
        name: "quality_ticket_details",
        component: QualityTicketDetails,
        meta: { listed: false },
    },
    {
        path: "/quality/calender",
        name: "quality_TheCalender",
        component: QualityTheCalender,
        meta: {
            icon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="17.599" height="18.22" viewBox="0 0 17.599 18.22">
          <path id="reminder" d="M19.967,18.29a2.376,2.376,0,0,1-.7-1.278c0-.015,0-.029,0-.043V15.339A2.545,2.545,0,0,0,17.3,12.85V5.3a2.2,2.2,0,0,0-2.2-2.2H13.137V2.587a.44.44,0,0,0-.88,0V3.1H7.544V2.587a.44.44,0,0,0-.88,0V3.1H4.7A2.2,2.2,0,0,0,2.5,5.3v10.41a2.2,2.2,0,0,0,2.2,2.2h9.041a3.559,3.559,0,0,1-.311.377.44.44,0,0,0,.308.754h1.521a1.44,1.44,0,0,0,2.871,0h1.528a.44.44,0,0,0,.308-.754ZM4.7,3.984H6.664v.521a.44.44,0,1,0,.88,0V3.984h4.713v.521a.44.44,0,1,0,.88,0V3.984H15.1a1.32,1.32,0,0,1,1.32,1.32v.9H3.38V5.3A1.32,1.32,0,0,1,4.7,3.984Zm0,13.049a1.32,1.32,0,0,1-1.32-1.32V7.082H16.421V12.8a2.56,2.56,0,0,0-2.282,2.541v1.674c0,.015-.005.014-.005.021Zm12,2.453a.562.562,0,0,1-.543-.44h1.085a.561.561,0,0,1-.542.44Zm.973-1.32H14.643a2.332,2.332,0,0,0,.375-1.1.535.535,0,0,0,0-.054V15.339a1.68,1.68,0,1,1,3.359,0v1.729a2.335,2.335,0,0,0,.375,1.1Z" transform="translate(-2.5 -2.147)" fill="#fff"/>
        </svg>
      `,
            group: '',
            listed: true,
            page_name: "التذكيرات"
        },
    },
    {
        path: "/quality/notifications",
        name: "quality_TheNotifc",
        component: QualityTheNotifc,
        meta: {
            icon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="18.552" height="19" viewBox="0 0 20.552 21">
          <g id="message_1_" data-name="message (1)" transform="translate(1 1)">
            <g id="bell">
              <line id="Line_3" data-name="Line 3" x2="3" transform="translate(8 19)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              <path id="Path_2642" data-name="Path 2642" d="M22.552,19.179H4a3.044,3.044,0,0,0,1.687-2.732V11.59A7.59,7.59,0,0,1,13.276,4h0a7.59,7.59,0,0,1,7.59,7.59v4.857a3.044,3.044,0,0,0,1.687,2.732Z" transform="translate(-4 -4)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            </g>
          </g>
        </svg>
        `,
            listed: true,
            group: '',
            page_name: "الإشعارات",
        },
    },
    {
      path: "/quality/search-customer",
      name: "quality_SearchCustomer",
      component: QualitySearchCustomer,
      meta: {
        icon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="18.574" height="16.641" viewBox="0 0 18.574 16.641">
              <g id="Group_3168" data-name="Group 3168" transform="translate(0.5 0.5)">
                <path id="Path_30" data-name="Path 30" d="M14.228,591.566v-2.294a3.022,3.022,0,0,0-3.022-3.022H4.442a3.022,3.022,0,0,0-3.022,3.022v2.294" transform="translate(-1.42 -575.975)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                <circle id="Ellipse_14" data-name="Ellipse 14" cx="3.505" cy="3.505" r="3.505" transform="translate(2.899 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                <path id="Path_31" data-name="Path 31" d="M23.58,566.05a3.5,3.5,0,0,1,0,7.01" transform="translate(-12.308 -566.05)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                <path id="Path_32" data-name="Path 32" d="M30.47,585.3s3.23.727,2.747,5.8" transform="translate(-15.693 -575.508)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
              </g>
            </svg>
        `,
        listed: true,
        group: '',
        page_name: "البحث عن عميل",
      },
    },
    {
        path: "/quality/request/:id",
        name: "quality_request_details",
        component: QRequestDetails,
        meta: { icon: "", listed: false, page_name: "تفاصيل الطلب" },
        redirect: { name: "quality_agent_data" },
        children: [
            {
                path: "agent-data",
                name: "quality_agent_data",
                component: QAgentData,
                meta: { icon: "", listed: false },
            },
            {
                path: "real-estate-data",
                name: "quality_real_estate_data",
                component: QEstateData,
                meta: { icon: "", listed: false },
            },
            {
                path: "fund-data",
                name: "quality_fund_data",
                component: QFundData,
                meta: { icon: "", listed: false },
            },
            {
                path: "fund-calculator",
                name: "quality_fund_calc",
                component: QFundCalc,
                meta: { icon: "", listed: false },
            },
            {
                path: "attachments",
                name: "quality_attachments",
                component: QRequestAttachments,
                meta: { icon: "", listed: false },
            },
            {
                path: "prepayment",
                name: "quality_prepayment",
                component: QPrePayment,
                meta: { icon: "", listed: false },
            },
            {
                path: "logs",
                name: "quality_logs",
                component: QRequestLog,
                meta: { icon: "", listed: false },
            },
            {
                path: "tickets",
                name: "quality_tickets",
                component: QRequestTickets,
                meta: { icon: "", listed: false },
            },
            {
                path: "calls",
                name: "quality_calls",
                component: QRequestCalls,
                meta: { icon: "", listed: false },
            },
        ],
    },

    {
        path: "/quality/requests/all",
        name: "qualityAll",
        component: QualityAllRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات .",
            page_name: "جميع الطلبات",
            breadcrumb: "",
        },
    },
    {
        path: "/quality/requests/recieved",
        name: "quality_recieved",
        component: QualityRecievedRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات .",
            page_name: "الطلبات المستلمه",
        },
    },
    {
        path: "/quality/requests/follow",
        name: "quality_following",
        component: QualityFollowRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات .",
            page_name: "الطلبات المتابعه",
        },
    },
    {
        path: "/quality/requests/archived",
        name: "quality_archived",
        component: QualityArchievedRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات .",
            page_name: "الطلبات المؤرشفة",
        },
    },
    {
        path: "/quality/requests/completed",
        name: "quality_completed",
        component: QualityCompletedRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات .",
            page_name: "الطلبات المكتمله",
        },
    },
    {
        path: "/quality/requests/need_to_move",
        name: "quality_need_to_move",
        component: QualityNeedToMoveRequests,
        meta: {
            icon: "",
            listed: true,
            group: "الطلبات .",
            page_name: "الطلبات بحاجة الي التحويل",
        },
    },
]