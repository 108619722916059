<template>
  <div>
    <v-btn
      @click="dialog = true"
      fab
      class="mx-2 pa-0 genaralIcons"
      small
      style="background: #3f0e40; border-radius: 8px; left: 50px"
      :color="$vuetify.theme.themes.light.BtnBKColor"
      depressed
      width="40"
      height="40"
    >
      <v-icon color="white">mdi-magnify</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" persistent width="60%">
      <v-card style="padding: 20px">
        <v-card-title style="margin-bottom: 30px">البحث</v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <SelectInput
                @selectedValues="getSelectedStatus"
                :originalItems="requestStatus"
                label="حاله الطلب"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <SelectInput
                @selectedValues="getSelectedMission"
                :originalItems="missionStatus"
                label="حاله التذكرة"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <SelectInput
                @selectedValues="getSelectedAgentStatus"
                :originalItems="agentStatus"
                label="تصنيف استشاري المبيعات"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <SelectInput
                @selectedValues="getSelectedQualityStatus"
                :originalItems="qualityStatus"
                label="تصنيف الجودة"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="راتب العميل"
                v-model="filterObj.quality_customer_salary"
                outlined
                v-numericOnly
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                label="الهاتف"
                v-model="filterObj.quality_customer_mobile"
                outlined
                v-numericOnly
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <SelectInput
                @selectedValues="getSelectedCommunicationSource"
                :originalItems="communicationSource"
                label="مصدر المعاملة"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <SelectInput
                @selectedValues="getSelectedTypeRequest"
                :originalItems="typeRequest"
                label="نوع الطلب"
              />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <PickerInput label="تاريخ الطلب من" @dateValue="saveDateFrom" />
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <PickerInput label="تاريخ الطلب الي" @dateValue="saveDateTo" />
            </v-col>

            <v-col cols="12" sm="4" md="4" style="margin-top: 10px">
              <SelectInput
                @selectedValues="getSelectedWorkList"
                :originalItems="workSource"
                label="جهه العمل"
              />
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                :items="notes"
                label="ملاحظة استشاري المبيعات"
                v-model="filterObj.quality_request_agent_notes"
                outlined
                dense
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title
                      :id="attrs['aria-labelledby']"
                      v-text="item"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                :items="notes"
                label="ملاحظة الجودة"
                v-model="filterObj.quality_request_quality_notes"
                outlined
                dense
              >
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-title
                      :id="attrs['aria-labelledby']"
                      v-text="item"
                    ></v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
            الغاء
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="handleSearch"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { SearchIcon } from "@/assets/adminIcons/searchIcon";
import SelectInput from "@/components/Charts/SelectInput.vue";
import PickerInput from "@/components/Charts/PickerInput.vue";
import { axiosInstance } from "@/axios/config";
import { ref, onMounted, defineEmits } from "vue";
import { store } from "@/store";

const emit = defineEmits(["handleFilter"]);
const dialog = ref(false);
const filterObj = ref({
  quality_request_status: null,
  quality_task_status: null,
  quality_request_agent_classification: null,
  quality_request_quality_classification: null,
  quality_customer_salary: null,
  quality_customer_mobile: null,
  quality_request_source: null,
  quality_request_types: null,
  quality_request_date_from: null,
  quality_request_date_to: null,
  quality_customer_work_source: null,
  quality_request_agent_notes: null,
  quality_request_quality_notes: null,
});

const requestStatus = ref([]);
const getRequestStatus = () => {
  let url = `/employee/quality/quality-request/request/status`;
  axiosInstance.get(url).then((res) => {
    requestStatus.value = res.data.payload.map((item) => {
      item["color"] = "red";
      item["text"] = item["status_value"];
      return item;
    });
  });
};

const getSelectedStatus = (val) => {
  filterObj.value.quality_request_status = val;
};

const missionStatus = ref([]);
const getMissionStatus = () => {
  let url = `/employee/quality/quality-request/task/status`;
  axiosInstance.get(url).then((res) => {
    missionStatus.value = res.data.payload.map((item) => {
      item["color"] = "red";
      item["text"] = item["status_value"];
      return item;
    });
  });
};

const getSelectedMission = (val) => {
  filterObj.value.quality_task_status = val;
};

const agentStatus = ref([]);
const getAgentStatus = () => {
  let url = `/agent/Request/Classification?type_of_classification=0`;
  axiosInstance.get(url).then((res) => {
    agentStatus.value = res.data.payload.map((item) => {
      item["color"] = "red";
      item["text"] = item["value"];
      return item;
    });
  });
};

const getSelectedAgentStatus = (val) => {
  filterObj.value.quality_request_agent_classification = val;
};

const qualityStatus = ref([]);
const getQualityStatus = () => {
  let url = `/employee/quality/Classification`;
  axiosInstance.get(url).then((res) => {
    qualityStatus.value = res.data.payload.map((item) => {
      item["color"] = "red";
      item["text"] = item["value"];
      return item;
    });
  });
};

const getSelectedQualityStatus = (val) => {
  filterObj.value.quality_request_quality_classification = val;
};

const getSelectedWorkSource = (val) => {
  filterObj.value.quality_request_source = val;
};

const saveDateFrom = (val) => {
  filterObj.value.quality_request_date_from = val;
};

const saveDateTo = (val) => {
  filterObj.value.quality_request_date_to = val;
};

const getSelectedWorkList = (val) => {
  filterObj.value.quality_customer_work_source = val;
};

const typeRequest = ref([]);
const getTypeRequestList = () => {
  let data = [
    {
      color: "red",
      value: "شراء",
    },
    {
      color: "red",
      value: "رهن",
    },
    {
      color: "red",
      value: "تساهيل",
    },
    {
      color: "red",
      value: "شراء-دفعة",
    },
    {
      color: "red",
      value: "رهن-شراء",
    },
  ];
  typeRequest.value = data.map((item) => {
    item["color"] = "red";
    item["text"] = item["value"];
    return item;
  });
};

const getSelectedTypeRequest = (val) => {
  filterObj.value.quality_request_types = val;
};

const workSource = ref([]);
const getWorkSource = () => {
  workSource.value = store.state.works_list.map((item) => {
    item["color"] = "red";
    item["text"] = item["value"];
    return item;
  });
};

const communicationSource = ref([]);
const getCommunicationSource = () => {
  let url = `/agent/Request/Sources`;
  axiosInstance.get(url).then((res) => {
    communicationSource.value = res.data.payload.map((item) => {
      item["color"] = "red";
      item["text"] = item["value"];
      return item;
    });
  });
};

const getSelectedCommunicationSource = (val) => {
  filterObj.value.quality_request_source = val;
};

const notes = ref(["الفارغه", "تحتوي علي محتوي"]);
const handleSearch = () => {
  dialog.value = false;
  store.state.qualityObjFilter = filterObj;
  emit("handleFilter", filterObj.value);
};

onMounted(() => {
  getRequestStatus();
  getMissionStatus();
  getAgentStatus();
  getQualityStatus();
  getWorkSource();
  getTypeRequestList();
  getCommunicationSource();
});
</script>

<style>
.genaralIcons {
  position: absolute;
  top: -50px;
  min-width: unset;
  z-index: 5;
}
</style>
