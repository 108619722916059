<template>
    <div>
      <v-btn
        @click="handleFilterRateDialog"
        fab
        class="mx-2 pa-0 genaralIcons"
        small
        style="left: 80px"
        :color="$vuetify.theme.themes.light.BtnBKColor"
        depressed
        width="35"
        height="35"
      >
        <SearchIcon />
      </v-btn>

      <FilterRate @startRateSearch="startRateFilter"/>
  
    </div>
  </template>
    
    <style>
  .genaralIcons {
    position: absolute;
    top: -50px;
    min-width: unset;
    z-index: 5;
  }
  </style>
    
    <script>
  import { SearchIcon } from "@/assets/adminIcons/searchIcon";
  import FilterRate from './FilterRate.vue';
  
  export default {
    components: {
      FilterRate,
      SearchIcon
    },
    props: ["selectedItems", "type"],
    data() {
      return {
        qualityUsers: [],
        active: false,
        AllSelectedTable: [],
        dialog: false,
        message: "",
        snackColor: "",
        messages: "هل انت متاكد من رغبتك في ارشفت هذه الطلبات",
        allStatus: [],
        dialogQuality: false,
        selectedQuality: [],
        needToAction: [],
        snackMessage: "",
        FilterRateDialog: false,
      };
    },
    computed: {},
  
    methods: {
      handleFilterRateDialog() {
        this.$store.state.FilterRateDialog = true;
      },
  
      getSelectedValueManger(val) {
        this.selectedQuality = val;
      },
  

      startRateFilter(data){
        this.$emit('filterData' , data)
      }
    },
  };
  </script>
    