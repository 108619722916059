<template>
  <v-row justify="center">
    <v-dialog v-model="$store.state.openOneCallDialog" width="600">
      <v-card>
        <v-card-title>
          <div>المكالمه الخاصة بالطلب</div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div>
                <v-row
                style="
                    background-color: #f8f9fa;
                    border: 1px solid #eff2f7;
                    margin-top: 20px;
                "
                class="mx-0 file-info-row d-flex align-center text-center"
                >
                <v-progress-linear color="primary" reverse></v-progress-linear>
                <v-col cols="3"> الاستشاري</v-col>
                <v-col cols="5"> المكالمه</v-col>
                <v-col cols="4">تاريخ المكالمه</v-col>
                </v-row>

                <v-row 
                class="mx-0 py-4 file-info-row d-flex align-center text-center"
                >
                <v-col cols="3">{{ calls_details.agent_name }}</v-col>
                <v-col cols="5"><audio controls :src="calls_details.path" style="width: 100%; max-width: 200px;"></audio></v-col>
                <v-col cols="4">{{ calls_details.day_date }}</v-col>
                </v-row>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="closeRequestCalls"
          >
            الغاء
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
  
  <script>
import Axios from "axios";

export default {
  data() {
    return {
      calls_details: {},
    };
  },

  methods: {
    get_calls_for_request() {
      if (this.$store.state.idOfOneCall) {
        Axios.get(
          `https://appproduct.alwsata.com.sa/api/agent/callCenter/${this.$store.state.idOfOneCall}`,
          { headers: this.request_headers() }
        )
          .then((response) => {
            this.calls_details = response.data.payload;
          })
          .catch((err) => {
            this.showPopup("error", err.response.data.message);
          });
      }
    },

    closeRequestCalls(){
      this.$store.state.idOfOneCall = null;
      this.$store.state.openOneCallDialog = false;
    },
  },

  created() {
    if (this.$store.state.idOfOneCall != null) {
      this.get_calls_for_request();
    }else{
        console.log('There is an Erorr !');
    }
  },

  watch: {
    "$store.state.idOfOneCall": {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.get_calls_for_request();
        }
      },
    },
  },
};
</script>