export const IconFeatherArrowRight = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="13.521" height="13.936" viewBox="0 0 13.521 13.936">
            <g id="Icon_feather-arrow-right" data-name="Icon feather-arrow-right" transform="translate(-7 -6.793)">
                <path id="Path_5846" data-name="Path 5846" d="M7.5,18H20.021" transform="translate(0 -4.239)"
                      fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                <path id="Path_5847" data-name="Path 5847" d="M18,7.5l6.261,6.261L18,20.021"
                      transform="translate(-4.239)" fill="none" stroke="#000" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="1"/>
            </g>
        </svg>
    )
}