export const SourceValue = (val) => {
    if(val == 0) return  'دردشه - ويب'
    if (val == 1) return 'مكالمة فائته'
    if (val == 2) return 'متعاون'
    if (val == 3) return 'صديق'
    if (val == 4) return 'مدير النظام'
    if (val == 5) return 'تلفون ثابت'
    if (val == 6) return 'أسكان'
    if (val == 7) return 'ويب-اطلب تمويل'
    if (val == 8) return 'ويب-اطلب استشاره'
    if (val == 9) return 'ويب-الحاسبه العقاريه'
    if (val == 10) return 'مكالمة لم تسجل'
    if (val == 11) return 'تطبيق- اطلب استشاره'
    if (val == 12) return 'تطبيق- حاسبة التمويل'
    if (val == 1001) return 'الحاسبة-مكتمل'
    if (val == 1002) return 'الحاسبة-غير مكتمل'
    if (val == 1003) return 'موظف البنك'
    if (val == 1004) return 'عميل سابق'
    if (val == 1006) return 'الإدارة'
}