export const SendIcon = () => {
    return (
        <svg id="Group_12893" data-name="Group 12893" xmlns="http://www.w3.org/2000/svg" width="30" height="30"
             viewBox="0 0 30 30">
            <g id="Group_11863" data-name="Group 11863">
                <circle id="Ellipse_126" data-name="Ellipse 126" cx="15" cy="15" r="15" fill="#3f0e40" opacity="0.06"/>
                <path id="send"
                      d="M2.194,7.484a2.034,2.034,0,0,1,.813-.813h0l8.76-4.38a1.907,1.907,0,0,1,2.5.813,1.557,1.557,0,0,1,.063,1.564l-1.314,3H7.325a.626.626,0,1,0,0,1.251h5.694l1.314,3a1.9,1.9,0,0,1-.939,2.5,3.141,3.141,0,0,1-.751.125,1.732,1.732,0,0,1-.813-.188L3.07,9.987A1.913,1.913,0,0,1,2.194,7.484Z"
                      transform="translate(5.737 6.669)" fill="#3f0e40"/>
            </g>
        </svg>
    )
}

