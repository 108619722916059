<template>
  <page-component style="position: relative">
    <template #content>
      <!-- eslint-disable -->
      <!-- بيانات العميل -->
      <v-sheet>
        <!-- <span class="font-weight-bold" style="display: inline">بيانات الfgfgتمويل</span> -->
        <v-form class="mt-5" :disabled="!for_agent">
          <v-card flat>
            <v-layout
              row
              wrap
              style="background-color: #f3f5f7"
              class="userProfile__container"
            >
              <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="px-2 py-1 mb-3">
                <v-card class="pa-3 position-relative userProfile__card">
                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('product_type')"
                        />
                        نوع المنتج
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.app_products"
                        hide-details
                        v-model="agent.funding.product_code"
                        item-text="text"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('fundPersPre')"
                        />
                        نسبه الاستقطاع
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          hide-details
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.funding.personalFun_pre"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('fundMonth')"
                        />
                        القسط الشهري العقاري
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          hide-details
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.funding.monthly_in"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('fundDur')"
                        />
                        مدة التمويل
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          hide-details
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.funding.funding_duration"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('fundRealPre')"
                        />
                        فائده التمويل العقاري
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          hide-details
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.funding.realFun_pre"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-col>

              <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="px-2 py-1 mb-3">
                <v-card class="pa-3 position-relative userProfile__card">
                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('funding_source')"
                        />
                        جهة التمويل
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.funding_sources"
                        hide-details
                        v-model="agent.funding.funding_source"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('fundReal')"
                        />
                        مبلغ التمويل العقاري
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          hide-details
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.funding.realFun_cost"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('installment_after_support')"
                        />
                        القسط الشهري بعد الدعم
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          hide-details
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.funding.monthly_installment_after_support"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('fundFlex')"
                        />
                        مبلغ التمويل المرن
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          hide-details
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.funding.flexiableFun_cost"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('fundExten')"
                        />
                        مبلغ التمويل الممتد
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          hide-details
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.funding.extendFund_cost"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-col>

              <v-col cols="12" lg="4" md="4" sm="6" xs="12" class="px-2 py-1 mb-3">
                <v-card class="pa-3 position-relative userProfile__card">
                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('fundDed')"
                        />
                        نسبة الإستقطاع الشخصي
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          hide-details
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.funding.ded_pre"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('personal_salary_deduction')"
                        />
                        القسط الشهري (شخصي)
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          hide-details
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.funding.personal_monthly_installment"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('fundPers')"
                        />
                        مبلغ التمويل الشخصي
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          hide-details
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.funding.personalFun_cost"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-col>
            </v-layout>
          </v-card>
          <!-- <v-row> -->
          <!-- <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('product_type')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                نوع المنتج
              </div>
              <v-autocomplete
                :items="$store.state.app_products"
                hide-details
                v-model="agent.funding.product_code"
                item-text="text"
                item-value="id"
                class="mr-1"
                outlined
                dense
              />
            </v-col>

            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('fundPersPre')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                نسبه الاستقطاع
              </div>
              <v-text-field
                hide-details
                v-model="agent.funding.personalFun_pre"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('fundMonth')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                القسط الشهري العقاري
              </div>
              <v-text-field
                hide-details
                v-model="agent.funding.monthly_in"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('fundDur')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                مدة التمويل
              </div>
              <v-text-field
                color=""
                hide-details
                v-model="agent.funding.funding_duration"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('fundRealPre')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                فائده التمويل العقاري
              </div>
              <v-text-field
                hide-details
                v-model="agent.funding.realFun_pre"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('funding_source')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                جهة التمويل
              </div>
              <v-autocomplete
                :items="$store.state.funding_sources"
                hide-details
                v-model="agent.funding.funding_source"
                item-text="value"
                item-value="id"
                class="mr-1"
                outlined
                dense
              />
            </v-col>

            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('fundReal')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                مبلغ التمويل العقاري
              </div>
              <v-text-field
                hide-details
                v-model="agent.funding.realFun_cost"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('installment_after_support')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                القسط الشهري بعد الدعم
              </div>
              <v-text-field
                hide-details
                v-model="agent.funding.monthly_installment_after_support"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('fundFlex')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                مبلغ التمويل المرن
              </div>
              <v-text-field
                hide-details
                v-model="agent.funding.flexiableFun_cost"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('fundExten')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                مبلغ التمويل الممتد
              </div>
              <v-text-field
                hide-details
                v-model="agent.funding.extendFund_cost"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('fundDed')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                نسبة الإستقطاع الشخصي
              </div>
              <v-text-field
                hide-details
                v-model="agent.funding.ded_pre"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('personal_salary_deduction')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                القسط الشهري (شخصي)
              </div>
              <v-text-field
                hide-details
                v-model="agent.funding.personal_monthly_installment"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">
                <svg
                  @click="show_Field_log('fundPers')"
                  xmlns="http://www.w3.org/2000/svg"
                  width="15.76"
                  height="12.964"
                  viewBox="0 0 15.76 12.964"
                  style="margin-left: 3px; cursor: pointer; margin-bottom: -2px"
                >
                  <path
                    id="Icon_material-history"
                    data-name="Icon material-history"
                    d="M10.506,4.5a6.623,6.623,0,0,0-6.754,6.482H1.5l2.919,2.8.053.1,3.032-2.9H5.252A5.147,5.147,0,0,1,10.506,5.94a5.147,5.147,0,0,1,5.253,5.042,5.147,5.147,0,0,1-5.253,5.042A5.322,5.322,0,0,1,6.8,14.54L5.733,15.563a6.864,6.864,0,0,0,4.773,1.9,6.623,6.623,0,0,0,6.754-6.482A6.623,6.623,0,0,0,10.506,4.5Zm-.75,3.6v3.6l3.212,1.829.54-.871-2.627-1.5V8.1Z"
                    transform="translate(-1.5 -4.5)"
                  />
                </svg>
                مبلغ التمويل الشخصي
              </div>
              <v-text-field
                hide-details
                v-model="agent.funding.personalFun_cost"
                class="mr-1"
                outlined
                dense
                type="number"
                hide-spin-buttons
              />
            </v-col> -->

          <!-- <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="py-0 mb-5">
              <div class="mb-2">نسبة الإستقطاع (شخصي)</div>
              <v-text-field
                hide-details
                v-model="agent.funding.personal_salary_deduction"
                class="mr-1"
                outlined
                dense
                type="number"
              ></v-text-field>
            </v-col> -->
          <!-- </v-row> -->
        </v-form>
      </v-sheet>
    </template>
  </page-component>
</template>

<script>
export default {
  props: ["agent"],
  data() {
    return {
      name: "اهلا اهلا",
      productTest: null,
      agent_data: {},
      date: {
        birth_date: "",
        birth_date_higri: "",
      },
      birth_date: true,
    };
  },
  computed: {
    for_agent() {
      return (
        this.$store.state.request_info.statusReq == 3 ||
        this.$store.state.request_info.statusReq == 5 ||
        this.$store.state.request_info.statusReq == 7 ||
        this.$store.state.request_info.statusReq == 10
      );
    },
  },

  watch: {
    agent: {
      handler(val) {
        if (val) {
          this.$emit("update:agent", val);
          this.$store.state.request_info = val;
          this.date.birth_date = val.customer.birth_date;
          this.date.birth_date_higri = val.customer.birth_date_higri;
          val.customer.work = parseInt(val.customer.work);
          this.setBreadCrumbs(["الرئيسية", "الطلبات", val.customer.name]);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
