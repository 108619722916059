<template>
  <div>
    <v-btn
      fab
      :color="$vuetify.theme.themes.light.dialogActionBTN"
      @click="addNewRequestDialog = true"
      class="my-10"
      style="position: fixed; left: 20px; bottom: 30px; z-index: 3"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.425"
        height="21.166"
        viewBox="0 0 25.425 21.166"
      >
        <g
          id="Icon_feather-user-plus"
          data-name="Icon feather-user-plus"
          transform="translate(1 1)"
        >
          <path
            id="Path_2554"
            data-name="Path 2554"
            d="M17.471,28.889v-2.13A4.259,4.259,0,0,0,13.212,22.5H5.759A4.259,4.259,0,0,0,1.5,26.759v2.13"
            transform="translate(-1.5 -9.723)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_2555"
            data-name="Path 2555"
            d="M15.268,8.759A4.259,4.259,0,1,1,11.009,4.5,4.259,4.259,0,0,1,15.268,8.759Z"
            transform="translate(-3.023 -4.5)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_2556"
            data-name="Path 2556"
            d="M30,12v6.389"
            transform="translate(-9.77 -6.676)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_2557"
            data-name="Path 2557"
            d="M31.889,16.5H25.5"
            transform="translate(-8.464 -7.982)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </svg>
    </v-btn>
    <add-new-request
      v-model="addNewRequestDialog"
      width="350px"
      @submitted="get_all_requests"
    />
  </div>
</template>

<script>
import { axiosInstance } from "@/axios/config";
export default {
  name: "AddNewRequestBtn",

  data: () => ({
    addNewRequestDialog: false,
  }),
  methods: {
    async get_all_requests(page = 1) {
      this.loading = true;
      await axiosInstance
        .get(`/v2/employee/Requests?type=all&page=${page}&per_page=12`)
        .then((response) => {
          this.items = response.data.payload.data;
          this.pagination = {
            limit: 10,
            count: response.data.payload.count_of_all,
          };
          this.loading = false;
        });
    },
  },
};
</script>
