import { base } from './base.js'

export const PageComponentMixin = {
  mixins: [base],
  data () {
    return {
    }
  },
  methods: {
    capitalize (s) {
      return (s ? s.charAt(0).toUpperCase() + s.slice(1) : s)
    },
    capitalizeAll (s) {
      return (s ? s.toUpperCase() : s)
    },
    formatName (requestor) {
      return (
        this.capitalize(requestor.first_name) + ' ' + this.capitalize(requestor.last_name)
      )
    },
    nullify (obj) {
      Object.keys(obj).forEach(key => {
        obj[key] = null
      });
    },
    //Function to deep remove object keys that have null/undefined values
    normalizeObject(obj) {
      Object.keys(obj).forEach(key => {
        if (obj[key] === null || obj[key] === undefined) {
          delete obj[key];
        } else if (typeof obj[key] === 'object') {
          this.normalizeObject(obj[key])
        }
      });
    },
    isObjectDifferent(object1, object2) {
      const keys1 = Object.keys(object1);

      for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = this.isObject(val1) && this.isObject(val2);
        if (
          areObjects && !this.isObjectDifferent(val1, val2) ||
          !areObjects && val1 != val2
        ) {
          return false;
        }
      }
      return true;
    },
    isObject(object) {
      return object != null && typeof object === 'object';
    }
  }
}
