<template>
    <div>
        <v-sheet class="c-block-1">
          <v-row>
            <v-col cols="12">
              <RateFilter  @handleFilter="handleFilter" :role="'7'" />
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet class="c-block-1">
            <v-row class=" title-sec-1 title-row">
                <v-col cols="4">
                    <div class="inner-c" style="">
                        <h4>
                            تقييمات العملاء
                        </h4>
                        <p style=" direction:ltr;">
                            ( {{reviews_percent}} out of 5)
                        </p>
                        <p style=" direction:ltr;">
                            ( {{reviews_count}} ,Reviews)
                        </p>
                        <div
                            class=""
                            :style="'display:flex'"
                            v-if="1"
                            >
                            <span style="direction: ltr;">
                                <v-list-item-icon class="ma-0 pa-0" v-for="index in 5" :key="index">
                                <v-icon size="25" color="warning"> {{ average_rate_stars(index) }} </v-icon>
                                </v-list-item-icon>
                            </span>
                        </div>
                    </div>
                </v-col>
                <v-col cols="6"></v-col>
                <v-col cols="2">
                    <div class="back-txt" @click="backToAgentPercentage">
                        عودة
                        <span>
                            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20 17.418C17.5535 14.4315 15.381 12.737 13.482 12.334C11.5835 11.9315 9.776 11.8705 8.059 12.1515V17.5L0 8.7725L8.059 0.5V5.5835C11.2335 5.6085 13.932 6.7475 16.155 9C18.3775 11.2525 19.6595 14.0585 20 17.418Z" fill="#3F0E40"/>
                            </svg>
                            
                        </span>
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <table-component
                        :showSelect="false"
                        itemKey="id"
                        :headers="headers"
                        :pagination="this.$store.state.pagination"
                        :limit="$store.state.counterOfShow"
                        :items="tableData"
                        :loading="loading"
                        >

                        <template slot="item.star_avg_stars" slot-scope="props">
                            <div>
                              <div
                                  class=""
                                  :style="'display:flex; direction:ltr;justify-content: center;'"
                                  v-if="1"
                                  >
                                  <span>
                                      <v-list-item-icon class="ma-0 pa-0" v-for="index in 5" :key="index">
                                      <v-icon size="25" color="warning"> {{ average_rate_starsA(index, props.item.star_avg_stars) }} </v-icon>
                                      </v-list-item-icon>
                                  </span>
                              </div>
                            </div>
                        </template>
                        <template slot="item.star_avg" slot-scope="props">
                            <div>
                              {{props.item.star_avg_stars}}
                            </div>
                        </template>
                        <template slot="item.star_avg_c" slot-scope="props">
                            <div>
                              {{props.item.star_avg_c}} ,Reviews
                            </div>
                        </template>
                    </table-component>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <h4 class="sub-title">
                        الاستشاريين
                    </h4>
                </v-col>
                <v-col cols="12" class="sales-managers-ch">
                    <v-row>
                        <v-col v-for="(item, index) in itemsSalesManagers" :key="index" cols="2">
                        <v-radio-group v-model="selected_sales_manger">
                            <v-radio :label="item.label" :value="item.value"></v-radio>
                        </v-radio-group>
                        </v-col>
                    </v-row>

                </v-col>

                <v-col cols="12">

                    <RateChart :xaxis="xaxis" :chartData="chartData" />
                </v-col>
            </v-row>
        </v-sheet>
    </div>
  </template>
  
  <script>
  
  import { axiosInstance } from "@/axios/config";
  import RateFilter from './RateFilter'
  import RateChart from './RateChart'
  import Axios from "axios";
import router from "@/routes";
  
  export default {
    components: {
      RateChart,
      RateFilter
    },
    data() {
      return {
        query_params_obj: {},
        loading: true,
        xaxis:[],
        chartData:[],
        selected_sales_manger: '',
        reviews_count: null,
        reviews_percent: null,
      selectedItem: null,
      itemsSalesManagers: [
        { label: 'جميع الاستشاريين', value: '' }
      ],
            headers: [
                { text: "جميع الاقسام", value: "name", align: "center", width: '40%' },
                { text: "التقييم بالنجوم", value: "star_avg_stars", align: "center" },
                { text: "التقييم بالنسبه (out of 5)", value: "star_avg", align: "center" },
                { text: "عدد التقييمات", value: "star_avg_c", align: "center" }
            ],
            tableData:[],
          day_date: '',
          agents: [],
          star_1: 0,
          star_2: 0,
          star_3: 0,
          star_4: 0,
          star_5: 0,
          star_1_c: 0,
          star_2_c: 0,
          star_3_c: 0,
          star_4_c: 0,
          star_5_c: 0,
          star_t_c: 0,
          star_avg: 0,
          valueDeterminate: 50,
        items: [
          {
            label: "اسبوع",
            value: 7 
          },
          {
            label: "اسبوعين",
            value: 14 
          },
          {
            label: "شهر",
            value: 30 
          },
        ],
      };
    },
    watch:{
      selected_sales_manger(val){
        this.getAgentsReviews();
      }
    },
    methods: {
      backToAgentPercentage(){
        router.push({
          name: "agent-percentage",
        });
      },
      average_rate_stars(index) {
        if (index <= this.reviews_percent) {
          return "mdi-star";
        } else if (Math.round(this.reviews_percent) == index) {
          return "mdi-star-half-full";
        } else {
          return "mdi-star-outline";
        }
      },
      average_rate_starsA(index, star_avg_stars) {
        if (index <= star_avg_stars) {
          return "mdi-star";
        } else if (Math.round(star_avg_stars) == index) {
          return "mdi-star-half-full";
        } else {
          return "mdi-star-outline";
        }
      },
      
    getReviews(){
        axiosInstance
        .get(`/employee/admin/reviews-statistics`, {params: this.query_params_obj})
        .then((res) => {
            this.reviews_count = res.data.payload.star_avg_c;
            let percent = res.data.payload.star_avg;
            percent = (percent / 20);
            this.reviews_percent = percent.toFixed(2);
        })
        .catch((err) => {
          console.log("eror")
        });
      },
    getSalesReviews(){
        axiosInstance
        .get(`/employee/admin/reviews-statistics-salesmanagers`, {params: this.query_params_obj})
        .then((res) => {
          console.log("res", res)
          this.tableData = res.data.payload;
          for (let index = 0; index < res.data.payload.length; index++) {
            const element = res.data.payload[index];
            this.itemsSalesManagers.push({
              label: element.name,
              value: element.id
            });
            
          }
          this.loading = false
            // this.reviews_count = res.data.payload.star_avg_c;
            // let percent = res.data.payload.star_avg;
            // percent = (percent / 20);
            // this.reviews_percent = percent.toFixed(2);
        })
        .catch((err) => {
          console.log("eror")
        });
      },
    getAgentsReviews(){
      let data = '';
      if (this.selected_sales_manger != '') {
        data = 'sales_manger_id='+this.selected_sales_manger;
      }
      
      this.xaxis = [],
      this.chartData = [],
        axiosInstance
        .get(`/employee/admin/reviews-statistics-salesmanagers-agents?${data}`, {params: this.query_params_obj} )
        .then((res) => {
          console.log("res", res)
          for (let index = 0; index < res.data.payload.length; index++) {
            const element = res.data.payload[index];
            this.xaxis.push(element.name);
            this.chartData.push(element.star_avg_stars);
            
          }
            // this.reviews_count = res.data.payload.star_avg_c;
            // let percent = res.data.payload.star_avg;
            // percent = (percent / 20);
            // this.reviews_percent = percent.toFixed(2);
        })
        .catch((err) => {
          console.log("eror")
        });
      },
      handleFilter(obj){
        console.log("obj", obj);
        this.query_params_obj.from_date = obj.startDate;
        this.query_params_obj.to_date = obj.endDate;
        this.getReviews();
        this.getSalesReviews();
        this.getAgentsReviews();
      },
    },
  
    created() {
      this.getReviews();
      this.getSalesReviews();
      this.getAgentsReviews();
    },
  };
  </script>
  <style>
  .title-row{
    border: 1px solid;
    border-radius: 10px;
    padding: 7px 0 0 0;
  }
  .title-sec-1{
    display: flex;
    justify-content: flex-start;
  }
  .title-sec-1 .inner-c{
     display: flex; justify-content: space-around; 
     /* min-width: 30%; */
  }
  /* .title-sec-1{} */
h4.sub-title{
  background-color: #d9d1da;
    padding: 11px 11px;
    margin: 11px 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid;
    color: #451646;
}
  </style>