<template>
  <v-container>
    <div class="selectInput">
      <v-combobox
        v-model="model"
        :filter="filter"
        :hide-no-data="!search"
        :items="items"
        :search-input.sync="search"
        hide-selected
        :label="label"
        multiple
        small-chips
        solo
      >
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip
            v-if="item === Object(item)"
            v-bind="attrs"
            :color="`${item.color} lighten-3`"
            :input-value="selected"
            label
            small
          >
            <span class="pr-2">
              {{ item.text }}
            </span>
            <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
          </v-chip>
        </template>
        <template v-slot:item="{ index, item }">
          <v-text-field
            v-if="editing === item"
            v-model="editing.text"
            autofocus
            flat
            background-color="transparent"
            hide-details
            solo
            @keyup.enter="edit(index, item)"
          ></v-text-field>
          <v-chip v-else :color="`${item.color} lighten-3`" dark label small>
            {{ item.text }}
          </v-chip>
          <v-spacer></v-spacer>
        </template>
      </v-combobox>
    </div>
  </v-container>
</template>

<style>
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: 0 0 0 0 !important;
}
.selectInput
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  border: 1px solid #9e9e9e;
  margin-top: -12px;
}
</style>

<script>
export default {
  name: "SelectInput",
  props: ["name", "originalItems", "label"],
  emits: ["selectedObjects"],
  data: () => ({
    activator: null,
    attach: null,
    colors: ["green", "purple", "indigo", "cyan", "teal", "orange"],
    editing: null,
    editingIndex: -1,
    nonce: 1,
    menu: false,
    model: [],
    x: 0,
    search: null,
    y: 0,
    items: [],
  }),

  beforeMount() {
    this.items = structuredClone(this.originalItems);
  },
  watch: {
    originalItems(val) {
      this.items = val;
    },
    model(val, prev) {
      this.emitObjects(val);
      if (val.length === prev.length) return;

      this.model = val.map((v) => {
        if (typeof v === "string") {
          v = {
            text: v,
            color: this.colors[this.nonce - 1],
          };

          this.items.push(v);
          this.nonce++;
        }
        return v;
      });
    },
  },

  methods: {
    clear() {
      // Clear the model and emit an empty array to clear the selected values
      this.model = [];
      this.emitObjects([]);
    },
    emitObjects(val) {
      this.$emit("selectedValues", val);
    },
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
    },
  },
};
</script>
