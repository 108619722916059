<template>
  <page-component style="position: relative">
    <template #content>
      <!-- بيانات العميل -->
      <v-sheet class="px-5 py-5">
        <div class="d-flex align-center justify-center py-3"
          style="font-size: 20px; color: white; background-color: #451646">
          حاسبة التمويل
        </div>
        <v-form class="mt-10">
          <v-row>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2 mr-2">نوع المنتج</div>
              <v-autocomplete
                :items="$store.state.app_products"
                hide-details
                v-model="payload.product_code"
                item-text="text"
                item-value="code"
                class="mr-1"
                outlined
                dense
                append-icon
              >
                <template v-slot:append>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.532"
                    height="6.328"
                    viewBox="0 0 11.532 6.328"
                    style="margin-top: 8px"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-down"
                      data-name="Icon ionic-ios-arrow-down"
                      d="M11.956,15.667l4.361-4.188a.845.845,0,0,1,1.164,0,.775.775,0,0,1,0,1.12L12.54,17.344a.848.848,0,0,1-1.136.023L6.428,12.6a.773.773,0,0,1,0-1.12.845.845,0,0,1,1.164,0Z"
                      transform="translate(-6.188 -11.246)"
                      fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2 mr-2">تاريخ الميلاد (هجري)</div>
              <v-text-field
                style="position: relative"
                hide-details
                v-model="payload.birth_date_hijri"
                class="mr-1"
                outlined
                dense
              >
                <template v-slot:append>
                  <svg
                    id="calendar"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.396"
                    height="16.989"
                    viewBox="0 0 17.396 16.989"
                    @click="show_date_2 = !show_date_2"
                    style="margin-top: 4px"
                  >
                    <path
                      id="Path_2784"
                      data-name="Path 2784"
                      d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                      transform="translate(-4.21 -7.917)"
                      fill="#6c757d"
                    />
                    <path
                      id="Path_2785"
                      data-name="Path 2785"
                      d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                      transform="translate(-4.21 -4.86)"
                      fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-text-field>
              <v-hijri-date-picker
                @change="show_date_2 = false"
                v-if="show_date_2"
                style="position: absolute; z-index: 1000"
                v-model="payload.birth_date_hijri"
                locale="ar"
              />
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2 mr-2">صافي الراتب</div>
              <v-text-field
                hide-details
                v-model="payload.salary"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2 mr-2">جهة العمل</div>
              <v-autocomplete
                :items="$store.state.works_list"
                hide-details
                v-model="payload.work_source"
                item-text="value"
                item-value="value"
                class="mr-1"
                outlined
                dense
                append-icon
              >
                <template v-slot:append>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.532"
                    height="6.328"
                    viewBox="0 0 11.532 6.328"
                    style="margin-top: 8px"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-down"
                      data-name="Icon ionic-ios-arrow-down"
                      d="M11.956,15.667l4.361-4.188a.845.845,0,0,1,1.164,0,.775.775,0,0,1,0,1.12L12.54,17.344a.848.848,0,0,1-1.136.023L6.428,12.6a.773.773,0,0,1,0-1.12.845.845,0,0,1,1.164,0Z"
                      transform="translate(-6.188 -11.246)"
                      fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              lg="3"
              md="4"
              sm="6"
              xs="12"
              class="mb-5 py-0"
              v-if="payload.work_source == 'عسكري'"
            >
              <div class="mb-2 mr-2">الرتبة العسكريه</div>
              <v-autocomplete
                :items="$store.state.military_ranks"
                hide-details
                v-model="payload.military_rank"
                item-text="value"
                item-value="id"
                class="mr-1"
                outlined
                dense
                append-icon
              >
                <template v-slot:append>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.532"
                    height="6.328"
                    viewBox="0 0 11.532 6.328"
                    style="margin-top: 8px"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-down"
                      data-name="Icon ionic-ios-arrow-down"
                      d="M11.956,15.667l4.361-4.188a.845.845,0,0,1,1.164,0,.775.775,0,0,1,0,1.12L12.54,17.344a.848.848,0,0,1-1.136.023L6.428,12.6a.773.773,0,0,1,0-1.12.845.845,0,0,1,1.164,0Z"
                      transform="translate(-6.188 -11.246)"
                      fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2 mr-2">الراتب الأساسي</div>
              <v-text-field
                hide-details
                v-model="payload.basic_salary"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2 mr-2">جهة نزول المرتب</div>
              <v-autocomplete
                :items="$store.state.salary_sources"
                item-text="value"
                item-value="id"
                hide-details
                v-model="payload.salary_source"
                class="mr-1"
                outlined
                dense
                append-icon
              >
                <template v-slot:append>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.532"
                    height="6.328"
                    viewBox="0 0 11.532 6.328"
                    style="margin-top: 8px"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-down"
                      data-name="Icon ionic-ios-arrow-down"
                      d="M11.956,15.667l4.361-4.188a.845.845,0,0,1,1.164,0,.775.775,0,0,1,0,1.12L12.54,17.344a.848.848,0,0,1-1.136.023L6.428,12.6a.773.773,0,0,1,0-1.12.845.845,0,0,1,1.164,0Z"
                      transform="translate(-6.188 -11.246)"
                      fill="#6c757d"
                    />
                  </svg> </template
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2 mr-2">تاريخ التعيين</div>
              <v-text-field
                style="position: relative"
                hide-details
                v-model="payload.hiring_date"
                class="mr-1"
                outlined
                dense
              >
                <template v-slot:append>
                  <svg
                    id="calendar"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.396"
                    height="16.989"
                    viewBox="0 0 17.396 16.989"
                    @click="show_date = !show_date"
                    style="margin-top: 4px"
                  >
                    <path
                      id="Path_2784"
                      data-name="Path 2784"
                      d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                      transform="translate(-4.21 -7.917)"
                      fill="#6c757d"
                    />
                    <path
                      id="Path_2785"
                      data-name="Path 2785"
                      d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                      transform="translate(-4.21 -4.86)"
                      fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-text-field>
              <v-hijri-date-picker
                @change="show_date = false"
                v-if="show_date"
                style="position: absolute; z-index: 1000"
                v-model="payload.hiring_date"
                locale="ar"
              />
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <v-switch
                :true-value="1"
                :false-value="0"
                inset
                flat
                label="الدعم السكني"
                v-model="payload.residential_support"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              lg="3"
              md="4"
              sm="6"
              xs="12"
              v-if="payload.residential_support"
            >
              <v-checkbox
                :true-value="1"
                :false-value="0"
                label="إضافة قسط إلي دعم الراتب"
                v-model="payload.add_support_installment_to_salary"
              />
            </v-col>
            <v-col
              cols="12"
              lg="3"
              md="4"
              sm="6"
              xs="12"
              v-if="payload.residential_support"
            >
              <v-checkbox
                :true-value="1"
                :false-value="0"
                label="الضمانات"
                v-model="payload.guarantees"
              />
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <v-switch
                :true-value="1"
                :false-value="0"
                inset
                flat
                label="إظهار نسبة الإستقطاع"
                v-model="payload.deduction_percentage"
              />
            </v-col>
          </v-row>
          <v-row v-if="payload.deduction_percentage">
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">نسبة استقطاع صافي الراتب(شخصي)</div>
              <v-text-field
                hide-details
                v-model="payload.personal_salary_deduction"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">مدة التمويل بالشهور (شخصي)</div>
              <v-text-field
                hide-details
                v-model="payload.personal_funding_months"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">نسبة الإستقطاع</div>
              <v-text-field
                hide-details
                v-model="payload.salary_deduction"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">مدة التمويل بالشهور</div>
              <v-text-field
                hide-details
                v-model="payload.funding_months"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">قيمة العقار</div>
              <v-text-field
                hide-details
                v-model="payload.property_amount"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">حالة العقار</div>
              <v-autocomplete
                :items="[
                  { text: 'عقار مكتمل', value: 1 },
                  { text: 'عقار غير مكتمل', value: 0 },
                ]"
                hide-details
                v-model="payload.property_completed"
                item-text="text"
                item-value="value"
                class="mr-1"
                outlined
                dense
                append-icon
              >
                <template v-slot:append>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.532"
                    height="6.328"
                    viewBox="0 0 11.532 6.328"
                    style="margin-top: 8px"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-down"
                      data-name="Icon ionic-ios-arrow-down"
                      d="M11.956,15.667l4.361-4.188a.845.845,0,0,1,1.164,0,.775.775,0,0,1,0,1.12L12.54,17.344a.848.848,0,0,1-1.136.023L6.428,12.6a.773.773,0,0,1,0-1.12.845.845,0,0,1,1.164,0Z"
                      transform="translate(-6.188 -11.246)"
                      fill="#6c757d"
                    />
                  </svg> </template
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">المسكن</div>
              <v-autocomplete
                :items="[
                  { text: 'مسكن أول', value: 1 },
                  { text: 'مسكن ثاني', value: 2 },
                ]"
                hide-details
                v-model="payload.residence_type"
                item-text="text"
                item-value="value"
                class="mr-1"
                outlined
                dense
                append-icon
              >
                <template v-slot:append>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.532"
                    height="6.328"
                    viewBox="0 0 11.532 6.328"
                    style="margin-top: 8px"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-down"
                      data-name="Icon ionic-ios-arrow-down"
                      d="M11.956,15.667l4.361-4.188a.845.845,0,0,1,1.164,0,.775.775,0,0,1,0,1.12L12.54,17.344a.848.848,0,0,1-1.136.023L6.428,12.6a.773.773,0,0,1,0-1.12.845.845,0,0,1,1.164,0Z"
                      transform="translate(-6.188 -11.246)"
                      fill="#6c757d"
                    />
                  </svg> </template
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <v-switch
                :true-value="1"
                :false-value="0"
                inset
                flat
                label="الإلتزامات"
                v-model="payload.has_obligations"
              />
            </v-col>
          </v-row>
          <v-row v-if="payload.has_obligations">
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">قسط بنك التسليف</div>
              <v-text-field
                hide-details
                v-model="payload.funding_source"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">قسط الإلتزامات</div>
              <v-text-field
                hide-details
                v-model="payload.obligations_installment"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">أشهر الإلتزامات المتبقية</div>
              <v-text-field
                hide-details
                v-model="payload.remaining_obligations_months"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">مبلغ السداد المبكر</div>
              <v-text-field
                hide-details
                v-model="payload.early_repayment"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <v-switch
                :true-value="1"
                :false-value="0"
                :disabled="payload.provide_first_batch != 0"
                inset
                flat
                label="يوجد دفعة"
                v-model="payload.sahal"
              />
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <v-switch
                :true-value="1"
                :disabled="payload.sahal != 0"
                :false-value="0"
                inset
                flat
                label="توفير الدفعة الأولي"
                v-model="payload.provide_first_batch"
              />
            </v-col>
          </v-row>
          <v-row v-if="payload.provide_first_batch">
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">نسبة الدفعة الأولي (%)</div>
              <v-text-field
                hide-details
                v-model="payload.first_batch_percentage"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">نسبة ربح الدفعة الأولي (%)</div>
              <v-text-field
                hide-details
                v-model="payload.first_batch_profit"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">رسوم إدارية</div>
              <v-text-field
                hide-details
                v-model="payload.fees"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">خصم العميل</div>
              <v-text-field
                hide-details
                v-model="payload.discount"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <v-switch
                :true-value="1"
                :false-value="0"
                inset
                flat
                label="يوجد متضامن"
                v-model="payload.has_joint"
              />
            </v-col>
          </v-row>
          <v-row v-if="payload.has_joint">
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2 mr-2">تاريخ الميلاد (هجري)</div>
              <v-text-field
                style="position: relative"
                hide-details
                v-model="payload.joint_birth_hijri"
                class="mr-1"
                outlined
                dense
                append-icon
                type="date"
              >
                <template v-slot:append>
                  <svg
                    id="calendar"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.396"
                    height="16.989"
                    viewBox="0 0 17.396 16.989"
                    style="margin-top: 4px"
                  >
                    <path
                      id="Path_2784"
                      data-name="Path 2784"
                      d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                      transform="translate(-4.21 -7.917)"
                      fill="#6c757d"
                    />
                    <path
                      id="Path_2785"
                      data-name="Path 2785"
                      d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                      transform="translate(-4.21 -4.86)"
                      fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-text-field>
              <v-hijri-date-picker
                @change="show_date = false"
                v-if="show_date"
                style="position: absolute; z-index: 1000"
                v-model="payload.joint_birth_hijri"
                locale="ar"
              />
              <!-- <v-text-field type="date" hide-details v-model="payload.joint_birth_hijri" class="mr-1" outlined dense></v-text-field> -->
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2 mr-2">صافي الراتب</div>
              <v-text-field
                hide-details
                v-model="payload.joint_salary"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2 mr-2">جهة العمل</div>
              <v-autocomplete
                :items="$store.state.works_list"
                hide-details
                v-model="payload.joint_work"
                item-text="value"
                item-value="value"
                class="mr-1"
                outlined
                dense
                append-icon
              >
                <template v-slot:append>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.532"
                    height="6.328"
                    viewBox="0 0 11.532 6.328"
                    style="margin-top: 8px"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-down"
                      data-name="Icon ionic-ios-arrow-down"
                      d="M11.956,15.667l4.361-4.188a.845.845,0,0,1,1.164,0,.775.775,0,0,1,0,1.12L12.54,17.344a.848.848,0,0,1-1.136.023L6.428,12.6a.773.773,0,0,1,0-1.12.845.845,0,0,1,1.164,0Z"
                      transform="translate(-6.188 -11.246)"
                      fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              lg="3"
              md="4"
              sm="6"
              xs="12"
              class="mb-5 py-0"
              v-if="payload.joint_work == 'عسكري'"
            >
              <div class="mb-2 mr-2">الرتبة العسكريه</div>
              <v-autocomplete
                :items="$store.state.military_ranks"
                hide-details
                v-model="payload.joint_military_rank"
                item-text="value"
                item-value="id"
                class="mr-1"
                outlined
                dense
                append-icon
              >
                <template v-slot:append>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.532"
                    height="6.328"
                    viewBox="0 0 11.532 6.328"
                    style="margin-top: 8px"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-down"
                      data-name="Icon ionic-ios-arrow-down"
                      d="M11.956,15.667l4.361-4.188a.845.845,0,0,1,1.164,0,.775.775,0,0,1,0,1.12L12.54,17.344a.848.848,0,0,1-1.136.023L6.428,12.6a.773.773,0,0,1,0-1.12.845.845,0,0,1,1.164,0Z"
                      transform="translate(-6.188 -11.246)"
                      fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12" class="mb-5 py-0">
              <div class="mb-2 mr-2">جهة نزول المرتب</div>
              <v-autocomplete
                :items="$store.state.salary_sources"
                item-text="value"
                item-value="id"
                hide-details
                v-model="payload.joint_salary_source"
                class="mr-1"
                outlined
                dense
                append-icon
              >
                <template v-slot:append>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.532"
                    height="6.328"
                    viewBox="0 0 11.532 6.328"
                    style="margin-top: 8px"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-down"
                      data-name="Icon ionic-ios-arrow-down"
                      d="M11.956,15.667l4.361-4.188a.845.845,0,0,1,1.164,0,.775.775,0,0,1,0,1.12L12.54,17.344a.848.848,0,0,1-1.136.023L6.428,12.6a.773.773,0,0,1,0-1.12.845.845,0,0,1,1.164,0Z"
                      transform="translate(-6.188 -11.246)"
                      fill="#6c757d"
                    />
                  </svg> </template
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-divider class="my-3" v-if="payload.has_joint"></v-divider>
          <v-row v-if="payload.has_joint">
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <v-switch
                :true-value="1"
                :false-value="0"
                inset
                flat
                label="الدعم السكني"
                v-model="payload.joint_residential_support"
              />
            </v-col>
          </v-row>
          <v-row v-if="payload.has_joint">
            <v-col
              cols="12"
              lg="3"
              md="4"
              sm="6"
              xs="12"
              v-if="payload.joint_residential_support"
            >
              <v-checkbox
                :true-value="1"
                :false-value="0"
                label="إضافة قسط إلي دعم الراتب"
                v-model="payload.joint_add_support_installment_to_salary"
              />
            </v-col>
          </v-row>
          <v-divider class="my-3" v-if="payload.has_joint"></v-divider>
          <v-row v-if="payload.has_joint">
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <v-switch
                :true-value="1"
                :false-value="0"
                inset
                flat
                label="الإلتزامات"
                v-model="payload.joint_has_obligations"
              />
            </v-col>
          </v-row>
          <v-row v-if="payload.has_joint">
            <v-col
              cols="12"
              lg="3"
              md="4"
              sm="6"
              xs="12"
              v-if="payload.joint_has_obligations"
            >
              <div class="mb-2 mr-2">مبلغ السداد المبكر</div>
              <v-text-field
                hide-details
                v-model="payload.joint_personal_salary_deduction"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider v-if="payload.has_joint" class="my-3"></v-divider>
          <v-row v-if="payload.has_joint">
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">نسبة استقطاع صافي الراتب(شخصي)</div>
              <v-text-field
                hide-details
                v-model="payload.joint_personal_salary_deduction"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">مدة التمويل بالشهور (شخصي)</div>
              <v-text-field
                hide-details
                v-model="payload.joint_personal_funding_months"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">نسبة الإستقطاع</div>
              <v-text-field
                hide-details
                v-model="payload.joint_salary_deduction"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">مدة التمويل بالشهور</div>
              <v-text-field
                hide-details
                v-model="payload.joint_funding_months"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider v-if="payload.has_joint" class="my-3"></v-divider>
          <v-row v-if="payload.has_joint">
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">دخل إضافي (التقاعد)</div>
              <v-text-field
                hide-details
                v-model="payload.retirement_income"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3" md="4" sm="6" xs="12">
              <div class="mb-2 mr-2">تاريخ التعيين</div>
              <v-text-field
                hide-details
                v-model="payload.funding_months"
                class="mr-1"
                outlined
                dense
                type="date"
                append-icon
              >
                <template v-slot:append>
                  <svg
                    id="calendar"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.396"
                    height="16.989"
                    viewBox="0 0 17.396 16.989"
                    style="margin-top: 4px"
                  >
                    <path
                      id="Path_2784"
                      data-name="Path 2784"
                      d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                      transform="translate(-4.21 -7.917)"
                      fill="#6c757d"
                    />
                    <path
                      id="Path_2785"
                      data-name="Path 2785"
                      d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                      transform="translate(-4.21 -4.86)"
                      fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex align-center justify-center">
              <v-btn
                :loading="btn_loading"
                color="primary"
                width="255"
                @click="calculate"
                >احسب</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="fundResult.length > 0">
              <v-toolbar color="#0F5B94" dark>
                <v-spacer />
                <v-btn
                  @click="showBanks = !showBanks"
                  class="font-weight-bold"
                  depressed
                  color="#0F5B94"
                  dark
                  >نتيجة حاسبة التمويل</v-btn
                >
                <v-spacer />
              </v-toolbar>
              <!-- <div class="bank-container mt-4" v-if="showBanks"> -->
              <div class="bank-container" style="background: #0E5A94;     padding: 25px;" v-if="showBanks">
                <div class="bank-names">
                  <v-btn
                    color="#E0F2FE"
                    v-for="(bank, i) in fundResult"
                    :key="i"
                    class="ma-2"
                    dark
                    style="
                      padding: 16px;
                      max-height: unset;
                      height: unset;
                      font-size: 18px;
                      background-color: #E0F2FE;
                      color: #043D6A;
                      font-weight: bold;
                      border: 1px solid #6A9DC2;
                    "
                    @click="showBankProgramms(i)"
                    >{{ bank.bank_name }}</v-btn
                  >
                  <!-- <div class="bank-names">
                  <v-btn
                    color="#451646"
                    color="#3B5DE7"
                    v-for="(bank, i) in fundResult"
                    :key="i"
                    class="ma-2"
                    dark
                    style="
                      padding: 18px;
                      max-height: unset;
                      height: unset;
                      background: #0f5b94;
                      font-weight: 700;
                    "
                    @click="showBankProgramms(i)"
                    >{{ bank.bank_name }}</v-btn
                  > -->
                </div>
                <div class="programms">
                  <span v-for="(bank, i) in fundResult" :key="i">
                    <div v-if="bank.show_programs" class="expands-div">
                      <v-expansion-panels
                        class="mb-5 py-0"
                        v-for="(programm, prog_key, index) in bank.programs"
                        :key="index"
                      >
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            {{
                              prog_key == "flexibleProgram"
                                ? "مرن 2x1"
                                : prog_key == "propertyProgram"
                                ? "عقارى"
                                : prog_key == "extendedProgram"
                                ? "ممتد"
                                : "شخصى"
                            }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <!--Personal Programm-->
                            <div
                              class="prog-spans"
                              v-if="prog_key == 'personalProgram'"
                            >
                              <span class="mx-2">
                                صافي مبلغ التمويل الشخصي :{{
                                  numberWithCommas(
                                    programm.personal_net_loan_total
                                  )
                                }}
                                ريال
                              </span>
                              <span class="mx-2"
                                >مدة التمويل بالسنوات (شخصي) :{{
                                  programm.personal_funding_years
                                }}
                                سنة
                              </span>
                              <span class="mx-2"
                                >مدة التمويل بالاشهر (شخصي) :
                                {{ programm.personal_funding_months }} شهر
                              </span>
                              <span class="mx-2"
                                >القسط الشهري (شخصي) :{{
                                  programm.personal_installment
                                }}
                                ريال
                              </span>
                              <span class="mx-2"
                                >هامش الربح (شخصي) :
                                {{ programm.personal_profit_margin }} ريال
                              </span>
                            </div>
                            <!--Flexible Program-->
                            <div
                              class="prog-spans"
                              v-if="prog_key == 'flexibleProgram'"
                            >
                              <span class="mx-2">
                                صافي مبلغ التمويل :{{
                                  numberWithCommas(programm.net_loan_total)
                                }}
                                ريال
                              </span>
                              <span class="mx-2">
                                صافي مبلغ التمويل الشخصى :
                                {{
                                  numberWithCommas(
                                    programm.personal_net_loan_total
                                  )
                                }}
                                ريال
                              </span>
                              <span class="mx-2">
                                التمويل العقاري (مرن) :
                                {{
                                  numberWithCommas(programm.flexible_loan_total)
                                }}
                                ريال
                              </span>
                              <span class="mx-2">
                                القسط الشهري:
                                {{ numberWithCommas(programm.installment) }}
                                ريال
                              </span>
                              <span class="mx-2">
                                القسط الشهري بعد الدعم :
                                {{
                                  numberWithCommas(
                                    programm.installment_after_support
                                  )
                                }}
                                ريال
                              </span>
                              <span class="mx-2">
                                قسط الدعم :
                                {{ programm.support_installment }}
                                ريال
                              </span>
                              <span class="mx-2">
                                مدة التمويل بالسنوات :
                                {{ programm.funding_years }}
                                سنة
                              </span>
                              <span class="mx-2">
                                مدة التمويل بالاشهر :
                                {{ programm.funding_months }}
                                شهر
                              </span>
                              <span class="mx-2">
                                قيمة الشيك :
                                {{ numberWithCommas(programm.check_total) }}
                                ريال
                              </span>
                              <span class="mx-2">
                                ضريبة الشراء :
                                {{ programm.purchase_tax }}
                                ريال
                              </span>
                              <span class="mx-2">
                                القسط الشهري (مرن):
                                {{
                                  numberWithCommas(
                                    programm.flexible_installment
                                  )
                                }}
                                ريال
                              </span>
                              <span class="mx-2">
                                القسط الشهري (عقاري-مرن):
                                {{
                                  numberWithCommas(
                                    programm.property_flexible_installment
                                  )
                                }}
                                ريال
                              </span>
                              <span class="mx-2">
                                عمر العميل:
                                {{ Math.floor(programm.age) }}
                                سنة
                              </span>
                              <span class="mx-2">
                                إجمالي الأرباح:
                                {{
                                  numberWithCommas(programm.loan_total_profits)
                                }}
                                ريال
                              </span>
                              <span class="mx-2">
                                الدفعة الاولي:
                                {{ numberWithCommas(programm.first_batch) }}
                                ريال
                              </span>
                              <!-- قيمة السعي -->
                              <span class="mx-2">
                                صافي المالك:
                                {{ programm.property_amount }}
                                ريال
                              </span>
                              <!-- مبلغ الزيادة -->
                              <span class="mx-2">
                                الحد الادني للتمويل:
                                {{ programm.min_net_loan_total }}
                                ريال
                              </span>
                              <span class="mx-2">
                                نسبة المرابحة:
                                {{ programm.profit }}%
                              </span>
                              <div
                                style="
                                  width: 100%;
                                  text-align: center;
                                  margin-top: 27px;
                                "
                              >
                                <v-btn
                                  :loading="choose_loading"
                                  @click="
                                    selectProgramm(bank, programm, prog_key)
                                  "
                                  style="
                                    width: 200px;
                                    height: 57px;
                                    font-size: 18px;
                                    font-weight: 700;
                                    background: #4dc14d;
                                    color: white;
                                  "
                                  >اختر البرنامج
                                  <v-icon
                                    style="
                                      font-size: 32px;
                                      margin-right: 10px;
                                      margin-top: 5px;
                                    "
                                    >mdi-check</v-icon
                                  ></v-btn
                                >
                              </div>
                            </div>
                            <!--Property Programm-->
                            <div
                              class="prog-spans"
                              v-if="prog_key == 'extendedProgram'"
                            >
                              <span class="mx-2">
                                صافي مبلغ التمويل :{{
                                  numberWithCommas(programm.net_loan_total)
                                }}
                                ريال
                              </span>
                              <span class="mx-2">
                                صافي مبلغ التمويل الشخصى :
                                {{
                                  numberWithCommas(
                                    programm.personal_net_loan_total
                                  )
                                }}
                                ريال
                              </span>
                              <span class="mx-2">
                                التمويل العقاري (مرن) :
                                {{
                                  numberWithCommas(programm.flexible_loan_total)
                                }}
                                ريال
                              </span>
                              <span class="mx-2">
                                القسط الشهري:
                                {{ numberWithCommas(programm.installment) }}
                                ريال
                              </span>
                              <span class="mx-2">
                                القسط الشهري بعد الدعم :
                                {{
                                  numberWithCommas(
                                    programm.installment_after_support
                                  )
                                }}
                                ريال
                              </span>
                              <span class="mx-2">
                                قسط الدعم :
                                {{ programm.support_installment }}
                                ريال
                              </span>
                              <span class="mx-2">
                                مدة التمويل بالسنوات :
                                {{ programm.funding_years }}
                                سنة
                              </span>
                              <span class="mx-2">
                                مدة التمويل بالاشهر :
                                {{ programm.funding_months }}
                                شهر
                              </span>
                              <span class="mx-2">
                                قيمة الشيك :
                                {{ numberWithCommas(programm.check_total) }}
                                ريال
                              </span>
                              <span class="mx-2">
                                ضريبة الشراء :
                                {{ programm.purchase_tax }}
                                ريال
                              </span>
                              <span class="mx-2">
                                القسط الشهري (مرن):
                                {{
                                  numberWithCommas(
                                    programm.flexible_installment
                                  )
                                }}
                                ريال
                              </span>
                              <span class="mx-2">
                                القسط الشهري (عقاري-مرن):
                                {{
                                  numberWithCommas(
                                    programm.property_flexible_installment
                                  )
                                }}
                                ريال
                              </span>
                              <span class="mx-2">
                                عمر العميل:
                                {{ Math.floor(programm.age) }}
                                سنة
                              </span>
                              <span class="mx-2">
                                إجمالي الأرباح:
                                {{
                                  numberWithCommas(programm.loan_total_profits)
                                }}
                                ريال
                              </span>
                              <span class="mx-2">
                                الدفعة الاولي:
                                {{ numberWithCommas(programm.first_batch) }}
                                ريال
                              </span>
                              <!-- قيمة السعي -->
                              <span class="mx-2">
                                صافي المالك:
                                {{ programm.property_amount }}
                                ريال
                              </span>
                              <!-- مبلغ الزيادة -->
                              <span class="mx-2">
                                الحد الادني للتمويل:
                                {{ programm.min_net_loan_total }}
                                ريال
                              </span>
                              <span class="mx-2">
                                نسبة المرابحة:
                                {{ programm.profit }}%
                              </span>
                              <div
                                style="
                                  width: 100%;
                                  text-align: center;
                                  margin-top: 27px;
                                "
                              >
                                <v-btn
                                  :loading="choose_loading"
                                  @click="
                                    selectProgramm(bank, programm, prog_key)
                                  "
                                  style="
                                    width: 200px;
                                    height: 57px;
                                    font-size: 18px;
                                    font-weight: 700;
                                    background: #4dc14d;
                                    color: white;
                                  "
                                  >اختر البرنامج
                                  <v-icon
                                    style="
                                      font-size: 32px;
                                      margin-right: 10px;
                                      margin-top: 5px;
                                    "
                                    >mdi-check</v-icon
                                  ></v-btn
                                >
                              </div>
                            </div>
                            <!--Extended Programm-->
                            <div
                              class="prog-spans"
                              v-if="prog_key == 'propertyProgram'"
                            >
                              <span class="mx-2">
                                صافي مبلغ التمويل :{{
                                  numberWithCommas(programm.net_loan_total)
                                }}
                                ريال
                              </span>
                              <span class="mx-2">
                                القسط الشهري:
                                {{ numberWithCommas(programm.installment) }}
                                ريال
                              </span>
                              <span class="mx-2">
                                القسط الشهري بعد الدعم :
                                {{
                                  numberWithCommas(
                                    programm.installment_after_support
                                  )
                                }}
                                ريال
                              </span>
                              <span class="mx-2">
                                قسط الدعم :
                                {{ programm.support_installment }}
                                ريال
                              </span>
                              <span class="mx-2">
                                مدة التمويل بالسنوات :
                                {{ programm.funding_years }}
                                سنة
                              </span>
                              <span class="mx-2">
                                قيمة الشيك :
                                {{ numberWithCommas(programm.check_total) }}
                                ريال
                              </span>
                              <span class="mx-2">
                                ضريبة الشراء :
                                {{ programm.purchase_tax }}
                                ريال
                              </span>
                              <span class="mx-2">
                                إجمالي الأرباح:
                                {{
                                  numberWithCommas(programm.loan_total_profits)
                                }}
                                ريال
                              </span>
                              <span class="mx-2">
                                الدفعة الاولي:
                                {{ numberWithCommas(programm.first_batch) }}
                                ريال
                              </span>
                              <!-- قيمة السعي -->
                              <span class="mx-2">
                                صافي المالك:
                                {{ programm.property_amount }}
                                ريال
                              </span>
                              <!-- مبلغ الزيادة -->
                              <span class="mx-2">
                                نسبة المرابحة:
                                {{ programm.profit }}%
                              </span>
                              <div
                                style="
                                  width: 100%;
                                  text-align: center;
                                  margin-top: 27px;
                                "
                              >
                                <v-btn
                                  @click="
                                    selectProgramm(bank, programm, prog_key)
                                  "
                                  style="
                                    width: 200px;
                                    height: 57px;
                                    font-size: 18px;
                                    font-weight: 700;
                                    background: #4dc14d;
                                    color: white;
                                  "
                                  >اختر البرنامج
                                  <v-icon
                                    style="
                                      font-size: 32px;
                                      margin-right: 10px;
                                      margin-top: 5px;
                                    "
                                    >mdi-check</v-icon
                                  ></v-btn
                                >
                              </div>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </div>
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="GHResult.length > 0">
              <v-toolbar color="#0F5B94" dark>
                <v-spacer />
                <v-btn
                  @click="showGHBanks = !showGHBanks"
                  class="font-weight-bold"
                  depressed
                  color="#0F5B94"
                  dark
                  >نتيجة حاسبة التمويل Google Hasba</v-btn
                >
                <v-spacer />
              </v-toolbar>
              <!-- <div class="bank-container mt-4" v-if="showBanks"> -->
              <div class="bank-container" style="background: #0E5A94;     padding: 25px;" v-if="showGHBanks">
                <div class="bank-names">
                  <v-btn
                    color="#E0F2FE"
                    v-for="(GHbank, i) in GHResult"
                    :key="i"
                    class="ma-2"
                    dark
                    style="
                      padding: 16px;
                      max-height: unset;
                      height: unset;
                      font-size: 18px;
                      background-color: #E0F2FE;
                      color: #043D6A;
                      font-weight: bold;
                      border: 1px solid #6A9DC2;
                    "
                    @click="showGHBankProgramms(i)"
                    >{{ GHbank.bank }}</v-btn
                  >
                  <!-- <div class="bank-names">
                  <v-btn
                    color="#451646"
                    color="#3B5DE7"
                    v-for="(bank, i) in fundResult"
                    :key="i"
                    class="ma-2"
                    dark
                    style="
                      padding: 18px;
                      max-height: unset;
                      height: unset;
                      background: #0f5b94;
                      font-weight: 700;
                    "
                    @click="showBankProgramms(i)"
                    >{{ bank.bank_name }}</v-btn
                  > -->
                </div>
                <div class="programms">
                  <span v-for="(item, ii) in GHResult" :key="ii">
                    <div v-if="item.show_GH_programs" class="expands-div">
                      <v-expansion-panels
                        class="mb-5 py-0"
                      >
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            {{
                              item.type
                            }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <!--Personal Programm-->
                            <div
                              class="prog-spans"
                            >
                              <span v-for="(property, index2) in item.items" :key="index2" class="mx-2">
                                {{ property }}
                              </span>
                            </div>
                            <!--Flexible Program-->
                            
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            {{
                              item.type2
                            }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <!--Personal Programm-->
                            <div
                              class="prog-spans"
                            >
                              <span v-for="(property, index2) in item.items2" :key="index2" class="mx-2">
                                {{ property }}
                              </span>
                            </div>
                            <!--Flexible Program-->
                            
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            {{
                              item.type3
                            }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <!--Personal Programm-->
                            <div
                              class="prog-spans"
                            >
                              <span v-for="(property, index2) in item.items3" :key="index2" class="mx-2">
                                {{ property }}
                              </span>
                            </div>
                            <!--Flexible Program-->
                            
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                      </v-expansion-panels>
                    </div>
                  </span>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-sheet>
    </template>
  </page-component>
</template>

<script>
import Axios from "axios";
export default {
  props: ["agent"],
  data() {
    return {
      choose_loading: false,
      btn_loading: false,
      show_date: false,
      show_date_2: false,
      payload: {
        product_code: null,
        work_source: null,
        military_rank: undefined,
        salary: null,
        birth_date_hijri: null,
        basic_salary: null,
        salary_source: null,
        hiring_date: null,
        residential_support: null,
        add_support_installment_to_salary: 0,
        guarantees: null,
        personal_salary_deduction: null,
        personal_funding_months: null,
        deduction_percentage: 0,
        salary_deduction: null,
        funding_months: null,
        property_amount: null,
        property_completed: null,
        residence_type: null,
        has_obligations: 0,
        remaining_obligations_months: null,
        obligations_installment: null,
        early_repayment: null,
        funding_source: null,
        has_joint: 0,
        joint_work: null,
        joint_military_rank: null,
        joint_birth_hijri: undefined,
        joint_salary: null,
        joint_has_obligations: 0,
        joint_credit_installment: null,
        discount: null,
        provide_first_batch: 0,
        first_batch_profit: null,
        first_batch_percentage: null,
        fees: null,
        sahal: 0,
        housing_allowance: null,
        transfer_allowance: null,
        other_allowance: null,
        retirement_income: null,
        joint_remaining_obligations_months: null,
        joint_obligations_installment: null,
        joint_early_repayment: null,
        joint_housing_allowance: null,
        joint_transfer_allowance: null,
        joint_other_allowance: null,
        joint_retirement_income: null,
        joint_residential_support: null,
        joint_add_support_installment_to_salary: 0,
        joint_job_tenure_months: null,
        joint_personal_salary_deduction: null,
        joint_personal_funding_months: null,
        joint_funding_months: null,
        joint_salary_deduction: null,
        first_batch_mode: null,
        joint_job_tenure: null,
        joint_salary_source: null,
      },
      fundResult: [],
      GHResult: [],
      showBanks: false,
      showGHBanks: false,
    };
  },
  async created() {
    await this.setBreadCrumbs(["الرئيسية", "حاسبة التمويل"]);
  },
  methods: {
    calculate() {
      this.btn_loading = true;
      Axios.post(
        "https://appproduct.alwsata.com.sa/api/agent/Request/Funding/Calculator",
        this.payload,
        { headers: this.request_headers() }
      )
        .then((res) => {
          res.data.payload.result.forEach((bank) => {
            bank.show_programs = false;
          });
          this.fundResult = res.data.payload.result;
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          // this.btn_loading = false;
        });
      Axios.post(
        "https://appproduct.alwsata.com.sa/api/agent/Request/Funding/GoogleCalculator",
        this.payload,
        { headers: this.request_headers() }
      )
        .then((res) => {
          res.data.payload.forEach((bank) => {
            bank.show_GH_programs = false;
          });
          let BankList = res.data.payload;
          this.GHResult = BankList;
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          this.btn_loading = false;
        });
    },
    showBankProgramms(index) {
      this.fundResult.forEach((bank) => (bank.show_programs = false));
      this.fundResult[index].show_programs = true;
    },
    showGHBankProgramms(index) {
      this.GHResult.forEach((bank) => (bank.show_GH_programs = false));
      this.GHResult[index].show_GH_programs = true;
    },
    numberWithCommas(num) {
      return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    selectProgramm(bank, program, prorgam_name) {
      this.choose_loading = true;
      const payload = {
        program_name: prorgam_name == "flexibleProgram" ? "1x2 مرن" : "عقارى",
        request_id: this.$route.params.id,
        work: this.payload.work_source,
        military_rank: this.payload.military_rank,
        funding_source: this.payload.funding_source,
        birth_hijri: this.payload.birth_date_hijri,
        hiring_date: this.payload.hiring_date,
        has_obligations: this.payload.has_obligations,
        salary: program.salary,
        product_type_id: this.payload.product_code,
        bank_code: bank.bank_code,
        basic_salary: program.basic_salary,
        salary_bank_code: bank.bank_code,
        without_transfer_salary: program.is_salary_transfer ? 1 : 0,
        housing_allowance: program.housing_allowance,
        transfer_allowance: program.transfer_allowance,
        other_allowance: program.other_allowance,
        retirement_income: program.retirement_income,
        discount: program.discount,
        provide_first_batch: this.payload.provide_first_batch,
        first_batch_profit: program.first_batch_profit,
        first_batch_percentage: program.first_batch_percentage,
        fees: program.fees,
        job_tenure_months: program.job_tenure_months,
        personal_profit: program.personal_profit,
        profit: program.profit,
        net_loan_total: program.net_loan_total,
        personal_net_loan_total: program.personal_net_loan_total,
        personal_installment: program.personal_installment,
        installment: program.installment,
        installment_after_support: program.installment_after_support,
        credit_installment: program.credit_installment,
        obligations_installment: program.obligations_installment,
        remaining_obligations_months: program.remaining_obligations_months,
        property_amount: program.property_amount,
        residential_support: this.payload.residential_support,
        add_support_installment_to_salary:
          this.payload.add_support_installment_to_salary,
        guarantees: this.payload.guarantees,
        personal_salary_deduction: program.personal_salary_deduction,
        personal_funding_months: program.personal_funding_months,
        salary_deduction: program.salary_deduction,
        funding_months: program.funding_months,
        residence_type: program.residence_type,
        flexible_loan_total: program.flexible_loan_total,
        early_repayment: program.early_repayment,
        first_batch_mode: program.first_batch_mode,
        have_joint: this.payload.has_joint,
        joint_work: this.payload.joint_work,
        joint_military_rank: this.payload.joint_military_rank,
        joint_birth_hijri: this.payload.joint_birth_hijri,
        joint_salary: this.payload.joint_salary,
        joint_credit_installment: this.payload.joint_credit_installment,
        joint_remaining_obligations_months:
          this.payload.joint_remaining_obligations_months,
        joint_obligations_installment:
          this.payload.joint_obligations_installment,
        joint_early_repayment: this.payload.joint_early_repayment,
        joint_housing_allowance: this.payload.joint_housing_allowance,
        joint_transfer_allowance: this.payload.joint_transfer_allowance,
        joint_other_allowance: this.payload.joint_other_allowance,
        joint_retirement_income: this.payload.joint_retirement_income,
        joint_residential_support: this.payload.joint_residential_support,
        joint_add_support_installment_to_salary:
          this.payload.joint_add_support_installment_to_salary,
        joint_job_tenure: this.payload.joint_job_tenure,
        joint_job_tenure_months: this.payload.joint_job_tenure_months,
        joint_personal_salary_deduction:
          this.payload.joint_personal_salary_deduction,
        joint_personal_funding_months:
          this.payload.joint_personal_funding_months,
        joint_funding_months: this.payload.joint_funding_months,
        joint_salary_deduction: this.payload.joint_salary_deduction,
      };
      Axios.post(
        "https://appproduct.alwsata.com.sa/api/agent/Request/Funding/calculatorSelect",
        payload,
        { headers: this.request_headers() }
      )
        .then(() => {
          this.showPopup("success", "تم اختيار نتيجة الحاسبة بنجاح");
          this.fundResult = [];
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          this.choose_loading = false;
        });
    },
  },

  watch: {
    "$store.state.rates_in_admin_count": {
      handler(val) {
        if (val > this.$store.state.redirect_count_of_rates && localStorage["role"] == "1") {
          this.$store.state.showAlertOfRatingDidntProccess = true;
          this.$router.push({ name: "salesManager_SMRateServices" });
        }
      },
      immediate: true 
    }
  },
  
};
</script>
<style scoped lang="scss">
.expands-div {
  background: #0f5b94;
  padding: 1px 15px;
  margin-top: 20px;
}
.v-item-group {
  .v-expansion-panel-header {
    background: #fff !important;
    min-height: unset !important;
    line-height: 0;
    font-weight: 700;
    color: #0f5b94;
    font-size: 17px;
    padding: 15px;
  }
  .v-expansion-panel-content {
    background: #0f5b94;
  }
  .prog-spans {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    flex-wrap: wrap;
    margin-top: 15px;
    span {
      color: #003d6b;
      font-weight: bold;
      font-size: 17px;
      background: #e1f2ff;
      padding: 13px;
      border-radius: 5px;
    }
  }
}
</style>
