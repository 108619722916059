<template>
  <v-card
    @click="$emit('getCardId', item.id)"
    class="pa-3 position-relative"
    style="
      height: 130px;
      border: 1px solid #d4d5d5;
      border-radius: 8px !important;
      overflow: hidden;
    "
  >
    <span
      class="right-color card__border"
      :style="`${
        returnStatusReq(item) === 0
          ? `background-color: #017F85`
          : returnStatusReq(item) === 1
          ? `background-color: #41BF72`
          : returnStatusReq(item) === 2
          ? `background-color: #DEB523`
          : `background-color: #FE705B`
      } `"
    >
    </span>

    <div class="req-status-parent d-flex justify-space-between align-center">
      <span class="req-title">
        {{ item.customer?.name || item.request?.customer?.name }}
      </span>
      <span class="req-date">
        {{ item.agent_date !== undefined ? item.agent_date.split(" ")[0] : "" }}
      </span>
    </div>

    <div class="req-status-parent d-flex justify-space-between align-center">
      <v-tooltip bottom color="#3F0E40">
        <template v-slot:activator="{ on }">
          <v-card-text v-on="on" class="pt-2 px-0">
            <span style="margin-left: -10px; font-weight: bold; color: #3f0e40">
              {{
                item.type
                  ? item.type.length > 5
                    ? item.type.substring(0, 5) + ".."
                    : item.type
                  : item.request?.type
                  ? item.request?.type.length > 5
                    ? item.request?.type.substring(0, 5) + ".."
                    : item.request?.type
                  : "لايوجد"
              }}</span
            >
          </v-card-text>
        </template>
        <div style="font-size: 14px; background-color: #451646; color: white">
          {{ item.type || item.request?.type || "لايوجد نوع للطلب" }}
        </div>
      </v-tooltip>

      <v-tooltip bottom color="#451646">
        <template v-slot:activator="{ on }">
          <v-card-text v-on="on" class="pt-2 px-0">
            <div
              :style="`${
                returnStatusReq(item) === 0
                  ? `color: #017F85; border: 1px solid #017F85 `
                  : returnStatusReq(item) === 1
                  ? `color: #41BF72; border: 1px solid #41BF72`
                  : returnStatusReq(item) === 2
                  ? `color: #DEB523; border: 1px solid #DEB523`
                  : `color: #FE705B; border: 1px solid #FE705B`
              }; padding: 1px 4px 6px;
              border-radius: 4px;
              display: flex;
              justify-content: center;
              align-items: center; margin-left: 23px; margin-right: -31px;font-size: 15px`"
            >
              {{
                item.class_id_agent_text
                  ? item.class_id_agent_text.length > 14
                    ? item.class_id_agent_text.substring(0, 11) + ".."
                    : item.class_id_agent_text
                  : item.request?.class_id_agent_text
                  ? item.request?.class_id_agent_text.length > 14
                    ? item.request?.class_id_agent_text.substring(0, 11) + "..."
                    : item.request?.class_id_agent_text
                  : "لايوجد"
              }}
            </div>
          </v-card-text>
        </template>
        <div style="font-size: 14px; background-color: #451646; color: white">
          {{
            item.class_id_agent_text ||
            item.request?.class_id_agent_text ||
            "لايوجد تصنيف معاملة"
          }}
        </div>
      </v-tooltip>

      <v-tooltip bottom color="#451646">
        <template v-slot:activator="{ on }">
          <v-card-text v-on="on" class="pt-2 px-0">
            <span
              :style="`${
                returnStatusReq(item) === 0
                  ? `background-color: #017F85; border: 1px solid #017F85`
                  : returnStatusReq(item) === 1
                  ? `background-color: #41BF72; border: 1px solid #41BF72`
                  : returnStatusReq(item) === 2
                  ? `background-color: #DEB523 ; border: 1px solid #DEB523`
                  : `background-color: #FE705B ; border: 1px solid #FE705B`
              }; color: white; padding: 1px 4px 6px;
              border-radius: 4px;
              display: flex;
              justify-content: center;
              align-items: center; font-size: 15px; margin-right: -6px;`"
            >
              {{
                item.status
                  ? item.status.length > 11
                    ? item.status.substring(0, 8) + ".."
                    : item.status
                  : "لايوجد"
              }}
            </span>
          </v-card-text>
        </template>
        <div style="font-size: 14px; background-color: #451646; color: white">
          {{ item.status }}
        </div>
      </v-tooltip>
    </div>

    <hr style="border: 1px solid #000000; opacity: 0.1" class="mb-0" />
    <div class="desc parent">
      <v-tooltip bottom color="#3F0E40">
        <template v-slot:activator="{ on }">
          <v-card-text v-on="on" class="pt-2 px-0">
            {{
              item.agent_notes
                ? item.agent_notes.length > 30
                  ? item.agent_notes.substring(0, 30) + "..."
                  : item.agent_notes
                : item.request?.agent_notes
                ? item.request.agent_notes.length > 30
                  ? item.request.agent_notes.substring(0, 30) + "..."
                  : item.request.agent_notes
                : "لا يوجد ملاحظات"
            }}
          </v-card-text>
        </template>
        <div style="font-size: 14px; background-color: #3f0e40; color: white">
          {{ item.agent_notes || item.request?.agent_notes || "لا يوجد ملاحظات" }}
        </div>
      </v-tooltip>
    </div>
  </v-card>
</template>

<script setup>
import { defineProps } from "vue";
const props = defineProps(["item"]);

const returnStatusReq = (item) => {
  return item.statusReq;
};

const returnStatus = (item) => {
  return item.status;
};
</script>
