<template>
  <v-row justify="center">
    <v-dialog v-model="$store.state.openAdministrationDialog" width="524">
      <v-card>
        <v-card-title>
          <div>إرسال الطلب للادارة</div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-card-title> الملاحظة </v-card-title>
                <v-card-text>
                  <v-textarea
                    :error="move_reason_required"
                    :error-messages="error_msgs"
                    auto-grow
                    variant="outlined"
                    rows="3"
                    row-height="25"
                    v-model="move_reason"
                    color="#000"
                    shaped
                ></v-textarea>
              </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="$store.state.openAdministrationDialog = false"
          >
            الغاء
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="handleSend"> ارسال </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["items", "label"],
  data: () => ({
    move_reason_required: false,
    error_msgs: [],
    selectedValue: "",
    move_reason: "",
  }),
  watch:{
    
    move_reason(val) {
      if (val.trim().length > 0) {
        this.move_reason_required = false;
        this.error_msgs = [];
      }else{
        this.move_reason_required = true;
      }
    }
  },
  methods: {
    handleSend() {
      
      if (this.move_reason.trim().length == 0) {
        this.move_reason_required = true;
        this.error_msgs = ['الملاحظة مطلوبة'];
        return;
      }else{
        this.move_reason_required = false;
        this.error_msgs = [];

      }
      this.$store.state.openAdministrationDialog = false;
      this.$emit("reload");
      this.$emit("selectedAdministrationValue", this.move_reason);
      this.move_reason = '';
      this.selectedValue = '';
    },
  },
};
</script>
<style>
textarea{
  color:#000 !important;
}
</style>