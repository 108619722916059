import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './routes'
import {store} from './store'
import'./assets/font.ttf'
import DrawerComponent from '@/components/Base/DrawerComponent.vue'
import BreadcrumbsComponent from '@/components/Base/BreadcrumbsComponent.vue'
import TableComponent from '@/components/Base/TableComponent.vue'
import SecondaryTableComponent from '@/components/Base/SecondaryTableComponent.vue'
import NewTableComponent from '@/components/Base/NewTableComponent.vue'
import AppBar from '@/components/Base/AppBar.vue'
import { PageComponentMixin } from '@/mixins/page'
import PageComponent from '@/components/Base/PageComponent.vue'
import Popup from '@/components/Base/Popup.vue'
import PopupError from '@/components/Base/PopupError.vue'
import PopupErrorBtnComponent from '@/components/Base/PopupWithBtn.vue'
import AnnouncementAlert from '@/components/Base/AnnouncementAlert.vue'
import PageSection from '@/components/Base/PageSection.vue'
import DialogComponent from '@/components/Base/DialogComponent'
import FieldLogDialog from '@/components/Dialogs/FieldLogDialog'
import AddNewRequest from '@/components/Dialogs/AddNewRequest.vue'
import Vuex from 'vuex'
import Router from 'vue-router'
import VueDatetimeJs from 'vue-datetime-js'
import VHijriDatePicker from 'vuetify-umalqura'
import VueChatScroll from 'vue-chat-scroll'
import FilterAllRequests from '@/components/Dialogs/FilterAllRequests.vue'
import FilterQualityAllRequests from '@/components/Dialogs/FilterQualityAllRequests.vue'
import SearchResult from '@/components/Base/SearchResult'
import AgentChat from '@/components/AgentChat/AgentChat.vue'
import numericOnlyDirective from '@/mixins/numericOnly'
import decimalOnlyDirective from '@/mixins/decimalOnly'


// import VuetifyDaterangePicker from "vuetify-daterange-picker";
// import "vuetify-daterange-picker/dist/vuetify-daterange-picker.css";



Router.prototype.$store = store;

Vue.use(Vuex)
Vue.use(VueChatScroll)
// Vue.use(VuetifyDaterangePicker)
Vue.component('nav-drawer', DrawerComponent)
Vue.component('agent-chat', AgentChat )
Vue.component('breadcrumbs-component', BreadcrumbsComponent)
Vue.component('page-component', PageComponent)
Vue.component('table-component', TableComponent)
Vue.component('secondary-table-component', SecondaryTableComponent)
Vue.component('new-table-component', NewTableComponent)
Vue.component('c-dialog', DialogComponent)
Vue.component('app-bar', AppBar)
Vue.component('popup-component', Popup)
Vue.component('popup-error-component', PopupError)
Vue.component('popup-error-btn-component', PopupErrorBtnComponent)
Vue.component('announcement-alert-component', AnnouncementAlert)
Vue.component('page-section', PageSection)
Vue.component('date-picker', VueDatetimeJs)
Vue.component('request-log', FieldLogDialog)
Vue.component('add-new-request', AddNewRequest)
Vue.component('search-result', SearchResult)
Vue.component('filter-all', FilterAllRequests)
Vue.component('filter-quality', FilterQualityAllRequests)
// Vue.component('filter-rate', FilterRates)
Vue.component(VHijriDatePicker.name, VHijriDatePicker)
Vue.directive('numericOnly', numericOnlyDirective);
Vue.directive('decimalOnly', decimalOnlyDirective);
Vue.mixin(PageComponentMixin)


new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
