var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"pa-3 position-relative",staticStyle:{"height":"130px","border":"1px solid #d4d5d5","border-radius":"8px !important","overflow":"hidden"},on:{"click":function($event){return _vm.$emit('getCardId', _vm.item.id)}}},[_c('span',{staticClass:"right-color card__border",style:(`${
      _setup.returnStatusReq(_vm.item) === 0
        ? `background-color: #017F85`
        : _setup.returnStatusReq(_vm.item) === 1
        ? `background-color: #41BF72`
        : _setup.returnStatusReq(_vm.item) === 2
        ? `background-color: #DEB523`
        : `background-color: #FE705B`
    } `)}),_c('div',{staticClass:"req-status-parent d-flex justify-space-between align-center"},[_c('span',{staticClass:"req-title"},[_vm._v(" "+_vm._s(_vm.item.customer?.name || _vm.item.request?.customer?.name)+" ")]),_c('span',{staticClass:"req-date"},[_vm._v(" "+_vm._s(_vm.item.agent_date !== undefined ? _vm.item.agent_date.split(" ")[0] : "")+" ")])]),_c('div',{staticClass:"req-status-parent d-flex justify-space-between align-center"},[_c('v-tooltip',{attrs:{"bottom":"","color":"#3F0E40"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-card-text',_vm._g({staticClass:"pt-2 px-0"},on),[_c('span',{staticStyle:{"margin-left":"-10px","font-weight":"bold","color":"#3f0e40"}},[_vm._v(" "+_vm._s(_vm.item.type ? _vm.item.type.length > 5 ? _vm.item.type.substring(0, 5) + ".." : _vm.item.type : _vm.item.request?.type ? _vm.item.request?.type.length > 5 ? _vm.item.request?.type.substring(0, 5) + ".." : _vm.item.request?.type : "لايوجد"))])])]}}])},[_c('div',{staticStyle:{"font-size":"14px","background-color":"#451646","color":"white"}},[_vm._v(" "+_vm._s(_vm.item.type || _vm.item.request?.type || "لايوجد نوع للطلب")+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"#451646"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-card-text',_vm._g({staticClass:"pt-2 px-0"},on),[_c('div',{style:(`${
              _setup.returnStatusReq(_vm.item) === 0
                ? `color: #017F85; border: 1px solid #017F85 `
                : _setup.returnStatusReq(_vm.item) === 1
                ? `color: #41BF72; border: 1px solid #41BF72`
                : _setup.returnStatusReq(_vm.item) === 2
                ? `color: #DEB523; border: 1px solid #DEB523`
                : `color: #FE705B; border: 1px solid #FE705B`
            }; padding: 1px 4px 6px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center; margin-left: 23px; margin-right: -31px;font-size: 15px`)},[_vm._v(" "+_vm._s(_vm.item.class_id_agent_text ? _vm.item.class_id_agent_text.length > 14 ? _vm.item.class_id_agent_text.substring(0, 11) + ".." : _vm.item.class_id_agent_text : _vm.item.request?.class_id_agent_text ? _vm.item.request?.class_id_agent_text.length > 14 ? _vm.item.request?.class_id_agent_text.substring(0, 11) + "..." : _vm.item.request?.class_id_agent_text : "لايوجد")+" ")])])]}}])},[_c('div',{staticStyle:{"font-size":"14px","background-color":"#451646","color":"white"}},[_vm._v(" "+_vm._s(_vm.item.class_id_agent_text || _vm.item.request?.class_id_agent_text || "لايوجد تصنيف معاملة")+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"#451646"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-card-text',_vm._g({staticClass:"pt-2 px-0"},on),[_c('span',{style:(`${
              _setup.returnStatusReq(_vm.item) === 0
                ? `background-color: #017F85; border: 1px solid #017F85`
                : _setup.returnStatusReq(_vm.item) === 1
                ? `background-color: #41BF72; border: 1px solid #41BF72`
                : _setup.returnStatusReq(_vm.item) === 2
                ? `background-color: #DEB523 ; border: 1px solid #DEB523`
                : `background-color: #FE705B ; border: 1px solid #FE705B`
            }; color: white; padding: 1px 4px 6px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center; font-size: 15px; margin-right: -6px;`)},[_vm._v(" "+_vm._s(_vm.item.status ? _vm.item.status.length > 11 ? _vm.item.status.substring(0, 8) + ".." : _vm.item.status : "لايوجد")+" ")])])]}}])},[_c('div',{staticStyle:{"font-size":"14px","background-color":"#451646","color":"white"}},[_vm._v(" "+_vm._s(_vm.item.status)+" ")])])],1),_c('hr',{staticClass:"mb-0",staticStyle:{"border":"1px solid #000000","opacity":"0.1"}}),_c('div',{staticClass:"desc parent"},[_c('v-tooltip',{attrs:{"bottom":"","color":"#3F0E40"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-card-text',_vm._g({staticClass:"pt-2 px-0"},on),[_vm._v(" "+_vm._s(_vm.item.agent_notes ? _vm.item.agent_notes.length > 30 ? _vm.item.agent_notes.substring(0, 30) + "..." : _vm.item.agent_notes : _vm.item.request?.agent_notes ? _vm.item.request.agent_notes.length > 30 ? _vm.item.request.agent_notes.substring(0, 30) + "..." : _vm.item.request.agent_notes : "لا يوجد ملاحظات")+" ")])]}}])},[_c('div',{staticStyle:{"font-size":"14px","background-color":"#3f0e40","color":"white"}},[_vm._v(" "+_vm._s(_vm.item.agent_notes || _vm.item.request?.agent_notes || "لا يوجد ملاحظات")+" ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }