export const RequestCallsIcon = () => {
    return (
        <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="14.513"
            height="16.448"
            >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
                <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3 12C3 8.68629 5.68629 6 9 6C10.7598 6 12.351 6.89558 13.1964 8.24206C13.8443 9.24757 13.9999 10.467 13.613 11.5986C13.214 12.7477 12.2146 13.5365 11.05 13.8024C9.95699 14.0483 8.83343 13.7391 8 12.914V16.2929C8 16.9194 7.61304 17.4578 7 17.7445C6.38696 18.0312 5.61304 17.9442 5 17.5858L1 15.7071V18C1 18.5523 1.44772 19 2 19H7C7.55228 19 8 19.4477 8 20C8 20.5523 7.55228 21 7 21H2C0.895431 21 0 20.1046 0 19V14.5858C0 14.2091 0.210714 13.8495 0.553856 13.5949L3 12ZM5.5 12L7.35355 12.3536C7.83513 12.8351 8.1792 13.4851 8.29289 14.1716C8.40659 14.8581 8.27549 15.5731 7.94868 16.1361C7.62187 16.699 7.14264 17.0534 6.61136 17.0532C6.08009 17.0529 5.60068 16.698 5.27388 16.1349C4.94707 15.5718 4.81597 14.8568 4.92967 14.1703C5.04336 13.4838 5.38744 12.8338 5.86902 12.3523L5.5 12ZM9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V9C15 9.55228 14.5523 10 14 10C13.4477 10 13 9.55228 13 9V5.41421L11.7071 6.70711C11.3166 7.09763 10.6834 7.09763 10.2929 6.70711C9.90237 6.31658 9.90237 5.68342 10.2929 5.29289L13.2929 2.29289C13.6834 1.90237 14.3166 1.90237 14.7071 2.29289C15.0976 2.68342 15.0976 3.31658 14.7071 3.70711L11.7071 6.70711L9 4ZM22 12C22 15.3137 19.3137 18 16 18C14.2402 18 12.649 17.1044 11.8036 15.7579C11.1557 14.7524 11.0001 13.533 11.387 12.4014C11.786 11.2523 12.7854 10.4635 13.95 10.1976C15.043 9.95168 16.1666 10.2609 17 11.086V7.70711C17 7.08057 17.3869 6.54218 18 6.25549C18.613 5.9688 19.3869 6.05582 20 6.41421L24 8.29289V6C24 5.44772 23.5523 5 23 5H18C17.4477 5 17 4.55228 17 4C17 3.44772 17.4477 3 18 3H23C24.1046 3 25 3.89543 25 5V9.41421C25 9.79086 24.7893 10.1505 24.4461 10.4051L22 12Z"
                fill="#000000"
                />
            </g>
            </svg>

    )
}
