<template>
  <page-component style="position: relative" class="agent-data-page">
    <!-- eslint-disable -->
    <template #content>
      <v-form class="mt-5" :disabled="!for_agent">
        <v-card flat>
          <v-layout
            row
            wrap
            style="background-color: #f3f5f7"
            class="userProfile__container"
          >
            <v-col cols="12" lg="4" md="4" sm="6" xs="12">
              <v-card class="pa-3 position-relative userProfile__card">
                <v-col cols="12" sm="12">
                  <div style="display: flex" class="input__group">
                    <div style="display: flex; align-items: center">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('customerName')"
                      />
                      إسم&nbsp;العميل
                    </div>
                    <div class="horizontal__line"></div>
                    <div>
                      <v-text-field
                        type="text"
                        class="form-control"
                        style="
                          background-color: #fbfbfb;
                          border-right: #fbfbfb;
                          margin-top: -20px;
                        "
                        v-model="agent.customer.name"
                        ref="customerName"
                      />
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; position: relative" class="input__group">
                    <div style="display: flex; align-items: center">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('mobile')"
                      />
                      جوال&nbsp;العميل
                    </div>
                    <div class="horizontal__line"></div>
                    <div>
                      <v-text-field
                        type="text"
                        class="form-control"
                        style="
                          background-color: #fbfbfb;
                          border-right: #fbfbfb;
                          margin-top: -20px;
                        "
                        v-model="agent.customer.mobile"
                        v-numericOnly
                        :disabled="forMorPur"
                      />

                      <img
                        src="@/assets/icons/add-new.svg"
                        alt="image"
                        @click="showNums = !showNums"
                        style="
                          position: absolute;
                          left: 65px;
                          top: 9px;
                          width: 16px;
                          height: 25px;
                          z-index: 1;
                        "
                      />
                      <img
                        style="
                          cursor: pointer;
                          position: absolute;
                          left: 40px;
                          top: 10px;
                          z-index: 1;
                        "
                        @click="secondNumber = !secondNumber"
                        src="@/assets/add-num.png"
                        alt=""
                      />

                      <a :href="'https://wa.me/966' + agent.customer.mobile" style="text-decoration: none" target="_blank">
                        <img
                          style="
                            cursor: pointer;
                            position: absolute;
                            left: 10px;
                            top: 15px;
                            z-index: 1;
                          "
                          src="@/assets/whatsapp.png"
                          alt=""
                          height="20px"
                          width="20px"
                        />
                      </a>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; position: relative" class="input__group">
                    <div style="display: flex; align-items: center">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('age')"
                      />
                      العمر
                    </div>
                    <div class="horizontal__line"></div>
                    <div>
                      <v-text-field
                        type="text"
                        class="form-control"
                        style="
                          background-color: #fbfbfb;
                          border-right: #fbfbfb;
                          margin-top: -20px;
                        "
                        v-model="agent.customer.age"
                        v-numericOnly
                      />
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; position: relative" class="input__group">
                    <div style="display: flex; align-items: center">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('salary')"
                      />
                      صافي&nbsp;الراتب
                    </div>
                    <div class="horizontal__line"></div>
                    <div>
                      <v-text-field
                        type="text"
                        class="form-control"
                        style="
                          background-color: #fbfbfb;
                          border-right: #fbfbfb;
                          margin-top: -20px;
                        "
                        v-model="agent.customer.salary"
                        v-numericOnly
                      />
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; position: relative" class="input__group">
                    <div style="display: flex; align-items: center">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('basic_salary')"
                      />
                      الراتب&nbsp;الأساسي
                    </div>
                    <div class="horizontal__line"></div>
                    <div>
                      <v-text-field
                        type="text"
                        class="form-control"
                        style="
                          background-color: #fbfbfb;
                          border-right: #fbfbfb;
                          margin-top: -20px;
                        "
                        v-model="agent.customer.basic_salary"
                        v-numericOnly
                      />
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div style="display: flex">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('sex')"
                      />الجنس
                    </div>
                    <div class="horizontal__line"></div>

                    <v-autocomplete
                      :items="['أنثي', 'ذكر']"
                      v-model="sex"
                      style="margin-top: 15px"
                      outlined
                      dense
                    >
                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-bind="attrs" v-on="on">
                          <v-list-item-title
                            :id="attrs['aria-labelledby']"
                            v-text="item"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <img src="@/assets/icons/down-arrow.svg" />
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('nationality_id')"
                      />
                      الجنسية
                    </div>
                    <div class="horizontal__line"></div>

                    <v-autocomplete
                      :items="['غير سعودي', 'سعودي']"
                      v-model="selectedNationality"
                      style="margin-top: 15px"
                      outlined
                      dense
                    >
                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-bind="attrs" v-on="on">
                          <v-list-item-title
                            :id="attrs['aria-labelledby']"
                            v-text="item"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <img src="@/assets/icons/down-arrow.svg" />
                  </div>
                </v-col>


              </v-card>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12">
              <v-card class="pa-3 position-relative userProfile__card">
                <v-col cols="12" sm="12">
                  <div style="display: flex" class="input__group">
                    <div style="display: flex; align-items: center">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('agent_identity_number')"
                      />
                      رقم&nbsp;الهويه
                    </div>
                    <div class="horizontal__line"></div>
                    <div>
                      <v-text-field
                        type="text"
                        class="form-control"
                        style="
                          background-color: #fbfbfb;
                          border-right: #fbfbfb;
                          margin-top: -20px;
                        "
                        v-model="agent.agent_identity_number"
                        v-numericOnly
                      />
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="12" v-if="!chowHigriDate">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div style="display: flex; align-items: center">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('birth_hijri')"
                      />
                      تاريخ&nbsp;الميلاد
                    </div>
                    <div class="horizontal__line"></div>

                    <v-text-field
                      type="date"
                      style="margin-bottom: 5px"
                      hide-details
                      v-model="agent.customer.birth_date"
                      class="mr-1"
                      dense
                      append-icon
                    >
                      <template v-slot:append>
                        <svg
                          id="calendar"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.396"
                          height="16.989"
                          viewBox="0 0 17.396 16.989"
                          style="margin-top: 15px; cursor: pointer"
                        >
                          <path
                            id="Path_2784"
                            data-name="Path 2784"
                            d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                            transform="translate(-4.21 -7.917)"
                            fill="#6c757d"
                          />
                          <path
                            id="Path_2785"
                            data-name="Path 2785"
                            d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                            transform="translate(-4.21 -4.86)"
                            fill="#6c757d"
                          />
                        </svg>

                        <!-- <div class="horizontal__line" style="height: 47px"></div> -->
                      </template>
                    </v-text-field>

                    <!-- <v-text-field
                        type="text"
                        style="margin-bottom: 5px"
                        hide-details
                        v-model="agent.customer.birth_date_higri"
                        class="mr-1"
                        placeholder="هجري"
                        dense
                        append-icon
                        @click="showHiringBirthDate = !showHiringBirthDate"
                      >
                        <template v-slot:append>
                          <svg
                            @click="showHiringBirthDate = !showHiringBirthDate"
                            id="calendar"
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.396"
                            height="16.989"
                            viewBox="0 0 17.396 16.989"
                            style="margin-top: 15px"
                          >
                            <path
                              id="Path_2784"
                              data-name="Path 2784"
                              d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                              transform="translate(-4.21 -7.917)"
                              fill="#6c757d"
                            />
                            <path
                              id="Path_2785"
                              data-name="Path 2785"
                              d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                              transform="translate(-4.21 -4.86)"
                              fill="#6c757d"
                            />
                          </svg>
                        </template>
                      </v-text-field>
                      <v-hijri-date-picker
                        @change="showHiringBirthDate = false"
                        v-if="showHiringBirthDate"
                        style="position: absolute; z-index: 1000"
                        v-model="agent.customer.birth_date_higri"
                        locale="ar"
                      /> -->
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div style="display: flex; align-items: center">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('birth_hijri')"
                      />
                      التاريخ&nbsp;الهجري
                    </div>
                    <div class="horizontal__line"></div>

                    <v-text-field
                      type="text"
                      style="margin-bottom: 5px"
                      hide-details
                      v-model="agent.customer.birth_date_higri"
                      class="mr-1"
                      placeholder="هجري"
                      dense
                      append-icon
                      @click="showHiringBirthDate = !showHiringBirthDate"
                    >
                      <template v-slot:append>
                        <svg
                          @click="showHiringBirthDate = !showHiringBirthDate"
                          id="calendar"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.396"
                          height="16.989"
                          viewBox="0 0 17.396 16.989"
                          style="margin-top: 15px"
                        >
                          <path
                            id="Path_2784"
                            data-name="Path 2784"
                            d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                            transform="translate(-4.21 -7.917)"
                            fill="#6c757d"
                          />
                          <path
                            id="Path_2785"
                            data-name="Path 2785"
                            d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                            transform="translate(-4.21 -4.86)"
                            fill="#6c757d"
                          />
                        </svg>
                      </template>
                    </v-text-field>
                    <v-hijri-date-picker
                      @change="showHiringBirthDate = false"
                      v-if="showHiringBirthDate"
                      style="position: absolute; z-index: 1000"
                      v-model="agent.customer.birth_date_higri"
                      locale="ar"
                    />
                  </div>
                </v-col>

                <v-col cols="12" sm="12" v-if="chowHigriDate">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('birth_hijri')"
                      />
                      تاريخ&nbsp;الميلاد&nbsp;(هجري)
                    </div>
                    <div class="horizontal__line"></div>

                    <v-text-field
                      type="text"
                      style="margin-bottom: 5px"
                      hide-details
                      v-model="agent.customer.birth_date_higri"
                      class="mr-1"
                      dense
                      append-icon
                      @click="showHiringBirthDate = !showHiringBirthDate"
                    >
                      <template v-slot:append>
                        <svg
                          @click="showHiringBirthDate = !showHiringBirthDate"
                          id="calendar"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.396"
                          height="16.989"
                          viewBox="0 0 17.396 16.989"
                          style="margin-top: 15px; cursor: pointer"
                        >
                          <path
                            id="Path_2784"
                            data-name="Path 2784"
                            d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                            transform="translate(-4.21 -7.917)"
                            fill="#6c757d"
                          />
                          <path
                            id="Path_2785"
                            data-name="Path 2785"
                            d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                            transform="translate(-4.21 -4.86)"
                            fill="#6c757d"
                          />
                        </svg>
                        <img
                          src="@/assets/icons/alternative.svg"
                          alt="img"
                          style="margin-right: 10px; cursor: pointer"
                          @click="chowHigriDate = !chowHigriDate"
                        />
                      </template>
                    </v-text-field>
                    <v-hijri-date-picker
                      @change="showHiringBirthDate = false"
                      v-if="showHiringBirthDate"
                      style="position: absolute; z-index: 1000"
                      v-model="agent.customer.birth_date_higri"
                      locale="ar"
                    />
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div style="display: flex">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('joint_hiring_date')"
                      />
                      تاريخ&nbsp;التعيين
                    </div>
                    <div class="horizontal__line"></div>

                    <v-text-field
                      style="margin-bottom: 5px"
                      hide-details
                      v-model="agent.customer.hiring_date"
                      class="mr-1"
                      dense
                      append-icon
                    >
                      <template v-slot:append>
                        <svg
                          @click="showHiringDate = !showHiringDate"
                          id="calendar"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.396"
                          height="16.989"
                          viewBox="0 0 17.396 16.989"
                          style="margin-top: 15px"
                        >
                          <path
                            id="Path_2784"
                            data-name="Path 2784"
                            d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                            transform="translate(-4.21 -7.917)"
                            fill="#6c757d"
                          />
                          <path
                            id="Path_2785"
                            data-name="Path 2785"
                            d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                            transform="translate(-4.21 -4.86)"
                            fill="#6c757d"
                          />
                        </svg>
                      </template>
                    </v-text-field>
                    <v-hijri-date-picker
                      @change="showHiringDate = false"
                      v-if="showHiringDate"
                      style="position: absolute; z-index: 1000"
                      v-model="agent.customer.hiring_date"
                      locale="ar"
                    />
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div style="display: flex">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('work')"
                      />
                      جهة&nbsp;العمل
                    </div>
                    <div class="horizontal__line"></div>

                    <v-autocomplete
                      :items="$store.state.works_list"
                      hide-details
                      v-model="agent.customer.work"
                      item-text="value"
                      item-value="id"
                      style="border: none !important"
                      class="mr-1"
                      outlined
                      dense
                    >
                    </v-autocomplete>
                    <img src="@/assets/icons/down-arrow.svg" />
                  </div>
                </v-col>

                <v-col cols="12" sm="12" v-if="agent.customer.work == 1">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('rank')"
                      />
                      الرتبة&nbsp;العسكرية
                    </div>
                    <div class="horizontal__line"></div>

                    <v-autocomplete
                      :items="$store.state.military_ranks"
                      hide-details
                      v-model="agent.customer.military_rank"
                      item-text="value"
                      item-value="id"
                      style="border: none !important"
                      class="mr-1"
                      outlined
                      dense
                    >
                    </v-autocomplete>
                    <img src="@/assets/icons/down-arrow.svg" />
                  </div>
                </v-col>

                <v-col cols="12" sm="12" v-if="agent.customer.work == 2">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div style="display: flex">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('madanyWork')"
                      />
                      الجهة
                    </div>
                    <div class="horizontal__line"></div>

                    <v-autocomplete
                      :items="$store.state.madany_works"
                      hide-details
                      v-model="agent.customer.madany_id"
                      item-text="value"
                      item-value="id"
                      style="border: none !important"
                      class="mr-1"
                      outlined
                      dense
                    >
                    </v-autocomplete>
                    <img src="@/assets/icons/down-arrow.svg" />
                  </div>
                </v-col>

                <v-col cols="12" sm="12" v-if="agent.customer.work == 2">
                  <div style="display: flex; position: relative" class="input__group">
                    <div style="display: flex">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('jobTitle')"
                      />
                      المنصب&nbsp;الوظيفي
                    </div>
                    <div class="horizontal__line"></div>
                    <div>
                      <v-text-field
                        type="text"
                        class="form-control"
                        style="
                          background-color: #fbfbfb;
                          border-right: #fbfbfb;
                          margin-top: -20px;
                        "
                        v-model="agent.customer.job_title"
                      />
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div style="display: flex">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('salary_source')"
                      />
                      جهة&nbsp;نزول&nbsp;المرتب
                    </div>
                    <div class="horizontal__line"></div>

                    <v-autocomplete
                      :items="$store.state.salary_sources"
                      item-text="value"
                      item-value="id"
                      hide-details
                      v-model="agent.customer.salary_id"
                      class="mr-1"
                      outlined
                      dense
                    >
                    </v-autocomplete>
                    <img src="@/assets/icons/down-arrow.svg" />
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('you_have')"
                      />
                      لديه
                    </div>
                    <div class="horizontal__line"></div>

                    <v-autocomplete
                      :items="['شركة','مؤسسة','فرد']"
                      v-model="selectedYouHave"
                      style="margin-top: 15px"
                      outlined
                      dense
                    >
                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-bind="attrs" v-on="on">
                          <v-list-item-title
                            :id="attrs['aria-labelledby']"
                            v-text="item"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <img src="@/assets/icons/down-arrow.svg" />
                  </div>
                </v-col>

                <v-col cols="12" sm="12">
                  <div style="display: flex; align-items: center" class="input__group">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('source_of_income')"
                      />
                      مصدر دخلك
                    </div>
                    <div class="horizontal__line"></div>

                    <v-autocomplete
                      :items="['نقاط بيع','قوائم مالية','لا يوجد']"
                      v-model="selectedSourceOfIncome"
                      style="margin-top: 15px"
                      outlined
                      dense
                    >
                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item v-bind="attrs" v-on="on">
                          <v-list-item-title
                            :id="attrs['aria-labelledby']"
                            v-text="item"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <img src="@/assets/icons/down-arrow.svg" />
                  </div>
                </v-col>


              </v-card>
            </v-col>
            <v-col cols="12" lg="4" md="4" sm="6" xs="12">
              <v-card class="px-3 position-relative userProfile__card">
                <div class="switch__container">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-left: 5px"
                      @click="show_Field_log('support')"
                    />
                    هل&nbsp;هو&nbsp;مدعوم
                  </div>
                  <div>
                    <v-radio-group row v-model="agent.customer.is_supported">
                      <v-radio value="yes" label="نعم"></v-radio>
                      <v-radio value="no" label="لا"></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                <div class="switch__container">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-left: 5px"
                      @click="show_Field_log('without_transfer_salary')"
                    />
                    بدون&nbsp;تحويل&nbsp;الراتب
                  </div>
                  <div>
                    <v-radio-group v-model="agent.customer.without_transfer_salary" row>
                      <v-radio :value="1" label="نعم"></v-radio>
                      <v-radio :value="0" label="لا"></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                <div class="switch__container">
                  <div style="width: 140px">
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-left: 5px"
                      @click="show_Field_log('add_support_installment_to_salary')"
                    />
                    إضافة&nbsp;قسط الدعم&nbsp;للراتب
                  </div>
                  <div>
                    <v-radio-group
                      row
                      v-model="agent.customer.add_support_installment_to_salary"
                    >
                      <v-radio :value="1" label="نعم"></v-radio>
                      <v-radio :value="0" label="لا"></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                <div class="switch__container">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-left: 5px"
                      @click="show_Field_log('guarantees')"
                    />
                    الضمانات
                  </div>
                  <div>
                    <v-radio-group row v-model="agent.customer.guarantees">
                      <v-radio :value="1" label="نعم"></v-radio>
                      <v-radio :value="0" label="لا"></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                <div class="switch__container">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-left: 5px"
                      @click="show_Field_log('obligations')"
                    />
                    هل لديه التزامات
                  </div>
                  <div>
                    <v-radio-group row v-model="agent.customer.has_obligations">
                      <v-radio value="yes" label="نعم"></v-radio>
                      <v-radio value="no" label="لا"></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                <div class="switch__container">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-left: 5px"
                      @click="show_Field_log('distress')"
                    />
                    هل لديه تعثرات
                  </div>
                  <div>
                    <v-radio-group row v-model="agent.customer.has_financial_distress">
                      <v-radio value="yes" label="نعم"></v-radio>
                      <v-radio value="no" label="لا"></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                <div class="switch__container">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-left: 5px"
                      @click="show_Field_log('have_salary')"
                    />
                    هل لديك راتب
                  </div>
                  <div>
                    <v-radio-group row v-model="agent.customer.have_salary">
                      <v-radio value="yes" label="نعم"></v-radio>
                      <v-radio value="no" label="لا"></v-radio>
                    </v-radio-group>
                  </div>
                </div>
                <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                <v-col cols="12" sm="12" v-if="agent.customer.has_obligations === 'yes'">
                  <div style="display: flex; position: relative" class="input__group">
                    <div style="display: flex; align-items: center">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('obligations_value')"
                      />
                      قيمة الإلتزامات
                    </div>
                    <div class="horizontal__line"></div>
                    <div>
                      <v-text-field
                        type="text"
                        class="form-control"
                        style="
                          background-color: #fbfbfb;
                          border-right: #fbfbfb;
                          margin-top: -20px;
                        "
                        v-model="agent.customer.obligations_value"
                        v-numericOnly
                      />
                    </div>
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  v-if="agent.customer.has_financial_distress === 'yes'"
                >
                  <div style="display: flex; position: relative" class="input__group">
                    <div style="display: flex; align-items: center">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-top: 5px; margin-left: 5px"
                        @click="show_Field_log('financial_distress_value')"
                      />
                      قيمة التعثرات
                    </div>
                    <div class="horizontal__line"></div>
                    <div>
                      <v-text-field
                        type="text"
                        class="form-control"
                        style="
                          background-color: #fbfbfb;
                          border-right: #fbfbfb;
                          margin-top: -20px;
                        "
                        v-model="agent.customer.financial_distress_value"
                        v-numericOnly
                      />
                    </div>
                  </div>
                </v-col>
              </v-card>
            </v-col>
          </v-layout>
        </v-card>
      </v-form>

      <!--Dialog Add Numbers-->
      <v-dialog v-model="secondNumber" max-width="540px">
        <v-card class="pl-3">
          <div class="d-flex justify-space-between align-center">
            <v-card-title> اضافة رقم هاتف</v-card-title>
            <v-icon style="cursor: pointer" @click="secondNumber = false"
              >mdi-close</v-icon
            >
          </div>
          <v-card-text>
            <v-text-field
              hide-details
              class="mr-1"
              outlined
              dense
              v-model="secNum"
              @keydown="confirmNum.err = true"
            ></v-text-field>
            <div class="d-flex justify-content-center">
              <v-btn class="mt-5 success white--text d-block" @click="addNumber">{{
                verifing ? "جاري التحقق ..." : "اضافة"
              }}</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!--Dialog-->

      <!--Dialog Show Numbers-->
      <v-dialog v-model="showNums" max-width="540px">
        <v-card v-if="numbersArray.length > 0" class="pb-3 pl-3 pr-3">
          <div class="d-flex justify-space-between align-center">
            <v-card-title> ارقام الهاتف</v-card-title>
            <v-icon style="cursor: pointer" @click="showNums = false">mdi-close</v-icon>
          </div>
          <div class="d-flex justify-start align-center flex-wrap">
            <span
              style="
                background: rgb(60 89 208);
                color: white;
                padding: 7px 9px 9px;
                border-radius: 10px;
                font-size: 16px;
                margin: 10px;
                letter-spacing: 1px;
              "
              v-for="(num, i) in numbersArray"
              :key="i"
              >{{ num.mobile }}</span
            >
          </div>
        </v-card>
        <v-card v-else class="text-center pt-3">
          <v-card-text style="font-size: 17px"
            >لم يتم اضافة ارقام هاتف حتي الان</v-card-text
          >
        </v-card>
      </v-dialog>
      <!--Dialog-->
      <v-sheet class="px-3 pb-3 pt-5">
        <v-form :disabled="!for_agent_joint">
          <!-- بيانات المتضامن -->
          <div
            @click="openInformationJoint = !openInformationJoint"
            class="d-flex align-center justify-center py-3"
            style="
              font: 16px;
              border-radius: 4px;
              color: white;
              background-color: #451646;
              cursor: pointer;
              padding-bottom: 10px !important;
            "
          >
            بيانات المتضامن
          </div>

          <v-card flat v-if="openInformationJoint" class="mt-3">
            <v-layout
              row
              wrap
              style="background-color: #f3f5f7"
              class="userProfile__container"
            >
              <v-col cols="12" lg="4" md="4" sm="6" xs="12">
                <v-card class="pa-3 position-relative userProfile__card">
                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointName')"
                        />
                        اسم&nbsp;المتضامن
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          class="form-control"
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.joint.name"
                          ref="customerName"
                        />
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointMobile')"
                        />
                        جوال&nbsp;المتضامن
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          class="form-control"
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.joint.mobile"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointSalary')"
                        />
                        راتب&nbsp;المتضامن
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          class="form-control"
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.joint.salary"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" v-if="!showHigriDateJoint">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('birth_hijri')"
                        />
                        تاريخ&nbsp;الميلاد(م)
                      </div>
                      <div class="horizontal__line"></div>

                      <v-text-field
                        type="date"
                        style="margin-bottom: 5px"
                        hide-details
                        v-model="agent.joint.birth_date"
                        class="mr-1"
                        dense
                        append-icon
                      >
                        <template v-slot:append>
                          <svg
                            id="calendar"
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.396"
                            height="16.989"
                            viewBox="0 0 17.396 16.989"
                            style="margin-top: 15px"
                          >
                            <path
                              id="Path_2784"
                              data-name="Path 2784"
                              d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                              transform="translate(-4.21 -7.917)"
                              fill="#6c757d"
                            />
                            <path
                              id="Path_2785"
                              data-name="Path 2785"
                              d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                              transform="translate(-4.21 -4.86)"
                              fill="#6c757d"
                            />
                          </svg>

                          <img
                            src="@/assets/icons/alternative.svg"
                            alt="img"
                            style="margin-right: 10px; cursor: pointer"
                            @click="showHigriDateJoint = !showHigriDateJoint"
                          />
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" v-if="showHigriDateJoint">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('birth_hijri')"
                        />
                        تاريخ&nbsp;الميلاد(ه)
                      </div>
                      <div class="horizontal__line"></div>

                      <v-text-field
                        type="text"
                        style="margin-bottom: 5px"
                        hide-details
                        v-model="agent.joint.birth_date_higri"
                        class="mr-1"
                        @click="showHiringBirthDateJoint = !showHiringBirthDateJoint"
                        dense
                        append-icon
                      >
                        <template v-slot:append>
                          <svg
                            @click="showHiringBirthDateJoint = !showHiringBirthDateJoint"
                            id="calendar"
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.396"
                            height="16.989"
                            viewBox="0 0 17.396 16.989"
                            style="margin-top: 15px"
                          >
                            <path
                              id="Path_2784"
                              data-name="Path 2784"
                              d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                              transform="translate(-4.21 -7.917)"
                              fill="#6c757d"
                            />
                            <path
                              id="Path_2785"
                              data-name="Path 2785"
                              d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                              transform="translate(-4.21 -4.86)"
                              fill="#6c757d"
                            />
                          </svg>
                          <img
                            src="@/assets/icons/alternative.svg"
                            alt="img"
                            style="margin-right: 10px; cursor: pointer"
                            @click="showHigriDateJoint = !showHigriDateJoint"
                          />
                        </template>
                      </v-text-field>
                      <v-hijri-date-picker
                        @change="showHiringBirthDateJoint = false"
                        v-if="showHiringBirthDateJoint"
                        style="position: absolute; z-index: 1000"
                        v-model="agent.joint.birth_date_higri"
                        locale="ar"
                      />
                    </div>
                  </v-col>
                </v-card>
              </v-col>
              <v-col cols="12" lg="4" md="4" sm="6" xs="12">
                <v-card class="pa-3 position-relative userProfile__card">
                  <v-col cols="12" sm="12">
                    <div style="display: flex" class="input__group">
                      <div style="display: flex; align-items: center">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointage')"
                        />
                        العمر
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          class="form-control"
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.joint.age"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div style="display: flex">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointWork')"
                        />
                        جهة&nbsp;العمل
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.works_list"
                        hide-details
                        v-model="agent.joint.work"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" v-if="agent.joint.work == 1">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div style="display: flex">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointaskaryWork')"
                        />
                        الجهة
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.military_works"
                        hide-details
                        v-model="agent.joint.askary_id"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" v-if="agent.joint.work == 1">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div style="display: flex">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointRank')"
                        />
                        الرتبة&nbsp;العسكرية
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.military_ranks"
                        hide-details
                        v-model="agent.joint.military_rank"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" v-if="agent.joint.work == 2">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div style="display: flex">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointmadanyWork')"
                        />
                        الجهة
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.madany_works"
                        hide-details
                        v-model="agent.joint.madany_id"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12" v-if="agent.joint.work == 2">
                    <div style="display: flex; position: relative" class="input__group">
                      <div style="display: flex">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointJobTitle')"
                        />
                        المنصب&nbsp;الوظيفي
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          class="form-control"
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.joint.job_title"
                        />
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div style="display: flex">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointsalary_source')"
                        />
                        جهة&nbsp;نزول&nbsp;المرتب
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.salary_sources"
                        hide-details
                        v-model="agent.joint.salary_id"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div style="display: flex">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointfunding_source')"
                        />
                        جهة&nbsp;التمويل
                      </div>
                      <div class="horizontal__line"></div>

                      <v-autocomplete
                        :items="$store.state.funding_sources"
                        hide-details
                        v-model="agent.joint.funding_id"
                        item-text="value"
                        item-value="id"
                        style="border: none !important"
                        class="mr-1"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <img src="@/assets/icons/down-arrow.svg" />
                    </div>
                  </v-col>
                </v-card>
              </v-col>
              <v-col cols="12" lg="4" md="4" sm="6" xs="12">
                <v-card class="pa-3 position-relative userProfile__card">
                  <v-col cols="12" sm="12">
                    <div style="display: flex; align-items: center" class="input__group">
                      <div style="display: flex">
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('joint_hiring_date')"
                        />
                        تاريخ&nbsp;التعيين
                      </div>
                      <div class="horizontal__line"></div>

                      <v-text-field
                        type="date"
                        style="margin-bottom: 5px"
                        hide-details
                        v-model="agent.joint.hiring_date"
                        class="mr-1"
                        dense
                        append-icon
                      >
                        <template v-slot:append>
                          <svg
                            id="calendar"
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.396"
                            height="16.989"
                            viewBox="0 0 17.396 16.989"
                            style="margin-top: 15px"
                          >
                            <path
                              id="Path_2784"
                              data-name="Path 2784"
                              d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                              transform="translate(-4.21 -7.917)"
                              fill="#6c757d"
                            />
                            <path
                              id="Path_2785"
                              data-name="Path 2785"
                              d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                              transform="translate(-4.21 -4.86)"
                              fill="#6c757d"
                            />
                          </svg>
                        </template>
                      </v-text-field>
                    </div>
                  </v-col>
                  <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                  <div class="switch__container">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-left: 5px"
                        @click="show_Field_log('jointSupport')"
                      />
                      هل هو مدعوم
                    </div>
                    <div>
                      <v-radio-group v-model="agent.joint.is_supported" row>
                        <v-radio value="yes" label="نعم"></v-radio>
                        <v-radio value="no" label="لا"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                  <div class="switch__container">
                    <div style="width: 137px; display: flex">
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-left: 5px"
                        @click="show_Field_log('joint_add_support_installment_to_salary')"
                      />
                      إضافة&nbsp;قسط&nbsp;الدعم إلي الراتب
                    </div>
                    <div>
                      <v-radio-group
                        v-model="agent.joint.add_support_installment_to_salary"
                        row
                      >
                        <v-radio value="1" label="نعم"></v-radio>
                        <v-radio value="0" label="لا"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />

                  <div class="switch__container">
                    <div>
                      <img
                        src="@/assets/icons/material-history.svg"
                        alt="image"
                        style="margin-left: 5px"
                        @click="show_Field_log('jointObligations')"
                      />
                      هل لديه إلتزامات
                    </div>
                    <div>
                      <v-radio-group v-model="agent.joint.has_obligations" row>
                        <v-radio value="yes" label="نعم"></v-radio>
                        <v-radio value="no" label="لا"></v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <hr style="border: 0.5px solid #38414a26; margin-bottom: 0px" />
                  <v-col cols="12" sm="12" v-if="agent.joint.has_obligations === 'yes'">
                    <div style="display: flex" class="input__group">
                      <div>
                        <img
                          src="@/assets/icons/material-history.svg"
                          alt="image"
                          style="margin-top: 5px; margin-left: 5px"
                          @click="show_Field_log('jointobligations_value')"
                        />
                        قيمة الإلتزامات
                      </div>
                      <div class="horizontal__line"></div>
                      <div>
                        <v-text-field
                          type="text"
                          class="form-control"
                          style="
                            background-color: #fbfbfb;
                            border-right: #fbfbfb;
                            margin-top: -20px;
                          "
                          v-model="agent.joint.obligations_value"
                          v-numericOnly
                        />
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-col>
            </v-layout>
          </v-card>
        </v-form>
      </v-sheet>
    </template>
  </page-component>
</template>

<script src="dist/hijir-date-picker.js"></script>
<script>
import Axios from "axios";

import css from "./DetailsStyle.css";

var moment = require("moment-hijri");
export default {
  props: ["agent", "id"],

  data() {
    return {
      model: true,
      chowHigriDate: false,
      showHigriDateJoint: false,
      openInformationJoint: false,
      workList: this.$store.state.works_list,
      searchTerm: "",
      addNewRequestDialog: false,
      date: "",
      joint_birth_date: false,
      customer_birth_date: false,
      show_joint_date: false,
      show_customer_date: false,
      showHiringDate: false,
      showHiringBirthDate: false,
      showHiringBirthDateJoint: false,
      secondNumber: false,
      showNums: false,
      secNum: "",
      numbersArray: [],
      confirmNum: {
        msg: "",
      },
      verifing: false,
      isSupported: null,
      guarantees: false,
      has_obligations: false,
      has_financial_distress: false,
      sex: "",
    };
  },
  computed: {
    for_agent() {
      return (
        this.$store.state.request_info.statusReq == 0 ||
        this.$store.state.request_info.statusReq == 1 ||
        this.$store.state.request_info.statusReq == 2 ||
        this.$store.state.request_info.statusReq == 4 ||
        (this.$store.state.request_info.statusReq == 19 &&
          this.$store.state.request_info.type == "رهن-شراء")
      );
    },

    selectedNationality: {
      get() {
        if(this.agent.customer.nationality_id === 1){
          return 'سعودي';
        }else if(this.agent.customer.nationality_id === 5){
          return 'غير سعودي';
        }
        // return this.agent.customer.nationality_id === 1 ? 'سعودي' : 'غير سعودي';
      },
      set(value) {
        if(value === 'سعودي'){
          this.agent.customer.nationality_id =1;
        }else if(value === 'غير سعودي'){
          this.agent.customer.nationality_id =5;
        }
        // this.agent.customer.nationality_id = value === 'سعودي' ? 1 : 5;
      },
    },

    selectedYouHave: {
      get() {
        if(this.agent.customer.you_have == "company"){
          return 'شركة';
        }else if(this.agent.customer.you_have == "institution"){
          return 'مؤسسة';
        }else if(this.agent.customer.you_have == "individual"){
          return 'فرد';
        }
      },
      set(value) {
        if(value == "شركة"){
         this.agent.customer.you_have = 'company';
        }else if(value == "مؤسسة"){
         this.agent.customer.you_have = 'institution';
        }else if(value == "فرد"){
         this.agent.customer.you_have = 'individual';
        }
      },
    },

    selectedSourceOfIncome: {
      get() {
        if(this.agent.customer.source_of_income == "selling_points"){
          return 'نقاط بيع';
        }else if(this.agent.customer.source_of_income == "financial_menu"){
          return 'قوائم مالية';
        }else if(this.agent.customer.source_of_income == "nothing"){
          return 'لا يوجد';
        }
      },
      set(value) {
        if(value == "نقاط بيع"){
         this.agent.customer.source_of_income = 'selling_points';
        }else if(value == "قوائم مالية"){
         this.agent.customer.source_of_income = 'financial_menu';
        }else if(value == "لا يوجد"){
         this.agent.customer.source_of_income = 'nothing';
        }
      },
    },

    for_agent_joint() {
      return (
        this.$store.state.request_info.statusReq == 0 ||
        this.$store.state.request_info.statusReq == 1 ||
        this.$store.state.request_info.statusReq == 2 ||
        this.$store.state.request_info.statusReq == 4
      );
    },

    forMorPur() {
      return (
        this.$store.state.request_info.statusReq == 19 &&
        this.$store.state.request_info.type == "رهن-شراء"
      );
    },

    // $store.state.request_info.type == 'رهن-شراء' && $store.state.request_info.statusReq == 19
  },
  mounted() {
    if (
      this.$store.state.request_info.status === `طلب تم فتحه` &&
      !(this.$store.state.request_info.is_stared == 1) &&
      !(this.$store.state.request_info.is_followed == 1)
    ) {
      this.setBreadCrumbs([
        "الرئيسية",
        "الطلبات",
        "الطلبات المستلمه",
        this.agent.customer.name,
      ]);
    } else if (this.$store.state.request_info.status === `مؤرشف لدى استشاري المبيعات`) {
      this.setBreadCrumbs([
        "الرئيسية",
        "الطلبات",
        "الطلبات المؤرشفه",
        this.agent.customer.name,
      ]);
    } else if (
      this.$store.state.request_info.status === `في انتظار موافقة مدير المبيعات`
    ) {
      this.setBreadCrumbs([
        "الرئيسية",
        "الطلبات",
        "الطلبات المكتمله",
        this.agent.customer.name,
      ]);
    } else if (this.$store.state.request_info.is_stared == 1) {
      this.setBreadCrumbs([
        "الرئيسية",
        "الطلبات",
        "الطلبات المميزه",
        this.agent.customer.name,
      ]);
    } else if (this.$store.state.request_info.is_followed == 1) {
      this.setBreadCrumbs([
        "الرئيسية",
        "الطلبات",
        "الطلبات المتابعه",
        this.agent.customer.name,
      ]);
    } else {
      this.setBreadCrumbs(["الرئيسية", "الطلبات", this.agent.customer.name]);
    }
    // this.workList = ;
  },
  watch: {
    sex(val) {
      if (val == "أنثي") {
        this.agent.customer.sex = "أنثي";
      } else if (val == "ذكر") {
        this.agent.customer.sex = "ذكر";
      }
    },

    guarantees(val) {
      if (val) {
        this.agent.customer.guarantees = 1;
      } else {
        this.agent.customer.guarantees = 0;
      }
    },

    has_obligations(val) {
      if (val) {
        this.agent.customer.has_obligations = "yes";
      } else {
        this.agent.customer.has_obligations = "no";
      }
    },

    has_financial_distress(val) {
      if (val) {
        this.agent.customer.has_financial_distress = "yes";
      } else {
        this.agent.customer.has_financial_distress = "no";
      }
    },
    date(val) {
      this.agent.joint.birth_date_higri = val;
    },
    "$route.params": {
      handler() {
        this.$router.go(this.$router.currentRoute);
      },
      deep: true,
    },
    "agent.customer.birth_date_higri": {
      handler(val) {
        this.agent.customer.birth_date = moment(val, "iYYYY/iM/iD").format("YYYY-M-D");
      },
    },
    agent: {
      handler(val) {
        if (val) {
          this.$emit("update:agent", val);
          this.$store.state.request_info = val;
          val.customer.military_rank = parseInt(val.customer.military_rank) || "";
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    returnValues(val) {
      if (val == "0") {
        return false;
      } else {
        return true;
      }
    },
    async get_all_requests(page = 1) {
      this.loading = true;
      await Axios.get(
        `https://appproduct.alwsata.com.sa/api/v2/employee/Requests?type=all&page=${page}&per_page=12`,
        {
          headers: this.request_headers(),
        }
      ).then((response) => {
        this.items = response.data.payload.data;
        this.pagination = {
          limit: 10,
          count: response.data.payload.count_of_all,
        };
        this.loading = false;
      });
    },

    async getNumbers() {
      await Axios.get(
        `https://appproduct.alwsata.com.sa/api/agent/Request/Phones/${this.id}`,
        {
          headers: this.request_headers(),
        }
      )
        .then((res) => {
          if (res.data.payload.length > 0) {
            this.numbersArray = res.data.payload;
          }
        })
        .catch((err) => console.log(err.message));
    },
    addNumber() {
      this.verifing = true;
      Axios.post(
        `https://appproduct.alwsata.com.sa/api/agent/Request/Add/Phone`,
        {
          mobile: this.secNum,
          request_id: this.id,
        },
        {
          headers: this.request_headers(),
        }
      )
        .then((res) => {
          this.showPopup("success", res.data.message);
          this.secondNumber = false;
          this.getNumbers();
          this.verifing = false;
          this.secNum = "";
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
          this.verifing = false;
        });
    },
  },
  async created() {
    if (this.agent.customer.work == "متقاعد") {
      this.agent.customer.work = 3;
    } else if (this.agent.customer.work == "عسكري") {
      this.agent.customer.work = 1;
    } else if (this.agent.customer.work == "مدني") {
      this.agent.customer.work = 2;
    } else if (this.agent.customer.work == "شبه حكومي") {
      this.agent.customer.work = 4;
    } else if (this.agent.customer.work == "قطاع خاص غير معتمد") {
      this.agent.customer.work = 5;
    } else if (this.agent.customer.work == "قطاع خاص معتمد") {
      this.agent.customer.work = 6;
    }

    if (this.agent.customer.sex == "female") {
      this.sex = "أنثي";
    } else if (this.agent.customer.sex == "male") {
      this.sex = "ذكر";
    }
    await this.getNumbers();
  },
};
</script>
