<template>
    <div >
      <highcharts :options="chartOptions" style="height: 600px; width: 90%; margin: auto;padding: 25px;"></highcharts>
    </div>
  </template>
  
  <script>
  import { Chart } from "highcharts-vue";
  import Highcharts from "highcharts-vue";
  
  export default {
    props: ["xaxis", "chartData", 'chartColors', 'chartLabels'],
    data() {
        return {
        chartOptions: {
            chart: {
                type: 'column',
                style:{
                    // direction: 'rtl',
                    // align: 'center'
                },
            },
                legend:{
                    rtl: true,
                },
            title: {
                text: '',
                
                useHTML: true, //bug fixed `IE9` and `EDGE`
                style: {
                    fontSize: '20px',
                    fontFamily: 'tahoma',
                    direction: 'rtl',
                },
            },
            
                    tooltip: {
                            formatter: function () {
                            return 'Agent <b>' + this.x +
                                '</b> Percent <b>' + this.y + '</b>' 
                            + '<br/>'  + 'requests: <b>' + this.point.series.userOptions.labels[this.point.index] + '</b>';
                        },
                    },
            xAxis: {
                categories: this.xaxis
            },
            yAxis: {
          min: 0, // Minimum value
          max: 100, // Maximum value
          tickInterval: 10, // Interval between ticks
                title: {
                    text: 'Some Details'
                },
                // categories: [`1`, `2`, `3`, `4`, `5`],
                labels: {
                    // Customize y-axis labels here
                    formatter: function() {
                    // You can format the labels using this function
                    return "(  " + this.value + ' %)'; // For example, append 'star' to each label
                    },
                    style: {
                        // You can customize the style of the labels here
                        color: 'red', // Change label text color
                        fontWeight: 'bold' // Make labels bold
                        // You can add more style properties as needed
                    }
                }
            },
            plotOptions: {
            column: {
                colorByPoint: true, // This allows each column to have its own color
                // colors: ['#FF0000', '#00FF00', '#0000FF'] // Specify colors for each column
                color: "#3f0e40",
                pointWidth: 20
            }
            },
            series: [{
                name: 'الطلبات ',
                data: this.chartData,
                colors: this.chartColors,
                // tooltip:{
                //     valueSuffix: " un"
                // },
                // showInLegend: true,
                labels: this.chartLabels
            }]
        }
        };
    },
    watch:{
        chartData(val){
            
            // Update the chart data
            this.chartOptions.xAxis.categories = this.xaxis;
            this.chartOptions.series[0].data = this.chartData;
            this.chartOptions.series[0].colors = this.chartColors;
            this.chartOptions.series[0].labels = this.chartLabels;
        }
    },
  
    components: {
      highcharts: Chart,
    },
  
    methods: {
        tooltipFormatter(tooltip) {
            let s = `<b>${tooltip.x}</b>`;
            tooltip.points.forEach(point => {
                s += `<br/><span style="color:${point.series.color}">●</span> ${point.series.name}: <b>${point.y}${point.series.userOptions.tooltip.valueSuffix}</b>`;
            });
                return  s;
            }
    },
  
    created() {
    },
  };
  </script>
  