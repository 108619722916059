<template>
  <v-dialog :width="width" v-model="active" content-class="dialog">
    <template v-slot:default>
      <div
        v-if="theLoading"
        class="progress d-flex align-center justify-center px-10"
        style="min-height: 200px"
      >
        <v-progress-circular
          size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-card v-else class="my-0 py-0">
        <v-toolbar elevation="0"
          >{{ title }} <v-spacer />
          <v-btn icon elevation="0" @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <slot name="content"></slot>
        </v-card-text>
        <v-card-actions>
          <slot name="actions"></slot>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import Dialog from "../../mixins/dialog";

export default {
  mixins: [Dialog],
  props: {
    title: {default: ''},
    width: {default: '900px'},
    theLoading: {type: Boolean, default: false}
  }
};
</script>

<style>
.dialog .v-toolbar {
  border-bottom: 1px solid #00000014;
}
.dialog .v-toolbar__content {
  font-size: 20px !important;
}
.dialog .v-card__text {
  margin: 0 !important;
  padding: 30px 15px 15px !important;
  width: initial;
}
</style>
