<template>
  <c-dialog
    v-model="active"
    title="اضافة عميل"
    :loading="loading"
    width="570px"
    class="add-new-req"
  >
    <template slot="content">
      <v-form style="color: #2c2c2c; font-weight: 500; margin-bottom: 50px;">
        <div v-if="ShowIfRequestCanAdded">
          <div class="mb-1">الإسم</div>
          <v-row>
            <v-col cols="12" class="mt-2 mb-5">
              <v-text-field
                hide-details
                v-model="name"
                class="mr-1"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </div>

        <div class="mb-1" style="font-weight: 600">رقم الجوال</div>
        <v-row>
          <v-col cols="10" class="mt-2">
            <v-text-field
              hide-details
              class="mr-1"
              v-model="mobile"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="my-2 pr-0">
            <v-btn
              style="float: left; width: 100%"
              :loading="verify_loading"
              @click="verify_number"
              color="primary"
              >تحقق</v-btn
            >
          </v-col>
          <v-col cols="12" class="py-0" v-if="success || fail">
            <div v-if="success && ShowIfRequestCanAdded" class="my-0 py-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                style="margin-bottom: -7px"
              >
                <g
                  id="Group_11862"
                  data-name="Group 11862"
                  transform="translate(-531.375 -263.683)"
                >
                  <g
                    id="Rectangle_18255"
                    data-name="Rectangle 18255"
                    transform="translate(531.375 263.683)"
                    fill="#e8fff1"
                    stroke="#40af6c"
                    stroke-width="1"
                  >
                    <rect width="26" height="26" rx="13" stroke="none" />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="25"
                      height="25"
                      rx="12.5"
                      fill="none"
                    />
                  </g>
                  <path
                    id="Icon_feather-check"
                    data-name="Icon feather-check"
                    d="M18.112,9,9.785,17.327,6,13.542"
                    transform="translate(532.287 263.519)"
                    fill="none"
                    stroke="#40af6c"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.6"
                  />
                </g>
              </svg>
              <span class="mr-2 font-weight-bold">هذا الرقم متاح</span>
            </div>
            <div v-if="fail && !hidden_baskets.includes('pull_request_from_agent')" class="my-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                style="margin-bottom: -7px"
              >
                <path
                  id="Icon_material-error-outline"
                  data-name="Icon material-error-outline"
                  d="M14.7,19.9h2.6v2.6H14.7Zm0-10.4h2.6v7.8H14.7ZM15.987,3A13,13,0,1,0,29,16,12.994,12.994,0,0,0,15.987,3ZM16,26.4A10.4,10.4,0,1,1,26.4,16,10.4,10.4,0,0,1,16,26.4Z"
                  transform="translate(-3 -3)"
                  fill="#ff715b"
                />
              </svg>
              <span class="mr-2" style="color: red; font-size: 16px"
                >{{ error_message }}
                <span
                  v-if="false"
                  @click="openDetailsRequest"
                  style="cursor: pointer; color: blue; font-size: 16px"
                >
                  فتح الطلب</span
                ></span
              >
            </div>

            <div v-if="fail && hidden_baskets.includes('pull_request_from_agent')" class="my-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                style="margin-bottom: -7px"
              >
                <path
                  id="Icon_material-error-outline"
                  data-name="Icon material-error-outline"
                  d="M14.7,19.9h2.6v2.6H14.7Zm0-10.4h2.6v7.8H14.7ZM15.987,3A13,13,0,1,0,29,16,12.994,12.994,0,0,0,15.987,3ZM16,26.4A10.4,10.4,0,1,1,26.4,16,10.4,10.4,0,0,1,16,26.4Z"
                  transform="translate(-3 -3)"
                  fill="#ff715b"
                />
              </svg>
              <span class="mr-2" style="color: red; font-size: 16px">
                الطلب موجود بالفعل لدى استشاري آخر
              </span>
            </div>

            <div v-if="Object.keys(exist_request).length > 0  && !hidden_baskets.includes('pull_request_from_agent')" class="my-2">
              <card-item
                :item="exist_request"
                @pull-request="pull_request"
                :action_type="exist_request_type"
                :hidden_baskets="hidden_baskets"
              />
            </div>
          </v-col>
        </v-row>
        <div v-if="ShowIfRequestCanAdded">
          <div class="mb-1 mt-5">مصدر المعاملة</div>
          <v-row>
            <v-col cols="12" class="my-2 select-source-box">
              <v-autocomplete
                hide-details
                :items="sources"
                class="mr-1"
                item-text="value"
                item-value="id"
                v-model="request_source"
                outlined
                dense
                append-icon
              >
                <template v-slot:append>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.003"
                    height="6.291"
                    viewBox="0 0 11.003 6.291"
                    class="mt-3"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-back"
                      data-name="Icon ionic-ios-arrow-back"
                      d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                      transform="translate(0 6.291) rotate(-90)"
                      fill="#2c2c2c"
                    />
                  </svg> </template
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>

        <div v-if="ShowIfRequestCanAdded">
          <div v-if="request_source == 2" class="mb-1">
            المتعاونيين الإضافيين
          </div>
          <v-row v-if="request_source == 2">
            <v-col cols="12" class="my-2">
              <v-autocomplete
                hide-details
                :items="collaborators"
                :loading="getting"
                class="mr-1"
                item-text="name"
                item-value="collaborator_id"
                v-model="collaborator_id"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>

        <div v-if="ShowIfRequestCanAdded">
          <div class="mb-1 mt-5">نوع المنتج</div>
          <v-row>
            <v-col cols="12" class="my-2 select-source-box">
              <v-autocomplete
                hide-details
                :items="$store.state.selected_products"
                class="mr-1"
                item-text="value"
                item-value="id"
                v-model="req_product_type"
                outlined
                dense
                append-icon
              >
                <template v-slot:append>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.003"
                    height="6.291"
                    viewBox="0 0 11.003 6.291"
                    class="mt-3"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-back"
                      data-name="Icon ionic-ios-arrow-back"
                      d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                      transform="translate(0 6.291) rotate(-90)"
                      fill="#2c2c2c"
                    />
                  </svg> </template
              ></v-autocomplete>
            </v-col>
          </v-row>
        </div>

      </v-form>
    </template>
    <template slot="actions" v-if="ShowIfRequestCanAdded">
      <v-btn
        style="width: 100%; max-height: unset; height: unset"
        class="button-colors mb-3 py-3 mx-auto"
        color="button"
        :loading="submitting"
        depressed
        @click="submit"
        >إضـافه</v-btn
      >
    </template>
  </c-dialog>
</template>

<script>
import Dialog from "../../mixins/dialog";
import Axios from "axios";
import CardItem from "../Base/CardItem.vue";
export default {
  components: { CardItem },
  mixins: [Dialog],
  data() {
    return {
      success: false,
      hidden_baskets : [],
      getting: false,
      fail: false,
      verify_loading: false,
      submitting: false,
      error_message: "",
      exist_request: {},
      exist_request_type: "",
      mobile: "",
      name: "",
      request_source: "",
      req_product_type: "",
      collaborators: [],
      collaborator_id: "",
      userId: null,
      ShowIfRequestCanAdded: false,
    };
  },
  computed: {
    sources() {
      return this.$store.state.work_sources;
    },
  },
  destroyed() {
    this.init();
  },
  watch: {
    active() {
      setTimeout(() => {
        document
          .querySelector(".v-overlay__scrim")
          .addEventListener("click", this.emptyErr);
        document
          .querySelector(".v-icon.notranslate.mdi.mdi-close.theme--light")
          .parentElement.parentElement.addEventListener("click", this.emptyErr);
        return;
      }, 100);
    },
    request_source(val) {
      if (val == 2) {
        this.get_collaborators();
      }
      this.active = false;
      setTimeout(() => {
        this.active = true;
      }, 0);
    },
  },
  methods: {
    openDetailsRequest() {
      this.$router.push({
        name: "request_details",
        params: { id: this.userId },
      });
    },

    async get_hidden_baskets() {
      await Axios.get("https://appproduct.alwsata.com.sa/api/employee/admin/get-Basket-settings-data-for-agent", {
        headers: this.request_headers(),
      }).then((response) => {
        const basketPaths = response.data.payload.data.map(item => item.basket_path);
        this.$store.state.hidden_baskets_for_user = basketPaths;
        this.hidden_baskets = basketPaths;
      });
    },

    emptyErr() {
      this.fail = false;
      this.success = false;
      this.mobile = "";
      this.name = "";
    },
    init() {
      this.name = "";
      this.mobile = "";
      this.request_source = "";
      this.req_product_type = "";
      this.collaborator_id = "";
    },
    submit() {
      this.submitting = true;
      this.active = false;
      setTimeout(() => {
        this.active = true;
      }, 0);
      Axios.post(
        "https://appproduct.alwsata.com.sa/api/agent/Add/Customer",
        {
          name: this.name,
          mobile: this.mobile,
          request_source: this.request_source,
          req_product_type: this.req_product_type,
          collaborator_id:
            this.request_source == 2 ? this.collaborator_id : undefined,
        },
        {
          headers: this.request_headers(),
        }
      )
        .then((response) => {
          this.init();
          this.submitted("تمت اضافة الطلب بنجاح");
          this.active = false;
          this.$root.$emit("REFRESHCARDDATA");
          this.showPopup("success", response.data.message);
          this.ShowIfRequestCanAdded = false;
          this.$router.push({
            name: "request_details",
            params: { id: response.data.payload.id },
          });
        })
        .catch((err) => {
          this.success = false;
          this.showPopup("error", err.response.data.message);
          this.active = false;
          this.submitting = false;
          setTimeout(() => {
            this.active = true;
          }, 0);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    verify_number() {
      this.success = false;
      this.fail = false;
      this.verify_loading = true;
      this.active = false;
      setTimeout(() => {
        this.active = true;
      }, 0);
      Axios.post(
        "https://appproduct.alwsata.com.sa/api/agent/Check/Customer/Mobile",
        { mobile: this.mobile },
        {
          headers: this.request_headers(),
        }
      )
        .then((res) => {
          // this.userId = res.data.payload.request.id;
          // if (res.data.payload.open) {
          //   this.error_message = res.data.payload.message ;
          //   this.fail = true;
          //   this.success = false;
          // } else {
          //   this.success = true;
          //   this.fail = false;
          // }
          if (res.data.payload.status == 1) {
            this.ShowIfRequestCanAdded = true;
          } else {
            this.ShowIfRequestCanAdded = false;
          }
          if (res.data.payload.status == 0 && res.data.payload.open == true) {
            this.error_message = res.data.payload.message;
            this.exist_request = res.data.payload.request;
            this.exist_request_type = "open";
            this.fail = true;
            this.success = false;
          } else if (res.data.payload.status == 0) {
            this.error_message = res.data.payload.message;
            this.fail = true;
            this.exist_request = {};
            this.success = false;
          } else if (res.data.payload.status == 1) {
            this.success = true;
            this.exist_request = {};
            this.fail = false;
          } else {
            this.error_message = res.data.payload.message;
            this.exist_request = res.data.payload.request;
            this.exist_request_type = "pull";
            this.fail = true;
            this.success = false;
          }
        })
        .catch((err) => {
          this.fail = true;
          this.success = false;
          this.error_message = err.response.data.message;
        })
        .finally(() => {
          this.verify_loading = false;
          this.active = false;
          setTimeout(() => {
            this.active = true;
          }, 0);
        });
    },
    get_collaborators() {
      this.getting = true;
      this.active = false;
      setTimeout(() => {
        this.active = true;
      }, 0);
      Axios.get("https://appproduct.alwsata.com.sa/api/agent/Collaborators", {
        headers: this.request_headers(),
      })
        .then((response) => {
          this.collaborators = response.data.payload;
          this.active = false;
          setTimeout(() => {
            this.active = true;
          }, 0);
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          this.getting = false;
        });
    },
    pull_request(req_id) {
      this.getting = true;
      this.active = false;
      Axios.get(`https://appproduct.alwsata.com.sa/api/agent/MoveRequest/${req_id}`, {
        headers: this.request_headers(),
      })
        .then((response) => {
          this.exist_request = {};
          this.fail = false;
          this.active = false;
          this.mobile = "";
          this.$root.$emit("REFRESHCARDDATA");
          this.showPopup("success", response.data.message);
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          this.getting = false;
        });
    },
  },

  beforeMount(){
    this.get_hidden_baskets();
  }
};
</script>

<style lang="scss">
.v-dialog fieldset {
  border: 1px solid #e9edf4;
}
.select-source-box .v-autocomplete__selections {
  line-height: 32px !important;
}
</style>
