<template>
  <div>
    <v-list>
      <v-list-item @click="handleRecived">
        <v-list-item-icon class="ml-3">
          <img src="./assets/1.svg" alt="recived" />
        </v-list-item-icon>
        <v-list-item-title>{{ recived }}</v-list-item-title>
      </v-list-item>

      <v-list-item @click="switchUser">
        <v-list-item-icon class="ml-3">
          <img src="./assets/2.svg" style="width: 22px" alt="switch" />
        </v-list-item-icon>
        <v-list-item-title> تسجيل الدخول بهذا الحساب</v-list-item-title>
      </v-list-item>

      <v-list-item @click="handleEdit">
        <v-list-item-icon class="ml-3">
          <img src="./assets/3.svg" alt="edit" />
        </v-list-item-icon>
        <v-list-item-title>تعديل</v-list-item-title>
      </v-list-item>

      <v-list-item @click="handleArchived">
        <v-list-item-icon class="ml-3">
          <img src="./assets/4.svg" style="width: 22px" alt="archive" />
        </v-list-item-icon>
        <v-list-item-title> ارشفه</v-list-item-title>
      </v-list-item>

      <v-list-item @click="handleShowProfile">
        <v-list-item-icon class="ml-3">
          <img src="./assets/5.svg" alt="profile" />
        </v-list-item-icon>
        <v-list-item-title>عرض ملف الموظف</v-list-item-title>
      </v-list-item>

      <v-list-item @click="handleShowNotification">
        <v-list-item-icon class="ml-3">
          <img src="./assets/6.svg" style="width: 22px" alt="notif" />
        </v-list-item-icon>
        <v-list-item-title>تنبيهات البريد الالكتروني</v-list-item-title>
      </v-list-item>

      <v-list-item @click="openChats">
        <v-list-item-icon class="ml-3">
          <img src="./assets/7.svg" alt="chat" />
        </v-list-item-icon>
        <v-list-item-title>محادثات العملاء</v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script setup>
import { axiosInstance } from "@/axios/config";
import { defineProps, ref, defineEmits } from "vue";
import router from "@/routes";

const props = defineProps(["item"]);
const emit = defineEmits(["recived", "switchUser", "archived"]);

const recived = ref(" عدم السماح بإستلام الطلبات");
const handleRecived = () => {
  axiosInstance
    .post(`/employee/admin/allowReceivedRequest`, { user_id: props.item.id })
    .then((res) => {
      recived.value =
        props.item.allow_recived !== "نشط"
          ? " عدم السماح بإستلام الطلبات"
          : `السماح بإستلام الطلبات`;
      emit("recived");
    });
};

const switchUser = () => {
  emit("switchUser", props.item.id);
};

const handleShowNotification = () => {
  router.push({
    name: "show-notification",
    params: { id: props.item.id },
  });
};

const handleEdit = () => {
  router.push({
    name: "edit-user",
    params: { id: props.item.id },
  });
};

const handleArchived = () => {
  emit("archived", props.item.id);
};

const handleShowProfile = () => {
  router.push({
    name: "personal-info",
    params: { id: props.item.id },
  });
};

const openChats = () => {
  router.push({
    name: "show-chats",
    params: { id: props.item.id },
  });
};
</script>
