<template>
  <v-row justify="center">
    <v-dialog
        v-model="$store.state.freezingSearch"
        persistent
        width="824"
    >
      <v-card id="adminFilter">
        <v-card-title>
          <span class="text-h5">البحث</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <SelectInput
                    @selectedValues="getSelectedAgentType"
                    :originalItems="agentsType"
                    label="تصنيف استشاري المبيعات"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <SelectInput
                    @selectedValues="getSourceWork"
                    :originalItems="requestSource"
                    label="مصدر المعامله"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                    label="رقم الهاتف"
                    v-model="phoneNumber"
                    :rules="[saudiPhoneNumberRule]"
                    required
                    outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <PickerInput label="تاريخ الطلب من" @dateValue="saveDateFrom"/>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <PickerInput label="تاريخ الطلب الي" @dateValue="saveDateTo" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue-darken-1"
              variant="text"
              @click="$store.state.freezingSearch = false"
          >
            الغاء
          </v-btn>
          <v-btn
              color="blue-darken-1"
              variant="text"
              @click='searchRequest'
          >
            بحث
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style>
#adminFilter .v-text-field--outlined.v-input--has-state fieldset, .v-text-field--outlined.v-input--is-focused fieldset{
  height: 45px;
}

#adminFilter .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset{
  height: 48px;
}
</style>

<script>
import Axios from "axios";
import SelectInput from "@/components/Charts/SelectInput.vue";
import {SelectItems} from "@/mixins/selectItems";
import PickerInput from "@/components/AdminComponent/PickerInput.vue";
import {formatDate} from "@/mixins/formatDate";
import {SourceValue} from "@/mixins/sourceValue";

export default {
  components: {PickerInput, SelectInput},
  data(){
    return{
      body: {},
      agentsType: [],
      allSelectedAgent: [],
      requestSource: [],
      allSourcesWork: [],
      phoneNumber: "",
      dateFrom: '',
      dateTo: '',
      valid: false,
      saudiPhoneNumberRule: value => {
        if(this.phoneNumber){
        const pattern = /^(?:\+966|5)[0-9]{8}$/;
        this.valid = pattern.test(value);
        return pattern.test(value) || "رقم الهاتف غير صالح";
        }else {
          return true;
        }
      },
    }
  },

  methods: {
    getAgentClarification() {
      let url = `${this.$store.state.url}/agent/Request/Classification?type_of_classification=0`
      Axios.get(url, {headers: this.request_headers()}).then(res => {
        let response = res.data.payload
        this.agentsType = SelectItems(response)
      })
    },

    getSelectedAgentType(val) {
      this.allSelectedAgent = val
    },

    getRequestSource() {
      Axios.get(`${this.$store.state.url}/agent/Request/Sources`, {headers: this.request_headers()}
      ).then((res) => {
        let response = res.data.payload
        this.requestSource = SelectItems(response);
      });
    },

    getSourceWork(val){
      this.allSourcesWork = val
    },

    saveDateTo(val){
      this.dateTo = val;
    },

    saveDateFrom(val){
      this.dateFrom = val;
    },

    searchRequest(){
      if(this.phoneNumber && this.valid || !this.phoneNumber){
      this.$store.state.isSearch = true;
      this.body = new FormData();
      this.$store.state.freezingSearch = false
      this.$store.state.freezeData = [];
      this.$store.state.freezeSearch = [];
      this.$store.state.freezeLoading = true;
      this.$store.state.tableData = [];
      this.$store.state.InfinityPageNumber = 1;
      this.$store.state.loadingData = true;
      this.$store.state.back = true
      this.$store.state.startElement = [];
      this.$store.state.bodySearch = {}
      this.counter++


      this.body.append('page', 1);

      if(this.dateFrom){
        this.body.append('request_date_from', this.dateFrom);
      }

      if(this.dateTo){
        this.body.append('request_date_to', this.dateTo);
      }

      if (this.allSourcesWork.length > 0) {
        this.allSourcesWork.forEach((item, index) => {
          this.body.append(`work_source[${index}]`, item["id"]);
        });
      }

      if (this.allSelectedAgent.length > 0) {
        this.allSelectedAgent.forEach((item, index) => {
          this.body.append(`agent_classification[${index}]`, item["id"]);
        });
      }

      if(this.phoneNumber && this.valid){
        this.body.append('customer_phone' , this.phoneNumber)
      }


      let url = `${this.$store.state.url}/v2/employee/Requests/search?type=freez`
      Axios.post(url, this.body, {headers: this.request_headers()}).then(res => {
        let response = res.data.payload.data
        this.$store.state.freezeData.push(...response);
        this.$store.state.freezeSearch.push(...response);

        this.$store.state.loadingData = false
        res.data.payload.data.map((item) => {
          this.setTbData(item);
          item["starChecked"] = false;
          return item;
        });

        this.$store.state.pagination.count = res.data.payload.searched
        this.$store.state.freezeLoading = false;

      }).catch(err => {
        this.$store.state.freezeLoading = false;

      })
      this.$store.state.bodySearch = this.body;
      }
    },

    setTbData (items){
      this.$store.state.tableData.push({
        "id": items.id,
        "رقم الطلب": items.id,
        "تصنيف ما قبل التجميد": items.classification_before_to_freeze,
        "تاريخ الطلب": items.created_at ? formatDate(items.created_at) : '',
        "مصدر المعامله": SourceValue(items.source),
        "عميل": items.customer.name,
        "الجوال" : items.customer.mobile,
      })
    }

  },

  created() {
    this.getAgentClarification();
    this.getRequestSource();
  }
}
</script>