export const NotAllowIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.756" height="14.756" viewBox="0 0 14.756 14.756">
            <g id="Icon_ionic-ios-close-circle-outline" data-name="Icon ionic-ios-close-circle-outline"
               transform="translate(-3.375 -3.375)">
                <path id="Path_5857" data-name="Path 5857"
                      d="M17.876,17.072,16.007,15.2l1.869-1.869a.567.567,0,1,0-.8-.8L15.2,14.4l-1.869-1.869a.567.567,0,0,0-.8.8L14.4,15.2l-1.869,1.869a.548.548,0,0,0,0,.8.563.563,0,0,0,.8,0L15.2,16l1.869,1.869a.569.569,0,0,0,.8,0A.563.563,0,0,0,17.876,17.072Z"
                      transform="translate(-4.452 -4.45)"/>
                <path id="Path_5858" data-name="Path 5858"
                      d="M10.753,4.368A6.382,6.382,0,1,1,6.237,6.237a6.342,6.342,0,0,1,4.515-1.869m0-.993a7.378,7.378,0,1,0,7.378,7.378,7.377,7.377,0,0,0-7.378-7.378Z"/>
            </g>
        </svg>
    )
}
