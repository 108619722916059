<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="#D9CFD9" depressed small dark v-bind="attrs" v-on="on">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="4.153"
          height="18.986"
          viewBox="0 0 4.153 18.986"
        >
          <path
            id="menu"
            d="M16.077,43.153a2.166,2.166,0,0,1-1.483-.623A2.066,2.066,0,0,1,14,41.077a2.341,2.341,0,0,1,.593-1.483A2.085,2.085,0,0,1,16.077,39a2.2,2.2,0,0,1,1.454.593,2.092,2.092,0,0,1-1.454,3.56Zm8.87-.623a2.1,2.1,0,0,0,.623-1.454,2.223,2.223,0,0,0-.623-1.483A2.066,2.066,0,0,0,23.493,39a2.278,2.278,0,0,0-1.483.593,2.085,2.085,0,0,0-.593,1.483,2.2,2.2,0,0,0,.593,1.454,2.042,2.042,0,0,0,2.937,0Zm7.417,0a2.1,2.1,0,0,0,.623-1.454,2.223,2.223,0,0,0-.623-1.483A2.066,2.066,0,0,0,30.91,39a2.278,2.278,0,0,0-1.483.593,2.085,2.085,0,0,0-.593,1.483,2.2,2.2,0,0,0,.593,1.454,2.042,2.042,0,0,0,2.937,0Z"
            transform="translate(-39 32.986) rotate(-90)"
          />
        </svg>
      </v-btn>
    </template>
    <v-list style="padding: 0px">
      <v-list-item
        class="black--text font-weight-bold"
        style="border-bottom: 2px solid #ededed"
      >
        <v-list-item-icon class="ml-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.528"
            height="15.111"
            viewBox="0 0 13.528 10.111"
          >
            <g
              id="Icon_feather-eye-dark"
              data-name="Icon feather-eye-dark"
              transform="translate(-1 -5.5)"
            >
              <path
                id="Path_4040"
                data-name="Path 4040"
                d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
              <path
                id="Path_4041"
                data-name="Path 4041"
                d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                transform="translate(-7.444 -4.653)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
          </svg>
        </v-list-item-icon>
        <v-list-item-title>
          <router-link
            target="_blank"
            :to="{ path: `/requests/${clientId}/agent-data` }"
            style="text-decoration: none"
            >فتح الطلب</router-link
          >
        </v-list-item-title>
      </v-list-item>

      <v-list-item
        class="black--text font-weight-bold"
        style="border-bottom: 2px solid #ededed"
      >
        <v-list-item-icon class="ml-3">
          <svg
            class="ml-2"
            id="history_2_"
            data-name="history (2)"
            xmlns="http://www.w3.org/2000/svg"
            width="22.654"
            height="23.212"
            viewBox="0 0 22.654 23.212"
          >
            <path
              id="Path_2774"
              data-name="Path 2774"
              d="M29.292,16.287H16.2a.454.454,0,1,1,0-.907H29.292a.454.454,0,1,1,0,.907Zm0,3.95H16.2a.454.454,0,0,1,0-.907H29.292a.454.454,0,1,1,0,.907Zm-8.309,3.955H16.194a.454.454,0,0,1,0-.907h4.789a.454.454,0,0,1,0,.907Zm-1.261,3.95H16.194a.454.454,0,0,1,0-.907h3.529a.454.454,0,1,1,0,.907Zm8.767,6.381A5.946,5.946,0,1,1,32.7,32.79,5.941,5.941,0,0,1,28.489,34.524Zm0-10.976a5.039,5.039,0,1,0,3.573,1.468A5.034,5.034,0,0,0,28.489,23.548Z"
              transform="translate(-11.799 -11.312)"
              :fill="$route.name == 'logs' ? '#fff' : '#2c2c2c'"
            />
            <path
              id="Path_2775"
              data-name="Path 2775"
              d="M43.3,40.448a.454.454,0,0,1-.454-.454V37.274a.454.454,0,1,1,.907,0v2.721A.454.454,0,0,1,43.3,40.448Z"
              transform="translate(-26.613 -23.028)"
              :fill="$route.name == 'logs' ? '#fff' : '#2c2c2c'"
            />
            <path
              id="Path_2776"
              data-name="Path 2776"
              d="M40.243,44.4H37.7a.454.454,0,1,1,0-.907h2.54a.454.454,0,1,1,0,.907Z"
              transform="translate(-23.553 -26.673)"
              :fill="$route.name == 'logs' ? '#fff' : '#2c2c2c'"
            />
            <path
              id="Path_2777"
              data-name="Path 2777"
              d="M19.5,27.617H9.318A2.268,2.268,0,0,1,7.05,25.35V8.678A2.268,2.268,0,0,1,9.318,6.41H26.67a2.268,2.268,0,0,1,2.268,2.268v8.617a.454.454,0,1,1-.907,0V8.678A1.361,1.361,0,0,0,26.67,7.317H9.318A1.361,1.361,0,0,0,7.957,8.678V25.35A1.361,1.361,0,0,0,9.318,26.71H19.5a.454.454,0,1,1,0,.907Z"
              transform="translate(-7.05 -6.41)"
              :fill="$route.name == 'logs' ? '#fff' : '#2c2c2c'"
            />
          </svg>
        </v-list-item-icon>
        <v-list-item-title>
          <router-link
            target="_blank"
            :to="{ path: `/requests/${clientId}/logs` }"
            style="text-decoration: none"
          >
            سجل الطلب
          </router-link>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "ChatDetailsIcon",
  props: ["clientId"],
  methods: {
    to_request_details(item) {
      this.$router.push({
        name: "request_details",
        params: { id: item },
      });
    },

    to_order_request(item) {
      this.$router.push({
        name: "logs",
        params: { id: item },
      });
    },
  },
};
</script>
