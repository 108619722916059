export const TicketIcon = () => {
    return (
        <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="23.194" height="23.212"
             viewBox="0 0 23.194 23.212">
            <g id="ticket_menu" data-name="ticket menu" transform="translate(0)">
                <path id="Path_2758" data-name="Path 2758"
                      d="M93.931,77.839a.631.631,0,0,0-.8-.076,2.521,2.521,0,0,1-3.493-3.506.638.638,0,0,0-.076-.795l-.725-.725A2.525,2.525,0,0,0,87.051,72h0a2.525,2.525,0,0,0-1.785.738L72.92,85.123a2.525,2.525,0,0,0,.006,3.563l.486.479a.636.636,0,0,0,.845.044,2.5,2.5,0,0,1,3.525,3.519.631.631,0,0,0,.038.851l.9.9a2.527,2.527,0,0,0,3.569,0h0l12.36-12.36a2.527,2.527,0,0,0,0-3.569ZM81.382,93.579a1.264,1.264,0,0,1-1.785,0l-.517-.517A3.758,3.758,0,0,0,73.929,87.9l-.107-.107a1.264,1.264,0,0,1,0-1.785l8.406-8.437,7.58,7.58Zm12.36-12.36L90.69,84.271l-7.58-7.586,3.039-3.052a1.24,1.24,0,0,1,.889-.372h0a1.26,1.26,0,0,1,.889.366l.385.385a3.788,3.788,0,0,0,5.064,5.064l.359.359a1.258,1.258,0,0,1,.006,1.785Z"
                      transform="translate(-72.187 -72)"
                fill="$route.name == 'tickets' ? '#fff' : '#2c2c2c'" />
                <path id="Path_2759" data-name="Path 2759"
                      d="M171.445,217.177l-3.058-3.058a1.264,1.264,0,0,0-1.785,0h0l-2.983,2.983a1.264,1.264,0,0,0,0,1.785l3.058,3.058a1.264,1.264,0,0,0,1.785,0l2.983-2.983a1.249,1.249,0,0,0,.032-1.772C171.47,217.177,171.463,217.171,171.445,217.177Zm-3.872,3.872-3.065-3.058,2.983-2.983h0l3.058,3.058Z"
                      transform="translate(-157.508 -204.811)" fill="$route.name == 'tickets' ? '#fff' : '#2c2c2c'" />
            </g>
        </svg>
    )
}