<template>
    <div>
        <v-sheet class="c-block-1">
          <v-row>
            <v-col cols="12">
              <RateFilter  @handleFilter="handleFilter" :role="'7'" />
            </v-col>
          </v-row>
        </v-sheet>
        <br/>
            <v-row>
                <v-col cols="3">
                    <v-sheet class="c-block-1" style=" margin-bottom: 11px;  display: flex; justify-content: space-between;">
                        <h4>
                            رسائل الواتساب
                        </h4>
                        
                        <div class="back-txt" style="padding-left: 0;;" @click="backToAgentPercentage">
                                    عودة
                                    <span>
                                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20 17.418C17.5535 14.4315 15.381 12.737 13.482 12.334C11.5835 11.9315 9.776 11.8705 8.059 12.1515V17.5L0 8.7725L8.059 0.5V5.5835C11.2335 5.6085 13.932 6.7475 16.155 9C18.3775 11.2525 19.6595 14.0585 20 17.418Z" fill="#3F0E40"/>
                                        </svg>
                                        
                                    </span>
                                </div>
                    </v-sheet>
                    <v-sheet class="c-block-1 auc-card2"  style="text-align: center;">
                        <div>
                               
                               <v-progress-linear
                               v-model="whatsapp_msg_percent"
                               :color="'green'"
                               height="25"
                               :rounded="true"
                               :reverse="true"
                               style="pointer-events: none; text-align: right;"
                               >
                               <template v-slot:default="{ value }">
                                   <strong>{{ value }}%</strong>
                               </template>
                               </v-progress-linear>
                           </div>
                            <p style=" direction:ltr;">
                                الردود / اجمالي الرسائل
                            </p>
                            <p style=" direction:ltr;">
                                ( {{whatsapp_msg_count}} / {{whatsapp_msg_s}})
                            </p>
                    </v-sheet>
                </v-col>
                <v-col cols="9">
                    <v-sheet class="c-block-1">
                        <secondary-table-component
                            :showSelect="false"
                            itemKey="id"
                            :headers="headers"
                            :pagination="this.$store.state.pagination"
                            :limit="$store.state.counterOfShow"
                            :items="tableData"
                            :loading="loading"
                            >

                            <template slot="item.star_avg" slot-scope="props">
                                <div>
                               
                               <v-progress-linear
                               v-model="props.item.star_avg"
                               :color="props.item.color"
                               height="25"
                               :rounded="true"
                               :reverse="true"
                               style="pointer-events: none; text-align: right;"
                               >
                               <template v-slot:default="{ value }">
                                   <strong>{{ value.toFixed(2) }}%</strong>
                               </template>
                               </v-progress-linear>
                           </div>
                            </template>
                            <template slot="item.star_avg_c" slot-scope="props">
                                <div>
                                ({{props.item.star_avg_s}} / {{props.item.star_avg_c}}) 
                                </div>
                            </template>
                        </secondary-table-component>
                    </v-sheet>
                </v-col>
            </v-row>
            <br/>
            <v-sheet class="c-block-1">
                <v-row>
                    <v-col cols="12">
                        <h4 class="sub-title">
                            الاستشاريين
                        </h4>
                    </v-col>
                    <v-col cols="12" class="sales-managers-ch">
                        <v-row>
                            <v-col v-for="(item, index) in itemsSalesManagers" :key="index" cols="2">
                            <v-radio-group v-model="selected_sales_manger">
                                <v-radio :label="item.label" :value="item.value"></v-radio>
                            </v-radio-group>
                            </v-col>
                        </v-row>

                    </v-col>

                    <v-col cols="12">

                        <WhatsappMsgChart :xaxis="xaxis" :chartData="chartData"  :chartLabels="chartLabels" />
                    </v-col>
                </v-row>
            </v-sheet>
    </div>
  </template>
  
  <script>
  
  import { axiosInstance } from "@/axios/config";
  import RateFilter from './RateFilter'
  import WhatsappMsgChart from './WhatsappMsgChart'
  import Axios from "axios";
import router from "@/routes";
  
  export default {
    components: {
      WhatsappMsgChart,
      RateFilter
    },
    data() {
      return {
        query_params_obj: {},
        loading: true,
        xaxis:[],
        chartData:[],
        chartLabels:[],
        selected_sales_manger: '',
        whatsapp_msg_s: null,
        whatsapp_msg_count: null,
        whatsapp_msg_percent: null,
      selectedItem: null,
      itemsSalesManagers: [
        { label: 'جميع الاستشاريين', value: '' }
      ],
            headers: [
                { text: "جميع الاقسام", value: "name", align: "center", width: '40%' },
                { text: "النسبه", value: "star_avg", align: "center" },
                { text: "عدد الردود", value: "star_avg_c", align: "center" }
            ],
            tableData:[],
          day_date: '',
          agents: [],
          star_1: 0,
          star_2: 0,
          star_3: 0,
          star_4: 0,
          star_5: 0,
          star_1_c: 0,
          star_2_c: 0,
          star_3_c: 0,
          star_4_c: 0,
          star_5_c: 0,
          star_t_c: 0,
          star_avg: 0,
          valueDeterminate: 50,
        items: [
          {
            label: "اسبوع",
            value: 7 
          },
          {
            label: "اسبوعين",
            value: 14 
          },
          {
            label: "شهر",
            value: 30 
          },
        ],
      };
    },
    watch:{
      selected_sales_manger(val){
        this.getAgentsWhatsappMsg();
      }
    },
    methods: {
      backToAgentPercentage(){
        router.push({
          name: "agent-percentage",
        });
      },
      average_rate_stars(index) {
        if (index <= this.whatsapp_msg_percent) {
          return "mdi-star";
        } else if (Math.round(this.whatsapp_msg_percent) == index) {
          return "mdi-star-half-full";
        } else {
          return "mdi-star-outline";
        }
      },
      average_rate_starsA(index, star_avg_stars) {
        if (index <= star_avg_stars) {
          return "mdi-star";
        } else if (Math.round(star_avg_stars) == index) {
          return "mdi-star-half-full";
        } else {
          return "mdi-star-outline";
        }
      },
      
    getWhatsappMsg(){
        axiosInstance
        .get(`/employee/admin/whatsapp-msg-statistics`, {params: this.query_params_obj})
        .then((res) => {
            this.whatsapp_msg_s = res.data.payload.star_avg_s;
            this.whatsapp_msg_count = res.data.payload.star_avg_c;
            let percent = res.data.payload.star_avg;
            // percent = (percent / 20);
            this.whatsapp_msg_percent = percent.toFixed(2);
        })
        .catch((err) => {
          console.log("eror")
        });
      },
    getSalesWhatsappMsg(){
        axiosInstance
        .get(`/employee/admin/whatsapp-msg-statistics-salesmanagers`, {params: this.query_params_obj})
        .then((res) => {
          console.log("res", res)
          this.tableData = res.data.payload;
          for (let index = 0; index < res.data.payload.length; index++) {
            const element = res.data.payload[index];
            this.itemsSalesManagers.push({
              label: element.name,
              value: element.id
            });
            
          }
          this.loading = false
            // this.whatsapp_msg_count = res.data.payload.star_avg_c;
            // let percent = res.data.payload.star_avg;
            // percent = (percent / 20);
            // this.whatsapp_msg_percent = percent.toFixed(2);
        })
        .catch((err) => {
          console.log("eror")
        });
      },
    getAgentsWhatsappMsg(){
      let data = '';
      if (this.selected_sales_manger != '') {
        data = 'sales_manger_id='+this.selected_sales_manger;
      }
      
      this.xaxis = [],
      this.chartData = [],
      this.chartLabels = [],
        axiosInstance
        .get(`/employee/admin/whatsapp-msg-statistics-salesmanagers-agents?${data}`, {params: this.query_params_obj} )
        .then((res) => {
          console.log("res", res)
          for (let index = 0; index < res.data.payload.length; index++) {
            const element = res.data.payload[index];
            this.xaxis.push(element.name);
            this.chartData.push(element.star_avg);
            this.chartLabels.push( '( ' + element.star_avg_s + ' / ' + element.star_avg_c + ' )' );
            
          }
            // this.whatsapp_msg_count = res.data.payload.star_avg_c;
            // let percent = res.data.payload.star_avg;
            // percent = (percent / 20);
            // this.whatsapp_msg_percent = percent.toFixed(2);
        })
        .catch((err) => {
          console.log("eror")
        });
      },
      handleFilter(obj){
        console.log("obj", obj);
        this.query_params_obj.from_date = obj.startDate;
        this.query_params_obj.to_date = obj.endDate;
        this.getWhatsappMsg();
        this.getSalesWhatsappMsg();
        this.getAgentsWhatsappMsg();
      },
    },
  
    created() {
      this.getWhatsappMsg();
      this.getSalesWhatsappMsg();
      this.getAgentsWhatsappMsg();
    },
  };
  </script>
  <style>
  .title-row{
    border: 1px solid;
    border-radius: 10px;
    padding: 7px 0 0 0;
  }
  .title-sec-1{
    display: flex;
    justify-content: flex-start;
  }
  .title-sec-1 .inner-c{
     display: flex; justify-content: space-around; 
     /* min-width: 30%; */
  }
  /* .title-sec-1{} */
h4.sub-title{
  background-color: #d9d1da;
    padding: 11px 11px;
    margin: 11px 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid;
    color: #451646;
}
  </style>