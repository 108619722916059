<template>
    <div>
        <v-sheet class="c-block-1">
          <v-row>
            <v-col cols="12">
              <RateFilter  @handleFilter="handleFilter" :role="'7'" />
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet class="c-block-1">
            <v-row class=" title-sec-1 title-row">
                <v-col cols="8">
                    <div class="inner-c" style="">
                        <h4 v-if="type == 'positive' || type == '1'">
                            الطلبات الايجابية
                        </h4>
                        <h4 v-if="type == 'negative' || type == '0'">
                            الطلبات السلبية
                        </h4>
                        <h4 v-if="type == 'without' || type == '-1'">
                            الطلبات بدون
                        </h4>
                        <div style="display: contents;">
                            <v-progress-linear
                                v-model="requests_percent"
                                :color="type_color"
                                height="25"
                                style="width: 32rem; pointer-events: none"
                                >
                                <template v-slot:default="{ value }">
                                    <strong>{{ value }}%</strong>
                                </template>
                                </v-progress-linear>
                        </div>
                        <p style="direction: ltr;">
                          ({{ requests_count }} from {{ all_requests_count }})
                        </p>
                    </div>
                </v-col>
                <v-col cols="2"></v-col>
                <v-col cols="2">
                    <div class="back-txt" @click="backToAgentPercentage">
                        عودة
                        <span>
                            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M20 17.418C17.5535 14.4315 15.381 12.737 13.482 12.334C11.5835 11.9315 9.776 11.8705 8.059 12.1515V17.5L0 8.7725L8.059 0.5V5.5835C11.2335 5.6085 13.932 6.7475 16.155 9C18.3775 11.2525 19.6595 14.0585 20 17.418Z" fill="#3F0E40"/>
                            </svg>
                            
                        </span>
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <table-component
                        :showSelect="false"
                        itemKey="id"
                        :headers="headers"
                        :pagination="this.$store.state.pagination"
                        :limit="$store.state.counterOfShow"
                        :items="tableData"
                        :loading="loading"
                        v-model="page"
                        >
                        
                        <template slot="item.statistics" slot-scope="props">
                            <div>
                                <span>
                                    ({{props.item.statistics.count}} طلب)
                                </span>
                               
                                <v-progress-linear
                                v-model="props.item.statistics.percent"
                                :color="props.item.statistics.color"
                                height="25"
                                style="pointer-events: none"
                                >
                                <template v-slot:default="{ value }">
                                    <strong>{{ value.toFixed(2) }}%</strong>
                                </template>
                                </v-progress-linear>
                            </div>
                        </template>
                    </table-component>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <h4 class="sub-title">
                        الاستشاريين
                    </h4>
                </v-col>
                <v-col cols="12" class="sales-managers-ch">
                    <v-row>
                        <v-col v-for="(item, index) in itemsSalesManagers" :key="index" cols="2">
                        <v-radio-group v-model="selected_sales_manger">
                            <v-radio :label="item.label" :value="item.value"></v-radio>
                        </v-radio-group>
                        </v-col>
                    </v-row>

                </v-col>

                <v-col cols="12">

                    <PositiveChart :xaxis="xaxis" :chartData="chartData" :chartColors="chartColors" />
                </v-col>
            </v-row>
        </v-sheet>
    </div>
  </template>
  
  <script>
  
  import { axiosInstance } from "@/axios/config";
  import RateFilter from './RateFilter'
  import PositiveChart from './PositiveChart'
  import Axios from "axios";
import router from "@/routes";
  
  export default {
    components: {
      PositiveChart,
      RateFilter
    },
    data() {
      return {
        query_params_obj: {},
        loading: true,
        xaxis:[],
        chartData:[],
        chartColors:[],
        selected_sales_manger: '',
        type: '',
        type_color: '',
        all_percent: 70,
        requests_count: null,
        all_requests_count: null,
        requests_percent: null,
        selectedItem: null,
        
        itemsSalesManagers: [
          { label: 'جميع الاستشاريين', value: '' }
        ],
          headers: [
              { text: "جميع الاقسام", value: "name", align: "center", width: '40%' },
              { text: "احصائيات طلبات الاقسام", value: "statistics", align: "center" }
          ],
          tableData:[],
          day_date: '',
      };
    },
  
    watch:{
      selected_sales_manger(val){
        this.getAgentsRequests();
      }
    },
    methods: {
      
      backToAgentPercentage(){
        router.push({
          name: "agent-percentage",
        });
      },
    getRequests(){
        axiosInstance
        .get(`/employee/admin/requests-statistics`, {params: this.query_params_obj})
        .then((res) => {
          if(this.$route.params.type == 'positive'){
            this.requests_count = res.data.payload.positive.count;
            this.all_requests_count = res.data.payload.positive.all_count;
            let percent = res.data.payload.positive.percent;
            // percent = (percent / 20);
            this.requests_percent = percent.toFixed(2);
            
          }else if(this.$route.params.type == 'negative'){
            this.requests_count = res.data.payload.negative.count;
            this.all_requests_count = res.data.payload.negative.all_count;
            let percent = res.data.payload.negative.percent;
            // percent = (percent / 20);
            this.requests_percent = percent.toFixed(2);
            
          }else{
            this.requests_count = res.data.payload.none.count;
            this.all_requests_count = res.data.payload.none.all_count;
            let percent = res.data.payload.none.percent;
            // percent = (percent / 20);
            this.requests_percent = percent.toFixed(2);

          }
        })
        .catch((err) => {
          console.log("eror")
        });
      },
    getSalesRequests(){
        axiosInstance
        .get(`/employee/admin/requests-statistics-salesmanagers/${this.type}`, {params: this.query_params_obj})
        .then((res) => {
          console.log("res", res)
          this.tableData = res.data.payload;
          for (let index = 0; index < res.data.payload.length; index++) {
            const element = res.data.payload[index];
            this.itemsSalesManagers.push({
              label: element.name,
              value: element.id
            });
            
          }
          this.loading = false
            // this.reviews_count = res.data.payload.star_avg_c;
            // let percent = res.data.payload.star_avg;
            // percent = (percent / 20);
            // this.reviews_percent = percent.toFixed(2);
        })
        .catch((err) => {
          console.log("eror")
        });
      },
    getAgentsRequests(){
      let data = '';
      if (this.selected_sales_manger != '') {
        data = 'sales_manger_id='+this.selected_sales_manger;
      }
      
      this.xaxis = [];
      this.chartData = [];
      this.chartColors = [];
        axiosInstance
        .get(`/employee/admin/requests-statistics-salesmanagers-agents?type=${this.type}&${data}`, {params: this.query_params_obj} )
        .then((res) => {
          console.log("res", res)
          for (let index = 0; index < res.data.payload.length; index++) {
            const element = res.data.payload[index];
            this.xaxis.push(element.name);
            this.chartData.push(element.statistics.percent);
            this.chartColors.push(element.statistics.color);
            
          }
            // this.reviews_count = res.data.payload.star_avg_c;
            // let percent = res.data.payload.star_avg;
            // percent = (percent / 20);
            // this.reviews_percent = percent.toFixed(2);
        })
        .catch((err) => {
          console.log("eror")
        });
      },
      
      handleFilter(obj){
        // console.log("obj", obj);
        this.query_params_obj.from_date = obj.startDate;
        this.query_params_obj.to_date = obj.endDate;
        this.getRequests();
        this.getSalesRequests();
        this.getAgentsRequests();
      },
    },
  
    created() {
      if(this.$route.params.type == 'positive'){
        this.type = '1';
        this.type_color = '#4caf50';
        
      }else if(this.$route.params.type == 'negative'){
        this.type = '0';
        this.type_color = '#ee0000';
        
      }else{
        this.type = '-1';
        this.type_color = 'yellow';

      }
      this.getRequests();
      this.getSalesRequests();
      this.getAgentsRequests();
    },
  };
  </script>
  <style>
  .title-row{
    border: 1px solid;
    border-radius: 10px;
    padding: 7px 0 0 0;
  }
  .title-sec-1{
    display: flex;
    justify-content: flex-start;
  }
  .title-sec-1 .inner-c{
     display: flex; justify-content: space-around; 
     /* min-width: 30%; */
  }
  /* .title-sec-1{} */
h4.sub-title{
  background-color: #d9d1da;
    padding: 11px 11px;
    margin: 11px 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid;
    color: #451646;
}
  </style>