<template>
  <div>
    <div class="vRowToShowData">
      <v-sheet class="mx-auto mb-7" v-if="this.startElement.length > 0">
        <v-slide-group multiple show-arrows>
          <v-slide-item v-for="(person, index) in startElement" :key="index">
            <div class="starContainer">
              <img src="@/assets/icons/chipStar.svg" alt="image" />
              <span class="m-5">{{ person?.name || person.user_name }}</span>
              <v-icon
                class="exitIcon"
                @click="starToggle(person['id'])"
                style="color: white; font-size: 15px"
                >mdi-close</v-icon
              >
            </div>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>

      <v-card flat>
        <v-layout row wrap>
          <v-col
            cols="12"
            lg="3"
            md="4"
            sm="6"
            xs="12"
            class="px-2 py-1"
            v-for="(item, i) in CardData"
            :key="i"
          >
            <v-card class="pa-3 position-relative cardContainer">
              <span class="right-color requestType" :style="returnBorder(item)"></span>
              <div class="card-head d-flex justify-space-between align-center">
                <v-card-title class="pa-0 cardTitle">
                  {{ item.name }}
                </v-card-title>

                <div class="icons">
                  <input
                    class="star ml-3"
                    type="checkbox"
                    :checked="!item.starChecked"
                    @change="starToggle(item.id)"
                  />
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        height="30"
                        color="transparent"
                        depressed
                        fab
                        light
                        v-bind="attrs"
                        v-on="on"
                        style="margin-left: -10px"
                      >
                        <img src="@/assets/icons/eyeIcon.svg" />
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        @click="to_request_details(item.id)"
                        v-if="item.has_request === 'Yes'"
                      >
                        <v-list-item-icon class="ml-3">
                          <img src="@/assets/icons/titcketIcon.svg" alt="image" />
                        </v-list-item-icon>
                        <v-list-item-title> فتح الطلب </v-list-item-title>
                      </v-list-item>

                      
                      <v-list-item
                        @click="openRequestInNewTap(item)"
                        v-if="item.has_request === 'Yes'"
                      >
                        <v-list-item-icon class="ml-3">
                          <img src="@/assets/icons/titcketIcon.svg" alt="image" />
                        </v-list-item-icon>
                        <v-list-item-title> فتح الطلب ف تاب جديد </v-list-item-title>
                      </v-list-item>

                      <!-- <router-link
                        v-if="item.has_request === 'Yes'"
                        style="text-decoration: none"
                        target="_blank"
                        :to="{
                          name: 'request_details',
                          params: { id: item.request?.id },
                        }"
                      >
                        <v-list-item>
                          <v-list-item-icon class="ml-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18.528"
                              height="15.111"
                              viewBox="0 0 13.528 10.111"
                            >
                              <g
                                id="Icon_feather-eye-dark"
                                data-name="Icon feather-eye-dark"
                                transform="translate(-1 -5.5)"
                              >
                                <path
                                  id="Path_4040"
                                  data-name="Path 4040"
                                  d="M1.5,10.556S3.778,6,7.764,6s6.264,4.556,6.264,4.556-2.278,4.556-6.264,4.556S1.5,10.556,1.5,10.556Z"
                                  fill="none"
                                  stroke="#000"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1"
                                />
                                <path
                                  id="Path_4041"
                                  data-name="Path 4041"
                                  d="M16.917,15.208A1.708,1.708,0,1,1,15.208,13.5,1.708,1.708,0,0,1,16.917,15.208Z"
                                  transform="translate(-7.444 -4.653)"
                                  fill="none"
                                  stroke="#000"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="1"
                                />
                              </g>
                            </svg>
                          </v-list-item-icon>
                          <v-list-item-title> فتح في تاب جديد</v-list-item-title>
                        </v-list-item>
                      </router-link> -->

                      <v-list-item @click="to_supportRequest_details(item.id)">
                        <v-list-item-icon class="ml-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.599"
                            height="17.599"
                            viewBox="0 0 17.599 17.599"
                          >
                            <path
                              id="support"
                              d="M20.746,12.41v-.463a6.947,6.947,0,1,0-13.894,0v.463A1.852,1.852,0,0,0,5,14.262v2.779a1.852,1.852,0,0,0,1.852,1.852h.083a1.389,1.389,0,0,0,2.7-.463V12.873a1.389,1.389,0,0,0-1.852-1.311,6.021,6.021,0,0,1,12,0,1.389,1.389,0,0,0-1.815,1.311v5.557a1.389,1.389,0,0,0,1.389,1.389h.153a3.242,3.242,0,0,1-2.932,1.852H15.189v-.463A1.389,1.389,0,1,0,13.8,22.6h2.779a4.168,4.168,0,0,0,4.14-3.7h.028A1.852,1.852,0,0,0,22.6,17.041V14.262A1.852,1.852,0,0,0,20.746,12.41ZM5.926,17.041V14.262a.926.926,0,0,1,.926-.926v4.631A.926.926,0,0,1,5.926,17.041ZM8.242,12.41a.463.463,0,0,1,.463.463v5.557a.463.463,0,1,1-.926,0V12.873A.463.463,0,0,1,8.242,12.41ZM13.8,21.672a.463.463,0,1,1,.463-.463v.463Zm5.557-2.779a.463.463,0,0,1-.463-.463V12.873a.463.463,0,0,1,.926,0v5.557A.463.463,0,0,1,19.357,18.894Zm2.316-1.852a.926.926,0,0,1-.926.926V13.336a.926.926,0,0,1,.926.926Z"
                              transform="translate(-5 -5)"
                            />
                          </svg>
                        </v-list-item-icon>
                        <v-list-item-title>
                          <router-link
                            :to="{ path: `/admin/help_desk/${item.id}` }"
                            style="text-decoration: none"
                          >
                            فتح الدعم الفني
                          </router-link>
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item>
                        <v-list-item-icon class="ml-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.599"
                            height="17.599"
                            viewBox="0 0 17.599 17.599"
                          >
                            <path
                              id="support"
                              d="M20.746,12.41v-.463a6.947,6.947,0,1,0-13.894,0v.463A1.852,1.852,0,0,0,5,14.262v2.779a1.852,1.852,0,0,0,1.852,1.852h.083a1.389,1.389,0,0,0,2.7-.463V12.873a1.389,1.389,0,0,0-1.852-1.311,6.021,6.021,0,0,1,12,0,1.389,1.389,0,0,0-1.815,1.311v5.557a1.389,1.389,0,0,0,1.389,1.389h.153a3.242,3.242,0,0,1-2.932,1.852H15.189v-.463A1.389,1.389,0,1,0,13.8,22.6h2.779a4.168,4.168,0,0,0,4.14-3.7h.028A1.852,1.852,0,0,0,22.6,17.041V14.262A1.852,1.852,0,0,0,20.746,12.41ZM5.926,17.041V14.262a.926.926,0,0,1,.926-.926v4.631A.926.926,0,0,1,5.926,17.041ZM8.242,12.41a.463.463,0,0,1,.463.463v5.557a.463.463,0,1,1-.926,0V12.873A.463.463,0,0,1,8.242,12.41ZM13.8,21.672a.463.463,0,1,1,.463-.463v.463Zm5.557-2.779a.463.463,0,0,1-.463-.463V12.873a.463.463,0,0,1,.926,0v5.557A.463.463,0,0,1,19.357,18.894Zm2.316-1.852a.926.926,0,0,1-.926.926V13.336a.926.926,0,0,1,.926.926Z"
                              transform="translate(-5 -5)"
                            />
                          </svg>
                        </v-list-item-icon>
                        <v-list-item-title>
                          <router-link
                            :to="{ path: `/admin/help_desk/${item.id}` }"
                            style="text-decoration: none"
                            target="_blank"
                          >
                            فتح الدعم الفني في تاب جديد
                          </router-link>
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item
                        @click="toOrderRequest(item)"
                        v-if="item.has_request === 'Yes'"
                      >
                        <v-list-item-icon class="ml-2">
                          <OrderHistory />
                        </v-list-item-icon>
                        <v-list-item-title>سجل الطلب</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>

              <div
                class="req-status-parent mt-1 d-flex justify-space-between align-center"
              >
                <span class="req-type" style="color: #545a69; font-size: 16px">
                  {{ item.mobile || "لا يوجد رقم هاتف" }}
                </span>
                <span class="req-status spanRequestType" :style="returnBorder(item)">
                  {{ returnStatusValue(item.status) }}
                </span>
              </div>

              <div class="req-date-parent my-2 d-flex justify-space-between align-center">
                <span class="req-date" style="color: #545a69"> هل لديه رقم تمويل ؟ </span>
                <span class="req-status letterStyle">
                  {{ item.has_request === "Yes" ? "نعم" : "لا" }}
                </span>
              </div>
              <hr class="lineStyle" />
              <div class="desc parent">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-card-text v-on="on" class="pt-2 px-0" style="color: #545a69">
                      {{ item.descrebtion || "لايوجد ملاحظات" }}
                    </v-card-text>
                  </template>
                </v-tooltip>
              </div>
            </v-card>
          </v-col>
        </v-layout>
        <v-card v-intersect="infiniteScrolling"></v-card>
      </v-card>
    </div>
    <OrderRequestDialog />
  </div>
</template>

<script>
import OrderRequestDialog from "@/components/AdminComponent/OrderRequestDialog.vue";
import { OrderHistory } from "@/assets/adminIcons/orderHistory";
import { axiosInstance } from "@/axios/config";

export default {
  name: "AdminCard",
  props: ["CardData"],
  components: { OrderRequestDialog, OrderHistory },
  mounted() {
    this.cpCardData = structuredClone(this.CardData);
  },
  data() {
    return {
      startElement: [],
      cpCardData: [],
      page: 1,
    };
  },

  computed: {
    returnVacations() {
      return window.location.pathname === "/admin/vacations/agent_vacation";
    },
  },

  methods: {
    emitObjects(val) {
      this.$emit("pageCount", val);
    },

    returnBorder(item) {
      if (item.status === 0) {
        return `background-color: #017F85`;
      } else if (item.status === 1) {
        return `background-color: #41BF72`;
      } else if (item.status === 2) {
        return `background-color: #DEB523`;
      } else {
        return `background-color: #FE705B`;
      }
    },

    infiniteScrolling(entries, observer, isIntersecting) {
      setTimeout(() => {
        this.page++;
        this.$emit("pageCount", this.page);
      }, 100);
    },

    to_supportRequest_details(item) {
      this.$router.push({
        name: "help_desk",
        params: { id: item },
      });
    },

    toOrderRequest(item) {
      this.$store.state.orderRequestId = item.request?.id;
      if (this.$store.state.showCards || this.$store.state.showCards == false) {
        this.$store.state.orderRequestDialog = true;
      } else {
        this.$store.state.openOrderRequestSection = true;
      }
    },

    openRequestInNewTap(item){
        axiosInstance
        .get(`/employee/collaborator/request-of-customer-helpdesk/${item.id}`, {
          headers: this.request_headers(),
        })
        .then((res) => {
          const routeURL = this.$router.resolve({
            name: "request_details",
            params: { id: res.data.payload.id }
          }).href;
          window.open(routeURL, '_blank');
        });
    },

    to_request_details(item) {
      axiosInstance
        .get(`/employee/collaborator/request-of-customer-helpdesk/${item}`)
        .then((res) => {
          this.requestDetails = res.data.payload.id;

          this.$router.push({
            name: "request_details",
            params: { id: res.data.payload.id },
          });
        });
    },
    returnStatusValue(status) {
      if (status == 0) {
        return "جديد";
      } else if (status == 1) {
        return "تم فتحه";
      } else if (status == 2) {
        return "مكتمل";
      } else {
        return "ملغي";
      }
    },
    starToggle(id) {
      this.cpCardData = this.CardData.map((ele, index) => {
        if (ele.id == id) {
          ele.starChecked = !ele.starChecked;
          if (ele.starChecked) {
            this.startElement.push(ele);
          } else {
            let targetItem = this.startElement.findIndex((item) => item.id === ele.id);
            if (targetItem !== -1) {
              this.startElement.splice(targetItem, 1);
            }
          }
          return ele;
        }
        return ele;
      });
    },
    deleteSelectedPerson(index, person) {
      this.starToggle(person.id);
    },
  },
};
</script>

<style lang="scss">
.lineStyle {
  border: 1px solid #000000;
  opacity: 0.1;
  margin-bottom: 0px;
}

.letterStyle {
  color: #074c74;
  font-size: 14px;
}

.starContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  background: #984abe;
  color: white;
  border-radius: 4px;
  padding: 15px;
}

.cardContainer {
  height: 100%;
  background-color: #f4f2f4 !important;
  border: 1px solid #d4d5d5;
  position: relative;
}

.star {
  visibility: hidden;
  font-size: 30px;
  cursor: pointer;
  color: purple;
}

.star:before {
  content: "\2605";
  position: absolute;
  visibility: visible;
  top: 0px;
  color: purple;
}

.star:checked:before {
  content: "\2606";
  position: absolute;
  top: 0px;
}

.spanRequestType {
  font-size: 13px;
  padding: 0px 5px 5px;
  color: white;
}

.vRowToShowData .cardTitle {
  color: #3f0f41;
  font-size: 16px;
  font-weight: bold;
}

.vRowToShowData .requestType {
  position: absolute;
  width: 6px;
  height: 101.2%;
  top: -1px;
  right: -6px;
}

.vRowToShowData {
  padding-top: 11px !important;
}

.vChipLoadingIcon .exitIcon {
  font-size: 13px;
  position: absolute;
  left: 3px;
  top: 4px;
  cursor: pointer;
}

.vChipLoadingIcon img {
  margin-left: 5px;
}

.vChipLoadingIcon {
  clip-path: unset !important;
  border-radius: 5px !important;
  height: 36px;
  flex-shrink: 0;
  padding-left: 30px !important;
}

.vSheetContainer {
  height: 52px;
  overflow: hidden;
  border-radius: 5px;
  max-width: 100% !important;
  margin-top: 30px;
}

.app {
  display: grid;
  place-items: center;
  background-color: transparent;
  height: 50vh;
}

.no-requests {
  flex: 1;
  overflow-y: hidden;
}

.v-chip.v-chip.v-chip--outlined.v-chip.v-chip {
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
  clip-path: polygon(0% 50%, 10% 0%, 100% 0%, 100% 100%, 10% 100%, 0% 50%) !important;
  background-color: #3f0e40 !important;
  color: white !important;
  padding-left: 20px !important;
}

.v-btn {
  &:focus {
    &:before {
      background-color: white !important;
    }
  }
}

.selected-slider {
  .v-slide-group__content {
    align-items: center !important;
  }

  .theme--light.v-icon.v-icon:not(.mdi-close) {
    background-color: #f2f2f2 !important;
    border-color: #f2f2f2 !important;
    font-size: 40px;
    color: black;
  }
}
</style>
