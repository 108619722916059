<template>
    <v-subheader style="background-color: white;
            margin-bottom: 40px;
            margin-top: 30px;
            border-radius: 4px;
            display: flex;
            justify-content: space-between;
            height: 80px;
            border: 2px solid #E4E5E7;">
        <div class="text-center buttonContainer">
            <v-btn class="ma-2 white--text" :style="{ 'background-color': orderCaseRequestActive ? 'purple' : 'rgb(251 246 245)' }"
             @click="ordeCaseRequest('تقرير تصنيفات الطلب')">
             <span style="margin-left: 20px;" v-if="orderCaseRequestActive">
                    
                    <img id="logo" contain src='@/assets/ReportAsk.png' />
                </span>
                <span style="margin-left: 20px;" v-else>
                    
                    <img id="logo" contain src='@/assets/reportBlue.svg' />
                </span>
                <span :style="{ 'color': orderCaseRequestActive ? 'white' : 'purple' }">
                    تقرير تصنيفات الطلب
                
                </span>
            </v-btn>


            <v-btn class="ma-2 white--text" :style="{ 'background-color': basketRequestActive ? 'purple' : 'rgb(251 246 245)' }"
                @click="pasketRequest('تقرير سلال الطلب')">

                <span style="margin-left: 20px;" v-if="basketRequestActive">
                    <v-img id="logo" contain src="@/assets/reportOneWhite.svg" />
                </span>
                <span style="margin-left: 20px;" v-else>
                    <v-img id="logo" contain src="@/assets/Report.png" />
                </span>
                <span :style="{ 'color': basketRequestActive ? 'white' : 'purple' }">
                    تقرير سلال الطلب
                </span>
            </v-btn>

              
            <v-btn class="ma-2" :style="{ 'background-color': typesRequestsActive ? 'purple' : 'rgb(251 246 245)' }"
                @click="typesRequest('تقرير حالات الطلب')">
                <span style="margin-left: 20px;" v-if="typesRequestsActive">
                    <v-img id="logo" contain src="@/assets/listWhite.svg" />
                </span>
                <span style="margin-left: 20px;" v-else>
                    <v-img id="logo" contain src="@/assets/List.png" />
                </span>
                <span :style="{ 'color': typesRequestsActive ? 'white' : 'purple' }">
                    تقرير حالات الطلب
                </span>
            </v-btn>

        </div>
        <div style="display: flex;">
            <v-btn
          depressed
          width="45"
          height="45"
          class="ml-2 hidden-sm-and-down"
          :color="$vuetify.theme.themes.light.dataActionBTN"
          dark
          fab
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.116"
            height="18.116"
            viewBox="0 0 16.116 16.116"
          >
            <g
              id="Icon_feather-printer"
              data-name="Icon feather-printer"
              transform="translate(-2.5 -2.5)"
            >
              <path
                id="Path_4011"
                data-name="Path 4011"
                d="M9,8.291V3h9.069V8.291"
                transform="translate(-2.977)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
              <path
                id="Path_4012"
                data-name="Path 4012"
                d="M6.023,20.3H4.512A1.512,1.512,0,0,1,3,18.791V15.012A1.512,1.512,0,0,1,4.512,13.5H16.6a1.512,1.512,0,0,1,1.512,1.512v3.779A1.512,1.512,0,0,1,16.6,20.3H15.093"
                transform="translate(0 -5.209)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
              <path
                id="Path_4013"
                data-name="Path 4013"
                d="M9,21h9.069v6.046H9Z"
                transform="translate(-2.977 -8.931)"
                fill="none"
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
              />
            </g>
          </svg>
        </v-btn>
            <v-btn
          depressed
          width="45"
          height="45"
          :color="$vuetify.theme.themes.light.dataActionBTN"
          dark
          fab
          class="ml-1 hidden-sm-and-down"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.116"
            height="18.116"
            viewBox="0 0 12.25 14"
          >
            <g id="excel-file" transform="translate(-1.5)">
              <path
                id="Path_4097"
                data-name="Path 4097"
                d="M11.708,14H1.792a.292.292,0,0,1-.292-.292V3.548a.292.292,0,0,1,.074-.194L4.491.1A.292.292,0,0,1,4.708,0h7A.292.292,0,0,1,12,.292V7a.292.292,0,0,1-.583,0V.583H4.839L2.083,3.659v9.757h9.333V12.25a.292.292,0,1,1,.583,0v1.458A.293.293,0,0,1,11.708,14Z"
                fill="#fff"
              />
              <path
                id="Path_4098"
                data-name="Path 4098"
                d="M4.917,4.292H2.292a.292.292,0,0,1,0-.583H4.625V.792a.292.292,0,0,1,.583,0V4A.292.292,0,0,1,4.917,4.292Zm8.167,8.167h-7a.876.876,0,0,1-.875-.875v-3.5a.876.876,0,0,1,.875-.875h7a.876.876,0,0,1,.875.875v3.5A.876.876,0,0,1,13.083,12.458Zm-7-4.667a.292.292,0,0,0-.292.292v3.5a.292.292,0,0,0,.292.292h7a.293.293,0,0,0,.292-.292v-3.5a.293.293,0,0,0-.292-.292Z"
                transform="translate(-0.208 -0.208)"
                fill="#fff"
              />
              <path
                id="Path_4099"
                data-name="Path 4099"
                d="M11.29,15.67,10.566,14.6h.3a.075.075,0,0,1,.047.012.1.1,0,0,1,.027.03l.572.878a.4.4,0,0,1,.032-.07l.54-.8a.186.186,0,0,1,.03-.036.058.058,0,0,1,.039-.012h.283l-.726,1.052.751,1.139h-.293a.079.079,0,0,1-.053-.017.176.176,0,0,1-.031-.038l-.588-.919a.316.316,0,0,1-.027.061l-.572.858a.21.21,0,0,1-.032.039.065.065,0,0,1-.05.018h-.275l.755-1.125Zm1.754.876h.948V16.8H12.747V14.6h.3Zm2.4-1.6a.117.117,0,0,1-.03.034.059.059,0,0,1-.039.012.111.111,0,0,1-.061-.026q-.042-.031-.086-.058a.805.805,0,0,0-.128-.057.558.558,0,0,0-.18-.026.575.575,0,0,0-.176.027.377.377,0,0,0-.128.073.279.279,0,0,0-.076.109.348.348,0,0,0-.026.134.243.243,0,0,0,.045.152.385.385,0,0,0,.119.1.952.952,0,0,0,.168.074l.193.065c.065.023.131.048.192.075a.658.658,0,0,1,.168.107.486.486,0,0,1,.12.158.515.515,0,0,1,.045.229.735.735,0,0,1-.049.269.632.632,0,0,1-.143.22.672.672,0,0,1-.231.146.849.849,0,0,1-.311.054.956.956,0,0,1-.389-.077.908.908,0,0,1-.3-.209l.086-.14a.107.107,0,0,1,.03-.029.07.07,0,0,1,.038-.012.118.118,0,0,1,.073.034.946.946,0,0,0,.1.076.608.608,0,0,0,.366.107.577.577,0,0,0,.188-.029.378.378,0,0,0,.258-.373.269.269,0,0,0-.045-.163.38.38,0,0,0-.118-.106.832.832,0,0,0-.167-.072l-.192-.061a2.009,2.009,0,0,1-.192-.073.608.608,0,0,1-.167-.109.484.484,0,0,1-.118-.164.571.571,0,0,1-.045-.244.579.579,0,0,1,.177-.418.622.622,0,0,1,.211-.134.75.75,0,0,1,.29-.05.921.921,0,0,1,.335.058.773.773,0,0,1,.264.169l-.072.141Z"
                transform="translate(-3.765 -6.075)"
                fill="#fff"
              />
            </g>
          </svg>
        </v-btn>
        

            <!-- <span style="padding: 5px;"><img style="width: 35px; height: 40px; " id="logo" contain
                    src="@/assets/Eyes.png" /></span> -->
        </div>
    </v-subheader>
</template>     


<style>
.buttonContainer .theme--light.v-btn.v-btn--has-bg {
    margin-top: 7px;
    border: 1px solid purple;
    color: #6982ec;
    font-weight: bold;
    box-shadow: 0 0 0 0;
    border-radius: 4px;
    color: #8F8CEC !important;
    border-radius: 4px;
    padding: 20px !important;
}
</style>

<script>
export default {
    name: "SubHeader",
    props: ['name', 'typesRequestsActive', "basketRequestActive" , 'orderCaseRequestActive'],



    data() {
        return {
            activeSrcReport: "ReportAsk.png",
            model: null,
        }
    },

    methods: {
        ordeCaseRequest(title) {
            this.$emit("ordeCaseRequest", title)
        },

        pasketRequest(title) {
            this.$emit("pasketRequest", title);
        },

        typesRequest(title) {
            this.$emit("typesRequest", title)
        },
    },
}
</script>