<template>
                <v-card class="pa-3 position-relative" style="height: 100%">
                  <span class="right-color requestType"
                    :style="`${item.status == `طلب تم فتحه` ? `background-color:#40BE72`
                : item.status == `طلب جديد` ? `background-color:#3B5DE7` : item.status == `مؤرشف لدى استشاري المبيعات`
                  ? `background-color:#017E84` : item.status == `طلب مرفوض` ? `background-color: indianred`
                    : item.status == `في انتظار موافقة مدير المبيعات` ? `background-color: #FF715B` : `background-color:black`}`">
                  </span>
                  <div class="card-head d-flex justify-space-between align-center">
                    <v-card-title class="pa-0 requestTitle">
                      {{ item.customer?.name}}
                    </v-card-title>
                    
                    <div class="icons" v-if="canopen == 1">
                      <v-btn v-if="$route.name == 'all' || $route.name == 'following' || $route.name == 'SMEAllRequests'" @click="select_element(item)" width="30" height="30" color="transparent" depressed fab>
                        <v-icon size="30" :color="in_selected_elements(item) ? 'primary' : '#999898'">
                          {{ in_selected_elements(item) ?"mdi-hexagram": "mdi-crop-square" }}
                        </v-icon>
                      </v-btn>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn width="30" height="30" color="transparent" depressed fab light v-bind="attrs" v-on="on"
                            style="margin-left: -10px">
                            <img src="@/assets/icons/eyeIcon.svg" />
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="to_request_details(item)">
                            <v-list-item-icon class="ml-3">
                              <img src="@/assets/icons/titcketIcon.svg" />
                            </v-list-item-icon>
                            <v-list-item-title>فتح</v-list-item-title>
                          </v-list-item>

                          <router-link
                            style="text-decoration: none"
                            target="_blank"
                            :to="{
                                    name: 'salesManager_request_details',
                                    params: { id: item.id },
                                  }"
                          >
                            <v-list-item>
                              <v-list-item-icon class="ml-3">
                                <img src="@/assets/icons/titcketIcon.svg" />
                              </v-list-item-icon>
                              <v-list-item-title> فتح في تاب جديد</v-list-item-title>
                            </v-list-item>
                          </router-link>

                          <!-- <v-list-item @click="to_tickets_page(item)">
                            <v-list-item-icon class="ml-3">
                              <img src="@/assets/icons/ticketIcon.svg" />
                            </v-list-item-icon>
                            <v-list-item-title>التذاكر</v-list-item-title>
                          </v-list-item> -->
                        </v-list>
                       
                      </v-menu>
                    </div>
                  </div>
                  <div class="req-status-parent mt-1 d-flex justify-space-between align-center">
                    <span class="req-type" style="color: #444B5A ; font-size: 15px;">
                      {{ item.type || "لايوجد نوع للطلب" }}
                    </span>
                    <span class="req-status spanRequestType" :style="`${item.status == `طلب تم فتحه` ? `background-color:#40BE72` : item.status == `طلب جديد`
                ? `background-color:#3B5DE7` : item.status == `مؤرشف لدى استشاري المبيعات` ? `background-color:#017E84`
                  : item.status == `طلب مرفوض` ? `background-color: indianred` : item.status == `في انتظار موافقة مدير المبيعات`
                    ? `background-color: #FF715B` : `background-color:black`}`">{{ item.status }}
                    </span>
                  </div>
                  <div  class="req-status-parent mt-1 d-flex justify-space-between align-center">
                    <span class="req-type" style="color: #444B5A ; font-size: 15px;">
                      استشاري المبيعات1
                    </span>
                    <span class="req-status " >
                      {{ item.user.name }}
                    </span>
                  </div>

                  <div class="req-date-parent my-2 d-flex justify-space-between align-center">
                    <span class="req-date">
                      {{ item.agent_date.split(" ")[0] }}
                    </span>
                    <span class="req-status" :style="`color: #444B5A ; font-size: 15px;`">
                      {{ item.class_id_agent_text || "لايوجد تصنيف معاملة" }}
                    </span>
                  </div>
                  <div class="req-date-parent my-2 d-flex justify-space-between align-center">
                    <span class="req-date">
                      هل تم استلامة من قبل الجودة
                    </span>
                    <span class="req-status" :style="`color: #444B5A ; font-size: 15px;`">
                      {{ item.class_id_quality ? "نعم" : "لا" }}
                    </span>
                  </div>
                  <hr style="height: 1px !important; opacity: 0.3" class="mb-0" />
                  <div class="desc parent">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-card-text v-on="on" class="pt-2 px-0">
                          {{
                            item.agent_notes ? item.agent_notes.length > 50
                              ? item.agent_notes.substring(0, 50) + "..." : item.agent_notes : "لايوجد ملاحظات"
                          }}
                        </v-card-text>
                      </template>
                      <div style="color: #444B5A ; font-size: 14px;">{{ item.agent_notes || "لايوجد ملاحظات" }}</div>
                    </v-tooltip>
                  </div>
                </v-card>
</template>

<script>

export default {
    name: "RelatedAgentCardItem",
    props:{
        item : {
            required : true
        },
        canopen : {
            required : false,
            default: 0
        }
    },
    methods: {
        
        to_request_details(item) {
            this.$router.push({
            name: "salesManager_request_details",
            params: { id: item.id },
            });
        },
        in_selected_elements(item) {
          return this.$store.state.elements.some((el) => {
            return el.id == item.id;
          });
        }

    },
};
</script>
