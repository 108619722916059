import { render, staticRenderFns } from "./RateServices.vue?vue&type=template&id=11c5d894&scoped=true&"
import script from "./RateServices.vue?vue&type=script&setup=true&lang=js&"
export * from "./RateServices.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./RateServices.vue?vue&type=style&index=0&id=11c5d894&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11c5d894",
  null
  
)

export default component.exports