<template>
  <div>
    <v-row style="margin: 20px">
      <v-card style="width: 100%">
        <v-card-title style="display: flex; justify-content: space-between">
          <span>سجل الطلب</span>
          <v-icon color="#3F0E40" @click="$store.state.openOrderRequestSection = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <div v-if="!loading">
            <v-container>
              <v-sheet class="px-5" style="margin-top: 10px; padding-top: 15px">
                <v-timeline class="my-10" align-top dense style="margin-right: 45px">
                  <v-timeline-item
                    color="primary"
                    small
                    v-for="(item, index) in request_logs"
                    :key="item.id"
                  >
                    <template v-slot:icon>
                      <div
                        style="
                          position: absolute;
                          right: -45px;
                          color: #444b5a;
                          font-size: 12px;
                        "
                      >
                        <div>
                          {{ item.raw_date.split("T")[0] }}
                        </div>

                        <div>
                          {{ item.raw_date.split("T")[1].split(".")[0] }}
                        </div>
                      </div>
                    </template>
                    <v-alert
                      border="left"
                      colored-border
                      :color="getAlertColor(index)"
                      elevation="2"
                      style="
                        border: 1px solid #c5c5c5;
                        box-shadow: unset !important;
                        background-color: #fbfbfb;
                        padding: 5px 15px !important;
                        font-size: 14px;
                        margin-top: 2px;
                        font-weight: bold;
                        border-radius: 6px;
                      "
                      class="ml-5"
                    >
                      <div style="position: absolute; right: -15px; top: 15px">
                        <img src="@/assets/icons/Polygon.svg" alt="image" />
                      </div>
                      <div class="pr-4 info-dad">
                        <span style="font-size: 18px; font-weight: bold">{{
                          item.title
                        }}</span>
                      </div>
                      <div class="my-2">
                        <span >
                          {{ item.text }} : 
                          <span v-if="!item.text.includes('مكالمة جديده')">
                            {{ item.value }}

                          </span>
                          <button
                            class="mobile-link"
                            v-if="item.text.includes('طلب دعم فني')"
                            @click="
                              to_supportRequest_details(item.text.match(/\b\d+\b/g))
                            "
                          >
                            فتح طلب الدعم
                          </button>

                          <button
                            class="mobile-link"
                            v-if="item.text.includes('مكالمة جديده')"
                            @click="to_call_voice(item.text.match(/\b\d+\b/g))"
                          >
                            فتح المكالمة
                          </button>

                          <button
                            class="mobile-link"
                            v-if="item.text.includes('أضافة تذكرة برقم')"
                            @click="to_task_content(item.text.match(/\b\d+\b/g))"
                          >
                            محتوي التذكرة
                          </button>
                        </span>
                      </div>
                    </v-alert>
                  </v-timeline-item>
                </v-timeline>
              </v-sheet>
            </v-container>
          </div>
          <div v-else>
            <LoadingMore />
          </div>
        </v-card-text>
      </v-card>
    </v-row>
    <OneCallsDialog />
    <OneContentDialog />
  </div>
</template>

<script>
import LoadingMore from "../Charts/LoadingMore.vue";
import OneCallsDialog from "@/components/Charts/OneCallsDialog.vue";
import OneContentDialog from "@/components/Charts/OneContentDialog.vue";
import { axiosInstance } from "@/axios/config";

export default {
  props: ["agent"],
  components: { LoadingMore, OneCallsDialog, OneContentDialog },
  data() {
    return {
      request_logs: [],
      date: {
        birth_date: "",
        birth_date_higri: "",
      },
      birth_date: true,
      loading: false,
    };
  },
  created() {
    if (this.$store.state.orderRequestId !== null) {
      this.get_request_logs();
    }
  },
  methods: {
    getAlertColor(index) {
      return index % 2 === 0 ? "#90DBAE" : "#e6b5e6";
    },

    to_supportRequest_details(requestId) {
      this.$store.state.orderRequestDialog = false;

      this.$router.push({
        name: "help_desk",
        params: { id: requestId[0] },
      });
    },

    to_call_voice(callId) {
      this.$store.state.idOfOneCall = callId;
      this.$store.state.openOneCallDialog = true;
    },

    to_task_content(taskId) {
      console.log(taskId + "taskId is");
      this.$store.state.idOfOneTaskContent = taskId;
      this.$store.state.openOneTaskContent = true;
    },

    get_request_logs() {
      if (this.$store.state.orderRequestId) {
        this.loading = true;
        axiosInstance
          .get(`/agent/Request/History-With-Notes/${this.$store.state.orderRequestId}`)
          .then((response) => {
            this.request_logs = response.data.payload;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
  },
  watch: {
    "$store.state.orderRequestId": {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.get_request_logs();
        }
      },
    },
    agent_data: {
      handler(val) {
        val.customer.birth_date = this.date.birth_date;
        val.customer.birth_date_higri = this.date.birth_date_higri;
        val.source = parseInt(val.source);
        val.customer.work = parseInt(val.customer.work);
        this.setBreadCrumbs(["الرئيسية", "الطلبات", val.customer.name]);
      },
    },
  },
};
</script>
<style lang="scss">
.log-page {
  .v-timeline-item__divider {
    z-index: 1;
  }

  .theme--light.v-timeline::before {
    border: 2px solid #3f0e40;
    margin-top: 23px;
    height: calc(100% - 63px);
    opacity: 0.5;
  }

  .logDate {
    position: absolute;
    right: -45px;
    color: #444b5a;
    font-size: 12px;
  }

  .info-dad {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .v-application--is-rtl .v-timeline--dense:not(.v-timeline--reverse)::before {
    //background-color: #4516466c !important;
    z-index: 1;
    width: 3px !important;
    margin-right: 1px;
  }

  .theme--light.v-timeline .v-timeline-item__dot {
    //border: 2px solid #3F0E40 !important;
    margin-top: 18px;

    & > div {
      background-color: white !important;
    }
  }
}

@media (max-width: 578px) {
  .log-page {
    .v-alert {
      width: 110% !important;
      transform: translateX(28px) !important;
    }

    .v-alert__content {
      span:not(:first-child) {
        font-size: 10px !important;
      }

      span:first-child {
        font-size: 13px !important;
      }
    }

    .v-timeline-item__dot {
      height: 25px !important;
      width: 25px !important;
      z-index: 1;
    }

    .v-timeline-item__inner-dot {
      display: inline-block !important;
    }
  }
}
</style>
