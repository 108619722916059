<template>
  <v-row justify="center">
    <v-dialog v-model="$store.state.TransferFilterDialog" persistent width="1024">
      <v-card>
        <v-card-title>
          <span class="text-h5"> فلتر تحويل الطلب</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <input
                  type="checkbox"
                  id="unActive"
                  name="unActive"
                  @change="handleChecked"
                  value="غير نشط"
                  :checked="mangerChecked"
                />
                <label for="unActive"> غير نشط </label><br />
              </v-col>
              <v-col cols="12" sm="6">
                <input
                  type="checkbox"
                  id="active"
                  name="active"
                  value="نشط"
                  :checked="agentChecked"
                  @change="handleCheckedAgent"
                />
                <label for="unActive"> نشط</label><br />
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <SelectInput
                  @selectedValues="getSelectedValueManger"
                  :originalItems="getManagerNames"
                  label="مدير المبيعات"
                />
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <div class="selectInput">
                  <v-combobox
                    v-model="getAgentsName"
                    :items="allAgents"
                    label="استشاري المبيعات"
                    multiple
                    small-chips
                    item-text="name"
                    item-value="id"
                    solo
                  >
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                      <v-chip
                        v-if="item === Object(item)"
                        v-bind="attrs"
                        :color="`${item.color} lighten-3`"
                        :input-value="selected"
                        label
                        small
                      >
                        <span class="pr-2">
                          {{ item.text }}
                        </span>
                        <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                      </v-chip>
                    </template>
                  </v-combobox>
                </div>
              </v-col>
              
              <v-col cols="12" sm="12" md="12" v-if="show_move_reason">
                <v-card-title> سبب التحويل </v-card-title>
                <v-card-text>
                  <v-textarea
                    :error="move_reason_required"
                    :error-messages="error_msgs"
                    auto-grow
                    variant="outlined"
                    rows="3"
                    row-height="25"
                    v-model="move_reason"
                    color="#000"
                    filled
                ></v-textarea>
              </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialog = $store.state.TransferFilterDialog = false"
          >
            الغاء
          </v-btn>
          <v-btn color="blue-darken-1" variant="text" @click="handleChangeUser">
            تحويل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="active"
      :color="color"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <div>
        <span class="font-weight-bold" style="font-size: 16px">{{ message }}</span>
      </div>
      <template v-slot:action>
        <v-btn icon small @click="active = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import SelectInput from "@/components/Charts/SelectInput.vue";
import { removeDuplicated } from "@/mixins/removeDublicated";
import { axiosInstance } from "@/axios/config";

export default {
  components: { SelectInput },
  props: ["selectedItems", "type"],
  data: () => ({
    move_reason_required: false,
    error_msgs: [],
    dialog: false,
    show_move_reason: false,
    allMangers: [],
    allAgents: [],
    mangerChecked: false,
    agentChecked: true,
    selectedManger: [],
    selectValue: [],
    selectedAgent: [],
    AllSelectedTable: [],
    active: false,
    move_reason: "",
    message: "",
    color: "",
    startChange: false,
    selectedMangerValue: null,
  }),
  computed: {
    getManagerNames() {
      return this.allMangers;
    },

    getAgentsName: {
      get() {
        if (this.startChange) {
          return this.allAgents;
        } else {
          return null;
        }
      },
      set(value) {
        // This setter will be called whenever getAgentsName changes
        console.log("getAgentsName changed:", value);
        this.selectedAgent = value;
      },
    },

    /*getAgentsName() {
      if (this.startChange == true) {
        return this.allAgents;
      } else {
        return null;
      }
    },*/
  },

  watch: {
    move_reason(val) {
      if (val.trim().length > 0) {
        this.move_reason_required = false;
        this.error_msgs = [];
      }else{
        this.move_reason_required = true;
      }
    },
    mangerChecked(val) {
      if (val === false) {
        this.getAllManger("manager", 1);
      } else if (val === true) {
        this.getAllManger("manager", 0);
      }
    },

    agentChecked(val) {
      if (val === false) {
        this.getAllAgents("agent", 0, this.selectValue);
        // this.getAllAgents("agent", 2, this.selectValue);
      } else if (val === true) {
        this.getAllAgents("agent", 1, this.selectValue);
      }
    },
    "$store.state.TransferFilterDialog": {
      handler(val){
      }
    },
  },

  mounted() {
    // add new comment to test upload
    if (window.location.pathname === "/admin/requests/need-to-turn-new") {
        this.show_move_reason = false;
      }else{
        this.show_move_reason = true;
      }
    this.getAllAgents("agent", 1);
    this.getAllManger("manager");
  },

  methods: {
    handleChangeUser() {
      if (this.selectedAgent.length == 0) {
        console.log("this.selectedAgent", this.selectedAgent);
        let message = "لابد من اختيار الاستشاريين";
        this.showPopup("error", message);
        return;
      }else{
        console.log("this.selectedAgent", this.selectedAgent);
      }
      if (this.show_move_reason && this.move_reason.trim().length == 0) {
        this.move_reason_required = true;
        this.error_msgs = ['سبب التحويل مطلوب'];
        return;
      }
      this.$store.state.TransferFilterDialog = false;
      let body = new FormData();
      let url = `/v2/employee/Requests/${this.type}`;
      
      if (window.location.pathname === "/admin/requests/need-to-turn-new") {
        url = `/v2/employee/Requests/moveNeedToBeTurnedQualityReqNew`;
      }else{
        body.append(`move_reason`, this.move_reason);
      }

      this.selectedAgent.length &&
        this.selectedAgent.forEach((item, index) => {
          body.append(`agents_ids[${index}]`, item["id"]);
        });

      this.selectedItems.length &&
        this.selectedItems.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });
      this.$store.state.startElement.length &&
        this.$store.state.startElement.forEach((item, index) => {
          body.append(`request_ids[${index}]`, item["id"]);
        });

      axiosInstance
        .post(url, body)
        .then((res) => {
          this.active = true;
          this.message = res.data.message;
          this.color = "green";
          this.$emit("reloadPage");
          this.$store.state.reloaddata_after_action = {
                action: 'admin_all_requests',
                date: new Date,
                view_type: 'table',
              };
          this.move_reason = '';
          this.startChange = true;
          this.startChange = false;
        })
        .catch((err) => {
          this.active = true;
          let error = { err };
          let message = "";
          if (Array.isArray(error.err.response.data.message)) {
            message = error.err.response.data.message[0];
          } else {
            message = error.err.response.data.message;
          }
          this.message = message;
          this.color = "red";
        });
    },

    getSelectedValueAgent(val) {
      this.selectedAgent = val;
    },

    getSelectedValueManger(val) {
      this.selectedMangerValue = val;
      if (this.selectedMangerValue.length != 0) {
        this.startChange = true;
      } else {
        this.startChange = false;
      }
      if (this.agentChecked === false) {
        this.getAllAgents("agent", 0, val);
      } else if (this.agentChecked === true) {
        this.getAllAgents("agent", 1, val);
      }
    },

    handleChecked() {
      this.mangerChecked = !this.mangerChecked;
    },

    handleCheckedAgent() {
      this.agentChecked = !this.agentChecked;
    },

    getAllManger(role, status) {
      this.allMangers = [];
      let body = { role: role };
      if (status === 0) {
        body = {
          status: status,
          role: role,
        };
      }

      let url = "/employee/admin/filterUsers";
      axiosInstance.post(url, body).then((res) => {
        this.allMangers = res.data.payload;
        this.allMangers.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
      });
    },

    getAllAgents(role, status, val) {
      this.allAgents = [];
      let body = new FormData();
      body.append("role", role);
      if (status === 0 && !val) {
        body.append("status", status);
        body.append("role", role);
      } else if (status === 1 && !val) {
        body.append("status", status);
        body.append("role", role);
      }

      this.selectValue = val;
      if (val) {
        val.forEach((item, index) => {
          this.selectedManger.push(`'manager_id[${index}]': '${item["id"]}'`);
        });
      }

      let removeDublicate = removeDuplicated(this.selectedManger);

      if (removeDublicate.length) {
        val.forEach((item, index) => {
          body.append(`manager_id[${index}]`, item["id"]);
        });
        body.append("status", status);
        body.append("role", role);
      }

      let url = "/employee/admin/filterUsers";
      axiosInstance.post(url, body).then((res) => {
        this.allAgents = res.data.payload;
        this.allAgents.map((item) => {
          item["color"] = "red";
          item["text"] = item["name"];
          return item;
        });
        if(val){
          this.startChange = true;
          this.selectedAgent = this.allAgents.slice();

        }
      });
    },
  },
};
</script>
