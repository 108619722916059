<template>
    <div>
        <br/>
      <!-- First Section: Form -->
      <v-sheet class="pad-5">
        <v-row>
            <v-col cols="12">
                    <v-form ref="form"  @submit.prevent="submitForm(0)">
                        <v-row>
                            <v-col cols="12" md="3"  style="display: flex; justify-content: space-around;">
                                <h4 style="    margin-top: 10px;">
                                    رفع ملف الاكسل: 
                                    <a href="http://alwsata.com.sa/check-customers-exists-example.xlsx" target="_blank" style="font-size: 12px;" rel="noopener noreferrer">تحميل نموذج الاكسل</a>
                                </h4>

                            </v-col>
                            <v-col cols="12" md="5">
                                <v-file-input
                                    v-model="file"
                                    label="Upload Excel File"
                                    accept=".xlsx, .xls"
                                    prepend-icon="mdi-file-upload"
                                    outlined
                                    :rules="fileRules"
                                    color="primary"
                                ></v-file-input>

                            </v-col>
                            <v-col cols="12" md="2">
                              <v-text-field
                                label="رقم عمود الجوال"
                                v-model="column_no"
                                height="55px"
                                outlined
                                dense
                              ></v-text-field>

                            </v-col>
                            <v-col cols="12" md="2">
                                <v-btn
                                    color="primary"
                                    class=""
                                    style="height: 55px; width: 100%;"
                                    type="submit"
                                >تحقق</v-btn>

                            </v-col>
                        </v-row>
                    </v-form>

                    
                </v-col>
            </v-row>
        </v-sheet>
        <br/>
        <br/>
      <!-- Second Section: Cards -->
      <v-sheet class="pad-5">
        <v-row class="mb-5">
                <v-col cols="12" md="6">
                <v-card class="card-style">
                    <v-card-text style="display: flex; justify-content: space-around; background-color: #a2ff9e;">
                    <h3>عدد العملاء المسجلين  بالنظام</h3>
                    <h4>العدد: {{ exists_customers_count }}</h4>
                    </v-card-text>
                </v-card>
                </v-col>
                <v-col cols="12" md="6">
                <v-card class="card-style-alt">
                    <v-card-text style="display: flex; justify-content: space-around; background-color: #f4b5ff;">
                    <h3>الغير مسجلين بالنظام</h3>
                    <h4>العدد: {{ not_exists_customers_count }}</h4>
                    </v-card-text>
                    <button style=" margin: auto; display: block; " @click="exportToExcel">Export as Excel</button>
                </v-card>
                </v-col>

            </v-row>
        </v-sheet>
  
        <v-sheet class="pad-5">
            <v-row>
                <v-col cols="12">
                    <v-text-field
                      v-model="search"
                      label="بحث"
                      class="mb-4"
                      prepend-icon="mdi-magnify"
                    ></v-text-field>
                    <div class="secondary__table">
                        <v-data-table
                            :headers="headers"
                            :items="items"
                            :search="search"
                            class="styled-table"
                            :items-per-page="5"
                            >
                                            
                            <template v-slot:[`item.exists`]="{ item }">
                                <v-chip
                                :color="item.exists ? 'success' : 'error'"
                                dark
                                >{{ item.exists ? 'موجود' : 'غير موجود' }}</v-chip>
                            </template>
                            <template v-slot:[`item.operations`]="{ item }">
                                <v-menu bottom left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <router-link
                                    v-if="item.exists"
                                    style="text-decoration: none"
                                    target="_blank"
                                    :to="openParticularRequest(item)"
                                >
                                        <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>mdi-eye</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>فتح ملف الطلب {{ item.mobile }}</v-list-item-title>
                                        </v-list-item>
                                    </router-link>
                                    <v-list-item v-else @click="addNewCustomer(item)">
                                    <v-list-item-icon>
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>انشاء ملف طلب للعميل {{ item.mobile }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                                </v-menu>
                            </template>
                        </v-data-table> 

                    </div>

                </v-col>
            </v-row>
        </v-sheet>
        <v-sheet class="pad-5">
          <v-row>
            <v-col cols="6" md="2" v-for="(item, index) in  broken_lst" :key="index">
              
              <v-btn
          class="white--text"
          color="error"
                  depressed
          elevation="2"
          rounded
        >
        {{item}}
        </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      <!-- Third Section: Table -->
    </div>
  </template>
  
  <script>
  
  import * as XLSX from "xlsx";
  import { axiosInstance } from "@/axios/config";
  export default {
    data() {
      return {
        broken_lst: [],
        column_no: 1,
        exists_customers_count: null,
        not_exists_customers_count: null,
        file: null,
        card1Number: 123,
        card2Number: 456,
        search: '',
        loading: false,
        headers: [
          { text: 'رقم الجوال', value: 'mobile' },
          { text: 'الاسم', value: 'name' },
          { text: 'حالة الرقم', value: 'exists' },
          { text: 'العمليات', value: 'operations', sortable: false },
        ],
        items: [
        ],
        fileRules: [
          (value) => !!value || 'File is required.',
          (value) => !value || /\.(xls|xlsx)$/i.test(value.name) || 'Only Excel files are allowed.',
        ],
      };
    },
    methods: {
    filterData() {
      // Example filter: only include people older than 25
      return this.items.filter(item => item.exists == 0);
    },
    exportToExcel() {
      const filteredData = this.filterData();
      const worksheet = XLSX.utils.json_to_sheet(filteredData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Filtered Data");
      XLSX.writeFile(workbook, "filtered_data.xlsx");
    },
        addNewCustomer(item){
            let body = {
                mobile: item.mobile
            };
            axiosInstance.post('employee/admin/check-customers-by-excel-sheet/create-customer', body)
            .then((response) => {
                // 
                const itemIndex = this.items.findIndex(item => item.mobile === response.data.payload.mobile);

                if (itemIndex !== -1) {
                    // Update the name property of the found item
                    this.$set(this.items, itemIndex, { ...this.items[itemIndex], request_id: response.data.payload.request_id });
                    
                } else {
                    // 
                }
                
                this.showPopupWithBtn(
                "success",
                response.data.message,
                "فتح الطلب",
                response.data.payload.request_id
              );
            }).catch((err) => {
                // 
            });
        },
        openParticularRequest(item) {
            return { name: "request_details", params: { id: item.request_id } };
        },
        async submitForm(start_pos = 0, path = '') {
            if(start_pos == -1){
              return;
            }else if(start_pos == 0){
              this.items = [];
              this.broken_lst = [];
              this.exists_customers_count = 0;
              this.not_exists_customers_count = 0;
            }
            if (this.$refs.form.validate()) {
                const formData = new FormData();
                if (path != '') {
                  formData.append('path', path);
                }else{
                  formData.append('file', this.file);
                }
                
                formData.append('start_pos', start_pos);
                formData.append('column_no', this.column_no);

                try {
                const response = await axiosInstance.post('employee/admin/check-customers-by-excel-sheet', formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    },
                });

                //   alert('File imported successfully.');
                this.exists_customers_count = this.exists_customers_count  + response.data.payload.exists_customers_count;
                this.not_exists_customers_count = this.not_exists_customers_count + response.data.payload.not_exists_customers_count;
                let arr2 = this.broken_lst;
                arr2 = [...arr2, ...response.data.payload.broken_lst];
                this.broken_lst = arr2;
                let arr = this.items;
                arr = [...arr, ...response.data.payload.result_list];
                this.items = arr;
                if(response.data.payload.next_start_pos != -1){
                  this.submitForm(response.data.payload.next_start_pos, response.data.payload.path);
                }else{
                  this.showPopup("success", response.data.message);
                }

                console.log(response.data);
                } catch (error) {
                console.error(error);
                alert('An error occurred while importing the file.');
                }
            }
        },
    },
  };
  </script>
  
  <style>
  .v-btn--primary {
    background-color: #5c315d;
    border-color: #5c315d;
  }
  
  .card-style {
    background-color: #5c315d;
    color: #ffffff;
  }
  
  .card-style-alt {
    background-color: #8e5e8c;
    color: #ffffff;
  }
  
  .v-chip--success {
    background-color: #4caf50 !important;
  }
  
  .v-chip--error {
    background-color: #f44336 !important;
  }
  
  .styled-table .v-data-table-header {
    background-color: #5c315d;
    color: #ffffff;
  }
  
  .styled-table .v-data-table__checkbox {
    color: #5c315d;
  }
  
  .styled-table .v-data-table__footer {
    background-color: #f2f2f2;
  }
  
  .v-list-item {
    color: #5c315d;
  }
  
  .styled-table .v-data-table {
    border: 1px solid #5c315d;
    border-radius: 8px;
  }
  
  .v-text-field input {
    color: #5c315d;
  }
  
  .v-text-field input::placeholder {
    color: #8e5e8c;
  }
  .pad-5{
    padding: 0px 20px;
  }

  
.v-data-table th {
  font-weight: bolder !important;
  font-size: 16px !important;
  color: #444444 !important;
  vertical-align: top !important;
  padding-top: 7px !important;
  background-color: #f8f9fa;
  letter-spacing: 0px;
}

.v-data-table td {
  letter-spacing: 0px;
  font-weight: 500;
  color: #5d5d5d;
  font-size: 16px !important;
}

.v-data-table tr:nth-of-type(even) {
  border-color: #f8f9fa !important;
  font-size: 16px;
  background-color: #f8f9fa;
}

.v-data-table th .v-icon {
  margin-top: -3px !important;
  margin-right: 7px !important;
  font-size: 18px !important;
}

.v-data-table__wrapper {
  border-top: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  border-left: 1px solid #e9e9e9;
}

.v-data-table thead tr:last-child > th {
  border-bottom: thin solid #e9e9e9 !important;
}

.v-data-table tr td {
  border-bottom: 1px solid #e9e9e9 !important;
}

.v-data-table tr:nth-of-type(even) {
  background-color: #f8f9fa;
}

.v-data-table tr td:last-child,
.v-data-table tr th:last-child {
  border-right: 1px solid #e2e2e2;

  i {
    font-size: 34px;
  }

  button {
    padding: 0 !important;
  }
}

.v-data-table tr th:last-child {
  background-color: #f8f9fa;
}

@media (max-width: 990px) {
  .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
    border-bottom: 1px solid #d1d1d1;
    padding: 17px;
  }

  .v-data-table tr td:last-child,
  .v-data-table tr th:last-child {
    border-right: 1px solid #e2e2e2;
    background-color: transparent;
  }
}

@media (max-width: 500px) {
  .v-card > *:last-child:not(.v-btn):not(.v-chip):not(.v-avatar):not(.excluded) {
    padding: 0 !important;
  }
  .v-application--is-rtl .v-data-table__mobile-row__header,
  .v-application--is-rtl .v-data-table__mobile-row__cell {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.secondary__table
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  background: rgb(63, 14, 64);
  color: white !important;
  border-right: 1px solid white;
  border-bottom: 1px solid rgb(63, 14, 64) !important;
  height: 72px;
}

.secondary__table .v-data-table-header__icon {
  display: none;
}

.secondary__table .v-data-table th .v-icon {
  color: white;
}

.secondary__table .v-data-table__wrapper {
  border-radius: 12px 12px 0 0;
}

.secondary__table .v-data-table th .v-icon {
  width: 50px;
  height: 20px;
  background: white;
  border-radius: 3px;
}

.secondary__table .v-data-table tr:nth-of-type(even) {
  background-color: #e3dce3;
}

.secondary__table .v-input--selection-controls__input .v-icon {
  color: purple;
}

.table-toolbar .v-btn:not(.v-btn--round).v-size--small {
  color: white !important;
  margin-left: -5px;
}

.table-toolbar .v-btn__content {
  background: rgb(63, 14, 64);
  width: 36px;
  height: 36px;
  border-radius: 6px;
}

.secondary__table .v-input__slot {
  min-height: 30px !important;
  margin-top: 20px;
  border: 1px solid rgb(63, 14, 64);
}

.secondary__table .v-text-field--outlined.v-input--is-focused fieldset {
  height: 30px !important;
  border: none;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row),
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-family: "Material Design Icons";

  span {
    font-family: "Material Design Icons";
  }
}
.secondary__table .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th{
  background-color: #e7dfe8 !important;
  color:#000 !important;    
  height: 49px !important;  
}
.secondary__table .v-data-table__wrapper {
    border-radius: 0 !important;    
}

  </style>
  