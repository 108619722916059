<template>
  <div>
    <v-menu offset-y nudge-top="-7">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          size="45px"
          v-bind="attrs"
          v-on="on"
          style="z-index: 5; left: 10px; top: -53px; position: absolute"
        >
          <svg
            width="512"
            height="512"
            viewBox="0 0 512 512"
            fill="#3f0e40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M474.557 37.4414V168.577H328.851V37.4414H474.557ZM255.999 37.4414V474.557H37.4414V37.4414H255.999ZM474.557 197.719V314.281H328.851V197.719H474.556H474.557ZM474.557 343.422V474.557H328.851V343.422H474.556H474.557Z"
              fill="#3f0e40"
            />
          </svg>
        </v-avatar>
      </template>
      <v-list>
        <v-list-item
          class="pl-12"
          @click="$store.state.showCards = true"
          style="border-bottom: 2px solid #ededed"
        >
          <v-list-item-icon class="ml-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#3F0E40"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="four-squares-icon 1">
                <g id="Group">
                  <path
                    id="Vector"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.61524 12.7831H10.4074C10.7402 12.7831 11.0124 13.1075 11.0124 13.5034V21.0877C11.0124 21.4842 10.7404 21.8083 10.4074 21.8083H2.61524C2.28272 21.8083 2.01025 21.4842 2.01025 21.0877V13.5034C2.01025 13.1075 2.28272 12.7831 2.61524 12.7831ZM13.5925 2H21.3848C21.7178 2 21.9898 2.32407 21.9898 2.72043V10.3048C21.9898 10.7011 21.7178 11.0249 21.3848 11.0249H13.5925C13.2596 11.0249 12.988 10.7011 12.988 10.3048V2.72043C12.988 2.32407 13.2596 2 13.5925 2ZM2.60449 2H10.3971C10.73 2 11.0021 2.32407 11.0021 2.72043V10.3048C11.0021 10.7011 10.7302 11.0249 10.3971 11.0249H2.60449C2.27197 11.0249 2 10.7011 2 10.3048V2.72043C2 2.32407 2.27197 2 2.60449 2ZM13.6027 12.9753H21.3951C21.7276 12.9753 22 13.2994 22 13.6959V21.2803C22 21.6761 21.7276 22.0002 21.3951 22.0002H13.6027C13.2699 22.0002 12.9977 21.6761 12.9977 21.2803V13.6959C12.9977 13.2994 13.2699 12.9753 13.6027 12.9753Z"
                    fill="#3F0E40"
                  />
                </g>
              </g>
            </svg>
          </v-list-item-icon>
          <v-list-item-title style="line-height: 2"> عرض الكروت</v-list-item-title>
        </v-list-item>

        <v-list-item
          class="pl-12"
          @click="$store.state.showCards = false"
          style="border-bottom: 2px solid #ededed"
        >
          <v-list-item-icon class="ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27.348"
              height="22.783"
              viewBox="0 0 24.348 19.783"
              fill="#3F0E40"
            >
              <path
                id="Icon_awesome-list-ul"
                data-name="Icon awesome-list-ul"
                d="M22.065,3.375a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,3.375Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283A2.283,2.283,0,0,1,22.065,10.984Zm0,7.609a2.283,2.283,0,1,1-2.283,2.283,2.283,2.283,0,0,1,2.283-2.283Zm-21.3.761H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,21.636V20.114A.761.761,0,0,1,.761,19.353Zm0-15.217H15.978a.761.761,0,0,1,.761.761V6.418a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,6.418V4.9A.761.761,0,0,1,.761,4.136Zm0,7.609H15.978a.761.761,0,0,1,.761.761v1.522a.761.761,0,0,1-.761.761H.761A.761.761,0,0,1,0,14.027V12.505A.761.761,0,0,1,.761,11.745Z"
                transform="translate(0 -3.375)"
                fill="#3F0E40"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-title style="line-height: 2">عرض الجدول</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- <v-text-field
      outlined
      v-model="$store.state.counterOfShow"
      dense
      width="10"
      height="35"
      color="white"
      style="
        position: absolute;
        top: -50px;
        left: 110px;
        min-width: unset;
        max-width: 50px !important;
        z-index: 5;
      "
      class="white-border"
    ></v-text-field> -->

    <div v-if="!this.lodingToGetData" style="margin-top: 20px">
      <div v-if="$store.state.showCards === true">
        <div v-if="this.allRequests.length > 0">
          <RequestsCard :CardData="this.allRequests" @pageCount="getAllRequests" />
          <div>
            <LoadingMore v-if="loadMoreData" style="margin: 40px" />
          </div>
        </div>
        <div class="noData" v-else>لا يوجد بيانات</div>
      </div>
      <div v-else>
        <table-component
          :showSelect="false"
          itemKey="id"
          :selectedRows.sync="selected_requests"
          :headers="headers"
          :pagination="this.$store.state.pagination"
          :limit="$store.state.counterOfShow"
          :items="this.$store.state.tableData"
          :loading="loading"
          @refresh="getTableData"
          v-model="page"
        >
          <template slot="item.comment" slot-scope="props">
            <span v-if="!props.item.comment"></span>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  props.item.comment.length > 35
                    ? props.item.comment.substring(0, 35) + "..."
                    : props.item.comment
                }}</span>
              </template>
              <div class="font-weight-bold">{{ props.item.comment }}</div>
            </v-tooltip>
          </template>
        </table-component>
      </div>
    </div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
      "
      v-else
    >
      <LoadingMore />
    </div>
    <QualityDialog
      :selectedItems="selected_requests"
      type="moveReqsNewToQualityReqArray"
    />
    <AdminDialog :items="this.$store.state.allStatusAgent" label="استشاري المبيعات" />
  </div>
</template>

<style lang="scss">
.genaralIcons {
  position: absolute;
  top: -50px;
  min-width: unset;
  z-index: 5;
}

@media screen and (max-width: 626px) {
  .genaralIcons {
    top: -10px;
  }
}

.table-style svg {
  opacity: 0.4;

  &.active-table-style {
    opacity: 1;
  }
}
</style>

<script>
import Axios from "axios";
import RequestsCard from "@/components/Charts/RequestsCard.vue";
import LoadingMore from "@/components/Charts/LoadingMore.vue";
import { formatDate } from "@/mixins/formatDate";
import { SourceValue } from "@/mixins/sourceValue";
import AdminDialog from "@/components/AdminComponent/AdminDialog.vue";
import QualityDialog from "@/components/AdminComponent/QualityDialog.vue";

export default {
  name: "AdminSupport",
  components: {
    QualityDialog,
    AdminDialog,
    RequestsCard,
    LoadingMore,
  },

  data() {
    return {
      page: 1,
      loading: false,
      copySupportRequests: [],
      loadMoreData: false,
      loadingData: false,
      selected_requests: [],
      allRequests: [],
      lodingToGetData: false,
      headers: [
        { text: "رقم الطلب", value: "رقم الطلب", align: "center" },
        {
          text: "تاريخ الطلب",
          value: "created_at",
          width: "107px",
          align: "center",
        },
        { text: "حاله الطلب", value: "حاله الطلب", align: "center" },
        {
          text: "استشاري المبيعات",
          value: "استشاري المبيعات",
          align: "center",
        },
        { text: "عميل", value: "عميل", align: "center" },
        { text: "الجوال", value: "الجوال", align: "center" },
        {
          text: "مصدر المعامله",
          value: "مصدر المعامله",
          align: "center",
        },
        {
          text: "تصنيف الاستشاري",
          value: "تصنيف الاستشاري",
          align: "center",
        },
        { text: "الملاحظه", value: "comment", width: "150px", align: "center" },
        { text: "العمليه", value: "العمليه", width: "150px", align: "center" },
      ],
    };
  },

  watch: {
    page(val) {
      this.getTableData(val);
    },

    selected_requests(val) {
      this.selectedItems = val;
      this.$emit("requests", val);
    },

    "$store.state.counterOfShow": {
      handler(newval, oldvalue) {
        if (newval != oldvalue) {
          this.getTableData(this.page);
        }
      },
    },

    $route: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$store.state.counterOfShow = 10;
        }
      },
    },
  },

  methods: {
    handleTableDesign() {
      this.$store.state.showCards = false;
    },

    toggleTableStyle(e) {
      document.querySelectorAll(".table-style").forEach((btn) => {
        btn.querySelector("svg").classList.remove("active-table-style");
      });
      e.target.querySelector("svg").classList.add("active-table-style");
    },

    getTableData(count) {
      this.$store.state.tableData = [];
      this.loading = true;
      Axios.get(
        `${this.$store.state.url}/v2/employee/Requests?type=need_actions_done&page=${count}&per_page=${this.$store.state.counterOfShow}`,
        { headers: this.request_headers() }
      ).then((res) => {
        res.data.payload.data.map((item) => {
          this.setTbData(item);
        });
        this.loading = false;
      });
    },

    setTbData(items) {
      this.$store.state.tableData.push({
        id: items.id,
        "رقم الطلب": items.id,
        created_at: items.request.created_at ? formatDate(items.request.created_at) : "",
        "حاله الطلب": items.request.status,
        "استشاري المبيعات": items.request.user?.name,
        عميل: items.request.customer.name,
        الجوال: items.request.customer.mobile,
        "مصدر المعامله": SourceValue(items.request.source),
        "تصنيف الاستشاري": items.request.class_id_agent_text,
        comment: items.request.comment,
        العمليه: items.action,
      });
    },

    getAllRequests(count) {
      if (this.allRequests.length === 0 && this.copySupportRequests.length === 0) {
        this.lodingToGetData = true;
      }
      this.loadMoreData = true;
      Axios.get(
        `https://appproduct.alwsata.com.sa/api/v2/employee/Requests?type=need_actions_done&page=${count}&per_page=${this.$store.state.counterOfShow}`,
        { headers: this.request_headers() }
      )
        .then((res) => {
          let response = res.data.payload.data.map((item) => {
            this.setTbData(item);
            item["starChecked"] = false;
            return item;
          });
          this.$store.state.pagination = {
            limit: 3,
            count: res.data.payload.count_of_need_actions_done,
          };
          this.allRequests.push(...response);
          this.copySupportRequests.push(...response);
          this.lodingToGetData = false;
        })
        .catch((err) => {
          this.loadingData = false;
          this.loadMoreData = false;
        })
        .finally(() => {
          this.loadMoreData = false;
          this.loadingData = false;
        });
    },
  },

  created() {
    this.$store.state.allRequests = [];
    this.$store.state.tableData = [];
    this.$store.state.back = false;
    this.$store.state.bodySearch = new FormData();
    this.$store.state.isSearch = false;
    this.getAllRequests();
    this.$store.state.startElement = [];
  },
};
</script>

<style>
.noData {
  display: flex;
  justify-content: center;
  font-size: 22px;
  color: #3f0e40;
  margin-top: 70px;
  font-weight: bold;
}
</style>
