<template>
  <c-dialog
    v-model="active"
    class="filter-dialog"
    title="البحث المتقدم"
    width="750px"
  >
    <template slot="content">
      <v-form class="pr-2 pl-1">
        <v-row>
          <v-col v-if="currentRole == 1" cols="12" lg="6" md="6" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">الاستشاريين</div>
            <v-autocomplete
              chips
              deletable-chips
              color="primary"
              :items="[
                { id: '', value: 'جميع الاستشاريين' },
                { id: '0', value: '  المؤرشفين' },
                { id: '1', value: '  النشطين' }
              ]"
              v-model="payload.agent_status"
              hide-details
              item-text="value"
              item-value="id"
              outlined
              dense
              append-icon
            >
              <template v-slot:append>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.003"
                  height="6.291"
                  viewBox="0 0 11.003 6.291"
                  class="mt-3"
                >
                  <path
                    id="Icon_ionic-ios-arrow-back"
                    data-name="Icon ionic-ios-arrow-back"
                    d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                    transform="translate(0 6.291) rotate(-90)"
                    fill="#2c2c2c"
                  />
                </svg>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col v-if="currentRole == 1" cols="12" md="6" lg="6" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">إسم الاستشاري </div>
            <v-autocomplete
              multiple
              chips
              deletable-chips
              color="primary"
              :items="$store.state.salesmanager_agents_search"
              :loading="getting"
              v-model="payload.agent_name"
              hide-details
              item-text="name"
              item-value="id"
              outlined
              append-icon
              dense
            >
              <template v-slot:append>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.003"
                  height="6.291"
                  viewBox="0 0 11.003 6.291"
                  class="mt-3"
                >
                  <path
                    id="Icon_ionic-ios-arrow-back"
                    data-name="Icon ionic-ios-arrow-back"
                    d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                    transform="translate(0 6.291) rotate(-90)"
                    fill="#2c2c2c"
                  />
                </svg> </template
            ></v-autocomplete>
          </v-col>
          
          <v-col v-if="currentRole == 1" cols="12" lg="6" md="6" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">استلامه من قبل الجودة</div>
            <v-autocomplete
              chips
              deletable-chips
              color="primary"
              :items="[
                { id: 1, value: 'نعم' },
                { id: 0, value: 'لا' },
              ]"
              v-model="payload.quality_recived"
              hide-details
              item-text="value"
              item-value="id"
              outlined
              dense
              append-icon
            >
              <template v-slot:append>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.003"
                  height="6.291"
                  viewBox="0 0 11.003 6.291"
                  class="mt-3"
                >
                  <path
                    id="Icon_ionic-ios-arrow-back"
                    data-name="Icon ionic-ios-arrow-back"
                    d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                    transform="translate(0 6.291) rotate(-90)"
                    fill="#2c2c2c"
                  />
                </svg>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-autocomplete
              :items="['لا', 'نعم']"
              label="طلبات تحتوي علي مكالمات"
              v-model="payload.request_has_calls"
              outlined
              dense
            >
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-title
                    :id="attrs['aria-labelledby']"
                    v-text="item"
                  ></v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" md="12" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">نوع المنتج</div>
            <v-autocomplete
              multiple
              chips
              deletable-chips
              color="primary"
              :items="productType"
              v-model="payload.request_product_type"
              hide-details
              item-text="value"
              item-value="id"
              outlined
              append-icon
              dense
            >
              <template v-slot:append>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.003"
                  height="6.291"
                  viewBox="0 0 11.003 6.291"
                  class="mt-3"
                >
                  <path
                    id="Icon_ionic-ios-arrow-back"
                    data-name="Icon ionic-ios-arrow-back"
                    d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                    transform="translate(0 6.291) rotate(-90)"
                    fill="#2c2c2c"
                  />
                </svg> </template
            ></v-autocomplete>
          </v-col>





          <v-col cols="12" md="12" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">إسم العميل</div>
            <v-autocomplete
              multiple
              chips
              deletable-chips
              color="primary"
              :items="$store.state.agent_customers"
              v-model="payload.customer_name"
              hide-details
              item-text="name"
              item-value="id"
              outlined
              append-icon
              dense
            >
              <template v-slot:append>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.003"
                  height="6.291"
                  viewBox="0 0 11.003 6.291"
                  class="mt-3"
                >
                  <path
                    id="Icon_ionic-ios-arrow-back"
                    data-name="Icon ionic-ios-arrow-back"
                    d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                    transform="translate(0 6.291) rotate(-90)"
                    fill="#2c2c2c"
                  />
                </svg> </template
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">حالة الطلب</div>
            <v-autocomplete
              multiple
              chips
              deletable-chips
              color="primary"
              :items="$store.state.request_statuses"
              v-model="payload.request_status"
              hide-details
              item-text="value"
              item-value="status_id"
              outlined
              dense
              append-icon
            >
              <template v-slot:append>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.003"
                  height="6.291"
                  viewBox="0 0 11.003 6.291"
                  class="mt-3"
                >
                  <path
                    id="Icon_ionic-ios-arrow-back"
                    data-name="Icon ionic-ios-arrow-back"
                    d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                    transform="translate(0 6.291) rotate(-90)"
                    fill="#2c2c2c"
                  />
                </svg>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">راتب العميل</div>
            <!-- eslint-disable -->
            <v-text-field
              hide-details
              outlined
              dense
              v-model="payload.customer_salary"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">راتب العميل من</div>
            <!-- eslint-disable -->
            <v-text-field
              hide-details
              outlined
              dense
              v-model="payload.customer_salary_from"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">راتب العميل الى</div>
            <!-- eslint-disable -->
            <v-text-field
              hide-details
              outlined
              dense
              v-model="payload.customer_salary_to"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">تصنيف إستشاري المبيعات</div>
            <v-autocomplete
              multiple
              chips
              deletable-chips
              color="primary"
              :items="$store.state.request_class"
              v-model="payload.agent_classification"
              hide-details
              item-text="value"
              item-value="id"
              outlined
              dense
              append-icon
            >
              <template v-slot:append>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.003"
                  height="6.291"
                  viewBox="0 0 11.003 6.291"
                  class="mt-3"
                >
                  <path
                    id="Icon_ionic-ios-arrow-back"
                    data-name="Icon ionic-ios-arrow-back"
                    d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                    transform="translate(0 6.291) rotate(-90)"
                    fill="#2c2c2c"
                  />
                </svg>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4" md="6" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">مصدر المعاملة</div>
            <v-autocomplete
              multiple
              chips
              deletable-chips
              color="primary"
              :items="$store.state.work_sources"
              v-model="payload.request_source"
              hide-details
              item-text="value"
              item-value="id"
              outlined
              dense
              append-icon
            >
              <template v-slot:append>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.003"
                  height="6.291"
                  viewBox="0 0 11.003 6.291"
                  class="mt-3"
                >
                  <path
                    id="Icon_ionic-ios-arrow-back"
                    data-name="Icon ionic-ios-arrow-back"
                    d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                    transform="translate(0 6.291) rotate(-90)"
                    fill="#2c2c2c"
                  />
                </svg>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" lg="4" md="6" class="pb-5 pt-0"  v-if="payload.request_source.includes(2)">
            <div class="mb-2 font-weight-bold">المتعاونين</div>
            <v-autocomplete
              hide-details
              :items="collaborators"
              class="mr-1"
              item-text="name"
              item-value="collaborator_id"
              v-model="payload.collaborator_name"
              outlined
              multiple
              dense
              chips
              deletable-chips
              color="primary"
              append-icon
            ></v-autocomplete>
          </v-col>


          <v-col cols="12" lg="4" md="6" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">الهاتف</div>
            <!-- eslint-disable -->
            <v-text-field
              hide-details
              v-model="payload.customer_phone"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">
              <span>تاريخ الميلاد</span>
            </div>
            <v-text-field
              type="date"
              hide-details
              v-model="payload.customer_birth_date"
              outlined
              dense
            >
              <template v-slot:append>
                <svg
                  class="mt-1"
                  id="calendar"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.396"
                  height="16.989"
                  viewBox="0 0 17.396 16.989"
                >
                  <path
                    id="Path_2784"
                    data-name="Path 2784"
                    d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                    transform="translate(-4.21 -7.917)"
                    fill="#6c757d"
                  />
                  <path
                    id="Path_2785"
                    data-name="Path 2785"
                    d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                    transform="translate(-4.21 -4.86)"
                    fill="#6c757d"
                  />
                </svg>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">حالة الملاحظة</div>
            <v-autocomplete
              chips
              deletable-chips
              color="primary"
              :items="[
                { id: 0, value: 'فارغة' },
                { id: 1, value: 'بها محتوي' },
              ]"
              v-model="payload.note_status"
              hide-details
              item-text="value"
              item-value="id"
              outlined
              dense
              append-icon
            >
              <template v-slot:append>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.003"
                  height="6.291"
                  viewBox="0 0 11.003 6.291"
                  class="mt-3"
                >
                  <path
                    id="Icon_ionic-ios-arrow-back"
                    data-name="Icon ionic-ios-arrow-back"
                    d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                    transform="translate(0 6.291) rotate(-90)"
                    fill="#2c2c2c"
                  />
                </svg>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4" md="6" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">نوع الطلب</div>
            <v-autocomplete
              multiple
              chips
              deletable-chips
              color="primary"
              v-model="payload.request_types"
              :items="['شراء', 'رهن', 'تساهيل', 'شراء-دفعة', 'رهن-شراء', 'رهن-مباشر']"
              hide-details
              outlined
              dense
              append-icon
            >
              <template v-slot:append>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.003"
                  height="6.291"
                  viewBox="0 0 11.003 6.291"
                  class="mt-3"
                >
                  <path
                    id="Icon_ionic-ios-arrow-back"
                    data-name="Icon ionic-ios-arrow-back"
                    d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                    transform="translate(0 6.291) rotate(-90)"
                    fill="#2c2c2c"
                  />
                </svg>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4" md="6" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">
              <span>تاريخ الطلب (من)</span>
            </div>
            <v-text-field
              type="date"
              hide-details
              v-model="payload.request_date_from"
              outlined
              dense
            >
              <template v-slot:append>
                <svg
                  class="mt-1"
                  id="calendar"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.396"
                  height="16.989"
                  viewBox="0 0 17.396 16.989"
                >
                  <path
                    id="Path_2784"
                    data-name="Path 2784"
                    d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                    transform="translate(-4.21 -7.917)"
                    fill="#6c757d"
                  />
                  <path
                    id="Path_2785"
                    data-name="Path 2785"
                    d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                    transform="translate(-4.21 -4.86)"
                    fill="#6c757d"
                  />
                </svg>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">
              <span>تاريخ الطلب (إلي)</span>
            </div>
            <v-text-field
              type="date"
              hide-details
              v-model="payload.request_date_to"
              outlined
              dense
            >
              <template v-slot:append>
                <svg
                  class="mt-1"
                  id="calendar"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.396"
                  height="16.989"
                  viewBox="0 0 17.396 16.989"
                >
                  <path
                    id="Path_2784"
                    data-name="Path 2784"
                    d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                    transform="translate(-4.21 -7.917)"
                    fill="#6c757d"
                  />
                  <path
                    id="Path_2785"
                    data-name="Path 2785"
                    d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                    transform="translate(-4.21 -4.86)"
                    fill="#6c757d"
                  />
                </svg>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">
              <span>تاريخ الإفراغ (من)</span>
            </div>
            <v-text-field
              type="date"
              hide-details
              v-model="payload.complete_date"
              outlined
              dense
            >
              <template v-slot:append>
                <svg
                  class="mt-1"
                  id="calendar"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.396"
                  height="16.989"
                  viewBox="0 0 17.396 16.989"
                >
                  <path
                    id="Path_2784"
                    data-name="Path 2784"
                    d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                    transform="translate(-4.21 -7.917)"
                    fill="#6c757d"
                  />
                  <path
                    id="Path_2785"
                    data-name="Path 2785"
                    d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                    transform="translate(-4.21 -4.86)"
                    fill="#6c757d"
                  />
                </svg>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">
              <span>تاريخ الإفراغ (إلي)</span>
            </div>
            <v-text-field
              type="date"
              hide-details
              v-model="payload.complete_date"
              outlined
              dense
            >
              <template v-slot:append>
                <svg
                  class="mt-1"
                  id="calendar"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.396"
                  height="16.989"
                  viewBox="0 0 17.396 16.989"
                >
                  <path
                    id="Path_2784"
                    data-name="Path 2784"
                    d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                    transform="translate(-4.21 -7.917)"
                    fill="#6c757d"
                  />
                  <path
                    id="Path_2785"
                    data-name="Path 2785"
                    d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                    transform="translate(-4.21 -4.86)"
                    fill="#6c757d"
                  />
                </svg>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="4" md="6" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">جهة العمل</div>
            <v-autocomplete
              multiple
              chips
              deletable-chips
              color="primary"
              :items="$store.state.works_list"
              hide-details
              item-text="value"
              v-model="payload.work_source"
              item-value="id"
              outlined
              dense
              append-icon
            >
              <template v-slot:append>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.003"
                  height="6.291"
                  viewBox="0 0 11.003 6.291"
                  class="mt-3"
                >
                  <path
                    id="Icon_ionic-ios-arrow-back"
                    data-name="Icon ionic-ios-arrow-back"
                    d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                    transform="translate(0 6.291) rotate(-90)"
                    fill="#2c2c2c"
                  />
                </svg>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4" md="6" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">جهة نزول الراتب</div>
            <v-autocomplete
              multiple
              chips
              deletable-chips
              color="primary"
              :items="$store.state.salary_sources"
              v-model="payload.salary_source"
              hide-details
              item-text="value"
              item-value="id"
              outlined
              dense
              append-icon
            >
              <template v-slot:append>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.003"
                  height="6.291"
                  viewBox="0 0 11.003 6.291"
                  class="mt-3"
                >
                  <path
                    id="Icon_ionic-ios-arrow-back"
                    data-name="Icon ionic-ios-arrow-back"
                    d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                    transform="translate(0 6.291) rotate(-90)"
                    fill="#2c2c2c"
                  />
                </svg>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="4" md="6" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">جهة التمويل</div>
            <v-autocomplete
              multiple
              chips
              deletable-chips
              color="primary"
              :items="$store.state.funding_sources"
              v-model="payload.funding_source"
              hide-details
              item-text="value"
              item-value="id"
              outlined
              dense
              append-icon
            >
              <template v-slot:append>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.003"
                  height="6.291"
                  viewBox="0 0 11.003 6.291"
                  class="mt-3"
                >
                  <path
                    id="Icon_ionic-ios-arrow-back"
                    data-name="Icon ionic-ios-arrow-back"
                    d="M1.9,5.5,6.06,1.34A.786.786,0,0,0,4.946.23L.229,4.943A.785.785,0,0,0,.206,6.028l4.737,4.746a.786.786,0,0,0,1.114-1.11Z"
                    transform="translate(0 6.291) rotate(-90)"
                    fill="#2c2c2c"
                  />
                </svg>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col  v-if="currentRole == 1"  cols="12" lg="12" md="12" class="pb-5 pt-0">
            
            <v-checkbox
            v-model="payload.is_updated"
            label="التي تم التحديث عليها"
            color="red-darken-3"
            value="1"
            hide-details
          ></v-checkbox>
          </v-col> 
          <v-col  cols="12" lg="12" md="12" class="pb-5 pt-0">
            <div class="mb-2 font-weight-bold">
              <span>تاريخ عدم التحديث</span>
            </div>
            <v-text-field
              type="date"
              hide-details
              v-model="payload.request_date_did_not_updated_from"
              outlined
              dense
            >
              <template v-slot:append>
                <svg
                  class="mt-1"
                  id="calendar"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.396"
                  height="16.989"
                  viewBox="0 0 17.396 16.989"
                >
                  <path
                    id="Path_2784"
                    data-name="Path 2784"
                    d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                    transform="translate(-4.21 -7.917)"
                    fill="#6c757d"
                  />
                  <path
                    id="Path_2785"
                    data-name="Path 2785"
                    d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                    transform="translate(-4.21 -4.86)"
                    fill="#6c757d"
                  />
                </svg>
              </template>
            </v-text-field>
          </v-col> 
         
            <v-col cols="12" lg="6" md="6" class="pb-5 pt-0">
              <div class="mb-2 font-weight-bold">
                <span> تم التحديث عليها من</span>
              </div>
              <v-text-field
                type="date"
                hide-details
                v-model="payload.request_date_updated_from"
                outlined
                dense
              >
                <template v-slot:append>
                  <svg
                    class="mt-1"
                    id="calendar"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.396"
                    height="16.989"
                    viewBox="0 0 17.396 16.989"
                  >
                    <path
                      id="Path_2784"
                      data-name="Path 2784"
                      d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                      transform="translate(-4.21 -7.917)"
                      fill="#6c757d"
                    />
                    <path
                      id="Path_2785"
                      data-name="Path 2785"
                      d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                      transform="translate(-4.21 -4.86)"
                      fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" class="pb-5 pt-0">
              <div class="mb-2 font-weight-bold">
                <span>تم التحديث عليها الي</span>
              </div>
              <v-text-field
                type="date"
                hide-details
                v-model="payload.request_date_updated_to"
                outlined
                dense
              >
                <template v-slot:append>
                  <svg
                    class="mt-1"
                    id="calendar"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.396"
                    height="16.989"
                    viewBox="0 0 17.396 16.989"
                  >
                    <path
                      id="Path_2784"
                      data-name="Path 2784"
                      d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                      transform="translate(-4.21 -7.917)"
                      fill="#6c757d"
                    />
                    <path
                      id="Path_2785"
                      data-name="Path 2785"
                      d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                      transform="translate(-4.21 -4.86)"
                      fill="#6c757d"
                    />
                  </svg>
                </template>
              </v-text-field>
            </v-col>
 

        </v-row>
      </v-form>
    </template>
    <template slot="actions">
      <v-btn
        :loading="filterSubmitting"
        @click="submit(page, false)"
        style="width: 100%; max-height: unset; height: unset"
        class="button-colors mb-3 mx-auto py-3 font-weight-bold"
        color="button"
        depressed
        >بحث</v-btn
      >
    </template>
  </c-dialog>
</template>

<script>
import Dialog from "../../mixins/dialog";
import Axios from "axios";
export default {
  mixins: [Dialog],
  data() {
    return {
      currentRole: 0,
      checkBoxStatus: false,
      success: false,
      getting: false,
      fail: false,
      filterSubmitting: false,
      payload: {
        customer_name: [],
        request_status: [],
        agent_classification: [],
        customer_salary: "",
        customer_salary_from: "",
        customer_salary_to: "",
        request_source: [],
        customer_phone: [],
        customer_birth_date: "",
        request_date_updated_from: '',
        request_date_updated_to: '',
        note_status: [],
        request_types: [],
        pay_status: [],
        request_date_from: "",
        request_date_to: "",
        complete_date: [],
        request_date_did_not_updated_from: '',
        work_source: [],
        salary_source: [],
        funding_source: [],
        agent_status: '',
        request_has_calls: "",
      },
      page: 1,
      productType : [],
      request_product_type : [],
      collaborators: [],

    };
  },
  watch: {
    "payload.agent_status": {
      handler(val) {
        if(val != 'all' && val !== undefined){

          this.getAgents();
          this.active = false;
          setTimeout(() => {
            this.active = true;
          }, 0);
        }
      }
    },
    "getting": {
      handler() {
        if(this.payload.agent_status != 'all' && this.payload.agent_status !== undefined){
          this.active = false;
          setTimeout(() => {
            this.active = true;
          }, 0);
        }
      }
    },
    "$store.state.loggedIn": {
      handler() {
          this.currentRole = window.localStorage.getItem("role");
          this.get_salesmanager_agents();
          this.get_customer();
      }
    },
    "$route.name": {
      handler(val) {
        if(val == "salesManager_following" || val == "salesManagerAll" ||  val == "salesManager_SMmortgage" || val == "salesManager_buying" || val == "salesManager_SMmortgagebuying" || val == "salesManager_completed" || val == "salesManager_archieved" || val == "salesManager_recieved" || val == "salesManager_SMrejected")
        {
          this.get_customer();
        }else if(val == "SMEFollowRequests" || val == "SMEAllRequests" ||  val == "SMECompletedRequests" || val == "SMEArchievedRequests" || val == "SMERecievedRequests" || val == "SMESpecialRequests")
        {
          this.get_customer();
        }
      }
    },

    "payload.request_source": {
      handler(val) {
        if (val.includes(2)) {
          this.get_collaborators();
          this.active = false;
          setTimeout(() => {
            this.active = true;
          }, 0);
        }
      },
      immediate: true,
    },

  },
  async created() {
    await this.init();
    await this.get_customer();
    this.get_salesmanager_agents();
    this.getProductType();
  },


  methods: {
    init() {
      this.payload = {
        customer_name: [],
        request_status: [],
        agent_classification: [],
        customer_salary: "",
        customer_salary_from: "",
        customer_salary_to: "",
        request_source: [],
        customer_phone: [],
        customer_birth_date: "",
        request_date_updated_from: '',
        request_date_updated_to: '',
        note_status: [],
        request_types: [],
        pay_status: [],
        request_date_from: "",
        request_date_to: "",
        request_date_did_not_updated_from: '',
        complete_date: [],
        work_source: [],
        salary_source: [],
        funding_source: [],
      };
    },
    async submit(page, isPaginated) {
      if(this.currentRole == 5){
        return;
      }
      if (!isPaginated) {
        this.$root.$emit("RESETPAGE");
        this.active = false;
        setTimeout(() => {
          this.active = true;
        }, 0);
      }
      this.filterSubmitting = true;
      let routName = "";
      (this.$route.name == "recieved" || this.$route.name == "salesManager_recieved" || this.$route.name == "SMERecievedRequests")
        ? (routName = "received")
        : (this.$route.name == "following" || this.$route.name == "salesManager_following" || this.$route.name == "SMEFollowRequests")
        ? (routName = "followed")
        : (this.$route.name == "special" || this.$route.name == "SMESpecialRequests")
        ? (routName = "stared")
        : (this.$route.name == "archieved" || this.$route.name == "salesManager_archieved" || this.$route.name == "SMEArchievedRequests")
        ? (routName = "archived")
        : (this.$route.name == "completed" || this.$route.name == "salesManager_completed" || this.$route.name == "SMECompletedRequests")
        ? (routName = "completed")
        : (this.$route.name == "salesManager_SMmortgagebuying")
        ? (routName = "mortgage_purchase")
        : (this.$route.name == "salesManager_buying")
        ? (routName = "prepayment")
        : (this.$route.name == "salesManager_SMmortgage")
        ? (routName = "mortgage")
        : (this.$route.name == "salesManager_SMrejected")
        ? (routName = "rejected")
        : this.$route.name == "extra"
        ? (routName = "additional")
        : (routName = "all");
      let payload = {
        page: page,
      };
      for (const prop in this.payload) {
        if ((this.payload[prop] != [] && this.payload[prop] != "") || this.payload[prop] === 0) {
          payload[prop] = this.payload[prop];
        }
      }

      this.$store.state.selected_customers = this.payload.customer_name;
      let search_url = `https://appproduct.alwsata.com.sa/api/v2/employee/Requests/search?type=${routName}`;
      if(this.$route.name == "SMEAllRequests" ||
        this.$route.name == "SMERecievedRequests" ||
        this.$route.name == "SMESpecialRequests" ||
        this.$route.name == "SMEFollowRequests" ||
        this.$route.name == "SMECompletedRequests" ||
        this.$route.name == "SMEArchievedRequests" ){
          search_url = `https://appproduct.alwsata.com.sa/api/sales/agentsRequest/search?type=${routName}`;

      }
      await Axios.post(
        search_url,
        payload,
        {
          headers: this.request_headers(),
        }
      )
        .then((response) => {
          this.$store.state.search_result = response.data.payload;
          this.$store.state.search_model = false;
          this.$store.state.search_result_visibility = true;
        })
        .catch((err) => {
          this.showPopup("error", err.response.data.message);
        })
        .finally(() => {
          this.filterSubmitting = false;
        });
    },
    get_customer() {
      if(document.cookie !== ''){
        // check if sales manager related reuests or owned requests
        let sufix = "";
        if(this.$route.name == "salesManager_following" || this.$route.name == "salesManagerAll" ||  this.$route.name == "salesManager_SMmortgage" || this.$route.name == "salesManager_buying" || this.$route.name == "salesManager_SMmortgagebuying" || this.$route.name == "salesManager_completed" || this.$route.name == "salesManager_archieved" || this.$route.name == "salesManager_recieved" || this.$route.name == "salesManager_SMrejected")
        {
          sufix = "?endpoint=sales";
        }else if(this.$route.name == "SMEFollowRequests" || this.$route.name == "SMEAllRequests" ||  this.$route.name == "SMECompletedRequests" || this.$route.name == "SMEArchievedRequests" || this.$route.name == "SMERecievedRequests" || this.$route.name == "SMESpecialRequests")
        {
          sufix = "?endpoint=agents";
        }
        // check if sales manager related reuests or owned requests
        Axios.get(
          "https://appproduct.alwsata.com.sa/api/agent/RelatedCustomers"+sufix,
          {
            headers: this.request_headers(),
          }
        ).then((response) => {
          if(this.$store.state.agent_customers ) {this.$store.state.agent_customers = response.data.payload}
        });
      }

    },

    getProductType() {
      let url = "https://appproduct.alwsata.com.sa/api/customer/SelectedProduct";
      Axios.get(url, { headers: this.request_headers() }).then((res) => {
        this.productType = res.data.payload.map((item) => {
          item["color"] = "red";
          item["text"] = item["value"];
          return item;
        });
      });
    },

    get_collaborators() {
      Axios.get("https://appproduct.alwsata.com.sa/api/agent/Collaborators", {
        headers: this.request_headers(),
      })
        .then((response) => {
          this.collaborators = response.data.payload.map((item) => {
            item["color"] = "red";
            item["text"] = item["name"];
            return item;
          });
        })
        .catch((err) => {
            console.error('Error fetching collaborators:', err);
        })
        .finally(() => {
          this.getting = false;
        });
    },
    async get_salesmanager_agents(page = 1) {
      this.loading = true;
      if(this.$route.name !== "SignIn"){
      await Axios.get(
        `https://appproduct.alwsata.com.sa/api/sales/agents-statistics`,
        {
          headers: this.request_headers(),
        }
      ).then((response) => {
          if(this.$store.state.salesmanager_agents ) {this.$store.state.salesmanager_agents = response.data.payload.data}
          if(this.$store.state.salesmanager_agents_search ) {this.$store.state.salesmanager_agents_search = response.data.payload.data}
        
      });
    }
    },
    getAgents() {
      this.loading = true;
      this.getting = true;
      Axios.get(
        `https://appproduct.alwsata.com.sa/api/sales/agents-statistics?status=${this.payload.agent_status}`,
        {
          headers: this.request_headers(),
        }
      ).then((response) => {
          if(this.$store.state.salesmanager_agents_search ) {this.$store.state.salesmanager_agents_search = response.data.payload.data}
        
      })
      .finally(() => {
          this.getting = false;
        });
    },
  },
  mounted() {
    if(localStorage["role"]){
      this.currentRole = localStorage["role"]
    }
    this.$root.$on("SEARCHRESULT", (page, isPaginated) => {
      this.submit(page, isPaginated);
    });
  }
};
</script>

<style>
.v-chip--select {
  background-color: #3c5de7 !important;
  color: white !important;
  font: bold !important;
  border-radius: 1% !important;
}
.v-chip__close::before {
  color: white !important;
}
.v-col {
  margin: 0px !important;
}
::-webkit-calendar-picker-indicator {
  left: -22px;
  position: absolute;
  z-index: 1;
  opacity: 0;
}
input[type="date"] {
  text-align: end;
}
</style>
