var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pa-3 position-relative",staticStyle:{"height":"100%"}},[_c('span',{staticClass:"right-color requestType",style:(`${_vm.item.status == `طلب تم فتحه` ? `background-color:#40BE72`
: _vm.item.status == `طلب جديد` ? `background-color:#3B5DE7` : _vm.item.status == `مؤرشف لدى استشاري المبيعات`
  ? `background-color:#017E84` : _vm.item.status == `طلب مرفوض` ? `background-color: indianred`
    : _vm.item.status == `في انتظار موافقة مدير المبيعات` ? `background-color: #FF715B` : `background-color:black`}`)}),_c('div',{staticClass:"card-head d-flex justify-space-between align-center"},[_c('v-card-title',{staticClass:"pa-0 requestTitle"},[_vm._v(" "+_vm._s(_vm.item.customer?.name)+" ")]),(_vm.canopen == 1)?_c('div',{staticClass:"icons"},[(_vm.$route.name == 'all' || _vm.$route.name == 'following' || _vm.$route.name == 'SMEAllRequests')?_c('v-btn',{attrs:{"width":"30","height":"30","color":"transparent","depressed":"","fab":""},on:{"click":function($event){return _vm.select_element(_vm.item)}}},[_c('v-icon',{attrs:{"size":"30","color":_vm.in_selected_elements(_vm.item) ? 'primary' : '#999898'}},[_vm._v(" "+_vm._s(_vm.in_selected_elements(_vm.item) ?"mdi-hexagram": "mdi-crop-square")+" ")])],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"-10px"},attrs:{"width":"30","height":"30","color":"transparent","depressed":"","fab":"","light":""}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/eyeIcon.svg")}})])]}}],null,false,3867774694)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.to_request_details(_vm.item)}}},[_c('v-list-item-icon',{staticClass:"ml-3"},[_c('img',{attrs:{"src":require("@/assets/icons/titcketIcon.svg")}})]),_c('v-list-item-title',[_vm._v("فتح")])],1),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"target":"_blank","to":{
                    name: 'salesManager_request_details',
                    params: { id: _vm.item.id },
                  }}},[_c('v-list-item',[_c('v-list-item-icon',{staticClass:"ml-3"},[_c('img',{attrs:{"src":require("@/assets/icons/titcketIcon.svg")}})]),_c('v-list-item-title',[_vm._v(" فتح في تاب جديد")])],1)],1)],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"req-status-parent mt-1 d-flex justify-space-between align-center"},[_c('span',{staticClass:"req-type",staticStyle:{"color":"#444B5A","font-size":"15px"}},[_vm._v(" "+_vm._s(_vm.item.type || "لايوجد نوع للطلب")+" ")]),_c('span',{staticClass:"req-status spanRequestType",style:(`${_vm.item.status == `طلب تم فتحه` ? `background-color:#40BE72` : _vm.item.status == `طلب جديد`
? `background-color:#3B5DE7` : _vm.item.status == `مؤرشف لدى استشاري المبيعات` ? `background-color:#017E84`
  : _vm.item.status == `طلب مرفوض` ? `background-color: indianred` : _vm.item.status == `في انتظار موافقة مدير المبيعات`
    ? `background-color: #FF715B` : `background-color:black`}`)},[_vm._v(_vm._s(_vm.item.status)+" ")])]),_c('div',{staticClass:"req-status-parent mt-1 d-flex justify-space-between align-center"},[_c('span',{staticClass:"req-type",staticStyle:{"color":"#444B5A","font-size":"15px"}},[_vm._v(" استشاري المبيعات1 ")]),_c('span',{staticClass:"req-status"},[_vm._v(" "+_vm._s(_vm.item.user.name)+" ")])]),_c('div',{staticClass:"req-date-parent my-2 d-flex justify-space-between align-center"},[_c('span',{staticClass:"req-date"},[_vm._v(" "+_vm._s(_vm.item.agent_date.split(" ")[0])+" ")]),_c('span',{staticClass:"req-status",style:(`color: #444B5A ; font-size: 15px;`)},[_vm._v(" "+_vm._s(_vm.item.class_id_agent_text || "لايوجد تصنيف معاملة")+" ")])]),_c('div',{staticClass:"req-date-parent my-2 d-flex justify-space-between align-center"},[_c('span',{staticClass:"req-date"},[_vm._v(" هل تم استلامة من قبل الجودة ")]),_c('span',{staticClass:"req-status",style:(`color: #444B5A ; font-size: 15px;`)},[_vm._v(" "+_vm._s(_vm.item.class_id_quality ? "نعم" : "لا")+" ")])]),_c('hr',{staticClass:"mb-0",staticStyle:{"height":"1px !important","opacity":"0.3"}}),_c('div',{staticClass:"desc parent"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-card-text',_vm._g({staticClass:"pt-2 px-0"},on),[_vm._v(" "+_vm._s(_vm.item.agent_notes ? _vm.item.agent_notes.length > 50 ? _vm.item.agent_notes.substring(0, 50) + "..." : _vm.item.agent_notes : "لايوجد ملاحظات")+" ")])]}}])},[_c('div',{staticStyle:{"color":"#444B5A","font-size":"14px"}},[_vm._v(_vm._s(_vm.item.agent_notes || "لايوجد ملاحظات"))])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }