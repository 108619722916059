<template>
  <form @submit.prevent="submit" style="display: flex; justify-content: center">
    <div class="notification__container">
      <div class="header__text">تعديل إرسال الإشعارت للمستخدم</div>
      <v-row style="background: white">
        <v-col cols="12" sm="4" md="4">
          <v-checkbox
            v-model="status[0].checked"
            label="محاولة العميل من تسجيل طلب مكرر"
            type="checkbox"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-checkbox
            v-model="status[1].checked"
            label=" رسالة جديدة من العميل"
            type="checkbox"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-checkbox
            label="طلب دعم فني"
            v-model="status[2].checked"
            type="checkbox"
          ></v-checkbox>
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <v-checkbox
            v-model="status[3].checked"
            label=" إفراغ الطلب"
            type="checkbox"
          ></v-checkbox>
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <v-checkbox
            v-model="status[4].checked"
            label="طلب جديد"
            type="checkbox"
          ></v-checkbox>
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <v-checkbox
            v-model="status[5].checked"
            label="متابعة الطلب"
            type="checkbox"
          ></v-checkbox>
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <v-checkbox
            v-model="status[6].checked"
            label="تنبيه اقتراح تعديل الحسبة"
            type="checkbox"
          ></v-checkbox>
        </v-col>

        <v-col cols="12" sm="4" md="4">
          <v-checkbox
            v-model="status[7].checked"
            label=" وجود خطأ في فورم اطلب تمويل"
            type="checkbox"
          ></v-checkbox>
        </v-col>
        <v-btn class="me-4" id="submit__button" type="submit" @click="handleEdit">
          اضافه</v-btn
        >
      </v-row>
    </div>
  </form>
</template>
<script setup>
import { ref, onBeforeMount, watch } from "vue";
import { axiosInstance } from "@/axios/config";
import router from "@/routes";

const status = ref([]);
const addBody = ref({});
watch(
  () => status.value,
  (newValue) => {
    const body = new FormData();
    for (let i = 0; i < newValue.length; i++) {
      if (newValue[i].checked === true) {
        body.append(`emails[${i}]`, newValue[i].id);
        addBody.value = body;
      }
    }
  },
  { deep: true }
);

const handleEdit = () => {
  axiosInstance
    .post(
      `/employee/admin/updateAllMailsNotifications/${router.currentRoute.params.id}`,
      addBody.value
    )
    .then((res) => {
      router.push({
        name: "users",
      });
    });
};

const getData = () => {
  axiosInstance
    .get(`/employee/admin/getAllMailsNotifications/${router.currentRoute.params.id}`)
    .then((res) => {
      status.value = res.data.payload;
    });
};

onBeforeMount(() => {
  getData();
});
</script>

<style>
.header__text {
  height: 50px;
  display: flex;
  align-items: center;
  background: #f7f7f7;
  margin-bottom: 13px;
}
.notification__container {
  width: 90%;
  border: 2px solid #ededed;
  margin-top: 20px;
  padding: 12px;
  border-radius: 4px;
}

#submit__button {
  background: #45184e;
  color: white;
  height: 40px;
  margin-bottom: 20px;
  width: 95%;
  border-radius: 4px;
  font-size: 19px;
  margin-right: 30px;
}
</style>
