<template>
  <div style="margin-top: 40px">
    <GroupProccess
      :selectedItems="selected_requests"
      @filterData="handleFilter"
    />


    <template>
     <v-row>
      <v-col cols="12" sm="7" md="7">
        <!-- <v-tabs v-model="selectedTab" style="margin-bottom: 50px;" class="custom-tabs-center-1">
          <div class="custom-tab-wrapper" :style="{ backgroundColor: selectedTab === 0 ? '#3f0e40' : ''}">
            <v-tab :key="0" @click="tabsFilter(0)" class="custom-tab">جميع التقييمات</v-tab>
          </div>
          <div class="custom-tab-wrapper" :style="{ backgroundColor: selectedTab === 1 ? '#3f0e40' : ''}">
            <v-tab :key="1" @click="tabsFilter(1)" class="custom-tab">تمت معالجتها</v-tab>
          </div>
          <div class="custom-tab-wrapper" :style="{ backgroundColor: selectedTab === 2 ? '#3f0e40' : ''}">
            <v-tab :key="2" @click="tabsFilter(2)" class="custom-tab">تحت المعالجة</v-tab>
          </div>
        </v-tabs> -->

        <div style="display: flex;margin-bottom: 21px;">

          <div class="text-center right_bouttons" style="position: relative">
            <v-btn
              class="ma-2 white--text filter__border"
              :style="{
                'background-color': selectedTab === 2 ? '#3F0E40' : '#8E8E8E',
              }"
              @click="tabsFilter(2)"
            >
              <span :style="{ color: selectedTab === 2 ? 'white' : 'white' }">
                تحت المعالجة
              </span>
              <div :class="[selectedTab === 2 && switchColor.notProccessedFromSalesManager ? 'down__arrow' : '']"></div>
            </v-btn>
          </div>

          <div class="text-center right_bouttons" style="position: relative">
            <v-btn
              class="ma-2 white--text filter__border"
              :style="{
                'background-color': selectedTab === 1 ? '#3F0E40' : '#8E8E8E',
              }"
              @click="tabsFilter(1)"
            >
              <span :style="{ color: selectedTab === 1 ? 'white' : 'white' }">
              
                تمت معالجتها
              </span>
              <div :class="[selectedTab === 1 && switchColor.ProccessedFromSalesManager ? 'down__arrow' : '']"></div>
            </v-btn>
          </div>

          <div class="text-center right_bouttons" style="position: relative">
            <v-btn
              class="ma-2 white--text filter__border"
              :style="{
                'background-color': selectedTab === 0 ? '#3F0E40' : '#8E8E8E',
              }"
              @click="tabsFilter(0)"
            >
              <span :style="{ color: selectedTab === 0 ? 'white' : 'white' }">
                جميع التقييمات
              </span>
              <div :class="[selectedTab === 0 && switchColor.getAllRates ? 'down__arrow' : '']"></div>
            </v-btn>
          </div>

       

        </div>
      </v-col>

      <v-col cols="12" sm="3" md="3" class="offset-sm-2 offset-md-2">
        <v-tabs class="custom-tabs-center-2">
          <div style="margin: 10px; text-align: center;">
            <v-rating
              v-model="number_of_stars"
              active-color="#F2C94C"
              color="#F2C94C"
              @click="handleStarsFilter"
              density="compact"
              size="large"
            ></v-rating>
          </div>
        </v-tabs>
      </v-col>
     </v-row>
    </template>


    <!-- <template>
      <v-tabs v-model="selectedTab" class="custom-tabs" style="margin-bottom: 50px; color: white;">
        <v-tab key="0" @click="tabsFilter(0)" class="custom-tab" :style="{ backgroundColor: selectedTab === 0 ? '#3f0e40' : '' }">تحت المعالجة</v-tab>
        <v-tab key="1" @click="tabsFilter(1)" class="custom-tab" :style="{ backgroundColor: selectedTab === 1 ? '#3f0e40' : '' }">تمت معالجتها</v-tab>
        <v-tab key="" @click="tabsFilter" class="custom-tab" :style="{ backgroundColor: selectedTab !== 0 && selectedTab !== 1 ? '#3f0e40' : '' }">جميع التقييمات</v-tab>
      </v-tabs>
    </template> -->


    <new-table-component
      :showSelect="true"
      itemKey="id"
      :selectedRows.sync="selected_requests"
      :headers="getHeaders"
      :pagination="pagination"
      :limit="$store.state.counterOfShow"
      :items="tableData"
      :loading="loading"
      v-model="page"
    >
      <template slot="item.stars" slot-scope="props">
        <div class="text-center">
          <v-rating
            v-model="props.item.stars"
            bg-color="orange-lighten-1"
            color="yellow"
            readonly
          ></v-rating>
        </div>
      </template>


      <template slot="item.customer" slot-scope="props">
        <span v-if="!props.item.customer"></span>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{
              props.item.customer.substring(0, 10) + "..."
            }}</span>
          </template>
          <div class="font-weight-bold">
            {{ props.item.customer }}
          </div>
        </v-tooltip>
      </template>

      <template slot="item.agent" slot-scope="props">
        <span v-if="!props.item.agent"></span>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{
              props.item.agent.substring(0, 10) + "..."
            }}</span>
          </template>
          <div class="font-weight-bold">
            {{ props.item.agent }}
          </div>
        </v-tooltip>
      </template>

      <template slot="item.agent_notes" slot-scope="props">
        <span v-if="!props.item.request?.comment"></span>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{
              props.item.request?.comment.substring(0, 10) + "..."
            }}</span>
          </template>
          <div class="font-weight-bold">
            {{ props.item.request?.comment }}
          </div>
        </v-tooltip>
      </template>

      <template slot="item.comments" slot-scope="props">
        <span v-if="!props.item.comments"></span>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{
              props.item.comments.substring(0, 10) + "..."
            }}</span>
          </template>
          <div class="font-weight-bold">
            {{ props.item.comments }}
          </div>
        </v-tooltip>
      </template>

      <template slot="item.sales_notes" slot-scope="props">
        <span v-if="!props.item.notes"></span>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{
              props.item.notes.substring(0, 10) + "..."
            }}</span>
          </template>
          <div class="font-weight-bold">
            {{ props.item.notes }}
          </div>
        </v-tooltip>
      </template>

      <template slot="item.source" slot-scope="props">
        <span v-if="!props.item.source"></span>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{
              props.item.source.substring(0, 10) + "..."
            }}</span>
          </template>
          <div class="font-weight-bold">
            {{ props.item.source }}
          </div>
        </v-tooltip>
      </template>

      <template slot="item.created_at" slot-scope="props">
        <div>
          <div>{{ props.item.created_at?.split(' ')[0] }}</div> <!-- Display date -->
          <div>{{ extractTime(props.item.created_at) }}</div> <!-- Display time -->
        </div>
      </template>
      <template slot="item.agent_classification" slot-scope="props">
        <div>
          {{ props.item.request?.class_id_agent_text }}
        </div>
      </template>

      <template slot="item.is_processed_by_sales_manager" slot-scope="props">
        <div :style="{ color: isProccessed(props.item.is_processed) == 'نعم' ? 'green' : 'red' }">
          {{ isProccessed(props.item.is_processed) }}
        </div>
      </template>

      <template slot="item.notes" slot-scope="props" v-if="props.item.is_processed != 0">
        <div>
          {{ props.item.notes }}
        </div>
      </template>


      <template slot="item.customer" slot-scope="props">
        <span v-if="!props.item.customer?.name"></span>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{
              props.item.customer?.name.substring(0, 10) + "..."
            }}</span>
          </template>
          <div class="font-weight-bold">
            {{ props.item.customer?.name }}
          </div>
        </v-tooltip>
      </template>

      <template slot="item.mobile" slot-scope="props">
        <div>
          {{ props.item.customer?.mobile }}
        </div>
      </template>

      <template slot="item.request_id" slot-scope="props">
        <div>
          {{ props.item.request?.id }}
        </div>
      </template>

      <template slot="item.agent" slot-scope="props">
        <span v-if="!props.item.user.name"></span>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{
              props.item.user?.name.substring(0, 10) + "..."
            }}</span>
          </template>
          <div class="font-weight-bold">
            {{ props.item.user?.name }}
          </div>
        </v-tooltip>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#3F0E40"
              small
              depressed
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <SingleProcess :is_processed_value="item.is_processed" :is_request_in_sales_manager="item.is_request_in_sales_manager" :item="item.request?.id" :idOfRate="item['id']" />
          </v-list>
        </v-menu>
      </template>
    </new-table-component>
    <v-dialog
      v-model="this.$store.state.openOrderRequestSection"
    >
    <OrderRequestSection
      v-if="this.$store.state.openOrderRequestSection"
      style="margin-top: 50px"
    />
    
  </v-dialog>

    <v-dialog v-model="$store.state.dialogOfRateNotes" width="60%">
      <v-card>
        <v-card-title> الملاحظة</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="Salesnotes"
            dense
            width="100%"
          ></v-text-field>

        </v-card-text>

        <v-card-actions>
          <v-btn color="primary" @click="$store.state.dialogOfRateNotes = false">الغاء</v-btn>
          <v-btn color="success" @click="handleProccessedInSalesManager" >تمت المعالجة</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="activeSnack"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <div>
        <span class="font-weight-bold" style="font-size: 16px">{{ MessageOFResponse }}</span>
      </div>
      <template v-slot:action>
        <v-btn icon small @click="activeSnack = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>


    <v-snackbar
      v-model="$store.state.showAlertOfRatingDidntProccess"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
      color="red"
    >
      <div>
        <span class="font-weight-bold" style="font-size: 16px">يوجد تقييمات تحتاج معالجة</span>
      </div>
      <template v-slot:action>
        <v-btn icon small @click="$store.state.showAlertOfRatingDidntProccess = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <TasksRequestSection
      v-if="this.$store.state.openTasksRequestSection"
      style="margin-top: 50px"
    />

    <AddTaskForRequest
      v-if="this.$store.state.openAddTaskForRequest"
      style="margin-top: 50px"
    />

    <TasksDetailsRequestSection
      v-if="this.$store.state.openTasksDetailsRequestSection"
      style="margin-top: 50px"
    />

  </div>
</template>

<script setup>
import { ref, onMounted, watch, onBeforeUnmount,computed,defineEmits } from "vue";
import { axiosInstance } from "@/axios/config";
import SingleProcess from "./SingleProcess.vue";
import OrderRequestSection from "@/components/AdminComponent/OrderRequestSection.vue";
import TasksRequestSection from "@/components/AdminComponent/TasksRequestSection.vue";
import AddTaskForRequest from "@/components/AdminComponent/AddTaskForRequest.vue";
import TasksDetailsRequestSection from "@/components/AdminComponent/TasksDetailsRequestSection.vue";
import GroupProccess from "./GroupProccess.vue";
import { store } from "@/store";


let loading = ref(false);
let Salesnotes = ref("");
let MessageOFResponse = ref("");
const activeSnack = ref(false);

const headers = [
  { text: "تاريخ التقيم", value: "created_at", width: "120px",align: "center",},
  { text: "الاسم", value: "customer",width: "120px",align: "center" },
  { text: "استشاري المبيعات", value: "agent",width: "120px",align: "center" },
  { text: "تصنيف الطلب", value: "agent_classification",width: "120px",align: "center" },
  { text: "ملاحظة الاستشاري", value: "agent_notes",width: "120px",align: "center" },
  { text: "الجوال", value: "mobile",width: "120px", align: "center"  },
  { text: "التقييم", value: "stars",width: "120px", align: "center" },
  { text: "التعليق", value: "comments", width: "100px",align: "center" },
  { text: "عالجه م.المبيعات", value: "is_processed_by_sales_manager",width: "120px",align: "center" },
  { text: "تعقيب م.مبيعات", value: "sales_notes",width: "120px",align: "center" },
  { text: "مصدر التقييم", value: "source",width: "120px",align: "center" },
  { text: "العمليات", value: "action",align: "center",width: "50px" },
];

const page = ref(1);
const filterData = ref({});
const number_of_stars = ref(null);

const switchColor = ref({
  getAllRates: true,
  notProccessedFromSalesManager: false,
  ProccessedFromSalesManager: false,
});


watch(page, (current) => {
  getData(current, filterData.value);
});

watch(() => store.state.counterOfShow,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      getData(page.value)
    }
  }
);


watch(number_of_stars, (newVal) => {
  handleStarsFilter(newVal);
});


const handleFilter = (data) => {
  filterData.value = data;
  tableData.value = [];
  page.value = 1;
  getData(1, data);
};

const handleStarsFilter = (stars) => {
  const starsArray = Array.isArray(stars) ? stars : [stars];
  filterData.value.stars = starsArray;
  // filterData.value.stars = stars;
  getData(page.value, filterData.value);
};


const pagination = ref({});
const tableData = ref([]);
let selectedTab = ref(2);

const getData = (count, data) => {
  loading.value = true;

  let body = new FormData();
  body.append("page", count);

  if (data) {
    for (const key in data) {
      if (
        data[key] !== "" &&
        data[key] !== undefined &&
        data[key] !== null &&
        key !== "agents" &&
        key !== "stars"
      ) {
        body.append(key, data[key]);
      }

      if (key === "agents" && data[key] != "" && data[key] !== null) {
        const agentsArray = data[key];
        agentsArray.forEach((agent, index) => {
          body.append(`agents[${index}]`, agent);
        });
      }

      if (key === "stars" && data[key] != "" && data[key] !== null) {
        const starsArray = data[key];
        starsArray.forEach((agent, index) => {
          body.append(`stars[${index}]`, agent);
        });
      }

    }
  }

  if(selectedTab.value == 0){
    switchColor.value.getAllRates = true;
    body.delete(`is_processed`);
  }
  
  if(selectedTab.value == 1){
    switchColor.value.ProccessedFromSalesManager = true;
    body.append(`is_processed`,1);
  }

  if(selectedTab.value == 2){
    switchColor.value.notProccessedFromSalesManager = true;
    body.append(`is_processed`,0);
  }

  body.append('perPage',store.state.counterOfShow)

  axiosInstance
    .post(`/employee/rates/search`, body)
    .then((res) => {
      const response = res.data.payload;
      tableData.value = response.data;
      let paginationCount = null;
      paginationCount = response.total;
      pagination.value = {
        limit: 3,
        count: paginationCount,
      };
      loading.value = false;
    })
    .catch((err) => (loading.value = false));
};

const isProccessed = (status) => {
    if(status == '2'){
      return `نعم`;
    }else if(status == '1'){
      return `نعم`;
    }else{
      return `لا`;
    }
  // return status == 1 ? `نعم` : `لا`;
};

const emit = defineEmits(["refreshTopBarFunctionality"]);


const extractTime = (rateDate) => {
  // Regular expression to match the time part
  const timeRegex = /\d{2}:\d{2} [AP]M/;
  // Extract the time part using regex
  const timeMatch = rateDate?.match(timeRegex);
  // If match found, return the time part
  return timeMatch ? timeMatch[0] : '';
};

const handleProccessedInSalesManager = () =>{

  let body = new FormData();
  body.append("notes",Salesnotes.value);
  axiosInstance
    .post(`/employee/admin/convert-to-proccess/${store.state.idOfRateInSalesMAnager}`, body)
    .then((res) => {
      MessageOFResponse.value = res.data.payload;
      activeSnack.value = true;
      store.state.dialogOfRateNotes = false;
      Salesnotes.value = '';
      getData(page.value,filterData.value)
      emit('refreshTopBarFunctionality')
    })
    .catch((err) => {
      MessageOFResponse.value = err.response.data.message.notes[0]
      activeSnack.value = true;
    });

};

const tabsFilter = (value) => {
  selectedTab.value = value;
  getData(page.value,filterData.value)
};

onBeforeUnmount(() => {
  store.state.openOrderRequestSection = false;
});

let selected_requests = ref([]);

onMounted(() => {
  getData(1);
});

const getHeaders = computed(() => {
  if (selectedTab.value === 2) {
    return headers.filter(header => header.value !== "sales_notes" && header.value !== "is_processed_by_sales_manager");
  } else if (selectedTab.value === 1) {
    return headers.filter(header => header.value !== "is_processed_by_sales_manager");
  } else {
    return headers;
  }
});

</script>


<style scoped>
.custom-tabs {
  border-bottom: 1px solid #ccc; /* Set your desired border color */
}

.custom-tab {
  color: white; /* Set your desired text color */
  font-weight: bold;
  transition: background-color 0.3s ease; /* Add transition for a smooth effect */
}

.custom-tab:hover {
  color: white; /* Set your desired hover text color */
}

.custom-tab--active {
  background-color: #FF5252; /* Set your desired background color for the selected tab */
  color: #fff; /* Set text color for the selected tab */
}

.custom-tab-wrapper {
  background-color: #e3dce3;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
}

.custom-tabs-center-1 {
  display: flex !important;
  justify-content: right !important;
}

.custom-tabs-center-2 {
  display: flex !important;
  justify-content: left !important;
}

.down__arrow {
  position: absolute;
  bottom: -19px;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid rgb(63, 14, 64);
  transform: translateX(-50%);
}
</style>
