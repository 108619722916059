<template>
    <div style="display: flex; justify-content: center; align-items: center;">
        <div role="progressbar" aria-valuemin="0" aria-valuemax="100"
            class="v-progress-circular v-progress-circular--visible v-progress-circular--indeterminate primary--text"
            style="height: 30px; width: 30px;"><svg xmlns="http://www.w3.org/2000/svg"
                viewBox="23.076923076923077 23.076923076923077 46.15384615384615 46.15384615384615"
                style="transform: rotate(0deg);">
                <circle fill="transparent" cx="46.15384615384615" cy="46.15384615384615" r="20"
                    stroke-width="6.153846153846153" stroke-dasharray="125.664" stroke-dashoffset="125.66370614359172px"
                    class="v-progress-circular__overlay"></circle>
            </svg>
            <div class="v-progress-circular__info"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoadingMore",
}
</script>