export const SendToQualityIcon = () => {
    return(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20.227"
            height="20.227"
            viewBox="0 0 20.227 20.227"
        >
            <g
                id="Icon_feather-send"
                data-name="Icon feather-send"
                transform="translate(-2.5 -2.293)"
            >
                <path
                    id="Path_4004"
                    data-name="Path 4004"
                    d="M26.961,3,16.5,13.461"
                    transform="translate(-4.941)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                />
                <path
                    id="Path_4005"
                    data-name="Path 4005"
                    d="M22.02,3,15.363,22.02l-3.8-8.559L3,9.657Z"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                />
            </g>
        </svg>

    )
}