<template>
  <v-row justify="center">
    <v-dialog
        v-model="$store.state.openConditionDialog"
        persistent
        width="824"
    >
      <v-card id="adminFilter">
        <v-card-title>
          <span class="text-h5">البحث</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>

              <v-col cols="12" sm="6" md="6">
                <PickerInput label="من تاريخ الميلاد(ميلادي)" @dateValue="saveBirthDateFromM"/>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <PickerInput label="الي تاريخ الميلاد(ميلادي)" @dateValue="saveBirthDateToM"/>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <PickerInput label="من تاريخ الميلاد(هجري)" @dateValue="saveBirthDateFromH"/>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <PickerInput label="من تاريخ الميلاد(هجري)" @dateValue="saveBirthDateToH"/>
              </v-col>


              <v-col cols="12" sm="6" md="6">
                <v-text-field
                    label="من راتب العميل"
                    v-model="obj.request_validation_from_salary"
                    outlined
                    dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                    label="الي راتب العميل"
                    v-model="obj.request_validation_to_salary"
                    outlined
                    dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                    :items="workList"
                    label="جهه العمل"
                    v-model="obj.request_validation_to_work"
                    outlined
                    dense
                >
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-title
                          :id="attrs['aria-labelledby']"
                          v-text="item"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                    :items="['الكل','لا', 'نعم']"
                    label="هل هو مدعوم"
                    v-model="obj.request_validation_to_support"
                    outlined
                    dense
                >
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-title
                          :id="attrs['aria-labelledby']"
                          v-text="item"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                    :items="['الكل','لا', 'نعم']"
                    label="هل وجد عقار؟"
                    v-model="obj.request_validation_to_hasProperty"
                    outlined
                    dense
                >
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-title
                          :id="attrs['aria-labelledby']"
                          v-text="item"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                    :items="['الكل','لا', 'نعم']"
                    label="هل لديه متضامن؟"
                    v-model="obj.request_validation_to_hasJoint"
                    outlined
                    dense
                >
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-title
                          :id="attrs['aria-labelledby']"
                          v-text="item"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                    :items="['الكل','لا', 'نعم']"
                    label="هل لديه التزامات؟"
                    v-model="obj.request_validation_to_has_obligations"
                    outlined
                    dense
                >
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-title
                          :id="attrs['aria-labelledby']"
                          v-text="item"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                    :items="['الكل','لا', 'نعم']"
                    label="هل لديه تعثرات؟"
                    v-model="obj.request_validation_to_has_financial_distress"
                    outlined
                    dense
                >
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-title
                          :id="attrs['aria-labelledby']"
                          v-text="item"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                    :items="['الكل','لا', 'نعم']"
                    label="هل يمتلك عقار؟"
                    v-model="obj.request_validation_to_owningProperty"
                    outlined
                    dense
                >
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-title
                          :id="attrs['aria-labelledby']"
                          v-text="item"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue-darken-1"
              variant="text"
              @click="$store.state.openConditionDialog = false"
          >
            الغاء
          </v-btn>
          <v-btn
              color="blue-darken-1"
              variant="text"
              @click='searchRequest'
          >
            تنفيذ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import PickerInput from "@/components/AdminComponent/PickerInput.vue";
import Axios from "axios";
import {convertToFormData} from "@/mixins/convertToFormDate";

export default {
  name: 'PendingCondition',
  components: {PickerInput},
  data(){
    return{
      obj: {
        request_validation_from_birth_date: '',
        request_validation_to_birth_date: '',
        request_validation_from_birth_hijri: '',
        request_validation_to_birth_hijri: '',
        request_validation_from_salary: '',
        request_validation_to_salary: '',
        request_validation_to_work: '',
        request_validation_to_support: '',
        request_validation_to_hasProperty: '',
        request_validation_to_hasJoint: '',
        request_validation_to_has_obligations: '',
        request_validation_to_has_financial_distress: '',
        request_validation_to_owningProperty: '',
      },
        workList: [],
    }
  },
  methods:{
    saveBirthDateFromM(val){
      this.obj.request_validation_from_birth_date = val
    },

    saveBirthDateToM(val){
      this.obj.request_validation_to_birth_date = val
    },

    saveBirthDateFromH(val){
      this.obj.request_validation_from_birth_hijri = val
    },

    saveBirthDateToH(val){
      this.obj.request_validation_to_birth_hijri = val
    },

    get_work_list() {
      this.workList = this.$store.state.works_list.map((item) => {
          item["color"] = "red";
          return item['value'] 
        });
    },

    searchRequest(){
      let keyValuePairs = [];

      for (const prop in this.obj) {
        if (Object.prototype.hasOwnProperty.call(this.obj, prop) &&
            (Array.isArray(this.obj[prop]) ? this.obj[prop].length > 0 : this.obj[prop] !== '')) {
          keyValuePairs.push({ key: prop, value: this.obj[prop] });
        }
      }

      let body = convertToFormData(keyValuePairs)
      let url = `${this.$store.state.url}/v2/employee/Requests/pending/condition`

      Axios.post(url, body, {headers: this.request_headers()}).then(res => {
        this.showPopup("success", res.data.message);
        this.$store.state.openConditionDialog = false
      })
    }
  },

  created() {
    this.get_work_list()
  }
}
</script>


