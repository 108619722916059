<template>
  <div>
    <UserCard v-if="$store.state.isProfile" />
    <ProfileHeader />
    <v-form class="mt-5 mb-5" :disabled="!active">
      <v-card flat>
        <v-layout
          row
          wrap
          style="background-color: #f3f5f7"
          class="userProfile__container"
        >
          <v-col cols="12" lg="4" md="4" sm="6" xs="12">
            <v-card class="pa-3 position-relative userProfile__card">
              <v-col cols="12" sm="12">
                <div style="display: flex" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('customerName')"
                    />
                    إسم الموظف كاملاً
                  </div>
                  <div class="horizontal__line"></div>
                  <div>
                    <v-text-field
                      type="text"
                      class="form-control"
                      style="
                        background-color: #fbfbfb;
                        border-right: #fbfbfb;
                        margin-top: -20px;
                      "
                      v-model="$store.state.userProfileData.name"
                      ref="customerName"
                    />
                  </div>
                </div>
              </v-col>

              <v-col cols="12" sm="12">
                <div style="display: flex" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('customerName')"
                    />
                    إسم الموظف باللغة الإنجليزية
                  </div>
                  <div class="horizontal__line"></div>
                  <div>
                    <v-text-field
                      type="text"
                      class="form-control"
                      style="
                        background-color: #fbfbfb;
                        border-right: #fbfbfb;
                        margin-top: -20px;
                      "
                      v-model="$store.state.userProfileData.name_en"
                      ref="customerName"
                    />
                  </div>
                </div>
              </v-col>

              <v-col cols="12" sm="12">
                <div style="display: flex" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('customerName')"
                    />
                    البريد الإلكترونى الرسمى
                  </div>
                  <div class="horizontal__line"></div>
                  <div>
                    <v-text-field
                      type="text"
                      class="form-control"
                      style="
                        background-color: #fbfbfb;
                        border-right: #fbfbfb;
                        margin-top: -20px;
                      "
                      v-model="$store.state.userProfileData.email"
                      ref="customerName"
                    />
                  </div>
                </div>
              </v-col>

              <v-col cols="12" sm="12">
                <div style="display: flex; position: relative" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('mobile')"
                    />
                    رقم الجوال
                  </div>
                  <div class="horizontal__line"></div>
                  <div>
                    <v-text-field
                      type="text"
                      class="form-control"
                      style="
                        background-color: #fbfbfb;
                        border-right: #fbfbfb;
                        margin-top: -20px;
                      "
                      v-model="$store.state.userProfileData.mobile"
                      v-numericOnly
                    />

                    <img
                      src="@/assets/icons/add-new.svg"
                      alt="image"
                      @click="showNums = !showNums"
                      style="
                        position: absolute;
                        left: 35px;
                        top: 9px;
                        width: 16px;
                        height: 25px;
                        z-index: 1;
                      "
                    />
                    <img
                      style="
                        cursor: pointer;
                        position: absolute;
                        left: 10px;
                        top: 10px;
                        z-index: 1;
                      "
                      @click="secondNumber = !secondNumber"
                      src="@/assets/add-num.png"
                      alt=""
                    />
                  </div>
                </div>
              </v-col>

              <v-col cols="12" sm="12">
                <div style="display: flex; align-items: center" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('work')"
                    />
                    الجنسية
                  </div>
                  <div class="horizontal__line"></div>

                  <v-autocomplete
                    :items="nationality"
                    hide-details
                    v-model="$store.state.userProfileData.control_nationality_id"
                    item-text="value"
                    item-value="id"
                    style="border: none !important"
                    class="mr-1"
                    outlined
                    dense
                  >
                  </v-autocomplete>
                  <img src="@/assets/icons/down-arrow.svg" />
                </div>
              </v-col>

              <v-col cols="12" sm="12">
                <div style="display: flex; align-items: center" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('work')"
                    />
                    الحالة الإجتماعية
                  </div>
                  <div class="horizontal__line"></div>

                  <v-autocomplete
                    :items="[
                      { value: 'single', text: 'اعزب' },
                      { value: 'married', text: 'متزوج' },
                      { value: 'divorced', text: 'مطلق' },
                      { value: 'widow', text: 'ارمل' },
                    ]"
                    hide-details
                    v-model="$store.state.userProfileData.marital_status"
                    item-text="text"
                    item-value="value"
                    style="border: none !important"
                    class="mr-1"
                    outlined
                    dense
                  >
                  </v-autocomplete>
                  <img src="@/assets/icons/down-arrow.svg" />
                </div>
              </v-col>

              <v-col cols="12" sm="12">
                <div style="display: flex; position: relative" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('age')"
                    />
                    عدد افراد الأسرة
                  </div>
                  <div class="horizontal__line"></div>
                  <div>
                    <v-text-field
                      type="text"
                      class="form-control"
                      style="
                        background-color: #fbfbfb;
                        border-right: #fbfbfb;
                        margin-top: -20px;
                      "
                      v-model="$store.state.userProfileData.family_count"
                      v-numericOnly
                    />
                  </div>
                </div>
              </v-col>
            </v-card>
          </v-col>

          <v-col cols="12" lg="4" md="4" sm="6" xs="12">
            <v-card class="pa-3 position-relative userProfile__card">
              <v-col cols="12" sm="12">
                <div style="display: flex; align-items: center" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('joint_hiring_date')"
                    />
                    تاريخ الميلاد هجري
                  </div>
                  <div class="horizontal__line"></div>

                  <v-text-field
                    style="margin-bottom: 5px"
                    hide-details
                    v-model="$store.state.userProfileData.birth_date"
                    class="mr-1"
                    dense
                    append-icon
                  >
                    <template v-slot:append>
                      <svg
                        @click="showStartHiringDate = !showStartHiringDate"
                        id="calendar"
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.396"
                        height="16.989"
                        viewBox="0 0 17.396 16.989"
                        style="margin-top: 15px; cursor: pointer"
                      >
                        <path
                          id="Path_2784"
                          data-name="Path 2784"
                          d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                          transform="translate(-4.21 -7.917)"
                          fill="#6c757d"
                        />
                        <path
                          id="Path_2785"
                          data-name="Path 2785"
                          d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                          transform="translate(-4.21 -4.86)"
                          fill="#6c757d"
                        />
                      </svg>
                    </template>
                  </v-text-field>
                  <v-hijri-date-picker
                    @change="showStartHiringDate = false"
                    v-if="showStartHiringDate"
                    style="position: absolute; z-index: 1000"
                    v-model="$store.state.userProfileData.birth_date"
                    locale="ar"
                  />
                </div>
              </v-col>

              <!-- <v-col cols="12" sm="12">
                <div style="display: flex; align-items: center" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('joint_hiring_date')"
                    />
                    تاريخ الميلاد هجري
                  </div>
                  <div class="horizontal__line"></div>

                  <v-text-field
                    style="margin-bottom: 5px"
                    hide-details
                    v-model="$store.state.userProfileData.birth_date"
                    class="mr-1"
                    dense
                    append-icon
                  >
                    <template v-slot:append>
                      <svg
                        @click="showHiringDate = !showHiringDate"
                        id="calendar"
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.396"
                        height="16.989"
                        viewBox="0 0 17.396 16.989"
                        style="margin-top: 15px"
                      >
                        <path
                          id="Path_2784"
                          data-name="Path 2784"
                          d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                          transform="translate(-4.21 -7.917)"
                          fill="#6c757d"
                        />
                        <path
                          id="Path_2785"
                          data-name="Path 2785"
                          d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                          transform="translate(-4.21 -4.86)"
                          fill="#6c757d"
                        />
                      </svg>
                    </template>
                  </v-text-field>
                  <v-hijri-date-picker
                    @change="showHiringDate = false"
                    v-if="showHiringDate"
                    style="position: absolute; z-index: 1000"
                    v-model="userData.birth_date"
                    locale="ar"
                  />
                </div>
              </v-col> -->

              <v-col cols="12" sm="12">
                <div style="display: flex; align-items: center" class="input__group">
                  <div style="display: flex; align-items: center">
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('joint_hiring_date')"
                    />
                    تاريخ الميلاد ميلادى
                  </div>
                  <div class="horizontal__line"></div>

                  <v-text-field
                    type="date"
                    style="margin-bottom: 5px"
                    hide-details
                    v-model="$store.state.userProfileData.birth_date_m"
                    class="mr-1"
                    dense
                    append-icon
                  >
                    <template v-slot:append>
                      <svg
                        id="calendar"
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.396"
                        height="16.989"
                        viewBox="0 0 17.396 16.989"
                        style="margin-top: 15px"
                      >
                        <path
                          id="Path_2784"
                          data-name="Path 2784"
                          d="M19.1,24.906H6.714a2.5,2.5,0,0,1-2.5-2.5V16.412a.626.626,0,0,1,1.252,0V22.4a1.252,1.252,0,0,0,1.252,1.252H19.1A1.252,1.252,0,0,0,20.354,22.4V11.814A1.252,1.252,0,0,0,19.1,10.562H6.714a1.252,1.252,0,0,0-1.252,1.252V14.28a.626.626,0,0,1-1.252,0V11.814a2.5,2.5,0,0,1,2.5-2.5H19.1a2.5,2.5,0,0,1,2.5,2.5V22.4A2.5,2.5,0,0,1,19.1,24.906Z"
                          transform="translate(-4.21 -7.917)"
                          fill="#6c757d"
                        />
                        <path
                          id="Path_2785"
                          data-name="Path 2785"
                          d="M18.476,11.849H4.836a.626.626,0,1,1,0-1.252h13.64a.626.626,0,0,1,0,1.252ZM9.23,8.9A.626.626,0,0,1,8.6,8.275V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,9.23,8.9Zm7.355,0a.626.626,0,0,1-.626-.626V5.486a.626.626,0,1,1,1.252,0V8.275A.626.626,0,0,1,16.585,8.9Z"
                          transform="translate(-4.21 -4.86)"
                          fill="#6c757d"
                        />
                      </svg>
                    </template>
                  </v-text-field>
                </div>
              </v-col>

              <v-col cols="12" sm="12">
                <div style="display: flex; align-items: center" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('sex')"
                    />
                    الجنس
                  </div>
                  <div class="horizontal__line"></div>

                  <v-autocomplete
                    :items="[
                      { text: 'أنثي', value: 'female' },
                      { text: 'ذكر', value: 'male' },
                    ]"
                    v-model="$store.state.userProfileData.gender"
                    style="margin-top: 15px"
                    outlined
                    dense
                  >
                    <template v-slot:item="{ item, attrs, on }">
                      <v-list-item v-bind="attrs" v-on="on">
                        <v-list-item-title
                          :id="attrs['aria-labelledby']"
                          v-text="item.text"
                          v-value="item.value"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                  <img src="@/assets/icons/down-arrow.svg" />
                </div>
              </v-col>

              <v-col cols="12" sm="12">
                <div style="display: flex" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('customerName')"
                    />
                    العنوان المختصر
                  </div>
                  <div class="horizontal__line"></div>
                  <div>
                    <v-text-field
                      type="text"
                      class="form-control"
                      style="
                        background-color: #fbfbfb;
                        border-right: #fbfbfb;
                        margin-top: -20px;
                      "
                      v-model="$store.state.userProfileData.title"
                      ref="customerName"
                    />
                  </div>
                </div>
              </v-col>

              <v-col cols="12" sm="12">
                <div style="display: flex; align-items: center" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('work')"
                    />
                    المنطقة
                  </div>
                  <div class="horizontal__line"></div>

                  <v-autocomplete
                    :items="area"
                    hide-details
                    v-model="$store.state.userProfileData.area_id"
                    item-text="value"
                    item-value="id"
                    style="border: none !important"
                    class="mr-1"
                    outlined
                    dense
                  >
                  </v-autocomplete>
                  <img src="@/assets/icons/down-arrow.svg" />
                </div>
              </v-col>

              <v-col cols="12" sm="12">
                <div style="display: flex; align-items: center" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('work')"
                    />
                    المدينه
                  </div>
                  <div class="horizontal__line"></div>

                  <v-autocomplete
                    :items="citys"
                    hide-details
                    v-model="$store.state.userProfileData.city_id"
                    item-text="value"
                    item-value="id"
                    style="border: none !important"
                    class="mr-1"
                    outlined
                    dense
                  >
                  </v-autocomplete>
                  <img src="@/assets/icons/down-arrow.svg" />
                </div>
              </v-col>

              <v-col cols="12" sm="12">
                <div style="display: flex; align-items: center" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('work')"
                    />
                    الحى
                  </div>
                  <div class="horizontal__line"></div>

                  <v-autocomplete
                    :items="distracts"
                    hide-details
                    v-model="$store.state.userProfileData.district_id"
                    item-text="value"
                    item-value="id"
                    style="border: none !important"
                    class="mr-1"
                    outlined
                    dense
                  >
                  </v-autocomplete>
                  <img src="@/assets/icons/down-arrow.svg" />
                </div>
              </v-col>
            </v-card>
          </v-col>

          <v-col cols="12" lg="4" md="4" sm="6" xs="12">
            <v-card class="pa-3 position-relative userProfile__card">
              <v-col cols="12" sm="12">
                <div style="display: flex" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('customerName')"
                    />
                    إسم الشارع
                  </div>
                  <div class="horizontal__line"></div>
                  <div>
                    <v-text-field
                      type="text"
                      class="form-control"
                      style="
                        background-color: #fbfbfb;
                        border-right: #fbfbfb;
                        margin-top: -20px;
                      "
                      v-model="$store.state.userProfileData.street_name"
                    />
                  </div>
                </div>
              </v-col>

              <v-col cols="12" sm="12">
                <div style="display: flex" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('customerName')"
                    />
                    رقم المبنى
                  </div>
                  <div class="horizontal__line"></div>
                  <div>
                    <v-text-field
                      type="text"
                      class="form-control"
                      style="
                        background-color: #fbfbfb;
                        border-right: #fbfbfb;
                        margin-top: -20px;
                      "
                      v-model="$store.state.userProfileData.building_number"
                    />
                  </div>
                </div>
              </v-col>

              <v-col cols="12" sm="12">
                <div style="display: flex" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('customerName')"
                    />
                    رقم الوحدة
                  </div>
                  <div class="horizontal__line"></div>
                  <div>
                    <v-text-field
                      type="text"
                      class="form-control"
                      style="
                        background-color: #fbfbfb;
                        border-right: #fbfbfb;
                        margin-top: -20px;
                      "
                      v-model="$store.state.userProfileData.unit_number"
                    />
                  </div>
                </div>
              </v-col>

              <v-col cols="12" sm="12">
                <div style="display: flex" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('customerName')"
                    />
                    إسم الشخص القريب
                  </div>
                  <div class="horizontal__line"></div>
                  <div>
                    <v-text-field
                      type="text"
                      class="form-control"
                      style="
                        background-color: #fbfbfb;
                        border-right: #fbfbfb;
                        margin-top: -20px;
                      "
                      v-model="$store.state.userProfileData.contact_person_name"
                    />
                  </div>
                </div>
              </v-col>

              <v-col cols="12" sm="12">
                <div style="display: flex" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('customerName')"
                    />
                    صلة القرابة
                  </div>
                  <div class="horizontal__line"></div>
                  <div>
                    <v-text-field
                      type="text"
                      class="form-control"
                      style="
                        background-color: #fbfbfb;
                        border-right: #fbfbfb;
                        margin-top: -20px;
                      "
                      v-model="$store.state.userProfileData.contact_person_relation"
                    />
                  </div>
                </div>
              </v-col>

              <v-col cols="12" sm="12">
                <div style="display: flex" class="input__group">
                  <div>
                    <img
                      src="@/assets/icons/material-history.svg"
                      alt="image"
                      style="margin-top: 5px; margin-left: 5px"
                      @click="show_Field_log('customerName')"
                    />
                    رقم جوال الشخص قريب
                  </div>
                  <div class="horizontal__line"></div>
                  <div>
                    <v-text-field
                      type="text"
                      class="form-control"
                      style="
                        background-color: #fbfbfb;
                        border-right: #fbfbfb;
                        margin-top: -20px;
                      "
                      v-model="$store.state.userProfileData.contact_person_number"
                    />
                  </div>
                </div>
              </v-col>
            </v-card>
          </v-col>
        </v-layout>
      </v-card>
    </v-form>

    <v-btn class="primary" @click="active = true" v-if="!active">تعديل</v-btn>

    <v-btn class="primary" v-if="active" @click="handleEdit">حفظ</v-btn>

    <v-btn @click="active = false" v-if="active" style="margin-right: 5px">عرض</v-btn>

    <v-dialog v-if="0" v-model="secondNumber" max-width="540px">
      <v-card class="pl-3">
        <div class="d-flex justify-space-between align-center">
          <v-card-title> اضافة رقم هاتف</v-card-title>
          <v-icon style="cursor: pointer" @click="secondNumber = false">mdi-close</v-icon>
        </div>
        <v-card-text>
          <v-text-field
            hide-details
            class="mr-1"
            outlined
            dense
            v-model="secNum"
            @keydown="confirmNum.err = true"
          ></v-text-field>
          <div class="d-flex justify-content-center">
            <v-btn class="mt-5 success white--text d-block" @click="addNumber">{{
              verifing ? "جاري التحقق ..." : "اضافة"
            }}</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-if="0" v-model="showNums" max-width="540px">
      <v-card v-if="numbersArray.length > 0" class="pb-3 pl-3 pr-3">
        <div class="d-flex justify-space-between align-center">
          <v-card-title> ارقام الهاتف</v-card-title>
          <v-icon style="cursor: pointer" @click="showNums = false">mdi-close</v-icon>
        </div>
        <div class="d-flex justify-start align-center flex-wrap">
          <span
            style="
              background: rgb(60 89 208);
              color: white;
              padding: 7px 9px 9px;
              border-radius: 10px;
              font-size: 16px;
              margin: 10px;
              letter-spacing: 1px;
            "
            v-for="(num, i) in numbersArray"
            :key="i"
            >{{ num.mobile }}</span
          >
        </div>
      </v-card>
      <v-card v-else class="text-center pt-3">
        <v-card-text style="font-size: 17px"
          >لم يتم اضافة ارقام هاتف حتي الان</v-card-text
        >
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="activeSnack"
      timeout="-1"
      right
      bottom
      class="ml-4"
      elevation="24"
      transition="fade-transition"
    >
      <div>
        <span class="font-weight-bold" style="font-size: 16px">تم التعديل</span>
      </div>
      <template v-slot:action>
        <v-btn icon small @click="activeSnack = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script setup>
import ProfileHeader from "./ProfileHeader.vue";
import UserCard from "./UserCard.vue";
import { ref, onMounted, watch } from "vue";
import { axiosInstance } from "@/axios/config";
import { store } from "@/store";
import router from "@/routes";

const active = ref(false);
const showStartHiringDate = ref(false);

const getData = () => {
  axiosInstance
    .get(`/employee/hr/user-profile/${router.currentRoute.params.id}`)
    .then((res) => {
      if (res.data.payload.profile === null) {
        store.state.isProfile = false;
        res.data.payload.profile = {
          id: router.currentRoute.params.id,
          name: null,
          name_en: null,
          gender: null,
          mobile: null,
          email: null,
          birth_date: null,
          family_count: null,
          marital_status: null,
          qualification: null,
          control_nationality_id: null,
          job: null,
          work_date: null,
          work_date_2: null,
          work_end_date: null,
          residence_number: null,
          residence_end_date: null,
          direct_date: null,
          notes: null,
          specialization: null,
          area_id: null,
          city_id: null,
          district_id: null,
          street_name: null,
          building_number: null,
          unit_number: null,
          title: null,
          contact_person_number: null,
          contact_person_name: null,
          contact_person_relation: null,
          job_number: null,
          control_section_id: null,
          control_subsection_id: null,
          control_guaranty_id: null,
          control_company_id: null,
          control_identity_id: null,
          control_insurances_id: null,
          control_work_id: null,
          control_medical_id: null,
          guaranty_name: null,
          control_guaranty_company_id: null,
          user_id: null,
          birth_date_m: null,
          work_date_m: null,
          work_date_2_m: null,
          work_end_date_m: null,
          direct_date_m: null,
          residence_end_date_m: null,
          custody: null,
          holidays: null,
        };
      } else {
        store.state.isProfile = true;
      }

      store.state.userProfileData = res.data.payload.profile;
      store.state.custodies = res.data.payload.custodies;
      console.log(res.data.payload.custodies);
      if (!res.data.payload.custodies || res.data.payload.custodies.length === 0) {
        store.state.custodies = [
          {
            control_id: null,
            description: "",
          },
        ];
      }
      store.state.employeeFiles = res.data.payload.employeeFiles;
      store.state.archivedEmployeeFiles = res.data.payload.archivedEmployeeFiles;
    });
};

const nationality = ref([]);
const getNationality = () => {
  axiosInstance.get(`/employee/hr/employee-control-list`).then((res) => {
    nationality.value = res.data.payload.nationality;
  });
};

const area = ref([]);
const getAreas = () => {
  axiosInstance.get(`/areas`).then((res) => {
    area.value = res.data.payload.map((item) => {
      item["color"] = "red";
      item["text"] = item["value"];
      return item;
    });
  });
};

watch(area, (current) => {
  getCitys(current);
});

const citys = ref([]);
const getCitys = (val) => {
  let body = new FormData();
  val.forEach((item, index) => {
    body.append(`area_id[${index}]`, item["id"]);
  });
  axiosInstance.post(`/cities`, body).then((res) => {
    citys.value = res.data.payload.map((item) => {
      item["color"] = "red";
      item["text"] = item["value"];
      return item;
    });
  });
};

watch(citys, (current) => {
  getDistracts(current);
});

const distracts = ref([]);
const getDistracts = (val) => {
  let body = new FormData();
  val.forEach((item, index) => {
    body.append(`city_id[${index}]`, item["id"]);
  });
  axiosInstance.post(`/districts`, body).then((res) => {
    distracts.value = res.data.payload.map((item) => {
      item["color"] = "red";
      item["text"] = item["value"];
      return item;
    });
  });
};

const activeSnack = ref(false);
const handleEdit = () => {
  const formData = new FormData();

  Object.entries(store.state.userProfileData).forEach(([key, value]) => {
    formData.append(key, value);
  });

  let obj = store.state.userProfileData;
  for (const key in obj) {
    if (obj[key] !== null && key !== "id") {
      store.state.isProfile = true;
    }
  }

  axiosInstance
    .post(`/employee/hr/user-update-profile/${router.currentRoute.params.id}`, formData)
    .then((res) => {
      activeSnack.value = true;
    });
};

const getPdfLink = () => {
  axiosInstance
    .get(`/employee/hr/upload-profile/${router.currentRoute.params.id}`)
    .then((res) => {
      store.state.userPdf = res.data.payload.profile_pdf;
    });
};
onMounted(() => {
  getPdfLink();
  getData();
  getNationality();
  getAreas();
});
</script>
